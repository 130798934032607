/**
 * Durée de mise en cache par défaut.
 */
const defaultCacheDuration = 0

/**
 * Retourne une fonction de mise en cache configurée avec une certaine durée.
 *
 * Usage :
 *
 * const cache = configureCache(duree)
 * const cachedValue = cache.cacheObject({ value: 1234 })
 * const timestamp = cache.mark()
 *
 * @param duration la durée de mise en cache pour tout le cache
 */
const configureCache = (duration = defaultCacheDuration) => {
	const mark = (date = new Date()) => (date.getTime() + duration * 1000)
	return {
		cacheObject: (object, date = new Date()) => ({
			...object,
			expirationDate: mark(date)
		}),
		mark
	}
}

export default configureCache
