import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { downloadNotification } from '../../../webservices/notifications/NotificationActions'
import { getSuffix } from '../../../webservices/notifications/NotificationUtils'
import Loader from '../../../../components/Loader'

type DownloadNotifLinkProps = {
	notification: any
	children?: React.ReactNode
	url: string
	label?: string
}

const DownloadNotifLink: React.FC<DownloadNotifLinkProps> = (
	{
		label,
		notification,
		url,
		children
	}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	return (
		<a
			href="#"
			onClick={() => {
				setIsLoading(true)
				downloadNotification(url)
					.finally(() => setIsLoading(false))
			}}
		>
			{isLoading ? <Loader /> :
				children || label || (
					<FormattedMessage
						id={`notification.${getSuffix(notification)}.lien`}
						values={{
							...notification.data,
							date: new Date(notification.data.date)
						}}
					/>
				)
			}
		</a>
	)
}

export default DownloadNotifLink
