import React, {FC, useCallback, useEffect, useMemo} from 'react'
import {Stack, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import {FormattedMessage, useIntl} from 'react-intl'
import Paycard from '../resources/img/paycard.png'
import AppMenuCell from './appbar/AppMenuCell'
import {MAIN_PATH, MAIN_PATH_EE, SECONDARY_PATH} from '../constant/path'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {logoutEA, logoutEE} from '../univers/ui/authentication/services/login/loginActions'
import {profileSelector, profileSelectorKpi, userSelector} from '../ea/ui/user/userSelectors'
import {useHistory, useLocation} from 'react-router-dom'
import {
    mdiAccountBoxOutline,
    mdiCogs,
    mdiCommentQuestionOutline,
    mdiCurrencyEur,
    mdiLogout,
    mdiViewDashboardOutline,
    mdiHumanGreetingProximity,
    mdiCardAccountMail, mdiUmbrella, mdiMedicalBag, mdiFaceAgent
} from '@mdi/js'
import * as affiliationActions from '../ea/webservices/affiliation/affiliationActions'
import {useBreakpoints} from './breakpoints/BreakpointsProvider'
import {getNavMenu} from '../ea/webservices/navMenu/navMenuSelector'
import {getMenu} from '../ea/webservices/navMenu/navMenuActions'
import { UNIVERS_ENTREPRISES, UNIVERS_PARTICULIERS } from '../univers/constant/univers'
import ButtonModuleRH from './ButtonModuleRH'
import Icon from '@mdi/react'
import CarteTPService from '../api/gerep/CarteTPService'
import LocalStorage from '../business/storage/LocalStorage'
import { setCarteTpView } from '../ea/ui/user/userActions'
import ApiError from '../api/gerep/ApiError'
import AppBarCardButton from './appbar/AppBarCardButton'

const LOGO_HEIGHT = '100px'

type NavMenuProps = {
	closeDrawer?: () => void
	isCarteTP?: boolean
    setLoading?: (value : boolean) => void
}

/**
 * Verifie selon le profil de l'utilisateur si le menuItem doit être afficher ou non
 * @param menuItem
 * @param profile
 */
const checkMenuRG = (menuItem: any, profile: any) => {
    const allowedProfilList = (
        menuItem.fields.profil && menuItem.fields.profil.split(';')) || []
    if (allowedProfilList.length) {
        return allowedProfilList.reduce((acc: any, el: any) => {
            return acc && profile[el] === true
        }, true)
    }
    return true
}

const NavMenu: FC<NavMenuProps> = (
    {
        // @ts-ignore
        logout,
        // @ts-ignore
        getAffiliation,
        // @ts-ignore
        user,
        closeDrawer,
        // @ts-ignore
        getMenu,
        // @ts-ignore
        menuFolders,
        // @ts-ignore
        profile,
        // @ts-ignore
        isEntreprise,
        // @ts-ignore
        setCarteTpView,
	    isCarteTP,
        // @ts-ignore
        setLoading
    }) => {
    const intl = useIntl()
    const history = useHistory()
    const location = useLocation()
    const currentPath = useMemo(() => location.pathname, [location.pathname])
    const [expanded, setExpanded] = React.useState<string | false>(currentPath)

    const {height, isMobile} = useBreakpoints()

    const menuHeight = useMemo(() => height - 100 - 320, [height])

    const affiliationExpired = useMemo(() => isEntreprise? false : user?.affiliationExpired, [isEntreprise, user?.affiliationExpired])

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }

    const getPrimaryIcon = (url: string) => {
        switch (url) {
            case MAIN_PATH.DASHBOARD:
                return mdiViewDashboardOutline
            case MAIN_PATH.REFUND:
                return mdiCurrencyEur
            case MAIN_PATH.ACCOUNT:
                return mdiAccountBoxOutline
            case MAIN_PATH.SERVICES:
                return mdiCogs
            case MAIN_PATH_EE.SANTE:
                return mdiMedicalBag
            case MAIN_PATH_EE.PREVOYANCE_ARRET_TRAVAIL:
                return mdiUmbrella
            case MAIN_PATH_EE.QUALITE_SERVICE:
                return mdiFaceAgent
            default:
                return mdiViewDashboardOutline
        }
    }

    const DRAWER_MENU_CONTENT: DrawerContent[] = useMemo(() => {
	    const menuContent = isCarteTP ? menuFolders.filter((folder: any) => folder.fields.url === '/home') : menuFolders
        const mainMenuContent = menuContent ? menuContent.map((folder: any) => {
            return {
                label: folder.fields.title,
                icon: getPrimaryIcon(folder.fields.url),
                onClick: () => {
                    if (!folder.folders || folder.folders.length === 0) {
                        if (!isCarteTP) {
                            closeDrawer && closeDrawer()
                            history.push(folder.fields.url)
                        } else {
                            if (setLoading) {
                                setLoading(true)
                            }
                            CarteTPService.getTicket(LocalStorage.getToken())
                                .then((weakToken) => {
                                    console.log(weakToken)
                                    CarteTPService.getNewToken(weakToken)
                                        .then((response) => {
                                            response && LocalStorage.setSession(response.token.access_token, UNIVERS_PARTICULIERS.id, false)
                                            window.location.href = UNIVERS_PARTICULIERS.to(MAIN_PATH.DASHBOARD)
                                        })
                                }).catch((error) => {
                                if (setLoading) {
                                    setLoading(false)
                                }
                                if (error instanceof ApiError) {
                                    throw error
                                }
                                throw new ApiError(500, null, 'GEREP API unreachable')
                            })
                        }
                    }
                },
                path: folder.fields.url,
                content: folder.folders.map((subFolder: any) => {
                    return {
                        label: subFolder.fields.title,
                        onClick: () => {
                            if (!folder.folders || folder.folders.length !== 0) {
                                closeDrawer && closeDrawer()
                                history.push(subFolder.fields.url)
                            }
                        },
                        path: subFolder.fields.url,
                        show: checkMenuRG(subFolder, profile)
                    }
                }),
                show: checkMenuRG(folder, profile)
            }
        }) : []
        if(isEntreprise){
            mainMenuContent.push(...[
                    {
                        label: intl.formatMessage({id: 'appMenu.contact.title'}),
                        icon: mdiCardAccountMail,
                        onClick: () => {
                            closeDrawer && closeDrawer()
                            history.push(MAIN_PATH_EE.CONTACTER_GEREP)
                        },
                        path: MAIN_PATH.HELP,
                        content: [],
                        show: !affiliationExpired
                    }
                ]
            )
        } else {
            mainMenuContent.push(...[
                    {
                        label: intl.formatMessage({id: 'appMenu.help.title'}),
                        icon: mdiCommentQuestionOutline,
                        onClick: () => {
                            closeDrawer && closeDrawer()
                            history.push(MAIN_PATH.HELP)
                        },
                        path: MAIN_PATH.HELP,
                        content: [],
                        show: !affiliationExpired && !isCarteTP
                    }
                ]
            )
        }

        mainMenuContent.push(...[
                {
                    label: intl.formatMessage({id: 'appMenu.logout.title'}),
                    icon: mdiLogout,
                    onClick: () => {
                        closeDrawer && closeDrawer()
                        logout()
                    },
                    path: MAIN_PATH.LOGOUT,
                    content: [],
                    show: true
                }
            ]
        )
        return mainMenuContent
    }, [user, menuFolders, affiliationExpired])

    useEffect(() => {
        if(isEntreprise) {
            getMenu('header_kpi')
        } else {
            if(!isCarteTP){
                getAffiliation()
            }
            if (user && user.affiliationExpired) {
                getMenu('headerRadie')
            } else {
                getMenu('header')
            }
        }
    }, [])

    const renderMenu = useCallback(
        () => (
            <Stack
                width="100%"
                direction="column"
                marginTop="8px"
                height={isEntreprise ? '100%':`${menuHeight}px`}
                spacing={1}
                sx={{
                    overflow: 'hidden',
                    overflowY: 'auto'
                }}
            >
                {DRAWER_MENU_CONTENT.filter((drawer) => drawer?.show).map((item, index) => (
                    <AppMenuCell
                        content={item}
                        key={index}
                        expanded={typeof expanded === 'string' && item.path ? expanded.includes(item.path) : false}
                        onChange={item.path ? handleChange(item.path) : undefined}
                    />
                ))}
                {!isEntreprise && isMobile && (
                    <Stack mb="30px" p="12px" position="absolute" bottom={0}>
                        <Box>
                            <img src={Paycard} style={{width: '200px', marginBottom: '-20px'}} alt="paycard"/>
                        </Box>
                        <Stack direction="column" spacing={2} alignItems="center">
                            <Typography variant="body1" width="80%" sx={{color: '#232323', textAlign: 'center'}}>
                                <FormattedMessage id="appMenu.card.title"/>
                            </Typography>
                            <Box>
                                <AppBarCardButton sx={{backgroundColor: '#fff'}}
                                                  onClick={() => history.push(SECONDARY_PATH.ACCOUNT_CARD)}>
                                    <FormattedMessage id="appMenu.card.button"/>
                                </AppBarCardButton>
                            </Box>
                        </Stack>
                        <Box bottom="172px" zIndex={-1} position="relative" width="200px" height="186px"
                             bgcolor="#fae2d0" borderRadius="24px" left="12px" marginBottom="-160px"/>
                    </Stack>
                )}
            </Stack>
        ),
        [expanded, DRAWER_MENU_CONTENT, isMobile, menuHeight]
    )

    if (DRAWER_MENU_CONTENT.length <= 2) {
        return (
            <Stack
                width="260px"
                height={height}
                direction="column"
                flexGrow={1}
                alignItems="center"
                px="8px"
                position="fixed"
                boxShadow={2}
                bgcolor="white"
                zIndex={1111}
            />
        )
    }

    return (
        <Stack
            width="260px"
            height={height}
            direction="column"
            flexGrow={1}
            alignItems="center"
            px="8px"
            position="fixed"
            boxShadow={2}
            bgcolor="white"
            zIndex={1111}
        >
            <Box height={LOGO_HEIGHT} alignItems="center" justifyContent="center" pt="12px" mb="32px">
                <img src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
                     style={{width: '150px', height: '80px'}} alt="logo"/>
            </Box>
            {renderMenu()}
            {!isEntreprise ?
                (
                    !affiliationExpired && !isMobile && !isCarteTP &&
                    <Stack mb="30px" p="12px">
                        <Box>
                            <img src={Paycard} style={{width: '200px', marginBottom: '-20px'}} alt="paycard"/>
                        </Box>
                        <Stack direction="column" spacing={2} alignItems="center">
                            <Typography variant="body1" width="80%" sx={{color: '#232323', textAlign: 'center'}}>
                                <FormattedMessage id="appMenu.card.title"/>
                            </Typography>
                            <Box>
                                <AppBarCardButton
                                    sx={{backgroundColor: '#fff'}}
                                    onClick={() => history.push(SECONDARY_PATH.ACCOUNT_CARD)}
                                >
                                    <FormattedMessage id="appMenu.card.button"/>
                                </AppBarCardButton>
                            </Box>
                        </Stack>
                        <Box bottom="20px" zIndex={-1} position="absolute" width="200px" height="186px" bgcolor="#fae2d0"
                             borderRadius="24px"/>
                    </Stack>
                ) : (
                    <Stack mb="30px" py="12px" width="90%">
                        {(profile.affiliations || profile.prevoyance || profile.dispense) &&
                            <ButtonModuleRH
                                variant="outlined"
                                startIcon={<Icon size="20px" path={mdiHumanGreetingProximity} />}
                                onClick={() => {
                                    window.location.href = UNIVERS_ENTREPRISES.to(MAIN_PATH_EE.DASHBOARD)
                                }}
                            >
                                <FormattedMessage id="appMenu.redirectEE.title" />
                            </ButtonModuleRH>
                        }
                    </Stack>
                )
	        }
            {isEntreprise && (
                    <Stack mb="30px" py="12px" width="90%">
                        {(profile.affiliations || profile.prevoyance || profile.dispense) &&
                            <ButtonModuleRH
                                variant="outlined"
                                startIcon={<Icon size="20px" path={mdiHumanGreetingProximity}/>}
                                onClick={() => {window.location.href = UNIVERS_ENTREPRISES.to(MAIN_PATH_EE.DASHBOARD)}}
                            >
                                    <FormattedMessage id="appMenu.redirectEE.title"/>
                            </ButtonModuleRH>
                        }
                    </Stack>
                )
            }
        </Stack>
    )
}

const actions = {
    logout : logoutEA,
    getAffiliation: affiliationActions.getAffiliation,
    getMenu: getMenu
}

const actionsCarteTPView = {
    logout : logoutEA,
    getMenu: getMenu,
    setCarteTpView
}

const actionsEE = {
    logout : logoutEE,
    getMenu: getMenu
}

const makeMapStateToProps = (state: any) => ({
    user: userSelector(state),
    menuFolders: getNavMenu(state)
})

export const NavMenuEE = compose(
    connect((state : any) => {
        const commonMapState = makeMapStateToProps(state)
        return {
            ...commonMapState,
            profile: profileSelectorKpi(state),
            isEntreprise: true,
            isCarteTP: false
        }
    }, actionsEE)
)(NavMenu)

export const NavMenuEA = compose(
    connect((state : any) => {
        const commonMapState = makeMapStateToProps(state)
        return {
            ...commonMapState,
            profile: profileSelector(state),
            isEntreprise: false,
            isCarteTP: false
        }
    }, actions)
)(NavMenu)

export const NavMenuEACarteTp = compose(
	connect((state: any) => {
		const commonMapState = makeMapStateToProps(state)
		return {
			...commonMapState,
			profile: profileSelector(state),
			isEntreprise: false,
            isCarteTP: true
		}
	}, actionsCarteTPView)
)(NavMenu)


export default compose(
    connect(makeMapStateToProps, actions)
)(NavMenu)
