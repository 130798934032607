import React, {useContext, useEffect, useMemo, useState} from 'react'
import PageLayout from '../../../../components/PageLayout'
import {FormattedMessage, useIntl} from 'react-intl'
import {Box, CircularProgress, Stack, Typography} from '@mui/material'
import TextInput from '../../../../components/form/TextInput'
import {Form} from 'react-final-form'
import ButtonDefault from '../../../../components/ButtonDefault'
import {ToastType} from '../../../../components/toast/toastConstants'
import {ToastContext} from '../../../../components/toast/ToastContext'
import {DELETE_BENEFICIARY_FIELDS} from '../services/personalInfoConstants'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import { getIndividuListForManage } from '../../../webservices/affiliation/affiliationSelector'
import appConst from '../../../../constant/appConstants'
import SelectInput from '../../../../components/form/SelectInput'
import FileInput from '../../../../components/fileInput/FileInput'
import DatePickerInput from '../../../../components/form/DatePickerInput'
import {isBefore, isValid} from 'date-fns'
import * as affiliationActions from '../../../webservices/affiliation/affiliationActions'
import LocalStorage from '../../../../business/storage/LocalStorage'
import ContactService from '../../../../api/gerep/ContactService'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import CheckboxInput from '../../../../components/form/CheckboxInput'
import Popup from '../../../../components/Popup'
import {userSelector} from '../../user/userSelectors'

const ARTICLE_NAME = 'demande_suppression_beneficiaire'

const ENFANT_MAJEUR_NON_SCOLARISE = 1
const DIVORCE = 2
const DISSOLUTION_PACS = 3
const SEPARATION = 4
const DECES = 5
const AUTRE_MUTUELLE = 6

const DeleteBeneficiaryPage = (
    {
        // @ts-ignore
        individuList,
        // @ts-ignore
        introduction,
        // @ts-ignore
        getArticleByShortcut,
        // @ts-ignore
        getAffiliation,
        // @ts-ignore
        user
    }
) => {
    const intl = useIntl()
    const {addToast} = useContext(ToastContext)
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
    const [openSendMail, setOpenSendMail] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const radiationDate = useMemo(() => {
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() + 1)
        return currentDate.toLocaleDateString('fr-FR')
    }, [])

    const onSubmit = (values: any) => {
        setIsLoading(true)
        const {
            beneficiaire,
            motif,
            dateRadiation,
            files = [],
            envoyerMail,
            email
        } = values

        const personneConcernee = individuList.find((individu: any) => individu[1].numIndividu === beneficiaire)
        const nomPersonneConcernee = personneConcernee && `${personneConcernee[1].prenom} ${personneConcernee[1].nom}`

        // soumission de la radiation
        const filesUploaded = files.map((file: any, index: number) => ({
            key: `file${index}`,
            value: file
        }))
        const data = [{
            key: 'suppression',
            value: {
                beneficiaire,
                nomBeneficiaire: nomPersonneConcernee,
                motif,
                dateRadiation: new Date(dateRadiation).toLocaleDateString('fr-FR')
            }
        }, ...filesUploaded]

        if (envoyerMail && envoyerMail.length > 0 && motif !== DECES.toString() && motif !== AUTRE_MUTUELLE.toString()) {
            Promise.all([
                ContactService.informationBeneficiaire(
                    LocalStorage.getToken(),
                    {
                        numIndividu: personneConcernee && personneConcernee[1].numIndividu,
                        dateNaissance: personneConcernee && personneConcernee[1].dateNaissance,
                        nom: personneConcernee && personneConcernee[1].nom,
                        prenom: personneConcernee && personneConcernee[1].prenom,
                        email
                    }),
                ContactService.suppressionBeneficiaire(LocalStorage.getToken(), data)
            ])
                .then(() => {
                    addToast(ToastType.SUCCESS, 'deleteBeneficiary.success')
                    setOpenSendMail(false)
                    setOpenConfirmation(true)
                })
                .catch((e) => {
                    const error = (e.data && e.data._error) || 'Echec de la suppression du bénéficiaire'
                    addToast(ToastType.ERROR, '', error)
                })
                .finally(() => setIsLoading(false))
        } else if (!envoyerMail) {
            ContactService.suppressionBeneficiaire(LocalStorage.getToken(), data)
                .then(() => {
                    addToast(ToastType.SUCCESS, 'deleteBeneficiary.success')
                    setOpenConfirmation(true)
                })
                .catch((e) => {
                    const error = (e.data && e.data._error) || 'Echec de la suppression du bénéficiaire'
                    addToast(ToastType.ERROR, '', error)
                })
                .finally(() => setIsLoading(false))
        }
    }

    const filteredIndividuList = individuList.filter((item: any) => item[1].libTypAdr !== 'Assuré Principal').map((item: any) => ({
        label: `${item[1].prenom} ${item[1].nom}`,
        value: item[0]
    }))

    useEffect(() => {
        getArticleByShortcut(appConst.cardiboxApi, ARTICLE_NAME)
        getAffiliation()
    }, [getAffiliation, getArticleByShortcut])

    const MOTIFS_RADIATION: SelectOption[] = [
        {
            value: ENFANT_MAJEUR_NON_SCOLARISE.toString(),
            label: intl.formatMessage({id: 'deleteBeneficiary.labels.child'})
        },
        {
            value: DIVORCE.toString(),
            label: intl.formatMessage({id: 'deleteBeneficiary.labels.divorce'})
        },
        {
            value: DISSOLUTION_PACS.toString(),
            label: intl.formatMessage({id: 'deleteBeneficiary.labels.endPACS'})
        },
        {
            value: SEPARATION.toString(),
            label: intl.formatMessage({id: 'deleteBeneficiary.labels.separation'})
        },
        {
            value: DECES.toString(),
            label: intl.formatMessage({id: 'deleteBeneficiary.labels.death'})
        },
        {
            value: AUTRE_MUTUELLE.toString(),
            label: intl.formatMessage({id: 'deleteBeneficiary.labels.otherMutual'})
        }
    ]
    return (
        <PageLayout title={intl.formatMessage({id: 'deleteBeneficiary.title'})}
                    bodyPadding={isMobile || isTabletPortrait ? '32px 32px 12px 32px' : '32px 32px 0 32px'}>
            <Form
                onSubmit={onSubmit}
                validate={(values: any) => {
                    const errors: any = {}
                    const dateDebut = new Date()
                    const dateFin = new Date()
                    const dateRadiation = new Date(values[DELETE_BENEFICIARY_FIELDS.RADIATION_DATE])
                    dateDebut.setDate(1)
                    dateDebut.setHours(0, 0, 0, 0)
                    dateFin.setMonth(dateFin.getMonth() + 3)
                    dateFin.setHours(0, 0, 0, 0)

                    if (!values[DELETE_BENEFICIARY_FIELDS.RADIATION_DATE]) {
                        errors[DELETE_BENEFICIARY_FIELDS.RADIATION_DATE] = intl.formatMessage({id: 'global.error.required'})
                    } else if (!isValid(dateRadiation)) {
                        errors[DELETE_BENEFICIARY_FIELDS.RADIATION_DATE] = intl.formatMessage({id: 'global.error.invalidDate'})
                    } else if (isBefore(dateRadiation, dateDebut)) {
                        errors[DELETE_BENEFICIARY_FIELDS.RADIATION_DATE] = intl.formatMessage({id: 'global.error.dateTooOld'})
                    } else if (isBefore(dateFin, dateRadiation)) {
                        errors[DELETE_BENEFICIARY_FIELDS.RADIATION_DATE] = intl.formatMessage({id: 'global.error.dateTooFar'})
                    }

                    if (!values[DELETE_BENEFICIARY_FIELDS.REASON]) {
                        errors[DELETE_BENEFICIARY_FIELDS.REASON] = intl.formatMessage({id: 'global.error.required'})
                    }

                    if (!values[DELETE_BENEFICIARY_FIELDS.BENEFIARY]) {
                        errors[DELETE_BENEFICIARY_FIELDS.BENEFIARY] = intl.formatMessage({id: 'global.error.required'})
                    }

                    if (values[DELETE_BENEFICIARY_FIELDS.REASON] === DECES.toString() && (!values[DELETE_BENEFICIARY_FIELDS.DEATH_CERTIFICATE] || values[DELETE_BENEFICIARY_FIELDS.DEATH_CERTIFICATE]?.length === 0)) {
                        errors[DELETE_BENEFICIARY_FIELDS.DEATH_CERTIFICATE] = intl.formatMessage({id: 'global.error.required'})
                    }

                    return errors
                }}
                render={({handleSubmit, pristine, errors, values, form: {reset}}) => {
                    const personneConcernee = individuList.find((individu: any) => individu[1].numIndividu === values[DELETE_BENEFICIARY_FIELDS.BENEFIARY])
                    const nomPersonneConcernee = personneConcernee ? `${personneConcernee[1].prenom} ${personneConcernee[1].nom}` : ''

                    return (
                        <form onSubmit={handleSubmit}>
                            <Stack direction="column" spacing="24px">
                                <Typography variant="body2">
                                    <div
                                        dangerouslySetInnerHTML={{__html: (introduction && introduction.fields && introduction.fields.body)}}
                                    />
                                </Typography>

                                <SelectInput
                                    name={DELETE_BENEFICIARY_FIELDS.BENEFIARY}
                                    label={intl.formatMessage({id: 'deleteBeneficiary.labels.beneficiary'})}
                                    data={filteredIndividuList}
                                    mandatory
                                />
                                <SelectInput
                                    name={DELETE_BENEFICIARY_FIELDS.REASON}
                                    label={intl.formatMessage({id: 'deleteBeneficiary.labels.reason'})}
                                    data={MOTIFS_RADIATION}
                                    mandatory
                                />
                                <DatePickerInput
                                    maxWidth="unset"
                                    name={DELETE_BENEFICIARY_FIELDS.RADIATION_DATE}
                                    label={intl.formatMessage({id: 'deleteBeneficiary.labels.date'})}
                                    initialValue={radiationDate}
                                    mandatory
                                />
                                {values[DELETE_BENEFICIARY_FIELDS.REASON] === DECES.toString() && (
                                    <FileInput
                                        name={DELETE_BENEFICIARY_FIELDS.DEATH_CERTIFICATE}
                                        title={<FormattedMessage id="deleteBeneficiary.labels.deathCertificate.title"/>}
                                        label={<FormattedMessage id="deleteBeneficiary.labels.deathCertificate.label"/>}
                                        error={errors && errors[DELETE_BENEFICIARY_FIELDS.DEATH_CERTIFICATE]}
                                        required
                                    />
                                )}
                                {values[DELETE_BENEFICIARY_FIELDS.BENEFIARY] &&
                                    (values[DELETE_BENEFICIARY_FIELDS.REASON] !== DECES.toString() && values[DELETE_BENEFICIARY_FIELDS.REASON] !== AUTRE_MUTUELLE.toString()) && (
                                        <CheckboxInput
                                            name={DELETE_BENEFICIARY_FIELDS.SEND_MAIL}
                                            data={[
                                                {
                                                    value: '1',
                                                    label: intl.formatMessage({id: 'deleteBeneficiary.labels.sendMail'}, {name: nomPersonneConcernee})
                                                }
                                            ]}
                                        />
                                    )}
                                <Stack alignItems="center">
                                    <ButtonDefault
                                        variant="contained"
                                        disabled={pristine}
                                        onClick={() => {
                                            if (values[DELETE_BENEFICIARY_FIELDS.SEND_MAIL]
                                                && values[DELETE_BENEFICIARY_FIELDS.SEND_MAIL].length > 0
                                                && values[DELETE_BENEFICIARY_FIELDS.REASON] !== DECES.toString()
                                                && values[DELETE_BENEFICIARY_FIELDS.REASON] !== AUTRE_MUTUELLE.toString()) {
                                                setOpenSendMail(true)
                                            } else {
                                                handleSubmit()
                                            }
                                        }}
                                    >
                                        {isLoading ? <CircularProgress size={24} color="inherit"/> :
                                            <FormattedMessage id="global.button.validate"/>}
                                    </ButtonDefault>
                                    <Box display="flex" width="100%" flex={1}
                                         alignSelf={isMobile || isTabletPortrait ? 'unset' : 'baseline'}>
                                        <Typography sx={{
                                            position: isMobile || isTabletPortrait ? 'unset' : 'relative',
                                            bottom: '32px',
                                            left: 0,
                                            height: '32px',
                                            display: 'flex',
                                            alignItems: 'flex-end'
                                        }} variant="body2" color="#7a7a7a">
                                            <FormattedMessage id="global.mandatory"/>
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                            <ConfirmationPopin
                                title={intl.formatMessage({id: 'deleteBeneficiary.title'})}
                                open={openConfirmation}
                                setOpen={setOpenConfirmation}
                                resetForm={reset}
                            />
                            <Popup
                                maxWidth="600px"
                                open={openSendMail}
                                setOpen={setOpenSendMail}
                                title={intl.formatMessage({id: 'deleteBeneficiary.sendMail.title'})}
                            >
                                <Stack direction="column" spacing="24px">
                                    <Stack direction="column" spacing="12px">
                                        <Typography variant="body2">
                                            <FormattedMessage id="deleteBeneficiary.sendMail.description.1"
                                                              values={{name: nomPersonneConcernee}}/>
                                        </Typography>
                                        <Typography variant="body2">
                                            <FormattedMessage id="deleteBeneficiary.sendMail.description.2"/>
                                        </Typography>
                                    </Stack>
                                    <TextInput
                                        maxWidth="unset"
                                        name={DELETE_BENEFICIARY_FIELDS.EMAIL}
                                        label={intl.formatMessage({id: 'deleteBeneficiary.labels.email'})}
                                        defaultValue={user?.email || ''}
                                    />
                                    <Stack alignItems="center">
                                        <ButtonDefault
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            {isLoading ? <CircularProgress size={24} color="inherit"/> :
                                                <FormattedMessage id="global.button.validate"/>}
                                        </ButtonDefault>
                                    </Stack>
                                </Stack>
                            </Popup>
                        </form>
                    )
                }}
            />
        </PageLayout>
    )
}

const mapStateToProps = (state: any) => {
    return {
        // @ts-ignore
        individuList: getIndividuListForManage(state),
        introduction: cardiboxSelectors.articles.getByShortcut(state.cardibox).demande_suppression_beneficiaire,
        user: userSelector(state)
    }
}

const actions = {
    getArticleByShortcut: cardiboxActions.articles.getByShortcut,
    getAffiliation: affiliationActions.getAffiliation
}

export default compose(
    connect(mapStateToProps, actions)
)(DeleteBeneficiaryPage)
