import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react'
import {Box, Link, Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import TextInput from '../../../../components/form/TextInput'
import {Form} from 'react-final-form'
import {generalUseUrl, SIGNUP_FIELDS, SIGNUP_OPTIONS} from '../services/loginConstants'
import DatePickerInput from '../../../../components/form/DatePickerInput'
import Popup from '../../../../components/Popup'
import CheckboxInput from '../../../../components/form/CheckboxInput'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import CaptchaInput from '../../../../components/form/CaptchaInput'
import ReCAPTCHA from 'react-google-recaptcha'
import {useAppDispatch} from '../../../../config/hooksUnivers'
import {isValid} from 'date-fns'
import {subscribe} from '../services/inscription/inscriptionActions'
import ButtonDefault from '../../../../components/ButtonDefault'
import PatternTextInput from '../../../../components/PatternTextInput'
import {FORM_REGEX, PATTERNS} from '../../../../components/formConstants'
import {ToastType} from '../../../../components/toast/toastConstants'
import {ToastContext} from '../../../../components/toast/ToastContext'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import {connect} from 'react-redux'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import appConst from '../../../../constant/appConstants'
import {FormApi} from 'final-form'
import {isNssValid} from '../../../../components/fieldsValidation'
import NissTextInput from '../../../../components/NissTextInput'
import {toUpperCase} from '../../../../utils/stringUtils'

const ARTICLE_CONFIRMATION = 'inscription_confirmation'

type SignupFormProps = {
    openSignup: boolean
    setOpenSignup: Dispatch<SetStateAction<boolean>>
}

const SignupForm: React.FC<SignupFormProps> = (
    {
        openSignup,
        setOpenSignup,
        // @ts-ignore
        confirmationArticle,
        // @ts-ignore
        getArticleByShortcut
    }) => {
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const {addToast} = useContext(ToastContext)
    const {isDesktop, isLaptop, isWidescreen} = useBreakpoints()
    const recaptchaRef = React.useRef<ReCAPTCHA>()

    let openConfirmation: any, setOpenConfirmation: any
    [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

    const isDesktopOrLaptop = isDesktop || isLaptop || isWidescreen

    const onSubmit = (values: SubscribeParams, form: FormApi<SubscribeParams, Partial<Record<string, any>>>) => {
        const {idAdherent, nom, prenom, niss, password, dateNaissance, email, newsletter, captcharesponse} = values
        const newsletterValue = newsletter.includes(SIGNUP_OPTIONS.NEWSLETTER)
        const dateNaissanceValue = new Date(dateNaissance).toLocaleDateString('fr-FR')
        return dispatch(subscribe(idAdherent.replaceAll(' ', ''), nom, prenom, niss.replaceAll('_', '').replaceAll(' ', ''), password, dateNaissanceValue, email, newsletterValue, captcharesponse))
            .then(() => {
                // inscription success
                setOpenSignup(false)
                setOpenConfirmation(true)
                recaptchaRef.current?.reset()
                form.reset()
            }).catch((e) => {
                recaptchaRef.current?.reset()
                if (e?.data?._error) {
                    addToast(ToastType.ERROR, e.data._error)
                } else if (e?.data?.globalError) {
                    addToast(ToastType.ERROR, e.data.globalError)
                } else if (e?.data) {
                    addToast(ToastType.ERROR, 'Echec de l\'inscription')
                    return e.data
                } else {
                    addToast(ToastType.ERROR, 'Echec de l\'inscription')
                }
            })
    }

    const required = (value: any) => (value ? undefined : intl.formatMessage({id: 'global.error.required'}))
    const generalConditionRequired = (value: string) => (value ? undefined : intl.formatMessage({id: 'global.error.generalCondition'}))

    const TERMS_AND_CONDITIONS_DATA = [
        {
            label: intl.formatMessage({id: 'login.form.signup.termsAndConditions.newsletter'}),
            value: SIGNUP_OPTIONS.NEWSLETTER,
            validate: generalConditionRequired
        },
        {
            label: intl.formatMessage({id: 'login.form.signup.termsAndConditions.generalUse.body'}, {
                link: (
                    <Link href={generalUseUrl} target="_blank" rel="noreferrer noopener">
                        {intl.formatMessage({id: 'login.form.signup.termsAndConditions.generalUse.link'})}
                    </Link>
                )
            }),
            value: SIGNUP_OPTIONS.GENERAL_USE
        }
    ]

    useEffect(() => {
        getArticleByShortcut(appConst.cardiboxApi, ARTICLE_CONFIRMATION)
    }, [])

    return (
        <Form
            onSubmit={onSubmit}
            validate={(values: any) => {
                const errors: any = {}
                if (!values[SIGNUP_FIELDS.PASSWORD]) {
                    errors[SIGNUP_FIELDS.PASSWORD] = intl.formatMessage({id: 'global.error.required'})
                } else if (!FORM_REGEX.PASSWORD.test(values[SIGNUP_FIELDS.PASSWORD])) {
                    errors[SIGNUP_FIELDS.PASSWORD] = intl.formatMessage({id: 'global.error.newpasswordbadFormat'})
                }

                if (!values[SIGNUP_FIELDS.CONFIRM_PASSWORD]) {
                    errors[SIGNUP_FIELDS.CONFIRM_PASSWORD] = intl.formatMessage({id: 'global.error.required'})
                } else if (values[SIGNUP_FIELDS.PASSWORD] !== values[SIGNUP_FIELDS.CONFIRM_PASSWORD]) {
                    errors[SIGNUP_FIELDS.CONFIRM_PASSWORD] = intl.formatMessage({id: 'global.error.passwordsDontMatch'})
                }

                if (values[SIGNUP_FIELDS.EMAIL] !== values[SIGNUP_FIELDS.CONFIRM_EMAIL]) {
                    errors[SIGNUP_FIELDS.CONFIRM_EMAIL] = intl.formatMessage({id: 'global.error.emailsDontMatch'})
                }

                if (!values[SIGNUP_FIELDS.TERMS_AND_CONDITIONS]?.includes(SIGNUP_OPTIONS.GENERAL_USE)) {
                    errors[SIGNUP_FIELDS.TERMS_AND_CONDITIONS] = intl.formatMessage({id: 'global.error.generalCondition'})
                }

                if (!values[SIGNUP_FIELDS.CAPTCHA_RESPONSE]) {
                    errors[SIGNUP_FIELDS.CAPTCHA_RESPONSE] = intl.formatMessage({id: 'global.error.required'})
                }

                if (!values[SIGNUP_FIELDS.BIRTH_DATE]) {
                    errors[SIGNUP_FIELDS.BIRTH_DATE] = intl.formatMessage({id: 'global.error.required'})
                } else if (!isValid(values[SIGNUP_FIELDS.BIRTH_DATE])) {
                    errors[SIGNUP_FIELDS.BIRTH_DATE] = intl.formatMessage({id: 'global.error.format'})
                }

                if (!values[SIGNUP_FIELDS.SOCIAL_SECURITY_NUMBER]) {
                    errors[SIGNUP_FIELDS.SOCIAL_SECURITY_NUMBER] = intl.formatMessage({id: 'global.error.required'})
                } else if (!isNssValid(values[SIGNUP_FIELDS.SOCIAL_SECURITY_NUMBER])) {
                    errors[SIGNUP_FIELDS.SOCIAL_SECURITY_NUMBER] = intl.formatMessage({id: 'global.error.format'})
                }

                return errors
            }}
            render={({handleSubmit, errors}) => (
                <>
                    <Popup
                        open={openSignup}
                        setOpen={setOpenSignup}
                        title={intl.formatMessage({id: 'login.form.signup.title'})}
                        onClose={() => recaptchaRef.current?.reset()}
                    >
                        <form onSubmit={handleSubmit}>
                            <Stack direction="column" spacing={5}>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="login.form.signup.subtitle"/>
                                </Typography>

                                <Stack direction="column" spacing={3}
                                       alignItems={!isDesktopOrLaptop ? 'center' : 'unset'}>
                                    <Stack direction={isDesktopOrLaptop ? 'row' : 'column'} spacing={3}
                                           width={isDesktopOrLaptop ? 'unset' : '100%'}>
                                        <PatternTextInput
                                            name={SIGNUP_FIELDS.INSURANCE_NUMBER}
                                            label={intl.formatMessage({id: 'login.form.signup.insuranceNumber.label'})}
                                            placeholder={intl.formatMessage({id: 'login.form.signup.insuranceNumber.placeholder'})}
                                            format={PATTERNS.NUM_ASSURE}
                                            popoverContent={
                                                <Typography variant="subtitle2">
                                                    <FormattedMessage id="login.form.signup.insuranceNumber.info"/>
                                                </Typography>
                                            }
                                            validate={required}
                                            maxWidth={isDesktopOrLaptop ? 'unset' : '100%'}
                                        />
                                        {isDesktopOrLaptop && <Box maxWidth="350px" width="100%"/>}
                                    </Stack>

                                    <Stack direction={isDesktopOrLaptop ? 'row' : 'column'} spacing={3}
                                           width={isDesktopOrLaptop ? 'unset' : '100%'}>
                                        <TextInput
                                            name={SIGNUP_FIELDS.LAST_NAME}
                                            label={intl.formatMessage({id: 'login.form.signup.lastName.label'})}
                                            placeholder={intl.formatMessage({id: 'login.form.signup.lastName.placeholder'})}
                                            maxWidth={isDesktopOrLaptop ? 'unset' : '100%'}
                                            validate={required}
                                        />
                                        <TextInput
                                            name={SIGNUP_FIELDS.FIRST_NAME}
                                            label={intl.formatMessage({id: 'login.form.signup.firstName.label'})}
                                            placeholder={intl.formatMessage({id: 'login.form.signup.firstName.placeholder'})}
                                            maxWidth={isDesktopOrLaptop ? 'unset' : '100%'}
                                            validate={required}
                                        />
                                    </Stack>

                                    <Stack
                                        direction={isDesktopOrLaptop ? 'row' : 'column'}
                                        width={isDesktopOrLaptop ? 'unset' : '100%'}
                                        spacing={3}
                                    >
                                        <NissTextInput
                                            name={SIGNUP_FIELDS.SOCIAL_SECURITY_NUMBER}
                                            label={intl.formatMessage({id: 'login.form.signup.socialSecurityNumber.label'})}
                                            placeholder={intl.formatMessage({id: 'login.form.signup.socialSecurityNumber.placeholder'})}
                                            popoverContent={
                                                <Typography variant="subtitle2">
                                                    <FormattedMessage id="login.form.signup.socialSecurityNumber.info"/>
                                                </Typography>
                                            }
                                            validate={required}
                                            format={toUpperCase}
                                            maxWidth={isDesktopOrLaptop ? 'unset' : '100%'}
                                        />
                                        <DatePickerInput
                                            name={SIGNUP_FIELDS.BIRTH_DATE}
                                            label={intl.formatMessage({id: 'login.form.signup.birthDate.label'})}
                                            validate={required}
                                            maxWidth={isDesktopOrLaptop ? 'unset' : '100%'}
                                        />
                                    </Stack>

                                    <Stack
                                        direction={isDesktopOrLaptop ? 'row' : 'column'}
                                        width={isDesktopOrLaptop ? 'unset' : '100%'}
                                        spacing={3}
                                    >
                                        <TextInput
                                            name={SIGNUP_FIELDS.EMAIL}
                                            type="email"
                                            label={intl.formatMessage({id: 'login.form.signup.email.label'})}
                                            placeholder={intl.formatMessage({id: 'login.form.signup.email.placeholder'})}
                                            popoverContent={
                                                <Typography variant="subtitle2">
                                                    <FormattedMessage id="login.form.signup.email.info"/>
                                                </Typography>
                                            }
                                            validate={required}
                                            maxWidth={isDesktopOrLaptop ? 'unset' : '100%'}
                                        />
                                        <TextInput
                                            name={SIGNUP_FIELDS.CONFIRM_EMAIL}
                                            label={intl.formatMessage({id: 'login.form.signup.confirmEmail'})}
                                            placeholder={intl.formatMessage({id: 'login.form.signup.email.placeholder'})}
                                            validate={required}
                                            maxWidth={isDesktopOrLaptop ? 'unset' : '100%'}
                                        />
                                    </Stack>

                                    <Stack
                                        direction={isDesktopOrLaptop ? 'row' : 'column'}
                                        width={isDesktopOrLaptop ? 'unset' : '100%'}
                                        spacing={3}
                                    >
                                        <TextInput
                                            name={SIGNUP_FIELDS.PASSWORD}
                                            label={intl.formatMessage({id: 'login.form.signup.password.label'})}
                                            type="password"
                                            helperChecksNeedeed
                                            maxWidth={isDesktopOrLaptop ? 'unset' : '100%'}
                                        />
                                        <TextInput
                                            name={SIGNUP_FIELDS.CONFIRM_PASSWORD}
                                            label={intl.formatMessage({id: 'login.form.signup.confirmPassword'})}
                                            type="password"
                                            maxWidth={isDesktopOrLaptop ? 'unset' : '100%'}
                                        />
                                    </Stack>
                                </Stack>

                                <Stack direction="column" spacing={3} maxWidth="700px" bgcolor="#e0e4ff" p="16px"
                                       borderRadius="5px" alignItems="center">
                                    <Box>
                                        <CheckboxInput
                                            name={SIGNUP_FIELDS.TERMS_AND_CONDITIONS}
                                            data={TERMS_AND_CONDITIONS_DATA as SelectOption[]}
                                            error={errors && errors[SIGNUP_FIELDS.TERMS_AND_CONDITIONS]}
                                        />
                                    </Box>
                                    <Box>
                                        <CaptchaInput name={SIGNUP_FIELDS.CAPTCHA_RESPONSE}
                                                      recaptchaRef={recaptchaRef}/>
                                    </Box>
                                </Stack>

                                <Box display="flex" justifyContent="center">
                                    <ButtonDefault variant="contained" type="submit"
                                                   onClick={handleSubmit}>{intl.formatMessage({id: 'global.button.confirm'})}</ButtonDefault>
                                </Box>
                            </Stack>
                        </form>
                    </Popup>
                    {confirmationArticle &&
                        <ConfirmationPopin
                            title={confirmationArticle.fields.title}
                            open={openConfirmation}
                            setOpen={setOpenConfirmation}
                            body={<div dangerouslySetInnerHTML={{__html: (confirmationArticle.fields.body)}}/>}
                            showActions={false}
                        />
                    }
                </>
            )}
        />
    )
}

const mapStateToProps = (state: any) => ({
    confirmationArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_CONFIRMATION]
})

export default connect(
    mapStateToProps,
    {
        getArticleByShortcut: cardiboxActions.articles.getByShortcut
    }
)(SignupForm)
