import React, {useContext, useEffect, useState} from 'react'
import {Stack} from '@mui/material'
import {useIntl} from 'react-intl'
import {connect} from 'react-redux'
import Loader from '../../../../components/Loader'
import ConnexionInfo from '../components/connexionInfo/ConnexionInfo'
import {RefreshContextEE} from '../services/refresh/RefreshContextEE'
import PageLayout from '../../../../components/PageLayout'
import {userSelector} from '../../userEE/userEESelectors'

const PersonalDataEEPage = (
	{
		// @ts-ignore
		user
	}
) => {
	const intl = useIntl()
	const { refreshing } = useContext(RefreshContextEE)
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		setLoading(true)

		setLoading(false)
	}, [refreshing])

	if (loading) {
		return <Loader />
	}

	return (
		<PageLayout title={intl.formatMessage({ id: 'personalInfo.title' })}>
			<Stack direction="column" spacing="24px">
				<ConnexionInfo user={user} />
			</Stack>
		</PageLayout>
	)
}


const mapStateToProps = (state: any) => ({
	user: userSelector(state),
})

const actions = {

}

export default connect(
	mapStateToProps, actions
)(PersonalDataEEPage)
