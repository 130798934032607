import React, { Dispatch, SetStateAction } from 'react'
import Popup from '../../../../components/Popup'
import { FormattedMessage, useIntl } from 'react-intl'
import { Typography } from '@mui/material'

type GuideGestionErrorPopinProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}

const GuideGestionErrorPopin: React.FC<GuideGestionErrorPopinProps> = (
	{
		open,
		setOpen
	}
) => {
	const intl = useIntl()
	return (
		<Popup open={open} setOpen={setOpen} title={intl.formatMessage({ id: 'simulation.guidePopin.title' })}>
			<Typography variant="body2">
				<FormattedMessage id="simulation.guidePopin.text" />
			</Typography>
		</Popup>
	)
}

export default GuideGestionErrorPopin