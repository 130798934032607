import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as souscriptionActions from '../../../webservices/souscription/souscriptionActions'
import * as ribCotisationActions from '../../../webservices/rib/cotisation/ribCotisationActions'
import { connect } from 'react-redux'
import PageLayout from '../../../../components/PageLayout'
import { useIntl } from 'react-intl'
import StepperForm from '../../../../components/StepperForm'
import { Form } from 'react-final-form'
import StepsButton from '../../../../components/StepsButton'
import { Stack } from '@mui/material'
import GarantieSouscriptionForm from '../components/GarantieSouscriptionForm'
import { getSouscriptionGaranties, isSouscriptionGarantiesLoading } from '../../../webservices/souscription/souscriptionSelector'
import { createSelector } from 'reselect'
import GarantieSouscriptionRibSection from '../components/GarantieSouscriptionRibSection'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import NoGaranties from '../components/NoGaranties'
import Loader from '../../../../components/Loader'
import { STEP1_CHECKBOX_NAME, STEP1_FORM, STEP2_FORM_NAME, STEP3_FORM_NAME } from '../services/souscriptionConstants'
import { getRibPrestationAssurePrincipal } from '../../../webservices/rib/ribSelector'
import { getRib as getRibCotisation } from '../../../webservices/rib/cotisation/ribCotisationSelector'
import { COTISATIONS, NATURE } from '../../../webservices/rib/ribConsts'
import IBAN from 'iban'
import GarantieSouscriptionEtape3Form from '../components/GarantieSouscriptionEtape3Form'
import ContratNotAvailable from '../../contrats/components/ContratNotAvailable'
import { ToastContext } from '../../../../components/toast/ToastContext'
import { ToastType } from '../../../../components/toast/toastConstants'
import LocalStorage from '../../../../business/storage/LocalStorage'
import ContratsService from '../../../../api/gerep/ContratsService'
import { positiveOrNaN } from '../../../../utils/numberUtils'

const isPrelevementSurSalaire = (rib: any) => rib && rib.nature !== NATURE.VIREMENT

const resetScroll = () => {
	window.scrollTo(0, 0)
}

const GarantieSouscriptionPage = (
	{
		// @ts-ignore
		loadSouscriptionGaranties,
		// @ts-ignore
		loadRibCotisation,
		// @ts-ignore
		garanties,
		// @ts-ignore
		isSouscriptionGarantiesLoading,
		// @ts-ignore
		rib
	}
) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)

	const [currentStep, setCurrentStep] = useState<number>(0)
	const [editRib, setEditRib] = useState<boolean>((rib && !isPrelevementSurSalaire(rib)) && !rib)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
	const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(true)
	const [openContratNotAvailable, setOpenContratNotAvailable] = useState<boolean>(false)
	const [submitLoading, setSubmitLoading] = useState<boolean>(false)

	const STEPS_LABELS = useMemo(() => [
		intl.formatMessage({ id: 'souscription.steps.1' }),
		intl.formatMessage({ id: 'souscription.steps.2' }),
		intl.formatMessage({ id: 'souscription.steps.3' })
	], [intl])

	const isGaranties = useMemo(() => garanties && garanties.length > 0, [garanties])

	const onSubmit = (values: any) => {
		const selectedOptionValue = values[STEP1_CHECKBOX_NAME] && values[STEP1_CHECKBOX_NAME].filter((value: any) => value?.selected)[0]
		const selectedOption = garanties.filter((garantie: any) => garantie.key === selectedOptionValue?.idGarantie[0])[0]

		const getPrixOption = () => {
			if (selectedOption.cumulative) {
				// le prix de l'option varie en fonction du nombre de bénéficiaires
				const selectedBenef = selectedOption?.beneficiaires || []
				if (selectedBenef.length === 0) {
					return positiveOrNaN(selectedOption.prix)
				}
				return selectedOption.beneficiaires.filter((benef: any) => selectedBenef.includes(benef.id)).reduce((acc: number, benef: any) => acc + benef.prix, 0)
			}
			// le prix placé sur l'option, qui est normalement le prix le plus bas
			return positiveOrNaN(selectedOption.prix)
		}

		const selectedBenef = selectedOption?.beneficiaires || []

		const step1Data = {
			fract: selectedOption.fract,
			echeance: selectedOption.echeance,
			options: [{
				beneficiaires: selectedBenef.filter((benef: any) => values[STEP1_CHECKBOX_NAME] && values[STEP1_CHECKBOX_NAME][0]?.beneficiaires?.includes(benef.numIndiv)).map((benef: any) => ({
					typBene: benef.typBene,
					numIndiv: benef.numIndiv
				})),
				total: getPrixOption(),
				...selectedOption
			}],
			editRib: editRib
		}

		const ribFile = values[STEP2_FORM_NAME.RIB]

		const ribInfos = editRib ? {
			iban: values[STEP2_FORM_NAME.IBAN],
			banque: values[STEP2_FORM_NAME.BANK],
			titulaire: values[STEP2_FORM_NAME.ACCOUNT_HOLDER]
		} : {}

		const newRibInfo = editRib ? {
			type: COTISATIONS,
			nature: NATURE.VIREMENT
		} : {}

		const ribInfoData = {
			rib:
				{
					...rib,
					...ribInfos,
					...newRibInfo
				}
		}

		const dataToSend = [{
			key: 'souscription',
			value: {
				...step1Data,
				...ribInfoData
			}
		},
			{
				key: 'rib',
				value: ribFile
			}
		]

		setSubmitLoading(true)
		return ContratsService.souscription(currentStep + 1, dataToSend, LocalStorage.getToken())
			.then(() => {
				if (currentStep < STEPS_LABELS.length - 1) {
					setCurrentStep(currentStep + 1)
					resetScroll()
				} else if (currentStep === STEPS_LABELS.length - 1) {
					setOpenConfirmation(true)
				}
			})
			.catch((e: any) => {
				if (!e.data || Object.keys(e.data).length === 0) {
					addToast(ToastType.ERROR, 'Une erreur technique est survenue. Votre demande n\'a pas pu aboutir.')
				} else if (e?.data && e?.data?._error) {
					addToast(ToastType.ERROR, e?.data?._error)
				}
				return e?.data
			})
			.finally(() => setSubmitLoading(false))
	}

	useEffect(() => {
		loadSouscriptionGaranties()
		loadRibCotisation()
		setIsGlobalLoading(false)
	}, [loadRibCotisation, loadSouscriptionGaranties])

	const checkboxInitial = useMemo(() => garanties ? (
		(garanties as Garantie[]).map((option) => ({
				idGarantie: [option.key],
				selected: false,
				beneficiaires: option.beneficiaires.filter((beneficiaire) => beneficiaire.garantieObligatoire)
					.map((beneficiaire) => (beneficiaire.numIndiv))
			})
		)) : [], [garanties])

	if (isSouscriptionGarantiesLoading || isGlobalLoading) {
		return <Loader />
	}

	const validateFirstStep = (values: any, errors: any, selectedOptions: any) => {
		if (selectedOptions.length === 0) {
			errors[STEP1_FORM] = intl.formatMessage({ id: 'souscription.form.error.options' })
		} else if (selectedOptions.length > 1) {
			errors[STEP1_FORM] = intl.formatMessage({ id: 'souscription.form.error.tooManyOptions' })
		} else if (selectedOptions[0].beneficiaires.length === 0) {
			errors[STEP1_FORM] = intl.formatMessage({ id: 'souscription.form.error.beneficiaires' })
		} else {
			const option: Garantie = garanties.find((option: any) => option.key === selectedOptions[0].idGarantie[0])
			if (option && option.nbBeneMax && option.nbBeneMax < selectedOptions[0].beneficiaires.length) {
				errors[STEP1_FORM] = intl.formatMessage({ id: 'souscription.form.error.tooManyBeneficiairies' }, { nbBeneMax: option.nbBeneMax })
			}
		}
	}

	const validateSecondStep = (values: any, errors: any) => {
		if (!isPrelevementSurSalaire(rib)) {
			if (!values[STEP2_FORM_NAME.IBAN]) {
				errors[STEP2_FORM_NAME.IBAN] = intl.formatMessage({ id: 'global.error.required' })
			} else if (!IBAN.isValid(values[STEP2_FORM_NAME.IBAN])) {
				errors[STEP2_FORM_NAME.IBAN] = intl.formatMessage({ id: 'souscription.form.error.iban' })
			}

			if (!values[STEP2_FORM_NAME.BANK]) {
				errors[STEP2_FORM_NAME.BANK] = intl.formatMessage({ id: 'global.error.required' })
			}
			if (!values[STEP2_FORM_NAME.ACCOUNT_HOLDER]) {
				errors[STEP2_FORM_NAME.ACCOUNT_HOLDER] = intl.formatMessage({ id: 'global.error.required' })
			}
		}

		if (editRib) {
			if (!values[STEP2_FORM_NAME.RIB] || values[STEP2_FORM_NAME.RIB].length === 0) {
				errors[STEP2_FORM_NAME.RIB] = intl.formatMessage({ id: 'global.error.required' })
			}
		}

		if (!values[STEP2_FORM_NAME.ACCEPT_SEPA] || values[STEP2_FORM_NAME.ACCEPT_SEPA].length === 0) {
			if (!editRib && isPrelevementSurSalaire(rib)) {
				errors[STEP2_FORM_NAME.ACCEPT_SEPA] = intl.formatMessage({ id: 'souscription.form.error.acceptPrelev' })
			} else {
				errors[STEP2_FORM_NAME.ACCEPT_SEPA] = intl.formatMessage({ id: 'souscription.form.error.acceptSepa' })
			}
		}
	}

	const validateThirdStep = (values: any, errors: any) => {
		if (!values[STEP3_FORM_NAME] || values[STEP3_FORM_NAME].length === 0) {
			errors[STEP3_FORM_NAME] = intl.formatMessage({ id: 'souscription.form.error.acceptConditions' })
		}
	}

	const initialValues = {
		[STEP1_CHECKBOX_NAME]: checkboxInitial,
		[STEP2_FORM_NAME.IBAN]: rib?.iban,
		[STEP2_FORM_NAME.BANK]: rib?.banque,
		[STEP2_FORM_NAME.ACCOUNT_HOLDER]: rib?.titulaire
	}

	return (
		<PageLayout title={intl.formatMessage({ id: 'souscription.title' })}>
			<>
				<Form
					initialValues={initialValues}
					validate={(values) => {
						const errors: any = {}
						const selectedOptions = values[STEP1_CHECKBOX_NAME] ? values[STEP1_CHECKBOX_NAME].filter((option: any) => option.selected) : []

						if (currentStep === 0) {
							validateFirstStep(values, errors, selectedOptions)
						} else if (currentStep === 1) {
							validateSecondStep(values, errors)
						} else if (currentStep === 2) {
							validateThirdStep(values, errors)
						}

						return errors
					}}
					onSubmit={onSubmit}
					render={({ handleSubmit, values, errors }) => {
						return (
							<>
								{isGaranties ?
									(
										<Stack direction="column" spacing="24px">
											<StepperForm steps={STEPS_LABELS} activeStep={currentStep} />

											{currentStep === 0 && (
												<GarantieSouscriptionForm
													garanties={garanties}
													values={values}
													errors={errors}
													onError={() => setOpenContratNotAvailable(true)}
												/>
											)}

											{currentStep === 1 && (
												<GarantieSouscriptionRibSection
													editRib={editRib}
													garanties={garanties}
													setEditRib={setEditRib}
													values={values}
													rib={rib}
													errors={errors}
												/>
											)}

											{currentStep === 2 && (
												<GarantieSouscriptionEtape3Form
													garanties={garanties}
													rib={rib}
													values={values}
													errors={errors}
													onError={() => setOpenContratNotAvailable(true)}
												/>
											)}

											<StepsButton
												step={currentStep}
												setStep={setCurrentStep}
												maxSteps={STEPS_LABELS.length}
												handleSubmit={handleSubmit}
												loading={submitLoading}
												labelNext={intl.formatMessage({ id: 'souscription.buttons.nextStep' })}
												labelEnd={intl.formatMessage({id:'souscription.buttons.validate'})}
												labelPrevious={intl.formatMessage({ id: 'souscription.buttons.previousStep' })}

											/>
										</Stack>
									) :
									<NoGaranties />
								}
							</>
						)
					}}
				/>
				<ConfirmationPopin
					title={intl.formatMessage({ id: 'souscription.title' })}
					open={openConfirmation}
					setOpen={setOpenConfirmation}
					body={intl.formatMessage({ id: 'souscription.confirmation.body' }, { br: <br /> })}

				/>
				<ContratNotAvailable open={openContratNotAvailable} setOpen={setOpenContratNotAvailable} />
			</>
		</PageLayout>
	)
}

const makeMapStateToProps = () => {
	const sel = createSelector([
		getSouscriptionGaranties
	], (garanties: any) => {
		return garanties.map((garantie: any) => {
			return {
				...garantie,
				selected: false,
				beneficiaires: garantie.beneficiaires.map((beneficiaire: any) => {
					return {
						...beneficiaire,
						selected: false
					}
				})
			}
		})
	})

	/*
	 * Le mode de paiement des options.
	 *
	 * Pour l'instant on suppose qu'il est identique pour toutes les options...
	 */
	const modePaieSelector = createSelector([
		getSouscriptionGaranties
	], (garanties) => garanties.reduce((acc: any, el: any) => el.modePaie || acc, null))

	// le rib
	const ribFromStateToProps = createSelector([
		getRibPrestationAssurePrincipal,
		getRibCotisation,
		modePaieSelector
	], (ribPrestation, ribCotisation, modePaie) => {
		// TODO enum
		if (modePaie === 1) {
			// prélèvement sur salaire
			return {
				type: COTISATIONS,
				nature: NATURE.CHEQUE
			}
		}
		if (modePaie === 2) {
			// prélèvement SEPA

			// on considère le rib cotisation si prélèvement bancaire
			if (ribCotisation && ribCotisation.nature === NATURE.VIREMENT) {
				const {
					clefIban,
					bban,
					intitule: titulaire,
					domiciliation: banque,
					type,
					nature
				} = ribCotisation
				return {
					iban: `${clefIban}${bban}`,
					titulaire,
					banque,
					type,
					nature
				}
			}

			// on considère le rib de prestation si prélèvement bancaire
			if (ribPrestation && ribPrestation.nature === NATURE.VIREMENT) {
				const {
					clefIban,
					bban,
					intitule: titulaire,
					domiciliation: banque,
					type,
					nature
				} = ribPrestation
				return {
					iban: `${clefIban}${bban}`,
					titulaire,
					banque,
					type,
					nature
				}
			}
		}
		return null
	})

	return (state: any) => ({
		garanties: sel(state),
		// @ts-ignore
		rib: ribFromStateToProps(state),
		// @ts-ignore
		isSouscriptionGarantiesLoading: isSouscriptionGarantiesLoading(state)
	})
}

const mappedActions = {
	loadSouscriptionGaranties: souscriptionActions.loadSouscriptionGaranties,
	loadRibCotisation: ribCotisationActions.getRibCotisation
}

export default connect(makeMapStateToProps, mappedActions)(GarantieSouscriptionPage)
