import React, {useEffect, useState} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import Loader from '../../../../components/Loader'
import {Divider, Stack, Typography} from '@mui/material'
import PageLayoutEE from '../../../../components/PageLayoutEE'
import {FormattedMessage, useIntl} from 'react-intl'
import PieChartDemographie from '../components/PieChartDemographie'
import PieChartPortabilite from '../components/PieChartPortabilite'
import GridAssuresChart from '../components/GridAssuresChart'
import GridDemographieChart from '../components/GridDemographiqueChart'
import {isDataKPILoading, santeDemographieSelector} from '../../data/dataSelectors'
import AnneeSelector from '../../components/AnneeSelector'
import FormatUtils from '../../../../utils/FormatUtils'
import ParityChart from '../components/ParityChart'
import EmptyResult from '../../components/EmptyResult'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import appConst from '../../../../constant/appConstants'


const ARTICLE_DEMOGRAPHIE_DONUT_CHART = 'demographie_donut_chart'
const ARTICLE_DEMOGRAPHIE_PIE_CHART = 'demographie_pie_chart'
const ARTICLE_DEMOGRAPHIE_EVOLUTION_GRAPGH = 'demographie_evolution_graph'
const ARTICLE_DEMOGRAPHIE_GENDER_RATIO = 'demographie_gender_ratio'
const ARTICLE_DEMOGRAPHIE_AGE_REPARTITION = 'demographie_age_repartition'

const SanteDemographiePageEE = (
	{
		// @ts-ignore
		loading,
		// @ts-ignore
		dataDemographie,
		// @ts-ignore
		getArticleByShortcut,
		// @ts-ignore
		demographieDonutChartArticle,
		// @ts-ignore
		demographiePieChartArticle,
		// @ts-ignore
		demographieEvolutionGraphArticle,
		// @ts-ignore
		demographieGenderRatioArticle,
		// @ts-ignore
		demographieAgeRepartitionArticle
	}
) => {

	const intl = useIntl()

	const { isMobile, isTabletPortrait } = useBreakpoints()
	const isSmallScreen = isMobile || isTabletPortrait
	const [annee, setAnnee] = useState<number>(Number(Object.keys(dataDemographie)[2]))
	const santeDemoAnneeN = dataDemographie[annee.toString()]
	const santeDemoAnneeNMoins1 = dataDemographie[(annee - 1).toString()]

	useEffect(() => {
		setAnnee(Number(Object.keys(dataDemographie)[2]))
	}, [dataDemographie])

	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_DEMOGRAPHIE_DONUT_CHART)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_DEMOGRAPHIE_PIE_CHART)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_DEMOGRAPHIE_EVOLUTION_GRAPGH)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_DEMOGRAPHIE_GENDER_RATIO)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_DEMOGRAPHIE_AGE_REPARTITION)
	}, [getArticleByShortcut])

	function handleChangeYear(value:number){
		setAnnee(value)
	}

	const nothingToShow = (demoAnneeX: any) => {
		return demoAnneeX !== undefined && demoAnneeX.totalAssures === 0
	}
	const demoAnneeSelector = () => {
		const selector = [
			{
				value: Object.keys(dataDemographie)[2],
				label: Object.keys(dataDemographie)[2]
			}
		]
		if (!nothingToShow(dataDemographie[Object.keys(dataDemographie)[1]])
			|| (nothingToShow(dataDemographie[Object.keys(dataDemographie)[1]]) && !nothingToShow(dataDemographie[Object.keys(dataDemographie)[0]]))) {
			selector.push(
				{
					value: Object.keys(dataDemographie)[1],
					label: Object.keys(dataDemographie)[1]
				}
			)
		}
		if (!nothingToShow(dataDemographie[Object.keys(dataDemographie)[0]])) {
			selector.push(
				{
					value: Object.keys(dataDemographie)[0],
					label: Object.keys(dataDemographie)[0]
				}
			)
		}
		return selector
	}



	if ((loading || santeDemoAnneeN === undefined)
		|| !demographieDonutChartArticle || !demographiePieChartArticle || !demographieEvolutionGraphArticle || !demographieGenderRatioArticle || !demographieAgeRepartitionArticle){
		return <Loader />
	} else {
		return (
			<PageLayoutEE
				title={intl.formatMessage({ id: 'sante.demographie.title' })}
				subtitle={santeDemoAnneeN.donneesActualisees && !nothingToShow(santeDemoAnneeN) && (
					intl.formatMessage({id: 'global.majData'}) + santeDemoAnneeN.donneesActualisees
				)}
			>
				<Stack direction="column" spacing="24px">
					<Stack direction="column" alignSelf="center" width={isSmallScreen ? '100%' : '50%'}>
						<AnneeSelector
							onChange={handleChangeYear}
							name="toggleYears"
							stateValue={annee}
							data={demoAnneeSelector()}
						/>
					</Stack>

					<Divider style={{ border: '1px solid #dedede', width: '100%' }} />

					{nothingToShow(santeDemoAnneeN)
						? (
							<EmptyResult
								nature="PAGE"
								message={
									<FormattedMessage
										id="global.noPageData"
										values={{titrePage:
												<Typography
													variant="body2"
													fontSize="0,875rem"
													fontWeight="bold"
													color="#929292"
													style={{ whiteSpace: 'nowrap' }}
												>
													{intl.formatMessage({id: 'sante.demographie.title'})}
												</Typography>
										}}
									/>
								}
							/>
						) : (
							<>
								<Stack direction="column" flex="1">
									<Stack
										direction={isMobile ? 'column' : 'row'}
										spacing="24px"
										justifyContent="space-around"
										alignItems="center"
										flex="1"
									>
										<Stack direction="column" alignItems="flex-start">
											<Typography alignSelf="start" fontWeight="500" variant="body1" fontSize="0.875rem">
												{intl.formatMessage({id: 'sante.demographie.demoNumbers.assures'})}
											</Typography>
											{dataDemographie[(annee).toString()].totalAssures > 0 ?
												<>
													<Stack direction="row" alignItems="baseline" spacing="2px">
														<Typography fontSize="2rem" fontWeight="600" color="#8ac11d" variant="body1">
															{intl.formatNumber(santeDemoAnneeN.totalAssures, {maximumFractionDigits: 0})}
														</Typography>
														<Typography variant="subtitle1" fontSize="0.813rem">
															en {santeDemoAnneeN.annee}
														</Typography>
													</Stack>
													{(santeDemoAnneeNMoins1 && santeDemoAnneeNMoins1.totalAssures > 0) &&
														<Stack direction="row" spacing="2px">
															<Typography fontWeight="600" variant="body1" fontSize="1rem">
																{intl.formatNumber(santeDemoAnneeNMoins1.totalAssures, {maximumFractionDigits: 0})}
															</Typography>
															<Typography alignSelf="end" variant="subtitle1" fontSize="0.813rem">
																en {santeDemoAnneeNMoins1.annee}
															</Typography>
														</Stack>
													}
												</> : <>
													<EmptyResult
														nature="NUMBER"
														message={intl.formatMessage({id: 'global.noKpiNumbers'})}
													/>
												</>
											}
										</Stack>
										<Stack direction="column" alignItems="flex-start">
											<Typography alignSelf="start" fontWeight="500" variant="body1" fontSize="0.875rem">
												{intl.formatMessage({id: 'sante.demographie.demoNumbers.ageMoyen'})}
											</Typography>
											{dataDemographie[(annee).toString()].ageMoyen > 0 ?
												<>
													<Stack direction="row" alignItems="baseline" spacing="2px">
														<Typography fontSize="2rem" fontWeight="600" color="#8ac11d" variant="body1">
															{FormatUtils.formatNumber(santeDemoAnneeN.ageMoyen, 1)} ans
														</Typography>
														<Typography variant="subtitle1" fontSize="0.813rem">
															en {santeDemoAnneeN.annee}
														</Typography>
													</Stack>
													{(santeDemoAnneeNMoins1 && santeDemoAnneeNMoins1.ageMoyen > 0) &&
														<Stack direction="row" alignItems="baseline" spacing="2px">
															<Typography fontWeight="600" variant="body1" fontSize="1rem">
																{FormatUtils.formatNumber(santeDemoAnneeNMoins1.ageMoyen, 1)} ans
															</Typography>
															<Typography variant="subtitle1" fontSize="0.813rem">
																en {santeDemoAnneeNMoins1.annee}
															</Typography>
														</Stack>
													}
												</> : <>
													<EmptyResult
														nature="NUMBER"
														message={intl.formatMessage({id: 'global.noKpiNumbers'})}
													/>
												</>
											}
										</Stack>
									</Stack>
									{santeDemoAnneeN.dateDemoGlobale
										&& santeDemoAnneeN.donneesActualisees !== santeDemoAnneeN.dateDemoGlobale
										&&
											<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" pt="4px" mb="-12px">
												{intl.formatMessage({id: 'global.majData'}) + santeDemoAnneeN.dateDemoGlobale}
											</Typography>
									}
								</Stack>

								<Divider style={{border: '1px solid #dedede', width: '100%'}}/>

								<Stack direction={isSmallScreen ? 'column' : 'row'} spacing="24px">
									<Stack
										direction="row"
										flex="1"
										sx={{
											borderRight: isSmallScreen ? undefined : '1px solid rgb(222, 222, 222)',
											borderBottom: isSmallScreen ? '1px solid rgb(222, 222, 222)' : undefined,
										}}
										pb={isSmallScreen ? '24px' : 0}
									>
										<Stack direction="column" flex="1">
											<PieChartDemographie
												id_title={demographieDonutChartArticle && demographieDonutChartArticle.fields.title }
												idChart="sante_demographie_beneficaire"
												dataChart={[
													santeDemoAnneeN.totalAssures,
													santeDemoAnneeN.conjoint,
													santeDemoAnneeN.enfants
												]}
											/>
											{santeDemoAnneeN.dateDemoGlobale
												&& santeDemoAnneeN.donneesActualisees !== santeDemoAnneeN.dateDemoGlobale
												&&
													<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" mr="8px" pt="4px">
														{intl.formatMessage({id: 'global.majData'}) + santeDemoAnneeN.dateDemoGlobale}
													</Typography>
											}
										</Stack>
									</Stack>
									<Stack direction="column" flex="1">
										<PieChartPortabilite
											id_title={demographiePieChartArticle && demographiePieChartArticle.fields.title }
											idChart="sante_demographie_portabilite"
											dataChart={[santeDemoAnneeN.enPoste, santeDemoAnneeN.enPortabilite]}
										/>
										{santeDemoAnneeN.dateDemoGlobale
											&& santeDemoAnneeN.donneesActualisees !== santeDemoAnneeN.dateDemoGlobale
											&&
												<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" pt="4px">
													{intl.formatMessage({id: 'global.majData'}) + santeDemoAnneeN.dateDemoGlobale}
												</Typography>
										}
									</Stack>
								</Stack>

								<Divider style={{border: '1px solid #dedede', width: '100%'}}/>

								<Stack direction="column" flex="1">
									<GridAssuresChart
										id_title={demographieEvolutionGraphArticle && demographieEvolutionGraphArticle.fields.title}
										idChart="sante_demographie_evolutionNbAssures"
										dataChart={santeDemoAnneeN.dataMonth}
									/>
									{santeDemoAnneeN.dateDemoGlobale
										&& santeDemoAnneeN.donneesActualisees !== santeDemoAnneeN.dateDemoGlobale
										&&
											<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" pt="4px">
												{intl.formatMessage({id: 'global.majData'}) + santeDemoAnneeN.dateDemoGlobale}
											</Typography>
									}
								</Stack>

								<Divider style={{border: '1px solid #dedede', width: '100%'}}/>

								<Stack direction={isSmallScreen ? 'column' : 'row'} spacing="24px" flex={12}>
									<Stack
										direction="column"
										flex="1"
										sx={{
											borderRight: isSmallScreen ? undefined : '1px solid rgb(222, 222, 222)',
											borderBottom: isSmallScreen ? '1px solid rgb(222, 222, 222)' : undefined,
										}}
									>
										<Stack direction="column" flex="1">
											<Typography
												textAlign="left"
												variant="h2"
												fontSize="1.125rem"
												dangerouslySetInnerHTML={{ __html: demographieGenderRatioArticle && demographieGenderRatioArticle.fields.title }}
											/>
											<Stack
												direction={isMobile ? 'column' : 'row'}
												paddingBottom={isMobile ? '16px' : undefined}
												spacing="12px"
												alignItems="flex-end"
												height="100%"
											>
												{santeDemoAnneeN.totalAssures > 0 ?
													<>
														<ParityChart
															taux={santeDemoAnneeN.tauxFemmeMoyen}
															nbPersonne={santeDemoAnneeN.totalAssures > 0 ? santeDemoAnneeN.nbFemmeMoyen : undefined}
															color="#B5BBFF"
															woman={true}
														/>
														<ParityChart
															taux={santeDemoAnneeN.tauxHommeMoyen}
															nbPersonne={santeDemoAnneeN.totalAssures > 0 ? santeDemoAnneeN.nbHommeMoyen : undefined}
															color="#E96E11"
															woman={false}
														/>
													</>
													:
													<Stack alignItems="center" direction="column" flex="1">
														<EmptyResult
															nature="GRAPH"
															message={
																<FormattedMessage
																	id="global.noGraphData"
																	values={{titreGraph:
																			<Typography
																				variant="body2"
																				fontSize="0,875rem"
																				fontWeight="bold"
																				color="#929292"
																				dangerouslySetInnerHTML={{ __html: demographieGenderRatioArticle && demographieGenderRatioArticle.fields.title}}
																			/>
																	}}
																/>
															}
														/>
													</Stack>
												}
											</Stack>
										</Stack>
										{santeDemoAnneeN.dateDemoGlobale
											&& santeDemoAnneeN.donneesActualisees !== santeDemoAnneeN.dateDemoGlobale
											&&
												<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" mr="8px" pt="4px">
													{intl.formatMessage({id: 'global.majData'}) + santeDemoAnneeN.dateDemoGlobale}
												</Typography>
										}
									</Stack>
									<Stack direction="column" flex="1">
										<Stack direction="row" flex="1">
											<GridDemographieChart
												id_title={demographieAgeRepartitionArticle && demographieAgeRepartitionArticle.fields.title}
												idChart="sante_demographie_ageAssurer"
												dataChart={santeDemoAnneeN.dataAge}
											/>
										</Stack>
										{santeDemoAnneeN.dateHommeFemme
											&& santeDemoAnneeN.donneesActualisees !== santeDemoAnneeN.dateHommeFemme
											&&
												<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" pt="4px">
													{intl.formatMessage({id: 'global.majData'}) + santeDemoAnneeN.dateHommeFemme}
												</Typography>
										}
									</Stack>
								</Stack>
							</>
						)
					}
				</Stack>
			</PageLayoutEE>
		)
	}
}

const actions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}
const mapStateToProps = (state: any) => {

	return ({
		loading: isDataKPILoading(state),
		dataDemographie: santeDemographieSelector(state),
		demographieDonutChartArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_DEMOGRAPHIE_DONUT_CHART],
		demographiePieChartArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_DEMOGRAPHIE_PIE_CHART],
		demographieEvolutionGraphArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_DEMOGRAPHIE_EVOLUTION_GRAPGH],
		demographieGenderRatioArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_DEMOGRAPHIE_GENDER_RATIO],
		demographieAgeRepartitionArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_DEMOGRAPHIE_AGE_REPARTITION]
	})
}
export default compose(
	connect(mapStateToProps, actions)
)(SanteDemographiePageEE)

