import appConst from '../../../../constant/appConstants'

export const LIEN_ITELIS = 'http://partenaires.itelis.fr/partners?access_token=xdvzdfsp&intro=1'
export const SIMULATEUR_GA_CATEGORY = 'Simulateur'
export const SIMULATEUR_GA_ACTION = 'Estimation remboursement'

export const SERVICES_BIEN_ETRE_GA_CATEGORY = 'Services bien-etre'

export const ACCES_RESEAU_GA_ACTION = 'Accès au réseau'

export const TELECONSULTATION_GA_LIBELLE_SANTECLAIR = 'Santéclair'
export const TELECONSULTATION_GA_LIBELLE_ITELIS = 'Itélis'
/*
 automedication
 owop
 jalonnement
 owih
 nosmoking
 nutriclair
 devisdentaire
 */
export const getSanteClairUrl = (token: any, idAppli: any) => (
	appConst.santeClairUrl.replace('___idApplication___', idAppli) + token
)

export enum SEND_DOCUMENTS_FIELDS_VALUES {
	POLE_EMPLOI = 'pole_emploi',
	CERTIFICAT_SCOLARITE = 'certificat_scolarite',
	FACTURE = 'facture',
	RELEVE_SECU = 'releve_secu',
	RELEVE_AUTRE = 'releve_autre',
	DEVIS = 'devis',
	BIRTH_CERTIFICATE = 'birth_certificate',
	AUTRE = 'autre',
}
