import React, {useEffect, useState} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import Loader from '../../../../components/Loader'
import {Divider, Stack, Typography} from '@mui/material'
import PageLayout from '../../../../components/PageLayout'
import {FormattedMessage, useIntl} from 'react-intl'
import TextInput from '../../../../components/form/TextInput'
import {CONTACTER_GEREP_FIELDS, validContacterGerepFields} from './contacterGerepConstants'
import {Form} from 'react-final-form'
import CheckboxInput from '../../../../components/form/CheckboxInput'
import ButtonDefault from '../../../../components/ButtonDefault'
import {getListCompany} from '../../listCompany/ListCompanySelector'
import SelectInput from '../../../../components/form/SelectInput'
import {contacterGerep, getListContactsSociete} from '../../data/dataActions'
import {listCdcContacterGerep} from '../../data/dataSelectors'
import {oneCheckBoxListBool} from '../../../../univers/ui/preaffiliation/preaffiliationUtils'
import {reset} from '../../../../univers/ui/preaffiliation/services/preaffiliationActions'
import Popup from '../../../../components/Popup'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import {createSelector} from 'reselect'

type ContacterPageEEProps = {
    societes: any
    listChargesClientele: any
}

const ContacterPageEE: React.FC<ContacterPageEEProps> = (
    {
        societes,
        listChargesClientele,
        // @ts-ignore
        getListContactsSociete,
        // @ts-ignore
        contacterGerep
    }
) => {
	useEffect(() => {
		setLoading(false)
	}, [])

	const intl = useIntl()
    const {isMobile, isTabletPortrait} = useBreakpoints()

    const [loading, setLoading] = useState<boolean>(true)
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

    useEffect(() => {
    })

    const onSubmit = (values: any) => {
        contacterGerep(
            {
                idSociete: values[CONTACTER_GEREP_FIELDS.COMPANY],
                object: values[CONTACTER_GEREP_FIELDS.OBJECT],
                message: values[CONTACTER_GEREP_FIELDS.BODY],
                sendCopy: oneCheckBoxListBool(values[CONTACTER_GEREP_FIELDS.SEND_COPY])
            }
        ).then(() => setOpenConfirmation(true))
    }

    const sliceListCDC = () => {
        const sousListes = []
        const nbCdCParLigne = isMobile ? 1 : isTabletPortrait ? 2 : 3
        for (let i = 0; i < listChargesClientele.length; i += nbCdCParLigne) {
            sousListes.push(listChargesClientele.slice(i, i + nbCdCParLigne))
        }
        return sousListes
    }

    if (loading) {
        return <Loader/>
    }

    return (
        <PageLayout title={intl.formatMessage({id: 'contacterGerep.title'})}>
            <Form
                validate={(values: any) => {
                    const errors: any = {}
                    {
                        validContacterGerepFields(values, errors, intl)
                    }
                    return errors
                }}
                onSubmit={onSubmit}
                render={({handleSubmit, values, errors}) => {
                    return (
                        <Stack direction="column" spacing="24px">
                            <Typography fontSize="1,125rem" fontWeight="bold">
                                {intl.formatMessage({id: 'contacterGerep.assureur'})}
                            </Typography>

                            <SelectInput
                                name={CONTACTER_GEREP_FIELDS.COMPANY}
                                label={intl.formatMessage({id: 'contacterGerep.mail.company'})}
                                data={societes.map((societe: any) => ({value: societe.numindiv, label: societe.nom}))}
                                onChange={(e: any) => getListContactsSociete(e)}
                                mandatory
                            />

                            {values[CONTACTER_GEREP_FIELDS.COMPANY] && (
                                listChargesClientele.length > 0 ? (
                                    <Stack direction="column" spacing="16px">
                                        {sliceListCDC().map((sousListe: any) =>
                                            <Stack direction="row">
                                                {sousListe.map((cdc: any) =>
                                                    <Stack direction="column" pl="24px" flex="1">
                                                        <Typography fontSize="1rem" variant="body1">
                                                            {cdc.nom} {cdc.prenom}
                                                        </Typography>

                                                        <Typography fontSize="0.875rem" variant="subtitle2">
                                                            {cdc.mail}
                                                        </Typography>
                                                    </Stack>
                                                )}
                                            </Stack>
                                        )}
									</Stack>
								) : (
                                    <Typography fontSize="1rem" variant="body1">
                                        {intl.formatMessage({id: 'contacterGerep.noCDC'})}
                                    </Typography>
                                )
                            )}

                            <Divider/>

                            <Stack direction="column" spacing="12px">
                                <Typography fontSize="1,125rem" fontWeight="bold">
                                    {intl.formatMessage({id: 'contacterGerep.mail.title'})}
                                </Typography>

                                <TextInput
                                    name={CONTACTER_GEREP_FIELDS.OBJECT}
                                    label={intl.formatMessage({id: 'contacterGerep.mail.objet'})}
                                    maxWidth="unset"
                                    mandatory
                                />

                                <Typography fontSize="0.875rem">
                                    {intl.formatMessage({id : 'contacterGerep.mail.body'})}
								</Typography>

                                <TextInput
                                    name={CONTACTER_GEREP_FIELDS.BODY}
                                    style={{resize: 'vertical'}}
                                    rows={5}
                                    maxWidth="unset"
                                />

                                <CheckboxInput
                                    name={CONTACTER_GEREP_FIELDS.SEND_COPY}
                                    data={[{
                                        label: intl.formatMessage({id: 'contacterGerep.mail.sendCopy'}),
                                        value: CONTACTER_GEREP_FIELDS.SEND_COPY
                                    }]}
                                    // defaultValues={[CONTACTER_GEREP_FIELDS.SEND_COPY]}
                                />

                                <Stack direction="column" paddingTop="24px" spacing="12px">
                                    <ButtonDefault
                                        variant="contained"
                                        onClick={handleSubmit}
                                        sx={{
                                            alignSelf: 'center',
                                            width: '25%'
                                        }}
                                        disabled={listChargesClientele.length === 0}
                                    >
                                        {intl.formatMessage({id: 'contacterGerep.mail.sendButton'})}
                                    </ButtonDefault>

                                    <Typography variant="body2" fontSize="0.875rem">
                                        <FormattedMessage id="global.mandatory"/>
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    )
                }}
			/>
            <Popup
                title={intl.formatMessage({id: 'contacterGerep.title'})}
                open={openConfirmation}
                setOpen={setOpenConfirmation}
            >
                <Stack direction="column" spacing="16px" alignItems="center">
                    <Typography
                        variant="body2"
                        fontSize="1rem"
                        whiteSpace="pre-line"
                    >
                        {intl.formatMessage({id: 'contacterGerep.popin.success'}, {br: <br/>})}
                    </Typography>

                    <ButtonDefault
                        variant="contained"
                        onClick={() => {
                            reset()
                            setOpenConfirmation(false)
                        }}
					>
                        {intl.formatMessage({id: 'contacterGerep.popin.close'})}
                    </ButtonDefault>
                </Stack>
            </Popup>
        </PageLayout>
    )
}

const actions = {
    getListContactsSociete,
    contacterGerep
}
const mapStateToProps = (state: any) => {
    const listCompanyKpi = getListCompany(state)
    const getListCompanyPageContacter = () => {
        if (listCompanyKpi && listCompanyKpi.length > 1) {
            return listCompanyKpi.slice(1, listCompanyKpi.length)
        }
        return listCompanyKpi
    }
    return ({
        societes: state.listCompany && getListCompanyPageContacter(),
        listChargesClientele: listCdcContacterGerep(state)
    })
}
export default compose(
    connect(mapStateToProps, actions)
)(ContacterPageEE)

