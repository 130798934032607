export enum LOGIN_FIELDS {
	LOGIN = 'login',
	PASSWORD = 'password',
	REMEMBER_ME = 'rememberMe',
	CAPTCHA_RESPONSE = 'captcharesponse'
}

export enum FORCE_RESET_FIELDS {
	OLD_PASSWORD = 'password',
	NEW_PASSWORD = 'newPassword',
	CONFIRM_PASSWORD = 'confirmPassword'
}

export enum SIGNUP_FIELDS {
	INSURANCE_NUMBER = 'idAdherent',
	FIRST_NAME = 'prenom',
	LAST_NAME = 'nom',
	SOCIAL_SECURITY_NUMBER = 'niss',
	BIRTH_DATE = 'dateNaissance',
	EMAIL = 'email',
	MEMBER_EMAIL = 'mailAdherent',
	CONFIRM_EMAIL = 'confirmEmail',
	PASSWORD = 'password',
	CONFIRM_PASSWORD = 'confirmPassword',
	CAPTCHA_RESPONSE = 'captcharesponse',
	TERMS_AND_CONDITIONS = 'newsletter',
	GLOBAL = 'globalError'
}

export const generalUseUrl = 'https://services.gerep.fr/blobs/medias/s/383736018900000a/Charte_utilisation_Application_IRIS_FINAL_(2).pdf'

export enum STORAGE_KEYS {
	token = 'storage_key',
}

export const SIGNUP_OPTIONS = {
	NEWSLETTER: 'newsletter',
	GENERAL_USE: 'generalUse'
}

export const FIELD_PATTERN = {
	EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*\-;\/_]).{12,32}$/,
	LENGTH: /^.{12,32}$/,
	UPPER: /[A-Z]/,
	LOWER: /[a-z]/,
	DIGIT: /\d/,
	SYMBOL: /[#?!@$%^&*\-\\;\/_]/
}
