import React from 'react'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import ButtonLink from '../../../../components/ButtonLink'
import Icon from '@mdi/react'
import { mdiEye, mdiAccountOff, mdiAccountPlus, mdiAccountEye } from '@mdi/js'
import { FormattedMessage } from 'react-intl'
import { SECONDARY_PATH } from '../../../../constant/path'
import { useHistory } from 'react-router-dom'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

const PaymentActions = () => {
	const history = useHistory()
	const { isMobile, isTabletPortrait } = useBreakpoints()

	return (
		<Stack direction="column" spacing={isMobile || isTabletPortrait ? '18px' : '14px'}>
			<Stack direction={isMobile || isTabletPortrait ? 'column' : 'row'} spacing={isMobile || isTabletPortrait ? '18px' : '14px'}>
				<Box flex={1}>
					<ButtonLink variant="contained" startIcon={<Icon size="20px" path={mdiAccountEye} />} onClick={() => history.push(SECONDARY_PATH.ACCOUNT_BENEFICIARY)}
					            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
					>
						<FormattedMessage id="paymentCard.buttons.beneficiaries" />
					</ButtonLink>
				</Box>
				<Box flex={1}>
					<ButtonLink variant="contained" startIcon={<Icon size="20px" path={mdiEye} />} onClick={() => history.push(SECONDARY_PATH.ACCOUNT_PERSONAL_DATA)}
					            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
					>
						<FormattedMessage id="paymentCard.buttons.personalData" />
					</ButtonLink>
				</Box>
			</Stack>
			<Stack direction={isMobile || isTabletPortrait ? 'column' : 'row'} spacing={isMobile || isTabletPortrait ? '18px' : '14px'}>
				<Box flex={1}>
					<ButtonLink variant="contained" startIcon={<Icon size="20px" path={mdiAccountPlus} />} onClick={() => history.push(SECONDARY_PATH.CONTACT_ADD_BENEFICIARY)}
					            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
					>
						<FormattedMessage id="beneficiary.buttons.add" />
					</ButtonLink>
				</Box>
				<Box flex={1}>
					<ButtonLink variant="contained" startIcon={<Icon size="20px" path={mdiAccountOff} />} onClick={() => history.push(SECONDARY_PATH.CONTACT_DELETE_BENEFICIARY)}
					            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
					>
						<FormattedMessage id="beneficiary.buttons.delete" />
					</ButtonLink>
				</Box>
			</Stack>
		</Stack>
	)
}

export default PaymentActions
