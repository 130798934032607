import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material'
import ToggleInput from '../../../../components/form/ToggleInput'
import { POSTES_NAMES, SIMULATOR_FIELD } from '../services/simulatorConstants'
import { mdiCurrencyEur, mdiEarHearing, mdiGlasses, mdiHospitalBuilding, mdiInformationOutline, mdiStethoscope, mdiToothOutline } from '@mdi/js'
import SelectInput from '../../../../components/form/SelectInput'
import { FormattedMessage, useIntl } from 'react-intl'
import Icon from '@mdi/react'
import ButtonDefault from '../../../../components/ButtonDefault'
import TextInput from '../../../../components/form/TextInput'
import { simulate } from '../../../../api/gerep/Simulateur'
import LocalStorage from '../../../../business/storage/LocalStorage'
import { ToastContext } from '../../../../components/toast/ToastContext'
import { ToastType } from '../../../../components/toast/toastConstants'
import SimulationChart from './SimulationChart'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import ReactGA from 'react-ga'
import { SIMULATEUR_GA_ACTION, SIMULATEUR_GA_CATEGORY } from '../../../../utils/servicesUtils'

type SimulationFormProps = {
	matrix?: any
}

const MAX_DEPENSE = 10000

const POSTES_DATA: SelectOption[] = [
	{
		value: POSTES_NAMES.SOINS_COURANTS,
		label: POSTES_NAMES.SOINS_COURANTS,
		icon: mdiStethoscope
	},
	{
		value: POSTES_NAMES.DENTAIRE,
		label: POSTES_NAMES.DENTAIRE,
		icon: mdiToothOutline
	},
	{
		value: POSTES_NAMES.OPTIQUE,
		label: POSTES_NAMES.OPTIQUE,
		icon: mdiGlasses
	},
	{
		value: POSTES_NAMES.AIDES_AUDITIVES,
		label: POSTES_NAMES.AIDES_AUDITIVES,
		icon: mdiEarHearing
	},
	{
		value: POSTES_NAMES.HOSPITALISATION,
		label: POSTES_NAMES.HOSPITALISATION,
		icon: mdiHospitalBuilding
	}
]

const SimulationForm: React.FC<SimulationFormProps> = (
	{
		matrix
	}
) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)
	const { isMobile } = useBreakpoints()

	const [simulationData, setSimulationData] = useState<any>()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const onSubmit = (values: any) => {
		const {
			amount,
			poste,
			typeActe,
			sousTypeActe
		} = values


		ReactGA.event({
			category: SIMULATEUR_GA_CATEGORY,
			action: SIMULATEUR_GA_ACTION,
			label: 'Calcul',
			value: Number(amount)
		})

		const selectedOption = matrix[poste][typeActe].find((option: any) => option.sousTypeActe === sousTypeActe)

		const cleanedDepense = amount && amount.replace(',', '.')
		setIsLoading(true)
		return simulate(LocalStorage.getToken(), {
			idSimulation: selectedOption.id,
			depense: cleanedDepense
		})
			.then(simulation => {
				setSimulationData(
					{
						depense: cleanedDepense,
						hypotheses: selectedOption?.texteAval,
						sousTypeActe,
						...simulation
					}
				)
			})
			.catch(() => addToast(ToastType.ERROR, 'global.error.occurred'))
			.finally(() => setIsLoading(false))
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={(values: any) => {
				const errors: any = {}
				if (!values[SIMULATOR_FIELD.AMOUNT]) {
					errors[SIMULATOR_FIELD.AMOUNT] = intl.formatMessage({ id: 'global.error.required' })
				} else if (parseFloat(values[SIMULATOR_FIELD.AMOUNT]) <= 0 || parseFloat(values[SIMULATOR_FIELD.AMOUNT]) > MAX_DEPENSE) {
					errors[SIMULATOR_FIELD.AMOUNT] = intl.formatMessage({ id: 'global.error.format' })
				}
				return errors
			}}
			render={({ handleSubmit, values, form: { reset } }) => (
				<form onSubmit={handleSubmit}>
					<Stack direction="column" spacing="24px">
						<Box display="flex" flex={1}>
							{!isMobile ?
								<ToggleInput
									label={
										<Typography variant="body2">
											<FormattedMessage id="simulation.label.poste" />
										</Typography>
									}
									name={SIMULATOR_FIELD.POSTE}
									data={POSTES_DATA}
									onChange={(e) => {
										ReactGA.event({
											category: SIMULATEUR_GA_CATEGORY,
											action: SIMULATEUR_GA_ACTION,
											label: e.replace(' ', '-')
										})
										reset()
										setSimulationData(undefined)
									}}
								/> :
								<SelectInput
									label={<FormattedMessage id="simulation.label.posteMobile" />}
									name={SIMULATOR_FIELD.POSTE}
									data={POSTES_DATA}
									onChange={(e) => {
										ReactGA.event({
											category: SIMULATEUR_GA_CATEGORY,
											action: SIMULATEUR_GA_ACTION,
											label: e.replace(' ', '-')
										})
										reset()
										setSimulationData(undefined)
									}}
								/>
							}
						</Box>
						{values[SIMULATOR_FIELD.POSTE] && (
							<Stack direction="column" spacing="12px">
								<SelectInput
									label={intl.formatMessage({ id: 'simulation.label.typeSoin' })}
									name={SIMULATOR_FIELD.TYPE_ACTE}
									data={Object.keys(matrix[values[SIMULATOR_FIELD.POSTE]]).map((key: string) => ({
										value: key,
										label: key
									}))}
								/>
								{values[SIMULATOR_FIELD.TYPE_ACTE] &&
									matrix[values[SIMULATOR_FIELD.POSTE]] &&
									matrix[values[SIMULATOR_FIELD.POSTE]][values[SIMULATOR_FIELD.TYPE_ACTE]] &&
									<Stack direction="row" spacing="8px">
										<Box width="20px" height="20px">
											<Icon path={mdiInformationOutline} size="20px" color="#232323" />
										</Box>
										<Typography variant="body2">
											{matrix[values[SIMULATOR_FIELD.POSTE]][values[SIMULATOR_FIELD.TYPE_ACTE]][0]?.texteAmontNiveau2 || ''}
										</Typography>
									</Stack>
								}
							</Stack>
						)}
						{values[SIMULATOR_FIELD.TYPE_ACTE] && (
							<Stack direction="column" spacing="12px">
								<SelectInput
									label={intl.formatMessage({ id: 'simulation.label.sousTypeSoin' })}
									name={SIMULATOR_FIELD.SOUS_TYPE_ACTE}
									data={matrix[values[SIMULATOR_FIELD.POSTE]][values[SIMULATOR_FIELD.TYPE_ACTE]].map((item: any) => ({
										value: item.sousTypeActe,
										label: item.sousTypeActe
									}))}
								/>
								{values[SIMULATOR_FIELD.SOUS_TYPE_ACTE] && matrix[values[SIMULATOR_FIELD.POSTE]][values[SIMULATOR_FIELD.TYPE_ACTE]].find((item: any) => item.sousTypeActe === values[SIMULATOR_FIELD.SOUS_TYPE_ACTE])?.texteAmontNiveau3 &&
									<Stack direction="row" spacing="8px">
										<Box width="20px" height="20px">
											<Icon path={mdiInformationOutline} size="20px" color="#232323" />
										</Box>
										<Typography variant="body2">
											{matrix[values[SIMULATOR_FIELD.POSTE]][values[SIMULATOR_FIELD.TYPE_ACTE]].find((item: any) => item.sousTypeActe === values[SIMULATOR_FIELD.SOUS_TYPE_ACTE])?.texteAmontNiveau3 || ''}
										</Typography>
									</Stack>
								}
							</Stack>
						)}
						{values[SIMULATOR_FIELD.SOUS_TYPE_ACTE] && (
							<Stack direction="column" spacing="24px">
								<Box display="flex" justifyContent="center">
									<TextInput
										numberOnly
										name={SIMULATOR_FIELD.AMOUNT}
										label={intl.formatMessage({ id: 'simulation.label.amount' })}
										rightIcon={<Icon path={mdiCurrencyEur} size="20px" color="#606060" />}
										style={{ textAlign: 'end', paddingRight: '8px' }}
									/>
								</Box>
								<Box display="flex" justifyContent="center">
									<ButtonDefault variant="contained" type="submit" onClick={handleSubmit}>
										{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="simulation.button.calculate" />}
									</ButtonDefault>
								</Box>
							</Stack>
						)}
						{simulationData && (
							<Stack direction="column" spacing="24px">
								<Divider />
								<SimulationChart {...simulationData} />
							</Stack>
						)}
					</Stack>
				</form>
			)}
		/>
	)
}

export default SimulationForm
