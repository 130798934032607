import GerepAPI from './GerepAPI'

export default class CarteTPService {

	static getCarteTP(token) {
		return GerepAPI.get('/carteTP', null, token)
	}

	static downloadCarteTP(numero, annee, format, token, callBack, callBackError) {
		return GerepAPI.downloadLoader('/carteTP/download', {
			numero,
			annee,
			format
		}, token, callBack, callBackError)
	}

	static getTicket(token) {
		return GerepAPI.get('/ticket/carteTp', null, token)
	}

	static getNewToken(token) {
		return GerepAPI.get('/redirect/newToken', {token}, null)
	}
}
