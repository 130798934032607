import React, {Dispatch, SetStateAction, useContext, useState} from 'react'
import Popup from '../../../../../components/Popup'
import {FormattedMessage, useIntl} from 'react-intl'
import {Form} from 'react-final-form'
import {Box, CircularProgress, Stack, Typography} from '@mui/material'
import TextInput from '../../../../../components/form/TextInput'
import {MODIFY_PASSWORD_FIELDS} from '../../services/personalInfoConstants'
import ButtonDefault from '../../../../../components/ButtonDefault'
import {ToastType} from '../../../../../components/toast/toastConstants'
import {ToastContext} from '../../../../../components/toast/ToastContext'
import LocalStorage from '../../../../../business/storage/LocalStorage'
import ConfirmPasswordPopin from './ConfirmPasswordPopin'
import UserServiceEE from '../../../../../api/gerepEe/UserServiceEE'

type ModifyPasswordProps = {
	openForm: boolean
	setOpenForm: Dispatch<SetStateAction<boolean>>
}

const ModifyPassword: React.FC<ModifyPasswordProps> = ({ setOpenForm, openForm }) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)
	const [openConfirm, setOpenConfirm] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const onSubmit = (values: Record<string, string>) => {
		setIsLoading(true)
		return UserServiceEE.editPassword(values.password, values.newPassword, LocalStorage.getToken())
			.then(() => {
				addToast(ToastType.SUCCESS, 'personalInfo.connexionInfo.modifyPassword.success')
				setOpenForm(false)
			})
			.catch((error) => {
				if (error?.data) {
					return error.data
				} else {
					addToast(ToastType.ERROR, 'global.error.occurred')
				}
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<>
			<Popup
				open={openForm}
				width="576px"
				setOpen={setOpenForm}
				title={intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyPassword.title' })}
			>
				<Form
					onSubmit={onSubmit}
					validate={(values) => {
						const errors: Record<string, string> = {}
						if (!values[MODIFY_PASSWORD_FIELDS.OLD_PASSWORD]) {
							errors[MODIFY_PASSWORD_FIELDS.OLD_PASSWORD] = intl.formatMessage({ id: 'global.error.required' })
						}
						if (!values[MODIFY_PASSWORD_FIELDS.NEW_PASSWORD]) {
							errors[MODIFY_PASSWORD_FIELDS.NEW_PASSWORD] = intl.formatMessage({ id: 'global.error.required' })
						}
						if (!values[MODIFY_PASSWORD_FIELDS.CONFIRM_PASSWORD]) {
							errors[MODIFY_PASSWORD_FIELDS.CONFIRM_PASSWORD] = intl.formatMessage({ id: 'global.error.required' })
						} else if (values[MODIFY_PASSWORD_FIELDS.NEW_PASSWORD] && values[MODIFY_PASSWORD_FIELDS.CONFIRM_PASSWORD] !== values[MODIFY_PASSWORD_FIELDS.NEW_PASSWORD]) {
							errors[MODIFY_PASSWORD_FIELDS.CONFIRM_PASSWORD] = intl.formatMessage({ id: 'global.error.passwordsNotMatch' })
						}

						return errors
					}}
					render={({ handleSubmit, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Stack direction="column" spacing="24px" pt="8px">
								<TextInput
									type="password"
									maxWidth="unset"
									showPasswordToggle={false}
									name={MODIFY_PASSWORD_FIELDS.OLD_PASSWORD}
									label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.oldPassword' })}
								/>
								<TextInput
									type="password"
									maxWidth="unset"
									showPasswordToggle={false}
									name={MODIFY_PASSWORD_FIELDS.NEW_PASSWORD}
									label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.newPassword' })}
									popoverContent={
										<Typography variant="subtitle2">
											<FormattedMessage id="login.form.signup.password.info" />
										</Typography>
									}
								/>
								<TextInput
									type="password"
									maxWidth="unset"
									showPasswordToggle={false}
									name={MODIFY_PASSWORD_FIELDS.CONFIRM_PASSWORD}
									label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.confirmNewPassword' })}
								/>
								<Box display="flex" justifyContent="center">
									<ButtonDefault variant="contained" onClick={handleSubmit} disabled={pristine}>
										{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
									</ButtonDefault>
								</Box>
							</Stack>
						</form>
					)}
				/>
			</Popup>
			<ConfirmPasswordPopin open={openConfirm} setOpen={setOpenConfirm} />
		</>
	)
}

export default ModifyPassword
