import React, { useEffect, useMemo, useState } from 'react'
import { loadNotifications, removeNotificationByInternalId } from '../../../webservices/notifications/NotificationActions'
import { getByDomainOrAll, isNotificationLoading } from '../../../webservices/notifications/NotificationSelectors'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PageLayout from '../../../../components/PageLayout'
import { useIntl } from 'react-intl'
import { getSuffix } from '../../../webservices/notifications/NotificationUtils'
import ParcoursPoleEmploiForm from '../component/ParcoursPoleEmploiForm'
import Loader from '../../../../components/Loader'
import { MAIN_PATH } from '../../../../constant/path'
import { useHistory } from 'react-router-dom'

const PoleEmploiPage = (
	{
		// @ts-ignore
		notifications,
		// @ts-ignore
		loadNotifications,
		// @ts-ignore
		removeNotification,
		// @ts-ignore
		isNotificationLoading
	}
) => {
	const intl = useIntl()
	const history = useHistory()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const urlParams = new URLSearchParams(window.location.search)

	const selectedNotif = useMemo(
		() => notifications.find(
			(notif: any) => notif.data.idpiece.toString() === urlParams.get('s')
		), [notifications, urlParams]
	)

	useEffect(() => {
		loadNotifications()
	}, [])

	useEffect(() => {
		setIsLoading(isNotificationLoading)
	}, [isNotificationLoading])

	useEffect(() => {
		if (!isNotificationLoading && (!urlParams.get('s') || !selectedNotif)) {
			history.push(MAIN_PATH.DASHBOARD)
		}
	}, [urlParams, selectedNotif]);

	const pageTitle = selectedNotif ? intl.formatMessage({ id: `notification.${getSuffix(selectedNotif)}.form.title`, defaultMessage: 'Pièces en attente' }) :
		'Parcours France Travail'

	if (isNotificationLoading || isLoading) {
		return <Loader />
	}

	return (
		<PageLayout title={pageTitle}>
			{selectedNotif ?
				<ParcoursPoleEmploiForm selectedNotif={selectedNotif} confirmationTitle={pageTitle} removeNotification={removeNotification} /> :
				<Loader />
			}
		</PageLayout>
	)
}

const actions = {
	loadNotifications,
	removeNotification: removeNotificationByInternalId
}

const mapStateToProps = (state: any) => {
	const notificationsSelector = getByDomainOrAll()

	return ({
		notifications: notificationsSelector(state),
		isNotificationLoading: isNotificationLoading(state)
	})
}

export default compose(
	connect(mapStateToProps, actions)
)(PoleEmploiPage)
