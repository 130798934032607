import React, { useContext, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import InfoField from '../../../../../components/InfoField'
import ButtonLink from '../../../../../components/ButtonLink'
import Icon from '@mdi/react'
import { mdiAccountLock, mdiCookieCog, mdiEmailEdit, mdiShieldLock } from '@mdi/js'
import { useBreakpoints } from '../../../../../components/breakpoints/BreakpointsProvider'
import { CookiesContext } from '../../../../../components/cookie/CookiesContext'
import ModifyMail from './ModifyMail'
import ModifyPassword from './ModifyPassword'
import PopinDoubleAuthentification from '../../../doubleAuthent/PopinDoubleAuthentification'
import { maskEmail } from '../../../../../utils/authentUtils'

const ConnexionInfo = (
	{
		// @ts-ignore
		user
	}
) => {
	const intl = useIntl()
	const { isMobile, isDesktop, isLaptop, isTabletPortrait, isWidescreen } = useBreakpoints()
	const isDesktopOrLaptop = isDesktop || isLaptop || isWidescreen
	const { openManageCookies } = useContext(CookiesContext)
	const [openModifyMail, setOpenModifyMail] = useState<boolean>(false)
	const [openModifyAuthent, setOpenModifyAuthent] = useState<boolean>(false)
	const [openModifyPassword, setOpenModifyPassword] = useState<boolean>(false)

	const doubleAuthentificationLabel = (methode: string) => {
		switch (methode) {
			case 'sms':
				return 'SMS'
			case 'mail':
				return 'Email'
			case 'sms/mail':
				return 'SMS / Email'
			case 'mail/sms':
				return 'Email / SMS'
		}
		return 'Activer la double authentification'
	}

	const doubleAuthentificationValue = (methode: string) => {
		if(methode){
			switch (methode) {
				case 'sms':
				case 'mail':
				case 'sms/mail':
				case 'mail/sms':
					return 'Activée'
			}
		}
		return 'Non activée'
	}

	return (
		<Stack direction="column" spacing="24px">
			<Typography variant="h2">
				<FormattedMessage id="personalInfo.connexionInfo.title" />
			</Typography>
			<Stack direction="column" spacing="14px" pl="24px" pr="12px">
				<Stack direction={isMobile ? 'column' : 'row'} spacing="14px" alignItems="center">
					<Stack width="100%" direction="column" spacing="16px" justifyContent="space-between">
						<InfoField
							label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.numAssure' })}
							value={user?.idAdherent || ''}
							variantValue="body1"
						/>
						<InfoField
							label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.email' })}
							value={maskEmail(user?.email) || ''}
							variantValue="body1"
						/>
					</Stack>
					<Stack width="100%" direction="column" spacing="16px" justifyContent="space-between" alignSelf="flex-start">
						<InfoField
							label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.password' })}
							value="***************"
							variantValue="body1"
						/>
						<InfoField
							label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.authentification' })}
							value={doubleAuthentificationValue(user?.doubleAuthent)}
							variantValue="body1"
						/>

					</Stack>
				</Stack>

				<Stack direction="column" spacing={isMobile || isTabletPortrait ? '18px' : '14px'}>
					<Stack direction={!isDesktopOrLaptop ? 'column' : 'row'} spacing={isMobile || isTabletPortrait ? '18px' : '14px'}>
						<Box display="flex" flex={1}>
							<ButtonLink onClick={() => setOpenModifyMail(true)} variant="contained" startIcon={<Icon size="20px" path={mdiEmailEdit} />}
							            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
							>
								<FormattedMessage id="personalInfo.connexionInfo.buttons.modifyEmail" />
							</ButtonLink>
						</Box>
						<Box display="flex" flex={1}>
							<ButtonLink onClick={() => setOpenModifyPassword(true)} variant="contained" startIcon={<Icon size="20px" path={mdiAccountLock} />}
							            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
							>
								<FormattedMessage id="personalInfo.connexionInfo.buttons.modifyPassword" />
							</ButtonLink>
						</Box>
						{!isDesktopOrLaptop && <>
							<Box display="flex" flex={1}>
								<ButtonLink onClick={openManageCookies} variant="contained" startIcon={<Icon size="20px" path={mdiCookieCog} />}
								            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
								>
									<FormattedMessage id="personalInfo.connexionInfo.buttons.manageCookies" />
								</ButtonLink>
							</Box>
							<Box display="flex" flex={1}>
								<ButtonLink onClick={() => setOpenModifyAuthent(true)} variant="contained" startIcon={<Icon size="20px" path={mdiShieldLock} />}
								            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
								>
									{!!user?.doubleAuthent ?
										<FormattedMessage id="personalInfo.connexionInfo.buttons.modifyAuthent" />
										: <FormattedMessage id="personalInfo.connexionInfo.buttons.activateAuthent" />
									}
								</ButtonLink>
							</Box>
						</>
						}
					</Stack>
					{isDesktopOrLaptop &&
						<Stack width="100%" direction={!isDesktopOrLaptop ? 'column' : 'row'} spacing="14px">
							<Box display="flex" flex={1}>
								<ButtonLink onClick={openManageCookies} variant="contained" startIcon={<Icon size="20px" path={mdiCookieCog} />}
								            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
								>
									<FormattedMessage id="personalInfo.connexionInfo.buttons.manageCookies" />
								</ButtonLink>
							</Box>
							<Box display="flex" flex={1}>
								<ButtonLink onClick={() => setOpenModifyAuthent(true)} variant="contained" startIcon={<Icon size="20px" path={mdiShieldLock} />}
								            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
								>
									{!!user?.doubleAuthent ?
										<FormattedMessage id="personalInfo.connexionInfo.buttons.modifyAuthent" />
										: <FormattedMessage id="personalInfo.connexionInfo.buttons.activateAuthent" />
									}
								</ButtonLink>
							</Box>
						</Stack>
					}
				</Stack>
			</Stack>
			<ModifyMail openForm={openModifyMail} setOpenForm={setOpenModifyMail} currentEmail={user?.email} currentAuthent={user?.doubleAuthent}/>
			<ModifyPassword openForm={openModifyPassword} setOpenForm={setOpenModifyPassword} />
			<PopinDoubleAuthentification open={openModifyAuthent} setOpen={setOpenModifyAuthent} modification={!!user?.doubleAuthent} currentTelephone={user?.telephone} currentEmail={user?.email} currentAuthent={user?.doubleAuthent} />
		</Stack>
	)
}

export default ConnexionInfo
