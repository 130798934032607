import React, {useMemo} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Link, Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import CheckboxInput from '../../../../components/form/CheckboxInput'
import {STEP1_CHECKBOX_NAME, STEP2_FORM_NAME} from '../services/souscriptionConstants'
import TextInput from '../../../../components/form/TextInput'
import IBAN from 'iban'
import IBANTextInput from '../../../../components/IBANTextInput'
import {downloadMandatSepa} from '../services/souscriptionActions'
import {createSelector} from 'reselect'
import {getAdhesion} from '../../webservices/WsSelector'

type GarantieSouscriptionRibSectionProps = {
	values: any
	rib: any
	errors: any
	garanties: Garantie[]
	preaffiliation: any
}

const GarantieSouscriptionRibSection: React.FC<GarantieSouscriptionRibSectionProps> = (
	{
		// @ts-ignore
		downloadMandatSepa,
		values,
		rib,
		errors,
		garanties,
		preaffiliation: {
			infoPersonnelles: {
				adresse,
				adresse2,
				adresse3,
				codePostal,
				ville,
				pays
			},
			coordBancaires: {
				titulaire
			}
		},
	}
) => {
	const intl = useIntl()
	const PRELEVEMENT_SUR_SALAIRE = 1

	const selectedOption = useMemo(() => {
	 	const selectedOptionKey = values[STEP1_CHECKBOX_NAME]
			? values[STEP1_CHECKBOX_NAME].filter((option: any) => option.selected)
			: []
	 	const selectedOption = garanties.find((garantie: Garantie) => garantie.key === selectedOptionKey[0].idGarantie[0])
	 	return selectedOption
	 }, [values, garanties])

	// @ts-ignore
	const isPrelevementSurSalaire = () => selectedOption && selectedOption.modePaie === PRELEVEMENT_SUR_SALAIRE

	const getLibellemandatSepa = (iban: string) => {
		if (isPrelevementSurSalaire()) {
			return intl.formatMessage({ id: 'souscription.input.libelleMandatSepa.prelevementSalaire' })
		}

		if (IBAN.isValid(iban)) {
			return intl.formatMessage({ id: 'souscription.input.libelleMandatSepa.modifyCoord' }, {
				link: (
					<Link
						component="button"
						variant="body2"
						sx={{ verticalAlign: 'initial' }}
						onClick={() => downloadMandatSepa({
							nom: titulaire, // hack
							adresse,
							adresse2,
							adresse3,
							codePostal,
							ville,
							pays,
							iban: iban || (rib && rib?.iban)
						})}
					>
						<FormattedMessage id="souscription.input.libelleMandatSepa.sepa" />
					</Link>
				)
			})
		}

		return intl.formatMessage({ id: 'souscription.input.libelleMandatSepa.default' })
	}

	const getIntro = () => {
		if (isPrelevementSurSalaire()) {
			return (
				<span>Votre mode de paiement pour la souscription d'option est le suivant : <b>Prélèvement sur salaire</b></span>
			)
		}
		return 'Les coordonnées bancaires associées à vos remboursements de frais de santé et au prélèvement de votre cotisation sont les suivantes :'
	}

	return (
		<Stack direction="column" spacing="24px">
			<Typography variant="body2" fontSize="0.875rem">
				{getIntro()}
			</Typography>
			{!isPrelevementSurSalaire() &&
				<>
					<Stack direction="column" spacing="12px">
						<IBANTextInput
							maxWidth="unset"
							name={STEP2_FORM_NAME.IBAN}
							label={intl.formatMessage({ id: 'souscription.input.iban' })}
							disabled
						/>
						<TextInput
							maxWidth="unset"
							name={STEP2_FORM_NAME.BANK}
							label={intl.formatMessage({ id: 'souscription.input.bank' })}
							disabled
						/>
						<TextInput
							maxWidth="unset"
							name={STEP2_FORM_NAME.ACCOUNT_HOLDER}
							label={intl.formatMessage({ id: 'souscription.input.titulaire' })}
							disabled
						/>
					</Stack>

					<Typography variant="body2" fontSize="0.875rem">
						<FormattedMessage id="souscription.selectOption.ulteriorIban"/>
					</Typography>
				</>
			}
			<CheckboxInput
				name={STEP2_FORM_NAME.ACCEPT_SEPA}
				data={[
					{
						label: getLibellemandatSepa(values[STEP2_FORM_NAME.IBAN]),
						value: 'acceptMandatSepa'
					}
				]}
				error={errors[STEP2_FORM_NAME.ACCEPT_SEPA]}
			/>
		</Stack>
	)
}

const mapStateToProps = (state: any) => {
	/*
         * Les données saisies par l'utilisateur lors de sa préaffiliation.
         */
	const preaffiliationSelector = createSelector([
		getAdhesion
	], ({preaffiliation}) => preaffiliation)

	return {
		preaffiliation: preaffiliationSelector(state),
	}

}
const mappedActions = {
	downloadMandatSepa: downloadMandatSepa
}
export default compose(
	connect(mapStateToProps, mappedActions)(GarantieSouscriptionRibSection))
