import React from 'react'
import { Field } from 'react-final-form'
import { TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import FormatUtils from '../../utils/FormatUtils'
import { styled } from '@mui/material/styles'

type DatePickerInputProps = {
	name: string
	label?: string | React.ReactNode
	validate?: (value: string) => string | undefined
	maxWidth?: string
	initialValue?: string
	disabled?: boolean
	onChange?: (newValue: any, index?: number) => void
	index?: number
	helperText?: string
	disableFuture?: boolean
	maxDate?: Date
	disablePast?: boolean
}

const StyledMuiTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root': {
		paddingRight: '10px'
	},
	'& .MuiInputAdornment-root': {
		marginLeft: 0
	}
}))


const DatePickerInputRembFilter: React.FC<DatePickerInputProps> = (
	{
		name,
		label,
		validate,
		maxWidth = '350px',
		initialValue,
		disabled = false,
		onChange,
		index,
		helperText,
		disableFuture = false,
		disablePast = false,
		maxDate
	}) => {

	const formattedDate = initialValue ? FormatUtils.parseDate(initialValue).toISOString() : ''

	return (
		<Field name={name} validate={validate} initialValue={formattedDate}>
			{({ input, meta }) => {
				const handleChange = (newValue: any, index?: number) => {
					input.onChange(newValue)
					onChange && onChange(newValue, index)
				}

				return (
					<DatePicker
						disableFuture={disableFuture}
						disablePast={disablePast}
						maxDate={maxDate}
						inputFormat="dd/MM/yyyy"
						label={label}
						disabled={disabled}
						{...input}
						value={input.value}
						onChange={(newValue: any) => {
							handleChange(newValue, index)
						}}
						toolbarTitle={
							<Typography variant="subtitle1">Date</Typography>
						}
						renderInput={(props) =>
							<StyledMuiTextField
								size="small"
								color="secondary"
								sx={{ width: '100%', maxWidth: maxWidth }}
								{...props}
								inputProps={{
									...props.inputProps,
									placeholder: 'jj/mm/aaaa',
									style: { fontSize: '0.9rem', padding: '8.5px 7px' }
								}}
								InputLabelProps={{
									shrink: true,
									style: { fontSize: '0.9rem' }
								}}
								error={(meta.error || meta.submitError) && meta.touched}
								helperText={(meta.touched && meta.error) || (meta.touched && meta.submitError) || helperText}
							/>
						}
					/>
				)
			}}
		</Field>
	)
}

export default DatePickerInputRembFilter
