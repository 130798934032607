import React from 'react'

type NotationContextProps = {
	setOpen: (open: boolean) => void
}

const NotationValues: NotationContextProps = {
	setOpen: () => {
	}
}

export const NotationContext = React.createContext<NotationContextProps>(NotationValues)