import DemandeServices from '../../../api/gerep/DemandeServices';
import LocalStorage from '../../../business/storage/LocalStorage';

const ACTION_NAMESPACE = '/POPIN_NOTATION'

export const types = {
	OPEN_POPIN: `${ACTION_NAMESPACE}/OPEN_POPIN`,
	CLOSE_POPIN: `${ACTION_NAMESPACE}/CLOSE_POPIN`
}

export const openPopin = () => (dispatch) => {
	dispatch({
		type: types.OPEN_POPIN
	})
}

export const addAffichagePopinNotation = (closePermanently, clicLien) => (dispatch) => {
	dispatch({
		type: types.CLOSE_POPIN
	})
	return DemandeServices.addAffichagePopinNotation(LocalStorage.getToken(), closePermanently, clicLien)
}
