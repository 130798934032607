export enum PATTERNS {
	SOCIAL_SECURITY_NUMBER = '# ## ## ## ### ### ##',
	ORGANISME = '## ### ###',
	IBAN = '#### #### #### #### #### #### ###',
	PHONE = '## ## ## ## ##',
	NUM_ASSURE = '#########',
	ZIPCODE = '## ###'
}

// La question est inversée par rapport au webservice
export const YES_OR_NO_DATA = [
	{
		value: 'non',
		label: 'Oui'
	},
	{
		value: 'oui',
		label: 'Non'
	}
]

export const FORM_REGEX = {
	NSS : /([12]([0-9]{2})((0[1-9]|1[012])|[235]{1}[0-9]{1}|4[012]{1})([0-9][0-9]|2[AB])([0-9]{3})([0-9]{3})([0-9]{2}))/,
	FOREIGN_NSS : /([78](\\w{14}))/,
	NAME: /^[a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ'-]*(?:-[a-zA-ZÀ-ÿ]{1,2})?(?: [a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ'-]*(?:-[a-zA-ZÀ-ÿ]{1,2})?)*$/,
	EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*\-\\;\/_]).{12,32}$/
}
