import React, {Dispatch, SetStateAction, useContext, useState} from 'react'
import {Form} from 'react-final-form'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {cardiboxSelectors} from 'redux-cardibox'
import {Stack, Typography} from '@mui/material'
import {BOOLEAN, BOOLEANS, DISPENSE_PREAFF_FIELDS} from '../services/preaffiliationConstants'
import {validPopinConfirmation} from '../services/preaffiliationValidationFields'
import {downloadAttestationDispense, resetAffiliation} from '../../webservices/WsActions'
import {getAdhesion} from '../../webservices/WsSelector'
import {MAIN_PATH_UNIVERS} from '../../../../constant/path'
import Loader from '../../../../components/Loader'
import RadioInput from '../../../../components/form/RadioInput'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import ConfirmationPopinPreaff from './ConfirmationPopinPreaff'
import CheckboxInput from '../../../../components/form/CheckboxInput'
import DownloadDispenseAttestationButton from './DownloadDispenseAttestationButton'
import {logout} from '../services/preaffiliationActions'
import {ToastContext} from '../../../../components/toast/ToastContext'
import {ToastType} from '../../../../components/toast/toastConstants'


const ARTICLE_POPIN_CONFIRM = 'txt_popin_confirmation_aff'


type ConfirmationPopinPreaffFormProps = {
    openPopin: boolean
    setOpenPopin: Dispatch<SetStateAction<boolean>>,
    wantDispense?: boolean
    formValues: any
}
const ConfirmationPopinPreaffForm: React.FC<ConfirmationPopinPreaffFormProps> = (
    {
        // @ts-ignore
        preAffConfirmPopArticle,
        // @ts-ignore
        downloadAttestationDispense,
        // @ts-ignore
        resetAffiliation,
        // @ts-ignore
        adhesion,
        openPopin = false,
        setOpenPopin,
        wantDispense,
        formValues
    }
) => {

    const intl = useIntl()
    const history = useHistory()
    const [attestationDownloaded, setAttestationDownloaded] = useState<boolean>(false)
    const { addToast } = useContext(ToastContext)


    const { isMobile, isTabletPortrait } = useBreakpoints()
    const isMobOrTabVert = isMobile || isTabletPortrait

    if (!preAffConfirmPopArticle) {
        return <Loader />
    }

    const telechargerAttestationDispense = () => {
        return downloadAttestationDispense(formValues)
    }

    const adhesionCheck = adhesion && adhesion.garanties && adhesion.garanties.length

    const onSubmitPopin = (values: any) => {
        resetAffiliation()
        logout()
    }

    return (
        <>
            <Form
                validate={(values) => {
                    const errors: any = {}
                    {wantDispense && (validPopinConfirmation(values, errors, intl, attestationDownloaded))}
                    return errors
                }}
                onSubmit={onSubmitPopin}
                render={({handleSubmit, values, errors}) => {
                    return (
                        <ConfirmationPopinPreaff
                            title={wantDispense
                                ? intl.formatMessage({ id: 'preaffiliation.dispense.popin.title' })
                                : intl.formatMessage({ id: 'preaffiliation.confirmation.popin.title' })
                            }
                            open={openPopin}
                            setOpen={setOpenPopin}
                            body={
                                <Stack direction="column" spacing="24px">
                                    {wantDispense ?
                                        (
                                            <Typography
                                                variant="body2"
                                                style={{fontSize: '1rem', lineHeight: '1'}}
                                                whiteSpace="pre-line"
                                            >
                                                {intl.formatMessage({ id: 'preaffiliation.dispense.popin.body'})}
                                            </Typography>
                                        ) : (
                                            <Typography
                                                variant="body2"
                                                style={{fontSize: '16px', lineHeight: '1'}}
                                                whiteSpace="pre-line"
                                                dangerouslySetInnerHTML={{ __html: preAffConfirmPopArticle.fields.body}}
                                            />
                                        )
                                    }

                                    {wantDispense ?
                                        (
                                            <Stack direction="column" spacing="12px">
                                                <Stack direction="row" justifyContent="center" flex="1">
                                                    <DownloadDispenseAttestationButton
                                                        downloadAction={telechargerAttestationDispense}
                                                        attestationDownloaded={attestationDownloaded}
                                                        setAttestationDownloaded={setAttestationDownloaded}
                                                    />
                                                </Stack>
                                                <CheckboxInput
                                                    name={DISPENSE_PREAFF_FIELDS.ENGAGEMENT_ATTESTATION}
                                                    error={errors && errors[DISPENSE_PREAFF_FIELDS.ENGAGEMENT_ATTESTATION]}
                                                    data={
                                                        [{
                                                            label: intl.formatMessage({id: 'preaffiliation.dispense.popin.honorEngagement'}),
                                                            value: DISPENSE_PREAFF_FIELDS.ENGAGEMENT_ATTESTATION
                                                        }] as SelectOption[]
                                                    }
                                                />
                                            </Stack>
                                        ) : (
                                            adhesionCheck > 0 &&
                                            <RadioInput
                                                name={"souscriptionQuestion"}
                                                label={intl.formatMessage({id: 'preaffiliation.confirmation.popin.question'})}
                                                data={BOOLEANS.map((boolean: any) => ({value: boolean.value, label: boolean.label}))}
                                                direction="row"
                                                defaultValue={BOOLEAN.OUI.value}
                                                radioSpacing="80px"
                                            />
                                        )
                                    }
                                </Stack>
                            }
                            goBackAction={!wantDispense && (adhesionCheck > 0 && values["souscriptionQuestion"] === "OUI") ?
                                {
                                    // @ts-ignore
                                    action: () => {
                                        resetAffiliation()
                                        history.push(MAIN_PATH_UNIVERS.PREAFFILIATION_SOUSCRIPTION)
                                    },
                                    title: intl.formatMessage({ id: 'preaffiliation.nextStep'})

                                } : {
                                    action: () => {
                                            if (!attestationDownloaded) {
                                                addToast(ToastType.ERROR, 'global.error.downloadObligation')
                                            }
                                            handleSubmit()
                                    },
                                    title: wantDispense
                                        ? intl.formatMessage({ id: 'preaffiliation.dispense.popin.terminado' })
                                        : intl.formatMessage({ id: 'preaffiliation.confirmation.popin.endNow' })
                                }
                            }
                        />
                    )
                }}
            />
        </>
    )
}

const actions = {
    resetAffiliation,
    downloadAttestationDispense: downloadAttestationDispense
}
const mapStateToProps = () => {
    return (state: any) => ({
        preAffConfirmPopArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_POPIN_CONFIRM],
        isPreAffConfirmPopArticleLoading: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_POPIN_CONFIRM],
        adhesion: state.pages && getAdhesion(state)
    })
}
export default compose(
    connect(mapStateToProps, actions)
)(ConfirmationPopinPreaffForm)
