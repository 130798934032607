import { Box, Breadcrumbs, Link, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Searchbar from '../components/Searchbar'
import { ChevronRight } from '@mui/icons-material'
import { connect } from 'react-redux'
import Loader from '../../../../components/Loader'
import { SHORTCUT_RACINE_AIDE, TYPE_ARTICLE_FAQ, TYPE_ARTICLE_LINK, TYPE_FOLDER } from '../services/HelpConstants'
import HelpListe from '../components/HelpListe'
import * as contactActions from '../../../webservices/contact/contactActions'
import { getRecherche, isRechercheLoading, isRubriquesLoading } from '../../../webservices/contact/contactSelector'
import PageLayout from '../../../../components/PageLayout'
import { getShowHelpDuration } from '../../../../constant/configuration'
import HelpPopin from '../components/HelpPopin'
import { useLocation } from 'react-router-dom'
import { ToastContext } from '../../../../components/toast/ToastContext'
import { ToastType } from '../../../../components/toast/toastConstants'

type ArianeString = {
	label: string
	shortcut: string
}

const HelpPage = (
	{
		// @ts-ignore
		folderFaq,
		// @ts-ignore
		loadFolders,
		// @ts-ignore
		isFaqLoading,
		// @ts-ignore
		isRechercheLoading,
		// @ts-ignore
		rechercheFaq,
		// @ts-ignore
		recherche,
		// @ts-ignore
		showHelpDuration
	}) => {
	const intl = useIntl()
	const location = useLocation<{ birthCertificate: boolean }>()
	const isBirthCertificate = useMemo(() => location.state?.birthCertificate, [location.state])

	const { addToast } = useContext(ToastContext)

	const [ariane, setAriane] = useState<ArianeString[]>([
		{
			label: intl.formatMessage({ id: 'help.ariane.home' }),
			shortcut: SHORTCUT_RACINE_AIDE
		}
	])
	const [folderContent, setFolderContent] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [shortcut, setShortcut] = useState<string>(SHORTCUT_RACINE_AIDE)
	const [forSearch, setForSearch] = useState<boolean>(false)

	const getFolderContentByShortcut = (shortcut: any) => {
		if (shortcut === SHORTCUT_RACINE_AIDE) {
			setAriane([
				{
					label: intl.formatMessage({ id: 'help.ariane.home' }),
					shortcut: SHORTCUT_RACINE_AIDE
				}
			])
		}

		const folderRecup = folderFaq[shortcut]
		if (folderRecup) {
			let subfolders = []
			if (folderRecup.folders) {
				subfolders = folderRecup.folders.map((folder: any) => ({ ...folder, type: TYPE_FOLDER }))
			}
			let subarticles = []
			if (folderRecup.articles) {
				subarticles = folderRecup.articles.map((article: any) => {
					const articleType = article?.type_article === TYPE_ARTICLE_FAQ ? TYPE_ARTICLE_FAQ : TYPE_ARTICLE_LINK
					return ({ ...article, type: articleType })
				})
			}
			setFolderContent([...subfolders, ...subarticles])
			setIsLoading(false)
		} else {
			setFolderContent([])
			setIsLoading(false)
		}
	}

	const getRechercheContent = () => {
		if (recherche) {
			let subarticles = []
			if (recherche) {
				subarticles = recherche.map((article: any) => {
					const articleType = article?.type_article === TYPE_ARTICLE_FAQ ? TYPE_ARTICLE_FAQ : TYPE_ARTICLE_LINK
					return ({ ...article, type: articleType })
				})
			}
			setFolderContent([...subarticles])
			setIsLoading(false)
		} else {
			setFolderContent([])
			setIsLoading(false)
		}
	}

	const callActionFolder = (shortcut: any) => {
		loadFolders(shortcut)
	}

	useEffect(() => {
		getRechercheContent()
	}, [recherche])

	useEffect(() => {
		setIsLoading(true)
		if (folderFaq[SHORTCUT_RACINE_AIDE]) {
			getFolderContentByShortcut(SHORTCUT_RACINE_AIDE)
		} else {
			callActionFolder(SHORTCUT_RACINE_AIDE)
		}
	}, [])

	useEffect(() => {
		if (folderFaq[shortcut]) {
			getFolderContentByShortcut(shortcut)
		} else {
			callActionFolder(shortcut)
		}
	}, [shortcut, folderFaq[shortcut]])

	useEffect(() => {
		if (isBirthCertificate) {
			addToast(ToastType.INFO, '', intl.formatMessage({ id: 'help.birthCertificate' }))
		}
	}, [isBirthCertificate])


	return (
		<PageLayout title={intl.formatMessage({ id: 'help.title' }, { br: <br /> })}>
			<Box width="100%">
				<Searchbar setForSearch={setForSearch} getFolderContentByShortcut={getFolderContentByShortcut} rechercheAction={rechercheFaq} setIsLoading={setIsLoading} />
			</Box>
			<Stack
				spacing="24px"
				sx={{ marginTop: '24px' }}
			>
				{(isLoading || isFaqLoading || isRechercheLoading) ?
					<Stack direction="column" spacing="24px">
						<Loader />
					</Stack> : (
						<>
							{(forSearch) ?
								(
									<Typography variant="h3">
										<FormattedMessage id="help.ariane_recherche" />
									</Typography>
								) : (
									<Breadcrumbs
										separator={<ChevronRight />}
										aria-label="breadcrumb"
									>
										{ariane.map((item, index) => {
											return (
												<Link
													key={index}
													underline="hover"
													href="#"
													color="inherit"
													onClick={() => {
														if (index !== ariane.length - 1) {
															setAriane(prev => prev.slice(0, index + 1))
															setShortcut(item.shortcut)
															setIsLoading(true)
														}
													}}
												>
													<Typography variant="h3">
														{item.label}
													</Typography>
												</Link>
											)
										})}
									</Breadcrumbs>
								)
							}
							<HelpListe setIsLoading={setIsLoading} folderContent={folderContent} setShortcut={setShortcut} ariane={ariane} setAriane={setAriane} forSearch={forSearch} />
						</>
					)}
			</Stack>
			<HelpPopin showHelpDuration={showHelpDuration} showActionsInital={false}/>
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	folderFaq: state.contact.data,
	isFaqLoading: isRubriquesLoading(state),
	isRechercheLoading: isRechercheLoading(state),
	recherche: getRecherche(state),
	showHelpDuration: getShowHelpDuration()

})

const mappedActions = {
	loadFolders: contactActions.getRubriques,
	rechercheFaq: contactActions.rechercheFaq
}

export default connect(
	mapStateToProps,
	mappedActions
)(HelpPage)
