import React, { Dispatch, SetStateAction } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import Popup from '../../../../components/Popup'
import { Form } from 'react-final-form'
import ButtonDefault from '../../../../components/ButtonDefault'
import { useHistory } from 'react-router-dom'
import { MAIN_PATH, SECONDARY_PATH } from '../../../../constant/path'
import { SEND_DOCUMENTS_FIELDS_VALUES } from '../services/dashboardConstants'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import RadioInput from '../../../../components/form/RadioInput'

type SendOtherDocumentPopupProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}

const SendOtherDocumentPopup: React.FC<SendOtherDocumentPopupProps> = (
	{
		open,
		setOpen
	}
) => {
	const intl = useIntl()
	const history = useHistory()
	const { isMobile } = useBreakpoints()

	const SELECT_DATA = [
		{
			value: SEND_DOCUMENTS_FIELDS_VALUES.POLE_EMPLOI,
			label: intl.formatMessage({ id: 'dashboard.sendDocuments.popup.labels.poleEmploi' })
		},
		{
			value: SEND_DOCUMENTS_FIELDS_VALUES.CERTIFICAT_SCOLARITE,
			label: intl.formatMessage({ id: 'dashboard.sendDocuments.popup.labels.certificatScolarite' })
		},
		{
			value: SEND_DOCUMENTS_FIELDS_VALUES.FACTURE,
			label: intl.formatMessage({ id: 'dashboard.sendDocuments.popup.labels.facture' })
		},
		{
			value: SEND_DOCUMENTS_FIELDS_VALUES.RELEVE_SECU,
			label: intl.formatMessage({ id: 'dashboard.sendDocuments.popup.labels.releveSS' })
		},
		{
			value: SEND_DOCUMENTS_FIELDS_VALUES.RELEVE_AUTRE,
			label: intl.formatMessage({ id: 'dashboard.sendDocuments.popup.labels.releveAutre' })
		},
		{
			value: SEND_DOCUMENTS_FIELDS_VALUES.DEVIS,
			label: intl.formatMessage({ id: 'dashboard.sendDocuments.popup.labels.devis' })
		},
		{
			value: SEND_DOCUMENTS_FIELDS_VALUES.BIRTH_CERTIFICATE,
			label: intl.formatMessage({ id: 'dashboard.sendDocuments.popup.labels.birthCertificate' })
		},
		{
			value: SEND_DOCUMENTS_FIELDS_VALUES.AUTRE,
			label: intl.formatMessage({ id: 'dashboard.sendDocuments.popup.labels.autre' })
		}
	]

	const onSubmit = (values: any) => {
		switch (values?.sendDocumentSelect) {
			case SEND_DOCUMENTS_FIELDS_VALUES.POLE_EMPLOI:
				history.push(SECONDARY_PATH.SERVICES_EXTEND_COVER)
				break
			case SEND_DOCUMENTS_FIELDS_VALUES.CERTIFICAT_SCOLARITE:
				history.push(SECONDARY_PATH.SERVICES_ADULT_CHILDREN)
				break
			case SEND_DOCUMENTS_FIELDS_VALUES.AUTRE:
				history.push(MAIN_PATH.HELP)
				break
			case SEND_DOCUMENTS_FIELDS_VALUES.FACTURE:
			case SEND_DOCUMENTS_FIELDS_VALUES.RELEVE_SECU:
			case SEND_DOCUMENTS_FIELDS_VALUES.RELEVE_AUTRE:
				history.push(SECONDARY_PATH.REFUND_DEMAND)
				break
			case SEND_DOCUMENTS_FIELDS_VALUES.DEVIS:
				history.push(SECONDARY_PATH.SERVICES_SEND_QUOTE)
				break
			case SEND_DOCUMENTS_FIELDS_VALUES.BIRTH_CERTIFICATE:
				history.push({ pathname: MAIN_PATH.HELP, state: { birthCertificate: true } })
				break
			default:
				break
		}
		setOpen(false)
	}

	return (
		<Popup
			width={!isMobile ? '448px' : 'auto'}
			open={open}
			setOpen={setOpen}
			title={intl.formatMessage({ id: 'dashboard.sendDocuments.popup.title' })}
		>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, pristine }) => (
					<form onSubmit={handleSubmit}>
						<Stack direction="column" spacing="24px">
							<Typography variant="body2">
								<FormattedMessage id="dashboard.sendDocuments.popup.text" />
							</Typography>

							<RadioInput
								name="sendDocumentSelect"
								data={SELECT_DATA}
							/>

							<Box display="flex" justifyContent="center">
								<ButtonDefault
									fullWidth={isMobile}
									variant="contained"
									onClick={handleSubmit}
									disabled={pristine}
								>
									<FormattedMessage id="global.button.continue" />
								</ButtonDefault>
							</Box>
						</Stack>
					</form>
				)}
			/>
		</Popup>
	)
}

export default SendOtherDocumentPopup