import React, {useEffect, useState} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import Loader from '../../../../components/Loader'
import {Divider, Stack, Typography} from '@mui/material'
import PageLayoutEE from '../../../../components/PageLayoutEE'
import {FormattedMessage, useIntl} from 'react-intl'
import GridPrevoyanceChart from '../components/GridPrevoyanceChart'
import TypeDataSelector from '../../components/TypeDataSelector'
import AnneeSelector from '../../components/AnneeSelector'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import appConst from '../../../../constant/appConstants'
import {isDataKPILoading, prevoyanceSelector} from '../../data/dataSelectors'
import EmptyResult from '../../components/EmptyResult'
import FormatUtils from '../../../../utils/FormatUtils'


const ARTICLE_PREVOYANCE_SUIVI_GRAPH = 'prevoyance_suivi_graph'

const PrevoyanceArretTravailPageEE = (
	{
		// @ts-ignore
		loading,
		// @ts-ignore
		dataPrevoyance,
		// @ts-ignore
		getArticleByShortcut,
		// @ts-ignore
		prevoyanceSuiviGraphArticle
	}
)=> {

	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const isSmallScreen = isMobile || isTabletPortrait

	const initialeAnnee = Number(Object.keys(dataPrevoyance)[2])
	const [annee, setAnnee] = useState<number>(initialeAnnee)
	const [typeData, setTypeData] = useState<boolean>(false)
	const [dataGraphe, setDataGraphe] = useState<any>([])
	const [validMonth, setValidMonth] = useState<number>(0)

	const prevoyanceAnneeN = dataPrevoyance[annee.toString()]
	const prevoyanceAnneeNMoins1 = dataPrevoyance[(annee - 1).toString()]
	const isSelectedYearPastYear = initialeAnnee != new Date().getFullYear() || (initialeAnnee == new Date().getFullYear() && prevoyanceAnneeN && (prevoyanceAnneeN.annee === (initialeAnnee - 1).toString() || prevoyanceAnneeN.annee === (initialeAnnee - 2).toString()))

	useEffect(() => {
		if (initialeAnnee) {
			setAnnee(initialeAnnee)
			for (let i = dataPrevoyance[initialeAnnee].dataMonth.length-1; i >= 0; i--) {
				if (validMonth === 0
					&& dataPrevoyance[initialeAnnee].dataMonth[i].ratio !== undefined
					&& dataPrevoyance[initialeAnnee].dataMonth[i].ouvert !== undefined
					&& dataPrevoyance[initialeAnnee].dataMonth[i].ferme  !== undefined) {
					setValidMonth(i)
					break
				}
			}
		}
	}, [dataPrevoyance])


	useEffect(() => {
		if (dataPrevoyance && prevoyanceAnneeN) {
			if (!typeData) {
				setDataGraphe(prevoyanceAnneeN.dataMonth)
			} else {
				let dataTrimestre: any[] = []
				Object.keys(dataPrevoyance).forEach((key: any) => {
					dataTrimestre = [...dataTrimestre,
						...dataPrevoyance[key].dataTrimestre
					]
				})
				setDataGraphe(dataTrimestre)
			}
		}
	}, [dataPrevoyance, prevoyanceAnneeN, typeData])

	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_PREVOYANCE_SUIVI_GRAPH)
	}, [getArticleByShortcut])


	function handleChangeTypeData() {
		setTypeData(!typeData)
	}

	function handleChangeYear(value: number){
		setAnnee(value)
	}

	const nothingToShow = (prevoyanceAnneeX: any) => {
		if (prevoyanceAnneeX !== undefined) {
			if(prevoyanceAnneeX.donneesActualisees){
				return false
			}
			return true
		}
	}

	const trimestreOrMonthNumbers = (donneeAnneeX: any) => {
		if (dataPrevoyance && !nothingToShow(donneeAnneeX)) {
			if (!typeData) {
				if (donneeAnneeX.dataMonth && donneeAnneeX.dataMonth.length > 0) {
					for (let i = validMonth; i >= 0; i--) {
						if (donneeAnneeX.dataMonth[i].ratio !== undefined && donneeAnneeX.dataMonth[i].ouvert !== undefined && donneeAnneeX.dataMonth[i].ferme !== undefined) {
							return donneeAnneeX.dataMonth[i]
						}
					}
				}
			} else {
				const validTrimestre = Math.floor((validMonth)/3)
				if (donneeAnneeX.dataTrimestre && donneeAnneeX.dataTrimestre.length > 0) {
					for (let i = validTrimestre; i >= 0; i--) {
						if (donneeAnneeX.dataTrimestre[i].ratio !== undefined && donneeAnneeX.dataTrimestre[i].ouvert !== undefined && donneeAnneeX.dataTrimestre[i].ferme !== undefined) {
							return donneeAnneeX.dataTrimestre[i]
						}
					}
				}
			}
		}
        return undefined
	}

	const prevoyanceAnneeSelector = () => {
		const selector = [
			{
				value: Object.keys(dataPrevoyance)[2],
				label: Object.keys(dataPrevoyance)[2]
			}
		]
		if (!nothingToShow(dataPrevoyance[Object.keys(dataPrevoyance)[1]])
			|| (nothingToShow(dataPrevoyance[Object.keys(dataPrevoyance)[1]]) && !nothingToShow(dataPrevoyance[Object.keys(dataPrevoyance)[0]]))) {
			selector.push(
				{
					value: Object.keys(dataPrevoyance)[1],
					label: Object.keys(dataPrevoyance)[1]
				}
			)
		}
		if (!nothingToShow(dataPrevoyance[Object.keys(dataPrevoyance)[0]])) {
			selector.push(
				{
					value: Object.keys(dataPrevoyance)[0],
					label: Object.keys(dataPrevoyance)[0]
				}
			)
		}
		return selector
	}


	if (loading || prevoyanceAnneeN === undefined || !prevoyanceSuiviGraphArticle) {
		return <Loader />
	} else {
		return (

			<PageLayoutEE
				title={intl.formatMessage({ id: 'prevoyance.arretTravail.title' })}
				subtitle={prevoyanceAnneeN.donneesActualisees && (
					intl.formatMessage({id: 'global.majData'}) + prevoyanceAnneeN.donneesActualisees
				)}
			>
				<Stack spacing="24px" alignItems={isSmallScreen ? 'center' : ''}>
					<Stack direction={isSmallScreen ? 'column' : 'row'}  spacing={isSmallScreen ? '24px' : 0} width="100%">
						<Stack direction="column" flex="1">
							<TypeDataSelector
								onChange={handleChangeTypeData}
								stateValue={typeData}
								name="typeData"
								label1={intl.formatMessage({id: 'prevoyance.dataSelector.monthLabel'})}
								label2={intl.formatMessage({id: 'prevoyance.dataSelector.trimesterLabel'})}
							/>
						</Stack>

						<Stack direction="column" flex="1" width={isSmallScreen ? '100%' : '50%'}>
							{!typeData &&
								<AnneeSelector
									onChange={handleChangeYear}
									name="toggleYears"
									stateValue={annee}
									data={prevoyanceAnneeSelector()}
								/>
							}
						</Stack>
					</Stack>

					<Divider style={{border: '1px solid #dedede', width: '100%'}}/>

					{nothingToShow(prevoyanceAnneeN) ?
						<EmptyResult
							nature="PAGE"
							message={
								<FormattedMessage
									id="global.noPageData"
									values={{titrePage:
											<Typography
												variant="body2"
												fontSize="0,875rem"
												fontWeight="bold"
												color="#929292"
											>
												{intl.formatMessage({id: 'prevoyance.arretTravail.title'})}
											</Typography>
									}}
								/>
							}
						/> : <>
							<Stack direction="column" flex="1">
								<Stack direction={isMobile ? 'column' : 'row'} spacing="24px" alignItems="flex-start">
									{!isSelectedYearPastYear &&
										<Stack flex="1" direction="column" spacing="50px" alignItems="center">
											<Stack direction="column" margin="auto">
												<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
													{intl.formatMessage({id: 'prevoyance.dirNumbers.inProgress'})}
												</Typography>

												{prevoyanceAnneeN.dossiersEnCours !== undefined ?
													<>
														<Stack direction="row" alignItems="baseline" alignSelf="center" spacing="2px">
															<Typography fontSize="2rem" fontWeight="600" color="#606060" variant="body1">
																{intl.formatNumber(Math.abs(prevoyanceAnneeN.dossiersEnCours))}
															</Typography>
														</Stack>
													</> : <>
														<EmptyResult
															nature="NUMBER"
															message={intl.formatMessage({id: 'global.noKpiNumbers'})}
														/>
													</>
												}
											</Stack>
										</Stack>
									}

									<Stack flex="1" direction="column" spacing="5px" alignItems="center">
										<Stack direction="column" margin="auto">
											<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
												{intl.formatMessage({id: isSelectedYearPastYear
														? 'prevoyance.dirNumbers.averageRate'
														: 'prevoyance.dirNumbers.instantAverageRate'
												})}
											</Typography>

											{(isSelectedYearPastYear
												? (!nothingToShow(prevoyanceAnneeN) && prevoyanceAnneeN.tauxAT !== undefined)
												: (trimestreOrMonthNumbers(prevoyanceAnneeN) !== undefined && trimestreOrMonthNumbers(prevoyanceAnneeN).ratio !== undefined))
													? (
														<>
															<Stack direction="row" alignItems="baseline" spacing="2px">
																<Typography fontSize="2rem" fontWeight="600" color="#fc454a" variant="body1">
																	{isSelectedYearPastYear
																		? prevoyanceAnneeN.tauxAT.toString().replace('.', ',')
																		: trimestreOrMonthNumbers(prevoyanceAnneeN) ? trimestreOrMonthNumbers(prevoyanceAnneeN).ratio.toString().replace('.', ',') : ""
																	}%
																</Typography>
																<Typography variant="subtitle1" fontSize="0.813rem">
																	en {isSelectedYearPastYear
																		? prevoyanceAnneeN.annee
																		: trimestreOrMonthNumbers(prevoyanceAnneeN) ? trimestreOrMonthNumbers(prevoyanceAnneeN).section.toLowerCase() + (!typeData ? " " + prevoyanceAnneeN.annee : '') : ""+ (!typeData ? " " + prevoyanceAnneeN.annee : '')
																	}
																</Typography>
															</Stack>
															{prevoyanceAnneeNMoins1 && (isSelectedYearPastYear
																? (!nothingToShow(prevoyanceAnneeNMoins1) && prevoyanceAnneeNMoins1.tauxAT !== undefined)
																: (trimestreOrMonthNumbers(prevoyanceAnneeNMoins1) !== undefined && trimestreOrMonthNumbers(prevoyanceAnneeNMoins1).ratio !== undefined))
																	&&
																		<Stack direction="row" alignItems="baseline" spacing="2px">
																			<Typography fontWeight="600" variant="body1" fontSize="1.125rem">
																				{isSelectedYearPastYear
																					? prevoyanceAnneeNMoins1.tauxAT.toString().replace('.', ',')
																					: trimestreOrMonthNumbers(prevoyanceAnneeNMoins1) ? trimestreOrMonthNumbers(prevoyanceAnneeNMoins1).ratio.toString().replace('.', ','): ""
																				}%
																			</Typography>
																			<Typography variant="subtitle1" fontSize="0.813rem">
																				en {isSelectedYearPastYear
																					? prevoyanceAnneeNMoins1.annee
																					: trimestreOrMonthNumbers(prevoyanceAnneeNMoins1) ? trimestreOrMonthNumbers(prevoyanceAnneeNMoins1).section.toLowerCase() + (!typeData ? " " + prevoyanceAnneeNMoins1.annee : '') : ""+ (!typeData ? " " + prevoyanceAnneeNMoins1.annee : '')
																				}
																			</Typography>
																		</Stack>

															}
														</>
													) : (
														<EmptyResult
															nature="NUMBER"
															message={intl.formatMessage({id: 'global.noKpiNumbers'})}
														/>
													)
											}
										</Stack>
									</Stack>

									<Stack flex="1" direction="column" spacing="5px" alignItems="center">
										<Stack direction="column" margin="auto">
											<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
												{intl.formatMessage({id: 'prevoyance.dirNumbers.openedDir'})}
											</Typography>

											{(isSelectedYearPastYear ? (!nothingToShow(prevoyanceAnneeN) && prevoyanceAnneeN.dossiersOuverts !== undefined) : trimestreOrMonthNumbers(prevoyanceAnneeN) !== undefined) ?
												<>
													<Stack direction="row" alignItems="baseline" spacing="2px">
														<Typography fontSize="2rem" fontWeight="600" color="#b5bbff" variant="body1">
															{intl.formatNumber(isSelectedYearPastYear ? prevoyanceAnneeN.dossiersOuverts : trimestreOrMonthNumbers(prevoyanceAnneeN) ? trimestreOrMonthNumbers(prevoyanceAnneeN).ouvert : "")}
														</Typography>
														<Typography variant="subtitle1" fontSize="0.813rem">
															en {isSelectedYearPastYear
																? prevoyanceAnneeN.annee
																: trimestreOrMonthNumbers(prevoyanceAnneeN) ? trimestreOrMonthNumbers(prevoyanceAnneeN).section.toLowerCase() + (!typeData ? " " + prevoyanceAnneeN.annee : '') : ""+ (!typeData ? " " + prevoyanceAnneeN.annee : '')
															}
														</Typography>
													</Stack>
													{prevoyanceAnneeNMoins1 && (isSelectedYearPastYear
														? (!nothingToShow(prevoyanceAnneeNMoins1) && prevoyanceAnneeNMoins1.dossiersOuverts !== undefined)
														: (trimestreOrMonthNumbers(prevoyanceAnneeNMoins1) !== undefined && trimestreOrMonthNumbers(prevoyanceAnneeNMoins1).ouvert !== undefined ))
															&&
																<Stack direction="row" alignItems="baseline" spacing="2px">
																	<Typography fontWeight="600" variant="body1" fontSize="1.125rem">
																		{intl.formatNumber(isSelectedYearPastYear ? prevoyanceAnneeNMoins1.dossiersOuverts : trimestreOrMonthNumbers(prevoyanceAnneeNMoins1) ? trimestreOrMonthNumbers(prevoyanceAnneeNMoins1).ouvert : "")}
																	</Typography>
																	<Typography variant="subtitle1" fontSize="0.813rem">
																		en {isSelectedYearPastYear
																			? prevoyanceAnneeNMoins1.annee
																			: trimestreOrMonthNumbers(prevoyanceAnneeNMoins1) ? trimestreOrMonthNumbers(prevoyanceAnneeNMoins1).section.toLowerCase() + (!typeData ? " " + prevoyanceAnneeNMoins1.annee : '') : ""+ (!typeData ? " " + prevoyanceAnneeNMoins1.annee : '')
																		}
																	</Typography>
																</Stack>

													}
												</> : <>
													<EmptyResult
														nature="NUMBER"
														message={intl.formatMessage({id: 'global.noKpiNumbers'})}
													/>
												</>
											}
										</Stack>
									</Stack>

									<Stack flex="1" direction="column" spacing="5px" alignItems="center">
										<Stack direction="column" margin="auto">
											<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
												{intl.formatMessage({id: 'prevoyance.dirNumbers.closedDir'})}
											</Typography>

											{(isSelectedYearPastYear ? (!nothingToShow(prevoyanceAnneeN) && prevoyanceAnneeN.dossiersFermes !== undefined) : trimestreOrMonthNumbers(prevoyanceAnneeN) !== undefined) ?
												<>
													<Stack direction="row" alignItems="baseline" spacing="2px">
														<Typography fontSize="2rem" fontWeight="600" color="#4bc4c2" variant="body1">
															{intl.formatNumber(isSelectedYearPastYear
																? prevoyanceAnneeN.dossiersFermes
																: trimestreOrMonthNumbers(prevoyanceAnneeN) ?  Math.abs(trimestreOrMonthNumbers(prevoyanceAnneeN).ferme) : ""
															)}
														</Typography>
														<Typography variant="subtitle1" fontSize="0.813rem">
															en {isSelectedYearPastYear
																? prevoyanceAnneeN.annee
																: trimestreOrMonthNumbers(prevoyanceAnneeN) ? trimestreOrMonthNumbers(prevoyanceAnneeN).section.toLowerCase() + (!typeData ? " " + prevoyanceAnneeN.annee : '') : ""+ (!typeData ? " " + prevoyanceAnneeN.annee : '')
															}
														</Typography>
													</Stack>
													{prevoyanceAnneeNMoins1 && (isSelectedYearPastYear
														? (!nothingToShow(prevoyanceAnneeNMoins1) && prevoyanceAnneeNMoins1.dossiersFermes !== undefined)
														: trimestreOrMonthNumbers(prevoyanceAnneeNMoins1) !== undefined && trimestreOrMonthNumbers(prevoyanceAnneeNMoins1).ferme !== undefined)
															&&
																<Stack direction="row" alignItems="baseline" spacing="2px">
																	<Typography fontWeight="600" variant="body1" fontSize="1.125rem">
																		{intl.formatNumber(isSelectedYearPastYear
																			? prevoyanceAnneeNMoins1.dossiersFermes
																			: trimestreOrMonthNumbers(prevoyanceAnneeNMoins1) ? Math.abs(trimestreOrMonthNumbers(prevoyanceAnneeNMoins1).ferme) : ""// la valeur des dossiers fermés est ici négative
																		)}
																	</Typography>
																	<Typography variant="subtitle1" fontSize="0.813rem">
																		en {isSelectedYearPastYear
																			? prevoyanceAnneeNMoins1.annee
																			: trimestreOrMonthNumbers(prevoyanceAnneeNMoins1) ? trimestreOrMonthNumbers(prevoyanceAnneeNMoins1).section.toLowerCase() + (!typeData ? " " + prevoyanceAnneeNMoins1.annee : '') : ""+ (!typeData ? " " + prevoyanceAnneeNMoins1.annee : '')
																		}
																	</Typography>
																</Stack>

													}
												</> : <>
													<EmptyResult
														nature="NUMBER"
														message={intl.formatMessage({id: 'global.noKpiNumbers'})}
													/>
												</>
											}
										</Stack>
									</Stack>
								</Stack>
								{prevoyanceAnneeN.datePrevoyanceOld
									&& prevoyanceAnneeN.donneesActualisees !== prevoyanceAnneeN.datePrevoyanceOld
									&&
										<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" mb="-12px" pt="4px">
											{intl.formatMessage({id: 'global.majData'}) + prevoyanceAnneeN.datePrevoyanceOld}
										</Typography>
								}
							</Stack>

							<Divider style={{border: '1px solid #dedede', width: '100%'}}/>

							<Stack direction="column" flex="1">
								<GridPrevoyanceChart
									id_title={prevoyanceSuiviGraphArticle && prevoyanceSuiviGraphArticle.fields.title}
									idChart="prevoyance_arretTravail"
									dataChart={dataGraphe}
								/>
								{prevoyanceAnneeN.datePrevoyanceOld
									&& prevoyanceAnneeN.donneesActualisees !== prevoyanceAnneeN.datePrevoyanceOld
									&&
										<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem">
											{intl.formatMessage({id: 'global.majData'}) + prevoyanceAnneeN.datePrevoyanceOld}
										</Typography>
								}
							</Stack>
						</>
					}
				</Stack>
			</PageLayoutEE>
		)
	}
}

const actions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}
const mapStateToProps = (state: any) => {
	return ({
		loading: isDataKPILoading(state),
		dataPrevoyance : prevoyanceSelector(state),
		prevoyanceSuiviGraphArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_PREVOYANCE_SUIVI_GRAPH]
	})
}
export default compose(
	connect(mapStateToProps, actions)
)(PrevoyanceArretTravailPageEE)

