import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import Popup from '../../../../../components/Popup'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { COTISATIONS, REMBOURSEMENTS, RIBS_FIELDS } from '../../services/personalInfoConstants'
import TextInput from '../../../../../components/form/TextInput'
import DatePickerInput from '../../../../../components/form/DatePickerInput'
import CheckboxInput from '../../../../../components/form/CheckboxInput'
import ButtonDefault from '../../../../../components/ButtonDefault'
import { isBefore, isValid } from 'date-fns'
import SelectInput from '../../../../../components/form/SelectInput'
import { TypeAdherent } from '../../../../../constant/TypeAdherent'
import FileInput from '../../../../../components/fileInput/FileInput'
import RibService from '../../../../../api/gerep/RibService'
import LocalStorage from '../../../../../business/storage/LocalStorage'
import { ToastContext } from '../../../../../components/toast/ToastContext'
import { ToastType } from '../../../../../components/toast/toastConstants'
import ConfirmationPopin from '../../../../../components/ConfirmationPopin'
import RadioInput from '../../../../../components/form/RadioInput'
import IBANTextInput from '../../../../../components/IBANTextInput'
import { connect } from 'react-redux'
import { profileSelector } from '../../../user/userSelectors'
import FormatUtils from '../../../../../utils/FormatUtils'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import appConst from '../../../../../constant/appConstants'

type BankActionFormPopinProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	title: string
	ribInfo: any
	titulaireCheque: any[]
	formType: BankFormTypes
	assurePrincipal: any
	optionsTitulairesRib: any[]
	individuList: any[]
	profile: any
}

const BankActionFormPopin: React.FC<BankActionFormPopinProps> = (
	{
		open,
		setOpen,
		title,
		ribInfo,
		titulaireCheque,
		formType,
		assurePrincipal,
		individuList,
		optionsTitulairesRib,
		profile,
		// @ts-ignore
		codesBanque,
		// @ts-ignore
		getValueListByShortcut
	}
) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
	const [openCotisation, setOpenCotisation] = useState(false)
	const [listCodesBanque, setListCodesBanque] = useState<string[]>([])
	const [pjObligatoire, setPjObligatoire] = useState<boolean>(false)

	useEffect(() => {
		getValueListByShortcut(appConst.cardiboxApi, 'rib_code_banque')
	}, [])

	useEffect(() => {
		codesBanque && setListCodesBanque(codesBanque.entries.map((item: any) => item.label))
	}, [codesBanque])

	// Constants
	const startingDate = useMemo(() => {
		const dateNow = new Date()
		dateNow.setDate(dateNow.getDate() + 2)
		return dateNow
	}, [])

	const endingDate = useMemo(() => {
		const dateNow = new Date()
		dateNow.setDate(dateNow.getDate() + 30)
		return dateNow
	}, [])

	const optionsTitulaires = useMemo(() => {
		const assurePrincipalOption = [
			{
				value: assurePrincipal.numIndividu,
				label: `${assurePrincipal.prenom} ${assurePrincipal.nom}`
			}
		]
		const titulairesOptions = formType === 'to_virement' ? titulaireCheque.map((option: any) => ({
			value: option[1].numIndividu,
			label: `${option[1].prenom} ${option[1].nom}`
		})) : optionsTitulairesRib.map((option: any) => ({
			value: option[1].numIndividu,
			label: `${option[1].prenom} ${option[1].nom}`
		}))

		const titulairesNonAssurePrincipal = titulairesOptions.filter((option: any) => option.value !== assurePrincipal.numIndividu)

		return [...assurePrincipalOption, ...titulairesNonAssurePrincipal]
	}, [assurePrincipal, formType, optionsTitulairesRib, titulaireCheque])

	const defaultTitulaire = useMemo(() => {
		return optionsTitulaires.find((option: any) => option.value === ribInfo.numindiv)?.value
	}, [optionsTitulaires, ribInfo.numindiv])

	const beneficiairesWithDisabled = useMemo(() => individuList.map((beneficiaire) => {
		// si l'individu est l'assuré principal, il n'est pas cochable/décochable
		let disabled = false
		if (beneficiaire[1].typAdr === TypeAdherent.ASSURE_PRINCIPAL) {
			return {
				value: beneficiaire[1].numIndividu,
				label: `${beneficiaire[1].prenom} ${beneficiaire[1].nom}`,
				disabled: true
			}
		}
		if (formType !== 'add') {
			// si l'individu est le titulaire du rib il n'est pas décochable
			if (ribInfo.numindiv === beneficiaire[1].numIndividu) {
				disabled = true
			}
			// si rib de l'assuré principal, ses bénéficiaires attachés ne sont pas décochables
			if (ribInfo.numindiv === assurePrincipal.numIndividu && ribInfo?.beneficiaires) {
				const benefAttachedToAssurePrincipal = ribInfo.beneficiaires.find((benef: any) => beneficiaire[1].numIndividu === benef[1].numIndividu)
				disabled = !!benefAttachedToAssurePrincipal
			}
		}
		return {
			value: beneficiaire[1].numIndividu,
			label: `${beneficiaire[1].prenom} ${beneficiaire[1].nom}`,
			disabled: disabled
		}
	}), [individuList, formType, ribInfo, assurePrincipal])

	const checkedTitulaires = useMemo(() => {
		const benefAssociees = ribInfo?.beneficiaires ? ribInfo.beneficiaires.map((benef: any) => benef[1].numIndividu) : []
		return [ribInfo.numindiv, ...benefAssociees]
	}, [ribInfo])

	const getBeneficiaireBody = (numIndividu: any) => {
		const individu = individuList.find((individu: any) => individu[1].numIndividu === numIndividu)
		return {
			idAdherent: individu[1].numIndividu,
			typeAdherent: individu[1].typAdr
		}
	}

	const initialValue = useMemo(() => {
		return {
			[RIBS_FIELDS.BENEFICIARIES]: checkedTitulaires && formType !== 'add' ? checkedTitulaires : [],
			[RIBS_FIELDS.EDIT_COTISATION_TOO]: 'NON',
		}
	}, [checkedTitulaires])

	const isPJObligatoire = (iban: string) => {
		const ibanCodeBanque = iban.replace(/\s/g, '').substring(4, 9)
		return listCodesBanque && listCodesBanque.some((code: string) => code === ibanCodeBanque)
	}

	const onSubmit = (values: any) => {
		const {
			iban,
			banque,
			titulaire,
			rib,
			dateCompter,
			editCotisationToo,
			beneficiaires
		} = values

		const ribTitulaire = individuList.find((ind: any) => ind[1].numIndividu.toString() === titulaire.toString())
		const type = formType === 'modify_cotisation' ? COTISATIONS : REMBOURSEMENTS

		const body = [{
			key: 'ribEdit',
			value: {
				virement: !!ribInfo?.bban,
				ajout: formType === 'add',
				iban: iban.toString().replace(/\s/g, ''),
				idrib: ribInfo.idrib,
				type,
				banque,
				numIndivTitulaire: titulaire,
				titulaire: `${ribTitulaire[1].nom} ${ribTitulaire[1].prenom}`,
				dateCompter: new Date(dateCompter).toLocaleDateString('fr-FR'),
				editCotisationToo: editCotisationToo === 'OUI',
				beneficiaires: beneficiaires.map((benef: any) => getBeneficiaireBody(benef)),
			}
		}, {
			key: 'rib',
			value: rib
		}]

		setIsLoading(true)
		return RibService.editRib(body, LocalStorage.getToken())
			.then(() => {
				addToast(ToastType.SUCCESS, 'global.success')
				setOpenCotisation(false)
				setOpen(false)
				setOpenConfirmation(true)
			})
			.catch((e) => {
				if (e?.data?._error) {
					setOpenCotisation(false)
					addToast(ToastType.ERROR, e.data._error)
				} else if (e?.data) {
					setOpenCotisation(false)
					return e.data
				} else {
					addToast(ToastType.ERROR, 'Une erreur technique est survenue. Votre demande n\'a pas pu aboutir.')
				}
			})
			.finally(() => setIsLoading(false))
	}

	const validate = (values: any) => {
		const errors: any = {}
		const dateMin = startingDate
		dateMin.setHours(0, 0, 0, 0)
		const dateMax = endingDate
		dateMax.setHours(0, 0, 0, 0)


		const formDate = new Date(values[RIBS_FIELDS.STARTING_DATE])

		if (!values[RIBS_FIELDS.IBAN]) {
			errors[RIBS_FIELDS.IBAN] = intl.formatMessage({ id: 'global.error.required' })
		}
		if (!values[RIBS_FIELDS.BANK]) {
			errors[RIBS_FIELDS.BANK] = intl.formatMessage({ id: 'global.error.required' })
		}
		if (!values[RIBS_FIELDS.OWNER]) {
			errors[RIBS_FIELDS.OWNER] = intl.formatMessage({ id: 'global.error.required' })
		}
		if (!values[RIBS_FIELDS.STARTING_DATE]) {
			errors[RIBS_FIELDS.STARTING_DATE] = intl.formatMessage({ id: 'global.error.required' })
		} else if (!isValid(formDate)) {
			errors[RIBS_FIELDS.STARTING_DATE] = intl.formatMessage({ id: 'global.error.invalidDate' })
		} else if (isBefore(formDate, dateMin) || isBefore(dateMax, formDate)) {
			errors[RIBS_FIELDS.STARTING_DATE] = intl.formatMessage({ id: 'personalInfo.bankInfo.input.startingDate.control' },
				{startDate: FormatUtils.formatDate(startingDate), endDate: FormatUtils.formatDate(endingDate)}
			)
		}
		if (pjObligatoire || formType === 'modify_cotisation') {
			if (!values[RIBS_FIELDS.RIB] || values[RIBS_FIELDS.RIB].length === 0) {
				errors[RIBS_FIELDS.RIB] = intl.formatMessage({ id: 'global.error.required' })
			}
		}
		if (formType === 'add') {
			if (!values[RIBS_FIELDS.BENEFICIARIES] || values[RIBS_FIELDS.BENEFICIARIES].length === 0) {
				errors[RIBS_FIELDS.BENEFICIARIES] = intl.formatMessage({ id: 'global.error.required' })
			}
		}
		return errors
	}

	return (
		<>
			<Popup maxWidth="600px" open={open} setOpen={setOpen} title={title}>
				<Form
					onSubmit={onSubmit}
					validate={validate}
					initialValues={initialValue}
					render={({ handleSubmit, hasValidationErrors, errors }) => {
						return (<form onSubmit={handleSubmit}>
							<Stack direction="column" spacing="24px" mt="8px">
								<IBANTextInput
									maxWidth="unset"
									name={RIBS_FIELDS.IBAN}
									label={intl.formatMessage({ id: 'personalInfo.bankInfo.input.iban.label' })}
									popoverContent={
										<Typography variant="subtitle2">
											<FormattedMessage id="personalInfo.bankInfo.input.iban.helper" />
										</Typography>
									}
									onChange={(value)=>setPjObligatoire(isPJObligatoire(value))}
									defaultValue={(formType !== 'add' && ribInfo?.clefIban && ribInfo?.bban) ? `${ribInfo.clefIban} ${ribInfo.bban}` : undefined}
								/>
								<TextInput
									maxWidth="unset"
									name={RIBS_FIELDS.BANK}
									label={intl.formatMessage({ id: 'personalInfo.bankInfo.input.bank.label' })}
									defaultValue={formType !== 'add' ? ribInfo?.domiciliation : undefined}
								/>
								<SelectInput
									name={RIBS_FIELDS.OWNER}
									label={intl.formatMessage({ id: 'personalInfo.bankInfo.input.owner.label' })}
									data={optionsTitulaires}
									defaultValue={(optionsTitulaires.length > 0 && formType !== 'add' && defaultTitulaire) ? defaultTitulaire : undefined}
									disabled={formType !== 'add' && ribInfo?.numindiv === assurePrincipal.numIndividu}
								/>
								<DatePickerInput
									maxWidth="unset"
									name={RIBS_FIELDS.STARTING_DATE}
									label={intl.formatMessage({ id: 'personalInfo.bankInfo.input.startingDate.label' })}
									helperText={intl.formatMessage({ id: 'personalInfo.bankInfo.input.startingDate.helper' })}
									initialValue={startingDate.toLocaleDateString('fr-FR')}
									minDate={startingDate}
									maxDate={endingDate}
								/>
								{(pjObligatoire || formType === 'modify_cotisation') &&
									<FileInput
										name={RIBS_FIELDS.RIB}
										title={intl.formatMessage({ id: 'personalInfo.bankInfo.input.ribFile.title' })}
										label={intl.formatMessage({ id: 'personalInfo.bankInfo.input.ribFile.label' })}
										required
									/>
								}
								{formType !== 'modify_cotisation' &&
									<Stack direction="column">
										<Typography variant="body2">
											<FormattedMessage id="personalInfo.bankInfo.input.beneficiaries.label" />
										</Typography>
										<CheckboxInput
											name={RIBS_FIELDS.BENEFICIARIES}
											data={beneficiairesWithDisabled}
											error={errors ? errors[RIBS_FIELDS.BENEFICIARIES] : []}
										/>
									</Stack>
								}

								<Stack alignItems="center">
									<ButtonDefault
										variant="contained"
										onClick={() => {
											if (hasValidationErrors){
												// Pour faire l'affichage des erreur
												handleSubmit()
											} else {
												if (ribInfo.type === REMBOURSEMENTS && profile.virementCotisation && ribInfo.numindiv === assurePrincipal.numIndividu) {
													setOpenCotisation(true)
												} else {
													handleSubmit()
												}
											}
										}}
									>
										{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
									</ButtonDefault>
								</Stack>
								<Popup open={openCotisation} setOpen={setOpenCotisation} title={intl.formatMessage({ id: 'personalInfo.bankInfo.input.cotisation.title' })}>
									<Stack direction="column" spacing="12px">
										<Typography variant="body2">
											<FormattedMessage id="personalInfo.bankInfo.input.cotisation.content" />
										</Typography>
										<RadioInput
											direction="row"
											name={RIBS_FIELDS.EDIT_COTISATION_TOO}
											data={[
												{
													label: 'Oui',
													value: 'OUI'
												},
												{
													label: 'Non',
													value: 'NON'
												}
											]}
										/>
										<Stack alignItems="center">
											<ButtonDefault
												variant="contained"
												type="submit"
												onClick={() => {
													handleSubmit()
												}}
											>
												{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
											</ButtonDefault>
										</Stack>
									</Stack>
								</Popup>
							</Stack>
						</form>
					)}}
				/>
			</Popup>
			<ConfirmationPopin
				title={title}
				open={openConfirmation}
				setOpen={setOpenConfirmation}
				showActions={false}
				goHomeOnClose={false}
			/>
		</>
	)
}

const mapStateToProps = (state: any) => ({
	profile: profileSelector(state),
	codesBanque: cardiboxSelectors.valuelists.getByShortcut(state.cardibox).rib_code_banque
})

const actions = {
	getValueListByShortcut : cardiboxActions.valuelists.getByShortcut
}

export default connect(
	mapStateToProps,
	actions
)(BankActionFormPopin)
