import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import Popup from '../../../../components/Popup'
import { Stack, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../../../../components/ButtonDefault'

const GA_CATEGORY = 'Aide et contact'

type HelpPopinProps = {
	showHelpDuration: number,
	showActionsInital: boolean,
	setOpenPopinClick?: any,
	openPopinClick?: boolean,
}

const HelpPopin: React.FC<HelpPopinProps> = (
	{
		showHelpDuration,
		showActionsInital,
		setOpenPopinClick = false,
		openPopinClick
	}
) => {
	const { isMobile } = useBreakpoints()
	const [showActions, setShowActions] = useState<boolean>(showActionsInital)
	const [openPopin, setOpenPopin] = useState<boolean>(false)
	const theme = useTheme()

	useEffect(() => {
		if (showHelpDuration > 0) {
			setTimeout(() => {
				setOpenPopin(true)
			}, showHelpDuration * 1000 * 60)
		} else {
			if(showActionsInital){
				setOpenPopinClick(true)
			} else {
				setOpenPopin(true)
			}
		}
	}, [showHelpDuration])

	const showNumber = () => {
		ReactGA.event({
			category: GA_CATEGORY,
			action: 'Besoin d\'aide'
		})
		setShowActions(true)
	}

	const closePopin = () => {
		if (!showActions) {
			ReactGA.event({
				category: GA_CATEGORY,
				action: 'Pas besoin d\'aide'
			})
		}
		if(showActionsInital){
			setOpenPopinClick(false)
		} else {
			setOpenPopin(false)
		}
	}

	const onClick = (mobile: boolean) => {
		ReactGA.event({
			category: GA_CATEGORY,
			action: `Appeler Gerep ${mobile ? 'mobile' : 'desktop'}`
		})
	}

	return (
		// @ts-ignore
		<Popup open={showActionsInital ? openPopinClick : openPopin} setOpen={closePopin} title={showActionsInital ? "Contacter le support" : "Besoin d'aide ?"}>
			<Stack direction="column" spacing="10px">
				{!showActions &&
					<Stack direction={isMobile ? 'column' : 'row'} spacing="10px" justifyContent="space-around">
						<ButtonDefault
							fullWidth={isMobile}
							variant="outlined"
							onClick={() => {
								closePopin()
							}}
						>
							<FormattedMessage id="global.input.no" />
						</ButtonDefault>
						<ButtonDefault
							fullWidth={isMobile}
							variant="contained"
							onClick={() => {
								showNumber()
							}}
						>
							<FormattedMessage id="global.input.yes" />
						</ButtonDefault>
					</Stack>
				}
				{showActions &&
					<div>
						<Stack direction="column" sx={{ paddingBottom: '10px' }}>
							<p>
								<span>Notre équipe est à votre disposition au <a
									href="tel:01-45-22-52-53"
									onClick={() => onClick(isMobile)}
									style={{ color: theme.palette.secondary.main, textDecoration: 'none' }}
								><b>01 45 22 52 53</b></a>&nbsp;:</span>
							</p>
						</Stack>
						<ul>
							<li style={{ paddingBottom: '5px' }}>du lundi au jeudi, de 8h30 à 12h30 et de 13h30 à 18h</li>
							<li>le vendredi, de 8h30 à 12h30 et de 13h30 à 17h30</li>
						</ul>
					</div>
				}
			</Stack>
		</Popup>
	)
}

export default HelpPopin
