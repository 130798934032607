import { Stack, Typography } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export default class demandeUtils {
	static demandStatus = (status: number, options: {
		variant?: string,
		fontWeight?: number,
		withoutPadding?: boolean,
		showDash?: boolean
	}) => {
		switch (status) {
			case 1:
			case 2:
			case 7:
				return (
					<Stack>
						{/*// @ts-ignore*/}
						<Typography noWrap variant={options?.variant ? options?.variant : 'subtitle1'} fontWeight={options?.fontWeight ? options?.fontWeight : 500}
						            sx={options?.withoutPadding ? { color: '#e96e11' } : { color: '#e96e11', marginLeft: '4px' }}>
							<FormattedMessage id="dashboard.demands.status.pending" />
						</Typography>
					</Stack>
				)
			case 3:
			case 5:
			case 6:
			case 8:
				return (
					<Stack>
						{/*// @ts-ignore*/}
						<Typography noWrap variant={options?.variant ? options?.variant : 'subtitle1'} fontWeight={options?.fontWeight ? options?.fontWeight : 500}
						            sx={options?.withoutPadding ? { color: '#4caf50' } : { color: '#4caf50', marginLeft: '4px' }}>
							<FormattedMessage id="dashboard.demands.status.done" />
						</Typography>
					</Stack>
				)
			default:
				return <></>
		}
	}

	static demandLabelStatus = (status: number) => {
		switch (status) {
			case 1:
			case 2:
			case 7:
				return 'En cours'
			case 3:
			case 5:
			case 6:
			case 8:
				return 'Traité'
			default:
				return ''
		}
	}
}
