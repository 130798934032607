import {types} from './carteTiersPayantActions'
import {types as loginTypes} from '../../../univers/ui/authentication/services/login/loginActions'
import {combineReducers} from 'redux'
import appConst from '../../../constant/appConstants';

const data = (state = [], action) => {
    switch (action.type) {
        case types.GET_CARTE_TP: {
            return action.payload
        }
        case loginTypes.LOGOUT: {
            return []
        }
        default:
            return state
    }
}

const loading = (state = false, action) => {
    switch (action.type) {
        case types.GET_CARTE_TP_LOADING: {
            return action.payload
        }
        case loginTypes.LOGOUT: {
            return false
        }
        default:
            return state
    }
}

const expirationDate = (state = 0, action) => {
    switch (action.type) {
        case types.GET_CARTE_TP: {
            return (+new Date() + appConst.cacheDuration.carteTp * 1000)
        }
        case loginTypes.LOGOUT: {
            return 0
        }
        default:
            return state
    }
}

export default combineReducers({
    data,
    loading,
    expirationDate
})
