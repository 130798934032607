import React, { useContext, useState } from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import { Box, CircularProgress, Divider, Stack, Typography, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import ButtonDashboard from '../../../../components/ButtonDashboard'
import { mdiBellRing } from '@mdi/js'
import Icon from '@mdi/react'
import { getSuffix, getUrlLink } from '../../../webservices/notifications/NotificationUtils'
import Loader from '../../../../components/Loader'
import RightDrawer from '../../../../components/RightDrawer'
import { useHistory } from 'react-router-dom'
import { downloadNotification } from '../../../webservices/notifications/NotificationActions'
import { ToastContext } from '../../../../components/toast/ToastContext'
import { ToastType } from '../../../../components/toast/toastConstants'
import notificationBell from '../../../../resources/animation/notif_bell.json'
import Lottie from 'react-lottie'

type NotificationsProps = {
	notifications: any[]
}

const Notifications: React.FC<NotificationsProps> = ({ notifications }) => {
	const theme = useTheme()
	const intl = useIntl()
	const history = useHistory()
	const { addToast } = useContext(ToastContext)

	const [openModal, setOpenModal] = useState<boolean>(false)
	const [isNotifLoading, setIsNotifLoading] = useState<boolean>(false)

	if (!notifications || notifications.length === 0) {
		return <Loader />
	}

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: notificationBell,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	}

	return (
		<>
			<DashboardCard height="fit-content" cardContentPadding="24px">
				<Stack
					spacing="25px"
					width="100%"
					alignItems="flex-start"
					direction="column"
					justifyContent="center"
				>
					<Stack direction="row" width="100%" paddingRight="-24px">
						{notifications[0] &&
							<Stack direction="column" spacing="4px" width="100%" flex={2}>
								<Typography variant="h4">
									{intl.formatMessage({ id: `notification.${getSuffix(notifications[0])}.title` }, { ...notifications[0].data })}
								</Typography>
								<Typography variant="body2"
								            sx={{
									            overflow: 'hidden',
									            textOverflow: 'ellipsis',
									            display: '-webkit-box',
									            WebkitBoxOrient: 'vertical',
									            WebkitLineClamp: 3
								            }}>
									<FormattedMessage
										id={`notification.${getSuffix(notifications[0])}.label`}
										values={{
											...notifications[0].data,
											date: new Date(notifications[0].data.date)
										}}
									/>
								</Typography>
							</Stack>
						}
						<Box flex={1}>
							<Lottie
								options={defaultOptions}
								height={124}
								width={124}
								style={{position: 'absolute', marginTop: '-74px' }} />
						</Box>
					</Stack>
					<Box width="100%" justifyContent="center" display="flex">
						<ButtonDashboard onClick={() => setOpenModal(true)} variant="outlined" size="small" sx={{ width: '100%' }}>
							<FormattedMessage id="dashboard.notification.buttons.displayAlert" />
						</ButtonDashboard>
					</Box>
				</Stack>
			</DashboardCard>
			<RightDrawer openModal={openModal} setOpenModal={setOpenModal} title={intl.formatMessage({ id: 'dashboard.notification.drawer.title' })}>
				{notifications.map((notification, index) => {
					const domain = getSuffix(notification)
					const urlLink = getUrlLink(notification)

					return (
						<Stack key={index} direction="column" width="100%">
							{index !== 0 && <Divider sx={{ color: '#dedede', marginBottom: '24px' }} />}
							<Stack mb="16px" direction="row" spacing="16px" width="100%">
								<Icon path={mdiBellRing} size="24px" color={theme.palette.secondary.main} />
								<Stack direction="column" spacing="8px" width="100%">
									<Typography variant="h4">
										{intl.formatMessage({ id: `notification.${domain}.title` }, { ...notification.data }).toUpperCase()}
									</Typography>
									<Typography variant="body2">
										<FormattedMessage
											id={`notification.${domain}.label`}
											values={{
												...notification.data,
												date: new Date(notification.data.date)
											}}
										/>
									</Typography>
								</Stack>
							</Stack>
							{(notification.data?.url || urlLink) && (
								<Box width="100%" justifyContent="flex-end" display="flex">
									<ButtonDashboard
										variant="outlined"
										size="small"
										onClick={() => {
											if (urlLink) {
												history.push(urlLink)
											} else if (notification.data?.url) {
												setIsNotifLoading(true)
												downloadNotification(notification.data?.url)
													.catch(() => addToast(ToastType.ERROR, 'global.error.occurred'))
													.finally(() => setIsNotifLoading(false))
											}
										}}
									>
										{isNotifLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="dashboard.notification.buttons.details" />}
									</ButtonDashboard>
								</Box>
							)}
						</Stack>
					)
				})}
				<Divider sx={{ color: '#dedede', marginTop: '24px' }} />
			</RightDrawer>
		</>
	)
}

export default Notifications
