import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const ButtonLink = styled(ButtonMUI)<ButtonProps>(({ theme }) => ({
	'&.MuiButton-root': {
		borderRadius: 24,
		height: 'auto',
		backgroundColor: 'white',
		color: theme.palette.primary.main,
		textAlign: 'left',
		fontSize: '14px',
		fontWeight: 500,
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(205, 218, 231, 0.6)',
			boxShadow: 'none'
		}
	}
}))

export default ButtonLink
