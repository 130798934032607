import { getDemandesExpirationDate, isDemandesLoading } from './demandesSelectors'
import DemandeServices from '../../../api/gerep/DemandeServices';
import LocalStorage from '../../../business/storage/LocalStorage';

const ACTION_NAMESPACE = '/DEMANDES'

export const types = {
	GET_DEMANDES: `${ACTION_NAMESPACE}/GET_DEMANDES`,
	GET_DEMANDES_LOADING: `${ACTION_NAMESPACE}/GET_DEMANDES_LOADING`
}

export const getDemandes = () => (dispatch, getState) => {
	if (!isDemandesLoading(getState()) &&
		(!getDemandesExpirationDate(getState()) ||
			getDemandesExpirationDate(getState()) < (+new Date()))) {
		dispatch({
			type: types.GET_DEMANDES_LOADING,
			payload: true
		})
		return DemandeServices.getHistoriqueDemandes(LocalStorage.getToken())
			.then((demandes) => {
				dispatch({
					type: types.GET_DEMANDES_LOADING,
					payload: false
				})
				dispatch({
					type: types.GET_DEMANDES,
					payload: demandes
				})
				return demandes
			}).catch(e => {
				dispatch({
					type: types.GET_DEMANDES_LOADING,
					payload: false
				})
			})
	}
}

export const forceGetDemandes = () => (dispatch, getState) => {
	dispatch({
		type: types.GET_DEMANDES_LOADING,
		payload: true
	})
	return DemandeServices.getHistoriqueDemandes(LocalStorage.getToken())
		.then((demandes) => {
			dispatch({
				type: types.GET_DEMANDES_LOADING,
				payload: false
			})
			dispatch({
				type: types.GET_DEMANDES,
				payload: demandes
			})
			return demandes
		}).catch(e => {
			dispatch({
				type: types.GET_DEMANDES_LOADING,
				payload: false
			})
		})
}

export const downloadDocument = (idDemande, idDocument) => (
	DemandeServices.downloadDocument(idDemande, idDocument, LocalStorage.getToken())
)
