export type AccessParam = {
    codeAccess: string
}

export enum IDENTIFICATION_FIELDS {
    COMPANY_NAME = 'entreprise',
    BENEFICIARY_TYPE = 'categorieBenef',
    CIVILITY = 'civilite',
    SEX = 'sexe',
    LAST_NAME = 'nom',
    FIRST_NAME = 'prenom',
    SOCIAL_SECURITY_NUMBER = 'niss',
    BIRTH_DATE = 'dateNaissance',
    TERMS_AND_CONDITIONS =  'contractTerms&Conditions',
    CONTRACT_MANAGEMENT = 'acceptRGPD',
    GENERAL_USE = 'acceptCGU'
}
export enum INFOS_PERSOS_FIELDS {
    RANG = 'rangNaissance',
    SOCIAL_SECURITY_ATTESTATION = 'fichierSS',
    TELETRANSMISSION = 'activeTeletransmission',
    ADRESS1 = 'adresse',
    ADRESS2 = 'adresse2',
    ADRESS3 = 'adresse3',
    ZIP_CODE = 'codePostal',
    CITY = 'ville',
    COUNTRY = 'pays',
    PHONE = 'telephone',
    MAIL = 'email'
}
export enum BANK_FIELDS {
    IBAN = 'iban',
    BANK_NAME = 'banque',
    ACCOUNT_OWNER = 'titulaire'
}
export enum CONTRACT_FIELDS {
    NAME = 'contrat',
    COVERAGE = 'couverture',
    AFFILIATION_START = 'dateDebutAffl',
    MORE_BENEFICIARY = 'declareBeneficiaires'
}

export enum BENEFICIARY_PREAFF_FIELDS {
    NAME = 'beneficiaires',
    TYPES = 'type',
    RANG = 'rangNaissance',
    COVERAGE = 'couverture',
    CIVILITY = 'civilite',
    SEX = 'sexe',
    LAST_NAME = 'nom',
    FIRSTNAME = 'prenom',
    BIRTH_DATE = 'dateNaissance',
    NISS = 'niss',
    NISS_PARENT = 'niss2',
    NISS_ATTESTATION = 'fichierSS',
    FILES = 'extraFile',
    TELETRANSMISSION = 'activeTeletransmission'
}

export enum DISPENSE_PREAFF_FIELDS {
    EXEMPTION_WISH = 'souhaitDispense',
    MOTIF = 'justificationDemande',
    START_DATE = 'dateDebutDispense',
    ORGANISM = 'orgasnismeEnCharge',
    END_DATE = 'dateFinDispense',
    SITUATION_CHANGEMENT = 'informChangementSituation',
    CHOICE_CONSEQUENCIES = 'acceptConsequencesDispense',
    DOWNLOAD_ATTESTATION = 'telechargementAttestation',
    ENGAGEMENT_ATTESTATION = 'engagementTransmission'
}


export const TERMS_AND_CONDITIONS_OPTIONS = {
    CONTRACT_MANAGEMENT: 'contractManagement',
    GENERAL_USE: 'generalUse'
}

export const generalUseUrl = 'https://services.gerep.fr/blobs/medias/s/383736018900000a/Charte_utilisation_Application_IRIS_FINAL_(2).pdf'
export const ameliUrl = 'https://www.ameli.fr/assure'
export const rgpdLink = 'http://www.gerep.fr/protection-donnees.html'

export const CIVILITE = {
    MS: {
        label: 'Madame',
        value: '2'
    },
    MR: {
        label: 'Monsieur',
        value: '1'
    }
}
export const CIVILITES = [
    CIVILITE.MS,
    CIVILITE.MR
]

export const SEXE = {
    FEMININ: {
        label: 'Féminin',
        value: '2'
    },
    MASCULIN: {
        label: 'Masculin',
        value: '1'
    }
}
export const SEXES = [
    SEXE.FEMININ,
    SEXE.MASCULIN
]

// La question est inversée par rapport au webservice
// TODO : interchanger les val si besoin
export const BOOLEAN = {
    OUI: {
        label: 'Oui',
        value: 'OUI'
    },
    NON: {
        label: 'Non',
        value: 'NON'
    }
}
export const BOOLEANS = [
    BOOLEAN.OUI,
    BOOLEAN.NON
]


export const TWO_FIRST_STEPS = {
    IDENTIFICATION: 0,
    INFOS_PERSO: 1,
}
export const STEPS_AFFIL_BASE = {
    ...TWO_FIRST_STEPS,
    BANK_INFOS: 2,
    BENEFICIAIRE: 3,
    CONFIRMATION: 4,
}
export const STEPS_DISPENSE = {
    ...TWO_FIRST_STEPS,
    EXEMPTION_CHOICE: 2,
    CONFIRMATION: 3,
}

export const STEP_STATUS = {
    NEXT: 'NEXT',
    ERROR: 'ERROR'
}




