import { createSelector } from 'reselect'
import { getEnfantMajeurList } from '../../webservices/affiliation/affiliationSelector';
import FormatUtils from '../../../utils/FormatUtils';

// TODO repasser sur tout le code pour s'assurer que c'est bien le sélecteur qui est utilisé
export const userSelector = state => state.user

export const dateDebutContratSelector = createSelector([
	userSelector
], (user) => {
	if (user && user.dateDebutContrat) {
		return FormatUtils.toLocalDate(user.dateDebutContrat)
	}
	return null
})

const emptyProfile = {}
export const profileSelector = createSelector([
	userSelector,
	getEnfantMajeurList
], (user, enfantsMajeurs) => {
	const userProfile = user.profil || emptyProfile
	const {
		santeclair,
		itelis
	} = userProfile
	return {
		...userProfile,
		enfantMajeur: enfantsMajeurs && enfantsMajeurs.length > 0,
		// RG: Itelis ne peut pas être affiché seul
		sansReseauSoins: !santeclair && !itelis
	}
})

export const profileSelectorKpi = createSelector([
	userSelector,
], (user, enfantsMajeurs) => {
	const userProfile = user.profil || emptyProfile
	return {
		...userProfile
	}
})
