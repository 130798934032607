import { createSelector } from 'reselect'
import { Map } from 'immutable'
// const
import { TypeAdherent } from '../../../constant/TypeAdherent'

export const getAffiliation = state => state.pages.affiliation
const getBeneficiaires = state => state.pages.beneficiaires
export const getAdhesion = state => state.pages.adhesion
export const getPreSetFields = state => state.pages.preremplissage

export const getDateFinDuContratEnVigueur = createSelector([
	getAffiliation
], (affiliation) => {
	if (affiliation && affiliation.contratEnVigueur) {
		return affiliation.contratEnVigueur.dateFin
	}
})

export const getAdherentPrincipal = createSelector([
	getBeneficiaires
], (beneficiaires) => {
	if (!beneficiaires || !beneficiaires.length) {
		return null
	}
	return beneficiaires.find(contract => contract.typBene === TypeAdherent.ASSURE_PRINCIPAL)
})

export const getMapCouvertures = createSelector([
	getBeneficiaires
], (beneficiaires) => {
	return (beneficiaires || []).reduce((map, benef) => {
		const couvertures = benef.contrats && benef.contrats[0] && benef.contrats[0].garanties || []
		return map.set(benef.typBene, couvertures)
	}, new Map())
})

export const getMapContrat = createSelector([
	getBeneficiaires
], (beneficiaires) => {
	return (beneficiaires || []).reduce((map, benef) => {
		const contrat = benef.contrats && benef.contrats[0] || {}
		console.log("contrat2", contrat)
		return map.set(benef.typBene, contrat)
	}, new Map())
})
