import React, { useMemo } from 'react'
import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionDetailsProps, AccordionProps, AccordionSummary as MuiAccordionSummary, AccordionSummaryProps, Box, Stack, styled, Typography, useTheme } from '@mui/material'
import AppBarButton from './AppBarButton'
import { useLocation } from 'react-router-dom'
import AppBarSubButton from './AppBarSubbutton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Icon from '@mdi/react'

type AppMenuCellProps = {
	content: DrawerContent
	expanded?: boolean
	onChange?: (event: React.SyntheticEvent, expanded: boolean) => void
}

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props}>
		{props.children}
	</MuiAccordion>
))(() => ({
	border: 'none',
	'&:not(:last-child)': {
		borderBottom: 0
	},
	'&:before': {
		display: 'none'
	}
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		{...props}
	/>
))(() => ({
	backgroundColor: 'transparent',
	minHeight: 48,
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	paddingLeft: '8px',
	paddingRight: '8px',
	alignItems: 'center',
	justifyContent: 'space-between',
	borderRadius: '8px',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(-90deg)'
	}
}))

const AccordionDetails = styled((props: AccordionDetailsProps) => (
	<MuiAccordionDetails
		{...props}
	/>
))(() => ({
	paddingBottom: 0
}))

const AppMenuCell: React.FC<AppMenuCellProps> = (
	{
		content,
		expanded,
		onChange
	}
) => {
	const theme = useTheme()
	const location = useLocation()
	const currentPath = useMemo(() => location.pathname, [location.pathname])

	const isPageActive = useMemo(() => currentPath.includes(content.path), [currentPath, content.path])
	const buttonColor = useMemo(() => isPageActive ? 'secondary' : 'inherit', [isPageActive])

	if (content.content.length > 0) {
		return (
			<Accordion expanded={expanded} onChange={onChange}>
				<Box>
					<AccordionSummary
						sx={{ border: isPageActive ? `1px solid ${theme.palette.secondary.main}` : 'none' }}
						expandIcon={<ChevronRightIcon color={buttonColor} />}
					>
						<Stack direction="row" spacing={1} alignItems="center">
							<Icon path={content.icon} size="20px" color={isPageActive ? theme.palette.secondary.main : '#232323'} />
							<Typography fontWeight={isPageActive ? 500 : 400} fontSize="0.9375rem" color={isPageActive ? theme.palette.secondary.main : '#232323'}>{content.label}</Typography>
						</Stack>
					</AccordionSummary>
				</Box>
				{content.content.map((cell, index) => {
					const isSecondaryPageActive = currentPath.includes(cell.path)

					return (
						cell.show ?
							<AccordionDetails key={index}>
								<Box>
									<AppBarSubButton
										color="inherit"
										startIcon={
											<Box
												width={0}
												mr="8px"
												height="32px"
												bgcolor={isPageActive && isSecondaryPageActive ? theme.palette.secondary.main : 'transparent'}
												border={isPageActive && isSecondaryPageActive ? `2px solid ${theme.palette.secondary.main}` : 'transparent'}
											/>
										}
										onClick={cell.onClick}
									>
										<Typography fontWeight={isPageActive && isSecondaryPageActive ? 500 : 400} fontSize="0.8125rem">
											{cell.label}
										</Typography>
									</AppBarSubButton>
								</Box>
							</AccordionDetails> :
							<Stack key={index} />
					)
				})}
			</Accordion>
		)
	}

	return (
		<Box>
			<AppBarButton
				color={buttonColor}
				sx={{ border: isPageActive ? `1px solid ${theme.palette.secondary.main}` : '', paddingLeft: '12px', marginRight: '12px' }}
				onClick={content.onClick}
				startIcon={<Icon path={content.icon} size="20px" color={isPageActive ? theme.palette.secondary.main : '#232323'} />}
			>
				<Typography color={isPageActive ? theme.palette.secondary.main : '#232323'} fontWeight={isPageActive ? 500 : 400} fontSize="0.9375rem">{content.label}</Typography>
			</AppBarButton>
		</Box>
	)

}

export default AppMenuCell
