import React from 'react'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type WorkingFormProps = {
	type?: 'working' | 'other'
}

const RadiationContent: React.FC<WorkingFormProps> = ({ type = 'working' }) => {
	return (
		<Stack direction="column" spacing="24px">
			<Stack direction="column" spacing="12px">
				<Typography variant="body2" fontWeight={500}>
					{type === 'other' ? (
						<FormattedMessage id="maintenirEnfantMajeur.input.radiation.description.other" />
					) : (
						<FormattedMessage id="maintenirEnfantMajeur.input.radiation.description.working" />
					)}
				</Typography>
				<Typography variant="body2">
					<FormattedMessage id="maintenirEnfantMajeur.input.radiation.description.1" />
				</Typography>
			</Stack>

			<Stack direction="column" spacing="12px">
				<Typography variant="body2">
					<FormattedMessage id="maintenirEnfantMajeur.input.radiation.description.2" />
				</Typography>
				<Typography variant="body2">
					<FormattedMessage id="maintenirEnfantMajeur.input.radiation.description.3" />
				</Typography>
			</Stack>
		</Stack>
	)
}

export default RadiationContent
