import React, {useContext, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {CircularProgress, Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import {userSelector} from '../../user/userSelectors'
import Loader from '../../../../components/Loader'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import * as echeancierActions from '../../../webservices/echeancier/echeancierActions'
import {getRibCotisation} from '../../../webservices/rib/cotisation/ribCotisationActions'
import {downloadEcheancierCotisations} from '../../../webservices/contrats/contratsActions'
import {
	getEcheancier,
	getModeReglement,
	getRumList,
	isEcheancierLoading
} from '../../../webservices/echeancier/echeancierSelector'
import {getRib, isRibLoading} from '../../../webservices/rib/cotisation/ribCotisationSelector'
import TableauEcheancier from '../components/TableauEcheancier'
import InfoField from '../../../../components/InfoField'
import {NATURE} from '../../../webservices/rib/ribConsts'
import FormatUtils from '../../../../utils/FormatUtils'
import ButtonDefault from '../../../../components/ButtonDefault'
import PageLayout from '../../../../components/PageLayout'
import {ToastType} from '../../../../components/toast/toastConstants'
import {ToastContext} from '../../../../components/toast/ToastContext'


const EcheancierCotisationPage = (
	{
		// @ts-ignore
		loading,
		// @ts-ignore
		echeancier,
		// @ts-ignore
		loadEcheancier,
		// @ts-ignore
		loadEcheancierIntro,
		// @ts-ignore
		getRibCotisation,
		// @ts-ignore
		modeReglement,
		// @ts-ignore
		rumList,
		// @ts-ignore
		rib,
		// @ts-ignore
		downloadEcheancierCotisations
	}
) => {

	const intl = useIntl()
	const { isMobile, isDesktop, isLaptop, isWidescreen } = useBreakpoints()
	const isDesktopOrLaptop = isDesktop || isLaptop || isWidescreen
	const prelevementBancaire = modeReglement === NATURE.VIREMENT
	const [loadingDlEcheancier, setLoadingDlEcheancier] = useState<boolean>(false)
	const { addToast } = useContext(ToastContext)
	const [txtContenu, setTxtContenu] = useState<any>(null)

	const onDlEcheancier = () => {
		setLoadingDlEcheancier(true)
		downloadEcheancierCotisations(callBackonDlEcheancier, callBackonDlEcheancierError).catch((e: any) => {
			console.error(e)
			addToast(ToastType.ERROR, 'echeancier.downloadError')
			setLoadingDlEcheancier(false)
		})
	}

	const callBackonDlEcheancier = () => {
		setLoadingDlEcheancier(false)
	}

	const callBackonDlEcheancierError = () => {
		addToast(ToastType.ERROR, 'echeancier.downloadError')
		setLoadingDlEcheancier(false)
	}

	useEffect(() => {
		getRibCotisation()
		loadEcheancier()
	}, [getRibCotisation, loadEcheancier])

	useEffect(() => {
		if (!txtContenu) {
			loadEcheancierIntro().then((echeancierIntro:any) => {
				setTxtContenu(echeancierIntro)
			})
		}
	}, [loadEcheancierIntro])

	return (
		<PageLayout title={intl.formatMessage({ id: 'echeancier.title' })}>
			{
				loading ? (
					<Stack direction="column" spacing="24px">
						<Loader />
					</Stack>
				) : (
					(echeancier.length > 0) &&
					<>
						<Stack direction={isDesktopOrLaptop ? 'row' : 'column'} spacing="24px" pb="10px">
							<Typography alignSelf="center" flex={10} variant="h2">
								<FormattedMessage id="echeancier.subtitle" />
							</Typography>
							<ButtonDefault
								variant="outlined"
								onClick={onDlEcheancier}
							>
								{loadingDlEcheancier ?
									<CircularProgress size={24} color="inherit" /> :
									<FormattedMessage id="echeancier.download" />
								}
							</ButtonDefault>
						</Stack>
						<Stack width="100%" direction="column" pb="10px" spacing="16px" justifyContent="space-between">
							<InfoField
								label={intl.formatMessage({ id: 'echeancier.rum.mode' })}
								value={prelevementBancaire ? 'Prélèvement bancaire' : 'Chèque'}
								variantValue="body1"
							/>
							{
								prelevementBancaire && (
									React.Children.toArray([
										<InfoField
											label={intl.formatMessage({ id: 'echeancier.rum.titulaire' })}
											value={rib.intitule}
											variantValue="body1"
										/>,
										<InfoField
											label={intl.formatMessage({ id: 'echeancier.rum.iban' })}
											value={`${rib.clefIban} ${FormatUtils.formatBban(rib.bban, true)}`}
											variantValue="body1"
										/>,
										<InfoField
											label={intl.formatMessage({ id: 'echeancier.rum.banque' })}
											value={rib.domiciliation}
											variantValue="body1"
										/>,
										(rumList && rumList.length) > 0 && (
											<Stack direction="column" flex={1}>
												<Typography flex={1} variant="body2">
													<FormattedMessage id="echeancier.rum.sepa" />
												</Typography>
												{
													rumList.map((r: any, index: number) => (
														<Typography key={index} flex={1} variant="body1" fontSize={isMobile ? '0.875rem' : '1rem'} sx={{ wordBreak: 'break-word' }}>
															{r.rum} pour le contrat n° {r.contrat}
														</Typography>
													))
												}
											</Stack>
										)
									])
								)
							}
						</Stack>
						<Stack direction="row" spacing="24px" pb="10px">
							{txtContenu && <div dangerouslySetInnerHTML={{__html: txtContenu.fields.body}}/>}
						</Stack>
						<Stack direction="row" pt="10px" spacing="24px">
							<TableauEcheancier echeancier={echeancier} />
						</Stack>
					</>
				)
			}
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	user: userSelector(state),
	loading: isEcheancierLoading(state) || isRibLoading(state),
	echeancier: getEcheancier(state),
	rumList: getRumList(state),
	modeReglement: getModeReglement(state),
	rib: getRib(state)
})

const actions = {
	loadEcheancier: echeancierActions.loadEcheancier,
	loadEcheancierIntro: echeancierActions.loadEcheancierIntro,
	getRibCotisation,
	downloadEcheancierCotisations
}

export default connect(mapStateToProps, actions)(EcheancierCotisationPage)
