import React, { useEffect } from 'react'
import PageLayout from '../../../../components/PageLayout'
import { useIntl } from 'react-intl'
import { getIndividuListForRefund } from '../../../webservices/affiliation/affiliationSelector'
import { connect } from 'react-redux'
import * as affiliationAction from '../../../webservices/affiliation/affiliationActions'
import DemandRefundForm from '../components/DemandRefundForm'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import appConst from '../../../../constant/appConstants'

const ARTICLE_SNACKBAR_INFO = 'demande_remboursement'

const DemandRefundPage = (
	{
		// @ts-ignore
		individuList,
		// @ts-ignore
		getAffiliation,
		// @ts-ignore
		snackbarInfo,
		// @ts-ignore
		getArticleByShortcut
	}
) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()

	useEffect(() => {
		getAffiliation()
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_SNACKBAR_INFO)
	}, [getAffiliation, getArticleByShortcut])

	return (
		<PageLayout title={intl.formatMessage({ id: 'demandRefund.title' })} bodyPadding={isMobile || isTabletPortrait ? '32px 32px 12px 32px' : '32px 32px 0 32px'}>
			<DemandRefundForm individuList={individuList} snackbarInfo={snackbarInfo} />
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	// @ts-ignore
	individuList: getIndividuListForRefund(state),
	snackbarInfo: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_SNACKBAR_INFO]
})

const actions = {
	getAffiliation: affiliationAction.getAffiliation,
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}

export default connect(
	mapStateToProps,
	actions
)(DemandRefundPage)