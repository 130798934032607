import React, { Dispatch, SetStateAction, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ConfirmationAuthent from '../../../../components/doubleAuthentComponents/ConfirmationAuthent'
import RelanceCode from '../../../../components/doubleAuthentComponents/RelanceCode'
import Popup from '../../../../components/Popup'
import { Typography } from '@mui/material'

type AuthentificationSecureEEProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	currentAuthent: string
	currentEmail: string
	token: string | undefined
	blocage?: string
	connexionIsLoading?: boolean
	onSubmit: (code: string) => void
}

const AuthentificationSecureEE: React.FC<AuthentificationSecureEEProps> = ({
	setOpen,
	open,
	onSubmit,
	currentAuthent,
	currentEmail,
	token,
	blocage,
	connexionIsLoading
}) => {
	const intl = useIntl()
	const [currentStep, setCurrentStep] = useState(1)

	const renderStep = () => {
		switch (currentStep) {
			case 1:
				return <ConfirmationAuthent
					nextStep={close}
					link={nextStep}
					currentAuthent={currentAuthent}
					email={currentEmail}
					onSubmitConnexion={onSubmit}
					connexionIsLoading={connexionIsLoading}
					espaceEntreprise={true}
				/>
			case 2:
				return <RelanceCode
					nextStep={prevStep}
					prevStep={prevStep}
					currentAuthent={currentAuthent}
					email={currentEmail}
					token={token}
					espaceEntreprise={true}
				/>
			default:
				return undefined
		}
	}

	const nextStep = () => {
		if (currentStep < 2) {
			setCurrentStep(currentStep + 1)
		}
	}

	const prevStep = () => {
		if (currentStep > 1) {
			setCurrentStep(currentStep - 1)
		}
	}

	const close = () => {
		setCurrentStep(1)
		setOpen(false)
	}

	return (
		<Popup
			open={open}
			setOpen={setOpen}
			title={intl.formatMessage({ id: 'doubleAuthent.title' })}
			width="720px"
			showCloseButton={true}
			onClose={close}
		>
			{!blocage && renderStep()}
			{
				blocage &&
				<Typography variant="body2" color="error">
					<FormattedMessage id={'doubleAuthent.confirmation.blocage'} values={{ number: blocage }} />
				</Typography>
			}
		</Popup>
	)
}

export default AuthentificationSecureEE
