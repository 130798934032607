import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Link, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../../../components/ButtonDefault'
import { MAIN_PATH } from '../../../constant/path'
import Loader from '../../../components/Loader'
import { connect } from 'react-redux'
import { getStates } from './surveillance/surveillanceActions'
import { getSurveillance, isSurveillanceLoading } from './surveillance/surveillanceSelector'

const InternalErrorPage: React.FC = (
	{
		// @ts-ignore
		surveillance,
		// @ts-ignore
		loading,
		// @ts-ignore
		getStates
	}
) => {
	const history = useHistory()

	useEffect(() => {
		getStates()
	}, [])

	if (loading) {
		return <Loader />
	} else if (surveillance && !surveillance.arthus) {
		return (
			<Stack direction="column" spacing="48px" alignItems="center">
				<Typography fontSize="5.653rem" fontWeight={600} color="secondary">
					<FormattedMessage id="internalError.title" />
				</Typography>
				<Stack direction="column" spacing="24px" justifyContent="center">
					<Typography variant="body1" textAlign="center">
						<FormattedMessage id="internalError.text.1" />
					</Typography>
					<Typography variant="body1" textAlign="center">
						<FormattedMessage id="internalError.text.2" />
					</Typography>
					<Typography variant="body1" textAlign="center">
						<FormattedMessage id="internalError.text.3" />
					</Typography>
				</Stack>
				<Box display="flex" width="100%" justifyContent="center">
					<ButtonDefault onClick={() => history.push(MAIN_PATH.DASHBOARD)} variant="text" color="primary" sx={{ bgcolor: 'transparent' }}>
						<FormattedMessage id="internalError.buttons.home" />
					</ButtonDefault>
				</Box>
			</Stack>
		)
	}

	return (
		<Stack direction="column" spacing="48px" alignItems="center">
			<Typography fontSize="5.653rem" fontWeight={600} color="secondary">
				<FormattedMessage id="internalError.title" />
			</Typography>
			<Stack direction="column" spacing="24px" justifyContent="center">
				<Typography variant="body1" textAlign="center">
					<FormattedMessage id="internalError.altText.1" />
				</Typography>
				<Typography variant="body1" textAlign="center">
					<FormattedMessage id="internalError.altText.2" />
				</Typography>
				<Typography variant="body1" textAlign="center">
					<FormattedMessage id="internalError.altText.3" values={{ link: <Link href="mailto:support@gerep.fr">support@gerep.fr</Link> }} />
				</Typography>
			</Stack>
			<Box display="flex" width="100%" justifyContent="center">
				<ButtonDefault onClick={() => history.push(MAIN_PATH.DASHBOARD)} variant="text" color="primary" sx={{ bgcolor: 'transparent' }}>
					<FormattedMessage id="internalError.buttons.home" />
				</ButtonDefault>
			</Box>
		</Stack>
	)
}

const mapStateToProps = (state: any) => ({
	surveillance: getSurveillance(state),
	loading: isSurveillanceLoading(state)
})

export default connect(
	mapStateToProps,
	{
		getStates
	}
)(InternalErrorPage)