import React, { useState } from 'react'
import { connect } from 'react-redux'
import { downloadGuide } from '../services/souscriptionActions'
import { Box, Button, CircularProgress } from '@mui/material'
import { Visibility } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'

type DownloadButtonProps = {
	onError?: () => void
	numGar: any
	siret: any
}

const DownloadGuideButton: React.FC<DownloadButtonProps> = (
	{
		// @ts-ignore
		download,
		onError,
		numGar,
		siret
	}
) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const downloadContrat = () => {
		setIsLoading(true)
		download({ numGar, siret, dateEffet: new Date().getFullYear() })
			.catch(() => {
				onError && onError()
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<Box>
			<Button
				variant="text"
				startIcon={<Visibility />}
				onClick={() => downloadContrat()}
				style={{
					borderRadius: 24,
					height: 36,
					fontSize: '15px',
					fontWeight: 500,
					textTransform: 'none',
					paddingLeft: '12px',
					paddingRight: '12px'
				}}
			>
				{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="souscription.selectOption.button.details" />}
			</Button>
		</Box>
	)
}

const mappedActions = {
	download: downloadGuide
}

export default connect(null, mappedActions)(DownloadGuideButton)