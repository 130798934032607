import React, { Dispatch, SetStateAction } from 'react'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import Popup from './Popup'
import ButtonDefault from './ButtonDefault'
import { useBreakpoints } from './breakpoints/BreakpointsProvider'
import { useHistory } from 'react-router-dom'
import { MAIN_PATH } from '../constant/path'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as demandeActions from '../ea/webservices/demandes/demandesActions'

type GoBackAction = {
	title: string
	action: () => void
}

type ConfirmationPopinProps = {
	title: string | React.ReactNode
	body?: React.ReactNode
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	showActions?: boolean
	resetForm?: () => void
	goBackAction?: GoBackAction
	onCloseAction?: () => void
	goHomeOnClose?: boolean
}

const ConfirmationPopin: React.FC<ConfirmationPopinProps> = (
	{
		title,
		open,
		setOpen,
		resetForm,
		showActions = true,
		goBackAction,
		onCloseAction,
		body,
		goHomeOnClose = true,
		// @ts-ignore
		forceGetDemandes
	}
) => {
	const { isMobile } = useBreakpoints()
	const history = useHistory()

	const onGoHome = () => {
		goHomeOnClose && history.push(MAIN_PATH.DASHBOARD)
		forceGetDemandes()
		if (onCloseAction) {
			onCloseAction()
		}
	}

	return (
		<Popup open={open} setOpen={setOpen} title={title} onClose={onGoHome}>
			<Stack direction="column" spacing="24px">
				{body ||
					<Stack direction="column" spacing="16px">
						<Typography variant="body2">
							<FormattedMessage id="beneficiary.modifyPopin.success" values={{ br: <br /> }} />
						</Typography>
						<Typography variant="body2" fontWeight={500}>
							<FormattedMessage id="beneficiary.modifyPopin.success2" />
						</Typography>
					</Stack>
				}
				{showActions &&
					<Stack direction={isMobile ? 'column' : 'row'} spacing="10px" justifyContent="space-around">
						{resetForm &&
							<ButtonDefault
								fullWidth={isMobile}
								variant="outlined"
								onClick={() => {
									resetForm()
									setOpen(false)
								}}
							>
								<FormattedMessage id="global.button.newDemand" />
							</ButtonDefault>
						}
						<ButtonDefault
							fullWidth={isMobile}
							variant="contained"
							onClick={() => {
								goBackAction ? goBackAction.action() : onGoHome()
								setOpen(false)
							}}
						>
							{goBackAction ? goBackAction.title : <FormattedMessage id="global.button.goBackToHome" />}
						</ButtonDefault>
					</Stack>
				}
			</Stack>
		</Popup>
	)
}

const actions = {
	forceGetDemandes: demandeActions.forceGetDemandes
}

export default compose(
	connect(null, actions)
)(ConfirmationPopin)
