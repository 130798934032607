import React, { useContext, useState } from 'react'
import { CircularProgress, Link, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../ButtonDefault'
import { MODIFY_AUTHENT_FIELDS } from '../../ea/ui/personalInfo/services/personalInfoConstants'
import AuthentInput from '../form/AuthentInput'
import { Form } from 'react-final-form'
import UserService from '../../api/gerep/UserService'
import LocalStorage from '../../business/storage/LocalStorage'
import { ToastType } from '../toast/toastConstants'
import { ToastContext } from '../toast/ToastContext'
import UserServiceEE from '../../api/gerepEe/UserServiceEE'
import { useBreakpoints } from '../breakpoints/BreakpointsProvider'
import { maskTelephone, maskEmail } from '../../utils/authentUtils'

type ConfirmationAuthentProps = {
	email: string
	nextStep: () => void
	prevStep?: () => void
	telephone?: string
	currentAuthent: string
	onSubmitConnexion?: (code: string) => void
	link?: () => void
	recuperation?: boolean
	espaceEntreprise?: boolean
	connexionIsLoading?: boolean
	modificationMailOuSms?: boolean
}

const ConfirmationAuthent: React.FC<ConfirmationAuthentProps> = ({
	prevStep,
	nextStep,
	onSubmitConnexion,
	link,
	currentAuthent,
	telephone,
	email,
	connexionIsLoading,
	recuperation = false,
	espaceEntreprise = false,
	modificationMailOuSms = false
}) => {
	const { addToast } = useContext(ToastContext)
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | undefined>(undefined)


	const codesTries = (codes: Record<string, string>): Record<string, string> => {
		return Object.keys(codes)
			.sort((a, b) => parseInt(a.replace('code', '')) - parseInt(b.replace('code', '')))
			.reduce((objTrie: Record<string, string>, key: string) => {
				objTrie[key] = codes[key]
				return objTrie
			}, {} as Record<string, string>)
	}

	const enregistrerAuthent = () => {
		const values = { newAuthent: currentAuthent, telephone: telephone, email: email }
		let newValues
		if (currentAuthent === 'sms') {
			const { email, ...rest } = values
			newValues = rest
		} else if (currentAuthent === 'mail') {
			const { telephone, ...rest } = values
			newValues = rest
		}
		return UserService.editAuthent({ ...newValues, recuperation: recuperation }, LocalStorage.getToken())
	}

	const onSubmit = (values: Record<string, string>) => {
		setIsLoading(true)
		const code = Object.values(codesTries(values)).join('')
		return validerCode(code, LocalStorage.getToken())
			.then(() => {
				{
					// si espace entreprise ou que l'utilisateur est en train de modifier l'email
					(espaceEntreprise || modificationMailOuSms) ? nextStep()
						: enregistrerAuthent().then(() => {
							addToast(ToastType.SUCCESS, 'doubleAuthent.confirmation.error.success')
							nextStep()
						}).catch((error) => {
							if (error?.data) {
								const errorMessages = Object.values(error.data as Record<string, string>)
								errorMessages.forEach(errorMessage => addToast(ToastType.ERROR, errorMessage))
							} else {
								addToast(ToastType.ERROR, 'global.error.occurred')
							}
						})
				}
			})
			.catch((error) => {
				if (error?.data) {
					if (error.data.blocage) {
						addToast(ToastType.ERROR, error.data.blocage)
						prevStep && prevStep()
					} else if (error.data.warning) {
						addToast(ToastType.ERROR, error.data.warning)
					} else {
						setError(error?.data.code)
					}
				} else {
					addToast(ToastType.ERROR, 'global.error.occurred')
				}
			})
			.finally(() => setIsLoading(false))
	}

	const validerCode = (code: string, token: string | null) => {
		return espaceEntreprise ?
			UserServiceEE.validateDoubleAuthent({ email: email, code: code }, token) :
			UserService.verifierCode({ code: code }, token)
	}

	const renvoyerCode = () => {
		const contact = (currentAuthent === 'sms') ? { telephone: telephone } : { email: email }
		return envoyerCode(contact, LocalStorage.getToken())
			.then(() => {
				addToast(ToastType.SUCCESS, 'personalInfo.connexionInfo.modifyAuthent.success')
			})
			.catch((error) => {
				if (error?.data) {
					return error?.data
				}
				addToast(ToastType.ERROR, 'global.error.occurred')
			})
	}

	const envoyerCode = (contact: Record<string, any>, token: string | null) => {
		return espaceEntreprise ?
			UserServiceEE.envoyerCode(contact, token) :
			UserService.envoyerCode(contact, token)
	}

	return (
		<Stack direction="column" spacing="24px">
			<Typography variant="body2">
				<FormattedMessage id={`doubleAuthent.confirmation.label.${currentAuthent}`} values={{ contact: (currentAuthent === 'sms') ? maskTelephone(telephone) : maskEmail(email) }} />
				<br />
				<FormattedMessage id={'doubleAuthent.confirmation.label.code'} />
			</Typography>
			<Stack>
				<Form
					onSubmit={onSubmitConnexion ? (values) => {
						onSubmitConnexion(Object.values(codesTries(values)).join(''))
					} : onSubmit}
					render={({ handleSubmit, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Stack direction="column" spacing="24px">
								<AuthentInput name={MODIFY_AUTHENT_FIELDS.CODE} codeLength={6} error={error} />
								<Typography variant="body2">
									<Link underline="hover" onClick={link ? () => link() : renvoyerCode} sx={{ cursor: 'pointer' }}>
										<FormattedMessage id="doubleAuthent.confirmation.renvoi" />
									</Link>
								</Typography>
								<Stack direction={(isMobile || isTabletPortrait) ? 'column' : 'row'} spacing="24px" display="flex" justifyContent="center">
									{prevStep && <ButtonDefault
										variant="outlined"
										onClick={prevStep}>
										{
											recuperation ? <FormattedMessage id="global.button.goBack" />
												: <FormattedMessage id="doubleAuthent.confirmation.button.retour" />
										}
									</ButtonDefault>}
									<ButtonDefault
										variant="contained"
										onClick={handleSubmit}
										disabled={pristine}
									>
										{(isLoading || connexionIsLoading) ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="doubleAuthent.confirmation.button.valider" />}
									</ButtonDefault>
								</Stack>
							</Stack>
						</form>
					)}
				/>
			</Stack>
		</Stack>
	)
}

export default ConfirmationAuthent
