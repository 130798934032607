import React from 'react'
import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import ButtonDashboard from './ButtonDashboard'
import Loader from './Loader'
import { useBreakpoints } from './breakpoints/BreakpointsProvider'

type DashboardCardProps = {
	title?: string
	children: React.ReactNode
	isTransparent?: boolean
	width?: string
	height?: string
	action?: ButtonAction
	loading?: boolean
	cardContentSx?: Record<string, any>
	cardSx?: Record<string, any>
	cardContentHeight?: string
	cardContentPadding?: string
}

const DashboardCard: React.FC<DashboardCardProps> = (
	{
		title,
		children,
		isTransparent = false,
		width = '100%',
		height = '234px',
		action,
		loading = false,
		cardContentSx,
		cardSx,
		cardContentHeight = 'fit-content',
		cardContentPadding = '16px 24px 24px 24px'
	}) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()

	return (
		<Card sx={{
			flex: !isMobile ? 1 : 'auto',
			borderTopLeftRadius: '32px',
			borderBottomRightRadius: '32px',
			boxShadow: isTransparent ? 0 : 1,
			bgcolor: isTransparent ? 'transparent' : '#fff',
			width: width,
			height: isMobile ? 'auto' : height,
			...cardSx
		}}>
			{title &&
				<CardHeader
					sx={{ padding: '24px 24px 0 24px' }}
					title={
						<Typography marginBottom={!isMobile ? '5px' : 0} variant="h2" pr="8px">
							{title}
						</Typography>
					}
					action={action &&
						<Box>
							<ButtonDashboard onClick={action.onClick} variant="outlined">
								{action.label}
							</ButtonDashboard>
						</Box>
					}
				/>
			}
			<CardContent
				sx={{
					height: cardContentHeight,
					display: 'flex',
					padding: !isTabletPortrait ? cardContentPadding : '8px 24px 24px 24px',
					...cardContentSx
				}}
			>
				{loading ? (
					<Loader />
				) : children}
			</CardContent>
		</Card>
	)
}

export default DashboardCard
