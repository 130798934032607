import React, { useEffect, useState } from 'react'
import ActivationAuthent from '../../../components/doubleAuthentComponents/ActivationAuthent'
import ModifyAuthent from '../../../components/doubleAuthentComponents/ModifyAuthent'
import ConfirmationAuthent from '../../../components/doubleAuthentComponents/ConfirmationAuthent'
import FinalPopinAuthent from '../../../components/doubleAuthentComponents/FinalPopinAuthent'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import Box from '@mui/material/Box'
import loginImg from '../../../resources/img/login_img.jpg'
import { alpha, Stack, Typography } from '@mui/material'
import { useBreakpoints } from '../../../components/breakpoints/BreakpointsProvider'
import { userSelector } from '../user/userSelectors'
import { UNIVERS_PARTICULIERS } from '../../../univers/constant/univers'
import { MAIN_PATH } from '../../../constant/path'
import { getArticleDoubleAuthent } from '../../webservices/articles/articleSelector'
import * as doubleAuthentActions from '../../webservices/articles/articleActions'

const DoubleAuthentificationPage = ({
	// @ts-ignore
	user,
	// @ts-ignore
	articles,
	// @ts-ignore
	loadArticles
}) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait, width, height } = useBreakpoints()
	const [currentStep, setCurrentStep] = useState(1)
	const [methode, setMethode] = useState<string>('sms')
	const [telephone, setTelephone] = useState<string>(user.telephone)
	const [email, setEmail] = useState<string>(user.email)

	useEffect(() => {
		loadArticles()
	}, [loadArticles])

	const articleParRaccourci = (articles: any[], raccourci: string) => {
		if (articles.length === 0) {
			return null
		}
		const article = articles.find((article: any) => article.raccourci === raccourci)
		if (article) {
			return article.corps
		}
		return null
	}

	const title = () => {
		switch (currentStep) {
			case 1:
				return intl.formatMessage({ id: 'doubleAuthent.activation.title' })
			case 2:
				return intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.title.activation' })
			case 3:
				return intl.formatMessage({ id: 'doubleAuthent.confirmation.title.activation' })
			case 4:
				return intl.formatMessage({ id: 'doubleAuthent.finalPopin.title' })
			case 5:
				return intl.formatMessage({ id: 'doubleAuthent.recup.title' })
			case 6:
				return intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.title.recuperation' })
			default:
				return undefined
		}
	}

	const renderStep = () => {
		switch (currentStep) {
			case 1:
				return <ActivationAuthent nextStep={nextStep} obligatoire={true} textCardibox={articleParRaccourci(articles, 'activate_authent')} />
			case 2:
				return <ModifyAuthent nextStep={nextStep} updateContact={updateContact} currentAuthent={methode} telephone={telephone} email={email} change={changeMethode} textCardibox={articleParRaccourci(articles, 'modify_authent')} />
			case 3:
				return <ConfirmationAuthent nextStep={nextStep} prevStep={prevStep} currentAuthent={methode} telephone={telephone} email={email} />
			case 4:
				return <FinalPopinAuthent nextStep={nextStep} terminer={closePopup} methode={methode} textCardibox={articleParRaccourci(articles, 'finalize_authent')} />
			case 5:
				return <ModifyAuthent recuperation={true} nextStep={nextStep} updateContact={updateContact} currentAuthent={methode === 'mail' ? 'sms' : 'mail'} telephone={telephone} email={email} change={changeMethode} prevStep={prevStep} />
			case 6:
				return <ConfirmationAuthent nextStep={closePopup} prevStep={prevStep} currentAuthent={methode === 'mail' ? 'sms' : 'mail'} telephone={telephone} email={email} recuperation={true} />
			default:
				return undefined
		}
	}

	const updateContact = (newTelephone: string, newEmail: string) => {
		newTelephone && setTelephone(newTelephone)
		newEmail && setEmail(newEmail)
	}

	const nextStep = () => {
		if (currentStep < 6) {
			setCurrentStep(currentStep + 1)
		}
	}

	const prevStep = () => {
		if (currentStep > 1) {
			setCurrentStep(currentStep - 1)
		}
	}

	const changeMethode = (newMethode: string) => {
		setMethode(newMethode)
	}

	const closePopup = () => {
		window.location.href = UNIVERS_PARTICULIERS.to(MAIN_PATH.DASHBOARD)
	}

	return (
		<Stack direction="row" justifyContent="flex-end">
			{!(isMobile || isTabletPortrait) && (
				<Box flex={1} width="100%" height={height} position="relative">
					<img style={{ width: '100%', height: height, objectFit: 'cover', zIndex: 0 }} src={loginImg}
					     alt="background" />
					<Stack
						direction="column"
						sx={{ position: 'absolute', top: '0', left: '0', width: '100%', height: height, zIndex: 1 }}
						bgcolor={alpha('#e0e4ff', 0.65)}
						alignItems="center"
						spacing="80px"
					>
						<img style={{ width: '300px', marginTop: '80px' }}
						     src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
						     alt="logo" />
					</Stack>
				</Box>)
			}
			<Stack width={!(isMobile || isTabletPortrait) ? '700px' : width} direction="column" spacing={2}
			       alignItems="center">
				<Stack direction="column" width={!isMobile ? '460px' : 'unset'} alignItems="center"
				       pt={!isMobile ? '96px' : '48px'} px="24px">
					<Stack spacing="32px">
						<Typography color="secondary" variant="h2" width={!isMobile ? '460px' : 'unset'}>
							{title()}
						</Typography>
						{renderStep()}
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	)
}

const mapStateToProps = (state: any) => ({
	user: userSelector(state),
	articles: getArticleDoubleAuthent(state)
})

const actions = {
	loadArticles: doubleAuthentActions.loadArticles
}

export default connect(
	mapStateToProps,
	actions
)(DoubleAuthentificationPage)
