import React from 'react'
import { Field } from 'react-final-form'
import { TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import FormatUtils from '../../utils/FormatUtils'

type DatePickerInputProps = {
	name: string
	label?: string | React.ReactNode
	validate?: (value: string) => string | undefined
	maxWidth?: string
	initialValue?: string
	disabled?: boolean
	onChange?: (newValue: any, index?: number) => void
	index?: number
	helperText?: string
	disableFuture?: boolean
	minDate?: Date
	maxDate?: Date
	disablePast?: boolean
	mandatory?: boolean
}

const DatePickerInput: React.FC<DatePickerInputProps> = (
	{
		name,
		label,
		validate,
		maxWidth = '350px',
		initialValue,
		disabled = false,
		onChange,
		index,
		helperText,
		disableFuture = false,
		disablePast = false,
		maxDate,
		minDate,
		mandatory = false
	}) => {

	const formattedDate = initialValue ? FormatUtils.parseDate(initialValue).toISOString() : undefined


	return (
		<Field name={name} validate={validate} initialValue={formattedDate}>
			{({ input, meta }) => {
				const handleChange = (newValue: any, index?: number) => {
					input.onChange(newValue)
					onChange && onChange(newValue, index)
				}

				return (
					<DatePicker
						disableFuture={disableFuture}
						disablePast={disablePast}
						minDate={minDate}
						maxDate={maxDate}
						inputFormat="dd/MM/yyyy"
						label={label + (mandatory ? " *" : "")}
						disabled={disabled}
						{...input}
						value={input.value}
						onChange={(newValue: any) => {
							handleChange(newValue, index)
						}}
						toolbarTitle={
							<Typography variant="subtitle1">Date</Typography>
						}
						renderInput={(props) =>
							<TextField
								size="small"
								color="secondary"
								sx={{ width: '100%', maxWidth: maxWidth }}
								{...props}
								inputProps={{
									...props.inputProps,
									// marginRight: 0,
									placeholder: 'JJ/MM/AAAA'
								}}
								error={((meta.touched || meta.dirty || meta.modified) && (meta.error || meta.submitError))}
								helperText={((meta.touched || meta.dirty || meta.modified) && meta.error) || ((meta.touched || meta.dirty || meta.modified) && meta.submitError) || helperText}
							/>
						}
					/>
				)
			}}
		</Field>
	)
}

export default DatePickerInput
