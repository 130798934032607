import {GerepAPI_EE} from '../gerep/GerepAPI'

export default class UserServiceEE {

    static getUser(token) {
        return GerepAPI_EE.get('/users/me', null, token)
    }

    static login(login, password, captcharesponse, rememberMe) {
        return GerepAPI_EE.post('/login', {
            login,
            password,
            captcharesponse,
            rememberMe
        }, null, true)
    }

    static changePassword(email, captcharesponse) {
        return GerepAPI_EE.post('/usersPassword/changePassword', {
            email,
            captcharesponse
        })
    }

    static editPasswordExt(token, password, newPassword, passwordToken) {
        return GerepAPI_EE.post('/usersPassword/editPasswordExt', {
            password,
            newPassword,
            passwordToken
        }, token)
    }

    static validatePasswordToken(passwordToken) {
        return GerepAPI_EE.post(`/usersPassword/validatePasswordToken`, {
            passwordToken
        })
    }

    static subscribe(nom, prenom, password, email, newsletter, captcharesponse) {
        return GerepAPI_EE.post('/users/subscribe', {
            nom,
            prenom,
            password,
            email,
            newsletter,
            captcharesponse
        })
    }

    static editPassword(password, newPassword, token) {
        return GerepAPI_EE.post('/usersPassword/editPassword', {
            password,
            newPassword
        }, token)
    }

    static editMail(newMail, token) {
        return GerepAPI_EE.post('/users/editMail', {
            newMail
        }, token)
    }

    static editInformationsPersonnelles(values, token) {
        return GerepAPI_EE.post('/users/editInformationsPersonnelles', values, token)
    }

    static getInfoPersonelles(token) {
        return GerepAPI_EE.get('/users/info', null, token)
    }

    static forceResetPassword(values, token) {
        return GerepAPI_EE.post('/usersPassword/forceResetPassword', values, token)
    }

    static activate(idAdherent, activationCode) {
        return GerepAPI_EE.post(`/users/activate/${idAdherent}/${activationCode}`)
    }

    static getConfigurationEE(token) {
        return GerepAPI_EE.get('/configuration', null, token)
    }

    static envoyerCode(values, token) {
        return GerepAPI_EE.post('/users/envoyerCode', values, token)
    }

    static validateDoubleAuthent(values, token) {
        return GerepAPI_EE.post('/users/validateDoubleAuthent', values, token)
    }

    static validateDoubleAuthentConnexion(values, token) {
        return GerepAPI_EE.post('/users/validateDoubleAuthentConnexion', values, token)
    }
}
