import React from 'react'
import { useIntl } from 'react-intl'
import DatePickerInput from '../../../../../components/form/DatePickerInput'
import { MODIFY_SOCIAL_SECURITY_FIELDS } from '../../services/personalInfoConstants'
import CheckboxInput from '../../../../../components/form/CheckboxInput'
import InfoField from '../../../../../components/InfoField'
import { Box, Stack, Typography } from '@mui/material'

type EndTeletransmissionProps = {
	allBeneficiaries: any[]
	errors: any
}

const EndTeletransmission: React.FC<EndTeletransmissionProps> = ({ allBeneficiaries, errors }) => {
	const intl = useIntl()

	const BENEFICIARIES_DATA: SelectOption[] = allBeneficiaries ? allBeneficiaries.map((beneficiary: any) => {
		return {
			value: beneficiary[0],
			label: `${beneficiary[1].prenom} ${beneficiary[1].nom}`
		}
	}) : []

	return (
		<>
			<InfoField
				label={intl.formatMessage({ id: 'personalInfo.personalData.modifySS.form.beneficiaries.title' })}
				value={
					<CheckboxInput
						name={MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARIES}
						data={BENEFICIARIES_DATA}
						error={errors[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARIES]}
					/>
				}
				direction="column"
				required
			/>
			<Box display="flex" flex={1}>
				<DatePickerInput
					disablePast
					name={MODIFY_SOCIAL_SECURITY_FIELDS.DATE_OF_ENTRY}
					label={
						<Stack direction="row">
							<Typography flex={4} variant="body2">
								{intl.formatMessage({ id: 'personalInfo.personalData.modifySS.form.startDate.title' })} <span>*</span>
							</Typography>
						</Stack>
					}
					initialValue={new Date().toLocaleDateString('fr-FR')}
				/>
			</Box>
		</>
	)
}

export default EndTeletransmission
