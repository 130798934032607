import {types as typesData} from '../data/dataActions'

const ACTION_NAMESPACE = '/USER'

export const types = {
	SET_USER_MAIL: `${ACTION_NAMESPACE}/SET_USER_MAIL`
}

export const setUserEmail = email => (dispatch) => {
	dispatch({
		type: types.SET_USER_MAIL,
		payload: email
	})
}

export const initUser = () => (dispatch) => {
	dispatch({
		type: typesData.LOADING,
		payload: true
	})
}
