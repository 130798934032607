import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import { MAIN_PATH_EE, MAIN_PATH_UNIVERS } from './constant/path'
import {compose} from 'redux'
import {connect} from 'react-redux'
import NavMenuProvider from './components/navMenu/NavMenuProvider'
import RefreshProviderEE from './kpi/ui/personalInfo/services/refresh/RefreshProviderEE'
import WithReadOnlyModalProvider from './ea/ui/readOnlyModal/withReadOnlyModal'
import NotFoundPage from './univers/ui/errorPage/NotFoundPage'
import InternalErrorPage from './univers/ui/errorPage/InternalErrorPage'
import UnauthorizedErrorPage from './univers/ui/errorPage/UnauthorizedErrorPage'
import DashboardPageEE from './kpi/ui/dashboard/pages/DashboardPageEE'
import PersonalDataEEPage from './kpi/ui/personalInfo/pages/PersonalDataEEPage'
import SanteConsomationPageEE from './kpi/ui/sante/pages/SanteConsommationPageEE'
import SanteDemographiePageEE from './kpi/ui/sante/pages/SanteDemographiePageEE'
import PrevoyanceArretTravailPageEE from './kpi/ui/prevoyance/pages/PrevoyanceArretTravailPageEE'
import QualiteServicePageEE from './kpi/ui/qualiteService/pages/QualiteServicePageEE'
import SanteFinanceCotisationPageEE from './kpi/ui/sante/pages/SanteFinanceCotisationPageEE'
import ContacterPageEE from './kpi/ui/contact/pages/ContacterPageEE'
import {getListCompany as getListCompanySelector} from './kpi/ui/listCompany/ListCompanySelector'
import {getListCompany} from './kpi/ui/listCompany/ListCompanyActions'
import {userSelector} from './kpi/ui/userEE/userEESelectors'
import ForceResetPage from './kpi/ui/forceReset/ForceResetPage'
import DoubleAuthentificationPageKPI from './kpi/ui/doubleAuthent/DoubleAuthentificationPageKPI'

const AppEE = (
    {
        // @ts-ignore
        user,
        // @ts-ignore
        getListCompany,
        // @ts-ignore
        societes,
        // @ts-ignore
        getDataKpiSociete
    }) => {

    useEffect(() => {
        getListCompany()
    }, [user, getListCompany])

    if (user && !user.passwordValid) {
        return (
            <Switch>
                <Route path={MAIN_PATH_EE.FORCE_RESET_PASSWORD} component={ForceResetPage}/>
                <Redirect path="*" to={MAIN_PATH_EE.FORCE_RESET_PASSWORD}/>
            </Switch>)
    } else if (user && (!user.doubleAuthentification && user.doubleAuthentObligatoire)) {
        return (
            <Switch>
                <Route path={MAIN_PATH_EE.ACTIVATE_DOUBLE_AUTHENT} component={DoubleAuthentificationPageKPI} />
                <Redirect path="*" to={MAIN_PATH_EE.ACTIVATE_DOUBLE_AUTHENT} />
            </Switch>)
    } else if (user) {
        return (
            <NavMenuProvider isEntreprise={true}>
                <RefreshProviderEE>
                    <WithReadOnlyModalProvider>
                        <Switch>
                            <Route path={MAIN_PATH_EE.DASHBOARD} component={DashboardPageEE}/>
                            <Route path={MAIN_PATH_EE.ACCOUNT} component={PersonalDataEEPage}/>
                            <Route path={MAIN_PATH_EE.SANTE_CONSOMMATION} component={SanteConsomationPageEE}/>
                            <Route path={MAIN_PATH_EE.SANTE_FINANCE_CONTISATION} component={SanteFinanceCotisationPageEE}/>
                            <Route path={MAIN_PATH_EE.SANTE_DEMOGRAPHIE} component={SanteDemographiePageEE}/>
                            <Route path={MAIN_PATH_EE.PREVOYANCE_ARRET_TRAVAIL} component={PrevoyanceArretTravailPageEE}/>
                            <Route path={MAIN_PATH_EE.QUALITE_SERVICE} component={QualiteServicePageEE}/>
                            <Route path={MAIN_PATH_EE.CONTACTER_GEREP} component={ContacterPageEE}/>
                            <Route path={MAIN_PATH_UNIVERS.NOT_FOUND} component={NotFoundPage}/>
                            <Route path={MAIN_PATH_UNIVERS.NOT_AUTHORIZED} component={UnauthorizedErrorPage}/>
                            <Route path={MAIN_PATH_UNIVERS.INTERNAL_ERROR} component={InternalErrorPage}/>
                            <Redirect path={MAIN_PATH_EE.DEFAULT} exact to={MAIN_PATH_EE.DASHBOARD}/>
                            <Route path="*" component={NotFoundPage}/>
                        </Switch>
                    </WithReadOnlyModalProvider>
                </RefreshProviderEE>
            </NavMenuProvider>
        )
    }

    return <Redirect to={MAIN_PATH_EE.LOGIN}/>
}

const actions = {
    getListCompany
}

const mapStateToProps = (state: any) =>
    ({
        user: userSelector(state),
        societes: getListCompanySelector(state)
    })

export default compose(
    connect(mapStateToProps, actions)
)(AppEE)
