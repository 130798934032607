import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import SelectInput from '../../../../components/form/SelectInput'
import { MAINTENIR_ENFANT_MAJEUR_FIELDS, SITUATION_VALUES } from '../services/maintenirEnfantMajeurConstants'
import StudyingForm from './StudyingForm'
import InternshipForm from './InternshipForm'
import RadiationContent from './RadiationContent'
import FirstJobSearchingForm from './FirstJobSearchingForm'
import ButtonDefault from '../../../../components/ButtonDefault'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { useHistory } from 'react-router-dom'
import { SECONDARY_PATH } from '../../../../constant/path'
import ServicesService from '../../../../api/gerep/ServicesService'
import LocalStorage from '../../../../business/storage/LocalStorage'
import { ToastContext } from '../../../../components/toast/ToastContext'
import { ToastType } from '../../../../components/toast/toastConstants'

type MaintenirEnfantMajeurFormProps = {
	individuList: any[],
	notification : any,
	removeNotification: any
}

const MaintenirEnfantMajeurForm: React.FC<MaintenirEnfantMajeurFormProps> = (
	{
		individuList,
		notification,
		removeNotification
	}
) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const history = useHistory()
	const { addToast } = useContext(ToastContext)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isModifying, setIsModifying] = useState<boolean>(false)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

	const getNotificationData = () => {
		return notification ? notification.data : null
	}

	const clearNotification = () => {
		if (notification) {
			removeNotification(notification.id)
		}
	}

	// ------------------------
	// Handle submit
	// ------------------------

	const handleScolariseSubmit = (values: any) => {
		const {
			certificat,
			beneficiaire,
			firstRegimeSalarie,
			st_attestationSS
		} = values
		const selectedBeneficiaire = individuList.find((individu: any) => individu[1].numIndividu === beneficiaire)

		const formValues = {
			firstRegimeEtudiant: firstRegimeSalarie === '1',
			beneficiaire: selectedBeneficiaire[1],
			editSS: false,
			notification: getNotificationData()
		}

		setIsLoading(true)
		return ServicesService.enfantMajeurScolarise(
			LocalStorage.getToken(), [{
				key: 'enfantMajeurScolarise',
				value: formValues
			}, {
				key: 'certificat',
				value: certificat
			}, {
				key: 'attestationSS',
				value: st_attestationSS
			}]
		)
			.then(() => {
				setOpenConfirmation(true)
				addToast(ToastType.SUCCESS, 'global.success')
				clearNotification()
			})
			.catch((e) => {
				const error = (e.data && e.data._error) || 'Echec de l\'envoi de la demande'
				addToast(ToastType.ERROR, error)
			})
			.finally(() => setIsLoading(false))
	}

	const handleApprentissageSubmit = (values: any) => {
		const {
			certificat,
			in_attestationSS1,
			firstRegimeSalarie,
			in_numSS1,
			in_organisme1,
			beneficiaire
		} = values
		const selectedBeneficiaire = individuList.find((individu: any) => individu[1].numIndividu === beneficiaire)

		const attestationBody = isModifying ? [{
			key: 'attestationSS',
			value: in_attestationSS1
		}] : []

		const modifySSBody = isModifying ? {
			numSS: in_numSS1.replaceAll('_', '').replaceAll(' ', ''),
			organisme: in_organisme1.replaceAll('_', '').replaceAll(' ', '')
		} : {}

		const body = [{
			key: 'enfantMajeurApprentissage',
			value: {
				beneficiaire: selectedBeneficiaire[1],
				editSS: isModifying,
				firstRegimeSalarie: firstRegimeSalarie === '1',
				...modifySSBody
			}
		}, {
			key: 'contrat',
			value: certificat
		}, {
			key: 'notification',
			value: getNotificationData()
		},
			...attestationBody
		]

		setIsLoading(true)
		return ServicesService.enfantMajeurApprentissage(
			LocalStorage.getToken(),
			body
		)
			.then(() => {
				setOpenConfirmation(true)
				addToast(ToastType.SUCCESS, 'global.success')
				clearNotification()
			})
			.catch((e) => {
				const error = (e.data && e.data._error) || 'Echec de l\'envoi de la demande'
				addToast(ToastType.ERROR, error)
			})
			.finally(() => setIsLoading(false))
	}

	const handleRechercheEmploiSubmit = (values: any) => {
		const {
			beneficiaire,
			searching_files
		} = values
		const selectedBeneficiaire = individuList.find((individu: any) => individu[1].numIndividu === beneficiaire)

		setIsLoading(true)
		return ServicesService.enfantMajeurPoleEmploi(
			LocalStorage.getToken(), [{
				key: 'enfantMajeurPoleEmploi',
				value: {
					beneficiaire: selectedBeneficiaire[1],
					notification: getNotificationData()
				}
			}, {
				key: 'poleEmploi',
				value: searching_files
			}]
		)
			.then(() => {
				setOpenConfirmation(true)
				addToast(ToastType.SUCCESS, 'global.success')
				clearNotification()
			})
			.catch((e) => {
				const error = e.data._error || 'Echec de l\'envoi de la demande'
				addToast(ToastType.ERROR, error)
			})
			.finally(() => setIsLoading(false))
	}

	const onSubmit = (values: any) => {
		if (values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.STUDYING) {
			return handleScolariseSubmit(values)
		} else if (values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.INTERNSHIP) {
			return handleApprentissageSubmit(values)
		} else if (values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.SEARCHING) {
			return handleRechercheEmploiSubmit(values)
		}
	}

	const SITUATION_DATA = [
		{
			value: SITUATION_VALUES.STUDYING,
			label: intl.formatMessage({ id: 'maintenirEnfantMajeur.input.studying.label' })
		},
		{
			value: SITUATION_VALUES.INTERNSHIP,
			label: intl.formatMessage({ id: 'maintenirEnfantMajeur.input.internship.label' })
		},
		{
			value: SITUATION_VALUES.WORKING,
			label: intl.formatMessage({ id: 'maintenirEnfantMajeur.input.working.label' })
		},
		{
			value: SITUATION_VALUES.SEARCHING,
			label: intl.formatMessage({ id: 'maintenirEnfantMajeur.input.searching.label' })
		},
		{
			value: SITUATION_VALUES.OTHER,
			label: intl.formatMessage({ id: 'maintenirEnfantMajeur.input.other.label' })
		}
	]

	return (
		<Form
			onSubmit={onSubmit}
			validate={(values: any) => {
				const errors: any = {}

				if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.BENEFICIARY]) {
					errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.BENEFICIARY] = intl.formatMessage({ id: 'global.error.required' })
				}

				if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION]) {
					errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] = intl.formatMessage({ id: 'global.error.required' })
				} else if (values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.STUDYING) {
					if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.CERTIFICAT] || values[MAINTENIR_ENFANT_MAJEUR_FIELDS.CERTIFICAT]?.length === 0) {
						errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.CERTIFICAT] = intl.formatMessage({ id: 'global.error.required' })
					}

					if (isModifying) {
						if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.STUDYING_ATTESTATION_SS] || values[MAINTENIR_ENFANT_MAJEUR_FIELDS.STUDYING_ATTESTATION_SS]?.length === 0) {
							errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.STUDYING_ATTESTATION_SS] = intl.formatMessage({ id: 'global.error.required' })
						}
					}
				} else if (values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.INTERNSHIP) {
					if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.CERTIFICAT] || values[MAINTENIR_ENFANT_MAJEUR_FIELDS.CERTIFICAT]?.length === 0) {
						errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.CERTIFICAT] = intl.formatMessage({ id: 'global.error.required' })
					}
					if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.FIRST_REGIME]) {
						errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.FIRST_REGIME] = intl.formatMessage({ id: 'global.error.required' })
					} else if (values[MAINTENIR_ENFANT_MAJEUR_FIELDS.FIRST_REGIME] === '1') {
						if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_NISS_1]) {
							errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_NISS_1] = intl.formatMessage({ id: 'global.error.required' })
						}

						if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ORGANISME_1]) {
							errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ORGANISME_1] = intl.formatMessage({ id: 'global.error.required' })
						}

						if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ATTESTATION_SS_1] || values[MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ATTESTATION_SS_1]?.length === 0) {
							errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ATTESTATION_SS_1] = intl.formatMessage({ id: 'global.error.required' })
						}
					} else if (values[MAINTENIR_ENFANT_MAJEUR_FIELDS.FIRST_REGIME] === '0' && isModifying) {
						if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ATTESTATION_SS_2] || values[MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ATTESTATION_SS_2]?.length === 0) {
							errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ATTESTATION_SS_2] = intl.formatMessage({ id: 'global.error.required' })
						}
					}
				} else if (values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.SEARCHING) {
					if (!values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SEARCHING_FILES] || values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SEARCHING_FILES]?.length === 0) {
						errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.SEARCHING_FILES] = intl.formatMessage({ id: 'global.error.required' })
					}
				}

				return errors
			}}
			render={({ handleSubmit, values, form: { reset }, touched, errors }) => {
				const selectedChild = individuList.find(ind => ind[0] === values[MAINTENIR_ENFANT_MAJEUR_FIELDS.BENEFICIARY])
				const buttonMessage = values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.WORKING ||
				values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.OTHER ?
					'maintenirEnfantMajeur.input.radiation.button' : 'global.button.validate'

				return (
					<form onSubmit={handleSubmit}>
						<Stack direction="column" spacing="24px">
							<Typography variant="body2">
								<FormattedMessage id="maintenirEnfantMajeur.text" values={{ br: <br /> }} />
							</Typography>
							<Divider />
							<SelectInput
								name={MAINTENIR_ENFANT_MAJEUR_FIELDS.BENEFICIARY}
								label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.child' })}
								data={individuList.map((individu: any) => ({ value: individu[0], label: `${individu[1].prenom} ${individu[1].nom}` }))}
								defaultValue={(notification && notification.data && notification.data.numbene) ? notification.data.numbene : undefined}
								mandatory
							/>
							<SelectInput
								name={MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION}
								label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.situation' })}
								data={SITUATION_DATA}
								onChange={() => setIsModifying(false)}
								mandatory
							/>

							{values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.STUDYING && (
								<StudyingForm
									selectedChild={selectedChild ? selectedChild[1] : undefined}
									setModifyNISS={setIsModifying}
									modifyNISS={isModifying}
								/>
							)}

							{values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.INTERNSHIP && (
								<InternshipForm values={values} selectedChild={selectedChild ? selectedChild[1] : undefined} touched={touched} errors={errors} />
							)}

							{values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.WORKING && (
								<RadiationContent />
							)}

							{values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.SEARCHING && (
								<FirstJobSearchingForm />
							)}

							{values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.OTHER && (
								<RadiationContent type="other" />
							)}

							<Stack alignItems="center">
								<ButtonDefault
									variant="contained"
									onClick={() => {
										values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.WORKING ||
										values[MAINTENIR_ENFANT_MAJEUR_FIELDS.SITUATION] === SITUATION_VALUES.OTHER ?
											history.push(SECONDARY_PATH.CONTACT_DELETE_BENEFICIARY) : handleSubmit()
									}}
									type="submit"
								>
									{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id={buttonMessage} />}
								</ButtonDefault>
								<Box display="flex" width="100%" flex={1} alignSelf={isMobile || isTabletPortrait ? 'unset' : 'baseline'}>
									<Typography sx={{ position: isMobile || isTabletPortrait ? 'unset' : 'relative', bottom: '32px', left: 0, height: '32px', display: 'flex', alignItems: 'flex-end' }} variant="body2" color="#7a7a7a">
										<FormattedMessage id="global.mandatory" />
									</Typography>
								</Box>
							</Stack>

							<ConfirmationPopin
								title={intl.formatMessage({ id: 'maintenirEnfantMajeur.title' })}
								open={openConfirmation}
								setOpen={setOpenConfirmation}
								resetForm={reset}
							/>
						</Stack>
					</form>
				)
			}}
		/>
	)
}

export default MaintenirEnfantMajeurForm
