import React, {useEffect, useState} from 'react'
import loginImg from '../../../../resources/img/login_img.jpg'
import loginImgEE from '../../../../resources/img/login_imgEE.jpg'
import {alpha, Stack, Tab, Tabs, ThemeProvider, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import {TabPanel} from '../components/TabPanel'
import {useIntl} from 'react-intl'
import LoginForm from '../components/LoginForm'
import LoginFormEE from '../components/LoginFormEE'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import appConst from '../../../../constant/appConstants'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import ResetPassword from '../components/ResetPassword'
import UserService from '../../../../api/gerep/UserService'
import Popup from '../../../../components/Popup'
import {themeEA, themeEE} from '../../../constant/univers'
import UserServiceEE from '../../../../api/gerepEe/UserServiceEE'
import {MAIN_PATH_UNIVERS} from '../../../../constant/path'
import {useHistory} from 'react-router-dom'
import {redirectBoolSelector} from '../services/selectors'
import {setRedirectionBoolean} from '../services/login/loginActions'
import ButtonDefault from '../../../../components/ButtonDefault'

import ResetPasswordEE from '../components/ResetPasswordEE'

const ARTICLE_INTRO_EA = 'login_bienvenue'
const ARTICLE_INTRO_EE = 'ee_login_bienvenue'

const LoginPage = (
    {
        // @ts-ignore
        loginBienvenueArticleEA,
        // @ts-ignore
        getArticleByShortcut,
        // @ts-ignore
        loginBienvenueArticleEE,
        // @ts-ignore
        isEntreprise,
        // @ts-ignore
        redirectionQuestion,
        // @ts-ignore
        setRedirectionBoolean,
    }
) => {
    const intl = useIntl()
    const history = useHistory()
    const [value, setValue] = useState(isEntreprise ? 1 : 0)
    const [openEditPasswordExt, setOpenEditPasswordExt] = useState<boolean>(false)
    const [openTokenInvalid, setOpenTokenInvalid] = useState<boolean>(false)
    const [openRedirectToMobileApp, setOpenRedirectToMobileApp] = useState<boolean>(false)
    const [alreadyRepliedToRedirection, setAlreadyRepliedToRedirection] = useState<boolean>(redirectionQuestion)
    const urlParams = new URLSearchParams(window.location.search)
    const passwordToken = urlParams.get('passwordToken') || ''
    const {isMobile, isTabletPortrait, width, height} = useBreakpoints()

    /**
     * MUI Pannels aria
     * @param index
     */
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        }
    }

    /**
     * On pannel click change the content rendered
     * Particulier ou entreprise
     * @param event
     * @param newValue
     */
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        if(newValue === 0){
            history.push(MAIN_PATH_UNIVERS.LOGIN_EA)
        } else {
            history.push(MAIN_PATH_UNIVERS.LOGIN_EE)
        }
    }

    useEffect(() => {
        if (!loginBienvenueArticleEE) {
            getArticleByShortcut(appConst.cardiboxApi, ARTICLE_INTRO_EE)
        }
        if (!loginBienvenueArticleEA) {
            getArticleByShortcut(appConst.cardiboxApi, ARTICLE_INTRO_EA)
        }
    }, [getArticleByShortcut, loginBienvenueArticleEE, loginBienvenueArticleEA])

    useEffect(() => {
        if (passwordToken) {
            if(isEntreprise){
                UserServiceEE.validatePasswordToken(passwordToken)
                    .then(() => setOpenEditPasswordExt(true))
            } else {
                UserService.validatePasswordToken(passwordToken)
                    .then(() => setOpenEditPasswordExt(true))
            }
        }
    }, [passwordToken])

   /* useEffect(() => {
        if (value === 0 && isMobile && !alreadyRepliedToRedirection) {
            setOpenRedirectToMobileApp(true)
        }
    }, [isMobile, value, alreadyRepliedToRedirection])*/

    return (
        <ThemeProvider theme={value === 0 ? themeEA : themeEE}>
            <Stack direction="row" justifyContent="flex-end">
                {!(isMobile || isTabletPortrait) && loginBienvenueArticleEE && loginBienvenueArticleEA && (
                    <Box flex={1} width="100%" height={height} position="relative">
                        <img
                            style={value === 0 ? {
                                width: '100%',
                                height: height,
                                objectFit: 'cover',
                                zIndex: 0
                            } : {
                                display: 'none'
                            }}
                            src={loginImg}
                            alt="background"
                        />
                        <img
                            style={value === 0 ? {
                                display: 'none'
                            } : {
                                width: '100%',
                                height: height,
                                objectFit: 'cover',
                                zIndex: 0
                            }}
                            src={loginImgEE}
                            alt="background"
                        />
                        <Stack
                            direction="column"
                            sx={{position: 'absolute', top: '0', left: '0', width: '100%', height: height, zIndex: 1}}
                            bgcolor={alpha('#e0e4ff', 0.65)}
                            alignItems="center"
                            spacing="80px"
                        >
                            <img style={{width: '300px', marginTop: '80px'}}
                                 src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
                                 alt="logo"/>
                            <Stack direction="column" p="24px" style={{maxWidth: '480px'}}>
                                {value === 0 ?
                                    <Typography color="secondary" variant="h1">
                                        {loginBienvenueArticleEA.fields.title}
                                    </Typography>
                                    :
                                    <Typography color="secondary" variant="h1">
                                        {loginBienvenueArticleEE.fields.title}
                                    </Typography>
                                }
                                {value === 0 ?
                                    <div dangerouslySetInnerHTML={{__html: loginBienvenueArticleEA.fields.body}}/>
                                    :
                                    <div dangerouslySetInnerHTML={{__html: loginBienvenueArticleEE.fields.body}}/>
                                }
                            </Stack>
                        </Stack>
                    </Box>)
                }
                <Stack width={!(isMobile || isTabletPortrait) ? '600px' : width} direction="column" spacing={2}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs
                            textColor={value === 0 ? 'secondary' : 'primary'}
                            indicatorColor={value === 0 ? 'secondary' : 'primary'} value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab sx={{flex: 1}}
                                 label={intl.formatMessage({id: 'login.tabs.civil'})} {...a11yProps(0)} />
                            <Tab sx={{flex: 1}}
                                 label={intl.formatMessage({id: 'login.tabs.professional'})} {...a11yProps(1)}/>
                        </Tabs>
                    </Box>
                    {value === 0 ?
                        <TabPanel value={value} index={0}>
                            <LoginForm/>
                        </TabPanel>
                        :
                        <TabPanel value={value} index={1}>
                            <LoginFormEE/>
                        </TabPanel>
                    }
                </Stack>
                {isEntreprise?
                    <ResetPasswordEE
                        passwordToken={passwordToken}
                        openForm={openEditPasswordExt}
                        setOpenForm={setOpenEditPasswordExt}
                    />
                    :
                    <ResetPassword
                        passwordToken={passwordToken}
                        openForm={openEditPasswordExt}
                        setOpenForm={setOpenEditPasswordExt}
                    />
                }
                <Popup open={openTokenInvalid} setOpen={setOpenTokenInvalid}
                       title={intl.formatMessage({id: 'login.resetPassword.tokenInvalid.title'})}>
                    <Typography variant="body2">
                        {intl.formatMessage({id: 'login.resetPassword.tokenInvalid.body'})}
                    </Typography>
                </Popup>


                <Popup
                    open={openRedirectToMobileApp}
                    setOpen={setOpenRedirectToMobileApp}
                    title={intl.formatMessage({id : 'login.popinToApp.title'})}
                    onClose={setRedirectionBoolean}
                >
                    <Stack direction="column" spacing="16px" alignItems="center">
                        <Typography variant="body2" fontSize="0.875rem">
                            {intl.formatMessage({id : 'login.popinToApp.appDetected'})}
                        </Typography>

                        <Stack direction={isTabletPortrait ? 'row' : 'column'} spacing="12px">
                            <ButtonDefault
                                variant="contained"
                                onClick={() => {
                                    setOpenRedirectToMobileApp(false)
                                    setRedirectionBoolean()
                                }}
                            >
                                {intl.formatMessage({id: 'login.popinToApp.goToGerepMobile'})}
                                {/*{intl.formatMessage({id: 'login.popinToApp.openPlayStore'})}*/}
                                {/*{intl.formatMessage({id: 'login.popinToApp.openAppStore'})}*/}
                            </ButtonDefault>
                            <ButtonDefault
                                variant="outlined"
                                onClick={() => {
                                    setOpenRedirectToMobileApp(false)
                                    setRedirectionBoolean()
                                }}
                            >
                                {intl.formatMessage({id: 'login.popinToApp.stayWeb'})}
                            </ButtonDefault>
                        </Stack>
                    </Stack>
                </Popup>
            </Stack>
        </ThemeProvider>
    )
}

const actions = {
    getArticleByShortcut: cardiboxActions.articles.getByShortcut,
    setRedirectionBoolean
}

const mapStateToProps = (state: any) => {
    return {
        loginBienvenueArticleEE: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_INTRO_EE],
        loginBienvenueArticleEA: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_INTRO_EA],
        isArticleLoadingEE: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_INTRO_EE],
        isArticleLoadingEA: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_INTRO_EA],
        redirectionQuestion: state.pages && state.pages.login && redirectBoolSelector(state)
    }
}

export const LoginPageEA = compose(
    connect((state) => {
        const commonMapState = mapStateToProps(state)
        return {
            ...commonMapState,
            isEntreprise: false
        }
    }, actions)
)(LoginPage)

export const LoginPageEE = compose(
    connect((state) => {
        const commonMapState = mapStateToProps(state)
        return {
            ...commonMapState,
            isEntreprise: true
        }
    }, actions)
)(LoginPage)

export default compose(
    connect(mapStateToProps, actions)
)(LoginPage)
