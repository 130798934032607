import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const ButtonConnexion = styled(ButtonMUI)<ButtonProps>(({ theme }) => ({
	'&.MuiButton-root': {
		borderRadius: 24,
		width: 'auto',
		padding: '6px 16px',
		height: 20,
		fontSize: '0.8125rem',
		fontWeight: 500,
		textTransform: 'none',
		color: theme.palette.primary.main,
		border: '1px solid ' + theme.palette.primary.main
	}
}))

export default ButtonConnexion
