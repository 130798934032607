import {all} from 'redux-saga/effects'
import interstitielSaga from './ea/webservices/interstitiel/sagaInterstitiel'
import ribSaga from '../src/ea/webservices/rib/ribSaga'
import InterstitielService from './api/gerep/InterstitielService'



export default function* rootSaga() {
    yield all([ribSaga(), interstitielSaga(InterstitielService.getInterstitiels)])
}
