import React from 'react'
import PageLayout from '../../../../components/PageLayout'
import { useIntl } from 'react-intl'
import PortabiliteForm from '../components/PortabiliteForm'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

const PortabilitePage = () => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()

	return (
		<PageLayout title={intl.formatMessage({ id: 'portabilite.title' })} bodyPadding={isMobile || isTabletPortrait ? '32px 32px 12px 32px' : '32px 32px 0 32px'}>
			<PortabiliteForm />
		</PageLayout>
	)
}

export default PortabilitePage