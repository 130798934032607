import React, { Dispatch, SetStateAction } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import FileInput from '../../../../components/fileInput/FileInput'
import { MAINTENIR_ENFANT_MAJEUR_FIELDS } from '../services/maintenirEnfantMajeurConstants'
import PatternTextInput from '../../../../components/PatternTextInput'
import { PATTERNS } from '../../../../components/formConstants'
import TextInput from '../../../../components/form/TextInput'
import ButtonLink from '../../../../components/ButtonLink'
import NissTextInput from '../../../../components/NissTextInput'
import {toUpperCase} from '../../../../utils/stringUtils'

type StudyingFormProps = {
	selectedChild?: any
	modifyNISS: boolean
	setModifyNISS: Dispatch<SetStateAction<boolean>>
}

const StudyingForm: React.FC<StudyingFormProps> = (
	{
		selectedChild,
		modifyNISS,
		setModifyNISS
	}) => {
	const intl = useIntl()

	return (
		<Stack direction="column" spacing="24px">
			<Typography variant="body2">
				<FormattedMessage id="maintenirEnfantMajeur.input.studying.description" />
			</Typography>
			<FileInput
				maxFileNumber={1}
				name={MAINTENIR_ENFANT_MAJEUR_FIELDS.CERTIFICAT}
				title={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.studying.title' })}
			/>
			<Typography variant="body2">
				<FormattedMessage id="maintenirEnfantMajeur.input.ssData.description" />
			</Typography>
			{/*<PatternTextInput*/}
			{/*	maxWidth="unset"*/}
			{/*	name={MAINTENIR_ENFANT_MAJEUR_FIELDS.STUDYING_NISS}*/}
			{/*	format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
			{/*	mask="_"*/}
			{/*	label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.niss' })}*/}
			{/*	defaultValue={selectedChild?.matorg}*/}
			{/*	disabled*/}
			{/*/>*/}
			<NissTextInput
				name={MAINTENIR_ENFANT_MAJEUR_FIELDS.STUDYING_NISS}
				label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.niss' })}
				defaultValue={selectedChild?.matorg}
				format={toUpperCase}
				maxWidth="unset"
				mandatory
				disabled
			/>
			<TextInput
				maxWidth="unset"
				name={MAINTENIR_ENFANT_MAJEUR_FIELDS.STUDYING_CAISSE}
				label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.caisse' })}
				defaultValue={selectedChild?.libCaisse}
				disabled
			/>
			{modifyNISS ? (
				<FileInput
					name={MAINTENIR_ENFANT_MAJEUR_FIELDS.STUDYING_ATTESTATION_SS}
					maxFileNumber={1}
					title={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.file.title' })}
					label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.file.label' })}
				/>
			) : (
				<>
					<Typography variant="body2">
						<FormattedMessage id="maintenirEnfantMajeur.input.ssData.modify" />
					</Typography>
					<Box display="flex" justifyContent="center">
						<ButtonLink sx={{ width: '230px' }} onClick={() => setModifyNISS(true)}>
							<FormattedMessage id="global.button.modify" />
						</ButtonLink>
					</Box>
				</>
			)}
		</Stack>
	)
}

export default StudyingForm