import {TypeAdherent} from '../../../../constant/TypeAdherent'


const SITUATION_FAMILIALE = {
	CELIBATAIRE: {
		code: '1',
		label: 'Célibataire'
	},
	DIVORCE: {
		code: '2',
		label: 'Divorcé(e)'
	},
	MARIE: {
		code: '3',
		label: 'Marié(e)'
	},
	PACSE: {
		code: '4',
		label: 'Pacsé(e)'
	},
	VEUF: {
		code: '5',
		label: 'Veuf(ve)'
	}
}

export const SITUATIONS_FAMILIALES = [
	SITUATION_FAMILIALE.CELIBATAIRE,
	SITUATION_FAMILIALE.DIVORCE,
	SITUATION_FAMILIALE.MARIE,
	SITUATION_FAMILIALE.PACSE,
	SITUATION_FAMILIALE.VEUF
]


/**
 * Enumération des types à utiliser pour accéder aux couvertures en fonction du type de bénéficiaire.
 *
 * Ce sont des valeurs fictives retournées par le webservice.
 *
 * RG_AFFIL_051
 */
export const TYPE_COUVERTURE = {
	ADHERENT_PRINCIPAL: '0',
	CONJOINT: '1',
	ENFANT: '2'
}

export const TYPE_BENEFICIAIRE = {
	CONJOINT: {
		code: `${TypeAdherent.CONJOINT}`,
		label: 'Conjoint'
	},
	ENFANT_MINEUR: {
		code: `${TypeAdherent.ENFANTS}` + '_A',
		label: 'Enfant mineur à charge'
	},
	ENFANT_MAJEUR: {
		code: `${TypeAdherent.ENFANTS}` + '_B',
		label: 'Enfant majeur à charge'
	},
	CONCUBIN: {
		code: `${TypeAdherent.CONCUBIN}`,
		label: 'Concubin'
	},
	PACSE: {
		code: '7',
		label: 'PACS'
	}
}

export const TYPES_BENEFICIAIRE = [
	TYPE_BENEFICIAIRE.CONJOINT,
	TYPE_BENEFICIAIRE.ENFANT_MINEUR,
	TYPE_BENEFICIAIRE.ENFANT_MAJEUR,
	TYPE_BENEFICIAIRE.CONCUBIN,
	TYPE_BENEFICIAIRE.PACSE
]

/**
 * Fait le mapping entre les types de bénéficiaires et les types de couverture.
 *
 * @param typeBeneficiaire le type de bénéficiaire
 * @return le type de couverture correspondant
 */
export const getTypeCouvertureFromTypeBeneficiaire = (typeBeneficiaire) => {
	switch (typeBeneficiaire) {
		case TYPE_BENEFICIAIRE.CONCUBIN.code:
		case TYPE_BENEFICIAIRE.CONJOINT.code:
		case TYPE_BENEFICIAIRE.PACSE.code:
			return TYPE_COUVERTURE.CONJOINT
		case TYPE_BENEFICIAIRE.ENFANT_MINEUR.code:
		case TYPE_BENEFICIAIRE.ENFANT_MAJEUR.code:
			return TYPE_COUVERTURE.ENFANT
		default:
		// ?
	}
}
