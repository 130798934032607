import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import {getPorteursRib} from '../affiliation/affiliationSelector'
import {types as loginTypes} from '../../../univers/ui/authentication/services/login/loginActions'
import {types as affiliationActionTypes} from '../affiliation/affiliationActions'
import {isRibInCache, isRibLoading} from './ribSelector'
import RibService from '../../../api/gerep/RibService';
import LocalStorage from '../../../business/storage/LocalStorage';

const ACTION_NAMESPACE = '/RIB'

export const types = {
    GET_RIB: `${ACTION_NAMESPACE}/GET_RIB`,
    GET_RIB_LOADING: `${ACTION_NAMESPACE}/GET_RIB_LOADING`
}

/**
 * Saga qui va chercher le RIB d'un individu.
 *
 * @param idIndividu
 */
function* sagaGetRibPrestation(idIndividu) {
    const _isRibLoading = yield select(isRibLoading(idIndividu))
    if (!_isRibLoading) {
        const _isRibInCache = yield select(isRibInCache(idIndividu))
        if (!_isRibInCache) {
            yield put({
                type: types.GET_RIB_LOADING,
                payload: {
                    loading: true,
                    idIndividu
                }
            })
            try {
                const rib = yield call(RibService.getRibPrestationIndividu, LocalStorage.getToken(), idIndividu)
                yield put({
                    type: types.GET_RIB_LOADING,
                    payload: {
                        loading: false,
                        idIndividu
                    }
                })
                yield put({
                    type: types.GET_RIB,
                    payload: {
                        rib,
                        idIndividu
                    }
                })
            } catch (error) {
                yield put({
                    type: types.GET_RIB_LOADING,
                    payload: {
                        loading: false,
                        idIndividu
                    }
                })
            }
        }
    }
}

/**
 * Saga déclenchée lors de l'actualisation des porteurs de RIB et qui déclenche à son
 * tour la récupération des ribs des nouveaux porteurs.
 */
function* sagaGetRibsPorteurs() {
    const porteurs = yield select(getPorteursRib)
    yield all(porteurs.map((individu) => {
        return call(sagaGetRibPrestation, [individu])
    }).toArray())
}

function* watchStartBackgroundTask() {
    yield takeLatest([affiliationActionTypes.GET_AFFILIATION, loginTypes.LOG_USER], sagaGetRibsPorteurs)
}

export default function ribSaga() {
    return call(watchStartBackgroundTask)
}
