import { getEcheancierExpirationDate } from './echeancierSelector'
import LocalStorage from '../../../business/storage/LocalStorage'
import ContratsService from '../../../api/gerep/ContratsService'
import GenericArticleCardibox from '../../../utils/GenericArticleCardibox'

const ACTION_NAMESPACE = '/ECHEANCIER'

export const types = {
	GET_ECHEANCIER: `${ACTION_NAMESPACE}/GET_ECHEANCIER`,
	GET_ECHEANCIER_LOADING: `${ACTION_NAMESPACE}/GET_ECHEANCIER_LOADING`,
}

export const loadEcheancier = () => (dispatch, getState) => {
	if (getEcheancierExpirationDate(getState()) < (+new Date())) {
		dispatch({ type: types.GET_ECHEANCIER_LOADING, payload: true })
		return ContratsService.getEcheancier(LocalStorage.getToken())
			.then((echeancier) => {
				dispatch({ type: types.GET_ECHEANCIER_LOADING, payload: false })
				return dispatch({
					type: types.GET_ECHEANCIER,
					payload: echeancier
				})
			}).catch((e) => {
				dispatch({ type: types.GET_ECHEANCIER_LOADING, payload: false })
				throw e
			})
	}
}


export const loadEcheancierIntro = () => () => {
	return ContratsService.getEcheancierIntro(LocalStorage.getToken())
		.then((echeancier) => {
			return GenericArticleCardibox.getFromApiObject(echeancier)
		}).catch((e) => {
			throw e
		})
}
