import React, { useState } from 'react'
import { CookiesContext } from './CookiesContext'
import CookiesPopup from './CookiesPopup'

type CookiesProviderProps = {
	children: React.ReactNode
}

const CookiesProvider: React.FC<CookiesProviderProps> = ({ children }) => {
	const [openCookiesPage, setOpenCookiesPage] = useState<boolean>(false)

	const openManageCookies = () => {
		setOpenCookiesPage(true)
	}

	const contextValue = {
		openManageCookies
	}

	return (
		<CookiesContext.Provider value={contextValue}>
			{children}
			<CookiesPopup openForm={openCookiesPage} setOpenForm={setOpenCookiesPage} />
		</CookiesContext.Provider>
	)
}

export default CookiesProvider