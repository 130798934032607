import { getExpirationDate, isRemboursementsLoading } from './remboursementsSelector'
import RemboursementsService from '../../../api/gerep/RemboursementsService';
import LocalStorage from '../../../business/storage/LocalStorage';

const ACTION_NAMESPACE = '/REMBOURSEMENTS'

export const types = {
	GET_REMBOURSEMENTS: `${ACTION_NAMESPACE}/GET_REMBOURSEMENTS`,
	GET_DERNIERS_REMBOURSEMENTS: `${ACTION_NAMESPACE}/GET_DERNIERS_REMBOURSEMENTS`,
	GET_REMBOURSEMENTS_LOADING: `${ACTION_NAMESPACE}/GET_REMBOURSEMENTS_LOADING`
}

const getKey = element => element.id

/**
 * Ajoute un id construit de toute pièce aux décomptes.
 *
 * @param remboursements
 */
const addKey = remboursements => remboursements
	.map(remboursement => ({
		...remboursement,
		id: getKey(remboursement)
	}))

export const getRemboursements = annee => (dispatch, getState) => {
	if (!isRemboursementsLoading(annee)(getState()) &&
		(!getExpirationDate(annee)(getState()) ||
			getExpirationDate(annee)(getState()) < (+new Date()))) {
		dispatch({
			type: types.GET_REMBOURSEMENTS_LOADING,
			payload: {
				annee,
				loading: true
			}
		})
		return RemboursementsService.getRemboursementsV2(LocalStorage.getToken(), annee)
			.then((remboursements) => {
				dispatch({
					type: types.GET_REMBOURSEMENTS_LOADING,
					payload: {
						annee,
						loading: false
					}
				})
				dispatch({
					type: types.GET_REMBOURSEMENTS,
					payload: {
						annee,
						remboursements: addKey(remboursements)
					}
				})
				return remboursements
			}).catch(e => dispatch({
				type: types.GET_REMBOURSEMENTS_LOADING,
				payload: {
					annee,
					loading: false
				}
			}))
	}
	// Remboursement en cache est toujours valable
	// ou est en train d'etre recupérée
}

export const getDerniersRemboursements = () => (dispatch, getState) => {
	const annee = 'last'
	if (!isRemboursementsLoading(annee)(getState()) &&
		(!getExpirationDate(annee)(getState()) ||
			getExpirationDate(annee)(getState()) < (+new Date()))) {
		dispatch({
			type: types.GET_REMBOURSEMENTS_LOADING,
			payload: {
				annee,
				loading: true
			}
		})
		return RemboursementsService.getDerniersRemboursements(LocalStorage.getToken())
			.then((response) => {
				dispatch({
					type: types.GET_REMBOURSEMENTS_LOADING,
					payload: {
						annee,
						loading: false
					}
				})
				dispatch({
					type: types.GET_DERNIERS_REMBOURSEMENTS,
					payload: {
						annee,
						intervalle: response.intervalle,
						remboursements: addKey(response.decomptes)
					}
				})
				return response
			}).catch(e => {
				console.error(e)
				dispatch({
					type: types.GET_REMBOURSEMENTS_LOADING,
					payload: {
						annee,
						loading: false
					}
				})
			})
	}
}

export const downloadRemboursements = filters => dispatch => (
	RemboursementsService.downloadRemboursements(LocalStorage.getToken(), filters)
		.catch((e) => {
			console.error(e)
		})
)

export const getRemboursementsByPeriode = (dateDebut, dateFin) => (dispatch, getState) => {
	const anneeCourante = new Date().getFullYear()
	const partsDateDebut = dateDebut && dateDebut.split('/')
	const partsDateFin = dateFin && dateFin.split('/')
	const anneeDebut = +partsDateDebut[2]
	const anneeFin = +partsDateFin[2]
	for (let annee = anneeDebut; annee <= anneeFin; annee += 1) {
		if (annee <= anneeCourante) {
			getRemboursements(annee)(dispatch, getState)
		}
	}
}
