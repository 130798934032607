import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Stack} from '@mui/material'
import {useIntl} from 'react-intl'
import {userSelector} from '../../user/userSelectors'
import Loader from '../../../../components/Loader'
import {isAffiliationLoading} from '../../../webservices/affiliation/affiliationSelector'
import {getListeDemandes, isDemandesLoading} from '../../../webservices/demandes/demandesSelectors'
import * as actionsAffiliation from '../../../webservices/affiliation/affiliationActions'
import * as demandeActions from '../../../webservices/demandes/demandesActions'
import PageLayout from '../../../../components/PageLayout'
import TableauHistoriqueDemandes from '../components/TableauHistoriqueDemandes'

const SuivieDemandePage = (
	{
		// @ts-ignore
		getAffiliation,
		// @ts-ignore
		getDemandes,
		// @ts-ignore
		loading,
		// @ts-ignore
		demandes,
		// @ts-ignore
		user
	}
) => {

	const intl = useIntl()

	useEffect(() => {
		getAffiliation()
		getDemandes()
	}, [getAffiliation, getDemandes])


	return (
		<PageLayout title={intl.formatMessage({ id: 'suivie_Demande.title' })} subtitle={intl.formatMessage({ id: 'suivie_Demande.subtitle' })}>
			{
				loading ? (
					<Stack direction="column" spacing="24px">
						<Loader/>
					</Stack>
				) : (
					<TableauHistoriqueDemandes
						demandes={demandes}
					/>
				)
			}
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	user: userSelector(state),
	demandes: getListeDemandes(state),
	loading: isDemandesLoading(state) || isAffiliationLoading(state)
})

const actions = {
	getDemandes: demandeActions.getDemandes,
	getAffiliation: actionsAffiliation.getAffiliation
}

export default connect(mapStateToProps, actions)(SuivieDemandePage)
