export enum STEP2_FORM_NAME {
	IBAN = 'iban',
	BANK = 'bank',
	ACCOUNT_HOLDER = 'titulaire',
	ACCEPT_SEPA = 'acceptMandatSepa',
	RIB = 'rib',
}

export const STEP1_CHECKBOX_NAME = 'options'
export const STEP1_FORM = 'step1'

export const STEP3_FORM_NAME = 'acceptConditions'

export const OPTIONS_TEST_DATA = [
	{
		libelle: 'Option 1',
		description: 'Description option 1',
		dateEffet: '01/01/2022',
		dateLimiteSouscription: '28/02/2022',
		dureeEngagement: 3,
		libellePrix: '0.1 % du salaire',
		numGar: '123456789',
		siret: '123456789',
		beneficiaires: [
			{
				libelle: 'Beneficiaire 1',
				prix: 0
			}
		],
		prix: 0.1,
		cumulative: true
	},
	{
		libelle: 'Option 2',
		description: 'Description option 2',
		dateEffet: '01/01/2022',
		dateLimiteSouscription: '28/02/2022',
		dureeEngagement: 1,
		numGar: '123456789',
		siret: '123456789',
		beneficiaires: [
			{
				libelle: 'Beneficiaire 1',
				prix: 12
			},
			{
				libelle: 'Beneficiaire 2',
				prix: 5
			}
		],
		prix: 17,
		cumulative: false
	}
]