import React, {useLayoutEffect} from 'react'
import {Stack, Typography} from '@mui/material'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import EmptyResult from '../../components/EmptyResult'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import {useIntl} from 'react-intl'
import FormatUtils from "../../../../utils/FormatUtils";

type GridFinanceYearChartChartProps = {
    idChart: string
    dataChart: any[]
}

const GridFinanceYearChart: React.FC<GridFinanceYearChartChartProps> = (
    {
        idChart,
        dataChart
    }
) => {
    const intl = useIntl()
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const isSmallScreen = isMobile || isTabletPortrait

    const dataRatiosCheck = dataChart.length === 1 && dataChart[0].ratio === 0

    useLayoutEffect(() => {

        if (dataChart && dataChart.length > 1) {
            let root = am5.Root.new(idChart)
            root.setThemes([am5themes_Animated.new(root)])
            root.numberFormatter.set("intlLocales", "fr-FR");
            root.numberFormatter.set("numberFormat", {
                maximumSignificantDigits: 3
            })
            root.setThemes([
                am5themes_Animated.new(root)
            ])
            root.interfaceColors.set("grid", am5.color(0xffffff));
            // @ts-ignore
            root._logo.dispose()

            let topValue = 0
            let bottomValue = 10000
            dataChart.forEach((data: any) => {
                if (data.ratio > topValue) {
                    topValue = data.ratio
                }
                if (data.ratio < bottomValue) {
                    bottomValue = data.ratio
                }
            })

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    layout: root.verticalLayout,
                })
            )
            // @ts-ignore
            chart.get("colors").set("colors", [
                am5.color(0xfc454a)
            ]);

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 3
            })
            xRenderer.grid.template.setAll({
                location: 1
            })

            let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: "year",
                    renderer: xRenderer,
                })
            )
            xAxis.data.setAll(dataChart)

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: bottomValue - 200,
                    max: topValue + 75,
                    visible: false,
                    strictMinMax: true,
                    renderer: am5xy.AxisRendererY.new(root, {
                        strokeOpacity: 0.1
                    })
                })
            )

            let series = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    name: "",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "ratio",
                    categoryXField: "year"
                })
            )
            series.strokes.template.setAll({
                templateField: "strokeSettings",
                strokeWidth: 3,
            })
            series.data.setAll(dataChart)

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(root, {
                        strokeWidth: 3,
                        stroke: series.get("stroke"),
                        radius: 5,
                        fill: root.interfaceColors.get("background"),
                    })
                })
            })
            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        text: "{valueY}%",
                        populateText: true,
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fill: am5.color(0xfc454a)
                    })
                })
            })

            chart.appear(1000, 100)

            return () => root.dispose()
        }
    }, [dataChart, idChart])


    if (!dataChart || dataRatiosCheck) {
        return (
            <Stack alignItems="center" direction="column" sx={{height: isSmallScreen ? '400px' : '500px'}} flex="1">
                <EmptyResult
                    nature="NUMBER"
                    message={intl.formatMessage({id: 'global.noKpiNumbers'})}
                />
            </Stack>
        )
    }
    if (dataChart.length === 1) {
        return (
            <Stack id={idChart} direction="row" alignItems="baseline" spacing="2px">
                <Typography fontSize="2rem" fontWeight="600" color="#fc454a" variant="body1">
                    {FormatUtils.formatNumber(dataChart[0].ratio)}%
                </Typography>
                <Typography variant="subtitle1" fontSize="0.813rem">
                    en {dataChart[0].year}
                </Typography>
            </Stack>
        )
    }
    return (
        <div id={idChart} style={{width: '300px', height: '150px'}}/>
    )
}

export default GridFinanceYearChart
