import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import ActivationAuthent from '../../../components/doubleAuthentComponents/ActivationAuthent'
import ModifyAuthent from '../../../components/doubleAuthentComponents/ModifyAuthent'
import ConfirmationAuthent from '../../../components/doubleAuthentComponents/ConfirmationAuthent'
import Popup from '../../../components/Popup'
import { getDateDoubleAuthent } from '../../../constant/configuration'
import FinalPopinAuthentKPI from '../../../components/doubleAuthentComponents/FinalPopinAuthentKPI'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import appConst from '../../../constant/appConstants'
import { isAfterNow } from '../../../utils/authentUtils'

type DoubleAuthentProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	currentEmail: string
	modification: boolean
}

const PopinDoubleAuthentKPI: React.FC<DoubleAuthentProps> = ({
	setOpen,
	open,
	currentEmail,
	modification,
	// @ts-ignore
	dateDoubleAuthent,
	// @ts-ignore
	getArticleByShortcut,
	// @ts-ignore
	activationAuthentArticle,
	// @ts-ignore
	finalisationAuthentArticle
}) => {
	const intl = useIntl()
	const [currentStep, setCurrentStep] = useState(modification ? 2 : 1)
	const [email, setEmail] = useState<string>(currentEmail)

	useEffect(() => {
		if (!activationAuthentArticle) {
			getArticleByShortcut(appConst.cardiboxApi, 'activate_authent')
		}
		if (!finalisationAuthentArticle) {
			getArticleByShortcut(appConst.cardiboxApi, 'finalize_authent')
		}
	}, [getArticleByShortcut, activationAuthentArticle, finalisationAuthentArticle])

	const title = () => {
		switch (currentStep) {
			case 1:
				return intl.formatMessage({ id: 'doubleAuthent.activation.title' })
			case 2:
				return modification ? intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.title.modification' }) : intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.title.activation' })
			case 3:
				return modification ? intl.formatMessage({ id: 'doubleAuthent.confirmation.title.modification' }) : intl.formatMessage({ id: 'doubleAuthent.confirmation.title.activation' })
			case 4:
				return intl.formatMessage({ id: 'doubleAuthent.finalPopin.title' })
			default:
				return undefined
		}
	}

	const renderStep = () => {
		switch (currentStep) {
			case 1:
				return <ActivationAuthent prevStep={() => setOpen(false)} nextStep={nextStep} textCardibox={activationAuthentArticle && activationAuthentArticle.fields.body} />
			case 2:
				return <ModifyAuthent recuperation={false} kpi={true} nextStep={nextStep} updateContact={updateContact} currentAuthent={'mail'} email={email} />
			case 3:
				return <ConfirmationAuthent nextStep={nextStep} prevStep={prevStep} currentAuthent={'mail'} email={email} espaceEntreprise={true} />
			case 4:
				return <FinalPopinAuthentKPI terminer={closePopup} textCardibox={finalisationAuthentArticle && finalisationAuthentArticle.fields.body} />
			default:
				return undefined
		}
	}

	const updateContact = (newTelephone: string, newEmail: string) => {
		newEmail && setEmail(newEmail)
	}

	const nextStep = () => {
		if (currentStep < 4) {
			setCurrentStep(currentStep + 1)
		}
	}

	const prevStep = () => {
		if (currentStep > 1) {
			setCurrentStep(currentStep - 1)
		}
	}
	const closePopup = () => {
		setOpen(false)
		setCurrentStep((modification || currentStep > 3) ? 2 : 1)
	}

	return (
		<Popup
			open={open}
			setOpen={setOpen}
			title={title()}
			width="720px"
			showCloseButton={isAfterNow(dateDoubleAuthent) || modification}
			onClose={closePopup}
		>
			{renderStep()}
		</Popup>
	)
}

const actions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}

const mapStateToProps = (state: any) => ({
	activationAuthentArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)['activate_authent'],
	finalisationAuthentArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)['finalize_authent'],
	dateDoubleAuthent: getDateDoubleAuthent()
})

export default connect(
	mapStateToProps,
	actions
)(PopinDoubleAuthentKPI)
