import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Stack, Typography } from '@mui/material'
import FileInput from '../../../../components/fileInput/FileInput'
import { MAINTENIR_ENFANT_MAJEUR_FIELDS } from '../services/maintenirEnfantMajeurConstants'
import RadioInput from '../../../../components/form/RadioInput'
import PatternTextInput from '../../../../components/PatternTextInput'
import { PATTERNS } from '../../../../components/formConstants'
import ButtonLink from '../../../../components/ButtonLink'
import NissTextInput from '../../../../components/NissTextInput'
import { toUpperCase } from '../../../../utils/stringUtils'

type StudyingFormProps = {
	selectedChild?: any
	values: any
	touched: any
	errors: any
}

const FIRST_REGIME_DATA = [
	{
		value: '1',
		label: 'Oui'
	},
	{
		value: '0',
		label: 'Non'
	}
]

const InternshipForm: React.FC<StudyingFormProps> = ({ selectedChild, values, touched, errors }) => {
	const intl = useIntl()
	const [modifyNISS, setModifyNISS] = useState<boolean>(false)

	return (
		<Stack direction="column" spacing="24px">
			<Typography variant="body2">
				<FormattedMessage id="maintenirEnfantMajeur.input.internship.description" />
			</Typography>
			<FileInput
				maxFileNumber={1}
				name={MAINTENIR_ENFANT_MAJEUR_FIELDS.CERTIFICAT}
				title={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.internship.file' })}
			/>
			<RadioInput
				name={MAINTENIR_ENFANT_MAJEUR_FIELDS.FIRST_REGIME}
				label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.internship.affiliation' })}
				data={FIRST_REGIME_DATA}
				error={touched && touched[MAINTENIR_ENFANT_MAJEUR_FIELDS.FIRST_REGIME] && errors && errors[MAINTENIR_ENFANT_MAJEUR_FIELDS.FIRST_REGIME]}
			/>

			{values[MAINTENIR_ENFANT_MAJEUR_FIELDS.FIRST_REGIME] === '1' ? (
					<>
						<Typography variant="body2">
							<FormattedMessage id="maintenirEnfantMajeur.input.ssData.description" />
						</Typography>
						{/*<PatternTextInput*/}
						{/*	maxWidth="unset"*/}
						{/*	name={MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_NISS_1}*/}
						{/*	format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
						{/*	mask="_"*/}
						{/*	label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.niss' })}*/}
						{/*	helperText={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.nissHelper' })}*/}
						{/*/>*/}
						<NissTextInput
							name={MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_NISS_1}
							label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.niss' })}
							helperText={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.nissHelper' })}
							format={toUpperCase}
							maxWidth="unset"
							mandatory
						/>
						<PatternTextInput
							maxWidth="unset"
							name={MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ORGANISME_1}
							format={PATTERNS.ORGANISME}
							mask="_"
							label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.organisme.label' })}
							helperText={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.organisme.helper' })}
							popoverContent={
								<Typography variant="subtitle2">
									<FormattedMessage id="maintenirEnfantMajeur.input.ssData.organisme.popover" />
								</Typography>
							}
						/>
						<FileInput
							maxFileNumber={1}
							name={MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ATTESTATION_SS_1}
							title={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.file.title' })}
							label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.file.label' })}
						/>
					</>
				)
				: values[MAINTENIR_ENFANT_MAJEUR_FIELDS.FIRST_REGIME] === '0' ? (
					<>
						<Typography variant="body2">
							<FormattedMessage id="maintenirEnfantMajeur.input.ssData.description" />
						</Typography>
						{/*<PatternTextInput*/}
						{/*	maxWidth="unset"*/}
						{/*	name={MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_NISS_2}*/}
						{/*	format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
						{/*	mask="_"*/}
						{/*	label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.niss' })}*/}
						{/*	helperText={modifyNISS ? intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.nissHelper' }) : undefined}*/}
						{/*	defaultValue={selectedChild?.matorg}*/}
						{/*	disabled={!modifyNISS}*/}
						{/*/>*/}
						<NissTextInput
							name={MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_NISS_2}
							label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.niss' })}
							helperText={modifyNISS ? intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.nissHelper' }) : undefined}
							defaultValue={selectedChild?.matorg}
							disabled={!modifyNISS}
							format={toUpperCase}
							maxWidth="unset"
							mandatory
						/>
						<PatternTextInput
							maxWidth="unset"
							name={MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ORGANISME_2}
							format={PATTERNS.ORGANISME}
							mask="_"
							label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.organisme.label' })}
							helperText={modifyNISS ? intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.organisme.helper' }) : undefined}
							defaultValue={`${selectedChild?.regime || ''}${selectedChild?.caisse || ''}`}
							disabled={!modifyNISS}
							popoverContent={
								<Typography variant="subtitle2">
									<FormattedMessage id="maintenirEnfantMajeur.input.ssData.organisme.popover" />
								</Typography>
							}
						/>
						{modifyNISS ? (
							<FileInput
								maxFileNumber={1}
								name={MAINTENIR_ENFANT_MAJEUR_FIELDS.INTERNSHIP_ATTESTATION_SS_2}
								title={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.file.title' })}
								label={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.ssData.file.label' })}
							/>
						) : (
							<>
								<Typography variant="body2">
									<FormattedMessage id="maintenirEnfantMajeur.input.ssData.modify" />
								</Typography>
								<Box display="flex" justifyContent="center">
									<ButtonLink sx={{ width: '230px' }} onClick={() => setModifyNISS(true)}>
										<FormattedMessage id="global.button.modify" />
									</ButtonLink>
								</Box>
							</>
						)}
					</>
				) : <></>
			}
		</Stack>
	)
}

export default InternshipForm