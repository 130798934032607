import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Divider, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import Loader from '../../../../components/Loader'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import PageLayout from '../../../../components/PageLayout'
import { getIndividuList } from '../../../webservices/affiliation/affiliationSelector'
import * as repaymentSelectors from '../../../webservices/remboursementsV2/repaymentSelectors'
import * as repaymentActions from '../../../webservices/remboursementsV2/repaymentActions'
import * as affiliationAction from '../../../webservices/affiliation/affiliationActions'
import FiltersRemboursementForm from '../components/FiltersRemboursementForm'
import ChartCell from '../components/ChartCell'
import RemboursementListCell from '../components/RemboursementListCell'
import EuroIcon from '@mui/icons-material/Euro'

const RemboursementListPage = (
	{
		// @ts-ignore
		loading,
		// @ts-ignore
		individuList,
		// @ts-ignore
		activeFilters,
		// @ts-ignore
		data,
		// @ts-ignore
		fetchRepayments,
		// @ts-ignore
		getAffiliation
	}
) => {

	const intl = useIntl()
	const { isTablet, isMobile, isTabletPortrait } = useBreakpoints()
	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(() => {
		if (individuList.length === 0) {
			getAffiliation()
		}
		if (individuList.length > 0) {
			fetchRepayments(individuList).then(() => {
				setIsLoading(false)
			})
		}
	}, [individuList, fetchRepayments, getAffiliation])

	return (
		<PageLayout title={intl.formatMessage({ id: 'remboursementConsultation.title' })}>
			{
				(loading || isLoading) ? (
					<Stack direction="column" spacing="24px">
						<Loader />
					</Stack>
				) : (
					<Stack direction="column" spacing="24px">
						<FiltersRemboursementForm />
						{
							(data && data.length > 0) ? (
								<>
									<Divider />
									<ChartCell data={data[0]} activeFilters={activeFilters} />
									<Divider />
									<RemboursementListCell data={data[1]} />
								</>
							) : (
								<>
									<Stack spacing={2} direction={isTablet || isMobile || isTabletPortrait ? 'column' : 'row'} justifyContent="center">
										<Stack spacing={isTablet || isMobile || isTabletPortrait ? 5 : 2} direction="row">
											<Stack direction="column" alignItems="center">
												<EuroIcon color="secondary" sx={{ fontSize: '48px', opacity: 0.5 }} />
												<Typography variant="body2" sx={{ color: '#929292' }}>
													<FormattedMessage id="remboursementConsultation.emptyState.message" values={{ size: 5 }} />
												</Typography>
												<Typography variant="body2" sx={{ color: '#929292' }}>
													{`${new Date(activeFilters.DATE_DEBUT).toLocaleDateString('fr-FR')} - ${new Date(activeFilters.DATE_FIN).toLocaleDateString('fr-FR')}`}
												</Typography>
											</Stack>
										</Stack>
									</Stack>
								</>
							)
						}
					</Stack>
				)
			}
		</PageLayout>
	)
}

export const repaymentSectionType = {
	chart: 'CHARTS',
	list: 'LIST'
}

const mapStateToProps = (state: any) => {
	const data = []
	const repaymentsList = repaymentSelectors.getRepaymentsList(state)
	const chartList = repaymentSelectors.getChartsList(state)

	if (repaymentsList.length) {
		if (chartList.length) {
			data.push({
				title: 'repayment.chart.title',
				data: chartList,
				type: repaymentSectionType.chart
			})
		}

		data.push({
			title: 'repayment.list.title',
			data: repaymentsList,
			type: repaymentSectionType.list
		})
	}

	return {
		repaymentsList,
		chartList,
		loading: repaymentSelectors.isLoading(state),
		// @ts-ignore
		individuList: getIndividuList(state),
		filters: repaymentSelectors.getFilters(state),
		activeFilters: repaymentSelectors.getActiveFilters(state),
		data
	}
}

const actions = {
	resetActiveFilters: repaymentActions.resetActiveFilters,
	fetchRepayments: repaymentActions.fetchRepayments,
	getAffiliation: affiliationAction.getAffiliation
}

export default connect(mapStateToProps, actions)(RemboursementListPage)
