import React from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Euro } from '@mui/icons-material'
import RefundCell from './RefundCell'
import { connect } from 'react-redux'
import { isRemboursementsLoading } from '../../../webservices/remboursements/remboursementsSelector'
import { useHistory } from 'react-router-dom'
import { SECONDARY_PATH } from '../../../../constant/path'

type RefundCardProps = {
	remboursements: any[]
}

const RefundCard: React.FC<RefundCardProps> = (
	{
		remboursements,
		// @ts-ignore
		loading
	}) => {
	const intl = useIntl()
	const { isMobile } = useBreakpoints()
	const history = useHistory()

	const height = isMobile ? 'auto' : '236px'

	return (
		<DashboardCard
			title={intl.formatMessage({ id: 'dashboard.refund.title' })}
			height={height}
			action={{
				label: intl.formatMessage({ id: 'global.button.seeAll' }),
				onClick: () => history.push(SECONDARY_PATH.REFUND_LIST)
			}}
			loading={loading}
		>
			{remboursements?.length > 0 ? (
				<Stack spacing="12px" width="100%">
					{remboursements.map((remboursement, index) => (
						<RefundCell key={index} remboursement={remboursement} />
					))}
				</Stack>
			) : (
				<Stack spacing={1} direction="column" alignItems="center" justifyContent="center" height="100%" width="100%" mt="24px">
					<Euro sx={{ width: '48px', height: '48px', color: '#f6c5a0' }} />
					<Typography textAlign="center" variant="body2" sx={{ color: '#929292' }}>
						<FormattedMessage id="dashboard.refund.emptyState" />
					</Typography>
				</Stack>
			)}
		</DashboardCard>
	)
}
const mapStateToProps = () => {
	const loadingSelector = isRemboursementsLoading('last')
	return (state: any) => ({
		loading: loadingSelector(state)
	})
}


export default connect(
	mapStateToProps,
	{}
)(RefundCard)
