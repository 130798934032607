import UserService from '../../../../../api/gerep/UserService'
import {UNIVERS_PARTICULIERS} from '../../../../constant/univers'
import {Dispatch} from 'react'
import UserServiceEE from '../../../../../api/gerepEe/UserServiceEE'

const ACTION_NAMESPACE = '/INSCRIPTION'

export const types = {
    SUBSCRIBE: `${ACTION_NAMESPACE}/SUBSCRIBE`,
    ACTIVATE: `${ACTION_NAMESPACE}/ACTIVATE`,
    ACTIVATE_LOADING: `${ACTION_NAMESPACE}/ACTIVATE_LOADING`,
    LOADING: `${ACTION_NAMESPACE}/LOADING`
}

export const subscribe = (idAdherent: string, nom: string, prenom: string, niss: string, password: string, dateNaissance: string, email: string, newsletter: boolean, captcharesponse: string) => (dispatch: Dispatch<any>) => {
    dispatch({type: types.LOADING, payload: true})
    return UserService.subscribe(idAdherent, nom, prenom, niss, password, dateNaissance, email, newsletter, captcharesponse).then((user) => {
        dispatch({type: types.LOADING, payload: false})
        if (user) {
            dispatch({
                type: types.SUBSCRIBE,
                payload: user
            })
        } else {
            throw new Error('Inscription failed')
        }
    }).catch((e) => {
        dispatch({type: types.LOADING, payload: false})
        throw e
    })
}

export const subscribeEE = (nom: string, prenom: string, password: string, email: string, newsletter: boolean, captcharesponse: string) => (dispatch: Dispatch<any>) => {
    dispatch({type: types.LOADING, payload: true})
    return UserServiceEE.subscribe(nom, prenom, password, email, newsletter, captcharesponse).then((user) => {
        dispatch({type: types.LOADING, payload: false})
        if (user) {
            dispatch({
                type: types.SUBSCRIBE,
                payload: user
            })
        } else {
            throw new Error('Inscription failed')
        }
    }).catch((e) => {
        dispatch({type: types.LOADING, payload: false})
        throw e
    })
}

export const ActivationStatus = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
}


export const activate = (idAdherent: string, codeActivation: string) => (dispatch: Dispatch<any>) => {
    dispatch({type: types.ACTIVATE_LOADING, payload: true})
    return UserService.activate(idAdherent, codeActivation).then(() => {
        dispatch({type: types.ACTIVATE_LOADING, payload: false})
        return dispatch({
            type: types.ACTIVATE,
            payload: ActivationStatus.SUCCESS
        })
    }).catch((e) => {
        if (e.status === 404) {
            dispatch({type: types.ACTIVATE_LOADING, payload: false})
            // le compte est introuvable, l'url activation est incorrect
            window.location.href = UNIVERS_PARTICULIERS.to('/login')
        } else {
            dispatch({type: types.ACTIVATE_LOADING, payload: false})
            return dispatch({
                type: types.ACTIVATE,
                payload: ActivationStatus.ERROR
            })
        }
        throw e
    })
}

export const activateEE = (idAdherent: string, codeActivation: string) => (dispatch: Dispatch<any>) => {
    dispatch({type: types.ACTIVATE_LOADING, payload: true})
    return UserServiceEE.activate(idAdherent, codeActivation).then(() => {
        dispatch({type: types.ACTIVATE_LOADING, payload: false})
        return dispatch({
            type: types.ACTIVATE,
            payload: ActivationStatus.SUCCESS
        })
    }).catch((e) => {
        if (e.status === 404) {
            dispatch({type: types.ACTIVATE_LOADING, payload: false})
            // le compte est introuvable, l'url activation est incorrect
            window.location.href = UNIVERS_PARTICULIERS.to('/login')
        } else {
            dispatch({type: types.ACTIVATE_LOADING, payload: false})
            return dispatch({
                type: types.ACTIVATE,
                payload: ActivationStatus.ERROR
            })
        }
        throw e
    })
}
