import { GET_SERVICES, GET_SERVICES_LOADING } from './servicesSanteActionTypes'
import { combineReducers } from 'redux'
import mentionsLegalesReducer from './mentionsLegales/servicesSanteMentionsLegalesReducer'
import appConst from '../../../constant/appConstants'
import configureCache from '../../../utils/cache'

const _cache = configureCache(appConst.cacheDuration.default)

const data = (state = [], action) => {
	switch (action.type) {
		case GET_SERVICES:
			return action.payload
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case GET_SERVICES_LOADING:
			return action.payload
		default:
			return state
	}
}

const expirationDate = (state = 0, action) => {
	switch (action.type) {
		case GET_SERVICES:
			return _cache.mark()
		default:
			return state
	}
}

export default combineReducers({
	data,
	loading,
	expirationDate,
	mentionsLegales: mentionsLegalesReducer
})
