const ACTION_NAMESPACE = '/USER'

export const types = {
	SET_USER_MAIL: `${ACTION_NAMESPACE}/SET_USER_MAIL`,
	SET_USER_AUTHENT: `${ACTION_NAMESPACE}/SET_USER_AUTHENT`,
	SET_USER_CARTE_TP_VIEW: `${ACTION_NAMESPACE}/SET_USER_CARTE_TP_VIEW`
}

export const setUserEmail = email => (dispatch) => {
	dispatch({
		type: types.SET_USER_MAIL,
		payload: email
	})
}

export const setUserAuthent = authent => (dispatch) => {
	dispatch({
		type: types.SET_USER_AUTHENT,
		payload: authent
	})
}
export const setCarteTpView = carteTpView => (dispatch) => {
	dispatch({
		type: types.SET_USER_CARTE_TP_VIEW,
		payload: carteTpView
	})
}
