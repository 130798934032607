import React, {useEffect} from 'react'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import {compose} from 'redux'
import {connect} from 'react-redux'
import appConst from '../../../constant/appConstants'
import {useBreakpoints} from '../../../components/breakpoints/BreakpointsProvider'
import {alpha, Stack, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import loginImg from '../../../resources/img/login_img.jpg'
import ForceResetForm from './ForceResetForm'
import {userSelector} from "../../../ea/ui/user/userSelectors";

const ARTICLE_INTRO = 'login_bienvenue'
const ARTICLE_FORCE_MDP_INTRO = 'force_reset_password'
const ARTICLE_FORCE_EXPIRED_MDP_INTRO = 'force_reset_expired_password'

const ForceResetPage = (
	{
		// @ts-ignore
		getArticleByShortcut,
		// @ts-ignore
		loginBienvenueArticle,
		// @ts-ignore
		forceResetIntro,
		// @ts-ignore
		forceResetExpiredIntro,
		// @ts-ignore
		user
	}
) => {
	const {isMobile, isTabletPortrait, width, height} = useBreakpoints()

	useEffect(() => {
		if (!loginBienvenueArticle) {
			getArticleByShortcut(appConst.cardiboxApi, ARTICLE_INTRO)
		}
		if(user.needResetPassword){
			if (!forceResetExpiredIntro) {
				getArticleByShortcut(appConst.cardiboxApi, ARTICLE_FORCE_EXPIRED_MDP_INTRO)
			}
		} else {
			if (!forceResetIntro) {
				getArticleByShortcut(appConst.cardiboxApi, ARTICLE_FORCE_MDP_INTRO)
			}
		}
	}, [getArticleByShortcut, loginBienvenueArticle])

	return (
		<Stack direction="row" justifyContent="flex-end">
			{!(isMobile || isTabletPortrait) && loginBienvenueArticle && (
				<Box flex={1} width="100%" height={height} position="relative">
					<img style={{width: '100%', height: height, objectFit: 'cover', zIndex: 0}} src={loginImg}
						 alt="background"/>
					<Stack
						direction="column"
						sx={{position: 'absolute', top: '0', left: '0', width: '100%', height: height, zIndex: 1}}
						bgcolor={alpha('#e0e4ff', 0.65)}
						alignItems="center"
						spacing="80px"
					>
						<img style={{width: '300px', marginTop: '80px'}}
							 src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
							 alt="logo"/>
						<Stack direction="column" spacing="24px">
							<Typography color="secondary" variant="h2">
								{loginBienvenueArticle.fields.title}
							</Typography>
							<div dangerouslySetInnerHTML={{__html: loginBienvenueArticle.fields.body}}/>
						</Stack>
					</Stack>
				</Box>)
			}
			<Stack width={!(isMobile || isTabletPortrait) ? '600px' : width} direction="column" spacing={2}
				   alignItems="center">
				<ForceResetForm forceResetIntro={user.needResetPassword? forceResetExpiredIntro : forceResetIntro}/>
			</Stack>
		</Stack>
	)
}

const actions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}

const mapStateToProps = (state: any) => ({
	loginBienvenueArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_INTRO],
	isArticleLoading: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_INTRO],
	forceResetIntro: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_FORCE_MDP_INTRO],
	forceResetExpiredIntro: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_FORCE_EXPIRED_MDP_INTRO],
	user: userSelector(state)
})

export default compose(
	connect(mapStateToProps, actions)
)(ForceResetPage)
