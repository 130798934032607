import GerepAPI from './GerepAPI'

export default class ServicesService {

	static getAbonnement(token) {
		return GerepAPI.get('/services/abonnementGerepDigital', null, token)
	}

	static demandeDevis(token, values) {
		return GerepAPI.postMultipart('/services/demandeDevis', values, token)
	}

	static demandeHospitalisation(token, values) {
		return GerepAPI.post('/services/demandeHospitalisation', values, token)
	}

	static abonnementGerepDigital(token, values) {
		return GerepAPI.post('/services/editAbonnementGerepDigital', values, token)
	}

	static enfantMajeurScolarise(token, values) {
		return GerepAPI.postMultipart('/services/enfantMajeur/scolarise', values, token)
	}

	static enfantMajeurApprentissage(token, values) {
		return GerepAPI.postMultipart('/services/enfantMajeur/apprentissage', values, token)
	}

	static enfantMajeurPoleEmploi(token, values) {
		return GerepAPI.postMultipart('/services/enfantMajeur/poleEmploi', values, token)
	}

	static getServicesSante(token) {
		return GerepAPI.get('/services/sante', null, token)
	}

	static getMentionsLegales(token) {
		return GerepAPI.get('/services/sante/mentions-legales', null, token)
	}

	static getHospiwayLink(token) {
		return GerepAPI.get('/services/sante/hospiway', null, token)
	}

	static demarrerTeleconsultation(beneficiaire, token) {
		return GerepAPI.post('/services/sante/vyv/mesdocteurs', {
			numindiv: beneficiaire
		}, token)
	}
}
