import React from 'react'
import {Card, CardContent, CardHeader, Divider, Stack, Typography} from '@mui/material'
import { useBreakpoints } from './breakpoints/BreakpointsProvider'

type PageLayoutProps = {
	children: React.ReactNode,
	title: React.ReactNode | string,
	subtitle?: React.ReactNode | string,
	isSmallPaper?: React.ReactNode | boolean,
	bodyPadding?: string
}

const PageLayout: React.FC<PageLayoutProps> = (
	{
		children,
		title,
		subtitle,
		bodyPadding = '24px 32px 32px 32px'
	}) => {
	const { isMobile, isTabletPortrait, isDesktop } = useBreakpoints()

	return (
		<Card sx={{ width: isTabletPortrait ? '576px' : isMobile ? 'auto' : isDesktop ? '1100px' : '996px', height: 'fit-content' }}>
			<CardHeader
				style={{ padding: isMobile ? '24px 12px' : '24px 32px' }}
				title={
					<Stack direction="column" >
						<Typography variant="h1">
							{title}
						</Typography>
						{
							subtitle && (
								<Typography alignSelf="flex-end" variant="subtitle2" marginBottom="-20px">
									{subtitle}
								</Typography>
							)
						}
					</Stack>
				}
			/>
			<Divider style={{ border: '1px solid #dedede' }} />
			<CardContent style={{ padding: isMobile ? '12px ' : bodyPadding }}>
				{children}
			</CardContent>
		</Card>
	)
}

export default PageLayout
