import React, { Dispatch, SetStateAction } from 'react'
import { CircularProgress, Stack } from '@mui/material'
import ButtonDefault from './ButtonDefault'
import { useBreakpoints } from './breakpoints/BreakpointsProvider'

type StepsButtonProps = {
	step: number
	setStep: Dispatch<SetStateAction<number>>
	maxSteps: number
	handleSubmit: any
	loading: boolean
	labelPrevious: string
	labelNext: string
	labelEnd: string
	moreStepsLater?: boolean
}

const resetScroll = () => {
	window.scrollTo(0, 0)
}

const StepsButton: React.FC<StepsButtonProps> = (
	{
		step,
		setStep,
		maxSteps,
		handleSubmit,
		loading,
		labelNext,
		labelPrevious,
		labelEnd,
		moreStepsLater = false
	}
) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()

	const previousStep = () => {
		setStep(step - 1)
		resetScroll()
	}
	const previousButton = () => {
		return (
			<>
				<ButtonDefault variant="outlined" style={{border:'2px solid'}} onClick={previousStep}>
					{labelPrevious}
				</ButtonDefault>
			</>
		)
	}
	const nextButton = () => {
		return (
			<>
				<ButtonDefault variant="contained" onClick={handleSubmit}>
					{labelNext}
				</ButtonDefault>
			</>
		)
	}
	const endButton = () => {
		return (
			<>
				<ButtonDefault variant="contained" onClick={handleSubmit}>
					{loading ? <CircularProgress size={24} color="inherit" /> : labelEnd}
				</ButtonDefault>
			</>
		)
	}
	return (
		<Stack
			direction={isMobile || isTabletPortrait ? 'column' : 'row-reverse'}
			spacing={isMobile || isTabletPortrait ? '12px' : '32px'}
			alignSelf={ isMobile || isTabletPortrait ? 'center' : undefined}
			justifyContent="center"
		>
			{step === maxSteps - 1 && !moreStepsLater ? endButton() : nextButton()}
			{step !== 0 ? previousButton() : undefined}
		</Stack>
	)
}
export default StepsButton