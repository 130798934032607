import { createSelector } from 'reselect'

const loginPagesSelector = state => state.pages && state.pages.login

export const loadingSelector = createSelector(
	loginPagesSelector,
	(login) => login.loading
)

export const redirectBoolSelector = createSelector(
	loginPagesSelector,
	(login) => login.redirectionBoolean
)

