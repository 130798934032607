import React, {Dispatch, SetStateAction} from 'react'
import {Stack, Typography} from '@mui/material'
import TextInput from '../../../../../components/form/TextInput'
import {
    BOOLEAN,
    BOOLEANS,
    DISPENSE_PREAFF_FIELDS,
    IDENTIFICATION_FIELDS,
    INFOS_PERSOS_FIELDS
} from '../../services/preaffiliationConstants'
import PatternTextInput from '../../../../../components/PatternTextInput'
import {PATTERNS} from '../../../../../components/formConstants'
import {FormattedMessage, useIntl} from 'react-intl'
import DatePickerInput from '../../../../../components/form/DatePickerInput'
import RadioInput from '../../../../../components/form/RadioInput'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AutocompleteInput from '../../../../../components/form/AutocompleteInput'
import {toUpperCase} from '../../../../../utils/stringUtils'
import {useBreakpoints} from '../../../../../components/breakpoints/BreakpointsProvider'
import AttestationTeletransmissionArea from '../Attestation&TeletransmissionArea'
import {cardiboxSelectors} from 'redux-cardibox'
import {compose} from 'redux'
import {connect} from 'react-redux'
import NissTextInput from '../../../../../components/NissTextInput'


const ARTICLE_DISPENSE_INFOS_PERSOS = 'intro_dispense_etape_2'


type InfosPersoAffiliationFormProps = {
    values: any
    pays: Country[]
    isDispense: boolean
    exemption?: boolean
    setExemption: Dispatch<SetStateAction<boolean | undefined>>
}

const InfosPersoAffiliationForm: React.FC<InfosPersoAffiliationFormProps> = (
    {
        values,
        pays,
        isDispense,
        exemption,
        setExemption,
        // @ts-ignore
        dispensePreaffInfosPersosArticle
    }
) => {

    const intl = useIntl()
    const { isMobile, isTabletPortrait } = useBreakpoints()
    const isMobOrTabVert = isMobile || isTabletPortrait

    return (
        <>
            <Stack direction="column" marginTop="24px" spacing="32px">
                <Stack
                    direction={isMobOrTabVert ? 'column' : 'row'}
                    spacing={isMobOrTabVert ? '32px' : '42px'}
                    flex="1"
                >
                    <DatePickerInput
                        name={IDENTIFICATION_FIELDS.BIRTH_DATE}
                        label={intl.formatMessage({id: 'preaffiliation.identification.form.birthDate.label'})}
                        maxWidth="unset"
                        disabled
                        mandatory
                    />
                    {/*<PatternTextInput*/}
                    {/*    maxWidth="unset"*/}
                    {/*    name={IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER}*/}
                    {/*    format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
                    {/*    label={intl.formatMessage({ id: 'preaffiliation.identification.form.NSS.label' })}*/}
                    {/*    defaultValue={values[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER]}*/}
                    {/*    disabled*/}
                    {/*    mandatory*/}
                    {/*/>*/}
                    <NissTextInput
                        name={IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER}
                        label={intl.formatMessage({ id: 'preaffiliation.identification.form.NSS.label' })}
                        defaultValue={values[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER]}
                        format={toUpperCase}
                        maxWidth="unset"
                        mandatory
                        disabled
                    />
                </Stack>

                {!isDispense && <AttestationTeletransmissionArea/>}

                <Stack direction="column" spacing="32px" flex="1">
                    <Stack
                        direction={isMobOrTabVert ? 'column' : 'row'}
                        spacing={isMobOrTabVert ? '32px' : '42px'}
                        flex="1"
                    >
                        <Stack
                            direction="column"
                            spacing="32px"
                            flex="1"
                        >
                            <TextInput
                                name={INFOS_PERSOS_FIELDS.ADRESS1}
                                label={intl.formatMessage({id: 'preaffiliation.personalInfos.form.adress.label'})}
                                placeholder={intl.formatMessage({id: 'preaffiliation.personalInfos.form.adress.placeholder'})}
                                maxWidth="unset"
                                mandatory
                                format={toUpperCase}
                            />
                            <TextInput
                                name={INFOS_PERSOS_FIELDS.ADRESS2}
                                label={intl.formatMessage({id: 'preaffiliation.personalInfos.form.adress.label2'})}
                                maxWidth="unset"
                                format={toUpperCase}
                            />
                            <TextInput
                                name={INFOS_PERSOS_FIELDS.ADRESS3}
                                label={intl.formatMessage({id: 'preaffiliation.personalInfos.form.adress.label3'})}
                                maxWidth="unset"
                                format={toUpperCase}
                            />
                        </Stack>

                        <Stack
                            direction="column"
                            spacing="32px"
                            flex="1"
                        >
                            <PatternTextInput
                                name={INFOS_PERSOS_FIELDS.ZIP_CODE}
                                format={PATTERNS.ZIPCODE}
                                label={intl.formatMessage({id: 'preaffiliation.personalInfos.form.zipCode.label'})}
                                placeholder={intl.formatMessage({id: 'preaffiliation.personalInfos.form.zipCode.placeholder'})}
                                maxWidth="unset"
                                mask="_"
                                mandatory
                            />
                            <TextInput
                                name={INFOS_PERSOS_FIELDS.CITY}
                                label={intl.formatMessage({id: 'preaffiliation.personalInfos.form.city.label'})}
                                placeholder={intl.formatMessage({id: 'preaffiliation.personalInfos.form.city.placeholder'})}
                                maxWidth="unset"
                                mandatory
                                format={toUpperCase}
                            />
                            <AutocompleteInput
                                name={INFOS_PERSOS_FIELDS.COUNTRY}
                                label={intl.formatMessage({id: 'preaffiliation.personalInfos.form.country.label'})}
                                options={pays.map((p) => toUpperCase(p.name))}
                                maxWidth="unset"
                                mandatory
                            />
                        </Stack>

                    </Stack>

                    <Stack
                        direction={isMobOrTabVert ? 'column' : 'row'}
                        spacing={isMobOrTabVert ? '32px' : '42px'}
                        flex="1"
                    >
                        <PatternTextInput
                            name={INFOS_PERSOS_FIELDS.PHONE}
                            format={PATTERNS.PHONE}
                            label={intl.formatMessage({ id: 'preaffiliation.personalInfos.form.phone.label' })}
                            placeholder={intl.formatMessage({id: 'preaffiliation.personalInfos.form.phone.placeholder'})}
                            mask="_"
                            maxWidth="unset"
                            mandatory
                        />
                        <TextInput
                            name={INFOS_PERSOS_FIELDS.MAIL}
                            label={intl.formatMessage({id: 'preaffiliation.personalInfos.form.mail.label'})}
                            placeholder={intl.formatMessage({id: 'preaffiliation.personalInfos.form.mail.placeholder'})}
                            maxWidth="unset"
                            mandatory
                        />
                    </Stack>

                    <Stack direction="row" spacing="8px">
                        <InfoOutlinedIcon width="400px"/>
                        <Typography variant="body2" fontSize="0.875rem">
                            <FormattedMessage id="preaffiliation.personalInfos.form.phone.helper"/>
                        </Typography>
                    </Stack>
                </Stack>

                {isDispense &&
                    <Stack direction="column" spacing="16px">
                        <Typography
                            variant="body2"
                            style={{fontWeight: '500', fontSize: '1rem'}}
                            dangerouslySetInnerHTML={{ __html: dispensePreaffInfosPersosArticle.fields.body }}
                        />
                        <RadioInput
                            name={DISPENSE_PREAFF_FIELDS.EXEMPTION_WISH}
                            label={intl.formatMessage({id: 'preaffiliation.dispense.question'})}
                            data={BOOLEANS.slice(0).reverse().map((boolean: any) => ({ value: boolean.value, label: boolean.label }))}
                            direction="row"
                            radioSpacing="60px"
                            onChange={(value) => {
                                setExemption(value === BOOLEAN.OUI.value)
                            }}
                        />
                    </Stack>
                }

                {isDispense && exemption !== undefined && !exemption && <AttestationTeletransmissionArea/>}
            </Stack>
        </>
    )
}

const actions = {
}
const mapStateToProps = (state: any) => ({
    dispensePreaffInfosPersosArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_DISPENSE_INFOS_PERSOS],
    isDispensePreaffInfosPersosArticleLoading: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_DISPENSE_INFOS_PERSOS]
})
export default compose(
    connect(mapStateToProps, actions)
)(InfosPersoAffiliationForm)