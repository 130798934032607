import * as SouscriptionService from '../../preaffiliation/api/SouscriptionService'
import { getToken } from '../../preaffiliation/user/userSelector'

export const downloadGuide = values => (dispatch, getState) => (
	SouscriptionService.downloadGuide(getToken(getState()), values)
)

export const downloadMandatSepa = values => (dispatch, getState) => (
	SouscriptionService.downloadMandatSepa(getToken(getState()), values)
)

export const souscrire = (numAdherent, idAdhesion, values) => (dispatch, getState) => (
	SouscriptionService.souscrire(numAdherent, idAdhesion, values, getToken(getState()))
)
