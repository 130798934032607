import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { Field } from 'react-final-form'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { mdiInformation } from '@mdi/js'
import { PatternFormat } from 'react-number-format'
import Popover from './Popover'

type PatternTextInputProps = {
	name: string
	label?: string | React.ReactNode
	helperText?: string
	size?: 'small' | 'medium'
	type?: 'text' | 'password' | 'tel'
	popoverContent?: React.ReactNode
	placeholder?: string
	showPasswordToggle?: boolean
	maxLength?: number
	validate?: (value: string) => string | undefined
	numberOnly?: boolean
	maxWidth?: string
	error?: string
	defaultValue?: string
	disabled?: boolean
	rows?: number
	format: string
	mask?: string
	rightIcon?: React.ReactNode
	mandatory?: boolean
}

// FIXME : trouver un moyen d'afficher des lettres dans le pattern
const PatternTextInput: React.FC<PatternTextInputProps> = (
	{
		name,
		label,
		helperText,
		size = 'small',
		type = 'text',
		popoverContent,
		placeholder,
		showPasswordToggle = true,
		validate,
		maxLength,
		numberOnly = false,
		maxWidth = '350px',
		error,
		defaultValue,
		disabled = false,
		rows = 1,
		format,
		mask,
		rightIcon,
		mandatory = false
	}) => {
	const [showPassword, setShowPassword] = useState<boolean>(false)
	const [isFocus, setIsFocus] = useState<boolean>(false)

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const numberOnlyProps = numberOnly ? { inputMode: 'numeric' as 'numeric', pattern: '[0-9]*' } : {}

	return (
		<Field name={name} validate={validate} initialValue={defaultValue}>
			{({ input, meta }) => {
				return (
					<PatternFormat
						customInput={TextField}
						format={format}
						mask={isFocus ? mask : undefined}
						onFocus={() => setIsFocus(true)}
						onBlur={() => setIsFocus(false)}
						multiline={rows > 1}
						rows={rows}
						disabled={disabled}
						type={type}
						error={!!error || ((meta.error || meta.submitError) && meta.touched)}
						placeholder={placeholder}
						label={label + (mandatory ? " *" : "")}
						color="secondary"
						size={size}
						helperText={meta.touched && meta.error || meta.touched && meta.submitError || helperText || error}
						value={input.value}
						onChange={input.onChange}
						style={{ width: '100%', maxWidth: maxWidth }}
						inputProps={{ maxLength, ...numberOnlyProps }}
						InputProps={{
							endAdornment: rightIcon ? rightIcon : popoverContent ? (
								<Popover iconPath={mdiInformation}>
									{popoverContent}
								</Popover>

							) : type === 'password' && showPasswordToggle ? (
								<span
									style={{ color: '#606060' }}
									onClick={handleClickShowPassword}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</span>
							) : null
						}}
					/>
				)
			}}
		</Field>
	)
}

export default PatternTextInput