import React, {useEffect, useMemo} from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import logoItelis from '../../../../resources/img/logo-itelis.png'
import logoSanteClair from '../../../../resources/img/logo-santeclair.jpg'
import {Stack, Typography} from '@mui/material'
import ButtonDashboard from '../../../../components/ButtonDashboard'
import {
	ACCES_RESEAU_GA_ACTION,
	getSanteClairUrl,
	LIEN_ITELIS,
	SERVICES_BIEN_ETRE_GA_CATEGORY
} from '../services/dashboardConstants'
import {profileSelector} from '../../user/userSelectors'
import * as reseauSoinActions from '../services/reseauSoinActions'
import {getSanteClairToken, isSanteClairTokenLoading} from '../services/reseauSoinSelector'
import {compose} from 'redux'
import {connect} from 'react-redux'
import ReactGA from 'react-ga'
import Box from '@mui/material/Box'
import Loader from '../../../../components/Loader'
import {SERVICES_SANTECLAIR} from "../../../webservices/servicesSante/servicesSanteUtils";

type NetworkPartnerCardProps = {
	isItelis: boolean
	isSanteClair: boolean
}

const NetworkPartnerCard: React.FC<NetworkPartnerCardProps> = (
	{
		isSanteClair,
		isItelis,
		// @ts-ignore
		santeClairToken,
		// @ts-ignore
		hasAcces,
		// @ts-ignore
		loadTokenSanteClair,
		// @ts-ignore
		santeClairTokenLoading
	}) => {
	const intl = useIntl()
	const { isMobile } = useBreakpoints()
	const height = isMobile ? 'auto' : '236px'

	const linkHref = useMemo(() => {
		if (isSanteClair && santeClairToken) {
			return getSanteClairUrl(santeClairToken, SERVICES_SANTECLAIR.PU)
		} else if (isItelis) {
			return LIEN_ITELIS
		} else {
			return '#'
		}
	}, [isSanteClair, isItelis, santeClairToken])

	useEffect(() => {
		if (hasAcces) {
			loadTokenSanteClair()
		}
	}, [])

	return (
		<DashboardCard height={height} title={intl.formatMessage({ id: 'dashboard.networkPartner.title' })}>
			{santeClairTokenLoading ?
				<Loader /> :
				<Stack direction="column" spacing="8px" width="100%" alignItems="center" justifyContent="center">
					<img style={{ maxHeight: '65px' }} src={isItelis ? logoItelis : logoSanteClair} alt={isItelis ? 'itelis logo' : 'santeclair logo'} />
					<Stack direction="column" spacing="16px" width="100%" alignItems="center" justifyContent="center">
						<Typography variant="body2" textAlign="center">
							<FormattedMessage id="dashboard.networkPartner.content" />
						</Typography>
						<a
							href={linkHref}
							style={{ textDecoration: 'none' }}
							target="_blank"
							rel="noreferrer"
							onClick={() => {
								if (isSanteClair) {
									ReactGA.event({
										category: SERVICES_BIEN_ETRE_GA_CATEGORY,
										action: ACCES_RESEAU_GA_ACTION,
										label: 'SANTECLAIR'
									})
								} else if (isItelis) {
									ReactGA.event({
										category: SERVICES_BIEN_ETRE_GA_CATEGORY,
										action: ACCES_RESEAU_GA_ACTION,
										label: 'ITELIS'
									})
								}
							}}
						>
							<Box>
								<ButtonDashboard>
									<FormattedMessage id="dashboard.networkPartner.buttons.access" />
								</ButtonDashboard>
							</Box>
						</a>
					</Stack>
				</Stack>
			}
		</DashboardCard>
	)
}

const mapStateToProps = (state: any) => ({
	hasAcces: profileSelector(state).santeclair,
	santeClairToken: getSanteClairToken(state),
	santeClairTokenLoading: isSanteClairTokenLoading(state)
})

const actions = {
	loadTokenSanteClair: reseauSoinActions.loadTokenSanteClair
}

export default compose(
	connect(mapStateToProps, actions)
)(NetworkPartnerCard)
