import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { Field } from 'react-final-form'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { mdiInformation } from '@mdi/js'
import { IMaskInput } from 'react-imask'
import Popover from './Popover'

type TextInputProps = {
	name: string
	label?: string | React.ReactNode
	helperText?: string
	size?: 'small' | 'medium'
	type?: 'text' | 'password' | 'email' | 'number'
	popoverContent?: React.ReactNode
	placeholder?: string
	showPasswordToggle?: boolean
	maxLength?: number
	validate?: (value: string) => string | undefined
	numberOnly?: boolean
	maxWidth?: string
	error?: string
	defaultValue?: string
	disabled?: boolean
	rows?: number
	onChange?: (newValue: any) => void
	rightIcon?: React.ReactNode
	mandatory?: boolean
	format?: any
}

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}


const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
	function TextMaskCustom(props, ref) {
		const { onChange, ...other } = props
		return (
			<IMaskInput
				{...other}
				mask="**** **** **** **** **** **** ***"
				// mask="FR## #### #### #### #### #### ###"
				// @ts-ignore
				inputRef={ref}
				onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
				overwrite
			/>
		)
	}
)

const IBANTextInput: React.FC<TextInputProps> = (
	{
		name,
		label,
		helperText,
		size = 'small',
		type = 'text',
		popoverContent,
		placeholder,
		showPasswordToggle = true,
		validate,
		maxLength,
		numberOnly = false,
		maxWidth = '350px',
		error,
		defaultValue,
		disabled = false,
		rows = 1,
		onChange,
		rightIcon,
		mandatory = false,
		format
	}) => {
	const [showPassword, setShowPassword] = useState<boolean>(false)

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const numberOnlyProps = numberOnly ? { inputMode: 'numeric' as 'numeric', pattern: '[0-9]*' } : {}

	return (
		<Field name={name} validate={validate} initialValue={defaultValue} parse={format}>
			{({ input, meta }) => {
				const handleChange = (newValue: any) => {
					input.onChange(newValue)
					onChange && onChange(newValue)
				}

				return (
					<TextField
						multiline={rows > 1}
						rows={rows}
						disabled={disabled}
						type={type !== 'password' ? type : showPassword ? 'text' : 'password'}
						error={!!error || ((meta.touched || meta.dirty || meta.modified) && (meta.error || meta.submitError))}
						placeholder={placeholder}
						label={label + (mandatory ? " *" : "")}
						color="secondary"
						size={size}
						helperText={((meta.touched || meta.dirty || meta.modified) && meta.error) ||
							((meta.touched || meta.dirty || meta.modified) && meta.submitError) ||
							helperText ||
							error
						}
						value={input.value}
						onChange={(e: any) => {
							handleChange(e.target.value)
						}}
						style={{ width: '100%', maxWidth: maxWidth }}
						inputProps={{ maxLength, ...numberOnlyProps }}
						InputProps={{
							inputComponent: TextMaskCustom as any,
							endAdornment: rightIcon ? rightIcon : popoverContent ? (
								<Popover iconPath={mdiInformation}>
									{popoverContent}
								</Popover>

							) : type === 'password' && showPasswordToggle ? (
								<span
									style={{ color: '#606060' }}
									onClick={handleClickShowPassword}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</span>
							) : null
						}}
					/>
				)
			}}
		</Field>
	)
}

export default IBANTextInput
