import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { MAIN_PATH, SECONDARY_PATH } from '../../../constant/path'
import ButtonDefault from '../../../components/ButtonDefault'

const NotFoundPage = () => {
	const history = useHistory()

	return (
		<Stack direction="column" spacing="48px" alignItems="center">
			<Typography fontSize="5.653rem" fontWeight={600} color="secondary">
				<FormattedMessage id="codeError.notFound.title" />
			</Typography>
			<Stack direction="column" spacing="24px" justifyContent="center">
				<Typography variant="body1">
					<FormattedMessage id="codeError.notFound.text" />
				</Typography>
				<Box display="flex" width="100%" justifyContent="center">
					<ButtonDefault onClick={() => history.push(MAIN_PATH.DASHBOARD)} variant="text" color="primary" sx={{ bgcolor: 'transparent' }}>
						<FormattedMessage id="codeError.buttons.home" />
					</ButtonDefault>
				</Box>
				<Box display="flex" width="100%" justifyContent="center">
					<ButtonDefault onClick={() => history.push(MAIN_PATH.HELP)} variant="text" color="primary" sx={{ bgcolor: 'transparent' }}>
						<FormattedMessage id="codeError.buttons.help" />
					</ButtonDefault>
				</Box>
				<Box display="flex" width="100%" justifyContent="center">
					<ButtonDefault onClick={() => history.push(SECONDARY_PATH.REFUND_LIST)} variant="text" color="primary" sx={{ bgcolor: 'transparent' }}>
						<FormattedMessage id="codeError.buttons.refund" />
					</ButtonDefault>
				</Box>
			</Stack>
		</Stack>
	)
}

export default NotFoundPage