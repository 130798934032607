import { createSelector } from 'reselect'

export const getArticles = state => state.articles.data

export const getArticleDoubleAuthent = createSelector(
	[getArticles],
	articles => (
		articles.filter(article => (
			article.raccourci === 'activate_authent' || article.raccourci === 'modify_authent' || article.raccourci === 'finalize_authent'
		))
	)
)
