/* eslint-disable import/prefer-default-export */

import { ITELIS, SANTECLAIR } from '../ea/webservices/servicesSante/servicesSanteUtils';
import appConst from '../constant/appConstants';

export const LIEN_ITELIS = 'http://partenaires.itelis.fr/partners?access_token=xdvzdfsp&intro=1'

export const SIMULATEUR_GA_CATEGORY = 'Simulateur'
export const SIMULATEUR_GA_ACTION = 'Estimation remboursement'

export const SERVICES_BIEN_ETRE_GA_CATEGORY = 'Services bien-etre'

export const ACCES_RESEAU_GA_ACTION = 'Accès au réseau'

export const TELECONSULTATION_GA_LIBELLE_SANTECLAIR = 'Santéclair'
export const TELECONSULTATION_GA_LIBELLE_ITELIS = 'Itélis'


export const getGALibelle = (idService, nomAssureur) => {
	switch (idService) {
		case SANTECLAIR:
			return TELECONSULTATION_GA_LIBELLE_SANTECLAIR
		case ITELIS:
			return TELECONSULTATION_GA_LIBELLE_ITELIS
		default:
			return nomAssureur
	}
}

export const getSanteClairUrl = (token, idAppli) => (
	appConst.santeClairUrl.replace('___idApplication___', idAppli) + token
)
