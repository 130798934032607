import React from 'react'
import { CircularProgress, Stack } from '@mui/material'

const Loader = () => {
	return (
		<Stack alignSelf="center" width="100%" height="fit-content" justifyContent="center" alignItems="center">
			<CircularProgress color="primary" />
		</Stack>
	)
}

export default Loader