import React from 'react'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import FileInput from '../../../../components/fileInput/FileInput'
import { MAINTENIR_ENFANT_MAJEUR_FIELDS } from '../services/maintenirEnfantMajeurConstants'

const FirstJobSearchingForm: React.FC = () => {
	const intl = useIntl()
	return (
		<Stack direction="column" spacing="24px">
			<Typography variant="body2">
				<FormattedMessage id="maintenirEnfantMajeur.input.searching.description" />
			</Typography>
			<FileInput
				maxFileNumber={1}
				name={MAINTENIR_ENFANT_MAJEUR_FIELDS.SEARCHING_FILES}
				title={intl.formatMessage({ id: 'maintenirEnfantMajeur.input.searching.file' })}
			/>
		</Stack>
	)
}

export default FirstJobSearchingForm