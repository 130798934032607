import { getExpirationDate, isAffiliationLoading } from './affiliationSelector'
import AffiliationService from '../../../api/gerep/AffiliationService';
import LocalStorage from '../../../business/storage/LocalStorage';

const ACTION_NAMESPACE = '/AFFILIATION'

export const types = {
	GET_AFFILIATION_LOADING: `${ACTION_NAMESPACE}/GET_AFFILIATION_LOADING`,
	GET_AFFILIATION: `${ACTION_NAMESPACE}/GET_AFFILIATION`
}

export const getAffiliation = () => (dispatch, getState) => {
	if (
		!isAffiliationLoading(getState()) &&
		(!getExpirationDate(getState()) ||
			getExpirationDate(getState()) < (+new Date()))) {
		dispatch({ type: types.GET_AFFILIATION_LOADING, payload: true })
		return AffiliationService.getAffiliation(LocalStorage.getToken())
			.then((aff) => {
				dispatch({ type: types.GET_AFFILIATION_LOADING, payload: false })
				return dispatch({
					type: types.GET_AFFILIATION,
					payload: aff
				})
			}).catch((e) => {
				dispatch({ type: types.GET_AFFILIATION_LOADING, payload: false })
				throw e
			})
	}
}
// L'affiliation en cache est toujours valable
// ou est en train d'être recupérée

export const downloadAttestationAffiliation = () => dispatch => (
	AffiliationService.downloadAttestationAffiliation(LocalStorage.getToken())
		.catch((e) => {
			console.error(e)
		})
)

export const downloadAttestationRadiation = () => dispatch => (
	AffiliationService.downloadAttestationRadiation(LocalStorage.getToken())
		.catch((e) => {
			console.error(e)
		})
)
