import React from 'react'
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material'
import { useBreakpoints } from './breakpoints/BreakpointsProvider'

type PageLayoutProps = {
	children: React.ReactNode,
	title: React.ReactNode | string,
	subtitle?: React.ReactNode | string,
	bodyPadding?: string
}

const PageLayout: React.FC<PageLayoutProps> = (
	{
		children,
		title,
		subtitle,
		bodyPadding = '24px 32px 32px 32px'
	}) => {
	const { isMobile, isTabletPortrait, isWidescreen } = useBreakpoints()

	return (
		<Card sx={{ width: isTabletPortrait ? '576px' : isMobile ? 'auto' : isWidescreen ? '1200px' : '900px', height: 'fit-content' }}>
			<CardHeader
				style={{ padding: isMobile ? '24px 12px' : '24px 32px' }}
				title={
					<>
						<Typography variant="h1">
							{title}
						</Typography>
						{
							subtitle && (
								<Typography variant="subtitle2">
									{subtitle}
								</Typography>
							)
						}
					</>
				}
			/>
			<Divider style={{ border: '1px solid #dedede' }} />
			<CardContent style={{ padding: isMobile ? '12px ' : bodyPadding }}>
				{children}
			</CardContent>
		</Card>
	)
}

export default PageLayout
