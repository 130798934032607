import React from 'react'
import { FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography, useTheme } from '@mui/material'
import { Field } from 'react-final-form'

type RadioInputProps = {
	name: string
	data: SelectOption[]
	label?: string | React.ReactNode
	error?: string
	direction?: 'row' | 'column'
	onChange?: (value: string) => void
	defaultValue?: string
	radioSpacing?: string
}

const RadioInput: React.FC<RadioInputProps> = (
	{
		data,
		name,
		label,
		error,
		direction = 'column',
		onChange,
		defaultValue,
		radioSpacing
	}) => {
	const theme = useTheme()

	return (
		<RadioGroup name={name} defaultValue={defaultValue}>
			<FormLabel>
				<Typography variant="body2">
					{label}
				</Typography>
			</FormLabel>
			<Stack direction={direction} spacing={radioSpacing ? radioSpacing : undefined}>
				{data.map(({ value, label, radioContent }, index) => (
					<Stack key={index} direction="column" spacing="12px">
						<FormControlLabel
							key={`${label}-${value}`}
							label={
								<Typography fontSize="0.875rem" fontWeight={500}>
									{label}
								</Typography>
							}
							value={value}
							control={
								<Field
									name={name}
									type="radio"
									value={value}
									initialValue={defaultValue}
									render={({ input }) => {
										return (
											<Radio
												color="secondary"
												{...input}
												onChange={() => {
													input.onChange(value)
													onChange && onChange(value)
												}}
											/>
										)
									}}
								/>
							}
						/>
						{radioContent}
					</Stack>
				))}
			</Stack>
			{error &&
				<Typography variant="subtitle1" sx={{ color: theme.palette.error.main }}>
					{error}
				</Typography>}
		</RadioGroup>
	)
}

export default RadioInput