import { getExpirationDate, isCarteTPLoading } from './carteTiersPayantSelector'
import CarteTPService from '../../../api/gerep/CarteTPService';
import LocalStorage from '../../../business/storage/LocalStorage';

const ACTION_NAMESPACE = '/CARTE_TP'

export const types = {
	GET_CARTE_TP_LOADING: `${ACTION_NAMESPACE}/GET_CARTE_TP_LOADING`,
	GET_CARTE_TP: `${ACTION_NAMESPACE}/GET_CARTE_TP`
}

export const loadCarteTP = () => (dispatch, getState) => {
	if (
		!isCarteTPLoading(getState()) &&
		getExpirationDate(getState()) < (+new Date())) {
		dispatch({ type: types.GET_CARTE_TP_LOADING, payload: true })
		return CarteTPService.getCarteTP(LocalStorage.getToken())
			.then((carteTP) => {
				dispatch({ type: types.GET_CARTE_TP_LOADING, payload: false })
				return dispatch({
					type: types.GET_CARTE_TP,
					payload: carteTP
				})
			}).catch((e) => {
				dispatch({ type: types.GET_CARTE_TP_LOADING, payload: false })
				throw e
			})
	}
}

export const downloadCarteTP = (numero, annee, format, callBack,callBackError) => () => (
	CarteTPService.downloadCarteTP(numero, annee, format, LocalStorage.getToken(), callBack,callBackError)
		.catch((e) => {
			console.error(e)
			return Promise.reject(e)
		})
)
