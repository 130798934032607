import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Card, CardContent, Divider, Stack, Typography} from '@mui/material'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import Loader from '../../../../components/Loader'
import RadialBarDashBoardChart from './RadialBarDashBoardChart'
import {FormattedMessage, useIntl} from 'react-intl'
import {MAIN_PATH_EE} from '../../../../constant/path'
import Icon from '@mdi/react'
import { mdiFinance } from '@mdi/js'
import ButtonLink from '../../../../components/ButtonLink'
import {useHistory} from 'react-router-dom'
import * as dataActions from '../../data/dataActions'
import {compose} from 'redux'
import {connect} from 'react-redux'
import EmptyResult from '../../components/EmptyResult'

type DashboardCardProps = {
	isTransparent?: boolean
	width?: string
	loading?: boolean
	cardContentSx?: Record<string, any>
	cardSx?: Record<string, any>
	cardContentPadding?: string
	data : any
	keyData: number
	setKeyData: Dispatch<SetStateAction<number>>
}

const DashboardCardEE: React.FC<DashboardCardProps> = (
	{
		isTransparent = false,
		width = '100%',
		loading = false,
		cardContentSx,
		cardSx,
		cardContentPadding = '16px 24px 24px 24px',
		data,
		// @ts-ignore
		handleSociete,
        keyData,
		setKeyData
	}) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const isMobOrTabVert = isMobile || isTabletPortrait

	const history = useHistory()
	const intl = useIntl()
	const [expand, setExpand] = useState<boolean>(false)

	useEffect(() => {
		if (keyData === data.societe.numindiv && !isMobile) {
			setExpand(true)
		} else {
			setExpand(false)
		}
	}, [keyData])

	const handleMouseClick = () => {
		if (expand) {
			setExpand(false)
		} else {
			if (keyData === data.societe.numindiv) {
				setExpand(true)
			} else {
				setKeyData(data.societe.numindiv)
			}
		}
	}


	return (
		<Card
			sx={{
				flex: !isMobile ? 1 : 'auto',
				borderTopLeftRadius: '32px',
				borderBottomRightRadius: '32px',
				boxShadow: isTransparent ? 0 : 1,
				bgcolor: isTransparent ? 'transparent' : '#fff',
				width: width,
				height: isMobile ? '100%' : expand ? '294px' : '150px',
				':hover': {
					backgroundColor: '#f4f4f4',
				},
				...cardSx
			}}
		>
			<CardContent
				sx={{
					display: 'flex',
					padding: !isTabletPortrait ? cardContentPadding : '8px 24px 24px 24px',
					height: isMobile ? 'auto' : expand ? '246px' : 'auto',
					cursor: 'pointer',
					'&:hover': {
						backgroundColor: '#f4f4f4',
					},
					...cardContentSx
				}}
				onClick={handleMouseClick}
			>
				{loading ?
					<Loader />
					:
					<Stack direction={isMobile ? 'column' : 'row'} width="100%" spacing="12px">
						<Stack
							flex={isMobOrTabVert ? '3' : '1'}
							direction="column"
							width="auto"
							ml="16px"
							my="16px"
							alignSelf="center"
						>
							<Typography variant="h2" sx={{ color: '#004687' }} >
								{data.societe.numindiv === "0"
									? intl.formatMessage({id : 'toutesSocietes.dashBoard.titleCard'})
									: data.societe.nom
								}
							</Typography>
							<Typography variant="subtitle1" sx={{ marginTop:"0 !important" }} >
								{data.societe.numindiv === "0"
									? intl.formatMessage({id : 'toutesSocietes.dashBoard.helperCard'})
									: `APE : ${data.societe.ape? data.societe.ape : " "} - SIRET : ${data.societe.siret? data.societe.siret : " "}`
								}
							</Typography>
							{data.societe.numindiv !== 0 &&
								<Typography
									variant="body1"
									sx={!expand ? {
										marginTop: '0 !important',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										display: '-webkit-box',
										lineClamp: (data.societe.nom).length > 20 ? 1 : 2,
										WebkitLineClamp: (data.societe.nom).length > 20 ? 1 : 2,
										WebkitBoxOrient: 'vertical'
									} : {
										marginTop: '0 !important'
									}}
								>
									{data.societe.adresse &&
										<>{data.societe.adresse}<br/></>
									}
									{data.societe.adresse2 &&
										<>{data.societe.adresse2}<br/></>
									}
									{data.societe.adresse3 &&
										<>{data.societe.adresse3}<br/></>
									}
									{data.societe.adresse4 &&
										<>{data.societe.adresse4}<br/></>
									}
									{data.societe.codpos} {data.societe.ville}<br/>
								</Typography>
							}
						</Stack>

						<Stack direction={isMobile ? 'column' : 'row'} flex={isMobOrTabVert ? '4' : '1'}>
							<Divider
								style={{
									border: '1px solid #dedede',
									height: isMobile ? undefined : (expand ? '250px' : '120px'),
									width: isMobile ? '100%' : undefined
								}}
							/>

							<Stack
								flex="1"
								direction="column"
								width="100%"
								alignSelf="center"
								pt={isMobile ? '16px' : undefined}
								pb={isMobile ? '12px' : undefined}
								px={isMobile ? '16px' : '4px'}
							>
								<Stack flex="1" alignItems="center" sx={{width: '100%'}}>
									<Typography variant="h2">
										{intl.formatMessage({id : 'dashBoard.titles.sante'})}
									</Typography>
								</Stack>
								{data.nbBeneficaire === undefined && data.nbUtilisateur === undefined
									&& data.depense === undefined && data.remboursement === undefined ?
									<EmptyResult
										nature="HOME"
										message={intl.formatMessage({id: 'global.noSanteDataHome'})}
									/> : (
										expand ?
											<>
												<Stack direction="row" alignSelf="center" >
													<Stack direction="column" flex="1" alignItems="center" pb={isMobile ? '16px' : undefined} sx={{width: '100%'}}>
														<RadialBarDashBoardChart
															isInt={false}
															idChart={"dashBoard_utilisationChart_" + data.societe.numindiv}
															middleLabel={data.nbUtilisateur && data.nbBeneficaire && data.nbBeneficaire !== 0
																? Math.round((data.nbUtilisateur / data.nbBeneficaire) * 100).toString() + "%"
																: intl.formatMessage({id : 'internalError.nonApplicable'})
															}
															dataChart={{
																value: data.nbUtilisateur && data.nbBeneficaire && data.nbBeneficaire !== 0
																	? Math.round((data.nbUtilisateur / data.nbBeneficaire) * 100)
																	: 0,
																color: "#b5bbff"
															}}
														/>
														<Typography
															variant="subtitle1"
															sx={{
																width: '200px',
																height: '36px',
																color: '#7a7a7a',
																fontWeight: '400',
																fontSize: '13px',
																lineHeight: '1.3',
																textAlign: 'center',
																marginTop: '0 !important'
															}}
														>
															<FormattedMessage id={`dashBoard.utilisationChart.title`} />
														</Typography>
													</Stack>
													<Stack flex="1" alignItems="center" sx={{ width: '100%'}}>
														<RadialBarDashBoardChart
															isInt={false}
															idChart={"dashBoard_remboursementChart_" + data.societe.numindiv}
															middleLabel={data.remboursement && data.depense && data.depense !== 0
																? Math.round((data.remboursement / data.depense) * 100).toString() + '%'
																: intl.formatMessage({id : 'internalError.nonApplicable'})
															}
															dataChart={{
																value: data.remboursement && data.depense && data.depense !== 0
																	? Math.round((data.remboursement / data.depense) * 100).toString()
																	: 0,
																color:"#4bc4c2"
															}}
														/>
														<Typography
															variant="subtitle1"
															sx={{
																width: "200px",
																height: "36px",
																color: "#7a7a7a",
																fontWeight: "400",
																fontSize: "13px",
																lineHeight: "1.3",
																textAlign: "center",
																marginTop:"0 !important"
															}}
														>
															<FormattedMessage id={`dashBoard.remboursementChart.title`} />
														</Typography>
													</Stack>
												</Stack>

												<Stack flex="1" alignItems="center" sx={{width: '100%'}}>
													<ButtonLink
														onClick={() => {
															handleSociete(data.societe.numindiv)
															history.push(MAIN_PATH_EE.SANTE_CONSOMMATION)}
														}
														variant="contained"
														startIcon={<Icon size="20px" path={mdiFinance}/>}
														sx={(theme) => isMobile ?
															{
																textAlign: 'start',
																lineHeight: '18px',
																color: theme.palette.primary.main
															} : {}
														}
													>
														<FormattedMessage id="dashBoard.button.sante"/>
													</ButtonLink>
												</Stack>
											</>
											:
											<Stack direction="row" pb="16px">
												<Stack flex="1" alignItems="center" justifyContent="center" >
													<Typography variant="body1" sx={{ color: '#b5bbff', fontWeight: 'bold', fontSize: '24px'}}>
														{data.nbUtilisateur && data.nbBeneficaire && data.nbBeneficaire !== 0
															? Math.round((data.nbUtilisateur / data.nbBeneficaire) * 100).toString() + "%"
															: intl.formatMessage({id : 'internalError.nonApplicable'})
														}
													</Typography>
													<Typography variant="body1" sx={{color: '#7a7a7a', fontWeight: '400', fontSize: '13px'}}>
														<FormattedMessage id={`dashBoard.utilisationChart.hoverTitle`} />
													</Typography>
												</Stack>
												<Stack flex="1" alignItems="center" justifyContent="center">
													<Typography variant="body1" sx={{color: '#4bc4c2', fontWeight: 'bold', fontSize: '24px'}}>
														{data.remboursement && data.depense && data.depense !== 0
															? Math.round((data.remboursement / data.depense) * 100).toString() + '%'
															: intl.formatMessage({id : 'internalError.nonApplicable'})
														}
													</Typography>
													<Typography variant="body1" textAlign="center" sx={{ color: '#7a7a7a', fontWeight: '400', fontSize: '13px'}}>
														<FormattedMessage id="dashBoard.remboursementChart.hoverTitle" />
													</Typography>
												</Stack>
											</Stack>
									)
								}
							</Stack>

							<Divider
								style={{
									border: '1px solid #dedede',
									height: isMobile ? undefined : (expand ? '250px' : '120px'),
									width: isMobile ? '100%' : undefined
								}}
							/>
						</Stack>

						<Stack
							flex={isMobOrTabVert ? '3' : '1'}
							direction="column"
							alignSelf="center"
							width="auto"
						>
							<Stack flex="1" alignItems="center" sx={{width: '100%'}}>
								<Typography variant="h2">
									{intl.formatMessage({id : 'dashBoard.titles.prevoyance'})}
								</Typography>
							</Stack>
							{data.tauxAT === undefined ?
								<EmptyResult
									nature="HOME"
									message={intl.formatMessage({id: 'global.noPrevDataHome'})}
								/> : (
									expand ?
										<Stack direction="column" flex="1" alignItems="center" sx={{width: '100%'}}>
											<RadialBarDashBoardChart
												isInt={false}
												idChart={"dashBoard_tauxPrevoyanceChart_" + data.societe.numindiv}
												middleLabel={data.tauxAT + "%"}
												dataChart={{value: data.tauxAT + "%", color: "#fc454a"}}
											/>
											<Typography
												variant="subtitle1"
												sx={{
													width: '200px',
													height: '36px',
													color: '#7a7a7a',
													fontWeight: '400',
													fontSize: '13px',
													lineHeight: '1.3',
													textAlign: 'center',
													marginTop: '0 !important'
												}}
											>
												<FormattedMessage id={`dashBoard.tauxPrevoyanceChart.title`} />
											</Typography>
											<ButtonLink
												onClick={() => {
													handleSociete(data.societe.numindiv)
													history.push(MAIN_PATH_EE.PREVOYANCE_ARRET_TRAVAIL)
												}}
												variant="contained"
												startIcon={<Icon size="20px" path={mdiFinance}/>}
												sx={(theme) => isMobile ?
													{
														textAlign: 'start',
														lineHeight: '18px',
														color: theme.palette.primary.main
													} : {}
												}
											>
												<FormattedMessage id="dashBoard.button.prevoyance"/>
											</ButtonLink>
										</Stack>
										:
										<Stack direction="column" flex="1" alignItems="center" justifyContent="center" pb={isMobile ? undefined : '16px'}>
											<Typography variant="body1" sx={{color: "#fc454a", fontWeight: "bold", fontSize: "24px"}}>
												{data.tauxAT + "%"}
											</Typography>
											<Typography variant="body1" sx={{color: "#7a7a7a", fontWeight: "400", fontSize: "13px"}}>
												<FormattedMessage id="dashBoard.tauxPrevoyanceChart.hoverTitle" />
											</Typography>
										</Stack>
								)
							}
						</Stack>
					</Stack>
				}
			</CardContent>
		</Card>
	)
}

const makeMapStateToProps = () => ({

})

const actions = {
	handleSociete: dataActions.handleSociete,
}

export default compose(
	connect(makeMapStateToProps, actions)
)(DashboardCardEE)
