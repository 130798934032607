import React, {useContext, useMemo, useState} from 'react'
import {Form} from 'react-final-form'
import {Box, CircularProgress, Divider, Link, Stack, Typography} from '@mui/material'
import ButtonDefault from '../../../../components/ButtonDefault'
import {FormattedMessage, useIntl} from 'react-intl'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import {useHistory} from 'react-router-dom'
import {MAIN_PATH, SECONDARY_PATH} from '../../../../constant/path'
import SelectInput from '../../../../components/form/SelectInput'
import {HOSPITALIZATION_FIELDS} from '../services/hospitalizationConstants'
import RadioInput from '../../../../components/form/RadioInput'
import {YES_OR_NO_DATA} from '../../../../components/formConstants'
import TextInput from '../../../../components/form/TextInput'
import PatternTextInput from '../../../../components/PatternTextInput'
import DatePickerInput from '../../../../components/form/DatePickerInput'
import {isBefore} from 'date-fns'
import ServicesService from '../../../../api/gerep/ServicesService'
import LocalStorage from '../../../../business/storage/LocalStorage'
import {ToastType} from '../../../../components/toast/toastConstants'
import {ToastContext} from '../../../../components/toast/ToastContext'

type DemandHospitalFormProps = {
	individuList: any[]
	dateDebutContrat: string
}

const HOSPITALIZATION_NATURE_DATA = [
	{
		value: '1',
		label: 'Médical'
	},
	{
		value: '2',
		label: 'Chirurgical (y compris césarienne)'
	}
]

const HospitalizationForm: React.FC<DemandHospitalFormProps> = (
	{
		individuList,
		dateDebutContrat
	}) => {
	const intl = useIntl()
	const history = useHistory()
	const {addToast} = useContext(ToastContext)
	const {isMobile, isTabletPortrait} = useBreakpoints()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

	const onSubmit = (values: any) => {
		const {
			mutuelle,
			...rest
		} = values

		if (mutuelle === 'non') {
			const telePhoneBody = rest?.telephone ? {telephone: rest.telephone.replace(/ /g, '')} : {}
			const faxBody = rest?.fax ? {fax: rest.fax.replace(/ /g, '')} : {}
			setIsLoading(true)
			return ServicesService.demandeHospitalisation(LocalStorage.getToken(), {
				...rest,
				dateEntree: new Date(rest.dateEntree).toLocaleDateString('fr-FR'),
				...faxBody,
				...telePhoneBody
			})
				.then(() => {
					addToast(ToastType.SUCCESS, 'global.success')
					setOpenConfirmation(true)
				})
				.catch((e) => {
					if (e?.data?._error) {
						addToast(ToastType.ERROR, e.data._error)
					} else if (e?.data) {
						return e.data
					} else {
						addToast(ToastType.ERROR, 'global.error.occurred')
					}
				})
				.finally(() => setIsLoading(false))
		}
	}

	const currentDate = useMemo(() => new Date(), [])

	const thirtyDaysLater = useMemo(() => {
		const date = new Date()
		date.setDate(date.getDate() + 30)
		return date
	}, [])

	const validate = (values: any) => {
		const errors: any = {}
		if (!values[HOSPITALIZATION_FIELDS.BENEFICIARY]) {
			errors[HOSPITALIZATION_FIELDS.BENEFICIARY] = intl.formatMessage({id: 'global.error.required'})
		}

		if (!values[HOSPITALIZATION_FIELDS.MUTUELLE]) {
			errors[HOSPITALIZATION_FIELDS.MUTUELLE] = intl.formatMessage({id: 'global.error.required'})
		} else if (values[HOSPITALIZATION_FIELDS.MUTUELLE] === 'non') {
			[
				'type',
				'nomEtablissement',
				'adresse',
				'codePostal',
				'ville',
				'email',
				'dateEntree'
			].forEach((field) => {
				if (!values[field]) {
					errors[field] = intl.formatMessage({id: 'global.error.required'})
				}
			})
		}

		if (dateDebutContrat
			&& !errors.dateEntree) {
			if (isBefore(new Date(values.dateEntree), new Date(dateDebutContrat))) {
				errors.dateEntree = 'La date d\'entrée doit être ultérieure à la date de début de votre contrat'
			}
		}

		return errors
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={validate}
			render={({handleSubmit, form: {reset}, values, errors, touched}) => (
				<form onSubmit={handleSubmit}>
					<Stack direction="column" spacing="24px">
						<Stack direction="column" spacing="12px">
							<Typography variant="body2">
								<FormattedMessage id="hospitalization.text.1"/>
							</Typography>

							<Typography variant="body2">
								<FormattedMessage
									id="hospitalization.text.2"
									values={{
										link:
											<Link
												component="button"
												variant="body2"
												sx={{verticalAlign: 'initial'}}
												onClick={() => history.push(SECONDARY_PATH.ACCOUNT_CONTRACT)}
											>
												<FormattedMessage id="hospitalization.links.contrat"/>
											</Link>
									}}
								/>
							</Typography>

							<Typography variant="body2">
								<FormattedMessage
									id="hospitalization.text.3"
									values={{
										link:
											<Link
												component="button"
												variant="body2"
												sx={{verticalAlign: 'initial'}}
												onClick={() => history.push(SECONDARY_PATH.REFUND_DEMAND)}
											>
												<FormattedMessage id="hospitalization.links.refund"/>
											</Link>
									}}
								/>
							</Typography>

							<Typography variant="body2">
								<FormattedMessage
									id="hospitalization.text.4"
									values={{
										link:
											<Link
												component="button"
												variant="body2"
												sx={{verticalAlign: 'initial'}}
												onClick={() => history.push(SECONDARY_PATH.SERVICES_SEND_QUOTE)}
											>
												<FormattedMessage id="hospitalization.links.devis"/>
											</Link>
									}}
								/>
							</Typography>

							<Typography variant="body2">
								<FormattedMessage id="hospitalization.text.5"/>
							</Typography>

						</Stack>

						<Divider/>

						<Stack direction="column" spacing="24px">
							<SelectInput
								name={HOSPITALIZATION_FIELDS.BENEFICIARY}
								data={individuList.map((individu: any) => ({
									label: `${individu[1].prenom} ${individu[1].nom}`,
									value: individu[1].numIndividu
								}))}
								label={intl.formatMessage({id: 'hospitalization.input.beneficiary.label'})}
								mandatory
							/>

							<RadioInput
								name={HOSPITALIZATION_FIELDS.MUTUELLE}
								label={intl.formatMessage({id: 'hospitalization.input.mutuelle.label'})}
								data={YES_OR_NO_DATA}
								direction="row"
								error={touched && touched[HOSPITALIZATION_FIELDS.MUTUELLE] && errors && errors[HOSPITALIZATION_FIELDS.MUTUELLE]}
							/>

							{values[HOSPITALIZATION_FIELDS.MUTUELLE] === 'oui' && (
								<Typography variant="body2">
									<FormattedMessage id="hospitalization.input.mutuelle.negative"
													  values={{br: <br/>}}/>
								</Typography>
							)}

							{values[HOSPITALIZATION_FIELDS.MUTUELLE] === 'non' && (
								<>
									<RadioInput
										name={HOSPITALIZATION_FIELDS.NATURE}
										data={HOSPITALIZATION_NATURE_DATA}
										label={intl.formatMessage({id: 'hospitalization.input.nature.label'})}
										error={touched && touched[HOSPITALIZATION_FIELDS.NATURE] && errors && errors[HOSPITALIZATION_FIELDS.NATURE]}
									/>
									<TextInput
										maxWidth="unset"
										name={HOSPITALIZATION_FIELDS.HOSPITAL_NAME}
										label={intl.formatMessage({id: 'hospitalization.input.hospitalName.label'})}
									/>
									<PatternTextInput
										maxWidth="unset"
										name={HOSPITALIZATION_FIELDS.NUM_FINESS}
										label={intl.formatMessage({id: 'hospitalization.input.numFiness.label'})}
										helperText={intl.formatMessage({id: 'hospitalization.input.numFiness.helper'})}
										format="#########"
									/>
									<TextInput
										maxWidth="unset"
										name={HOSPITALIZATION_FIELDS.ADDRESS}
										label={intl.formatMessage({id: 'hospitalization.input.address.label'})}
									/>
									<TextInput
										maxWidth="unset"
										name={HOSPITALIZATION_FIELDS.POSTAL_CODE}
										label={intl.formatMessage({id: 'hospitalization.input.postalCode.label'})}
									/>
									<TextInput
										maxWidth="unset"
										name={HOSPITALIZATION_FIELDS.CITY}
										label={intl.formatMessage({id: 'hospitalization.input.city.label'})}
									/>
									<PatternTextInput
										maxWidth="unset"
										name={HOSPITALIZATION_FIELDS.FAX}
										label={intl.formatMessage({id: 'hospitalization.input.fax.label'})}
										format="## ## ## ## ##"
									/>
									<PatternTextInput
										maxWidth="unset"
										name={HOSPITALIZATION_FIELDS.TELEPHONE}
										label={intl.formatMessage({id: 'hospitalization.input.phone.label'})}
										format="## ## ## ## ##"
									/>
									<TextInput
										maxWidth="unset"
										name={HOSPITALIZATION_FIELDS.EMAIL}
										label={intl.formatMessage({id: 'hospitalization.input.email.label'})}
									/>
									<DatePickerInput
										maxWidth="unset"
										name={HOSPITALIZATION_FIELDS.DATE_ENTRY}
										label={intl.formatMessage({id: 'hospitalization.input.entryDate.label'})}
										helperText={intl.formatMessage({id: 'hospitalization.input.entryDate.helper'})}
										initialValue={currentDate.toLocaleDateString('fr-FR')}
										maxDate={thirtyDaysLater}
									/>
								</>
							)}

						</Stack>

						<Stack alignItems="center">
							{values[HOSPITALIZATION_FIELDS.MUTUELLE] !== 'oui' ? (
								<ButtonDefault
									variant="contained"
									onClick={handleSubmit}
									type="submit"
								>
									{isLoading ? <CircularProgress size={24} color="inherit"/> :
										<FormattedMessage id="global.button.validate"/>}
								</ButtonDefault>) : (
								<ButtonDefault
									variant="outlined"
									onClick={() => history.push(MAIN_PATH.DASHBOARD)}
								>
									<FormattedMessage id="global.button.close"/>
								</ButtonDefault>
							)}
							<Box display="flex" width="100%" flex={1}
								 alignSelf={isMobile || isTabletPortrait ? 'unset' : 'baseline'}>
								<Typography sx={{
									position: isMobile || isTabletPortrait ? 'unset' : 'relative',
									bottom: '32px',
									left: 0,
									height: '32px',
									display: 'flex',
									alignItems: 'flex-end'
								}} variant="body2" color="#7a7a7a">
									<FormattedMessage id="global.mandatory"/>
								</Typography>
							</Box>
						</Stack>

						<ConfirmationPopin
							title={intl.formatMessage({id: 'hospitalization.title'})}
							open={openConfirmation}
							setOpen={setOpenConfirmation}
							resetForm={reset}
						/>
					</Stack>
				</form>
			)}
		/>
	)
}

export default HospitalizationForm
