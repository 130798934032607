import React, { useState } from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import { FormattedMessage, useIntl } from 'react-intl'
import { Stack, useTheme } from '@mui/material'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import CardButton from './CardButton'
import { AttachEmailOutlined, Euro } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Icon from '@mdi/react'
import { mdiFileDocumentMultipleOutline } from '@mdi/js'
import SendOtherDocumentPopup from './SendOtherDocumentPopup'
import { useHistory } from 'react-router-dom'
import { SECONDARY_PATH } from '../../../../constant/path'

const SendDocuments: React.FC = () => {
	const intl = useIntl()
	const history = useHistory()
	const { isMobile } = useBreakpoints()
	const [openSendOtherDoc, setOpenSendOtherDoc] = useState<boolean>(false)

	const height = isMobile ? 'auto' : '236px'
	const iconSize = !(isMobile) ? '56px' : '24px'
	const theme = useTheme()
	const iconMarginBottom = !(isMobile) ? '10px' : '0px'

	return (
		<DashboardCard height={height} title={intl.formatMessage({ id: 'dashboard.sendDocuments.title' })}>
			<Stack
				direction={isMobile ? 'column' : 'row'}
				width="100%"
				spacing="12px"
			>
				<Box flex={1} justifyContent="center" display="flex">
					<CardButton
						onClick={() => history.push(SECONDARY_PATH.REFUND_DEMAND)}
						isMobile={isMobile}
						variant="text"
						color="secondary"
						size="small"
						startIcon={
							<Euro color="secondary" sx={{ height: iconSize, width: iconSize }} style={{ marginBottom: iconMarginBottom }} />
						}
					>
						<FormattedMessage id="dashboard.sendDocuments.buttons.refund" />
					</CardButton>
				</Box>
				<Box flex={1} justifyContent="center" display="flex">
					<CardButton
						onClick={() => history.push(SECONDARY_PATH.SERVICES_SEND_QUOTE)}
						isMobile={isMobile}
						variant="text"
						color="secondary"
						size="small"
						startIcon={
							<AttachEmailOutlined color="secondary" sx={{ height: iconSize, width: iconSize }} style={{ marginBottom: iconMarginBottom }} />
						}
					>
						<FormattedMessage id="dashboard.sendDocuments.buttons.quote" />
					</CardButton>
				</Box>
				<Box flex={1} justifyContent="center" display="flex">
					<CardButton
						isMobile={isMobile}
						variant="text"
						color="secondary"
						onClick={() => setOpenSendOtherDoc(true)}
						size="small"
						startIcon={
							<Icon path={mdiFileDocumentMultipleOutline} size={iconSize} color={theme.palette.secondary.main} style={{ marginBottom: iconMarginBottom }} />
						}
					>
						<FormattedMessage id="dashboard.sendDocuments.buttons.other" />
					</CardButton>
				</Box>
				<SendOtherDocumentPopup open={openSendOtherDoc} setOpen={setOpenSendOtherDoc} />
			</Stack>
		</DashboardCard>
	)
}

export default SendDocuments
