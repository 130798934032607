import { types } from './echeancierActions'
import { combineReducers } from 'redux'
import appConst from '../../../constant/appConstants'

const data = (state = [], action) => {
	switch (action.type) {
		case types.GET_ECHEANCIER: {
			return action.payload
		}
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case types.GET_ECHEANCIER_LOADING: {
			return action.payload
		}
		default:
			return state
	}
}

const expirationDate = (state = 0, action) => {
	switch (action.type) {
		case types.GET_ECHEANCIER: {
			return (+new Date() + appConst.cacheDuration.contrats * 1000)
		}
		default:
			return state
	}
}

export default combineReducers({
	data,
	loading,
	expirationDate
})

