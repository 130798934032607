import { types } from './surveillanceActions'
import { combineReducers } from 'redux'
import appConst from '../../../../constant/appConstants'


const initialState = {
	arthus: true,
	arthusMaj: true,
	docuShareDemande: true,
	docuShareGuideGestion: true,
	arthusDemandes: true,
	docuShareGetDocument: true
}

const data = (state = initialState, action) => {
	switch (action.type) {
		case types.GET_STATES: {
			return action.payload
		}
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case types.GET_STATES_LOADING: {
			return action.payload
		}
		default:
			return state
	}
}

const expirationDate = (state = 0, action) => {
	switch (action.type) {
		case types.GET_STATES: {
			return (+new Date() + appConst.cacheDuration.surveillance * 1000)
		}
		default:
			return state
	}
}

export default combineReducers({
	data,
	loading,
	expirationDate
})
