import { Stack } from '@mui/material'
import React from 'react'
import Welcome from '../Welcome'
import Notifications from '../Notifications'
import DownloadApp from '../DownloadApp'
import Box from '@mui/material/Box'
import SendDocuments from '../SendDocuments'
import RefundCard from '../RefundCard'
import ServiceCard from '../ServiceCard'
import NetworkPartnerCard from '../NetworkPartnerCard'
import DemandsCard from '../DemandsCard'
import PersonalDataCard from '../PersonalDataCard'
import BeneficiaryCard from '../BeneficiaryCard'

type DashboardMobileProps = {
	affiliationExpired: boolean
	notifications: any[]
	remboursements: any[]
	user: any
	demandes: any[]
	assurePrincipal: any
	beneficiaires: any[]
}

const DashboardMobile: React.FC<DashboardMobileProps> = (
	{
		affiliationExpired,
		notifications,
		remboursements,
		user,
		demandes,
		assurePrincipal,
		beneficiaires
	}
) => {
	const hasUserServices = user?.profil?.itelis || user?.profil?.santeclair

	return (
		<Stack width="100%" direction="column" spacing="12px">
			<Welcome affiliationExpired={affiliationExpired} />
			<Box flex={1} height="200px">
				{notifications?.length > 0 ? <Notifications notifications={notifications} /> : <DownloadApp />}
			</Box>
			{affiliationExpired ?
				<ServiceCard affiliationExpired={affiliationExpired} servicesSante={user?.profil?.servicesSante} simulateur={user?.profil?.simulateur} virementCotisation={user?.profil?.virementCotisation} />
				:
				<SendDocuments />
			}
			<RefundCard remboursements={remboursements} />
			{!affiliationExpired && <ServiceCard affiliationExpired={affiliationExpired} servicesSante={user?.profil?.servicesSante} simulateur={user?.profil?.simulateur} virementCotisation={user?.profil?.virementCotisation} />}
			{!affiliationExpired && hasUserServices && <NetworkPartnerCard isItelis={user.profil.itelis} isSanteClair={user.profil.santeclair} />}
			<DemandsCard demands={demandes} affiliationExpired={affiliationExpired} />
			<PersonalDataCard user={user} affiliation={assurePrincipal} affiliationExpired={affiliationExpired} />
			<BeneficiaryCard beneficiaries={beneficiaires} affiliationExpired={affiliationExpired} />
		</Stack>
	)
}

export default DashboardMobile
