import React, { useEffect } from 'react'
import { getSanteClairToken, isSanteClairTokenLoading } from '../../dashboard/services/reseauSoinSelector'
import * as actions from '../services/reseauSoinActions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { ACCES_RESEAU_GA_ACTION, getSanteClairUrl, SERVICES_BIEN_ETRE_GA_CATEGORY } from '../../../../utils/servicesUtils'
import { SERVICES_SANTECLAIR } from '../../../webservices/servicesSante/servicesSanteUtils'
import { CircularProgress, Stack } from '@mui/material'
import ReactGA from 'react-ga'
import TeleconsultationCard from './TeleconsultationCard'
import ButtonDashboard from '../../../../components/ButtonDashboard'

type SanteClairBlockProps = {
	service: any
}

const SanteClairBlock: React.FC<SanteClairBlockProps> = (
	{
		// @ts-ignore
		santeClairToken,
		// @ts-ignore
		santeClairTokenLoading,
		// @ts-ignore
		loadTokenSanteClair,
		service
	}
) => {
	const url = getSanteClairUrl(santeClairToken, SERVICES_SANTECLAIR.PU)
	const buttonLibelle = service?.libelleLienWeb || 'Accédez au service'

	useEffect(() => {
		loadTokenSanteClair()
	}, [loadTokenSanteClair])

	return (
		<Stack spacing={'12px'}>
			<TeleconsultationCard
				service={service}
				lienWebCustom={
					<a
						href={url}
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => {
							ReactGA.event({
								category: SERVICES_BIEN_ETRE_GA_CATEGORY,
								action: ACCES_RESEAU_GA_ACTION,
								label: 'SANTECLAIR'
							})
						}}
						style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center' }}
					>
						<ButtonDashboard>
							{santeClairTokenLoading ? <CircularProgress size={24} color="inherit" /> : buttonLibelle}
						</ButtonDashboard>
					</a>
				}
			/>
		</Stack>
	)
}

const mapStateToProps = (state: any) => ({
	santeClairToken: getSanteClairToken(state),
	santeClairTokenLoading: isSanteClairTokenLoading(state)
})

const mappedActions = {
	loadTokenSanteClair: actions.loadTokenSanteClair
}

export default compose(
	connect(
		mapStateToProps,
		mappedActions
	)(SanteClairBlock))
