// lib
import {createSelector} from 'reselect'
// const

const _listCompany = state => state.listCompany.data
export const isListCompanyLoading = state => state.listCompany.loading
export const getExpirationDate = state => state.listCompany.expirationDate

/**
 * Les sociétés pour lesquelles l'utilisateur est interlocuteur, triées par ordre
 * alphabétique.
 */
export const getListCompany = createSelector([
	_listCompany
], (listCompany) => {
	if (Object.keys(listCompany).length > 1) {
		const listCompanySorted = listCompany.splice(1).sort((a, b) => a.nom.localeCompare(b.nom))
		return [...listCompany, ...listCompanySorted]
	}
	return listCompany
})
