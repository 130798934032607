import React from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {cardiboxSelectors} from 'redux-cardibox'
import {Box, Divider, Link, Stack, Typography} from '@mui/material'
import {useIntl} from 'react-intl'
import {
    CIVILITES,
    generalUseUrl,
    IDENTIFICATION_FIELDS,
    SEXES,
} from '../../services/preaffiliationConstants'
import {useBreakpoints} from '../../../../../components/breakpoints/BreakpointsProvider'
import TextInput from '../../../../../components/form/TextInput'
import SelectInput from '../../../../../components/form/SelectInput'
import PatternTextInput from '../../../../../components/PatternTextInput'
import {PATTERNS} from '../../../../../components/formConstants'
import DatePickerInput from '../../../../../components/form/DatePickerInput'
import Loader from '../../../../../components/Loader'
import CheckboxInput from '../../../../../components/form/CheckboxInput'
import {toUpperCase} from '../../../../../utils/stringUtils'
import NissTextInput from '../../../../../components/NissTextInput'


const ARTICLE_PREAFF_IDENTIF = 'preaffiliation_identification'

type IdentificationAffiliationFormProps = {
    errors: any
}
const IdentificationAffiliationForm: React.FC<IdentificationAffiliationFormProps> = (
    {
        // @ts-ignore
        preAffIdentifArticle,
        errors
    }
) => {
    const intl = useIntl()
    const { isMobile, isTabletPortrait} = useBreakpoints()
    const isMobOrTabVert = isMobile || isTabletPortrait

    if (!preAffIdentifArticle) {
        return <Loader />
    }

    const TERMS_DATA = [{
            label: intl.formatMessage({id: 'preaffiliation.identification.form.termsConditions.cntrManagement'}),
            value: IDENTIFICATION_FIELDS.CONTRACT_MANAGEMENT,
    }]
    const CONDITIONS_DATA = [{
        label: intl.formatMessage({id: 'preaffiliation.identification.form.termsConditions.generalUse.body'}, {
            link: (
                <Link
                    href={generalUseUrl}
                    underline="none"
                    target="_blank"
                    rel="noreferrer noopener"
                    sx={(theme) => ({color: theme.palette.secondary.main})}
                >
                    {intl.formatMessage({id: 'preaffiliation.identification.form.termsConditions.generalUse.link'})}
                </Link>
            )
        }),
        value: IDENTIFICATION_FIELDS.GENERAL_USE
    }]

    return (
        <>
            <Typography variant="body1" fontSize="1rem" dangerouslySetInnerHTML={{ __html: preAffIdentifArticle.fields.body }}/>
            <Divider sx={{borderBottomWidth:2}}/>

            <Stack direction="column" spacing="32px">
                <>
                    <Stack
                        direction={isMobOrTabVert ? 'column' : 'row'}
                        spacing={isMobOrTabVert ? '32px' : '42px'}
                        flex="1"
                    >
                        <TextInput
                            name={IDENTIFICATION_FIELDS.COMPANY_NAME}
                            label={intl.formatMessage({id: 'preaffiliation.identification.form.company.label'})}
                            disabled
                            maxWidth="unset"
                        />
                        <TextInput
                            name={IDENTIFICATION_FIELDS.BENEFICIARY_TYPE}
                            label={intl.formatMessage({id: 'preaffiliation.identification.form.category.label'})}
                            disabled
                            maxWidth="unset"
                        />
                    </Stack>

                    <Stack
                        direction={isMobOrTabVert ? 'column' : 'row'}
                        spacing={isMobOrTabVert ? '32px' : '42px'}
                        flex="1"
                    >
                         <SelectInput
                             name={IDENTIFICATION_FIELDS.CIVILITY}
                             label={intl.formatMessage({id: 'preaffiliation.identification.form.civility.label'})}
                             data={CIVILITES.map((civilite: any) => ({ value: civilite.value, label: civilite.label }))}
                             mandatory
                         />
                         <SelectInput
                             name={IDENTIFICATION_FIELDS.SEX}
                             label={intl.formatMessage({id: 'preaffiliation.identification.form.sex.label'})}
                             data={SEXES.map((sexe: any) => ({ value: sexe.value, label: sexe.label }))}
                             mandatory
                         />
                    </Stack>

                    <Stack
                        direction={isMobOrTabVert ? 'column' : 'row'}
                        spacing={isMobOrTabVert ? '32px' : '42px'}
                        flex="1"
                    >
                         <TextInput
                             name={IDENTIFICATION_FIELDS.LAST_NAME}
                             label={intl.formatMessage({id: 'preaffiliation.identification.form.lastName.label'})}
                             placeholder={intl.formatMessage({id: 'preaffiliation.identification.form.lastName.placeholder'})}
                             maxLength={30}
                             maxWidth="unset"
                             mandatory
                             format={toUpperCase}
                         />
                         <TextInput
                             name={IDENTIFICATION_FIELDS.FIRST_NAME}
                             label={intl.formatMessage({id: 'preaffiliation.identification.form.firstName.label'})}
                             placeholder={intl.formatMessage({id: 'preaffiliation.identification.form.firstName.placeholder'})}
                             maxLength={30}
                             maxWidth="unset"
                             mandatory
                             format={toUpperCase}
                         />
                    </Stack>

                    <Stack
                        direction={isMobOrTabVert ? 'column' : 'row'}
                        spacing={isMobOrTabVert ? '32px' : '42px'}
                        flex="1"
                    >
                        <DatePickerInput
                            name={IDENTIFICATION_FIELDS.BIRTH_DATE}
                            label={intl.formatMessage({id: 'preaffiliation.identification.form.birthDate.label'})}
                            maxWidth="unset"
                            mandatory
                        />
                        {/*<PatternTextInput*/}
                        {/*    name={IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER}*/}
                        {/*    format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
                        {/*    label={intl.formatMessage({id: 'preaffiliation.identification.form.NSS.label'})}*/}
                        {/*    placeholder={intl.formatMessage({id: 'preaffiliation.identification.form.NSS.placeholder'})}*/}
                        {/*    helperText={intl.formatMessage({id: 'preaffiliation.identification.form.NSS.info'})}*/}
                        {/*    maxWidth="unset"*/}
                        {/*    mask="_"*/}
                        {/*    mandatory*/}
                        {/*/>*/}
                        <NissTextInput
                            name={IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER}
                            label={intl.formatMessage({ id: 'preaffiliation.identification.form.NSS.label' })}
                            placeholder={intl.formatMessage({id: 'preaffiliation.identification.form.NSS.placeholder'})}
                            helperText={intl.formatMessage({id: 'preaffiliation.identification.form.NSS.info'})}
                            format={toUpperCase}
                            maxWidth="unset"
                            mandatory
                        />
                    </Stack>
                </>

                <Stack direction="column" spacing={isMobOrTabVert ? '8px' : 0}>
                    <Box>
                        <CheckboxInput
                            name={IDENTIFICATION_FIELDS.CONTRACT_MANAGEMENT}
                            data={TERMS_DATA as SelectOption[]}
                            error={errors && errors[IDENTIFICATION_FIELDS.CONTRACT_MANAGEMENT]}
                        />
                    </Box>
                    <Box>
                        <CheckboxInput
                            name={IDENTIFICATION_FIELDS.GENERAL_USE}
                            data={CONDITIONS_DATA as SelectOption[]}
                            error={errors && errors[IDENTIFICATION_FIELDS.GENERAL_USE]}
                        />
                    </Box>
                </Stack>
            </Stack>
        </>
    )
}

const actions = {
}

const mapStateToProps = (state: any) => ({
    preAffIdentifArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_PREAFF_IDENTIF],
    isPreAffIdentifArticleLoading: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_PREAFF_IDENTIF]
})


export default compose(
    connect(mapStateToProps, actions)
)(IdentificationAffiliationForm)

