import { createContext } from 'react'

type RefreshContextEEProps = {
	refresh: () => void
	refreshing: boolean
}

const context: RefreshContextEEProps = {
	refresh: () => {
	},
	refreshing: false
}

export const RefreshContextEE = createContext(context)
