import GerepAPI, { buildParams } from './GerepAPI'
import ApiError from './ApiError'
import appConst from '../../constant/appConstants';

export default class ContratsService {

	static getContrats(token) {
		return GerepAPI.get('/contrats', null, token)
	}

	static getSouscriptionGaranties(token) {
		return GerepAPI.get('/contrats/souscription', null, token)
	}

	static getEcheancier(token) {
		return GerepAPI.get('/contrats/echeancier', null, token)
	}

	static getEcheancierIntro(token) {
		return GerepAPI.get('/contrats/echeancierIntro', null, token)
	}

	static souscription(step, parts, token) {
		if (step === 2) {
			return GerepAPI.postMultipart('/contrats/validateSouscription', parts, token)
		}
		if (step === 3) {
			return GerepAPI.postMultipart('/contrats/souscription', parts, token)
		}
		return Promise.resolve()
	}

	static download(numGar, siret, annee, token) {
		const prepareUrl = '/contrats/prepareDownload'
		const url = '/contrats/download'
		return GerepAPI.get('/ticket', null, token)
			.then((weakToken) => {
				const queryString = buildParams({
					numGar,
					siret,
					annee: annee || new Date().getFullYear(),
					token: weakToken
				})
				const prepareDownloadUrl = `${appConst.gerepApi.baseUrl}${prepareUrl}?${queryString}`
				const documentUrl = `${appConst.gerepApi.baseUrl}${url}?${queryString}`
				return fetch(prepareDownloadUrl, {
					method: 'GET'
				}).then((response) => {
					if (!response.ok) {
						throw new ApiError(response.status, null, null)
					} else {
						window.location = documentUrl
						return null
					}
				}).catch((error) => {
					if (error instanceof ApiError) {
						throw error
					}
					throw new ApiError(500, null, 'GEREP API unreachable')
				})
			})
	}

	static downloadEcheancierCotisations(token, callback, callBackError) {
		return GerepAPI.downloadLoader('/contrats/downloadEcheancier', null, token, callback, callBackError)
	}
}
