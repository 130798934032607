import { Box, LinearProgress, linearProgressClasses, ListItemIcon, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ChevronRight } from '@mui/icons-material'
import { FormattedMessage, useIntl } from 'react-intl'
import RightDrawer from '../../../../components/RightDrawer'
import BeneficiaryAvatar from '../../../../components/BeneficiaryAvatar'
import FormatUtils from '../../../../utils/FormatUtils'
import { chartColorRepayment, formatDate } from '../services/remboursementConstants'
import moment from 'moment'
import 'moment/locale/fr'
import { styled } from '@mui/material/styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

type RemboursementCellProps = {
	children: any,
	beneficiary: any
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
	height: 13,
	borderRadius: 6,
	width: '100%',
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: '#7a7a7a'
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: '#4bc4c2'
	}
}))

const RemboursementCell: React.FC<RemboursementCellProps> = (
	{
		children,
		beneficiary
	}
) => {
	const intl = useIntl()
	const { isMobile } = useBreakpoints()
	const [openDrawer, setOpenDrawer] = useState<boolean>(false)
	const totalAmountRepayment = (children.montantRembSS || 0) + (children.montantRembGerep || 0) + (children.montantRembGerepOption || 0) + (children.montantRembAutre || 0)
	const percent = 100 * totalAmountRepayment / children.fraisReelSoins


	const data = [
		{
			key: 'montantRembSS',
			libelle: `remboursementConsultation.list.cell.drawn.montantRembSS`,
			val: children.montantRembSS,
			color: chartColorRepayment['montantRembSS']
		},
		{
			key: 'montantRembAutre',
			libelle: `remboursementConsultation.list.cell.drawn.montantRembAutre`,
			val: children.montantRembAutre,
			color: chartColorRepayment['montantRembAutre']
		},
		{
			key: 'montantRembGerepBase',
			libelle: `remboursementConsultation.list.cell.drawn.montantRembGerepBase`,
			val: children.montantRembGerep || 0,
			color: chartColorRepayment['montantRembGerep']
		},
		{
			key: 'montantRembGerepOption',
			libelle: `remboursementConsultation.list.cell.drawn.montantRembGerepOption`,
			val: children.montantRembGerepOption || 0,
			color: chartColorRepayment['montantRembGerepOption']
		},
		{
			key: 'montantResteACharge',
			libelle: `remboursementConsultation.list.cell.drawn.montantResteACharge`,
			val: (children.fraisReelSoins || 0) - (children.montantRembSS + children.montantRembAutre + children.montantRembGerep + children.montantRembGerepOption) > 0 ? (children.fraisReelSoins || 0) - (children.montantRembSS + children.montantRembAutre + children.montantRembGerep + children.montantRembGerepOption) : 0,
			color: chartColorRepayment['montantResteACharge']
		}
	]

	return (
		<>
			<Stack flex={10} sx={{ paddingTop: '16px' }} direction="row" spacing="12px" onClick={() => setOpenDrawer(true)}>
				<Stack direction="column">
					{beneficiary ?
						<BeneficiaryAvatar size="large" firstname={beneficiary[1].prenom} lastname={beneficiary[1].nom} type={beneficiary[1].typAdr} />
						:
						<BeneficiaryAvatar size="large" lastname={" "} type={18} />
					}
				</Stack>
				<Stack flex={9} direction="column" spacing="8px">
					<Stack direction="row">
						<Stack flex={5} direction="column" spacing="3px">
							<Typography variant="h4">
								{children.libActe}
							</Typography>
							<Typography variant="subtitle2">
								{moment(children.dateSoinsDate).locale('fr').format(formatDate.DoMMMYYYY)}
							</Typography>
						</Stack>
						<Stack flex={5} direction="row" justifyContent="flex-end" alignItems="end">
							<Typography variant="h4" sx={{ color: '#4bc4c2', paddingRight: '8px' }}>
								{FormatUtils.formatMontant(totalAmountRepayment)}
							</Typography>
							/
							<Typography variant="h4" sx={{ color: '#7a7a7a', paddingLeft: '8px' }}>
								{FormatUtils.formatMontant(children.fraisReelSoins)}
							</Typography>
						</Stack>
					</Stack>
					<Stack flex={10} direction="row">
						<BorderLinearProgress variant="determinate" value={percent} />
					</Stack>
				</Stack>
				<Stack justifyContent="center" direction="column" sx={isMobile ? { width: '2rem', paddingTop: '8px' } : { width: '40px', paddingTop: '8px' }}>
					<ListItemIcon sx={{ justifyContent: 'center' }}>
						<ChevronRight sx={{ fontSize: '2rem' }} />
					</ListItemIcon>
				</Stack>
			</Stack>
			<RightDrawer openModal={openDrawer} setOpenModal={setOpenDrawer} title={intl.formatMessage({ id: 'remboursementConsultation.list.cell.drawn.title' })}>
				<Stack flex={10} direction="row" spacing="12px">
					<Stack direction="column">
						{beneficiary ?
							<BeneficiaryAvatar size="large" firstname={beneficiary[1].prenom} lastname={beneficiary[1].nom} type={beneficiary[1].typAdr} />
							:
							<BeneficiaryAvatar size="large" lastname={" "} type={18} />
						}
					</Stack>
					<Stack flex={8} direction="column" spacing="8px">
						<Stack direction="row">
							<Stack flex={5} direction="column" spacing="3px">
								<Typography variant="h4">
									<FormattedMessage id="remboursementConsultation.list.cell.drawn.detail" values={{ libActe: children.libActe, date: moment(children.dateSoinsDate).locale('fr').format(formatDate.DoMMMYYYY) }} />
								</Typography>
								<Typography variant="subtitle1">
									{beneficiary ?
										<FormattedMessage id="remboursementConsultation.list.cell.drawn.checkTo" values={{ prenom: beneficiary[1].prenom, nom: beneficiary[1].nom }} />
										:
										<FormattedMessage id="remboursementConsultation.list.cell.drawn.checkToConcat" values={{ nom: children.prenomNomBeneficiaire }} />
									}
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
				<Stack flex={10} direction="row" spacing="12px">
					<Stack flex={5} direction="column" justifyContent="flex-start" align-items="center">
						<Typography variant="body1">
							<FormattedMessage id="remboursementConsultation.list.cell.drawn.total" />
						</Typography>
					</Stack>
					<Stack flex={5} direction="column" justifyContent="flex-end" align-items="center">
						<Typography variant="h3">
							{FormatUtils.formatMontant(children.fraisReelSoins)}
						</Typography>
					</Stack>
				</Stack>

				<Box display="flex" flex={1} sx={{ paddingTop: '40px' }}>
					<Stack flex={10} direction="column" spacing="24px">
						<Typography variant="body1">
							<FormattedMessage id="remboursementConsultation.list.cell.drawn.detailTitle" />
						</Typography>
						<Typography variant="body2">
							<FormattedMessage id="remboursementConsultation.list.cell.drawn.for" values={{ prenomNomNumdest: children.prenomNomNumdest }} />
						</Typography>

						<Stack direction="row">
							<Stack direction="column" spacing="26px">
								{data.map((entry: any) => {
									return (
										<Stack key={entry.key} sx={{ height: '24px' }} justifyContent="center">
											<Stack sx={{ height: '16px', width: '16px', borderRadius: '16px', backgroundColor: entry.color }} />
										</Stack>
									)
								})}
							</Stack>
							<Stack direction="column" alignContent="flex-start" spacing="26px">
								{data.map((entry: any) => {
									return (
										<Typography key={entry.key} variant="body2" sx={{ paddingLeft: '12px', height: '24px', justifyContent: 'center' }}>
											<FormattedMessage id={entry.libelle} />
										</Typography>)
								})}
							</Stack>
							<Stack direction="column" alignContent="flex-end" sx={{ paddingLeft: '68px' }} spacing="26px">
								{data.map((entry: any) => {
									return (
										<Typography key={entry.key} variant="h3" sx={{ height: '24px', justifyContent: 'center' }} alignSelf="flex-end">
											{`${parseFloat(entry.val).toFixed(2).replace('.', ',')} €`}
										</Typography>)
								})}
							</Stack>
						</Stack>
						{children.commentaire && (
							<Stack flex={10} direction="row" spacing="12px">
								<Stack direction="column" alignContent="flex-start" spacing="18px">
									<InfoOutlinedIcon color="secondary" />
								</Stack>
								<Stack flex={8} direction="column">
									<Typography variant="body2">
										{children.commentaire}
									</Typography>
								</Stack>
							</Stack>
						)}
					</Stack>
				</Box>
			</RightDrawer>
		</>
	)
}

export default RemboursementCell
