import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ActivationAuthent from '../../../components/doubleAuthentComponents/ActivationAuthent'
import ModifyAuthent from '../../../components/doubleAuthentComponents/ModifyAuthent'
import ConfirmationAuthent from '../../../components/doubleAuthentComponents/ConfirmationAuthent'
import FinalPopinAuthent from '../../../components/doubleAuthentComponents/FinalPopinAuthent'
import Popup from '../../../components/Popup'
import { useIntl } from 'react-intl'
import { getDateDoubleAuthent } from '../../../constant/configuration'
import { connect, useDispatch } from 'react-redux'
import { refreshAffiliation, refreshUser } from '../personalInfo/services/suggest/suggestActions'
import { refreshUserAuthent } from '../../../components/doubleAuthentComponents/services/authentActions'
import * as doubleAuthentActions from '../../webservices/articles/articleActions'
import { getArticleDoubleAuthent } from '../../webservices/articles/articleSelector'
import { isAfterNow } from '../../../utils/authentUtils'


type DoubleAuthentProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	currentAuthent?: string
	currentTelephone: string
	currentEmail: string
	modification: boolean
}

const PopinDoubleAuthentification: React.FC<DoubleAuthentProps> = ({
	setOpen,
	open,
	currentAuthent= 'sms',
	currentTelephone,
	currentEmail,
	modification,
	// @ts-ignore
	dateDoubleAuthent,
	// @ts-ignore
	loadArticles,
	// @ts-ignore
	articles
}) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const [currentStep, setCurrentStep] = useState(modification ? 2 : 1)
	const [methode, setMethode] = useState<string>('sms')
	const [telephone, setTelephone] = useState<string>(currentTelephone)
	const [email, setEmail] = useState<string>(currentEmail)

	useEffect(() => {
		loadArticles()
	}, [loadArticles])

	useEffect(() => {
		setMethode(determineMethod(currentAuthent))
	}, [currentAuthent])

	const determineMethod = (auth: string) => {
		if (auth === 'mail/sms' || auth === 'mail') {
			return 'mail'
		} else {
			return 'sms'
		}
	}

	const articleParRaccourci = (articles: any[], raccourci: string) => {
		if (articles.length === 0) {
			return null
		}
		const article = articles.find((article: any) => article.raccourci === raccourci)
		if (article) {
			return article.corps
		}
		return null
	}

	const title = () => {
		switch (currentStep) {
			case 1:
				return intl.formatMessage({ id: 'doubleAuthent.activation.title' })
			case 2:
				return modification ? intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.title.modification' }) : intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.title.activation' })
			case 3:
				return modification ? intl.formatMessage({ id: 'doubleAuthent.confirmation.title.modification' }) : intl.formatMessage({ id: 'doubleAuthent.confirmation.title.activation' })
			case 4:
				return intl.formatMessage({ id: 'doubleAuthent.finalPopin.title' })
			case 5:
				return intl.formatMessage({ id: 'doubleAuthent.recup.title' })
			case 6:
				return intl.formatMessage({ id: 'doubleAuthent.recup.title' })
			default:
				return undefined
		}
	}

	const renderStep = () => {
		switch (currentStep) {
			case 1:
				return <ActivationAuthent prevStep={() => setOpen(false)} nextStep={nextStep} textCardibox={articleParRaccourci(articles, 'activate_authent')} />
			case 2:
				return <ModifyAuthent nextStep={nextStep} updateContact={updateContact} currentAuthent={methode} telephone={telephone} email={email} change={changeMethode} textCardibox={articleParRaccourci(articles, 'modify_authent')} />
			case 3:
				return <ConfirmationAuthent nextStep={nextStep} prevStep={prevStep} currentAuthent={methode} telephone={telephone} email={email} />
			case 4:
				return <FinalPopinAuthent nextStep={nextStep} terminer={closePopup} methode={methode} textCardibox={articleParRaccourci(articles, 'finalize_authent')} />
			case 5:
				return <ModifyAuthent recuperation={true} nextStep={nextStep} updateContact={updateContact} currentAuthent={methode === 'mail' ? 'sms' : 'mail'} telephone={telephone} email={email} change={changeMethode} prevStep={prevStep} />
			case 6:
				return <ConfirmationAuthent nextStep={closePopup} prevStep={prevStep} currentAuthent={methode === 'mail' ? 'sms' : 'mail'} telephone={telephone} email={email} recuperation={true} />
			default:
				return undefined
		}
	}

	const updateContact = (newTelephone: string, newEmail: string) => {
		newTelephone && setTelephone(newTelephone)
		newEmail && setEmail(newEmail)
	}

	const nextStep = () => {
		if (currentStep < 6) {
			setCurrentStep(currentStep + 1)
		}
	}

	const prevStep = () => {
		if (currentStep > 1) {
			setCurrentStep(currentStep - 1)
		}
	}

	const changeMethode = (newMethode: string) => {
		setMethode(newMethode)
	}

	const closePopup = () => {
		setOpen(false)
		setCurrentStep((modification || currentStep > 3) ? 2 : 1)
		if (telephone !== currentTelephone) {
			refreshAffiliation(dispatch)
		}
		refreshUserAuthent(dispatch)
		refreshUser(dispatch)
	}

	return (
		<Popup
			open={open}
			setOpen={setOpen}
			title={title()}
			width="720px"
			showCloseButton={modification || isAfterNow(dateDoubleAuthent)}
			onClose={closePopup}
		>
			{renderStep()}
		</Popup>
	)
}

const mapStateToProps = (state: any) => ({
	dateDoubleAuthent: getDateDoubleAuthent(),
	articles: getArticleDoubleAuthent(state)
})

const actions = {
	loadArticles: doubleAuthentActions.loadArticles
}

export default connect(
	mapStateToProps,
	actions
)(PopinDoubleAuthentification)
