import { types } from './contratsActions'
import { combineReducers } from 'redux'
import appConst from '../../../constant/appConstants'

//= ============================
// GET COMPANY
//= ===========================
const data = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTRATS: {
			return action.payload
		}
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case types.GET_CONTRATS_LOADING:
			{
				return action.payload
			}
		default:
			return state
	}
}

const expirationDate = (state = 0, action) => {
	switch (action.type) {
		case types.GET_CONTRATS:
			{
				return (+new Date() + appConst.cacheDuration.contrats * 1000)
			}
		default:
			return state
	}
}

const contrats = combineReducers({
	data,
	loading,
	expirationDate
})
export default contrats
