import React from 'react'
import {Box, Stack, Typography} from '@mui/material'
import IBANTextInput from '../../../../../components/IBANTextInput'
import TextInput from '../../../../../components/form/TextInput'
import {useIntl} from 'react-intl'
import Loader from '../../../../../components/Loader'
import {cardiboxSelectors} from 'redux-cardibox'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {useBreakpoints} from '../../../../../components/breakpoints/BreakpointsProvider'
import {BANK_FIELDS, IDENTIFICATION_FIELDS} from '../../services/preaffiliationConstants'
import {toUpperCase} from '../../../../../utils/stringUtils'


const ARTICLE_PREAFF_BANKINFOS = 'intro_etape_3'

type BankInfosAffiliationFormProps ={
    values: any
}
const BankInfosAffiliationForm: React.FC<BankInfosAffiliationFormProps> = (
    {
        // @ts-ignore
        preAffBankInfosArticle,
        values
    }
) => {

    const intl = useIntl()
    const { isMobile, isTabletPortrait} = useBreakpoints()
    const isMobOrTabVert = isMobile || isTabletPortrait

    if (!preAffBankInfosArticle) {
        return <Loader />
    }

    const defaultTitulaire = () => {
        const nom = values[IDENTIFICATION_FIELDS.LAST_NAME].trim()
        const prenom = values[IDENTIFICATION_FIELDS.FIRST_NAME].trim()
        return prenom + " " + nom
    }

    return (

        <Stack direction="column" spacing="24px">
            <Typography
                variant="body2"
                style={{fontWeight: '500', fontSize: '1rem'}}
                dangerouslySetInnerHTML={{ __html: preAffBankInfosArticle.fields.body }}
            />

            <Stack direction="column" spacing="32px">
                <Stack
                    direction={isMobOrTabVert ? 'column' : 'row'}
                    spacing={isMobOrTabVert ? '32px' : '42px'}
                    flex="1"
                >
                    <IBANTextInput
                        maxWidth="unset"
                        name={BANK_FIELDS.IBAN}
                        label={intl.formatMessage({ id: 'preaffiliation.bankInfos.form.IBAN.label'})}
                        placeholder={intl.formatMessage({id: 'preaffiliation.bankInfos.form.IBAN.placeholder'})}
                        helperText={intl.formatMessage({id: 'preaffiliation.bankInfos.form.IBAN.helpertext'})}
                        format={toUpperCase}
                        mandatory
                    />
                    <TextInput
                        maxWidth="unset"
                        name={BANK_FIELDS.BANK_NAME}
                        label={intl.formatMessage({ id: 'preaffiliation.bankInfos.form.bank.label' })}
                        placeholder={intl.formatMessage({id: 'preaffiliation.bankInfos.form.bank.placeholder'})}
                        mandatory={true}
                        format={toUpperCase}
                    />
                </Stack>

                <Stack
                    direction={isMobOrTabVert ? 'column' : 'row'}
                    spacing={isMobOrTabVert ? '32px' : '42px'}
                    flex="1"
                >
                    <TextInput
                        maxWidth="unset"
                        name={BANK_FIELDS.ACCOUNT_OWNER}
                        label={intl.formatMessage({ id: 'preaffiliation.bankInfos.form.owner.label' })}
                        placeholder={intl.formatMessage({id: 'preaffiliation.bankInfos.form.owner.placeholder'})}
                        defaultValue={defaultTitulaire()}
                        mandatory={true}
                        format={toUpperCase}
                    />
                    {!isMobOrTabVert ? <Box maxWidth="600px" width="100%"/> : undefined}
                </Stack>
            </Stack>
        </Stack>
    )
}

const actions = {
}
const mapStateToProps = (state: any) => ({
    preAffBankInfosArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_PREAFF_BANKINFOS],
    isPreAffBankInfosArticleLoading: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_PREAFF_BANKINFOS]
})
export default compose(
    connect(mapStateToProps, actions)
)(BankInfosAffiliationForm)
