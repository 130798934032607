import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {VisibilityOff} from '@mui/icons-material'
import {Stack, Typography} from '@mui/material'

type EmptyResultProps = {
    message?: string | React.ReactNode
    nature?: "PAGE" | "GRAPH" | "NUMBER" | "HOME"
}

const EmptyResult: React.FC<EmptyResultProps> = (
    {
        message = <FormattedMessage id="global.no_results"/>,
        nature
    }
) => {
    const intl = useIntl()

    return (
        <Stack direction="column" alignItems="center" spacing={nature === "NUMBER" ? '4px' : '12px'}>
            {nature !== "HOME" &&
                <VisibilityOff
                    style={{
                        width: nature === "PAGE" ? '120px' : nature === "GRAPH" ? '48px' : '28px',
                        height: nature === "PAGE" ? '120px' : nature === "GRAPH" ? '48px' : '28px',
                        color: '#9cb5ce'
                    }}
                />
            }
            {nature === "PAGE" &&
                <Typography variant="h3" fontSize="1rem" color="#929292">
                    {intl.formatMessage({id: 'global.noData'})}
                </Typography>
            }
            <Typography variant="body2" fontSize="0,875rem" color="#929292" textAlign={nature === "HOME" ? 'center' : undefined}>
                {message}
            </Typography>
        </Stack>
    )
}

export default EmptyResult

