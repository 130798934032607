
export enum CONTACTER_GEREP_FIELDS {
    COMPANY = 'societe',
    OBJECT = 'objet',
    BODY = 'message',
    IN_CHARGE = 'chargeClientele',
    SEND_COPY = 'envoyerCopieCheck',
}

const required = (intl: any) => intl.formatMessage({id: 'global.error.required'})

export const validContacterGerepFields = (values: any, errors: any, intl: any) => {

    if (!values[CONTACTER_GEREP_FIELDS.COMPANY]) {
        errors[CONTACTER_GEREP_FIELDS.COMPANY] = required(intl)
    }
    if (!values[CONTACTER_GEREP_FIELDS.OBJECT]) {
        errors[CONTACTER_GEREP_FIELDS.OBJECT] = required(intl)
    }
    if (!values[CONTACTER_GEREP_FIELDS.BODY]) {
        errors[CONTACTER_GEREP_FIELDS.BODY] = required(intl)
    }
}
