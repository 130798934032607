import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getAffiliationAssurePrincipal, getIndividuListForManage, isAffiliationLoading } from '../../../webservices/affiliation/affiliationSelector'
import { profileSelector, userSelector } from '../../user/userSelectors'
import * as affiliationActions from '../../../webservices/affiliation/affiliationActions'
import * as remboursementsActions from '../../../webservices/remboursements/remboursementsActions'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import * as demandeActions from '../../../webservices/demandes/demandesActions'
import { getDernieresDemandes } from '../../../webservices/demandes/demandesSelectors'
import { getDerniersRemboursements, isLoading } from '../../../webservices/remboursements/remboursementsSelector'
import { getByDomainOrAll, isNotificationLoading } from '../../../webservices/notifications/NotificationSelectors'
import { loadNotifications } from '../../../webservices/notifications/NotificationActions'
import Loader from '../../../../components/Loader'
import DashboardMobile from '../components/dashboardLayout/DashboardMobile'
import DashboardTabletPortrait from '../components/dashboardLayout/DashboardTabletPortrait'
import DashboardTablet from '../components/dashboardLayout/DashboardTablet'
import DashboardLaptop from '../components/dashboardLayout/DashboardLaptop'
import DashboardComputer from '../components/dashboardLayout/DashboardComputer'
import * as contratsActions from '../../../webservices/contrats/contratsActions'
import { isContratsLoading } from '../../../webservices/contrats/contratsSelector'

const DashboardPage = (
	{
		// @ts-ignore
		user,
		// @ts-ignore
		refundLoading,
		// @ts-ignore
		notifications,
		// @ts-ignore
		remboursements,
		// @ts-ignore
		demandes,
		// @ts-ignore
		getDemandes,
		// @ts-ignore
		getDerniersRemboursements,
		// @ts-ignore
		loadNotifications,
		// @ts-ignore
		getAffiliation,
		// @ts-ignore
		assurePrincipal,
		// @ts-ignore
		beneficiaires,
		// @ts-ignore
		notificationLoading,
		// @ts-ignore
		affiliationLoading,
		// @ts-ignore
		loadContrats,
		// @ts-ignore
		isContratsLoading
	}
) => {
	useEffect(() => {
		loadNotifications()
		getDemandes()
		getAffiliation()
		getDerniersRemboursements()
		loadContrats()
		setLoading(false)
	}, [loadNotifications, getDemandes, getAffiliation, getDerniersRemboursements, loadContrats])

	const { isMobile, isLaptop, isTablet, isTabletPortrait } = useBreakpoints()

	const [loading, setLoading] = useState<boolean>(true)

	const affiliationExpired = user?.affiliationExpired

	if (loading || notificationLoading || affiliationLoading || refundLoading || isContratsLoading) {
		return <Loader />
	}

	const renderDashboard = () => {
		if (isMobile) {
			return (
				<DashboardMobile
					affiliationExpired={affiliationExpired}
					notifications={notifications}
					remboursements={remboursements}
					user={user}
					demandes={demandes}
					assurePrincipal={assurePrincipal}
					beneficiaires={beneficiaires}
				/>
			)
		} else if (isTabletPortrait) {
			return (
				<DashboardTabletPortrait
					affiliationExpired={affiliationExpired}
					notifications={notifications}
					remboursements={remboursements}
					user={user}
					demandes={demandes}
					assurePrincipal={assurePrincipal}
					beneficiaires={beneficiaires}
				/>
			)
		} else if (isTablet) {
			return (
				<DashboardTablet
					affiliationExpired={affiliationExpired}
					notifications={notifications}
					remboursements={remboursements}
					user={user}
					demandes={demandes}
					assurePrincipal={assurePrincipal}
					beneficiaires={beneficiaires}
				/>
			)
		} else if (isLaptop) {
			return (
				<DashboardLaptop
					affiliationExpired={affiliationExpired}
					notifications={notifications}
					remboursements={remboursements}
					user={user}
					demandes={demandes}
					assurePrincipal={assurePrincipal}
					beneficiaires={beneficiaires}
				/>
			)
		} else {
			return (
				<DashboardComputer
					affiliationExpired={affiliationExpired}
					notifications={notifications}
					remboursements={remboursements}
					user={user}
					demandes={demandes}
					assurePrincipal={assurePrincipal}
					beneficiaires={beneficiaires}
				/>
			)
		}
	}

	return (
		<>
			{renderDashboard()}
		</>
	)
}

const actions = {
	getAffiliation: affiliationActions.getAffiliation,
	downloadAttestationAffiliation: affiliationActions.downloadAttestationAffiliation,
	downloadAttestationRadiation: affiliationActions.downloadAttestationRadiation,
	getDerniersRemboursements: remboursementsActions.getDerniersRemboursements,
	getDemandes: demandeActions.getDemandes,
	loadContrats: contratsActions.loadContrats,
	loadNotifications
}

const mapStateToProps = (state: any) => {
	const notificationsSelector = getByDomainOrAll()
	const derniersRemboursementsSelector = getDerniersRemboursements(3)
	const dernieresDemandes = getDernieresDemandes(3)

	return ({
		notifications: notificationsSelector(state),
		user: userSelector(state),
		profile: profileSelector(state),
		assurePrincipal: getAffiliationAssurePrincipal(state),
		affiliationLoading: isAffiliationLoading(state),
		notificationLoading: isNotificationLoading(state),
		refundLoading: isLoading(state),
		remboursements: derniersRemboursementsSelector(state),
		demandes: dernieresDemandes(state),
		// @ts-ignore
		beneficiaires: getIndividuListForManage(state),
		isContratsLoading: isContratsLoading(state)
	})
}

export default compose(
	connect(mapStateToProps, actions)
)(DashboardPage)

