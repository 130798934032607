import * as React from 'react'
import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

type CardButtonProps = ButtonProps & {
	isMobile?: boolean
	children: React.ReactNode
}

const CardButtonComponent = styled(ButtonMUI)<CardButtonProps>(() => ({
	'&.MuiButton-root': {
		borderRadius: 24,
		height: '100%',
		width: '100%',
		alignItems: 'center',
		backgroundColor: 'white',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#fae2d0'
		}
	},
	'.MuiButton-startIcon': {
		margin: 0
	}
}))

const CardButton: React.FC<CardButtonProps> = (
	{
		isMobile,
		children,
		...style
	}) => {
	return (
		<CardButtonComponent
			{...style}
			sx={{
				paddingLeft: isMobile ? '16px' : 0,
				justifyContent: isMobile ? 'left' : 'center',
				flexDirection: isMobile ? 'row' : 'column'
			}}
		>
			<Typography textAlign={isMobile ? 'initial' : 'center'} paddingLeft={isMobile ? '12px' : 0} display="flex" alignItems={!isMobile ? 'flex-start' : 'center'} maxWidth={isMobile ? 'unset' : '144px'} minHeight="60px" variant="body2">
				{children}
			</Typography>
		</CardButtonComponent>
	)
}

export default CardButton