export const TypeAdherent = {
	ASSURE_PRINCIPAL: 0,
	CONJOINT: 1,
	CONCUBIN: 3,
	ENFANTS: 2,
	ENFANT_INVALIDE: 15
}

export const getOrder = (typeAdherent) => {
	switch (typeAdherent) {
		case TypeAdherent.ASSURE_PRINCIPAL: {
			return 0
		}
		case TypeAdherent.CONJOINT: {
			return 1
		}
		case TypeAdherent.CONCUBIN: {
			return 2
		}
		case TypeAdherent.ENFANTS: {
			return 3
		}
		case TypeAdherent.ENFANT_INVALIDE: {
			return 4
		}
		default: {
			return 100
		}
	}
}

export const sortByType = (type1, type2) => getOrder(type1) - getOrder(type2)
