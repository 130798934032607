import {flatten} from 'flat'
import appConst from '../constant/appConstants';

/* eslint-disable max-len, quotes, quote-props */

export default flatten({
    global: {
        button: {
            confirm: 'Confirmer',
            ok: 'OK',
            seeAll: 'Voir plus',
            modify: 'Modifier',
            help: 'Besoin d’aide ?',
            validate: 'Valider',
            newDemand: 'Nouvelle demande',
            goBackToHome: 'Revenir à l’accueil',
            goBackBeneficiary: 'Retour aux bénéficiaires',
            continue: 'Continuer',
            close: 'Fermer',
            login: 'Se connecter',
            meLogin: 'Me connecter',
            cancel: 'Annuler',
            goBack: 'Retour',
            prevStep: 'Précédent'
        },
        error: {
            required: 'Champ obligatoire',
            login: 'Identifiant ou mot de passe incorrect. Vérifiez que vous avez bien saisi le n° d\'adhérent ou l\'adresse email de l\'assuré principal, ainsi que le bon mot de passe.',
            generalCondition: 'Vous devez accepter les conditions générales d\'utilisation',
            passwordsDontMatch: 'La confirmation de mot de passe est différente',
            emailsDontMatch: 'L\'adresse email saisie ne correspond pas',
            format: 'Format incorrect',
            occurred: 'Une erreur est survenue',
            noPersonalInfoEdited: 'Echec de la modification des informations personnelles. Aucune donnée n\'a été modifiée',
            personInfoFailed: 'Echec de la modification des informations personnelles',
            invalidEmail: 'Adresse email invalide',
            validEmail: 'La confirmation d\'email est différente',
            matchingEmail: 'Veuillez choisir une adresse mail différente',
            passwordsNotMatch: 'La confirmation de mot de passe est différente',
            dateTooOld: 'Date invalide. Veuillez choisir une date plus récente',
            dateTooFar: 'Veuillez choisir une date plus ancienne',
            sizeTooHeavy: 'Fichier trop lourd (Ne doit pas excéder ' + appConst.fileUpload.maxSizMo + 'Mo)',
            totalSizeTooHeavy: 'Taille totale des pièces jointes supérieure à 10Mo',
            fieldRequired: 'Modifiez au moins un champ',
            invalidDate: 'Date invalide',
            startingDateTooOld: 'Pour une affiliation rétroactive de plus de 30 jours, il convient de passer par votre Service du Personnel.',
            chooseOption: 'Veuillez choisir une option',
            dateMin: 'Veuillez saisir une date après le {date}',
            invalidPlage: 'Plage de recherche incohérente',
            dateBeforeToday: 'Date antérieure à la date du jour',
            invalidPhone: 'Numéro de téléphone invalide',
            invalidCellphone: 'Le numéro de téléphone saisi doit être celui d\'un téléphone portable.',
            newpasswordbadFormat: 'Votre mot de passe doit être constitué d\'au moins 12 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial (#?!@$%^&*-\\;/_).',
            badLengthPassword: 'Entre 12 et 32 caractères',
            needUppercaseLetter: 'Une majuscule',
            needLowercaseLetter: 'Une minuscule',
            needDigit: 'Un chiffre',
            needSpecialSymbol: 'Un caractère spécial (#?!@$%^&*-\\;/_)',
        },
        input: {
            yes: 'Oui',
            no: 'Non'
        },
        mandatory: '* : Champs obligatoires',
        success: 'Enregistrement de votre demande effectuée avec succès'
    },
    servicesSante: {
        title: 'Téléconsultation - Services Santé',
        vyv: {
            title: 'Téléconsultation MesDocteurs',
            input: {
                beneficiary: 'Vous souhaitez accéder au service MesDocteurs au nom de :'
            }
        },
        telephone: 'Téléphone : {telephone}',
        email: 'Email : {email}',
        protocole: 'N° de protocole : {protocole}',
        contrat: 'N° de contrat : {contrat}',
    },
    sendContract: {
        title: 'Transmettre un devis',
        input: {
            beneficiary: {
                label: 'Bénéficiaire',
                helper: 'Sélectionnez le nom du bénéficiaire des soins'
            },
            demand: {
                label: 'Votre demande concerne'
            },
            mutuelleFile: {
                label: 'Merci de nous transmettre le relevé de la 1ère mutuelle ou le justificatif de sa non intervention :',
                title: 'Relevé de la 1ère mutuelle ou Justificatif de sa non intervention'
            },
            mutuelle: {
                label: 'Est-ce que Gerep est la 1ère mutuelle de ce bénéficiaire ? *'
            },
            files: {
                label: 'Si vous avez plusieurs devis pour la même nature de soin alors merci de les joindre dans cette même demande.',
                titles: {
                    contract: 'Devis',
                    contractAndOrdonnance: 'Devis et ordonnance de moins de 3 ans '
                }
            }
        }
    },
    portabilite: {
        title: 'Demander ou prolonger la portabilité de vos droits au(x) contrat(s) de complémentaire Frais de Santé et/ou prévoyance',
        info: 'Après la période de portabilité, conformément aux dispositions de la loi 891009 dite Evin du 31.12.89, vous pouvez souscrire auprès de Gerep un régime d’accueil Frais de Santé. Pour pouvoir en bénéficier, vous devez vous adresser à Gerep dans les 6 mois qui suivent la fin de la période de portabilité.',
        input: {
            already: 'Bénéficiez-vous déjà de la portabilité de vos droits ? *',
            lastDay: {
                description: 'Merci d\'indiquer ci-dessous la date du dernier jour de la période d\'indemnisation mentionnée sur le justificatif que vous allez nous transmettre.',
                label: 'Date du dernier jour d\'indemnisation *'
            },
            endDay: {
                description: 'Vous êtes sortis des effectifs de votre société et vous êtes inscrits et/ou indemnisés par France Travail, vous pouvez bénéficier de la portabilité de vos droits au(x) contrat(s) de complémentaire Frais de Santé et/ou prévoyance.',
                description2: 'Merci d\'indiquer ci-dessous la date de fin de votre contrat de travail et de joindre les justificatifs demandés.',
                label: 'Date de fin de votre contrat de travail *'
            },
            filePoleEmploi: {
                label: 'Merci de joindre votre inscription à France Travail',
                title: 'Inscription à France Travail'
            },
            file: {
                label: 'Merci de joindre votre Avis de situation ou Relevé de situation pour la période souhaitée *',
                warning: 'Les justificatifs de télé actualisation ne sont pas pris en compte par nos services',
                title: 'Avis de situation ou Relevé de situation'
            }
        }
    },
    maintenirEnfantMajeur: {
        title: 'Maintenir un enfant majeur',
        text: 'Votre enfant a 18 ans ou plus dans l\'année en cours, il peut continuer à bénéficier de votre contrat Frais de Santé, sous certaines conditions.{br}' +
            'Pour cela, merci de nous transmettre les informations nécessaires en fonction de sa situation.',
        input: {
            child: 'Enfant dont vous souhaitez prolonger la couverture',
            situation: 'Situation de l\'enfant',
            studying: {
                description: 'Si votre enfant est scolarisé, il peut être maintenu sur votre contrat sous certaines conditions. Pour que nous puissions vérifier, merci de nous transmettre :',
                label: 'Scolarisé',
                title: 'Son certificat de scolarité'
            },
            internship: {
                label: 'Sous contrat d’apprentissage ou d’alternance ou de professionnalisation',
                description: 'Si votre enfant est sous contrat d’apprentissage ou d’alternance ou de professionnalisation, il peut être maintenu sur votre contrat sous certaines conditions. Pour que nous puissions vérifier, merci de nous transmettre :',
                file: 'Son contrat d\'apprentissage, ou d\'alternance, ou de professionnalisation',
                affiliation: 'Est-ce sa 1ère affiliation à un régime de sécurité sociale salarié ?',
                ssDoc: {
                    label: 'Merci de joindre son attestation de sécurité sociale du régime général salarié',
                    title: 'Attestation de sécurité sociale du régime général salarié'
                }
            },
            working: {
                label: 'Exerce une activité professionnelle'
            },
            radiation: {
                description: {
                    working: 'Si votre enfant exerce une activité professionnelle, il ne peut plus bénéficier de votre contrat Frais de Santé.',
                    other: 'Si votre enfant n\'est ni scolarisé, ni demandeur d\'emploi, il ne peut plus bénéficier de votre contrat Frais de Santé.',
                    1: 'Vous devez donc demander la radiation de votre enfant majeur.',
                    2: 'Gerep peut lui proposer un contrat complémentaire santé à titre individuel.',
                    3: 'Pour cela, cochez la case prévue à cet effet lors de la demande de radiation de votre enfant sur votre contrat.'
                },
                button: 'Demander la radiation'
            },
            searching: {
                label: 'A la recherche de son 1er emploi',
                description: 'Si votre enfant est à la recherche de son 1er emploi, il peut être maintenu sur votre contrat sous certaines conditions. Pour que nous puissions vérifier, merci de nous transmettre :',
                file: 'Son justificatif d\'inscription à France Travail *'
            },
            other: {
                label: 'Autre situation'
            },
            ssData: {
                description: 'Données de sécurité sociale de votre enfant :',
                niss: 'N° de Sécurité sociale',
                nissHelper: 'Saisissez les 15 chiffres de votre N° de Sécurité sociale',
                caisse: 'Caisse sécurité sociale de votre enfant',
                organisme: {
                    label: 'Organisme de sécurité sociale',
                    helper: 'Saisissez uniquement les 8 premiers chiffres de votre organisme de rattachement.',
                    popover: 'L’organisme de rattachement (ou organisme d’affiliation) est indiqué sur votre attestation de sécurité sociale.'
                },
                modify: 'Merci de vérifier que les données de sécurité sociale de votre enfant sont à jour. Si ce n\'est pas le cas, vous pouvez les modifier directement au sein de cette demande.',
                file: {
                    title: 'Attestation de sécurité sociale',
                    label: 'Merci de joindre son attestation de sécurité sociale'
                }
            }
        }
    },
    simulation: {
        title: 'Simuler mes remboursements',
        guide: 'Pour plus d\'informations sur vos garanties, vous pouvez consulter votre {guide}',
        guidePopin: {
            title: 'Mon guide de gestion',
            text: 'Votre guide de gestion n\'est actuellement pas disponible.'
        },
        label: {
            poste: 'Pour simuler votre remboursement, sélectionnez le poste de soins :',
            posteMobile: 'Sélectionnez le poste de soins',
            typeSoin: 'Quel acte prévoyez-vous ?',
            sousTypeSoin: 'Sélectionnez un sous type d\'acte',
            amount: 'Montant facturé'
        },
        button: {
            calculate: 'Calculer'
        },
        result: {
            title: 'Simulation de remboursement',
            cost: 'Pour une dépense de',
            amount: '{amount} €',
            calcul: 'Hypothèse de calcul',
            label: {
                socialSecurity: 'Remboursement\nSécurité Sociale:\n{amount} €',
                rest: 'Reste à charge :\n{amount} €',
                gerep: 'Remboursement\nGerep :\n{amount} €'
            }
        }
    },
    demandRefund: {
        title: 'Demander un remboursement',
        input: {
            type: {
                bill: 'Une facture',
                other: 'Un relevé d\'une autre complémentaire',
                ss: 'Un relevé de sécurité sociale',
                label: 'Que souhaitez-vous nous transmettre ?'
            },
            files: {
                facture: 'Facture',
                releveComp: 'Relevé d\'une autre complémentaire',
                releveSS: 'Relevé Sécurité sociale'
            },
            beneficiary: {
                label: 'Bénéficiaire',
                helper: 'Sélectionnez le nom du bénéficiaire des soins'
            },
            mutuelleFile: {
                label: 'Merci de nous transmettre le relevé de la 1ère mutuelle ou le justificatif de sa non intervention :',
                title: 'Relevé de la 1ère mutuelle ou Justificatif de sa non intervention'
            },
            demand: {
                label: 'Votre demande concerne',
                helper: 'Sélectionnez la nature de soin'
            },
            healingDetails: {
                label: 'Détails des soins *',
                array: {
                    date: {
                        label: 'Date du soin *'
                    },
                    type: {
                        label: 'Type de soin'
                    },
                    amount: {
                        label: 'Montant du soin *',
                        helper: ' Montant payé au praticien (€)'
                    },
                    other: {
                        label: 'Autre mutuelle *',
                        helper: 'Montant remboursé par une autre mutuelle (€)'
                    }
                }
            }
        }
    },
    hospitalization: {
        title: 'Demander une prise en charge hospitalière',
        text: {
            1: 'En cas d\'hospitalisation dans un établissement conventionné pour au moins 24 heures, une prise en charge peut vous être délivrée.',
            2: 'Elle vous évite de faire l\'avance des frais que GEREP règlera directement à l\'établissement dans les limites fixées par {link}.',
            3: 'Les frais de dépassements d\'honoraires ou consécutifs à une maternité ou une cure thermale ainsi que les soins externes ne peuvent faire l\'objet d\'une prise en charge. Votre contrat peut prévoir le règlement de ces frais mais leur remboursement est effectué sur envoi des factures acquittées. Dans ce cas merci de nous adresser les pièces justificatives à partir du {link}.',
            4: 'Pour obtenir une estimation du remboursement des dépassements d’honoraires, {link}.',
            5: 'Les dépenses à caractère personnel (téléphone, télévision, boissons...) ne peuvent faire l\'objet d\'aucun remboursement dans le cadre de votre contrat frais de santé. Vous réglez ces frais au moment de votre sortie.'
        },
        links: {
            contrat: 'votre contrat frais de santé',
            refund: 'formulaire dédié',
            devis: 'adressez nous un devis'
        },
        input: {
            beneficiary: {
                label: 'Personne hospitalisée'
            },
            mutuelle: {
                label: 'Est-ce que Gerep est la 1ère mutuelle de ce bénéficiaire ? *',
                negative: 'Nous ne pouvons pas donner suite à votre demande.{br}' +
                    'Nous vous invitons à vous adresser à votre mutuelle principale.'
            },
            nature: {
                label: 'Nature de l\'hospitalisation *'
            },
            hospitalName: {
                label: 'Nom de l\'établissement hospitalier *'
            },
            numFiness: {
                label: 'N° Finess de l\'établissement hospitalier',
                helper: 'Le n° de FINESS est le numéro d’identification de l’établissement hospitalier, et se compose de 9 chiffres'
            },
            address: {
                label: 'Adresse *'
            },
            postalCode: {
                label: 'Code postal *'
            },
            city: {
                label: 'Ville *'
            },
            fax: {
                label: 'Numéro de fax'
            },
            phone: {
                label: 'Numéro de téléphone'
            },
            email: {
                label: 'Adresse email *'
            },
            entryDate: {
                label: 'Date d\'entrée (début de l\'hospitalisation) *',
                helper: 'Date limitée à 30 jours dans le futur'
            }
        }
    },
    acteNaissance: {
        title: 'Transmettre un acte de naissance ou d\'adoption',
        text: 'Pour vérifier vos droits à une prestation suite à la naissance ou l\'adoption de votre enfant, nous vous invitons à nous transmettre l\'acte de naissance ou d\'adoption ci-dessous.',
        confirmation: 'Acte de naissance ou d\'adoption',
        subtext: 'Pour prendre connaissance de vos garanties, rendez-vous dans la Rubrique « Maternité » de votre {link}'
    },
    dashboard: {
        welcome: {
            lastVisit: 'Dernière visite le {date}',
            message: 'Bienvenue dans votre Espace Assuré, nous sommes là pour vous accompagner.',
            readOnly: 'Consultation uniquement',
            expired: 'Votre adhésion au contrat complémentaire Frais de santé n’est plus en cours. ﻿﻿Vos accès à l\'Espace Assuré sont dorénavant limités.'
        },
        downloadApp: {
            content: 'Avez-vous téléchargé l’application sur votre smartphone ?'
        },
        notification: {
            content: 'Vous avez reçu des alertes de notre part. \n' +
                'Les avez-vous traitées ?',
            drawer: {
                title: 'Mes alertes'
            },
            buttons: {
                displayAlert: 'Afficher les alertes',
                details: 'Détail ici'
            }
        },
        sendDocuments: {
            title: 'Envoyer des documents',
            popup: {
                title: 'Envoyer un autre document',
                text: 'Quel document souhaitez-vous transmettre ?',
                labels: {
                    poleEmploi: 'Une attestation France Travail',
                    certificatScolarite: 'Un certificat de scolarité',
                    acteNaissance: 'Un acte de naissance ou d’adoption',
                    facture: 'Une facture',
                    releveSS: 'Un relevé de sécurité sociale',
                    releveAutre: 'Un relevé d\'une autre complémentaire',
                    devis: 'Un devis',
                    birthCertificate: 'Un acte de naissance ou d\'adoption',
                    autre: 'Un autre document'
                }
            },
            buttons: {
                refund: 'Demander un remboursement',
                quote: 'Transmettre un devis',
                other: 'Envoyer un autre document'
            }
        },
        refund: {
            title: 'Mes remboursements',
            emptyState: 'Vous n’avez pas eu de remboursement durant les 6 derniers mois',
            entry: {
                date: 'Remboursé le {date}',
                to: 'à {to}',
                price: '{price} €'
            }
        },
        services: {
            title: 'Mes services',
            buttons: {
                download: 'Télécharger mes garanties',
                simulate: 'Simuler mes remboursements',
                teleconsulting: 'Téléconsultation et Services santé',
                hospitalStay: 'Préparer mon séjour à l\'hôpital',
                portability: 'Bénéficier de la portabilité',
                schedule: 'Mon échéancier de cotisations',
                contract: 'Mes contrats',
                downloadAttestation: 'Télécharger une attestation de radiation',
                souscription: 'Souscrire une option',
                demandRefund: 'Demander un remboursement'
            }
        },
        networkPartner: {
            title: 'N\'avancez plus les frais',
            content: 'Et bénéficiez de tarifs négociés en optique, dentaire et audition.',
            buttons: {
                access: 'Accéder au réseau'
            }
        },
        demands: {
            title: 'Mes demandes',
            date: '{date}',
            beneficiary: ' - pour {beneficiary}',
            emptyState: 'Vous n\'avez pas transmis de demande au cours des 3 derniers mois',
            status: {
                pending: 'En cours',
                done: 'Traité'
            }
        },
        personalData: {
            title: 'Mes données perso.',
            insuranceNumber: 'N° assuré : <bold>{insuranceNumber}</bold>'
        },
        beneficiary: {
            title: 'Mes bénéficiaires',
            other: '{count, plural, =0 {Aucun autre bénéficiaire} one {# autre bénéficiaire} other {# autres bénéficiaires}}'
        }
    },
    downloadReleve: {
        title: 'Télécharger mes relevés',
        label: {
            startDate: 'Du',
            endDate: 'Au',
            select: 'Sélectionnez',
            refundTrimester: '{index}{index, plural, =1{er} other{ème}} trimestre {year}'
        },
        buttons: {
            pdf: 'Télécharger en PDF',
            excel: 'Télécharger en Excel'
        }
    },
    beneficiary: {
        title: 'Mes bénéficiaires',
        labels: {
            birth: 'Né(e) le',
            birthMale: 'Né le',
            birthFemale: 'Née le',
            phone: 'Téléphone',
            email: 'Email',
            nss: 'N°sécu. sociale',
            regime: 'Régime',
            caisse: 'Caisse'
        },
        buttons: {
            add: 'Ajouter un bénéficiaire',
            modify: 'Modifier les données d\'un bénéficiaire',
            modifySS: 'Modifier les informations de sécurité sociale',
            delete: 'Radier un bénéficiaire'
        },
        modifyPopin: {
            success: 'Votre demande a bien été prise en compte. Elle sera traitée sous 48 heures ouvrées.{br}',
            success2: 'Merci de ne pas renvoyer votre demande par mail ou par courrier.',
            fail: 'Echec de la modification des informations du bénéficiaire. Aucune donnée n\'a été modifiée.',
            title: 'Modifier les données d\'un bénéficiaire',
            text: 'Saisissez dans le champ concerné l\'information à compléter ou à mettre à jour. La modification sera visible sur votre Espace Assuré sous 48h après validation.',
            labels: {
                beneficiary: 'Bénéficiaire',
                name: 'Nom',
                firstName: 'Prénom',
                birthdate: 'Date de naissance',
                email: 'Email',
                phone: 'Téléphone',
                startingDate: 'A compter du'
            }
        },
        addBeneficiary: {
            title: 'Demander l’ajout d’un bénéficiaire',
            popoverContent: {
                attestation: 'L’attestation de droits à l’assurance maladie est téléchargeable sur votre compte Ameli',
                rankingBirth: 'Rang de naissance en cas de naissance gémellaire',
                nss: 'Saisissez les 15 chiffres de votre N° de Sécurité sociale',
                nss2: 'A renseigner si votre enfant est également rattaché sur le n° de Sécurité sociale de votre conjoint(e)',
                company: 'L’organisme de rattachement (ou organisme d’affiliation) est indiqué sur votre attestation de sécurité sociale.{br}' +
                    'Saisissez uniquement les 8 premiers chiffres de votre organisme de rattachement.'
            },
            affiliatedChildren: 'Le N° de Sécurité sociale à renseigner doit être celui du (ou des) parent(s) au(x)quel(s) l\'enfant est rattaché.',
            labels: {
                type: 'Type de bénéficiaire',
                sexe: 'Sexe',
                name: 'Nom',
                firstName: 'Prénom',
                birthdate: 'Date de naissance',
                rankingBirth: 'Rang de naissance',
                nss: 'N° de Sécurité sociale',
                nss2: 'Second N° de Sécurité sociale',
                company: 'Organisme de rattachement',
                company2: 'Second organisme de rattachement',
                startingDate: 'Date de début souhaitée',
                isGerepFirstInsurance: 'Est-ce que Gerep est la 1ère mutuelle de ce bénéficiaire ?',
                ssDocumentFile: 'Merci de nous joindre une copie de l\'attestation Sécurité Sociale mentionnant la personne concernée.',
                otherDocumentFile: {
                    title: 'Merci de nous joindre le(s) document(s) suivant(s) : *',
                    underageChild: 'Acte de naissance ou copie du livret de famille',
                    adultChild: 'Certificat de scolarité',
                    spouse: 'Acte de mariage ou copie du livret de famille',
                    concubine: 'Attestation de concubinage ou Justificatif de vie commune (quittance de loyer, compte bancaire joint...) ou Attestation sur l\'honneur de vie maritale',
                    pacs: 'Copie du PACS'
                }
            },
            input: {
                male: 'Masculin',
                female: 'Féminin',
                ssFile: 'Attestation Sécurité Sociale mentionnant la personne concernée'
            }
        }
    },
    forceResetPassword: {
        oldPassword: 'Mot de passe actuel',
        newPassword: 'Nouveau mot de passe',
        confirmPassword: 'Confirmation nouveau mot de passe'
    },
    login: {
        resetPassword: {
            tokenInvalid: {
                title: 'Réinitialisation mon mot de passe',
                body: 'Le lien sur lequel vous avez cliqué n\'est plus valide.'
            }
        },
        tabs: {
            civil: 'Espace Assuré',
            professional: 'Espace Entreprise'
        },
        form: {
            title: 'Connexion Espace Assuré',
            forgottenPassword: {
                title: 'Vous avez oublié votre mot de passe ?',
                text: 'Pour réinitialiser votre mot de passe, veuillez saisir votre adresse e-mail, votre numéro d\'assuré ainsi que votre date de naissance.'
            },
            login: {
                label: 'Votre identifiant',
                info: 'N° adhérent ou adresse e-mail',
                popover1: 'Pour vous identifier, indiquez soit votre N° adhérent, soit votre adresse email.\n' +
                    'Vous trouverez votre N° d\'adhérent en haut à gauche de votre carte de tiers-payant.',
                popover2: 'Seul l\'adhérent principal peut se connecter sur cet espace avec ses identifiants.'
            },
            signup: {
                title: 'Inscription sur l\'Espace Assuré',
                subtitle: 'Par mesure de sécurité, tous les champs ci-dessous sont obligatoires pour activer votre compte.',
                insuranceNumber: {
                    label: 'Numéro d\'assuré',
                    placeholder: 'XXXXXXXXX',
                    info: 'Vous trouverez votre numéro d’adhérent sur l’email de Bienvenue que vous avez reçu ou en haut à gauche de votre carte de tiers-payant'
                },
                lastName: {
                    label: 'Nom',
                    placeholder: 'Entrez votre nom'
                },
                firstName: {
                    label: 'Prénom',
                    placeholder: 'Entrez votre prénom'
                },
                socialSecurityNumber: {
                    label: 'Numéro de sécurité sociale',
                    placeholder: 'X-XX-XX-XXXXX-XXXXX-XX',
                    info: 'Votre numéro de sécurité sociale est constitué de 15 chiffres et se trouve sur votre carte vitale.'
                },
                birthDate: {
                    label: 'Date de naissance',
                    placeholder: 'JJ/MM/AAAA'
                },
                email: {
                    label: 'Adresse email',
                    placeholder: 'exemple@mail.fr',
                    info: 'Veuillez saisir l’adresse email de l’assuré principal. Cette adresse email sera utilisée pour toutes les notifications et correspondances ultérieures.'
                },
                password: {
                    label: 'Mot de passe',
                    info: 'Votre mot de passe doit être constitué d\'au moins 12 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial (#?!@$%^&*-\\;/_).'
                },
                confirmPassword: 'Confirmez votre mot de passe',
                confirmEmail: 'Confirmez votre adresse email',
                termsAndConditions: {
                    newsletter: 'J\'accepte de recevoir des informations commerciales de la part de Gerep. En aucun cas mon adresse email ne sera cédée à des tiers.',
                    generalUse: {
                        link: 'Conditions générales d\'utilisation',
                        body: 'Je certifie avoir pris connaissance des {link} et je les accepte.'
                    }
                }
            },
            password: 'Votre mot de passe',
            rememberMe: 'Rester connecté',
            forgotPassword: 'Mot de passe oublié ?',
            passwordRulesTitle: 'Votre mot de passe doit contenir :'
        },
        firstVisit: {
            title: 'Première visite ?',
            text: 'Vous êtes adhérent à un contrat santé Gerep, et vous souhaitez accéder à votre Espace Assuré pour la première fois. Rien de plus simple : il suffit d\'activer votre compte.'
        },
        title: 'Bienvenue sur votre Espace Assuré !',
        subtitle: 'En quelques clics, sur votre Espace Assuré Gerep : \n' +
            '\u3164 \u2022 Consultez vos remboursements et garanties\n' +
            '\u3164 \u2022 Gérez votre compte et vos bénéficiaires\n' +
            '\u3164 \u2022 Transmettez-nous vos documents\n' +
            '\u3164 \u2022 Téléchargez votre carte de tiers-payant et effectuez\n' +
            '\u3164\u3164 vos démarches en ligne',
        button: {
            login: 'Connexion',
            activateAccount: 'Activer mon compte',
            resetPassword: 'Réinitialiser mon mot de passe'
        },
        popinToApp: {
            title: 'Redirection',
            appDetected: 'L\'application mobile est installée sur votre mobile. Souhaitez-vous être redirigé ?',
            goToGerepMobile: 'Ouvrir l\'application',
            openPlayStore: 'Ouvrir le Play Store',
            openAppStore: 'Ouvrir l\'App Store',
            stayWeb: 'Rester sur le Web'
        }
    },
    form: {
        select: {
            placeholder: 'Choisissez'
        },
        date: {
            title: 'Date'
        },
        fileinput: {
            content: 'Merci de déposer votre justificatif ci-dessous :',
            maxSize: 'Taille maxi. : {size} Mo',
            acceptedFormat: 'Formats : {formats}',
            maxFiles: `{files} {files, plural, =1 {fichier} other {fichiers}} maximum`,
            button: {
                testTitle: 'Relevé d\'une autre complémentaire *',
                webSubtitle: 'Glissez/déposez ici les fichiers, vous pouvez aussi choisir un fichier'
            },
            error: {
                maxFiles: 'Nombre maximal de fichiers atteint',
                noSelected: 'Veuillez sélectionner un fichier',
                maxSize: 'La taille maximum acceptée par fichier est {size} Mo.',
                acceptedFormats: 'Les formats acceptés sont : {formats}.'
            }
        }
    },
    help: {
        title: 'Besoin d\'aide ?',
        placeholder: 'Rechercher un sujet ou poser une question. Exemple : « Garanties » ...',
        ariane_recherche: 'Résultats de la recherche',
        emptyState: 'Aucun résultat correspond à votre recherche',
        ariane: {
            home: 'Rubriques',
            searchResult: 'Résultats de la recherche'
        },
        birthCertificate: 'Pour connaître les modalités afin d\'affilier votre enfant sur votre contrat Frais de Santé, ou vérifier vos droits à une prestation suite à sa naissance ou son adoption, rendez-vous dans la Rubrique "Bénéficiaires - Ayants droit", sous-rubrique "Naissance ou adoption d\'un enfant".'
    },
    cookies: {
        content: 'En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de cookies permettant son bon fonctionnement et la réalisation de statistiques d\'utilisation.',
        buttons: {
            accept: 'J\'accepte',
            decline: 'Je refuse',
            info: 'En savoir plus et gérer mes cookies',
            manage: 'Gérer mes cookies'
        },
        popup: {
            title: 'Gestion de vos préférences sur les cookies',
            text: 'En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de traceurs (cookies) afin de sécuriser votre connexion au site et de réaliser des statistiques anonymes de visites ou vous proposer des contenus et services adaptés à vos centres d\'intérêts.\n' +
                '\n' +
                'Pour en savoir plus et paramétrer les traceurs, cliquez sur ce lien {link}.',
            whatIsCookie: {
                title: 'Qu\'est-ce qu\'un cookie ?',
                text: 'Les cookies sont des fichiers texte qui sont envoyés à votre outil informatique lorsque vous naviguez sur un site puis stockés sur un fichier de votre outil informatique par votre navigateur. Lors de chaque visite, les cookies sont alors renvoyés au site visité, ou à tout autre site qui reconnait ce cookie. Les cookies se comportent comme une « mémoire » pour le site internet, lui permettant de reconnaître votre outil informatique lorsque vous revenez sur ce site.'
            },
            cookieUsed: {
                title: 'Cookies utilisés par ce site',
                text: 'Le site que vous visitez utilise des traceurs (cookies). Ainsi, le site est susceptible d\'accéder à des informations déjà stockées dans votre équipement terminal de communications électroniques et d\'y inscrire des informations.\n' +
                    '\n' +
                    'Les données recueillies ne sont réservées qu’à un usage interne. Elles sont stockées en France et ne sont pas destinées à être revendues.\n' +
                    'Les traceurs utilisés par le site relèvent de deux catégories :\n' +
                    '\u3164 \u2022  traceurs dits strictement nécessaires, qui ne nécessitent pas votre consentement préalable,\n' +
                    '\u3164 \u2022 autres traceurs soumis à votre consentement préalable.'
            },
            noConsentTrackers: {
                title: '1 - Traceurs exemptés de consentement préalable',
                text: 'Nous utilisons ces traceurs pour permettre et faciliter la navigation sur le site notamment en mémorisant vos préférences de navigation définies au cours de votre session.\n' +
                    'Ces traceurs ne peuvent pas, techniquement, être désactivés depuis le site.'
            },
            consentTrackers: {
                title: '2 - Traceurs soumis à votre consentement préalable',
                text: 'Le site utilise des traceurs sous notre contrôle et d\'autres placés sous le contrôle de tiers qui sont soumis à votre consentement préalable.\n',
                underControl: {
                    title: 'Traceurs sous notre contrôle',
                    text: 'Nous utilisons ces traceurs pour recueillir des statistiques. Ces cookies statistiques nous permettent de connaître l\'utilisation et les performances du site dans un but d’amélioration de nos services.\n'
                },
                survey: {
                    title: 'J\'accepte l\'utilisation des cookies statistique\n',
                    text: 'Vos préférences de gestion des cookies peuvent être modifiées à tout moment dans la rubrique « Mon compte » puis « Gérer mes cookies ».\n'
                },
                thirdParty: {
                    title: 'Traceurs placés sous le contrôle de tiers',
                    text: 'Des cookies de sites tiers peuvent être installés par des services qui apparaissent sur ce site tiers.\n' +
                        '\n' +
                        'Ces cookies ne sont pas sous le contrôle du site visité. Pour plus d’information sur ces cookies Tierce partie, nous vous invitons à visiter le site internet tiers.\n' +
                        '\n' +
                        'L\'utilisation des traceurs est régie par l\'article 32 II de la loi n° 78-17 du 6 janvier 1978, transposant l\'article 5.3 de la directive 2002/58/CE du parlement européen et du conseil du 12 juillet 2002 modifiée par la directive 2009/136/CE.\n' +
                        '\n' +
                        'Pour en savoir plus sur les cookies et traceurs, nous vous invitons à consulter le site de la CNIL : {link}'
                }
            }
        }
    },
    appbar: {
        lastConnexion: 'Dernière connexion le {date}'
    },
    notation: {
        title: 'Notation'
    },
    codeError: {
        notFound: {
            title: 'Erreur 404',
            text: 'Oups, cette page n\'est malheureusement pas disponible. Peut-être qu\'une de ces pages vous sera utile :'
        },
        unauthorized: {
            title: 'Erreur 401',
            text: 'Oups, vous n\'êtes pas autorisé à accéder à cette page.'
        },
        buttons: {
            home: 'Retour à mon tableau de bord',
            help: 'Consulter l\'aide',
            refund: 'Consulter mes remboursements'
        }
    },
    internalError: {
        title: 'Erreur 500',
        text: {
            1: 'L\'accès à vos données est momentanément indisponible.',
            2: 'Nous vous prions de nous excuser pour la gêne occasionnée.',
            3: 'Merci de ré-essayer ultérieurement.'
        },
        altText: {
            1: 'Une erreur technique est survenue.',
            2: 'Nous allons régler ce souci. Merci de réessayer ultérieurement.',
            3: 'Si le problème persiste, vous pouvez nous contacter {link}.'
        },
        buttons: {
            home: 'Retour à mon tableau de bord'
        }
    },
    paymentCard: {
        title: 'Ma carte de Tiers Payant',
        insured: 'Assuré : {name}',
        date: 'Du {startingDate} au {endDate}',
        beneficiaries: 'Bénéficiaires',
        download: 'Télécharger ma carte',
        cardNotAvailable: {
            title: 'Ma carte de Tiers Payant',
            body: 'La carte de Tiers payant n\'est actuellement pas disponible.'
        },
        buttons: {
            beneficiaries: 'Voir mes bénéficiaires',
            personalData: 'Voir mes données personnelles'
        }
    },
    appMenu: {
        card: {
            title: 'Ma carte de\nTiers Payant',
            button: 'Télécharger ma carte'
        },
        dashboard: {
            title: 'Tableau de bord'
        },
        refund: {
            title: 'Mes remboursements',
            list: 'Liste des remboursements',
            demand: 'Demander un remboursement'
        },
        account: {
            title: 'Mon compte',
            personalData: 'Gérer mes données personnelles',
            contract: 'Mes contrats et garanties',
            beneficiary: 'Gérer mes bénéficiaires',
            card: 'Ma carte de Tiers Payant',
            demandTrack: 'Suivi de mes demandes',
            deadline: 'Mon échéancier de cotisations'
        },
        service: {
            title: 'Mes services',
            sendQuote: 'Transmettre un devis',
            majorChild: 'Maintenir un enfant majeur',
            hospitalization: 'Demander une prise en charge hospitalière',
            portable: 'Bénéficier de la portabilité',
            teleconsulting: 'Téléconsultation - Services santé',
            simulation: 'Simuler mes remboursements'
        },
        help: {
            title: 'Besoin d\'aide'
        },
        logout: {
            title: 'Déconnexion'
        }
    },
    carteTP: {
        range: 'Du {from, date, short} au {to, date, short}'
    },
    deleteBeneficiary: {
        success: 'Le bénéficiaire a bien été radié',
        title: 'Demander la radiation d\'un bénéficiaire',
        description: 'Si votre cotisation dépend du nombre de personnes inscrites sur votre contrat (conjoint, enfant), et qu\'elle est prélevée sur votre fiche de paie, nous vous remercions d\'informer votre service du personnel de votre demande.',
        labels: {
            beneficiary: 'Bénéficiaire concerné',
            reason: 'Motif de la radiation',
            date: 'Date de la radiation',
            comment: 'Votre demande / vos commentaires',
            child: 'Enfant majeur non scolarisé',
            divorce: 'Divorce',
            endPACS: 'Dissolution du PACS',
            separation: 'Séparation',
            death: 'Décès',
            otherMutual: 'Autre mutuelle',
            deathCertificate: {
                label: 'Merci de nous joindre un acte de décès',
                title: 'Acte de décès'
            },
            email: 'Email',
            sendMail: 'Je souhaite que Gerep m\'adresse des informations concernant un contrat complémentaire santé à titre individuel, pour {name}'
        },
        sendMail: {
            title: 'Demande d\'informations',
            description: {
                1: 'Vous souhaitez recevoir des informations concernant un contrat complémentaire santé individuel, pour {name}.',
                2: 'Si vous confirmez cette demande d’informations, Gerep vous adressera une proposition par email à l’adresse renseignée ci-dessous.'
            }
        }
    },
    contrat: {
        title: 'Mes contrats et garanties',
        subtitle: 'Rappel de vos modalités de réglement',
        download: 'Télécharger mes garanties',
        contratNotAvailable: {
            title: 'Mes contrats et garanties',
            body: 'Le descriptif de vos garanties n\'est actuellement pas disponible.'
        },
        beneficaire: {
            title: 'Bénéficiaires'
        },
        footer: {
            buttonInfoContrat: 'Plus d’informations au sujet de votre contrat',
            buttonInfoBeneficiaire: 'Voir les informations sur mes bénéficiaires',
            buttonDlAttestation: 'Télécharger une attestation d\'affiliation'
        }
    },
    suivie_Demande: {
        title: 'Mes demandes',
        subtitle: 'Effectuées sur Iris au cours des 3 derniers mois',
        emptyState: 'Vous n\'avez aucune nouvelle demande',
        tableau: {
            header: {
                statut: 'Statut',
                date: 'Date',
                type: 'Type',
                beneficiaire: 'Bénéficiaire',
                document: 'Document'
            },
            contenu: {
                periode: 'Du {from} au {to}',
                date: '{date}',
                cotisation: '{montant} {devise}'
            }
        }
    },
    echeancier: {
        title: 'Mon échéancier de cotisations',
        subtitle: 'Rappel de vos modalités de règlement',
        download: 'Télécharger l\'échéancier',
        downloadError: 'Impossible de télécharger l\'échéancier',
        rum: {
            mode: 'Mode de règlement',
            titulaire: 'Titulaire du compte',
            iban: 'IBAN',
            banque: 'Banque',
            sepa: 'Les prélèvements SEPA sont identifiés par la Référence Unique de Mandat (RUM) :'
        },
        tableau: {
            header: {
                nomContrat: 'N° contrat',
                periode: 'Période',
                date: 'Date de l’échéance',
                cotisation: 'Cotisation'
            },
            contenu: {
                periode: 'Du {from} au {to}',
                date: '{date}',
                cotisation: '{montant} {devise}',
                cotisationFr: '{montant}'
            }
        }
    },
    remboursements: {
        trimestre: '{index}{index, plural, =1{er} other{ème}} trimestre {year}'
    },
    doubleAuthent : {
        title: 'Authentification sécurisée',
        activation:{
            title : 'Activation de la double authentification',
            faq : {
                label: 'En savoir plus sur la double authentification ? ',
                link : 'Voir la FAQ'
            },
            button:{
                activate:'Activer',
                inactivate:'Ne pas activer',
            }
        },
        confirmation: {
            title: {
                activation: 'Confirmer l\'activation de la double authentification',
                modification: 'Confirmer la méthode de double authentification '
            },
            label: {
                sms: 'Un code de validation a été envoyé au {contact}',
                mail: 'Un code de validation a été envoyé à {contact}',
                code: 'Veuillez saisir le code reçu.'
            },
            error: {
                success: 'Double authentification validée'
            },
            renvoi: 'Me renvoyer un code',
            button: {
                retour: 'Modifier le numéro',
                valider: 'Valider'
            },
            success: 'Double authentification réussie'
        },
        finalPopin:{
            title: 'Double authentification activée',
            label:'Pour encore plus de sécurité, nous vous recommandons d’ajouter {methode} pour récupérer votre compte.',
            button: {
                ajout: {
                    mail:'Ajouter une adresse mail',
                    sms: 'Ajouter un n° de téléphone'
                },
                terminer: 'Terminer'
            }
        },
        recup: {
            title: 'Ajouter une méthode de récupération',
            popin: 'Vous avez la possibilité d\'ajouter un {contact} de récupération'
        }
    },
    personalInfo: {
        title: 'Mes données personnelles',
        personalData: {
            title: 'Mes informations personnelles',
            labels: {
                birth: 'Né le',
                ssNumber: 'N° sécurité sociale',
                address: 'Adresse',
                phone: 'Téléphone'
            },
            modifyPersonalInfo: {
                success: 'Vos informations personnelles ont bien été modifiées',
                title: 'Mettre à jour mes informations personnelles',
                subtitle: 'Saisissez dans le champ concerné l\'information à compléter ou à mettre à jour.\n' +
                    'La modification sera visible sur votre Espace Assuré sous 48h après validation.',
                info: 'Un mail de confirmation vous sera adressé dès mise à jour de votre dossier.',
                warningText: {
                    mail: 'Si vous validez, l’adresse email remplacera celle utilisée pour confirmer votre identité lors de la connexion à votre compte.',
                    sms: 'Si vous validez, ce numéro remplacera celui utilisé pour confirmer votre identité lors de la connexion à votre compte.'
                },
                labels: {
                    nass: 'N° Assuré : {num}',
                    nss: 'N° de Sécurité sociale : {num}',
                    nom: 'Nom',
                    prenom: 'Prénom',
                    birthdate: 'Date de naissance',
                    address: 'Adresse',
                    postalCode: 'Code postal',
                    city: 'Ville',
                    pays: 'Pays',
                    dateEntry: 'A compter du',
                    phone: 'N° téléphone portable'
                }
            },
            modifySS: {
                success: 'Votre demande a bien été traitée',
                title: 'Modifier ou ajouter des informations de sécurité sociale ou de caisse {br} Mettre fin à la télétransmission',
                content: 'Vous pouvez ici effectuer la modification d’un N° de sécurité sociale ou d’une caisse suite à un déménagement.{br}' +
                    'Si vous avez des enfants rattachés à votre N° de sécurité sociale, la modification sera réalisée automatiquement sur vos enfants.{br}' +
                    '{br}' +
                    'Vous pouvez également demander l\'ajout d\'un second N° de sécurité sociale pour un enfant, afin que la télétransmission {icon} fonctionne quelle que soit la carte Vitale présentée auprès des professionnels de santé.{br}' +
                    '{br}' +
                    'Si vous souhaitez que Gerep intervienne en complément de votre 1ère mutuelle, cochez mettre fin à la télétransmission.{br}' +
                    '{br}',
                radiation: 'Si votre demande d\'arrêt de télétransmission est liée à une demande de suppression de bénéficiaire, nous vous invitons à cliquer sur le lien suivant',
                popover: 'La télétransmission est l\'envoi informatisé des décomptes de remboursement de la sécurité sociale vers votre complémentaire santé Gerep',
                form: {
                    wish: {
                        title: 'Vous souhaitez',
                        modify: 'Modifier un N° de sécurité sociale ou une caisse',
                        end: 'Mettre fin à la télétransmission',
                        add: 'Ajouter un N° de sécurité sociale'
                    },
                    startDate: {
                        title: 'A compter du'
                    },
                    beneficiaries: {
                        title: 'Bénéficiaire(s) concerné(s)'
                    },
                    beneficiare: {
                        title: 'Bénéficiaire concerné',
                        choose: 'Choisissez'
                    },
                    certificate: {
                        label: 'Joindre une attestation de Sécurité sociale',
                        title: 'Attestation de Sécurité sociale'
                    },
                    socialSecurityNumber: {
                        title: 'N° de sécurité sociale *',
                        popup: 'Saisissez les 15 chiffres de votre N° de Sécurité sociale'
                    },
                    host: {
                        title: 'Organisme de rattachement',
                        popup: 'L’organisme de rattachement (ou organisme d’affiliation) est indiqué sur votre attestation de sécurité sociale.{br}' +
                            'Saisissez uniquement les 8 premiers chiffres de votre organisme de rattachement.'
                    },
                    secondSS: 'Second n° de Sécurité sociale'
                }
            },
            buttons: {
                update: 'Mettre à jour mes infos personnelles',
                modify: 'Modifier mes infos de sécurité sociale',
                removeBeneficiary: 'Demander la radiation d\'un bénéficiaire'
            }
        },
        bankInfo: {
            title: 'Mes coordonnées bancaires',
            refund: {
                title: 'Pour les remboursements - À {titulaire}',
                titleNoTitulaire: 'Pour les remboursements',
                subtitle: 'Par {type} - Pour les bénéficiaires {beneficiaries}'
            },
            contribution: {
                title: 'Pour les cotisations  - À {titulaire}',
                titleNoTitulaire: 'Pour les cotisations',
                subtitle: 'Par {type}'
            },
            labels: {
                titulaire: 'Titulaire',
                iban: 'IBAN',
                bank: 'Banque',
                beneficiaires: 'Bénéficiaires',
                ordre: 'Ordre'
            },
            input: {
                title: {
                    add: 'Ajouter un nouveau RIB de remboursement',
                    modifyRefund: 'Modifier mon RIB pour recevoir mes remboursements',
                    modifyCotisation: 'Modifier mon RIB pour le prélèvement de ma cotisation',
                    virement: 'Passer au virement bancaire'
                },
                iban: {
                    label: 'IBAN',
                    helper: 'Cette référence figure obligatoirement sur votre relevé d\'identité bancaire.'
                },
                bank: {
                    label: 'Banque'
                },
                owner: {
                    label: 'Titulaire du compte'
                },
                startingDate: {
                    label: 'A compter du',
                    helper: 'Le changement de RIB prendra effet au plus tôt 2 jours après la date de modification et jusqu\'à 30 jours au plus tard.',
                    control: 'La prise d\'effet de votre demande doit être comprise entre le {startDate} et le {endDate}. Pour toute demande de modification en dehors de cette période, il convient de renouveler votre demande ultérieurement.'
                },
                beneficiaries: {
                    label: 'Bénéficiaires rattachés à ce RIB'
                },
                ribFile: {
                    label: 'Merci de joindre votre RIB',
                    title: 'RIB'
                },
                cotisation: {
                    title: 'Mise à jour de mon RIB pour le prélèvement de ma cotisation',
                    content: 'Voulez-vous également mettre à jour le RIB de cotisation avec les informations précédemment renseignées ?'
                }
            },
            addRibText: 'Pour rattacher un ou plusieurs bénéficiaires sur un autre RIB vous devez enregistrer un nouveau RIB : ',
            buttons: {
                modify: 'Modifier mes coordonnées bancaires',
                add: 'Enregistrer un nouveau RIB',
                virement: 'Passer au virement',
                delete: 'Supprimer le RIB'
            }
        },
        connexionInfo: {
            title: 'Mes informations de connexion',
            modifyMail: {
                title: 'Modifier mon adresse email',
                subtitle: 'Attention: cette adresse email est utilisée pour votre connexion et pour vos alertes{br}Email actuel : {email}',
                success: 'Votre email a bien été modifié'
            },
            modifyPassword: {
                title: 'Modifier mon mot de passe',
                popup: 'Votre mot de passe doit être constitué d\'au moins 12 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial (#?!@$%^&*-\\;/_).',
                success: 'Votre mot de passe a bien été modifié'
            },
            modifyAuthent: {
                title: {
                    activation: 'Activation de la double authentification',
                    modification: 'Modification de la double authentification',
                },
                helperText: {
                    mail: 'Veuillez renseigner votre adresse mail personnelle',
                    sms: 'Veuillez renseigner votre n° de téléphone portable personnel'
                },
                warningText: {
                    mail: 'Si vous validez, l’adresse email remplacera l’adresse email actuellement enregistrée sur votre compte.',
                    sms: 'Si vous validez, le numéro renseigné remplacera le n° de téléphone actuellement enregistré sur votre compte.'
                },
                error: 'Le format n\'est pas correct',
                success: 'Le code vous a été envoyé'
            },
            resetPassword: {
                title: 'Réinitialiser mon mot de passe',
                success: 'Votre mot de passe a bien été réinitialisé'
            },
            labels: {
                email: 'Email',
                password: 'Mot de passe',
                numAssure: 'N°Assuré',
                newEmail: 'Nouvel email',
                confirmNewMail: 'Confirmer nouvel email',
                oldPassword: 'Ancien mot de passe',
                newPassword: 'Nouveau mot de passe',
                confirmNewPassword: 'Confirmez votre mot de passe',
                sms: 'N° de téléphone',
                emailAuthent: 'Adresse mail ',
                authentification: 'Double authentification'
            },
            buttons: {
                modifyEmail: 'Modifier mon adresse email',
                modifyPassword: 'Modifier mon mot de passe',
                manageCookies: 'Gérer mes cookies',
                modifyAuthent: 'Modifier ma méthode d\'authentification',
                activateAuthent: 'Activer la double authentification'
            }
        }
    },
    notification: {
        benef: 'Concernant {nombene}',
        endMessage: {
            1: 'Cordialement',
            2: ' L\'équipe Gerep'
        },
        pole_emploi: {
            title: 'Justificatif France Travail',
            label: 'En attente pour le maintien de votre adhésion',
            lien: 'Détail ici',
            form: {
                label: 'Nous vous remercions de nous transmettre le document suivant pour la mise à jour de votre dossier d\'adhésion :'
            }
        },
        enfant_majeur: {
            title: '{piece}',
            label: 'A nous transmettre pour {nombene}',
            lien: 'Détail ici'
        },
        generic: {
            title: '{piece}',
            label: 'A nous transmettre pour {nombene}',
            lien: 'Détail ici',
            form: {
                label: 'Nous vous remercions de nous transmettre le document suivant pour la mise à jour de votre dossier d\'adhésion :'
            }
        },
        pec: {
            title: 'Prise en charge hospitalière',
            label: 'Pour {prenom} {nom}',
            lien: 'Disponible ici'
        },
        devis: {
            title: 'Devis',
            label: 'Notre réponse à votre dossier du {date, date, short} pour {prenom} {nom} est disponible.',
            lien: 'Cliquez ici'
        },
        justificatifs_en_attente: {
            title: 'Justificatifs en attente',
            label: 'Pour compléter votre dossier',
            lien: 'Détail ici',
            form: {
                label: 'Nous vous remercions de nous transmettre le document suivant pour compléter votre dossier :'
            }
        },
        justificatifs_devis: {
            title: 'Devis',
            label: 'Des documents sont à nous transmettre pour traiter votre dossier',
            lien: 'Détail ici',
            form: {
                label: 'Nous vous remercions de nous transmettre le document suivant pour traiter votre dossier :'
            }
        },
        justificatifs_remboursement: {
            title: 'Remboursements',
            label: 'Des documents sont à nous transmettre pour traiter votre dossier',
            lien: 'Détail ici',
            form: {
                title: 'Demande de justificatif pour un remboursement',
                label: 'Nous vous remercions de nous transmettre le document suivant pour traiter votre dossier :'
            }
        },
        teletransmission: {
            title: 'Remboursements',
            label: 'Des justificatifs sont à nous transmettre pour traiter votre dossier',
            lien: 'Détail ici',
            form: {
                title: 'Télétransmission - Pièces en attente',
                label: 'Nous vous remercions de nous transmettre le document suivant pour traiter votre dossier reçu par télétransmission et dont détail joint :'
            }
        }
    },
    remboursementConsultation: {
        title: 'Mes remboursements',
        emptyState: {
            message: 'Vous n’avez pas eu de remboursement sur la période'
        },
        filters: {
            periode: {
                title: 'Afficher les remboursements :',
                debut: 'Du',
                fin: 'Au'
            },
            type: {
                title: 'Filtrer par :',
                dateSoin: 'Date de soins',
                beneficaire: 'Bénéficiaire',
                type: 'Type d\'acte'
            }
        },
        chart: {
            title: 'Mes frais de santé',
            total: 'Total',
            montantRembSS: 'Sécurité Sociale',
            montantRembAutre: 'Autre mutuelle',
            montantRembGerep: 'Gerep',
            montantResteACharge: 'Reste à charge'
        },
        list: {
            title: 'Détail des remboursements',
            cell: {
                header: {
                    title: 'Remboursé le {date}',
                    subtitle: 'à {who}'
                },
                drawn: {
                    title: 'Remboursement d’un frais de santé',
                    detail: '{libActe} du {date}',
                    for: 'Versé à {prenomNomNumdest}',
                    total: 'Montant total',
                    detailTitle: 'Détail du remboursement',
                    checkTo: 'Pour {prenom} {nom}',
                    checkToConcat: 'Pour {nom}',
                    montantRembSS: 'Sécurité Sociale',
                    montantRembAutre: 'Autre mutuelle',
                    montantRembGerepBase: 'Gerep ( contrat de base )',
                    montantRembGerepOption: 'Gerep ( contrat optionnel )',
                    montantResteACharge: 'Reste à charge'
                }
            }
        },
        download: 'Télécharger mes relevés'
    },
    contact: {
        radiation: {
            'envoyerMail.label': 'Je souhaite que Gerep m\'adresse des informations concernant un contrat complémentaire santé à titre individuel, pour <span style="text-transform: uppercase">{prenom} {nom}</span>.'
        }
    },
    souscription: {
        title: 'Souscrire une option',
        gestionLink: 'Guide de gestion',
        steps: {
            1: 'Choix de l\'option',
            2: 'Mode de règlement',
            3: 'Récapitulatif et validation'
        },
        error: {
            optionRequired: 'Vous devez au moins choisir une option pour continuer'
        },
        legends: {
            1: '* {text}',
            2: '** Durée minimale de votre adhésion à l’option, sauf événements indiqués dans le guide de gestion'
        },
        selectOption: {
            title: 'Sélectionnez une option',
            dates: {
                beginAndEnd: 'Date d\'effet : {dateDebut} - Date limite de souscription {dateFin}',
                dateDebut: 'Date d\'effet : {dateDebut}',
                dateFin: 'Date limite de souscription {dateFin}'
            },
            dureeEngagement: '{dureeEngagement, plural, =0{Sans engagement} =1{Engagement # mois **} other{Engagement # mois **}}',
            libellePrix: '{libellePrix} *',
            prix: '{prix} € / mois *',
            beneficiaire: 'Bénéficiaire(s)',
            button: {
                details: 'Voir le détail'
            }
        },
        step3: {
            title: 'Récapitulatif de votre demande d\'adhésion',
            info: {
                mode: {
                    title: 'Mode de règlement',
                    prelevement: 'Prélèvement bancaire',
                    salary: 'Prélèvement sur salaire'
                },
                datePrelev: 'Date de prélèvement',
                titulaire: 'Titulaire du compte',
                iban: 'IBAN',
                bank: 'Banque',
                beneficiaires: 'Bénéficiaire(s)',
                dateEffet: 'Date d\'effet',
                cout: 'Coût',
                acceptConditions: 'Je déclare avoir pris connaissance des {link}',
                conditions: 'conditions d\'entrée et de sortie à l\'option détaillée dans le Guide de gestion'
            }
        },
        noGaranties: {
            main: {
                title: 'Actuellement, vous ne pouvez pas souscrire de nouvelles options car vous vous trouvez dans l’une des situations suivantes :',
                1: 'vous avez déjà souscrit une option',
                2: 'vous avez dépassé le délai pour souscrire à une nouvelle option',
                3: 'vous avez résilié une option et le délai d’attente pour souscrire à nouveau n’est pas terminé',
                4: 'la souscription à l’option n’est pas encore ouverte'
            },
            subtext: 'Si vous avez changé de situation de famille et que vous souhaitez souscrire à une option, nous vous invitons à prendre contact avec nous, une fois votre situation mise à jour sur votre Espace Assuré.'
        },
        dateLimite: 'Date limite de souscription: {dateLimite, date, short}',
        datePrelevement: {
            1: 'le {echeance} de chaque mois',
            3: 'le {echeance} de chaque trimestre',
            6: 'le {echeance} de chaque semestre',
            12: 'le {echeance} de chaque année'
        },
        engagement: '{dureeEngagement, plural, =0{Sans engagement} =1{Engagement # mois} other{Engagement # mois}}',
        errors: {
            tooManyBeneficiairies: 'Vous devez sélectionner {nbBeneMax, plural, =1{un bénéficiaire} other{# bénéficiaires}} pour cette option'
        },
        mode: {
            confirm: 'Confirmer les coordonnées bancaires associées à vos cotisations',
            editRib: 'Si vous souhaitez que votre cotisation soit prélevée sur un autre compte bancaire, cliquer sur Modifier'
        },
        confirmation: {
            body: 'Votre demande de souscription a bien été prise en compte.{br}Un email de confirmation vous sera adressé dès enregistrement de votre demande.'
        },
        form: {
            error: {
                options: 'Veuillez sélectionner une option pour passer à l\'étape suivante',
                tooManyOptions: 'Vous ne pouvez sélectionner qu\'une seule option',
                beneficiaires: 'Veuillez sélectionner au moins un bénéficiaire',
                tooManyBeneficiairies: 'Vous devez sélectionner {nbBeneMax, plural, =1{un bénéficiaire} other{# bénéficiaires}} pour cette option',
                iban: 'IBAN invalide',
                acceptPrelev: 'Veuillez accepter les modalités de règlement pour passer à l’étape suivante',
                acceptSepa: 'Veuillez valider le mandat SEPA pour passer à l’étape suivante',
                acceptConditions: 'Veuillez confirmer avoir pris connaissance des conditions de souscription à l’option'
            }
        },
        input: {
            iban: 'IBAN *',
            bank: 'Banque *',
            titulaire: 'Titulaire du compte *',
            rib: {
                label: 'Merci de joindre votre RIB',
                title: 'RIB'
            },
            libelleMandatSepa: {
                sepa: 'le mandat de prélèvement SEPA',
                prelevementSalaire: 'J\'accepte que les cotisations associées à cette nouvelle option soient prélevées selon les modalités de règlement ci-dessus.',
                modifyCoord: 'Je valide {link} autorisant Gerep à présenter des ordres de prélèvement sur le compte bancaire ci-dessus, pour les cotisations à cette nouvelle option.',
                default: 'Je valide le mandat de prélèvement SEPA autorisant Gerep à présenter des ordres de prélèvement sur le compte bancaire ci-dessus, pour les cotisations à cette nouvelle option.'
            }
        },
        buttons: {
            previousStep: 'Etape précédente',
            nextStep: 'Etape suivante',
            validate: 'Valider ma demande',
            goHome: 'Retour à l\'accueil'
        }
    },
    enums: {
        typeAdherent: {
            0: 'Assuré principal',
            1: 'Conjoint',
            2: 'Enfant à charge',
            3: 'Concubin',
            7: 'Pacsé',
            10: 'Enfant bénéficiaire',
            12: 'Collatéraux',
            13: 'Neveu, nièce',
            15: 'Enfant invalide',
            16: 'Enfant primo demandeur d\'emploi',
            19: 'Enfant en gratuité',
            6: 'Ouvreur de droit',
            4: 'Ayant-droit à charge',
            11: 'Ascendants',
            18: 'Ex ayant-droit'
        },
        typeEtablissement: {
            T: 'Transport',
            L: 'Laboratoire',
            K: 'Kinésithérapie',
            R: 'Radiologue',
            C: 'Centre de santé'
        },
        motifRadiationBeneficiaire: {
            1: 'Enfant majeur non scolarisé',
            2: 'Divorce',
            3: 'Dissolution du PACS',
            4: 'Séparation',
            5: 'Décès',
            6: 'Autre mutuelle'
        },
        typeCoordonneesBancaires: {
            1: 'Remboursements',
            2: 'Cotisations'
        }
    }
})
