import React, { useEffect, useState } from 'react'
import PageLayout from '../../../../components/PageLayout'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import * as affiliationAction from '../../../webservices/affiliation/affiliationActions'
import { getIndividuListForManage, isAffiliationLoading } from '../../../webservices/affiliation/affiliationSelector'
import { Stack } from '@mui/material'
import BeneficiaryCell from '../components/BeneficiaryCell'
import Loader from '../../../../components/Loader'
import BeneficiaryActions from '../components/BeneficiaryActions'

const BeneficiaryPage = (
	{
		// @ts-ignore
		individuList,
		// @ts-ignore
		getAffiliation,
		// @ts-ignore
		isAffiliationLoading
	}
) => {
	const intl = useIntl()
	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(() => {
		getAffiliation()
		setIsLoading(false)
	}, [])

	if (isAffiliationLoading || isLoading) {
		return <Loader />
	}

	return (
		<PageLayout title={intl.formatMessage({ id: 'beneficiary.title' })}>
			<Stack direction="column" spacing="24px">
				{individuList.map((individu: any) => (
					<BeneficiaryCell beneficiary={individu[1]} />
				))}
				<BeneficiaryActions individuList={individuList} />
			</Stack>
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	// @ts-ignore
	individuList: getIndividuListForManage(state),
	isAffiliationLoading: isAffiliationLoading(state)
})

const actions = {
	getAffiliation: affiliationAction.getAffiliation
}

export default connect(
	mapStateToProps,
	actions
)(BeneficiaryPage)
