import React, { useMemo, useState } from 'react'
import { RefreshContextEE } from './RefreshContextEE'

type RefreshProviderEE = {
	children: React.ReactNode
}

const RefreshProviderEE: React.FC<RefreshProviderEE> = (
	{
		children
	}) => {
	const [refreshing, setRefreshing] = useState<boolean>(false)

	const refresh = () => {
		setRefreshing(prevState => !prevState)
	}

	const contextValue = useMemo(() => ({
		refresh,
		refreshing
	}), [refreshing, refresh])

	return (
		<RefreshContextEE.Provider value={contextValue}>
			{children}
		</RefreshContextEE.Provider>
	)
}

export default RefreshProviderEE
