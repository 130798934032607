export const maskEmail = (email) => {
	if (!email) {
		return null
	}
	const [local, domain] = email.split('@')
	const maskedLocal = local.slice(0, 1) + '*'.repeat(local.length - 2) + local.slice(-1)
	return `${maskedLocal}@${domain}`
}

export const maskTelephone = (obj) => {
	if (!obj) {
		return null
	}
	const cleanedObj = obj.replace(/[\s.]/g, '').trim()
	return '** ** ** ** ' + cleanedObj.slice(-2)
}

export const maskObj = (obj) => {
	if (!obj) {
		return null
	}
	return obj.replace(/\S/g, 'X')
}

export const maskNumeroAssure = (obj) => {
	if (!obj) {
		return null
	}
	return '******'
}

export const isAfterNow = (dateString) => {
	const date = new Date(dateString)
	const now = new Date()
	date.setHours(0, 0, 0, 0)
	now.setHours(0, 0, 0, 0)
	return date > now
}
