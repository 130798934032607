import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import SelectInput from '../../../../../components/form/SelectInput'
import {MODIFY_SOCIAL_SECURITY_FIELDS, SOCIAL_SECURITY_CHOICES} from '../../services/personalInfoConstants'
import FileInput from '../../../../../components/fileInput/FileInput'
import Box from '@mui/material/Box'
import {Stack, Typography} from '@mui/material'
import PatternTextInput from '../../../../../components/PatternTextInput'
import {PATTERNS} from '../../../../../components/formConstants'
import NissTextInput from '../../../../../components/NissTextInput'
import {toUpperCase} from '../../../../../utils/stringUtils'

type ModifySocialSecurityNumberProps = {
    allBeneficiaries: any[]
    selectedBenef: boolean
    type?: string
    change: any
    values: any
}

const buildOrganisme = (
    regime?: number,
    caisse?: number,
    centre?: number
) => {
    return [regime, caisse, centre].filter(value => value).join(' ')
}

const ModifySocialSecurityNumber: React.FC<ModifySocialSecurityNumberProps> = (
    {
        allBeneficiaries,
        selectedBenef,
        type,
        change,
        values
    }) => {
    const intl = useIntl()

    const BENEFICIARIES_DATA: SelectOption[] = useMemo(() => allBeneficiaries ? allBeneficiaries.map((beneficiary: any) => {
        return {
            value: beneficiary[0],
            label: `${beneficiary[1].prenom} ${beneficiary[1].nom}`
        }
    }) : [], [allBeneficiaries])

    const selectedBeneficiary = useMemo(() => allBeneficiaries.find(benef => benef[0] === selectedBenef), [selectedBenef, allBeneficiaries])

    return (
        <>
            <Box display="flex" flex={1}>
                <SelectInput
                    name={MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARY}
                    data={BENEFICIARIES_DATA}
                    label={
                        <Stack direction="row">
                            <Typography flex={4} variant="body2">
                                {intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.beneficiare.title'})}
                                <span>*</span>
                            </Typography>
                        </Stack>
                    }
                    onChange={() => {
                        if (values[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARY]) {
                            change(MODIFY_SOCIAL_SECURITY_FIELDS.SOCIAL_SECURITY_NUMBER, selectedBeneficiary && selectedBeneficiary[1]?.matorg)
                            change(MODIFY_SOCIAL_SECURITY_FIELDS.HOST, selectedBeneficiary && buildOrganisme(selectedBeneficiary[1]?.regime, selectedBeneficiary[1]?.caisse, selectedBeneficiary[1]?.centre))
                            change(MODIFY_SOCIAL_SECURITY_FIELDS.SECOND_SS_NUMBER, selectedBeneficiary && selectedBeneficiary[1]?.matorg2)
                        }
                    }}
                />
            </Box>
            {values[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARY] &&
                <>
                    <Box display="flex" flex="1">
                        {/*<PatternTextInput*/}
                        {/*    format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
                        {/*    mask="_"*/}
                        {/*    label={intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.socialSecurityNumber.title'})}*/}
                        {/*    maxWidth="unset"*/}
                        {/*    name={MODIFY_SOCIAL_SECURITY_FIELDS.SOCIAL_SECURITY_NUMBER}*/}
                        {/*    popoverContent={*/}
                        {/*        <Typography variant="subtitle2">*/}
                        {/*            {intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.socialSecurityNumber.popup'})}*/}
                        {/*        </Typography>*/}
                        {/*    }*/}
                        {/*    defaultValue={selectedBeneficiary && selectedBeneficiary[1]?.matorg}*/}
                        {/*    disabled={type === SOCIAL_SECURITY_CHOICES.ADD}*/}
                        {/*/>*/}
                        <NissTextInput
                            name={MODIFY_SOCIAL_SECURITY_FIELDS.SOCIAL_SECURITY_NUMBER}
                            label={intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.socialSecurityNumber.title'})}
                            defaultValue={selectedBeneficiary && selectedBeneficiary[1]?.matorg}
                            disabled={type === SOCIAL_SECURITY_CHOICES.ADD}
                            popoverContent={
                                <Typography variant="subtitle2">
                                    {intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.socialSecurityNumber.popup'})}
                                </Typography>
                            }
                            format={toUpperCase}
                            maxWidth="unset"
                        />
                    </Box>
                    <Box display="flex" flex="1">
                        <PatternTextInput
                            format={PATTERNS.ORGANISME}
                            mask="_"
                            label={intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.host.title'})}
                            maxWidth="unset"
                            name={MODIFY_SOCIAL_SECURITY_FIELDS.HOST}
                            popoverContent={
                                <Typography variant="subtitle2">
                                    {intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.host.popup'}, {
                                        br: <br/>
                                    })}
                                </Typography>
                            }
                            defaultValue={selectedBeneficiary && buildOrganisme(selectedBeneficiary[1]?.regime, selectedBeneficiary[1]?.caisse, selectedBeneficiary[1]?.centre)}
                            disabled={type === SOCIAL_SECURITY_CHOICES.ADD}
                        />
                    </Box>
                </>
            }

            {type === SOCIAL_SECURITY_CHOICES.ADD && (
                <Box display="flex" flex="1">
                    {/*<PatternTextInput*/}
                    {/*    format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
                    {/*    mask="_"*/}
                    {/*    label={intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.secondSS'})}*/}
                    {/*    maxWidth="unset"*/}
                    {/*    name={MODIFY_SOCIAL_SECURITY_FIELDS.SECOND_SS_NUMBER}*/}
                    {/*    popoverContent={*/}
                    {/*        <Typography variant="subtitle2">*/}
                    {/*            {intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.socialSecurityNumber.popup'}, {*/}
                    {/*                br: <br/>*/}
                    {/*            })}*/}
                    {/*        </Typography>*/}
                    {/*    }*/}
                    {/*    defaultValue={selectedBeneficiary && selectedBeneficiary[1]?.matorg2}*/}
                    {/*/>*/}
                    <NissTextInput
                        name={MODIFY_SOCIAL_SECURITY_FIELDS.SECOND_SS_NUMBER}
                        label={intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.secondSS'})}
                        defaultValue={selectedBeneficiary && selectedBeneficiary[1]?.matorg2}
                        popoverContent={
                            <Typography variant="subtitle2">
                                {intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.socialSecurityNumber.popup'}, {
                                    br: <br/>
                                })}
                            </Typography>
                        }
                        format={toUpperCase}
                        maxWidth="unset"
                    />
                </Box>
            )}
            <Box flex="1">
                <FileInput
                    name={MODIFY_SOCIAL_SECURITY_FIELDS.CERTIFICATE}
                    title={intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.certificate.title'})}
                    label={intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.certificate.label'})}
                    required
                />
            </Box>
        </>
    )
}

export default ModifySocialSecurityNumber
