export const SANTECLAIR = 16
export const ITELIS = 22

export const HOSPIWAY = 2297

export const TELECONSULTATION_VYV = 1493

/*
 automedication
 owop
 jalonnement
 owih
 nosmoking
 nutriclair
 devisdentaire
 */
export const SERVICES_SANTECLAIR = {
	OWOP: 'owop',
	PU: 'pu'
}
