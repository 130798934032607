import { createSelector } from 'reselect'
import { getAffiliationAssurePrincipal, getIndividuList } from '../affiliation/affiliationSelector'
import { isRibLoading as isRibCotisationLoading } from './cotisation/ribCotisationSelector'

export const getRibPrestationByIndividu = state => {
	return state.rib.ribByIndividu;
}
const isRibLoadingByIndividu = state => state.rib.loadingByIndividu
export const isRibLoading = idIndividu => state => state.rib.loadingByIndividu && state.rib.loadingByIndividu.get(idIndividu) === true
export const isRibInCache = idIndividu => state => !!state.rib.expirationDateByIndividu.get(idIndividu)

export const getRibList = createSelector(
	getRibPrestationByIndividu,
	getIndividuList,
	(ribByIndividu, individuList) => {
		if (!individuList || !ribByIndividu) {
			return null
		}
		return ribByIndividu.map(rib => {
				const titulaire = individuList.find(i => i[1].numIndividu === rib.numindiv)
				// on accroche les bénéficiaires au RIB
				return {
					...rib,
					titulaire,
					beneficiaires: individuList.filter(i => i[1].porteurRib === rib.numindiv && i[1].numIndividu !== rib.numindiv)
				}
			})
			// on ne conserve que les ribs dont on a trouvé le titulaire
			.filter(rib => rib.titulaire)
			// le rib de l'assuré principal en premier
			.sort((rib1, rib2) => rib1.typAdr - rib2.typAdr)
			.valueSeq()
			.toArray()
	}
)

export const getLoading = createSelector([
	isRibCotisationLoading,
	isRibLoadingByIndividu
], (ribCotisationLoading, ribLoadingByIndividu) => {
	return ribCotisationLoading
		|| ribLoadingByIndividu.reduce((res, loading) => loading || res, false)
})

export const getRibPrestationAssurePrincipal = createSelector([
	getRibPrestationByIndividu,
	getAffiliationAssurePrincipal
], (ribByIndividu, assurePrincipal) => {
	if (assurePrincipal) {
		return ribByIndividu.get(assurePrincipal.numIndividu)
	}
	return null
})

export const getTitulairesCheque = createSelector([
	getRibList
], (ribs) => {
	return ribs
		.filter(rib => !rib.bban)
		.map((rib) => rib.titulaire)
})

export const getTitulairesRib = createSelector([
	getRibList
], (ribs) => {
	return ribs
		.filter(rib => rib.bban)
		.map((rib) => rib.titulaire)
})
