import * as React from 'react'
import Box from '@mui/material/Box'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

type TabPanelProps = {
	children?: React.ReactNode
	index: number
	value: number
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props
	const { isMobile, height, width } = useBreakpoints()

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ height: isMobile ? height : '100%', width: isMobile ? width : '100%' }}
		>
			{value === index && (
				<Box sx={{ p: isMobile ? '20px' : 0, display: 'flex', justifyContent: 'center' }}>
					{children}
				</Box>
			)}
		</div>
	)
}