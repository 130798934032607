import {types} from '../../../univers/ui/authentication/services/login/loginActions'
import {types as userTypes} from './userActions'

export const user = (state = null, action) => {
    switch (action.type) {
        case types.LOG_USER: {
            if (action.payload) {
                return action.payload.user
            }
            return state
        }
        case userTypes.SET_USER_MAIL: {
            return {
                ...state,
                email: action.payload
            }
        }
        case userTypes.SET_USER_AUTHENT: {
            return {
                ...state,
                doubleAuthent: action.payload
            }
        }
        case userTypes.SET_USER_CARTE_TP_VIEW: {
            return {
                ...state,
                viewCarteTP: action.payload
            }
        }
        default:
            return state
    }
}
