export enum MAINTENIR_ENFANT_MAJEUR_FIELDS {
	BENEFICIARY = 'beneficiaire',
	SITUATION = 'situation',
	CERTIFICAT = 'certificat',
	STUDYING_NISS = 'st_numSS',
	STUDYING_CAISSE = 'st_libCaisse',
	STUDYING_ATTESTATION_SS = 'st_attestationSS',
	INTERNSHIP_NISS_1 = 'in_numSS1',
	INTERNSHIP_ORGANISME_1 = 'in_organisme1',
	INTERNSHIP_ATTESTATION_SS_1 = 'in_attestationSS1',
	INTERNSHIP_NISS_2 = 'in_numSS2',
	INTERNSHIP_ORGANISME_2 = 'in_organisme2',
	INTERNSHIP_ATTESTATION_SS_2 = 'in_attestationSS2',
	FIRST_REGIME = 'firstRegimeSalarie',
	SEARCHING_FILES = 'searching_files',
}

export enum SITUATION_VALUES {
	STUDYING = 'SCOLARISE',
	INTERNSHIP = 'APPRENTISSAGE',
	WORKING = 'PRO',
	SEARCHING = 'RECHERCHE_EMPLOI',
	OTHER = 'AUTRE',
}