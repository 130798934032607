import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Box, Divider, Link, Stack, Typography} from '@mui/material'
import InfoField from '../../../../../components/InfoField'
import FormatUtils from '../../../../../utils/FormatUtils'
import {
    BANK_FIELDS,
    BENEFICIARY_PREAFF_FIELDS,
    CIVILITES,
    CONTRACT_FIELDS,
    DISPENSE_PREAFF_FIELDS,
    IDENTIFICATION_FIELDS,
    INFOS_PERSOS_FIELDS,
    rgpdLink
} from '../../services/preaffiliationConstants'
import {BENEFICIARY_TYPES} from '../../../../../components/beneficiaryConstants'
import DownloadGarantiesButton from '../DownloadGarantiesButton'
import {downloadGaranties} from '../../../webservices/WsActions'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {useBreakpoints} from '../../../../../components/breakpoints/BreakpointsProvider'
import {getMapCouvertures} from '../../../webservices/WsSelector'
import {getTypeCouvertureFromTypeBeneficiaire} from '../../constants/global'
import {couvertureChosen, motifDispenseChosen} from '../../preaffiliationUtils'
import CheckboxInput from '../../../../../components/form/CheckboxInput'


type ConfirmationAffiliationFormProps = {
    formValues: any
    formErrors: any
    contract: any
    listCouvertures: any
    coveragesBenefsMap: any
    wantDispense?: boolean
}
const ConfirmationAffiliationForm: React.FC<ConfirmationAffiliationFormProps> = (
    {
        // @ts-ignore
        downloadGaranties,
        formValues,
        formErrors,
        contract,
        listCouvertures,
        coveragesBenefsMap,
        wantDispense
    }
) => {

    const intl = useIntl()
    const { isMobile, isTabletPortrait } = useBreakpoints()
    const isMobOrTabVert = isMobile || isTabletPortrait

    function getLabelEnum(enumeration : any[], valueEnum : string, label : string, value : string) {
        let result = enumeration.find((e) => e[valueEnum] === value)
        if(result) {
            return result[label]
        }
       return ""
    }

    const telechargerGaranties = () => {
        const idCompany = contract.idSociete
        const beginning = formValues[CONTRACT_FIELDS.AFFILIATION_START].toString()
        const year = beginning && beginning.split('/')[2]
        const numSiret = contract.siret

        return downloadGaranties(idCompany, numSiret, year)
    }

    const couverturesBenefSelected = (values: any, index: number) => {
        if (coveragesBenefsMap) {
            return coveragesBenefsMap.get(
                getTypeCouvertureFromTypeBeneficiaire(values[BENEFICIARY_PREAFF_FIELDS.NAME][index][BENEFICIARY_PREAFF_FIELDS.TYPES]))
        }
    }

    const SITUATION_DATA = [{
        label: intl.formatMessage({id: 'preaffiliation.dispense.confirmation.informYourBoss'}),
        value: DISPENSE_PREAFF_FIELDS.SITUATION_CHANGEMENT,
    }]
    const CONSEQUENCIES_DATA = [{
        label: intl.formatMessage({id: 'preaffiliation.dispense.confirmation.choiceConsequencies'}),
        value: DISPENSE_PREAFF_FIELDS.CHOICE_CONSEQUENCIES
    }]

    return (
        <>
            <Stack direction="column" spacing="24px">
                {!wantDispense &&
                    <>
                        <Stack direction="column" spacing="20px">
                            <Typography variant="h2" fontSize="1.125rem" fontWeight="bold">
                                <FormattedMessage id="preaffiliation.confirmation.contract.title"/>
                            </Typography>

                            <Stack
                                width="100%"
                                direction={isMobOrTabVert ? 'column' : 'row'}
                                spacing={isMobOrTabVert ? '20px' : '16px'}
                                justifyContent="space-between"
                            >
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.confirmation.contract.company'})}
                                    value={formValues[IDENTIFICATION_FIELDS.COMPANY_NAME]}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.confirmation.contract.category'})}
                                    value={formValues[IDENTIFICATION_FIELDS.BENEFICIARY_TYPE]}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                            </Stack>

                            <Stack
                                width="100%"
                                direction={isMobOrTabVert ? 'column' : 'row'}
                                spacing={isMobOrTabVert ? '20px' : '16px'}
                                justifyContent="space-between"
                            >

                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.confirmation.contract.effectDate'})}
                                    value={FormatUtils.formatDate(formValues[CONTRACT_FIELDS.AFFILIATION_START])}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.confirmation.contract.coverage'})}
                                    value={couvertureChosen(listCouvertures, formValues[CONTRACT_FIELDS.COVERAGE]).libelle}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                            </Stack>

                            <Stack direction="row" justifyContent="center" paddingTop="24px">
                                <DownloadGarantiesButton download={telechargerGaranties}/>
                            </Stack>
                        </Stack>

                        <Divider/>
                    </>
                }

                <Stack direction="column" spacing="20px">
                    <Typography variant="h2" fontSize="1.125rem" fontWeight="bold">
                        <FormattedMessage id="preaffiliation.confirmation.personal.title"/>
                    </Typography>

                    <Stack direction="column" spacing="14px" alignItems="center">
                        <Stack
                            width="100%"
                            direction={isMobOrTabVert ? 'column' : 'row'}
                            spacing={isMobOrTabVert ? '20px' : '16px'}
                            justifyContent="space-between"
                        >
                            <InfoField
                                label={getLabelEnum(CIVILITES, "value", "label", formValues[IDENTIFICATION_FIELDS.SEX])}
                                value={formValues[IDENTIFICATION_FIELDS.FIRST_NAME] + " " + formValues[IDENTIFICATION_FIELDS.LAST_NAME]}
                                variantValue="h3"
                                flexLabel={2}
                            />
                            <InfoField
                                label={intl.formatMessage({id: 'preaffiliation.identification.form.NSS.label'})}
                                value={FormatUtils.formatNiss(formValues[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER])}
                                variantValue="h3"
                                flexLabel={2}
                            />
                        </Stack>

                        <Stack
                            width="100%"
                            direction={isMobOrTabVert ? 'column' : 'row'}
                            spacing={isMobOrTabVert ? '20px' : '16px'}
                            justifyContent="space-between"
                        >
                            <Box flex="1">
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.personalInfos.form.adress.label'})}
                                    variantValue="h3"
                                    flexLabel={2}
                                    value={
                                        <Stack direction="column">
                                            <Typography variant="h3">{formValues[INFOS_PERSOS_FIELDS.ADRESS1]}</Typography>

                                            {formValues[INFOS_PERSOS_FIELDS.ADRESS2] &&
                                                <Typography variant="h3">{formValues[INFOS_PERSOS_FIELDS.ADRESS2]}</Typography>
                                            }
                                            {formValues[INFOS_PERSOS_FIELDS.ADRESS3] &&
                                                <Typography variant="h3">{formValues[INFOS_PERSOS_FIELDS.ADRESS3]}</Typography>
                                            }

                                            <Typography variant="h3">
                                                {formValues[INFOS_PERSOS_FIELDS.ZIP_CODE]} {formValues[INFOS_PERSOS_FIELDS.CITY]}
                                            </Typography>
                                            <Typography variant="h3">{formValues[INFOS_PERSOS_FIELDS.COUNTRY]}</Typography>
                                        </Stack>
                                    }
                                />
                            </Box>

                            <Stack direction="column" spacing="12px" flex="1">
                                {(!contract.dispense || (contract.dispense && !wantDispense)) ?
                                    <InfoField
                                        label={intl.formatMessage({id: 'preaffiliation.confirmation.teletransmission'})}
                                        value={formValues[INFOS_PERSOS_FIELDS.TELETRANSMISSION]}
                                        variantValue="h3"
                                        flexLabel={2}
                                    />
                                    :
                                    <InfoField
                                        label={intl.formatMessage({id: 'preaffiliation.confirmation.bornThe'})}
                                        value={FormatUtils.formatDate(formValues[IDENTIFICATION_FIELDS.BIRTH_DATE])}
                                        variantValue="h3"
                                        flexLabel={2}
                                    />
                                }
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.confirmation.personal.mail'})}
                                    value={formValues[INFOS_PERSOS_FIELDS.MAIL]}
                                    flexLabel={2}
                                    variantValue="h3"
                                />
                            </Stack>
                        </Stack>

                        <Stack
                            width="100%"
                            direction={isMobOrTabVert ? 'column' : 'row'}
                            spacing={isMobOrTabVert ? '20px' : '16px'}
                            justifyContent="space-between"
                        >
                            {!wantDispense ?
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.confirmation.bornThe'})}
                                    value={FormatUtils.formatDate(formValues[IDENTIFICATION_FIELDS.BIRTH_DATE])}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                                :
                                <Box flex="1"/>
                            }

                            <InfoField
                                label={intl.formatMessage({id: 'preaffiliation.personalInfos.form.phone.label'})}
                                value={FormatUtils.formatTel(formValues[INFOS_PERSOS_FIELDS.PHONE])}
                                flexLabel={2}
                                variantValue="h3"
                            />
                        </Stack>
                    </Stack>
                </Stack>

                <Divider/>

                {!wantDispense &&
                    <>
                        <Stack direction="column" spacing="20px">
                            <Typography variant="h2" fontSize="1.125rem" fontWeight="bold">
                                <FormattedMessage id="preaffiliation.confirmation.bank.title"/>
                            </Typography>

                            <Stack
                                width="100%"
                                direction={isMobOrTabVert ? 'column' : 'row'}
                                spacing={isMobOrTabVert ? '20px' : '16px'}
                                justifyContent="space-between"
                            >
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.confirmation.bank.owner'})}
                                    value={formValues[BANK_FIELDS.ACCOUNT_OWNER]}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.bankInfos.form.IBAN.label'})}
                                    value={formValues[BANK_FIELDS.IBAN]}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                            </Stack>

                            <Stack
                                width="100%"
                                direction="row"
                                spacing="16px"
                                justifyContent="space-between"
                            >
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.bankInfos.form.bank.label'})}
                                    value={formValues[BANK_FIELDS.BANK_NAME]}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                                {isMobOrTabVert ? undefined : <Box flex="1"/>}
                            </Stack>
                        </Stack>

                        {formValues[CONTRACT_FIELDS.MORE_BENEFICIARY]
                            && formValues[CONTRACT_FIELDS.MORE_BENEFICIARY].length === 1
                            && (
                                formValues.beneficiaires
                                && formValues.beneficiaires.length !== 0
                                && (
                                    <>
                                        <Divider/>

                                        <Typography variant="h2" fontSize="1.125rem" fontWeight="bold">
                                            <FormattedMessage id="preaffiliation.confirmation.beneficiary.title"/>
                                        </Typography>

                                        {formValues.beneficiaires.map((value: any, index: number) => (

                                            <>
                                                <Stack direction="column" spacing="20px" key={index}>
                                                    <Stack
                                                        width="100%"
                                                        direction={isMobOrTabVert ? 'column' : 'row'}
                                                        spacing={isMobOrTabVert ? '20px' : '16px'}
                                                        justifyContent="space-between"
                                                    >
                                                        <InfoField
                                                            label={getLabelEnum(BENEFICIARY_TYPES, "value", "label", value[BENEFICIARY_PREAFF_FIELDS.TYPES])}
                                                            value={value[BENEFICIARY_PREAFF_FIELDS.FIRSTNAME] + " " + value[BENEFICIARY_PREAFF_FIELDS.LAST_NAME]}
                                                            variantValue="h3"
                                                            flexLabel={2}
                                                        />
                                                        <InfoField
                                                            label={intl.formatMessage({id: 'preaffiliation.identification.form.NSS.label'})}
                                                            value={FormatUtils.formatNiss(value[BENEFICIARY_PREAFF_FIELDS.NISS])}
                                                            variantValue="h3"
                                                            flexLabel={2}
                                                        />
                                                    </Stack>

                                                    <Stack
                                                        width="100%"
                                                        direction={isMobOrTabVert ? 'column' : 'row'}
                                                        spacing={isMobOrTabVert ? '20px' : '16px'}
                                                        justifyContent="space-between"
                                                    >
                                                        <InfoField
                                                            label={intl.formatMessage({id: 'preaffiliation.confirmation.contract.coverage'})}
                                                            value={couverturesBenefSelected(formValues, index).find((couv: any) => couv.numFor === value[BENEFICIARY_PREAFF_FIELDS.COVERAGE]).libelle}
                                                            variantValue="h3"
                                                            flexLabel={2}
                                                        />
                                                        {(value[BENEFICIARY_PREAFF_FIELDS.TYPES] === "2_A" && value[BENEFICIARY_PREAFF_FIELDS.NISS_PARENT])
                                                            ? (
                                                                <InfoField
                                                                    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.label2'})}
                                                                    value={FormatUtils.formatNiss(value[BENEFICIARY_PREAFF_FIELDS.NISS_PARENT])}
                                                                    variantValue="h3"
                                                                    flexLabel={2}
                                                                />
                                                            ) : (
                                                                isMobOrTabVert ? undefined : <Box flex="1"/>
                                                            )
                                                        }
                                                    </Stack>

                                                    <Stack
                                                        width="100%"
                                                        direction={isMobOrTabVert ? 'column' : 'row'}
                                                        spacing={isMobOrTabVert ? '20px' : '16px'}
                                                        justifyContent="space-between"
                                                    >
                                                        <InfoField
                                                            label={intl.formatMessage({id: 'preaffiliation.confirmation.bornThe'})}
                                                            value={FormatUtils.formatDate(value[BENEFICIARY_PREAFF_FIELDS.BIRTH_DATE])}
                                                            variantValue="h3"
                                                            flexLabel={2}
                                                        />
                                                        <InfoField
                                                            label={intl.formatMessage({id: 'preaffiliation.confirmation.teletransmission'})}
                                                            value={value[BENEFICIARY_PREAFF_FIELDS.TELETRANSMISSION]}
                                                            variantValue="h3"
                                                            flexLabel={2}
                                                        />
                                                    </Stack>
                                                </Stack>

                                                {index + 1 !== formValues.beneficiaires.length && <Divider/>}
                                            </>
                                        ))}
                                    </>
                                )
                            )
                        }
                    </>
                }

                {wantDispense &&
                    <>
                        <Stack direction="column" spacing="20px">
                            <Typography variant="h2" fontSize="1.125rem" fontWeight="bold">
                                <FormattedMessage id="preaffiliation.dispense.confirmation.title"/>
                            </Typography>

                            <Stack
                                width="100%"
                                direction={isMobOrTabVert ? 'column' : 'row'}
                                spacing={isMobOrTabVert ? '20px' : '16px'}
                                justifyContent="space-between"
                            >
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.confirmation.contract.company'})}
                                    value={formValues[IDENTIFICATION_FIELDS.COMPANY_NAME]}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.confirmation.contract.category'})}
                                    value={formValues[IDENTIFICATION_FIELDS.BENEFICIARY_TYPE]}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                            </Stack>

                            <InfoField
                                label={intl.formatMessage({id: 'preaffiliation.dispense.confirmation.reason'})}
                                value={motifDispenseChosen(contract, formValues[DISPENSE_PREAFF_FIELDS.MOTIF]).libdispense}
                                variantValue="h3"
                                flexLabel={0.72}
                            />

                            <Stack
                                width="100%"
                                direction={isMobOrTabVert ? 'column' : 'row'}
                                spacing={isMobOrTabVert ? '20px' : '16px'}
                                justifyContent="space-between"
                            >
                                <InfoField
                                    label={intl.formatMessage({id: 'preaffiliation.dispense.confirmation.start'})}
                                    value={FormatUtils.formatDate(formValues[DISPENSE_PREAFF_FIELDS.START_DATE])}
                                    variantValue="h3"
                                    flexLabel={2}
                                />
                                {motifDispenseChosen(contract, formValues[DISPENSE_PREAFF_FIELDS.MOTIF]).organisme === "O" ?
                                    <InfoField
                                        label={intl.formatMessage({id: 'preaffiliation.dispense.confirmation.organism'})}
                                        value={formValues[DISPENSE_PREAFF_FIELDS.ORGANISM]}
                                        flexLabel={2}
                                        variantValue="h3"
                                    />
                                    :
                                    <Box flex="1"/>
                                }
                            </Stack>

                            {motifDispenseChosen(contract, formValues[DISPENSE_PREAFF_FIELDS.MOTIF]).ctrl === "O" &&
                                <Stack
                                    width="100%"
                                    direction={isMobOrTabVert ? 'column' : 'row'}
                                    spacing={isMobOrTabVert ? '20px' : '16px'}
                                    justifyContent="space-between"
                                >
                                    <InfoField
                                        label={intl.formatMessage({id: 'preaffiliation.dispense.confirmation.end'})}
                                        value={FormatUtils.formatDate(formValues[DISPENSE_PREAFF_FIELDS.END_DATE])}
                                        variantValue="h3"
                                        flexLabel={2}
                                    />
                                    <Box flex="1"/>
                                </Stack>
                            }


                        </Stack>

                        <Divider/>
                    </>
                }

                {wantDispense ?
                    <Stack direction="column" spacing={isMobOrTabVert ? '8px' : 0}>
                        <Box>
                            <CheckboxInput
                                name={DISPENSE_PREAFF_FIELDS.SITUATION_CHANGEMENT}
                                data={SITUATION_DATA as SelectOption[]}
                                error={formErrors && formErrors[DISPENSE_PREAFF_FIELDS.SITUATION_CHANGEMENT]}
                            />
                        </Box>
                        <Box>
                            <CheckboxInput
                                name={DISPENSE_PREAFF_FIELDS.CHOICE_CONSEQUENCIES}
                                data={CONSEQUENCIES_DATA as SelectOption[]}
                                error={formErrors && formErrors[DISPENSE_PREAFF_FIELDS.CHOICE_CONSEQUENCIES]}
                            />
                        </Box>
                    </Stack>
                    :
                    <Typography variant="subtitle2" fontStyle="italic" whiteSpace="pre-line">
                        {intl.formatMessage({id: 'preaffiliation.rgpd'})}
                        <Link
                            href={rgpdLink}
                            underline="none"
                            target="_blank"
                            rel="noreferrer noopener"
                            sx={(theme) => ({color: theme.palette.secondary.main})}
                        >
                            {intl.formatMessage({id: 'preaffiliation.rgpdlink'})}
                        </Link>
                    </Typography>
                }
            </Stack>
        </>
    )
}

const actions = {
    downloadGaranties: downloadGaranties
}
const mapStateToProps = () => {
    return (state: any) => ({
        coveragesBenefsMap: state.pages && getMapCouvertures(state)
    })
}
export default compose(
    connect(mapStateToProps, actions)
)(ConfirmationAffiliationForm)
