import UserService from '../../../api/gerep/UserService'
import LocalStorage from '../../../business/storage/LocalStorage'
import { types as getUserTypes } from '../../../ea/ui/user/userActions'

export const refreshUserAuthent = (dispatch) => {
	const token = LocalStorage.getToken()
	UserService.getUser(token).then(
		(response) => {
			dispatch({ type: getUserTypes.SET_USER_AUTHENT, payload: response.doubleAuthent })
		}
	).catch((e) => {
		throw e
	})
}
