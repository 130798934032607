import { unflatten } from 'flat'

/**
 * Erreur custom pour les retour API
 * status : code http
 * errCode: code d'erreur optionel
 * data : les données renvoyés dans le body de la réponse
 */
export default function ApiError(status, errCode, data) {
	this.status = status
	this.errCode = errCode
	this.data = unflatten(data)
	return this
}
