import * as SouscriptionService from '../../../../api/gerep/SouscriptionService'
import LocalStorage from '../../../../business/storage/LocalStorage'

export const downloadGuide = values => () => (
	SouscriptionService.downloadGuide(LocalStorage.getToken(), values)
)

export const downloadMandatSepa = values => () => (
	SouscriptionService.downloadMandatSepa(LocalStorage.getToken(), values)
)

export const souscrire = (numAdherent, idAdhesion, values) => () => (
	SouscriptionService.souscrire(numAdherent, idAdhesion, values, LocalStorage.getToken())
)
