import React, {useLayoutEffect} from 'react'
import {Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import EmptyResult from '../../components/EmptyResult'
import FormatUtils from '../../../../utils/FormatUtils'
import { text } from 'stream/consumers'

type GridChartProps = {
    id_title: string | React.ReactNode | any
    idChart: string
    dataChart: any[]
}

const GridChart: React.FC<GridChartProps> = (
    {
        id_title,
        idChart,
        dataChart
    }
) => {
    const intl = useIntl()
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const isSmallScreen = isMobile || isTabletPortrait

    const validDataCategoriesActes = (data: any) => {
        if (data.length) {
            for (const category of data) {
                if (category.remb !== 0 || category.rac !== 0) {
                    return true
                }
            }
            return false
        }
        return false
    }

    useLayoutEffect(() => {
        if (dataChart && validDataCategoriesActes(dataChart)) {
            let root = am5.Root.new(idChart)
            root.setThemes([am5themes_Animated.new(root)])
            root.numberFormatter.set("intlLocales", "fr-FR");
            root.numberFormatter.set("numberFormat", {
                minimumFractionDigits: 0
            })
            root.interfaceColors.set("grid", am5.color(0xffffff))
            // @ts-ignore
            root._logo.dispose()

            let myTheme = am5.Theme.new(root)
            myTheme.rule("Grid", ["base"]).setAll({
                strokeOpacity: 0.1
            })
            root.setThemes([
                am5themes_Animated.new(root),
                myTheme
            ])

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    layout: root.verticalLayout
                })
            )
            // @ts-ignore
            chart.get("colors").set("colors", [
                am5.color(0x4bc4c2),
                am5.color(0x606060)
            ])

            let yRenderer = am5xy.AxisRendererY.new(root, {})
            yRenderer.grid.template.setAll({
                location: 1
            })
            yRenderer.labels.template.setAll({
                maxWidth: isSmallScreen ? 115 : 200,
                fontWeight: '600',
                oversizedBehavior: isSmallScreen ? 'truncate' : 'wrap-no-break',
                tooltipText: isSmallScreen ? '{categorie}' : undefined,
                textAlign: 'end',
            })
            yRenderer.labels.template.setup = function (target) {
                target.set("background", am5.Rectangle.new(root, {
                    fill: am5.color(0x000000),
                    fillOpacity: 0
                }))
            }

            let yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: 'categorie',
                    renderer: yRenderer,
                    tooltip: am5.Tooltip.new(root, {}),
                })
            )
            yAxis.labelsContainer.set("tooltip", am5.Tooltip.new(root, {}))
            yAxis.data.setAll(dataChart)

            let maxData = 0
            dataChart.forEach((data) => {
                if (Math.abs(data.remb + data.rac) > maxData) {
                    maxData = Math.abs(data.remb + data.rac)
                }
            })

            let xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: 0,
                    max: 1.1 * maxData,
                    renderer: am5xy.AxisRendererX.new(root, {
                        strokeOpacity: 0.1
                    })
                })
            )

            let legend = chart.children.push(
                am5.Legend.new(root, {
                    layout: root.horizontalLayout,
                    centerX: am5.p50,
                    x: isSmallScreen ? am5.percent(60) : am5.p50
                })
            )
            legend.itemContainers.template.setAll(isMobile ? {
                marginLeft: 0,
                marginTop: 0,
                marginRight: 0,
                marginBottom: 0,
                paddingLeft: -20,
                paddingTop: 16,
                paddingRight: -20
            } : {})

            // @ts-ignore
            function makeSeries(name: string, fieldName: string, total: boolean) {
                let series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: name,
                        stacked: true,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        baseAxis: yAxis,
                        valueXField: fieldName,
                        categoryYField: 'categorie',
                        clustered: false
                    })
                )
                series.columns.template.setAll({
                    tooltipText: '{name}: {valueX}€',
                    tooltipY: am5.percent(90)
                })


                // @ts-ignore
                series.data.setAll(dataChart)

                series.bullets.push(function () {
                    let label = am5.Label.new(root, {
                        text: '{valueX}',
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: am5.p50,
                        centerX: am5.p50,
                        populateText: true,
                        oversizedBehavior: 'hide',
                        textAlign: 'center'
                    })

                    label.adapters.add("text", function (text, target) {
                        // @ts-ignore
                        let dataContext = target.dataItem.dataContext
                        // @ts-ignore
                        if(name === "Remboursement"){
                            // @ts-ignore
                            let val = FormatUtils.formatNumberWithoutK(Math.abs(dataContext.remb),0) + "€"
                            // @ts-ignore
                            return val
                        } else {
                            // @ts-ignore
                            let val = FormatUtils.formatNumberWithoutK(Math.abs(dataContext.rac),0) + "€"
                            // @ts-ignore
                            return val
                        }
                    })

                    return am5.Bullet.new(root, {
                        locationX: 0.5,
                        locationY: 0.5,
                        sprite: label,
                    })
                })

                if (total) {
                    series.columns.template.setAll({
                        cornerRadiusTR: 10,
                        cornerRadiusBR: 10,
                    })
                    series.bullets.push(function () {
                        let totalLabel = am5.Label.new(root, {
                            text: '{valueX}',
                            fill: root.interfaceColors.get("text"),
                            centerX: am5.p0,
                            centerY: am5.p50,
                            populateText: true,
                            textAlign: 'left',
                        })
                        totalLabel.adapters.add("text", function (text, target) {
                            // @ts-ignore
                            let dataContext = target.dataItem.dataContext
                            // @ts-ignore
                            let val = FormatUtils.formatNumberWithoutK(Math.abs(dataContext.remb + dataContext.rac),0) + "€"
                            // @ts-ignore
                            return val
                        })

                        return am5.Bullet.new(root, {
                            locationY: 0.5,
                            locationX: 1,
                            sprite: totalLabel,

                        })
                    })
                }
                series.appear()

                legend.data.push(series)
            }

            makeSeries(intl.formatMessage({id: 'sante.consommation.coutMoyen.refund'}), "remb", false)
            makeSeries(intl.formatMessage({id: 'sante.consommation.coutMoyen.rest'}), "rac", true)
            chart.appear(1000, 100)

            return () => root.dispose()
        }
    }, [dataChart, idChart, isMobile, isSmallScreen])

    const titleGraph = () => {
        return (
            <Typography
                textAlign="left"
                variant="h2"
                fontSize="1.125rem"
                dangerouslySetInnerHTML={{__html: id_title}}
            />
        )
    }

    if (!dataChart || (dataChart && !validDataCategoriesActes(dataChart))) {
        return (
            <Stack direction="column" spacing="24px" flex="1">
                {titleGraph()}
                <Stack alignItems="center" direction="column">
                    <EmptyResult
                        nature="GRAPH"
                        message={
                            <FormattedMessage
                                id="global.noGraphData"
                                values={{
                                    titreGraph:
                                        <Typography
                                            variant="body2"
                                            fontSize="0,875rem"
                                            fontWeight="bold"
                                            color="#929292"
                                            dangerouslySetInnerHTML={{__html: id_title}}
                                        />
                                }}
                            />
                        }
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column" spacing="12px" flex="1">
            {titleGraph()}
            <div
                id={idChart}
                style={{
                    width: 'auto',
                    padding: 0,
                    height: dataChart.length * 60 + 'px'
                }}
            />
        </Stack>
    )
}

export default GridChart
