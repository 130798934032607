import React, { useState } from 'react'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../ButtonDefault'
import { useBreakpoints } from '../breakpoints/BreakpointsProvider'

type PopinAuthentProps = {
	methode?: string
	terminer: () => void
	nextStep: () => void
	textCardibox?: string
}

const FinalPopinAuthent: React.FC<PopinAuthentProps> = ({
	methode,
	terminer,
	nextStep,
	textCardibox
}) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	return (
		<Stack direction="column" spacing="24px">
			{
				textCardibox && <Typography variant="body2">
					<div dangerouslySetInnerHTML={{ __html: textCardibox }} />
				</Typography>
			}
			{/*<Typography variant="body2">
				<FormattedMessage
					id="doubleAuthent.finalPopin.label"
					values={{
						methode: methode === 'mail' ? <b>un numéro de téléphone</b> : <b>une adresse email</b>
					}}
				/>
			</Typography>*/}
			<Stack direction={(isMobile || isTabletPortrait) ? 'column' : 'row'} spacing="16px" display="flex" justifyContent="center">
				{/*<ButtonDefault
					variant="outlined"
					onClick={nextStep}
					style={{ minWidth: 'auto', whiteSpace: 'nowrap' }}>
					<FormattedMessage id={`doubleAuthent.finalPopin.button.ajout.${methode === 'mail' ? 'sms' : 'mail'}`} />
				</ButtonDefault>*/}
				<ButtonDefault
					variant="contained"
					onClick={() => {
						setIsLoading(true)
						terminer()
					}}>
					{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="doubleAuthent.finalPopin.button.terminer" />}
				</ButtonDefault>
			</Stack>
		</Stack>
	)
}

export default FinalPopinAuthent
