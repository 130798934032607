import React from 'react'
import * as repaymentSelectors from '../../../webservices/remboursementsV2/repaymentSelectors'
import * as repaymentActions from '../../../webservices/remboursementsV2/repaymentActions'
import { connect } from 'react-redux'
import { Box, Stack, Typography } from '@mui/material'
import DatePickerInputRembFilter from '../../../../components/form/DatePickerInputRembFilter'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { REFUND_FILTERS_FIELDS } from '../services/remboursementConstants'
import { Form } from 'react-final-form'
import { isValid } from 'date-fns'
import SelectInputRembFilter from '../../../../components/form/SelectInputRembFilter'
import TextInputRembFilter from '../../../../components/form/TextInputRembFilter'

type FiltersRemboursementProps = {
	activeFilters: any,
	filters: any,
	setActiveFilters: any
}

const FiltersRemboursementForm: React.FC<FiltersRemboursementProps> = (
	{
		activeFilters,
		filters,
		setActiveFilters
	}
) => {

	const intl = useIntl()
	const { isDesktop, isLaptop, isTablet, isWidescreen } = useBreakpoints()
	const isDesktopOrLaptop = isDesktop || isLaptop || isTablet || isWidescreen
	const isDesktopOrLaptopSmall = isDesktop || isLaptop || isTablet
	const onSubmit = (values: any) => {
		setActiveFilters({
			filters: {
				[REFUND_FILTERS_FIELDS.DATE_DEBUT]: values[REFUND_FILTERS_FIELDS.DATE_DEBUT],
				[REFUND_FILTERS_FIELDS.DATE_FIN]: values[REFUND_FILTERS_FIELDS.DATE_FIN],
				[REFUND_FILTERS_FIELDS.DATE]: values[REFUND_FILTERS_FIELDS.DATE],
				[REFUND_FILTERS_FIELDS.BENEFICIARY]: values[REFUND_FILTERS_FIELDS.BENEFICIARY],
				[REFUND_FILTERS_FIELDS.TYPE]: values[REFUND_FILTERS_FIELDS.TYPE]
			}
		})
	}

	const validate = (values: any) => {
		const errors: any = {}
		if (values) {
			const dateD = new Date(values[REFUND_FILTERS_FIELDS.DATE_DEBUT])
			const dateF = new Date(values[REFUND_FILTERS_FIELDS.DATE_FIN])
			if (!dateD) {
				errors[REFUND_FILTERS_FIELDS.DATE_DEBUT] = 'Champ obligatoire'
			} else {
				// la date de debut de doit pas dater de plus de 2 ans
				const year = dateD.getFullYear()
				const now = new Date()
				const currentYear = now.getFullYear()
				if (currentYear - year > 2) {
					errors[REFUND_FILTERS_FIELDS.DATE_DEBUT] = 'L\'historique de remboursement n\'excède pas 2 ans, vous devez restreindre votre recherche.'
				}
			}

			if (!dateF) {
				errors[REFUND_FILTERS_FIELDS.DATE_FIN] = 'Champ obligatoire'
			}
			if ((dateD) &&
				(dateF) &&
				(dateD > dateF)) {
				errors[REFUND_FILTERS_FIELDS.DATE_FIN] = 'Date de fin antérieure à date de début'
			}
			if ((values[REFUND_FILTERS_FIELDS.DATE]) && !isValid(new Date(values[REFUND_FILTERS_FIELDS.DATE]))) {
				errors[REFUND_FILTERS_FIELDS.DATE] = ' '
			}
		}

		return errors
	}
	return (
		<Stack direction="row">
			<Form
				onSubmit={onSubmit}
				validate={validate}
				initialValues={activeFilters}
				render={(
					{
						handleSubmit,
						errors,
						form
					}) => {
					const onChangeInput = () => {
						form.submit()
					}

					return (
						<form onSubmit={handleSubmit} style={{ width: '100%' }}>
							<Stack direction={isDesktopOrLaptop ? 'row' : 'column'} spacing="24px">
								<Stack flex={4} direction="column">
									<Typography alignSelf="flex-start" variant="body2" sx={{ marginBottom: '12px' }}>
										<FormattedMessage id="remboursementConsultation.filters.periode.title" />
									</Typography>
									<Stack direction={isDesktopOrLaptop ? 'row' : 'column'} spacing="24px">
										<Box flex={1}>
											<DatePickerInputRembFilter
												maxWidth="unset"
												name={REFUND_FILTERS_FIELDS.DATE_DEBUT}
												label={intl.formatMessage({ id: 'remboursementConsultation.filters.periode.debut' })}
												initialValue={filters[0].values}
												onChange={onChangeInput}
											/>
										</Box>
										<Box flex={1}>
											<DatePickerInputRembFilter
												maxWidth="unset"
												name={REFUND_FILTERS_FIELDS.DATE_FIN}
												label={intl.formatMessage({ id: 'remboursementConsultation.filters.periode.fin' })}
												initialValue={filters[1].values}
												onChange={onChangeInput}
											/>
										</Box>
									</Stack>
								</Stack>
								<Stack flex={7} direction="column">
									<Typography alignSelf="flex-start" variant="body2" sx={{ marginBottom: '12px' }}>
										<FormattedMessage id="remboursementConsultation.filters.type.title" />
									</Typography>
									<Stack direction={isDesktopOrLaptop ? 'row' : 'column'} spacing="24px">
										<Box flex={1}>
											<DatePickerInputRembFilter
												maxWidth="unset"
												name={REFUND_FILTERS_FIELDS.DATE}
												label={intl.formatMessage({ id: 'remboursementConsultation.filters.type.dateSoin' })}
												onChange={onChangeInput}
											/>
										</Box>
										<Box flex={1}>
											<SelectInputRembFilter
												name={REFUND_FILTERS_FIELDS.BENEFICIARY}
												data={filters[3].values}
												label={intl.formatMessage({ id: 'remboursementConsultation.filters.type.beneficaire' })}
												error={errors && errors[REFUND_FILTERS_FIELDS.BENEFICIARY]}
												onChange={onChangeInput}
												sxOverright={isDesktopOrLaptopSmall ? { maxWidth: '170px' } : { minWidth: '160px' }}
											/>
										</Box>
										<Box flex={1}>
											<TextInputRembFilter
												label={intl.formatMessage({ id: 'remboursementConsultation.filters.type.type' })}
												maxWidth="unset"
												name={REFUND_FILTERS_FIELDS.TYPE}
												error={errors && errors[REFUND_FILTERS_FIELDS.TYPE]}
												onChange={onChangeInput}
											/>
										</Box>
									</Stack>
								</Stack>
							</Stack>
						</form>
					)
				}}
			/>
		</Stack>
	)
}

const mapStateToProps = (state: any) => ({
	activeFilters: repaymentSelectors.getActiveFilters(state),
	filters: repaymentSelectors.getFilters(state)
})

const actions = {
	setActiveFilters: repaymentActions.setActiveFilters
}

export default connect(mapStateToProps, actions)(FiltersRemboursementForm)
