import React from 'react'
import {useIntl} from 'react-intl'
import {Stack} from '@mui/material'
import {Cancel, CheckCircle} from '@mui/icons-material'
import {FIELD_PATTERN} from '../univers/ui/authentication/services/loginConstants'



type ChangingIconHelperPasswordChecksProps = {
    condition: boolean
    message: string
}
const ChangingIconHelperPasswordChecks: React.FC<ChangingIconHelperPasswordChecksProps> = ({condition, message}) => {
    const intl = useIntl()

    return (
        <Stack direction="row" style={{color: condition ? '#8ac11d' : '#fc454a', display: 'flex', alignItems: 'center', height: '12pt'}}>
            {condition ? <CheckCircle sx={{width: '13px', paddingRight: 1}}/> : <Cancel sx={{width: '13px', paddingRight: 1}}/>}
            {intl.formatMessage({id: message})}
        </Stack>
    )
}

const passwordChecksFct = (value: string): [boolean, string][] => {
    const hasRightLength = FIELD_PATTERN.LENGTH.test(value)
    const hasUppercaseLetter = FIELD_PATTERN.UPPER.test(value)
    const hasLowercaseLetter = FIELD_PATTERN.LOWER.test(value)
    const hasDigit = FIELD_PATTERN.DIGIT.test(value)
    const hasSpecialSymbol = FIELD_PATTERN.SYMBOL.test(value)

    return [
        [hasRightLength, 'global.error.badLengthPassword'],
        [hasUppercaseLetter, 'global.error.needUppercaseLetter'],
        [hasLowercaseLetter, 'global.error.needLowercaseLetter'],
        [hasDigit, 'global.error.needDigit'],
        [hasSpecialSymbol, 'global.error.needSpecialSymbol']
    ]
}


type HelperPasswordChecksProps = {
    input: string
}
const HelperPasswordChecks: React.FC<HelperPasswordChecksProps> = ({input}) => {
    const intl = useIntl()
    const errorPasswordChecksArray = passwordChecksFct(input)

    return (
        <>
            {intl.formatMessage({id: 'login.form.passwordRulesTitle'})}
            <Stack direction="column" style={{paddingLeft: 10, margin: 0}}>
                {errorPasswordChecksArray.map((element, index) =>
                    <ChangingIconHelperPasswordChecks key={index} condition={element[0]} message={element[1]}/>
                )}
            </Stack>
        </>
    )
}
export default HelperPasswordChecks
