import React from 'react'
import {Box, Link, Stack, Typography} from '@mui/material'
import TextInput from '../../../../../components/form/TextInput'
import {
    ameliUrl,
    BENEFICIARY_PREAFF_FIELDS,
    BOOLEAN,
    BOOLEANS,
    CIVILITES,
    SEXES
} from '../../services/preaffiliationConstants'
import SelectInput from '../../../../../components/form/SelectInput'
import PatternTextInput from '../../../../../components/PatternTextInput'
import {PATTERNS} from '../../../../../components/formConstants'
import DatePickerInput from '../../../../../components/form/DatePickerInput'
import {FormattedMessage, useIntl} from 'react-intl'
import {BENEFICIARY_TYPES} from '../../../../../components/beneficiaryConstants'
import FileInput from '../../../../../components/fileInput/FileInput'
import RadioInput from '../../../../../components/form/RadioInput'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ButtonLink from '../../../../../components/ButtonLink'
import Icon from '@mdi/react'
import {mdiAccountMinus, mdiAccountPlus} from '@mdi/js'
import {useBreakpoints} from '../../../../../components/breakpoints/BreakpointsProvider'
import {getTypeCouvertureFromTypeBeneficiaire} from '../../constants/global'
import {connect} from 'react-redux'
import {getMapCouvertures} from '../../../webservices/WsSelector'
import {toUpperCase} from '../../../../../utils/stringUtils'
import NissTextInput from '../../../../../components/NissTextInput'


type BeneficiaireAffiliationFormProps = {
    values: any
    name: string
    index: number
    push: any
    fields: any
    key?: any
    coveragesBenefsMap: any
}

const BeneficiaireAffiliationForm: React.FC<BeneficiaireAffiliationFormProps> = (
    {
        values,
        name,
        index,
        fields,
        push,
        coveragesBenefsMap
    }
) => {

    const intl = useIntl()
    const { isMobile, isTabletPortrait } = useBreakpoints()
    const isMobOrTabVert = isMobile || isTabletPortrait

    const formBeneficiaryExists = (values: any, index: number): string => {
        if (values[BENEFICIARY_PREAFF_FIELDS.NAME] && values[BENEFICIARY_PREAFF_FIELDS.NAME][index]) {
            return values[BENEFICIARY_PREAFF_FIELDS.NAME][index][BENEFICIARY_PREAFF_FIELDS.TYPES]
        } else {
            return ""
        }
    }

    const couverturesBenefSelected = (values: any, index: number) => {
        if (coveragesBenefsMap && formBeneficiaryExists(values, index)) {
            const typeCode = formBeneficiaryExists(values, index)
            return coveragesBenefsMap.get(getTypeCouvertureFromTypeBeneficiaire(typeCode))
        } else {
            return [{value:"", libelle:"Sélectionnez un type de bénéficiaire"}]
        }
    }

    return (

        <Stack key={`${name}-${index}`} direction="column" spacing="32px">

            <Stack
                direction={isMobOrTabVert ? 'column' : 'row'}
                spacing={isMobOrTabVert ? '32px' : '42px'}
                flex="1"
            >
                <SelectInput
                    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.TYPES}`}
                    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.type.label'})}
                    data={BENEFICIARY_TYPES.map((type: any) => ({value: type.value, label: type.label}))}
                    mandatory
                />
                <SelectInput
                    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.COVERAGE}`}
                    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.coverage.label'})}
                    data={couverturesBenefSelected(values, index).map((couverture: any) => ({value: couverture.numFor, label: couverture.libelle}))}
                    mandatory
                />
            </Stack>

            <Stack
                direction={isMobOrTabVert ? 'column' : 'row'}
                spacing={isMobOrTabVert ? '32px' : '42px'}
                flex="1"
            >
                <SelectInput
                    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.CIVILITY}`}
                    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.civility.label'})}
                    data={CIVILITES.map((civilite: any) => ({value: civilite.value, label: civilite.label}))}
                    mandatory
                />
                <SelectInput
                    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.SEX}`}
                    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.sex.label'})}
                    data={SEXES.map((sexe: any) => ({value: sexe.value, label: sexe.label}))}
                    mandatory
                />
            </Stack>

            <Stack
                direction={isMobOrTabVert ? 'column' : 'row'}
                spacing={isMobOrTabVert ? '32px' : '42px'}
                flex="1"
            >
                <TextInput
                    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.LAST_NAME}`}
                    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.lastName.label'})}
                    placeholder={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.lastName.placeholder'})}
                    maxLength={30}
                    maxWidth="unset"
                    mandatory={true}
                    format={toUpperCase}
                />
                <TextInput
                    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.FIRSTNAME}`}
                    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.firstName.label'})}
                    placeholder={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.firstName.placeholder'})}
                    maxLength={30}
                    maxWidth="unset"
                    mandatory={true}
                    format={toUpperCase}
                />
            </Stack>

            <Stack direction="column" spacing="32px" flex="1">
                <Stack
                    direction={isMobOrTabVert ? 'column' : 'row'}
                    spacing={isMobOrTabVert ? '32px' : '42px'}
                    flex="1"
                >
                    {formBeneficiaryExists(values, index) !== "2_A"
                        ? (
                            // <PatternTextInput
                            //     name={`${name}.${BENEFICIARY_PREAFF_FIELDS.NISS}`}
                            //     format={PATTERNS.SOCIAL_SECURITY_NUMBER}
                            //     label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.label'})}
                            //     placeholder={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.placeholder'})}
                            //     helperText={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.info'})}
                            //     maxWidth="unset"
                            //     mask="_"
                            //     mandatory
                            // />
                            <NissTextInput
                                name={`${name}.${BENEFICIARY_PREAFF_FIELDS.NISS}`}
                                label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.label'})}
                                placeholder={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.placeholder'})}
                                helperText={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.info'})}
                                format={toUpperCase}
                                maxWidth="unset"
                                mandatory
                            />
                        ) : undefined
                    }
                    <DatePickerInput
                        name={`${name}.${BENEFICIARY_PREAFF_FIELDS.BIRTH_DATE}`}
                        label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.birthDate.label'})}
                        maxWidth="unset"
                        mandatory={true}
                    />
                    {formBeneficiaryExists(values, index) === "2_A" && !isMobOrTabVert
                        ? <Box maxWidth="600px" width="100%"/> : undefined
                    }
                </Stack>

                {formBeneficiaryExists(values, index) === "2_A"
                    ? (
                        <Stack direction="column" spacing="12px">
                            <Stack direction="row" spacing="8px">
                                <InfoOutlinedIcon width="400px"/>
                                <Typography variant="body2" fontSize="0.875rem">
                                    <FormattedMessage id="preaffiliation.cntrtAndBenef.beneficiary.form.NSS.warning"/>
                                </Typography>
                            </Stack>

                            <Stack
                                direction={isMobOrTabVert ? 'column' : 'row'}
                                spacing={isMobOrTabVert ? '32px' : '42px'}
                                flex="1"
                            >
                                {/*<PatternTextInput*/}
                                {/*    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.NISS}`}*/}
                                {/*    format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
                                {/*    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.label'})}*/}
                                {/*    placeholder={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.placeholder'})}*/}
                                {/*    maxWidth="unset"*/}
                                {/*    mask="_"*/}
                                {/*    mandatory*/}
                                {/*/>*/}
                                <NissTextInput
                                    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.NISS}`}
                                    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.label'})}
                                    placeholder={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.placeholder'})}
                                    format={toUpperCase}
                                    maxWidth="unset"
                                    mandatory
                                />
                                {/*<PatternTextInput*/}
                                {/*    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.NISS_PARENT}`}*/}
                                {/*    format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
                                {/*    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.label2'})}*/}
                                {/*    placeholder={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.placeholder'})}*/}
                                {/*    helperText={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.info2'})}*/}
                                {/*    maxWidth="unset"*/}
                                {/*    mask="_"*/}
                                {/*/>*/}
                                <NissTextInput
                                    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.NISS_PARENT}`}
                                    label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.label2'})}
                                    placeholder={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.placeholder'})}
                                    helperText={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.NSS.info2'})}
                                    format={toUpperCase}
                                    maxWidth="unset"
                                />
                            </Stack>
                        </Stack>



                    ) : undefined



                }

            </Stack>

            <Stack direction="column" spacing="24px">
                <FileInput
                    name={`${name}.${BENEFICIARY_PREAFF_FIELDS.NISS_ATTESTATION}`}
                    title={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.attestation.title'})}
                    maxFileNumber={1}
                    label={
                        <Stack
                            direction={isMobOrTabVert ? 'column' : 'row'}
                            spacing={isMobOrTabVert ? '4px' : undefined}
                            justifyContent="space-between"
                        >
                            <Typography variant="body2" fontSize="0.875rem">
                                <FormattedMessage id="preaffiliation.cntrtAndBenef.beneficiary.form.attestation.label"/>
                            </Typography>

                            <Typography variant="body2" fontSize="0.75rem">
                                <Link
                                    href={ameliUrl}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    sx={(theme) => ({color: theme.palette.secondary.main})}
                                >
                                    {intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.attestation.link'})}
                                </Link>
                            </Typography>
                        </Stack>
                    }
                />

                {(formBeneficiaryExists(values, index) === "2_B"
                        || formBeneficiaryExists(values, index) === "3"
                        || formBeneficiaryExists(values, index) === "7")
                    && (
                        <FileInput
                            required
                            name={`${name}.${BENEFICIARY_PREAFF_FIELDS.FILES}`}
                            maxFileNumber={1}
                            title={
                                <Typography fontWeight="500" fontSize="0.875rem" whiteSpace="pre-line">
                                    {intl.formatMessage({
                                        id: (formBeneficiaryExists(values, index) === "2_B")
                                            ? 'preaffiliation.cntrtAndBenef.beneficiary.form.files.title_2_B'
                                            : formBeneficiaryExists(values, index) === "3"
                                                ? 'preaffiliation.cntrtAndBenef.beneficiary.form.files.title_3'
                                                : 'preaffiliation.cntrtAndBenef.beneficiary.form.files.title_7'
                                    })}
                                </Typography>
                            }
                            label={intl.formatMessage({
                                id: (formBeneficiaryExists(values, index) === "2_B")
                                    ? 'preaffiliation.cntrtAndBenef.beneficiary.form.files.label_2_B'
                                    : formBeneficiaryExists(values, index) === "3"
                                        ? 'preaffiliation.cntrtAndBenef.beneficiary.form.files.label_3'
                                        : 'preaffiliation.cntrtAndBenef.beneficiary.form.files.label_7'
                            })}

                    />
                    )
                }

                <Stack direction="column" spacing="12px">
                    <RadioInput
                        name={`${name}.${BENEFICIARY_PREAFF_FIELDS.TELETRANSMISSION}`}
                        label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.teletransmission.label'})}
                        data={BOOLEANS.map((boolean: any) => ({value: boolean.value, label: boolean.label}))}
                        direction="row"
                        defaultValue={formBeneficiaryExists(values, index) === "2_A" || formBeneficiaryExists(values, index) === "2_B"
                            ? BOOLEAN.OUI.value : BOOLEAN.NON.value
                        }
                        radioSpacing="24px"
                    />
                    <Stack direction="row" spacing="8px">
                        <InfoOutlinedIcon width="400px"/>
                        <Typography variant="body2" fontSize="0.875rem">
                            <FormattedMessage id="preaffiliation.personalInfos.form.teletransmission.helper"/>
                        </Typography>
                    </Stack>
                </Stack>

                <Stack direction="row" justifyContent="center">
                    <Stack direction="column" textAlign="center">
                        {fields.value.length > 1 &&
                            <Box flex="1">
                                <ButtonLink
                                    variant="contained"
                                    startIcon={<Icon size="20px" path={mdiAccountMinus}/>}
                                    onClick={() => {
                                        fields.remove(index)
                                    }}
                                    sx={{textAlign: 'center', lineHeight: '18px'}}
                                >
                                    <FormattedMessage id="preaffiliation.cntrtAndBenef.beneficiary.delete"/>
                                </ButtonLink>
                            </Box>
                        }

                        {index === fields.value.length - 1 &&
                            <Box flex="1">
                                <ButtonLink
                                    variant="contained"
                                    startIcon={<Icon size="20px" path={mdiAccountPlus}/>}
                                    onClick={() => {
                                        push(fields.name, undefined)
                                    }}
                                    sx={{textAlign: 'center', lineHeight: '18px'}}
                                >
                                    <FormattedMessage id="preaffiliation.cntrtAndBenef.beneficiary.addmore"/>
                                </ButtonLink>
                            </Box>
                        }
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

const mapStateToProps = () => {

    return (state: any) => ({
        coveragesBenefsMap: state.pages && getMapCouvertures(state)
    })
}

export default connect(mapStateToProps)(BeneficiaireAffiliationForm)

