// utils
import {getExpirationDate, isListCompanyLoading} from './ListCompanySelector'
import LocalStorage from '../../../business/storage/LocalStorage'
// api
import {getListCompany as getListCompanyService} from '../../../api/gerepEe/EntrepriseService'

const ACTION_NAMESPACE = '/LIST_COMPANY'

export const types = {
	GET_LIST_COMPANY_LOADING: `${ACTION_NAMESPACE}/GET_LIST_COMPANY_LOADING`,
	GET_LIST_COMPANY: `${ACTION_NAMESPACE}/GET_LIST_COMPANY`
}

export const getListCompany = () => (dispatch, getState) => {
	if (!isListCompanyLoading(getState()) && getExpirationDate(getState()) < (+new Date())) {
		dispatch({
			type: types.GET_LIST_COMPANY_LOADING,
			payload: true
		})
		getListCompanyService(LocalStorage.getToken())
			.then((listCompany) => {
				let listCompagniesKpi = listCompany.filter((compagnie) => {
					return compagnie.services.find(e => e === "KPI")
				})
				dispatch({
					type: types.GET_LIST_COMPANY,
					payload: listCompagniesKpi.length > 1 ? [{numindiv: '0', nom: 'Toutes vos sociétés'}, ...listCompagniesKpi] : listCompagniesKpi
				})
				dispatch({
					type: types.GET_LIST_COMPANY_LOADING,
					payload: false
				})
				return listCompagniesKpi
			}).catch((e) => {
				dispatch({ type: types.GET_LIST_COMPANY_LOADING, payload: false })
				throw e
			})
	}
}
