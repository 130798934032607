import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { Field } from 'react-final-form'

type AutocompleteInputProps = {
	name: string
	label?: string
	options: string[]
	validate?: (value: string) => string | undefined
	defaultValue?: string
	maxWidth?: string
	error?: string
	mandatory?: boolean
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = (
	{
		name,
		label,
		options,
		validate,
		defaultValue,
		maxWidth = '350px',
		error,
		mandatory = false
	}
) => {
	return (
		<Field name={name} validate={validate} initialValue={defaultValue}>
			{({ input }) => (
				<Autocomplete
					disablePortal
					value={input.value}
					onChange={(event, value) => input.onChange(value)}
					options={options}
					size="small"
					isOptionEqualToValue={(option, value) => option.toLocaleUpperCase() === value.toLocaleUpperCase()}
					renderInput={(params) =>
						<Field name={name}>
							{({meta}) => {
								return (
									<TextField
										{...params}
										color="secondary"
										error={!!error || ((meta.touched || meta.dirty || meta.modified) && (meta.error || meta.submitError))}
										helperText={((meta.touched || meta.dirty || meta.modified) && meta.error) || ((meta.touched || meta.dirty || meta.modified) && meta.submitError) || error}
										label={label+ (mandatory ? ' *' : '')}
									/>
								)
							}}
						</Field>
					}
					style={{width: '100%', maxWidth: maxWidth}}
				/>
			)}
		</Field>
	)
}

export default AutocompleteInput