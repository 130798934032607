import { getExpirationDate, isLoaded, isNotificationLoading } from './NotificationSelectors'
import NotificationsService from '../../../api/gerep/NotificationsService';
import LocalStorage from '../../../business/storage/LocalStorage';

const ACTION_NAMESPACE = '/NOTIFICATION'

export const types = {
	GET_ALL: `${ACTION_NAMESPACE}/GET_ALL`,
	GET_ALL_LOADING: `${ACTION_NAMESPACE}/GET_ALL_LOADING`,
	ADD: `${ACTION_NAMESPACE}/ADD`,
	REMOVE: `${ACTION_NAMESPACE}/REMOVE`
}

let key = 0

const getAllNotifications = notifications => (dispatch) => {
	dispatch({
		type: types.GET_ALL,
		payload: notifications.map(notification => ({
			...notification,
			id: key++
		}))
	})
}

export const loadNotifications = () => (dispatch, getState) => {
	if (!isNotificationLoading(getState())
		&& (!isLoaded(getState()) || getExpirationDate(getState()) < (+new Date()))) {
		dispatch(dispatch({
			type: types.GET_ALL_LOADING,
			payload: true
		}))
		NotificationsService.getNotifications(LocalStorage.getToken())
			.then(notifications => {
				dispatch(getAllNotifications(notifications))
				dispatch(dispatch({
					type: types.GET_ALL_LOADING,
					payload: false
				}))
				return notifications
			}, (e) => {
				console.error(e)
				dispatch(dispatch({
					type: types.GET_ALL_LOADING,
					payload: false
				}))
			})
	}
}

export const downloadNotification = (url) => {
	return NotificationsService.downloadNotification(url, LocalStorage.getToken())
}

/**
 * Supprime une notification identifiée par son id interne.
 *
 * @param id l'id interne de la notification
 */
export const removeNotificationByInternalId = (id) => (dispatch) => {
	dispatch({
		type: types.REMOVE,
		payload: {
			id
		}
	})
}
