import React, {useContext, useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {useBreakpoints} from '../../../../../components/breakpoints/BreakpointsProvider'
import {Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import PageLayout from '../../../../../components/PageLayout'
import IdentificationAffiliationForm from '../../components/home/IdentificationAffiliationForm'
import InfosPersoAffiliationForm from '../../components/home/InfosPersoAffiliationForm'
import BankInfosAffiliationForm from '../../components/home/BankInfosAffiliationForm'
import ContratBeneficiaireAffiliationForm from '../../components/home/Contrat&BeneficiaireAffiliationForm'
import ConfirmationAffiliationForm from '../../components/home/ConfirmationAffiliationForm'
import ChoixDispenseAffiliationForm from '../../components/dispense/ChoixDispenseAffiliationForm'
import ConfirmationPopinPreaffForm from '../../components/ConfirmationPopinPreaffForm'
import Box from '@mui/material/Box'
import StepperForm from '../../../../../components/StepperForm'
import {
	BANK_FIELDS,
	BOOLEAN,
	CIVILITE,
	CONTRACT_FIELDS,
	DISPENSE_PREAFF_FIELDS,
	IDENTIFICATION_FIELDS,
	INFOS_PERSOS_FIELDS,
	SEXE,
	STEP_STATUS,
	STEPS_AFFIL_BASE,
	STEPS_DISPENSE,
	TWO_FIRST_STEPS
} from '../../services/preaffiliationConstants'
import {Form} from 'react-final-form'
import StepsButton from '../../../../../components/StepsButton'
import {
	validBankInfos,
	validChoixDispense, validConfirmation,
	validContrat,
	validIdentification,
	validInfosPerso
} from '../../services/preaffiliationValidationFields'
import SuggestService from '../../../../../api/gerep/SuggestService'
import arrayMutators from 'final-form-arrays'
import {createSelector} from 'reselect'
import {getContract} from '../../user/userSelector'
import {
	getAffiliation,
	getDateFinDuContratEnVigueur, getMapContrat,
	getMapCouvertures,
	getPreSetFields
} from '../../../webservices/WsSelector'
import {getBeneficiaires, identifier, preSetFields, validerPreAffiliation} from '../../../webservices/WsActions'
import {compose} from 'redux'
import FormatUtils from '../../../../../utils/FormatUtils'
import {getTypeCouvertureFromTypeBeneficiaire, TYPE_BENEFICIAIRE, TYPE_COUVERTURE} from '../../constants/global'
import {isObjectEmpty} from '../../../../../utils/formUtils'
import EtatIdentification from '../../constants/EtatIdentification'
import Popup from '../../../../../components/Popup'
import ButtonDefault from '../../../../../components/ButtonDefault'
import {Redirect} from 'react-router-dom'
import {MAIN_PATH_UNIVERS} from '../../../../../constant/path'
import {reset} from '../../services/preaffiliationActions'
import {ToastType} from '../../../../../components/toast/toastConstants'
import {ToastContext} from '../../../../../components/toast/ToastContext'
import Loader from '../../../../../components/Loader'
import {addDays, subDays} from 'date-fns'
import appConst from '../../../../../constant/appConstants'
import {cardiboxActions} from 'redux-cardibox'
import {couvertureChosen, motifDispenseChosen, oneCheckBoxListBool} from '../../preaffiliationUtils'
import {TypeAdherent} from "../../../../../constant/TypeAdherent";


const ARTICLE_PREAFF_IDENTIF = 'preaffiliation_identification'
const ARTICLE_PREAFF_BANKINFOS = 'intro_etape_3'
const ARTICLE_PREAFF_CNTRT_BENEF = 'intro_etape_4'
const ARTICLE_PREAFF_SIMPLIFIE_CNTRT_BENEF = 'intro_etape_4_simplifie'
const ARTICLE_POPIN_CONFIRM = 'txt_popin_confirmation_aff'
const ARTICLE_DISPENSE_INFOS_PERSOS = 'intro_dispense_etape_2'
const ARTICLE_DISPENSE_CHOIX = 'intro_dispense_etape_3'



const resetScroll = () => {
	window.scrollTo(0, 0)
}

type HomePreAffiliationPageProps = {
	affiliation: any
	initialValues: any
	coverageValues: any
	dateEffetContrat: any
	contract: any
	endDate: Date
	numAssure: string
	prefill: any
}
const HomePreAffiliationPage: React.FC<HomePreAffiliationPageProps> = (
	{
		// @ts-ignore
		getArticleByShortcut,
		// @ts-ignore
		identifier,
		// @ts-ignore
		getBeneficiaires,
		// @ts-ignore
		validerPreAffiliation,
		// @ts-ignore
		reset,
		// @ts-ignore
		preSetFields,
		affiliation,
		initialValues,
		coverageValues,
		dateEffetContrat,
		contract,
		endDate,
		numAssure,
		prefill
	}
) => {
	const intl = useIntl()
	const [initialValuesFrom, setInitialValuesFrom] = useState<any>(initialValues)
	const [submitLoading, setSubmitLoading] = useState<boolean>(false)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
	const [wantDispense, setWantDispense] = useState<boolean|undefined>(contract.dispense ? undefined : false)
	const [valuesForPopin, setValuesForPopin] = useState({})
	const [moreStepsLater, setMoreStepsLater] = useState<boolean>(false)
	const { addToast } = useContext(ToastContext)

	const [openDeconnexionEAPopin, setOpenDeconnexionEAPopin] = useState<boolean>(false)
	const [deconnexionEAPopinProps, setDeconnexionEAPopinProps] = useState({})

	const { isMobile, isTabletPortrait, height } = useBreakpoints()
	const isMobOrTabVert = isMobile || isTabletPortrait

	const [pays, setPays] = useState<Country[]>([])
	useEffect(() => {
		SuggestService.getPays()
			.then((response) => setPays(response))
	}, [])

	const dateDebutMin = (values: any) => {
		let dateFinal = undefined
		if (!wantDispense && coverageValues.listCouvertures && values[CONTRACT_FIELDS.COVERAGE]) {
			dateFinal = new Date()
			const dateDebut = new Date(couvertureChosen(coverageValues.listCouvertures, values[CONTRACT_FIELDS.COVERAGE]).dateDebut)
			if (endDate !== undefined) {
				const dateFin = new Date(endDate)
				dateFin.setDate(dateFin.getDate() + 1)

				if (dateDebut > dateFin) {
					dateFinal.setTime(dateDebut.getTime())
				} else {
					dateFinal.setTime(dateFin.getTime())
				}
			} else {
				dateFinal.setTime(dateDebut.getTime())
			}
			const todayMinus90Days = subDays(new Date(), 90)
			if (dateFinal !== undefined) {
				if (dateFinal > todayMinus90Days) {
					return dateFinal.setHours(0,0,0,0);
				}
				return todayMinus90Days.setHours(0,0,0,0);
			}
			return todayMinus90Days.setHours(0,0,0,0);
		} else if (wantDispense && contract.justifsDispense !== null && values[DISPENSE_PREAFF_FIELDS.MOTIF]  && dateEffetContrat) {
			dateFinal = new Date()
			if (endDate !== undefined) {
				const dateFin = new Date(endDate)
				dateFin.setDate(dateFin.getDate() + 1)
				if (dateEffetContrat > dateFin) {
					dateFinal.setTime(dateEffetContrat.getTime())
				} else {
					dateFinal.setTime(dateFin.getTime())
				}
			} else {
				dateFinal.setTime(dateEffetContrat.getTime())
			}
			const todayMinus60Days = subDays(new Date(), 60)
			if (dateFinal !== undefined) {
				if (dateFinal > todayMinus60Days) {
					return dateFinal.setHours(0,0,0,0);
				}
				return todayMinus60Days.setHours(0,0,0,0);
			}
			return todayMinus60Days.setHours(0,0,0,0);
		}
		const todayMinus60Days = subDays(new Date(), 60).setHours(0,0,0,0);
		return todayMinus60Days;
	}

	const identifierAction = (values: any) => {

		return identifier ({
			acceptCGU: oneCheckBoxListBool(values[IDENTIFICATION_FIELDS.GENERAL_USE]),
			acceptRGPD: oneCheckBoxListBool(values[IDENTIFICATION_FIELDS.CONTRACT_MANAGEMENT]),
			categorieBenef: values[IDENTIFICATION_FIELDS.BENEFICIARY_TYPE],
			civilite: values[IDENTIFICATION_FIELDS.CIVILITY],
			dateNaissance: (FormatUtils.formatDate(values[IDENTIFICATION_FIELDS.BIRTH_DATE])),
			entreprise: values[IDENTIFICATION_FIELDS.COMPANY_NAME],
			niss: (values[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER] || '').replace(/ /g, ''),
			nom: values[IDENTIFICATION_FIELDS.LAST_NAME],
			prenom: values[IDENTIFICATION_FIELDS.FIRST_NAME],
			sexe: values[IDENTIFICATION_FIELDS.SEX],
			societeId: contract.idSociete
		})
	}

	const validerForm = (values: any) => {
		validerPreAffiliation(values)
		.then(() => {
				setSubmitLoading(false)
				setOpenConfirmation(true)
			}
		).catch((error:any) => {
			setSubmitLoading(false)
			if (error.data) {
				for (const key in error.data) {
					if (error.data.hasOwnProperty(key)) {
						addToast(ToastType.ERROR, error.data[key])
					}
				}
			} else {
				if (error.status === 401) {
					addToast(ToastType.ERROR, 'preaffiliation.error.wrongCode')
				} else if (error.status === 403) {
					addToast(ToastType.ERROR, 'preaffiliation.error.alreadyAffiliated')
				} else {
					addToast(ToastType.ERROR, 'preaffiliation.error.global')
				}
			}
		})
	}

	const deconnexionEAPopin = (propsEtat: any ) => {
		setDeconnexionEAPopinProps(propsEtat)
		setOpenDeconnexionEAPopin(true)
	}


	const onSubmit = (values: any) => {
		if (currentStep < STEPS_LABELS.length - 1) {
			if (currentStep === TWO_FIRST_STEPS.IDENTIFICATION) {
				identifierAction(values)
					.then((res: any) => {
						const status = res.status
						if (status) {
							switch (status.code) {
								case EtatIdentification.SUCCESS.code:
								case EtatIdentification.VALIDATION_MANUELLE_REQUISE.code:
									setCurrentStep(currentStep + 1)
									return STEP_STATUS.NEXT
								case EtatIdentification.NON_VALIDE_RH.code:
									deconnexionEAPopin(EtatIdentification.NON_VALIDE_RH)
									break
								case EtatIdentification.NON_VALIDE_GEREP.code:
									deconnexionEAPopin(EtatIdentification.NON_VALIDE_GEREP)
									break
								case EtatIdentification.ADHESION_EN_COURS.code:
									deconnexionEAPopin(EtatIdentification.ADHESION_EN_COURS)
									break
								default:
									throw new Error()
							}
							return res.data
						}
						throw new Error()
					})
					.catch((error:any) => {
						setSubmitLoading(false)
						if (error.data && error.data) {
							addToast(ToastType.ERROR, error.data._error)
						}

						if (error.status === 401) {
							addToast(ToastType.ERROR, 'preaffiliation.error.wrongCode')
						} else if (error.status === 403) {
							addToast(ToastType.ERROR, 'preaffiliation.error.alreadyAffiliated')
						} else {
							addToast(ToastType.ERROR, 'preaffiliation.error.global')
						}
					})
			} else if (currentStep === TWO_FIRST_STEPS.INFOS_PERSO) {
				getBeneficiaires(contract.idSociete)
				setCurrentStep(currentStep + 1)

			} else {
				if (wantDispense) {
					currentStep === STEPS_DISPENSE.EXEMPTION_CHOICE && setCurrentStep(currentStep + 1 )
				} else {
					if (currentStep === STEPS_AFFIL_BASE.BANK_INFOS) {
						setCurrentStep(currentStep + 1)
					} else if (currentStep === STEPS_AFFIL_BASE.BENEFICIAIRE) {
						setCurrentStep(currentStep + 1)
					}
				}
			}
			resetScroll()

		} else if (currentStep === STEPS_LABELS.length - 1) {
			setSubmitLoading(true)

			const declareBenefBool = () => {
				const declareBenef = values[CONTRACT_FIELDS.MORE_BENEFICIARY]
				if (declareBenef) {
					return declareBenef.length === 1
				} else {
					return false
				}
			}
			validerForm([
				{
					key: 'affiliation',
					value: {
						statut: affiliation.identification,
						idSociete: contract.idSociete,
						numgar: contract.numgar,
						numindiv: contract.numindiv ? contract.numindiv : affiliation.numindiv,
						declareBeneficiaires : declareBenefBool(),
						contrat: {
							dateDebutAffl: FormatUtils.formatDate(values[CONTRACT_FIELDS.AFFILIATION_START]),
							couverture: values[CONTRACT_FIELDS.COVERAGE]
						},
						identification: {
							nom: values[IDENTIFICATION_FIELDS.LAST_NAME],
							prenom: values[IDENTIFICATION_FIELDS.FIRST_NAME],
							sexe: values[IDENTIFICATION_FIELDS.SEX],
							civilite: values[IDENTIFICATION_FIELDS.CIVILITY],
							dateNaissance: FormatUtils.formatDate(values[IDENTIFICATION_FIELDS.BIRTH_DATE]),
							niss: values[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER].replace(/ /g, '')
						},
						infoPersonnelles: {
							rangNaissance: "1",
							activeTeletransmission: values[INFOS_PERSOS_FIELDS.TELETRANSMISSION],
							adresse: values[INFOS_PERSOS_FIELDS.ADRESS1],
							adresse2: values[INFOS_PERSOS_FIELDS.ADRESS2],
							adresse3: values[INFOS_PERSOS_FIELDS.ADRESS3],
							codePostal: values[INFOS_PERSOS_FIELDS.ZIP_CODE].replace(/ /g, ''),
							ville: values[INFOS_PERSOS_FIELDS.CITY],
							pays: values[INFOS_PERSOS_FIELDS.COUNTRY],
							telephone: values[INFOS_PERSOS_FIELDS.PHONE],
							email: values[INFOS_PERSOS_FIELDS.MAIL]
						},
						coordBancaires: wantDispense ? {} : {
							banque: values[BANK_FIELDS.BANK_NAME],
							titulaire: values[BANK_FIELDS.ACCOUNT_OWNER],
							iban: values[BANK_FIELDS.IBAN].replace(/ /g, '')
						},
						dispense: wantDispense ? {
							motifAff: motifDispenseChosen(contract, values[DISPENSE_PREAFF_FIELDS.MOTIF]).codemotifadh,
							dateDebutDispense: FormatUtils.formatDate(values[DISPENSE_PREAFF_FIELDS.START_DATE]),
							organisme: motifDispenseChosen(contract, values[DISPENSE_PREAFF_FIELDS.MOTIF]).organisme === "O" ? values[DISPENSE_PREAFF_FIELDS.ORGANISM] : undefined,
							dateResili: motifDispenseChosen(contract, values[DISPENSE_PREAFF_FIELDS.MOTIF]).ctrl === "O" ? FormatUtils.formatDate(values[DISPENSE_PREAFF_FIELDS.END_DATE]) : undefined
						} : undefined,
						beneficiaires: !declareBenefBool() ? [] : (values["beneficiaires"] || []).filter((benef: any) => !isObjectEmpty(benef)).map((benef: any) => {
							return {
								...benef,
								rangNaissance: "1",
								type: (benef.type === "2_A" || benef.type === "2_B") ? TypeAdherent.ENFANTS : benef.type,
								dateNaissance: FormatUtils.formatDate(benef.dateNaissance),
								fichierSS: (benef.fichierSS && benef.fichierSS[0]) ? benef.fichierSS[0] : {} ,
								extraFile: undefined ,
								niss: benef.niss.replace(/ /g, ''),
								niss2: (benef.type === "2_A" &&  benef.niss2) ? benef.niss2.replace(/ /g, '') : undefined
							}
						})
					}
				},
				{
					key: 'filePrincipal',
					value: values[INFOS_PERSOS_FIELDS.SOCIAL_SECURITY_ATTESTATION]
				},
				...(values["beneficiaires"] || []).map((benef:any) =>
					({
						key: 'filesBeneficiaire',
						value: (benef.fichierSS && benef.fichierSS[0]) ? benef.fichierSS[0] : {}
					})
				),
				...(values["beneficiaires"] || []).map((benef:any, idx: number) => {
					return ({
							key: `beneficiaire[${idx}].fichierExtra`,
							value: (benef.extraFile && benef.extraFile[0])? benef.extraFile[0] : {}
						})
				}
				)
			])
			wantDispense && setValuesForPopin({
				"nom": values[IDENTIFICATION_FIELDS.LAST_NAME],
				"prenom": values[IDENTIFICATION_FIELDS.FIRST_NAME],
				"adresse": values[INFOS_PERSOS_FIELDS.ADRESS1],
				"adresse2": values[INFOS_PERSOS_FIELDS.ADRESS2],
				"adresse3": values[INFOS_PERSOS_FIELDS.ADRESS3],
				"ville": values[INFOS_PERSOS_FIELDS.CITY],
				"codePostal": values[INFOS_PERSOS_FIELDS.ZIP_CODE],
				"pays": values[INFOS_PERSOS_FIELDS.COUNTRY],
				"dispenseOrganisme": motifDispenseChosen(contract, values[DISPENSE_PREAFF_FIELDS.MOTIF]).organisme === "O" ?
					values[DISPENSE_PREAFF_FIELDS.ORGANISM] : undefined,
				"dispenseDateResili": motifDispenseChosen(contract, values[DISPENSE_PREAFF_FIELDS.MOTIF]).ctrl === "O" ?
					FormatUtils.formatDate(values[DISPENSE_PREAFF_FIELDS.END_DATE]) : undefined,
				"dispenseMotifAff": motifDispenseChosen(contract, values[DISPENSE_PREAFF_FIELDS.MOTIF]).codemotifadh,
				"idSociete": contract.idSociete
			})
		}
	}

	const [currentStep, setCurrentStep] = useState(TWO_FIRST_STEPS.IDENTIFICATION)

	const STEPS_LABELS = useMemo(() => {
		const twoFirstSteps = [
			intl.formatMessage({id: 'preaffiliation.identification.title'}),
			intl.formatMessage({id: 'preaffiliation.personalInfos.title'})
		]
		const threeLastNormalPreaffSteps = [
			intl.formatMessage({id: 'preaffiliation.bankInfos.title'}),
			intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.title'}),
			intl.formatMessage({id: 'preaffiliation.confirmation.title'})
		]

		if (contract.dispense) {
			if (wantDispense === undefined) {
				setMoreStepsLater(true)
				return [
					...twoFirstSteps
				]
			} else if (wantDispense) {
				setMoreStepsLater(false)
				return [
					...twoFirstSteps,
					intl.formatMessage({id: 'preaffiliation.dispense.dispenseChoiceStep.title'}),
					intl.formatMessage({id: 'preaffiliation.confirmation.title'})
				]
			}
			setMoreStepsLater(false)
			return [
				...twoFirstSteps,
				...threeLastNormalPreaffSteps
			]
		}

		return [
			...twoFirstSteps,
			...threeLastNormalPreaffSteps
		]
	}, [intl, contract.dispense, wantDispense])


	useEffect(() => {
		if (contract.numindiv !== null) {
			preSetFields(contract.idSociete, contract.numindiv)
		}
	}, [contract, preSetFields])

	useEffect(() => {
		if (Object.keys(prefill).length !== 0) {
			setInitialValuesFrom({
				...initialValues,
				[IDENTIFICATION_FIELDS.LAST_NAME]: prefill.nom,
				[IDENTIFICATION_FIELDS.FIRST_NAME]: prefill.prenom,
				[IDENTIFICATION_FIELDS.BIRTH_DATE]: new Date(prefill.dateNaissance),
				[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER]: prefill.niss,
				[IDENTIFICATION_FIELDS.SEX]: prefill.niss && (
					prefill.niss.substring(0, 1) === "1"
						? SEXE.MASCULIN.value
						: prefill.niss.substring(0, 1) === "2"
							? SEXE.FEMININ.value
							: undefined
				),
				[IDENTIFICATION_FIELDS.CIVILITY]: prefill.niss && (
					prefill.niss.substring(0, 1) === "1"
						? CIVILITE.MR.value
						: prefill.niss.substring(0, 1) === "2"
							? CIVILITE.MS.value
							: undefined
				),
				[INFOS_PERSOS_FIELDS.ADRESS1]: prefill.adresse1,
				[INFOS_PERSOS_FIELDS.ADRESS2]: prefill.adresse2,
				[INFOS_PERSOS_FIELDS.ADRESS3]: prefill.adresse3,
				[INFOS_PERSOS_FIELDS.ZIP_CODE]: prefill.codpos,
				[INFOS_PERSOS_FIELDS.CITY]: prefill.ville,
				[INFOS_PERSOS_FIELDS.COUNTRY]: prefill.pays || "FRANCE",

				[BANK_FIELDS.BANK_NAME]: prefill.domiciliation,
				[BANK_FIELDS.IBAN]: prefill.bban ? prefill.clefIban + prefill.bban : null,
				[BANK_FIELDS.ACCOUNT_OWNER]: prefill.intitule
					? prefill.intitule
					: (prefill.prenom && prefill.nom)
						? (prefill.prenom + prefill.nom)
						: undefined,
			})
		}
	}, [prefill])


	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_PREAFF_IDENTIF)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_PREAFF_BANKINFOS)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_PREAFF_CNTRT_BENEF)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_PREAFF_SIMPLIFIE_CNTRT_BENEF)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_POPIN_CONFIRM)
		contract.dispense && getArticleByShortcut(appConst.cardiboxApi, ARTICLE_DISPENSE_INFOS_PERSOS)
		contract.dispense && getArticleByShortcut(appConst.cardiboxApi, ARTICLE_DISPENSE_CHOIX)
	}, [getArticleByShortcut])

	if ((Object.keys(contract).length === 0 && contract.constructor === Object)   // pour éviter de passer sans s'être log
		|| (Object.keys(contract).length !== 0 && affiliation.new === false)) {   // pour éviter de revenir depuis la souscription après validation
		return <Redirect to={MAIN_PATH_UNIVERS.LOGIN_EA}/>
	}

	if (contract.numindiv !== null &&
		(Object.keys(prefill).length === 0 && prefill.constructor === Object)) {
		return <Loader/>
	}

	return (
		<>
			<Box
				display="block"
				minHeight={height}
				sx={{ bgcolor: '#f7f5f4', overflow: 'hidden'}}
				justifyContent="center"
				paddingBottom={isMobOrTabVert ? '12px': '72px'}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					paddingX={isMobOrTabVert ? '12px' : '40px'}
				>
					<img
						src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
						style={{ width: '100px', height: '56px', paddingTop:'8px' }}
						alt="logo"
					/>

					<Stack direction="column" justifyContent="flex-end" textAlign="right">
						<Typography variant="h4" fontSize="0.875rem">
							<FormattedMessage id="preaffiliation.company"/>
							{initialValues[IDENTIFICATION_FIELDS.COMPANY_NAME]}
						</Typography>
						<Typography variant="subtitle1" fontSize="0.8125rem">
							<FormattedMessage id="preaffiliation.category"/>
							{initialValues[IDENTIFICATION_FIELDS.BENEFICIARY_TYPE]}
						</Typography>
					</Stack>
				</Stack>

				<Box
					display="flex"
					justifyContent="center"
					paddingTop="8px"
					paddingX={isMobile ? '12px': 0}
				>
					<PageLayout title={intl.formatMessage({ id: 'preaffiliation.title' })}>
						<Form
							initialValues={initialValuesFrom}
							validate={(values) => {
								const errors: any = {}
								{
									currentStep === TWO_FIRST_STEPS.IDENTIFICATION && (validIdentification(values, errors, intl))
								}
								{
									currentStep === TWO_FIRST_STEPS.INFOS_PERSO && (validInfosPerso(values, errors, intl, contract.dispense, wantDispense))
								}
								{
									!wantDispense && currentStep === STEPS_AFFIL_BASE.BANK_INFOS && (validBankInfos(values, errors, intl))
								}
								{
									!wantDispense && currentStep === STEPS_AFFIL_BASE.BENEFICIAIRE && (validContrat(values, errors, intl, dateDebutMin, contract))
								}
								{
									wantDispense && currentStep === STEPS_DISPENSE.EXEMPTION_CHOICE && (validChoixDispense(values, errors, intl, dateDebutMin, contract))
								}
								{
									wantDispense && currentStep === STEPS_DISPENSE.CONFIRMATION && (validConfirmation(values, errors, intl))
								}
								return errors
							}}
							onSubmit={onSubmit}
							mutators={{
								...arrayMutators
							}}
							render={({
										 handleSubmit,
										 values,
										 errors,
										 form: {
											 mutators: { push }
										 },
									 }) => {
								return (
									<>
										<Stack
											direction="column"
											spacing="24px"
											onDragOver={(event) => {
												event.preventDefault()
												event.stopPropagation()
											}}
											onDrop={(event) => {
												event.preventDefault()
												event.stopPropagation()
											}}
										>
											<StepperForm steps={STEPS_LABELS} activeStep={currentStep}/>

											<>
												{currentStep === TWO_FIRST_STEPS.IDENTIFICATION &&
													<IdentificationAffiliationForm errors={errors}/>
												}
												{currentStep === TWO_FIRST_STEPS.INFOS_PERSO &&
													<InfosPersoAffiliationForm
														values={values}
														pays={pays}
														isDispense={contract.dispense}
														exemption={wantDispense}
														setExemption={setWantDispense}
													/>
												}
												{!wantDispense && currentStep === STEPS_AFFIL_BASE.BANK_INFOS &&
													<BankInfosAffiliationForm values={values}/>
												}
												{!wantDispense && currentStep === STEPS_AFFIL_BASE.BENEFICIAIRE &&
													<ContratBeneficiaireAffiliationForm
														values={values}
														push={push}
														dateDebutMin={dateDebutMin}
														listCouvertures={coverageValues.listCouvertures}
														contract={contract}
													/>
												}
												{((!wantDispense && currentStep === STEPS_AFFIL_BASE.CONFIRMATION)
														|| (wantDispense && currentStep === STEPS_DISPENSE.CONFIRMATION)) &&
													<ConfirmationAffiliationForm
														formValues={values}
														formErrors={errors}
														contract={contract}
														listCouvertures={coverageValues.listCouvertures}
														wantDispense={wantDispense}
													/>
												}
												{wantDispense && currentStep === STEPS_DISPENSE.EXEMPTION_CHOICE &&
													<ChoixDispenseAffiliationForm
														values={values}
														contract={contract}
														dateDebutMin={dateDebutMin}
														step={currentStep}
														setStep={setCurrentStep}
													/>
												}
											</>

											<Stack direction="column" paddingTop="24px" spacing="12px">
												<StepsButton
													step={currentStep}
													setStep={setCurrentStep}
													maxSteps={STEPS_LABELS.length}
													handleSubmit={handleSubmit}
													loading={submitLoading}
													labelNext={intl.formatMessage({id: 'preaffiliation.nextStep'})}
													labelPrevious={intl.formatMessage({id: 'preaffiliation.previousStep'})}
													labelEnd={intl.formatMessage({id: 'preaffiliation.validateAffiliation'})}
													moreStepsLater={moreStepsLater}
												/>
												<Typography variant="body2" fontSize="0.875rem">
													<FormattedMessage id="preaffiliation.requiredFields"/>
												</Typography>
											</Stack>
										</Stack>
									</>
								)
							}}
						/>
					</PageLayout>
				</Box>
			</Box>

			<ConfirmationPopinPreaffForm
				formValues={valuesForPopin}
				openPopin={openConfirmation}
				setOpenPopin={setOpenConfirmation}
				wantDispense={wantDispense}
			/>

			<Popup
				open={openDeconnexionEAPopin}
				setOpen={setOpenDeconnexionEAPopin}
				// @ts-ignore
				title={deconnexionEAPopinProps.title}
				showCloseButton={false}
			>
				<Stack direction="column" spacing="16px" alignItems="center">
					<Typography
						variant="body2"
						fontSize="0.875rem"
						whiteSpace="pre-line"
						// @ts-ignore
						dangerouslySetInnerHTML={{ __html: deconnexionEAPopinProps.body }}
					/>

					{//  @ts-ignore
						deconnexionEAPopinProps.code === EtatIdentification.ADHESION_EN_COURS.code ?
							<Typography variant="body2" fontSize="0.875rem">
								{intl.formatMessage({id: 'preaffiliation.identification.popin.helper'}, {numAssure: numAssure})}
							</Typography>
							:
							undefined
					}

					<ButtonDefault variant="contained" onClick={()=> reset}>
						{intl.formatMessage({ id:
							// @ts-ignore
							deconnexionEAPopinProps.code === EtatIdentification.ADHESION_EN_COURS.code
								? 'preaffiliation.identification.popin.go_espace'
								: 'preaffiliation.identification.popin.quit'
						})}
					</ButtonDefault>
				</Stack>
			</Popup>
		</>
	)
}

const actions = {
	identifier,
	getBeneficiaires,
	validerPreAffiliation,
	reset,
	preSetFields,
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}

const mapStateToProps = () => {
	const getInitialValues = createSelector([
		getContract,
		getAffiliation
	], (contract, affiliation) => {
		return {
			[IDENTIFICATION_FIELDS.COMPANY_NAME]: contract.libSociete,
			[IDENTIFICATION_FIELDS.BENEFICIARY_TYPE]: contract.college,

			[INFOS_PERSOS_FIELDS.ADRESS1]: affiliation.adresse1,
			[INFOS_PERSOS_FIELDS.ADRESS2]: affiliation.adresse2,
			[INFOS_PERSOS_FIELDS.ADRESS3]: affiliation.adresse3,
			[INFOS_PERSOS_FIELDS.ZIP_CODE]: affiliation.codpos,
			[INFOS_PERSOS_FIELDS.CITY]: affiliation.ville,
			[INFOS_PERSOS_FIELDS.COUNTRY]: affiliation.pays || "FRANCE",
			[INFOS_PERSOS_FIELDS.TELETRANSMISSION]: BOOLEAN.OUI.value,

			[CONTRACT_FIELDS.AFFILIATION_START]: FormatUtils.parseDate(contract.debutAffiliation),
			[DISPENSE_PREAFF_FIELDS.START_DATE]: FormatUtils.parseDate(contract.debutAffiliation)
		}
	})
	const getNumAssure = createSelector([
		getAffiliation
	], (affiliation) => {
		return affiliation.numindiv
	})
	const getCollege = createSelector([
		getContract
	], (contract) => {
		return contract.college
	})
	const getCoverageSelectValues = createSelector([
		getMapCouvertures,
	], (mapCouvertures) => {
		const initialContrat: any = {}
		const listCouvertures = mapCouvertures.get(TYPE_COUVERTURE.ADHERENT_PRINCIPAL) || []
		if (listCouvertures.length === 1 && listCouvertures[0]) {
			const couverture = listCouvertures[0]
			initialContrat[CONTRACT_FIELDS.COVERAGE] = couverture.numFor
		}
		return {
			listCouvertures,
			"contrat": initialContrat
		}
	})

	const getContratDateEffet = createSelector([
		getMapContrat,
	], (mapContrats) => {
		const contrat = mapContrats.get(TYPE_COUVERTURE.ADHERENT_PRINCIPAL) || []
		console.log("contrat", contrat)
		if (contrat) {
			return new Date(contrat.dateEffet)
		}
		return new Date()
	})

	return (state: any) => ({
		affiliation: state.pages && getAffiliation(state),
		initialValues: state.pages && getInitialValues(state),
		college: state.pages && state.pages.contrat && getCollege(state),
		// @ts-ignore
		coverageValues: state.pages && getCoverageSelectValues(state),
		// @ts-ignore
		dateEffetContrat: state.pages && getContratDateEffet(state),
		contract: state.pages && getContract(state),
		endDate: state.pages && getDateFinDuContratEnVigueur(state),
		numAssure: state.pages && getNumAssure(state),
		prefill: state.pages && getPreSetFields(state)
	})
}
export default compose(
	connect(mapStateToProps, actions)
)(HomePreAffiliationPage)

