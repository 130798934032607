import {combineReducers} from 'redux'
import {cardiboxReducer} from 'redux-cardibox'
import login from './univers/ui/authentication/services/login/loginReducer'
import reseauSoin from './ea/ui/dashboard/services/reseauSoinReducer'
import notifications from './ea/webservices/notifications/NotificationReducer'
import interstitiel from './ea/webservices/interstitiel/interstitielReducer'
import suggest from './ea/ui/personalInfo/services/suggest/suggestReducer'
import {types as loginTypes} from './univers/ui/authentication/services/login/loginActions'
import {types as affilTypes} from './univers/ui/preaffiliation/services/preaffiliationActions'
import readOnlyModal from './ea/ui/readOnlyModal/ReadOnlyModalReducer'
import surveillance from './univers/ui/errorPage/surveillance/surveillanceReducers'
import popinNotation from './ea/ui/notation/popinNotationReducer'
import inscription from './univers/ui/authentication/services/inscription/inscriptionReducer'
import {webservicesReducersEA, webservicesReducersEE} from './ea/webservices/webservicesReducers'
import {user} from './ea/ui/user/userReducer'
import {user as userEE} from './kpi/ui/userEE/userEEReducer'
import contrat from './univers/ui/preaffiliation/user/userReducer'
import webservices from './univers/ui/webservices/WsReducer'
import dataReducer from './kpi/ui/data/dataReducer'
import listCompany from './kpi/ui/listCompany/ListCompanyReducers'


const pagesReducerUnivers = combineReducers({
    login,
    inscription,
    contrat,
    ...webservices
})

const universReducer = combineReducers({
    user,
    pages: pagesReducerUnivers,
    cardibox: cardiboxReducer,
    surveillance

})


const pagesReducer = combineReducers({
    reseauSoin,
    suggest
})

const appReducerEA = combineReducers({
    user,
    notifications,
    ...webservicesReducersEA,
    interstitiel,
    readOnlyModal,
    surveillance,
    pages: pagesReducer,
    cardibox: cardiboxReducer,
    popinNotation
})

const appReducerEE = combineReducers({
    user: userEE,
    dataKpi : dataReducer,
    ...webservicesReducersEE,
    readOnlyModal,
    surveillance,
    listCompany,
    cardibox: cardiboxReducer
})

export const rootUniversReducer = (state, action) => {
    if (action.type === loginTypes.LOGOUT) {
        // données à préserver lors de la déconnexion
        const {
            cardibox
        } = state
        return universReducer({
            cardibox
        }, action)
    }
    if (action.type === affilTypes.RESET) {
        // nettoyage du state du module
        return universReducer({}, action)
    }
    return universReducer(state, action)
}

export const rootEAReducer = (state, action) => {
    if (action.type === loginTypes.LOGOUT) {
        // données à préserver lors de la déconnexion
        const {
            cardibox
        } = state
        return appReducerEA({
            cardibox
        }, action)
    }
    return appReducerEA(state, action)
}

export const rootEEReducer = (state, action) => {
    if (action.type === loginTypes.LOGOUT) {
        // données à préserver lors de la déconnexion
        const {
            cardibox
        } = state
        return appReducerEE({
            cardibox
        }, action)
    }
    return appReducerEE(state, action)
}
