import React, { useState } from 'react'
import { Box, Popover as MUIPopover } from '@mui/material'
import Icon from '@mdi/react'

type PopoverProps = {
	children: React.ReactNode
	iconPath: string
	iconSize?: string
}

const Popover: React.FC<PopoverProps> = (
	{
		children,
		iconPath,
		iconSize = '24px'
	}) => {
	const [showPopover, setShowPopover] = useState<boolean>(false)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setShowPopover(!showPopover)
		setAnchorEl(event.currentTarget)
	}

	return (
		<>
			<span
				onClick={handleClick}
				style={{ color: '#606060' }}
			>
				<Icon path={iconPath} size={iconSize} />
			</span>
			<MUIPopover
				anchorEl={anchorEl}
				open={showPopover}
				onClose={() => setShowPopover(false)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<Box p={2} maxWidth="242px">
					{children}
				</Box>
			</MUIPopover>
		</>
	)
}

export default Popover