import React, { useEffect } from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getAffiliationAssurePrincipal } from '../../../webservices/affiliation/affiliationSelector'
import * as affiliationActions from '../../../webservices/affiliation/affiliationActions'
import { userSelector } from '../../user/userSelectors'
import appConst from '../../../../constant/appConstants'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import welcomeTemp from '../../../../resources/img/welcome_tempp.png'
import Box from '@mui/material/Box'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'

const ARTICLE_INTRO = 'homepage_introduction'

type WelcomeProps = {
	affiliationExpired?: boolean
}

const Welcome: React.FC<WelcomeProps> = (
	{
		// @ts-ignore
		getAffiliation,
		// @ts-ignore
		getArticleByEtiquette,
		// @ts-ignore
		assurePrincipal,
		// @ts-ignore
		user,
		// @ts-ignore
		introduction,
		affiliationExpired
	}) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()

	useEffect(() => {
		if (!introduction) {
			getArticleByEtiquette(appConst.cardiboxApi, ARTICLE_INTRO)
		}

		if (!assurePrincipal) {
			getAffiliation()
		}
	}, [getAffiliation, getArticleByEtiquette, introduction, assurePrincipal])

	return (
		<DashboardCard isTransparent cardSx={{
			display: 'flex',
			alignItems: 'flex-end'
		}}>
			<Stack direction="row" alignItems="end" justifyContent="space-between">
				<Stack width="100%" direction="column" spacing={2}>
					<Stack direction="column">
						{introduction && introduction.fields && assurePrincipal && (
							<Typography fontWeight={400} fontSize="1.5rem">
								{`${introduction.fields.description} `}
								<b>
									{assurePrincipal.prenom}
								</b>
							</Typography>
						)}
						{user && user.dateConnexion && (
							<Typography variant="body2">
								<FormattedMessage id="dashboard.welcome.lastVisit" values={{ date: user.dateConnexion }} />
							</Typography>
						)}
					</Stack>
					{affiliationExpired ?
						<Stack direction="row" alignItems="center" spacing={1}>
							<ErrorOutlinedIcon sx={{ height: '48px', width: '48px' }} color="secondary" />
							<Typography variant="body1" color="secondary">
								<FormattedMessage id="dashboard.welcome.expired" />
							</Typography>
						</Stack>
						:
						<Typography variant="body1">
							<FormattedMessage id="dashboard.welcome.message" />
						</Typography>
					}
				</Stack>
				{(!(isMobile || isTabletPortrait) && !affiliationExpired) &&
					<Box>
						<img src={welcomeTemp} alt="welcome temp" style={{ maxHeight: '172px' }} />
					</Box>
				}
			</Stack>
		</DashboardCard>
	)
}

const actions = {
	getAffiliation: affiliationActions.getAffiliation,
	getArticleByEtiquette: cardiboxActions.articles.getByEtiquette
}

const mapStateToProps = (state: any) =>
	({
		assurePrincipal: getAffiliationAssurePrincipal(state),
		user: userSelector(state),
		introduction: cardiboxSelectors.articles.getByEtiquette(state.cardibox)[ARTICLE_INTRO]
	})

export default compose(
	connect(mapStateToProps, actions)
)(Welcome)
