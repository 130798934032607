import {Dispatch} from 'react'
import {login as loginApi} from '../api/UserService'
import {UNIVERS_PARTICULIERS} from '../../../constant/univers'

export const ACTION_NAMESPACE = '/PREAFFILIATION'

export const types = {
	RESET: `${ACTION_NAMESPACE}/RESET`,
	LOG_USER: `${ACTION_NAMESPACE}/LOG_USER`,
	LOG_OUT: `${ACTION_NAMESPACE}/LOG_OUT`,
	LOADING: `${ACTION_NAMESPACE}/LOADING`,
}

/**
 * Commande de nettoyage du module.
 */
export const reset = () => (dispatch: Dispatch<any>) => {
	dispatch({
		type: types.RESET
	})
}


export const login = (token: string, codeAccess: string) => (dispatch: Dispatch<any>) => {
	return loginApi({
		token,
		codeAccess
	})
		.then((loginResponse: any) => {
			if (loginResponse) {
				dispatch({
					type: types.LOG_USER,
					payload: loginResponse
				})
				return loginResponse
			}
			throw new Error('Login failed')
		})
		.catch((e:any) => {
			throw e
		})
}

export const logout = () => {
	window.location.href = UNIVERS_PARTICULIERS.root()
}
