import { createSelector } from 'reselect'
import { getIndividuList } from '../affiliation/affiliationSelector'

const getDemandes = state => state.demandes.data
export const isDemandesLoading = state => state.demandes.loading
export const getDemandesExpirationDate = state => state.demandes.expirationDate

export const getListeDemandes = createSelector([
	getDemandes,
	getIndividuList
], (demandes, individus) => {
	return demandes && demandes.map((demande) => {
		const benef = individus.find(ind => ind[1].numIndividu === demande.numbene)
		const beneficiaire = benef ? `${benef[1].nom} ${benef[1].prenom}` : ''
		return {
			...demande,
			beneficiaire
		}
	})
})

export const getDernieresDemandes = (number = 2) => createSelector([
	getDemandes,
	getIndividuList
], (demandes, individus) => {
	return demandes.slice(0, number).map((demande) => {
		const benef = individus.find(ind => ind[1].numIndividu === demande.numbene)
		const beneficiaire = benef ? `${benef[1].prenom}` : ''
		return {
			...demande,
			beneficiaire
		}
	})
})
