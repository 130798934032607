import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Stack, Typography } from '@mui/material'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import { connect } from 'react-redux'
import Popup from '../../../../../components/Popup'
import appConst from '../../../../../constant/appConstants'

type ConfirmMailPopinProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}

const ARTICLE_NAME = 'confirm_edit_mail'

const ConfirmMailPopin: React.FC<ConfirmMailPopinProps> = (
	{
		open,
		setOpen,
		// @ts-ignore
		confirmEdit,
		// @ts-ignore
		getArticleByShortcut
	}
) => {
	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_NAME)
	}, [])

	return (
		<Popup open={open} setOpen={setOpen} title={confirmEdit && confirmEdit.fields.title}>
			<Stack direction="column" spacing="10px">
				<Typography variant="body2" fontWeight={500} dangerouslySetInnerHTML={{ __html: (confirmEdit && confirmEdit.fields && confirmEdit.fields.body) }} />
			</Stack>
		</Popup>
	)
}

const mapStateToProps = (state: any) => ({
	confirmEdit: cardiboxSelectors.articles.getByShortcut(state.cardibox).confirm_edit_mail
})


export default connect(
	mapStateToProps,
	{
		getArticleByShortcut: cardiboxActions.articles.getByShortcut
	}
)(ConfirmMailPopin)