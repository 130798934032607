import React from 'react'
import {Box, Divider, Step, StepLabel, Stepper} from '@mui/material'
import {useBreakpoints} from './breakpoints/BreakpointsProvider'

type StepperFormProps = {
	steps: string[],
	activeStep: number,
}

const StepperForm: React.FC<StepperFormProps> = (
	{
		steps,
		activeStep
	}
) => {

	const { isMobile, isTabletPortrait } = useBreakpoints()
	const isMobOrTabVert = isMobile || isTabletPortrait


	return (
		<>
			<Box sx={{ width: '100%', minHeight: isMobOrTabVert ? '75px' : undefined }}>
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step
							key={label}
							sx={(theme) => ({
								'& .MuiStepLabel-labelContainer': isMobOrTabVert ? {
									position: 'absolute',
									width: 'auto',
									marginTop: '18px'
								} : {},
								'& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
									color: theme.palette.secondary.main,
									fontSize: '1rem',
									fontWeight: 'bold',
								},
								'& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': isMobOrTabVert ? {
									display: 'none'
								} : {
									fontWeight: 'normal'
								},
								'& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel': isMobOrTabVert ? {
									display: 'none'
								} : {},
								'& .MuiStepConnector-line' : isMobOrTabVert ? {
									width: '100%'
								} : {},
						})}
						>
							<StepLabel >
								{label}
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</Box>

			<Divider sx={{borderBottomWidth:2}}/>
		</>
	)
}

export default StepperForm
