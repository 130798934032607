import { types } from './ribActions'
import { combineReducers } from 'redux'
import ribCotisationReducer from './cotisation/ribCotisationReducers'
import { Map } from 'immutable'

const ribByIndividu = (state = Map(), action) => {
	switch (action.type) {
		case types.GET_RIB: {
			return state.set(action.payload.idIndividu, action.payload.rib)
		}
		default:
			return state
	}
}

const loadingByIndividu = (state = Map(), action) => {
	switch (action.type) {
		case types.GET_RIB_LOADING: {
			if (action.payload.loading) {
				return state.set(action.payload.idIndividu, true)
			}
			return state.remove(action.payload.idIndividu)
		}
		default:
			return state
	}
}

const expirationDateByIndividu = (state = Map(), action) => {
	switch (action.type) {
		case types.GET_RIB: {
			return state.set(action.payload.idIndividu, new Date())
		}
		default:
			return state
	}
}

export default combineReducers({
	ribByIndividu,
	loadingByIndividu,
	expirationDateByIndividu,
	cotisation: ribCotisationReducer
})
