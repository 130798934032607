export enum SIMULATOR_FIELD {
	POSTE = 'poste',
	TYPE_ACTE = 'typeActe',
	SOUS_TYPE_ACTE = 'sousTypeActe',
	AMOUNT = 'amount',
}

export enum POSTES_NAMES {
	AIDES_AUDITIVES = 'Aides auditives',
	HOSPITALISATION = 'Hospitalisation',
	OPTIQUE = 'Optique',
	DENTAIRE = 'Dentaire',
	SOINS_COURANTS = 'Soins courants',
}

export enum simulateurConsts {
	COLOR_REMB_SS = '#b5bbff',
	COLOR_REMB_GEREP = '#ed8a41',
	COLOR_RAC = '#7a7a7a',
}