import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { MODIFY_AUTHENT_FIELDS } from '../../ea/ui/personalInfo/services/personalInfoConstants'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import TextInput from '../form/TextInput'
import ButtonDefault from '../ButtonDefault'
import { ToastContext } from '../toast/ToastContext'
import { ToastType } from '../toast/toastConstants'
import RadioInput from '../form/RadioInput'
import UserService from '../../api/gerep/UserService'
import LocalStorage from '../../business/storage/LocalStorage'
import UserServiceEE from '../../api/gerepEe/UserServiceEE'
import { useBreakpoints } from '../breakpoints/BreakpointsProvider'

type ModifyAuthentProps = {
	email: string
	nextStep: () => void
	prevStep?: () => void
	currentAuthent?: string
	telephone?: string
	change?: (event: string) => void
	updateContact: (telephone: string, email: string) => void
	recuperation?: boolean
	kpi?: boolean
	textCardibox?: string
}

const ModifyAuthent: React.FC<ModifyAuthentProps> = ({
	currentAuthent,
	telephone,
	email,
	nextStep,
	prevStep,
	change,
	updateContact,
	recuperation = false,
	kpi = false,
	textCardibox
}) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [warning, setWarning] = useState<boolean>(false)
	const [authentType, setAuthentType] = useState<string>(currentAuthent || 'sms')

	const warningCheck = (value: any) => {
		if (authentType === 'sms' && telephone && value !== telephone) {
			setWarning(true)
		} else if (authentType === 'mail' && email && value !== email) {
			setWarning(true)
		} else {
			setWarning(false)
		}
	}
	const onSubmit = (values: Record<string, string>) => {
		setIsLoading(true)
		let newValues = {}
		if (authentType === 'sms') {
			updateContact(values.telephone, '')
			newValues = { telephone: values.telephone }
		} else if (authentType === 'mail') {
			updateContact('', values.email)
			newValues = { email: values.email }
		}
		return envoyerCode(newValues, LocalStorage.getToken())
			.then(() => {
				addToast(ToastType.SUCCESS, 'personalInfo.connexionInfo.modifyAuthent.success')
				nextStep()
				setIsLoading(false)
			})
			.catch((error) => {
				if (error?.data) {
					const errorMessages = Object.values(error.data as Record<string, string>)
					errorMessages.forEach(errorMessage => addToast(ToastType.ERROR, errorMessage))
				} else {
					addToast(ToastType.ERROR, 'global.error.occurred')
				}
			})
			.finally(() => setIsLoading(false))
	}

	const envoyerCode = (newValues: Record<string, any>, token: string | null) => {
		return kpi ?
			UserServiceEE.envoyerCode(newValues, token) :
			UserService.envoyerCode(newValues, token)
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={(values: any) => {
				const errors: any = {}

				if (authentType === 'sms') {
					if (!/^\d{10}$/.test(values[MODIFY_AUTHENT_FIELDS.TELEPHONE]?.replace(/\s/g, '')) ||
						!/^0[6-7]/.test(values[MODIFY_AUTHENT_FIELDS.TELEPHONE]?.substring(0, 2))) {
						errors[MODIFY_AUTHENT_FIELDS.TELEPHONE] = intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.error' })
					}
				}

				if (authentType === 'mail') {
					if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values[MODIFY_AUTHENT_FIELDS.MAIL])) {
						errors[MODIFY_AUTHENT_FIELDS.MAIL] = intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.error' })
					}
				}

				if ((authentType === 'sms' && !errors[MODIFY_AUTHENT_FIELDS.TELEPHONE]) ||
					(authentType === 'mail' && !errors[MODIFY_AUTHENT_FIELDS.MAIL])) {
					warningCheck(authentType === 'sms' ? values[MODIFY_AUTHENT_FIELDS.TELEPHONE] : values[MODIFY_AUTHENT_FIELDS.MAIL])
				}

				return errors
			}}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Stack direction="column" spacing="24px">
						{
							!recuperation && (
								<Typography variant="body2">
									{
										textCardibox ? <div dangerouslySetInnerHTML={{ __html: textCardibox }} />
											: <FormattedMessage id="personalInfo.connexionInfo.modifyAuthent.text" />
									}
								</Typography>
							)
						}
						{/*{!recuperation && !kpi ?
							(<>
								<RadioInput
									name={MODIFY_AUTHENT_FIELDS.NEW_AUTHENT}
									data={[
										{ value: 'sms', label: 'SMS' },
										{ value: 'mail', label: 'Mail' }
									]}
									onChange={
										(event: string) => {
											setAuthentType(event)
											change && change(event)
										}}
									defaultValue={currentAuthent || 'sms'}
								/>
							</>) :
							(recuperation &&
								<Typography variant="body2">
									<FormattedMessage id="doubleAuthent.recup.popin" values={{ contact: currentAuthent === 'sms' ? 'n° de téléphone' : 'email' }} />
								</Typography>
							)
						}*/}
						{authentType === 'sms' && <TextInput
							maxWidth="unset"
							name={MODIFY_AUTHENT_FIELDS.TELEPHONE}
							label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.sms' })}
							helperText={intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.helperText.sms' })}
						/>}
						{authentType === 'mail' && <TextInput
							type="email"
							maxWidth="unset"
							name={MODIFY_AUTHENT_FIELDS.MAIL}
							label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.emailAuthent' })}
							helperText={intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.helperText.mail' })}
							defaultValue={email}
							disabled={kpi}
						/>}
						{
							warning && <Typography variant="subtitle2" sx={{ color: 'red' }}>
								<b>Attention : </b>
								<FormattedMessage id={`personalInfo.connexionInfo.modifyAuthent.warningText.${authentType}`} />
							</Typography>
						}
						<Stack direction={(isMobile || isTabletPortrait) ? 'column' : 'row'} spacing="24px" display="flex" justifyContent="center">
							{recuperation && prevStep &&
								<Box display="flex" justifyContent="center">
									<ButtonDefault variant="outlined" onClick={prevStep}>
										<FormattedMessage id="global.button.cancel" />
									</ButtonDefault>
								</Box>
							}
							<ButtonDefault variant="contained" onClick={handleSubmit}>
								{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
							</ButtonDefault>
						</Stack>
					</Stack>
				</form>
			)}
		/>
	)
}
export default ModifyAuthent
