import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { ChevronRight } from '@mui/icons-material'
import { FormattedMessage, useIntl } from 'react-intl'
import RightDrawer from '../../../../../components/RightDrawer'
import Icon from '@mdi/react'
import { mdiPencil } from '@mdi/js'
import ButtonLink from '../../../../../components/ButtonLink'
import InfoField from '../../../../../components/InfoField'
import FormatUtils from '../../../../../utils/FormatUtils'
import BankActionFormPopin from './BankActionFormPopin'
import { estMajeurFinAnnee } from '../../../../../utils/dateUtils'
import { TypeAdherent } from '../../../../../constant/TypeAdherent'

type BankAccountCellProps = {
	ribInfo: any
	ribs: any[]
	individuList: any[]
	assurePrincipal: any
	haveBBAN?: boolean
	echeancier: any[]
}

const BankAccountCotisationCell: React.FC<BankAccountCellProps> = (
	{
		ribInfo,
		ribs,
		individuList,
		assurePrincipal,
		haveBBAN = true,
		echeancier
	}
) => {
	const intl = useIntl()

	const [openDrawer, setOpenDrawer] = useState<boolean>(false)
	const [openPopin, setOpenPopin] = useState<boolean>(false)

	const beneficiaires = useMemo(() => ribInfo?.beneficiaires || [], [ribInfo])
	const titleMessage = 'personalInfo.bankInfo.contribution.title'
	const noTitulaireTitleMessage = 'personalInfo.bankInfo.contribution.titleNoTitulaire'
	const isAssurePrincipal = ribInfo.numindiv !== assurePrincipal.numIndividu
	const subtitleMessage = beneficiaires.length === 0 || !haveBBAN ? 'personalInfo.bankInfo.contribution.subtitle' : 'personalInfo.bankInfo.refund.subtitle'
	const intitule: any[] = useMemo(() => beneficiaires ? beneficiaires.map((be: any) => `${be[1].prenom} ${be[1].nom}`) : [], [beneficiaires])

	const titulaireList = useMemo(() => ribs.filter((rib: any) => rib.bban).map((rib: any) => rib.titulaire), [ribs])
	const titulaireCheque = useMemo(() => ribs.filter((rib: any) => !rib.bban).map((rib: any) => rib.titulaire), [ribs])
	const titulairesRibDispo = useMemo(() => individuList
		.filter((benef) => {
			return !titulaireList.find((titulaire: any) => titulaire[1].numIndividu === benef[1].numIndividu)
		})
		.filter(beneficiaire => estMajeurFinAnnee(beneficiaire[1].dateNaissance)), [titulaireList, individuList])
	const optionsTitulairesRib = useMemo(() => {
		if (isAssurePrincipal) {
			return titulaireList
		}
		return individuList.filter(benef => benef[1].typAdr !== TypeAdherent.ASSURE_PRINCIPAL && estMajeurFinAnnee(benef[1].dateNaissance))
	}, [isAssurePrincipal, titulairesRibDispo, titulaireList, isAssurePrincipal, individuList])

	const { libNature } = ribInfo

	const titulaire = useMemo(() => ribInfo?.intitule || '', [ribInfo])

	const displayedTitleMessage = useMemo(() => titulaire ? titleMessage : noTitulaireTitleMessage, [titulaire, titleMessage])

	return (
		<>
			<ListItem disablePadding sx={{ borderTop: '1px solid #dedede' }}>
				<ListItemButton onClick={() => setOpenDrawer(true)} sx={{ paddingRight: 0, paddingLeft: 0 }}>
					<ListItemText
						primary={intl.formatMessage({ id: displayedTitleMessage }, { titulaire })}
						primaryTypographyProps={{ variant: 'h4' }}
						secondary={intl.formatMessage({ id: subtitleMessage }, {
							type: libNature?.toUpperCase(),
							beneficiaries: intitule.join(', ')
						})}
						secondaryTypographyProps={{ variant: 'subtitle1' }}
					/>
					<ListItemIcon style={{ justifyContent: 'flex-end' }}>
						<ChevronRight />
					</ListItemIcon>
				</ListItemButton>
			</ListItem>
			<RightDrawer openModal={openDrawer} setOpenModal={setOpenDrawer} title={intl.formatMessage({ id: 'personalInfo.bankInfo.title' })}>
				<Stack direction="column">
					<Typography variant="h4">
						{intl.formatMessage({ id: noTitulaireTitleMessage })}
					</Typography>
					<Typography variant="subtitle1">
						{intl.formatMessage({ id: 'personalInfo.bankInfo.contribution.subtitle' }, {
							type: libNature?.toUpperCase()
						})}
					</Typography>
				</Stack>

				<Stack direction="column" spacing="24px">
					<InfoField
						label={intl.formatMessage({ id: 'personalInfo.bankInfo.labels.titulaire' })}
						value={ribInfo?.intitule || ''}
						variantValue="body1"
					/>
					{ribInfo?.bban && ribInfo?.clefIban &&
						<InfoField
							label={intl.formatMessage({ id: 'personalInfo.bankInfo.labels.iban' })}
							value={`${ribInfo.clefIban} ${FormatUtils.formatBban(ribInfo.bban, true)}`}
							variantValue="body1"
						/>
					}
					{(ribInfo?.domiciliation && !!ribInfo?.domiciliation?.length) &&
						<InfoField
							label={intl.formatMessage({ id: 'personalInfo.bankInfo.labels.bank' })}
							value={ribInfo?.domiciliation || ''}
							variantValue="body1"
						/>
					}
					{beneficiaires && beneficiaires.length > 0 &&
						<InfoField
							label={intl.formatMessage({ id: 'personalInfo.bankInfo.labels.beneficiaires' })}
							value={
								<Stack direction="column" spacing="8px">
									{intitule.map((beneficiaire, index) => (
										<Typography flex={3} variant="body1" key={index}>
											{beneficiaire}
										</Typography>
									))}
								</Stack>
							}
						/>
					}
				</Stack>

				{echeancier && echeancier.length > 0 && haveBBAN &&
					<Box display="flex" flex={1}>
						<ButtonLink
							variant="contained"
							startIcon={<Icon size="20px" path={mdiPencil} />}
							onClick={() => setOpenPopin(true)}
						>
							<FormattedMessage id="personalInfo.bankInfo.buttons.modify" />
						</ButtonLink>
					</Box>
				}
			</RightDrawer>
			<BankActionFormPopin
				open={openPopin}
				setOpen={setOpenPopin}
				title={intl.formatMessage({ id: 'personalInfo.bankInfo.input.title.modifyCotisation' })}
				ribInfo={ribInfo}
				titulaireCheque={titulaireCheque}
				formType="modify_cotisation"
				assurePrincipal={assurePrincipal}
				individuList={individuList}
				optionsTitulairesRib={optionsTitulairesRib}
			/>
		</>
	)
}

export default BankAccountCotisationCell
