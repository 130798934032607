import {createSelector} from 'reselect'

export const santeCotisationSelector = state => state.dataKpi.santeCotisation
export const santeDemographieSelector = state => state.dataKpi.santeDemographie
export const santeConsommationSelector = state => state.dataKpi.santeConsommation
export const qualiteSelector = state => state.dataKpi.qualite
export const prevoyanceSelector = state => state.dataKpi.prevoyance
export const homePageSelector = state => state.dataKpi.homePage
export const societeSelectSelector = state => state.dataKpi.societeSelect
export const listCdcContacterGerep = state => state.dataKpi.listContacts

export const isDataKPILoading= state => state.dataKpi.loading

/**
 * Les sociétés pour lesquelles l'utilisateur est interlocuteur, triées par ordre
 * alphabétique.
 */
export const homePageOrderSelector = createSelector([
    homePageSelector
], (dataHome) => {
    if (Object.keys(dataHome).length > 1) {
        const allCompanies = dataHome.splice(1).sort((a, b) => a.societe.nom.localeCompare(b.societe.nom))
        return [...dataHome, ...allCompanies]
    }
    return dataHome
})

