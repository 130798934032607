
import {
	FETCH_REPAYMENTS_ERROR,
	FETCH_REPAYMENTS_REQUEST,
	FETCH_REPAYMENTS_SUCCESS,
	RESET_ACTIVE_FILTERS,
	SET_ACTIVE_FILTERS
} from './repaymentActions'
import moment from 'moment'
import 'moment/locale/fr'
import {recipientFiltersAll, REFUND_FILTERS_FIELDS} from '../../ui/refundList/services/remboursementConstants'

const initialState = {
	isLoading: false,
	repayments: [],
	filters: [],
	activeFilters: {
	},
}

const repayment = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACTIVE_FILTERS: {
			const toReturn = {
				...state,
				activeFilters: {
					...state.activeFilters,
					...action.payload.filters
				}
			}
			return toReturn
		}
		case RESET_ACTIVE_FILTERS: {
			return {
				...state,
				activeFilters: {
					[REFUND_FILTERS_FIELDS.DATE_DEBUT]:moment().subtract(6, 'months').toDate(),
					[REFUND_FILTERS_FIELDS.DATE_FIN]: moment().toDate(),
					[REFUND_FILTERS_FIELDS.BENEFICIARY]: recipientFiltersAll.ALL.value
				}
			}
		}
		case FETCH_REPAYMENTS_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}
		case FETCH_REPAYMENTS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				repayments: action.payload.response,
				filters: action.payload.filters,
				activeFilters: {
					[REFUND_FILTERS_FIELDS.DATE_DEBUT]:moment().subtract(6, 'months').toDate(),
					[REFUND_FILTERS_FIELDS.DATE_FIN]: moment().toDate(),
					[REFUND_FILTERS_FIELDS.BENEFICIARY]: recipientFiltersAll.ALL.value
				}
			}
		}
		case FETCH_REPAYMENTS_ERROR: {
			return {
				...state,
				isLoading: false
			}
		}
		default: {
			return state
		}
	}
}

export default repayment
