import {types} from './dataActions'
import {combineReducers} from 'redux'

const loading = (state = true, action) => {
    switch (action.type) {
        case types.LOADING:
        {
            return action.payload
        }
        default:
            return state
    }
}

const santeCotisation = (state = [], action) => {
    switch (action.type) {
        case types.SANTE_COTISATION:
        {
            return action.payload
        }
        default:
            return state
    }
}

const santeDemographie = (state = [], action) => {
    switch (action.type) {
        case types.SANTE_DEMOGRAPHIE:
        {
            return action.payload
        }
        default:
            return state
    }
}

const santeConsommation = (state = [], action) => {
    switch (action.type) {
        case types.SANTE_COMSOMMATION:
        {
            return action.payload
        }
        default:
            return state
    }
}

const qualite = (state = [], action) => {
    switch (action.type) {
        case types.QUALITE:
        {
            return action.payload
        }
        default:
            return state
    }
}

const prevoyance = (state = [], action) => {
    switch (action.type) {
        case types.PREVOYANCE:
        {
            return action.payload
        }
        default:
            return state
    }
}

const homePage = (state = [], action) => {
    switch (action.type) {
        case types.HOMEPAGE:
        {
            return action.payload
        }
        default:
            return state
    }
}

const societeSelect = (state = "", action) => {
    switch (action.type) {
        case types.SELECT_SOCIETE:
        {
            return action.payload
        }
        default:
            return state
    }
}

const listContacts = (state = "", action) => {
    switch (action.type) {
        case types.GET_LIST_CONTACTS:
        {
            return action.payload
        }
        default:
            return state
    }
}


export default combineReducers({
    loading,
    santeCotisation,
    santeDemographie,
    santeConsommation,
    qualite,
    prevoyance,
    homePage,
    societeSelect,
    listContacts
})
