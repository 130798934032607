import {flatten} from 'flat'
import appConst from '../constant/appConstants'

/* eslint-disable max-len, quotes, quote-props */

export default flatten({
    global: {
        button: {
            confirm: 'Confirmer',
            ok: 'OK',
            seeAll: 'Voir plus',
            modify: 'Modifier',
            help: 'Besoin d’aide ?',
            validate: 'Valider',
            goBackToHome: 'Revenir à l’accueil',
            continue: 'Continuer',
            close: 'Fermer',
            login: 'Se connecter',
            meLogin: 'Me connecter',
            prevStep: 'Retour'
        },
        error: {
            required: 'Champ obligatoire',
            login: 'Identifiant ou mot de passe incorrect. Vérifiez que vous avez bien saisi le n° d\'adhérent ou l\'adresse email de l\'assuré principal, ainsi que le bon mot de passe.',
            timeOut:'OUPS.... En raison d\'un grand nombre de sollicitations simultanées, votre demande ne peut aboutir. Nous vous invitons à vous reconnecter ultérieurement',
            generalCondition: 'Vous devez accepter les conditions générales d\'utilisation',
            passwordsDontMatch: 'La confirmation de mot de passe est différente',
            emailsDontMatch: 'L\'adresse email saisie ne correspond pas',
            format: 'Format incorrect',
            phoneFormat: 'Le numéro de téléphone saisi doit être celui d\'un téléphone portable.',
            occurred: 'Une erreur est survenue',
            noPersonalInfoEdited: 'Echec de la modification des informations personnelles. Aucune donnée n\'a été modifiée',
            personInfoFailed: 'Echec de la modification des informations personnelles',
            invalidEmail: 'Adresse email invalide',
            validEmail: 'La confirmation d\'email est différente',
            matchingEmail: 'Veuillez choisir une adresse mail différente',
            passwordsNotMatch: 'La confirmation de mot de passe est différente',
            dateTooOld: 'Date invalide. Veuillez choisir une date plus récente',
            dateTooFar: 'Veuillez choisir une date plus ancienne',
            sizeTooHeavy: 'Fichier trop lourd (Ne doit pas excéder ' + appConst.fileUpload.maxSizMo + 'Mo)',
            totalSizeTooHeavy: 'Taille totale des pièces jointes supérieure à 10Mo',
            fieldRequired: 'Modifiez au moins un champ',
            invalidDate: 'Date invalide',
            chooseOption: 'Veuillez choisir une option',
            dateMin: 'Veuillez saisir une date après le {date}',
            dateBeforeToday: 'Date antérieure à la date du jour',
            invalidPhone: 'Numéro de téléphone invalide',
            invalidNiss: 'Veuillez saisir un numéro de sécurité sociale valide',
            newpasswordbadFormat: 'Votre mot de passe doit être constitué d\'au moins 12 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial (#?!@$%^&*-\\;/_).',
            badLengthPassword: 'Entre 12 et 32 caractères',
            needUppercaseLetter: 'Une majuscule',
            needLowercaseLetter: 'Une minuscule',
            needDigit: 'Un chiffre',
            needSpecialSymbol: 'Un caractère spécial (#?!@$%^&*-\\;/_)',
            downloadObligation: 'Veuillez télécharger le fichier pour continuer'
        },
        input: {
            yes: 'Oui',
            no: 'Non'
        },
        mandatory: '* : Champs obligatoires',
        success: 'Enregistrement de votre demande effectuée avec succès'
    },
    forceResetPassword: {
        newPassword: 'Nouveau mot de passe',
        confirmPassword: 'Confirmation mot de passe'
    },
    login: {
        resetPassword: {
            tokenInvalid: {
                title: 'Réinitialisation mon mot de passe',
                body: 'Le lien sur lequel vous avez cliqué n\'est plus valide.'
            }
        },
        tabs: {
            civil: 'Espace Assuré',
            professional: 'Espace Entreprise'
        },
        form: {
            title: 'Connexion Espace Assuré',
            titleEE: 'Connexion Espace Entreprise',
            forgottenPassword: {
                title: 'Vous avez oublié votre mot de passe ?',
                text: 'Pour réinitialiser votre mot de passe, veuillez saisir votre adresse e-mail, votre numéro d\'assuré ainsi que votre date de naissance.'
            },
            login: {
                label: 'Votre identifiant',
                labelEE: 'Identifiant',
                info: 'N° adhérent ou adresse e-mail',
                infoEE: 'Adresse e-mail',
                popover1: 'Pour vous identifier, indiquez soit votre N° adhérent, soit votre adresse email.\n' +
                    'Vous trouverez votre N° d\'adhérent en haut à gauche de votre carte de tiers-payant.',
                popover2: 'Seul l\'adhérent principal peut se connecter sur cet espace avec ses identifiants.',
                popoverEE1: 'Pour vous identifier, indiquez votre adresse email.'
            },
            signupEE: {
                title: 'Inscription sur l\'Espace Entreprise',
                subtitle: 'Par mesure de sécurité, tous les champs ci-dessous sont obligatoires pour activer votre compte.',
                lastName: {
                    label: 'Nom',
                    placeholder: 'Entrez votre nom'
                },
                firstName: {
                    label: 'Prénom',
                    placeholder: 'Entrez votre prénom'
                },
                email: {
                    label: 'Identifiant',
                    placeholder: 'exemple@mail.fr',
                    info: 'Indiquez votre adresse e-mail professionnelle transmise à Gerep.'
                },
                password: {
                    label: 'Mot de passe',
                    info: 'Votre mot de passe doit être constitué d\'au moins 12 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial (#?!@$%^&*-\\;/_).'
                },
                confirmPassword: 'Confirmez votre mot de passe',
                confirmEmail: 'Confirmez votre adresse email',
                termsAndConditions: {
                    newsletter: 'J\'accepte de recevoir des informations commerciales de la part de Gerep. En aucun cas mon adresse email ne sera cédée à des tiers.',
                    generalUse: {
                        link: 'Conditions générales d\'utilisation',
                        body: 'Je certifie avoir pris connaissance des {link} et je les accepte.'
                    }
                }
            },
            signup: {
                title: 'Inscription sur l\'Espace Assuré',
                subtitle: 'Par mesure de sécurité, tous les champs ci-dessous sont obligatoires pour activer votre compte.',
                insuranceNumber: {
                    label: 'Numéro d\'adhérent ',
                    placeholder: 'XXXXXXXXX',
                    info: 'Vous trouverez votre numéro d’adhérent sur l’email de Bienvenue que vous avez reçu ou en haut à gauche de votre carte de tiers-payant'
                },
                lastName: {
                    label: 'Nom',
                    placeholder: 'Entrez votre nom'
                },
                firstName: {
                    label: 'Prénom',
                    placeholder: 'Entrez votre prénom'
                },
                socialSecurityNumber: {
                    label: 'Numéro de sécurité sociale',
                    placeholder: 'X-XX-XX-XXXXX-XXXXX-XX',
                    info: 'Votre numéro de sécurité sociale est constitué de 15 chiffres et se trouve sur votre carte vitale.'
                },
                birthDate: {
                    label: 'Date de naissance',
                    placeholder: 'JJ/MM/AAAA'
                },
                email: {
                    label: 'Adresse email',
                    placeholder: 'exemple@mail.fr',
                    info: 'Veuillez saisir l’adresse email de l’assuré principal. Cette adresse email sera utilisée pour toutes les notifications et correspondances ultérieures.'
                },
                password: {
                    label: 'Mot de passe',
                    info: 'Votre mot de passe doit être constitué d\'au moins 12 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial (#?!@$%^&*-\\;/_).'
                },
                confirmPassword: 'Confirmez votre mot de passe',
                confirmEmail: 'Confirmez votre adresse email',
                termsAndConditions: {
                    newsletter: 'J\'accepte de recevoir des informations commerciales de la part de Gerep. En aucun cas mon adresse email ne sera cédée à des tiers.',
                    generalUse: {
                        link: 'Conditions générales d\'utilisation',
                        body: 'Je certifie avoir pris connaissance des {link} et je les accepte.'
                    }
                }
            },
            password: 'Votre mot de passe',
            rememberMe: 'Rester connecté',
            forgotPassword: 'Mot de passe oublié ?',
            passwordRulesTitle: 'Votre mot de passe doit contenir :'
        },
        firstVisit: {
            title: 'Première visite ?',
            text: 'Vous êtes adhérent à un contrat santé Gerep, et vous souhaitez accéder à votre Espace Assuré pour la première fois. Rien de plus simple : il suffit d\'activer votre compte.'
        },
        firstVisitEE: {
            title: 'Première visite ?',
            text: 'Vous représentez une entreprise cliente de Gerep, et vous souhaitez accéder à votre Espace Entreprise pour la première fois. Rien de plus simple : il suffit de vous inscrire.'
        },
        title: 'Bienvenue sur votre Espace Assuré !',
        subtitle: 'En quelques clics, sur votre Espace Assuré Gerep : \n' +
            '\u3164 \u2022 Consultez vos remboursements et garanties\n' +
            '\u3164 \u2022 Gérez votre compte et vos bénéficiaires\n' +
            '\u3164 \u2022 Transmettez-nous vos documents\n' +
            '\u3164 \u2022 Téléchargez votre carte de tiers-payant et effectuez\n' +
            '\u3164\u3164 vos démarches en ligne',
        button: {
            login: 'Connexion',
            activateAccount: 'Activer mon compte',
            inscription: 'S\'inscrire',
            resetPassword: 'Réinitialiser mon mot de passe'
        },
        popinToApp: {
            title: 'Redirection',
            appDetected: 'L\'application mobile est installée sur votre mobile. Souhaitez-vous être redirigé ?',
            goToGerepMobile: 'Ouvrir l\'application',
            openPlayStore: 'Ouvrir le Play Store',
            openAppStore: 'Ouvrir l\'App Store',
            stayWeb: 'Rester sur le Web'
        }
    },
    doubleAuthent: {
        title: 'Authentification sécurisée',
        confirmation: {
            label: {
                sms: 'Un code de validation a été envoyé au {contact}',
                mail: 'Un code de validation a été envoyé à {contact}',
                code: 'Veuillez saisir le code reçu.'
            },
            success: 'Double authentification réussie',
            renvoi: 'Je n\'ai pas reçu le code',
            button: {
                valider: 'Valider'
            },
            blocage: 'Votre compte est bloqué pendant {number}.'
        },
        success: 'Le code vous a été envoyé'
    },
    form: {
        select: {
            placeholder: 'Choisissez'
        },
        date: {
            title: 'Date'
        },
        fileinput: {
            content: 'Merci de déposer votre justificatif ci-dessous :',
            maxSize: 'Taille maxi. : {size} Mo',
            acceptedFormat: 'Formats : {formats}',
            maxFiles: `{files} {files, plural, =1 {fichier} other {fichiers}} maximum`,
            button: {
                testTitle: 'Relevé d\'une autre complémentaire *',
                webSubtitle: 'Glissez/déposez ici les fichiers, vous pouvez aussi choisir un fichier'
            },
            error: {
                maxFiles: 'Nombre maximal de fichiers atteint',
                noSelected: 'Veuillez sélectionner un fichier',
                maxSize: 'La taille maximum acceptée par fichier est {size} Mo.',
                acceptedFormats: 'Les formats acceptés sont : {formats}.'
            }
        }
    },
    cookies: {
        content: 'En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de cookies permettant son bon fonctionnement et la réalisation de statistiques d\'utilisation.',
        buttons: {
            accept: 'J\'accepte',
            decline: 'Je refuse',
            info: 'En savoir plus et gérer mes cookies',
            manage: 'Gérer mes cookies'
        },
        popup: {
            title: 'Gestion de vos préférences sur les cookies',
            text: 'En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de traceurs (cookies) afin de sécuriser votre connexion au site et de réaliser des statistiques anonymes de visites ou vous proposer des contenus et services adaptés à vos centres d\'intérêts.\n' +
                '\n' +
                'Pour en savoir plus et paramétrer les traceurs, cliquez sur ce lien {link}.',
            whatIsCookie: {
                title: 'Qu\'est-ce qu\'un cookie ?',
                text: 'Les cookies sont des fichiers texte qui sont envoyés à votre outil informatique lorsque vous naviguez sur un site puis stockés sur un fichier de votre outil informatique par votre navigateur. Lors de chaque visite, les cookies sont alors renvoyés au site visité, ou à tout autre site qui reconnait ce cookie. Les cookies se comportent comme une « mémoire » pour le site internet, lui permettant de reconnaître votre outil informatique lorsque vous revenez sur ce site.'
            },
            cookieUsed: {
                title: 'Cookies utilisés par ce site',
                text: 'Le site que vous visitez utilise des traceurs (cookies). Ainsi, le site est susceptible d\'accéder à des informations déjà stockées dans votre équipement terminal de communications électroniques et d\'y inscrire des informations.\n' +
                    '\n' +
                    'Les données recueillies ne sont réservées qu’à un usage interne. Elles sont stockées en France et ne sont pas destinées à être revendues.\n' +
                    'Les traceurs utilisés par le site relèvent de deux catégories :\n' +
                    '\u3164 \u2022  traceurs dits strictement nécessaires, qui ne nécessitent pas votre consentement préalable,\n' +
                    '\u3164 \u2022 autres traceurs soumis à votre consentement préalable.'
            },
            noConsentTrackers: {
                title: '1 - Traceurs exemptés de consentement préalable',
                text: 'Nous utilisons ces traceurs pour permettre et faciliter la navigation sur le site notamment en mémorisant vos préférences de navigation définies au cours de votre session.\n' +
                    'Ces traceurs ne peuvent pas, techniquement, être désactivés depuis le site.'
            },
            consentTrackers: {
                title: '2 - Traceurs soumis à votre consentement préalable',
                text: 'Le site utilise des traceurs sous notre contrôle et d\'autres placés sous le contrôle de tiers qui sont soumis à votre consentement préalable.\n',
                underControl: {
                    title: 'Traceurs sous notre contrôle',
                    text: 'Nous utilisons ces traceurs pour recueillir des statistiques. Ces cookies statistiques nous permettent de connaître l\'utilisation et les performances du site dans un but d’amélioration de nos services.\n'
                },
                survey: {
                    title: 'J\'accepte l\'utilisation des cookies statistique\n',
                    text: 'Vos préférences de gestion des cookies peuvent être modifiées à tout moment dans la rubrique « Mon compte » puis « Gérer mes cookies ».\n'
                },
                thirdParty: {
                    title: 'Traceurs placés sous le contrôle de tiers',
                    text: 'Des cookies de sites tiers peuvent être installés par des services qui apparaissent sur ce site tiers.\n' +
                        '\n' +
                        'Ces cookies ne sont pas sous le contrôle du site visité. Pour plus d’information sur ces cookies Tierce partie, nous vous invitons à visiter le site internet tiers.\n' +
                        '\n' +
                        'L\'utilisation des traceurs est régie par l\'article 32 II de la loi n° 78-17 du 6 janvier 1978, transposant l\'article 5.3 de la directive 2002/58/CE du parlement européen et du conseil du 12 juillet 2002 modifiée par la directive 2009/136/CE.\n' +
                        '\n' +
                        'Pour en savoir plus sur les cookies et traceurs, nous vous invitons à consulter le site de la CNIL : {link}'
                }
            }
        }
    },
    codeError: {
        notFound: {
            title: 'Erreur 404',
            text: 'Oups, cette page n\'est malheureusement pas disponible. Peut-être qu\'une de ces pages vous sera utile :'
        },
        unauthorized: {
            title: 'Erreur 401',
            text: 'Oups, vous n\'êtes pas autorisé à accéder à cette page.'
        },
        buttons: {
            home: 'Retour à mon tableau de bord',
            help: 'Consulter l\'aide',
            refund: 'Consulter mes remboursements'
        }
    },
    internalError: {
        title: 'Erreur 500',
        text: {
            1: 'L\'accès à vos données est momentanément indisponible.',
            2: 'Nous vous prions de nous excuser pour la gêne occasionnée.',
            3: 'Merci de ré-essayer ultérieurement.'
        },
        altText: {
            1: 'Une erreur technique est survenue.',
            2: 'Nous allons régler ce souci. Merci de réessayer ultérieurement.',
            3: 'Si le problème persiste, vous pouvez nous contacter {link}.'
        },
        buttons: {
            home: 'Retour à mon tableau de bord'
        }
    },
    personalInfo: {
        connexionInfo: {
            resetPassword: {
                title: 'Réinitialiser mon mot de passe',
                success: 'Votre mot de passe a bien été réinitialisé'
            },
            labels: {
                email: 'Email',
                password: 'Mot de passe',
                numAssure: 'N°Assuré',
                newEmail: 'Nouvel email',
                confirmNewMail: 'Confirmer nouvel email',
                oldPassword: 'Ancien mot de passe',
                newPassword: 'Nouveau mot de passe',
                confirmNewPassword: 'Confirmez votre mot de passe'
            }
        }
    },
    contrat: {
        title: 'Mes contrats et garanties',
        subtitle: 'Rappel de vos modalités de réglement',
        download: 'Télécharger mes garanties',
        contratNotAvailable: {
            title: 'Mes contrats et garanties',
            body: 'Le descriptif de vos garanties n\'est actuellement pas disponible.'
        },
        beneficaire: {
            title: 'Bénéficiaires'
        },
        footer: {
            buttonInfoContrat: 'Plus d’informations au sujet de votre contrat',
            buttonInfoBeneficiaire: 'Voir les informations sur mes bénéficiaires',
            buttonDlAttestation: 'Télécharger une attestation d\'affiliation'
        }
    },
    souscription: {
        title: 'Souscrire une option',
        gestionLink: 'Guide de gestion',
        steps: {
            1: 'Choix de l\'option',
            2: 'Mode de règlement',
            3: 'Récapitulatif et validation'
        },
        error: {
            optionRequired: 'Vous devez au moins choisir une option pour continuer'
        },
        legends: {
            1: '* {text}',
            2: '** Durée minimale de votre adhésion à l’option, sauf événements indiqués dans le guide de gestion'
        },
        selectOption: {
            title: 'Sélectionnez une option',
            dates: {
                beginAndEnd: 'Date d\'effet : {dateDebut} - Date limite de souscription {dateFin}',
                dateDebut: 'Date d\'effet : {dateDebut}',
                dateFin: 'Date limite de souscription {dateFin}'
            },
            dureeEngagement: '{dureeEngagement, plural, =0{Sans engagement} =1{Engagement # mois **} other{Engagement # mois **}}',
            libellePrix: '{libellePrix} *',
            prix: '{prix} € / mois *',
            beneficiaire: 'Bénéficiaire(s)',
            button: {
                details: 'Voir le détail'
            },
            ulteriorIban: 'Si vous le souhaitez, vous pourrez ultérieurement déclarer un autre compte bancaire dédié au ' +
                'prélèvement de votre cotisation sur l\'Espace Assuré. Dès traitement complet de votre affiliation, ' +
                'vous recevrez vos identifiants pour vous connecter à l\'Espace Assuré.'
        },
        step3: {
            title: 'Récapitulatif de votre demande d\'adhésion',
            info: {
                mode: {
                    title: 'Mode de règlement',
                    prelevement: 'Prélèvement bancaire',
                    salary: 'Prélèvement sur salaire'
                },
                datePrelev: 'Date de prélèvement',
                titulaire: 'Titulaire du compte',
                iban: 'IBAN',
                bank: 'Banque',
                beneficiaires: 'Bénéficiaire(s)',
                dateEffet: 'Date d\'effet',
                cout: 'Coût',
                acceptConditions: 'Je déclare avoir pris connaissance des {link}',
                conditions: 'conditions d\'entrée et de sortie à l\'option détaillée dans le Guide de gestion'
            }
        },
        noGaranties: {
            main: {
                title: 'Actuellement, vous ne pouvez pas souscrire de nouvelles options car vous vous trouvez dans l’une des situations suivantes :',
                1: 'vous avez déjà souscrit une option',
                2: 'vous avez dépassé le délai pour souscrire à une nouvelle option',
                3: 'vous avez résilié une option et le délai d’attente pour souscrire à nouveau n’est pas terminé',
                4: 'la souscription à l’option n’est pas encore ouverte'
            },
            subtext: 'Si vous avez changé de situation de famille et que vous souhaitez souscrire à une option, nous vous invitons à prendre contact avec nous, une fois votre situation mise à jour sur votre Espace Assuré.'
        },
        dateLimite: 'Date limite de souscription: {dateLimite, date, short}',
        datePrelevement: {
            1: 'le {echeance} de chaque mois',
            3: 'le {echeance} de chaque trimestre',
            6: 'le {echeance} de chaque semestre',
            12: 'le {echeance} de chaque année'
        },
        engagement: '{dureeEngagement, plural, =0{Sans engagement} =1{Engagement # mois} other{Engagement # mois}}',
        errors: {
            tooManyBeneficiairies: 'Vous devez sélectionner {nbBeneMax, plural, =1{un bénéficiaire} other{# bénéficiaires}} pour cette option'
        },
        mode: {
            confirm: 'Confirmer les coordonnées bancaires associées à vos cotisations',
            editRib: 'Si vous souhaitez que votre cotisation soit prélevée sur un autre compte bancaire, cliquer sur Modifier'
        },
        confirmation: {
            body: 'Votre demande de souscription a bien été prise en compte.{br}Un email de confirmation vous sera adressé dès enregistrement de votre demande.'
        },
        form: {
            error: {
                options: 'Veuillez sélectionner une option pour passer à l\'étape suivante',
                tooManyOptions: 'Vous ne pouvez sélectionner qu\'une seule option',
                beneficiaires: 'Veuillez sélectionner au moins un bénéficiaire',
                tooManyBeneficiairies: 'Vous devez sélectionner {nbBeneMax, plural, =1{un bénéficiaire} other{# bénéficiaires}} pour cette option',
                iban: 'IBAN invalide',
                acceptPrelev: 'Veuillez accepter les modalités de règlement pour passer à l’étape suivante',
                acceptSepa: 'Veuillez valider le mandat SEPA pour passer à l’étape suivante',
                acceptConditions: 'Veuillez confirmer avoir pris connaissance des conditions de souscription à l’option'
            }
        },
        input: {
            iban: 'IBAN *',
            bank: 'Banque *',
            titulaire: 'Titulaire du compte *',
            rib: {
                label: 'Merci de joindre votre RIB',
                title: 'RIB'
            },
            libelleMandatSepa: {
                sepa: 'le mandat de prélèvement SEPA',
                prelevementSalaire: 'J\'accepte que les cotisations associées à cette nouvelle option soient prélevées selon les modalités de règlement ci-dessus.',
                modifyCoord: 'Je valide {link} autorisant Gerep à présenter des ordres de prélèvement sur le compte bancaire ci-dessus, pour les cotisations à cette nouvelle option.',
                default: 'Je valide le mandat de prélèvement SEPA autorisant Gerep à présenter des ordres de prélèvement sur le compte bancaire ci-dessus, pour les cotisations à cette nouvelle option.'
            }
        },
        buttons: {
            previousStep: 'Etape précédente',
            nextStep: 'Etape suivante',
            validate: 'Valider ma demande',
            goHome: 'Retour à l\'accueil'
        }
    },
    preaffiliation: {
        title: 'Bienvenue dans l\'espace d\'affiliation en ligne Gerep ',
        company: 'Société contractante : ',
        category: 'Catégorie : ',
        error: {
            birthNissCheck: 'La date de naissance est incohérente avec le numéro de sécurité sociale',
            beforeDateDebutMin: `Cette date ne peut pas être antérieure au {dateDebutMin}`,
            afterDateDebutMax: `Cette date ne peut pas être postérieure au {dateDebutMax}`,
            alreadyAffiliated: 'Votre accès à l\'Espace Affiliation n\'est plus valide : vous avez déjà enregistré une affiliation.',
            wrongCode: 'Le code d\'accès saisi ne vous permet pas d\'accéder à cet espace d\'affiliation. Nous vous invitons à contacter votre service du personnel.',
            invalidCode: 'Code d\'accès invalide',
            global: 'Une erreur est survenue'
        },
        connexion: {
            title: 'Connexion à l\'Espace Affiliation',
            helper: 'Renseignez ci-dessous le code d’accès que vous avez reçu.',
            code: 'Code d\'accès',
            forget: 'Code d\'accès oublié ?',
            popin: {
                title: 'Code d’accès oublié',
                body: 'Votre code d’accès vous a été transmis, par mail, par le service du personnel ' +
                    'de votre entreprise (en même temps que l’URL d’accès à cet espace).\n \n' +
                    'Contactez votre service du personnel pour obtenir à nouveau ce code d’accès.'
            }
        },
        identification: {
            title: 'Identification',
            form: {
                company: {
                    label: 'Votre entreprise',
                    placeholder: 'Entrez votre entreprise'
                },
                category: {
                    label: 'Votre catégorie',
                    placeholder: 'Entrez votre catégorie bénéficiaire'
                },
                civility: {
                    label: 'Civilité',
                    placeholder: 'Choississez'
                },
                sex: {
                    label: 'Sexe',
                    placeholder: 'Choississez'
                },
                lastName: {
                    label: 'Nom',
                    placeholder: 'Entrez votre nom'
                },
                firstName: {
                    label: 'Prénom',
                    placeholder: 'Entrez votre prénom'
                },
                NSS: {
                    label: 'N° de Sécurité sociale',
                    placeholder: '0 X0 X0 X0 X0X 0X0 X0',
                    info: 'Veuillez saisir les 15 chiffres de votre numéro de sécurité sociale, présent sur le recto de votre carte vitale'
                },
                birthDate: {
                    label: 'Date de naissance',
                    placeholder: 'JJ/MM/AAAA'
                },
                termsConditions: {
                    cntrManagement: 'En soumettant ce formulaire, j\'accepte que les informations saisies pour mon affiliation soient utilisées par Gerep, dans le cadre de la gestion de mon contrat Frais de Santé. *',
                    generalUse: {
                        body: 'Je certifie avoir pris connaissance des {link}, et je les accepte. *',
                        link: 'Conditions Générales d\'Utilisation'
                    }
                }
            },
            popin: {
                title: 'Affiliation en ligne',
                quit: 'Quitter l\'Espace Affiliation',
                go_espace: 'Accéder à l\'Espace Assuré',
                helper: 'Votre N° d\'adhérent : {numAssure} vous permettra de vous inscrire sur l\'Espace Assuré, puis de vous connecter.'
            }
        },
        personalInfos: {
            title: 'Informations personnelles',
            form: {
                attestation: {
                    title: 'Attestation de Sécurité Sociale',
                    label: 'Merci de joindre une attestation de Sécurité sociale :  *',
                    link: 'Télécharger mon attestation de Sécurité sociale sur Ameli.fr'
                },
                teletransmission: {
                    label: 'Activer la télétransmission : *',
                    helper: 'La télétransmission est l’envoi informatisé des décomptes de remboursement de la sécurité sociale vers votre complémentaire santé Gerep. '
                },
                adress: {
                    label: 'Adresse',
                    placeholder: 'Entrez votre adresse',
                    label2: 'Adresse 2',
                    placeholder2: 'Entrer le complément d\'adresse (Bâtiment, Etage, Porte,...)',
                    label3: 'Adresse 3'
                },
                zipCode: {
                    label: 'Code Postal',
                    placeholder: '22 444'
                },
                city: {
                    label: 'Ville',
                    placeholder: 'Entrez votre ville'
                },
                country: {
                    label: 'Pays',
                    placeholder: 'Entrez votre pays'
                },
                phone: {
                    label: 'Téléphone portable',
                    placeholder: '00 11 22 33 44',
                    helper: 'Pour simplifier la gestion de votre compte, renseignez votre e-mail et votre téléphone personnels.'
                },
                mail: {
                    label: 'Adresse e-mail',
                    placeholder: 'Entrez votre adresse mail'
                }
            }
        },
        bankInfos: {
            title: 'Coordonnées bancaires',
            form: {
                IBAN: {
                    label: 'IBAN',
                    placeholder: 'FRX0 X0X0 X0X0 X0X0 X0X0 X0X0 X0X',
                    helpertext: 'Cette référence figure obligatoirement sur votre relevé d\'identité bancaire.'
                },
                bank: {
                    label: 'Banque',
                    placeholder: 'Entrez votre banque'
                },
                owner: {
                    label: 'Titulaire du compte',
                    placeholder: 'Nom et Prénom du titulaire'
                }
            }
        },
        cntrtAndBenef: {
            title: 'Contrat et bénéficiaires',
            contrat: {
                title: 'Votre contrat "Frais de santé"',
                coverage: 'Votre couverture',
                start: 'Date de début d\'affiliation',
                info: 'Si votre contrat prévoit une option, celle-ci vous sera proposée après validation de la couverture de base (étape 5).'
            },
            beneficiary: {
                name: 'beneficiaires',
                title: 'Vos bénéficiaires',
                addbenef: ' Je souhaite déclarer des bénéficiaires supplémentaires sur mon contrat (conjoint, enfant, ...) ',
                addmore: 'Ajouter un autre béneficiaire',
                delete: 'Retirer ce bénéficiaire',
                form: {
                    type: {
                        label: 'Type de bénéficiaire'
                    },
                    coverage: {
                        label: 'Sa couverture'
                    },
                    civility: {
                        label: 'Civilité',
                        placeholder: 'Choississez'
                    },
                    sex: {
                        label: 'Sexe',
                        placeholder: 'Choississez'
                    },
                    lastName: {
                        label: 'Nom',
                        placeholder: 'Entrez son nom'
                    },
                    firstName: {
                        label: 'Prénom',
                        placeholder: 'Entrez son prénom'
                    },
                    NSS: {
                        label: 'N° de Sécurité sociale',
                        placeholder: '0 X0 X0 X0 X0X 0X0 X0',
                        info: 'Veuillez saisir les 15 chiffres de son numéro de sécurité sociale, présent sur le recto de sa carte vitale',
                        warning: 'Le N° de Sécurité sociale à renseigner pour un enfant mineur doit être celui du parent auquel l\'enfant est rattaché',
                        label2: 'Second N° de Sécurité sociale',
                        info2: 'À renseigner si votre enfant est également rattaché sur le n° de Sécurité sociale de votre conjoint(e)'
                    },
                    birthDate: {
                        label: 'Date de naissance',
                        placeholder: 'JJ/MM/AAAA',
                        minorError: 'La date de naissance du bénéficiaire doit correspondre à celle d\'un enfant âgé de moins de 18 ans',
                        notMinorError: 'La date de naissance du bénéficiaire doit correspondre à celle d\'une personne âgée de plus de 18 ans'
                    },
                    attestation: {
                        title: 'Attestation de Sécurité Sociale',
                        label: 'Merci de joindre une attestation de Sécurité sociale :  *',
                        link: 'Télécharger mon attestation de Sécurité sociale sur Ameli.fr'
                    },
                    files: {
                        title_2_B: 'Certificat de scolarité\n ou Contrat d’apprentissage, d’alternance ou professionnalisation\n ou Justificatif d’inscription à Pôle Emploi',
                        label_2_B: 'Merci de joindre le justificatif correspondant à la situation de votre enfant :',
                        title_3: 'Justificatif de domicile commun',
                        label_3: 'Merci de joindre un justificatif de domicile commun :',
                        title_7: 'Convention ou Récépissé d\'enregistrement du PACS',
                        label_7: 'Merci de joindre la convention ou le récépissé d\'enregistrement du PACS :'
                    },
                    teletransmission: {
                        label: 'Activer la télétransmission : *',
                        helper: 'La télétransmission est l’envoi informatisé des décomptes de remboursement de la sécurité sociale vers votre complémentaire santé Gerep. '
                    }
                }
            }
        },
        confirmation: {
            title: 'Confirmation',
            bornThe: 'Né(e) le',
            teletransmission: 'Télétransmission',
            contract: {
                garanties: 'Télécharger les garanties',
                title: 'Votre contrat "Frais de santé"',
                company: 'Entreprise',
                category: 'Catégorie bénéficiaire',
                effectDate: 'Date d\'effet affiliation',
                coverage: 'Couverture'
            },
            personal: {
                title: 'Vos informations personnelles',
                mail: 'E-mail'
            },
            bank: {
                title: 'Vos coordonnées bancaires',
                owner: 'Titulaire'
            },
            beneficiary: {
                title: 'Vos bénéficiaires',
                coverlabel: '\n (libellé de sa couverture)'
            },
            popin: {
                title: 'Affiliation en ligne',
                question: 'Souhaitez-vous compléter votre couverture avec une option  ? *',
                endNow: 'Quitter'
            }
        },
        dispense: {
            question : 'Souhaitez-vous vous dispenser ?',
            dispenseChoiceStep: {
                title : 'Choix du motif de dispense',
                form: {
                    title: 'Choisissez la situation justifiant votre demande de dispense',
                    moreOptions: 'Voir plus de situations',
                    lessOptions: 'Voir moins de situations',
                    noOptions: 'Si aucune situation ne vous correspond, vous n’êtes pas éligible à la dispense. Pour reprendre votre affiliation à la complémentaire santé, ',
                    clickHere: 'cliquez ici',
                    startDate : {
                        label: 'Date de début de la dispense',
                        helper: 'La date de début de la dispense correspond soit à la date de début de votre contrat de travail, soit à la date à laquelle vous bénéficiez d’une autre couverture santé en tant qu’ayant droit.'
                    },
                    organisme: {
                        label: 'Nom de l\'organisme chargé de la couverture',
                        helper: 'Il s’agit du nom de l’organisme qui est chargé de vous couvrir au titre du régime complémentaire Frais de santé (mutuelle de votre conjoint ou souscrite individuellement)'
                    },
                    endDate: {
                        label: 'Date de fin de la dispense',
                        errorBefore: 'Cette date ne peut pas être antérieure au {dateFinDispenseMin}',
                        errorAfter: 'Cette date ne peut pas être postérieure au {dateFinDispenseMax}'
                    }
                }
            },
            confirmation: {
                title: 'Votre dispense',
                company: 'Entreprise',
                category: 'Catégorie bénéficiaire',
                reason: 'Motif de dispense',
                start: 'Début de la dispense',
                organism: 'Organisme',
                end: 'Fin de la dispense',
                informYourBoss: 'Par la présente, je m’engage à informer mon employeur de tout changement de situation ayant un impact sur cette dispense',
                choiceConsequencies: 'Je reconnais avoir été informé(e) préalablement par mon employeur des ' +
                    'conséquences de mon choix notamment en ce qui concerne la portabilité et j’ai bien pris ' +
                    'en considération que je ne bénéficierai pas de la contribution patronale et des prestations ' +
                    'de ce régime de "remboursement de frais de santé"',
                validButton: 'Valider ma dispense'
            },
            popin: {
                title: 'Dispense d’affiliation',
                body: 'Votre demande de dispense a bien été prise en compte.\n\n' +
                    'Nous vous invitons à télécharger votre Attestation sur l’honneur de dispense d’affiliation à la complémentaire santé et à la transmettre à votre employeur.',
                download: 'Attestation sur l’honneur de dispense d’affiliation à la complémentaire santé',
                honorEngagement: 'Je m’engage à transmettre l\'Attestation sur l’honneur de dispense d’affiliation à la complémentaire santé à mon employeur.',
                terminado: 'Terminer'
            }
        },
        nextStep: 'Étape suivante',
        previousStep: 'Étape précédente',
        validateAffiliation: 'Valider mon affiliation',
        requiredFields: '* : Champs obligatoires',
        rgpd: 'Je certifie l’exactitude et la sincérité des déclarations ci-dessus qui serviront ' +
            'de base à l’affiliation à intervenir et je reconnais que toute réticence ou fausse ' +
            'déclaration intentionnelle entraînera la nullité de l’affiliation, conformément au ' +
            'contrat souscrit par l’Entreprise auprès de l’organisme assureur.\n ' +
            'La loi et le règlement européen relatifs à la protection des données personnelles ' +
            '(RGPD) et secret professionnel : les informations nominatives recueillies dans le ' +
            'cadre du présent contrat et ultérieurement sont destinées à votre organisme d’assurances, ' +
            'qui de convention expresse, est autorisé à les conserver en mémoires informatiques, à les ' +
            'utiliser, ainsi qu’à les communiquer aux mêmes fins aux sociétés de son groupe, au courtier ' +
            'GEREP, voire à des tiers ou à des sous-traitants pour des besoins de gestion. Les droits ' +
            'd’accès, de rectification et de conservation peuvent être exercés auprès du service ayant ' +
            'recueilli ces informations. Par la validation de cette demande d’affiliation, vous acceptez ' +
            'expressément que les données vous concernant leur soient transmises.\n' +
            'Détails disponibles à cette adresse : ',
        rgpdlink: 'http://www.gerep.fr/protection-donnees.html'
    }
})
