import { GET_MENTIONS_LEGALES, GET_MENTIONS_LEGALES_LOADING } from '../servicesSanteActionTypes'
import { combineReducers } from 'redux'
import configureCache from '../../../../utils/cache'
import appConst from '../../../../constant/appConstants'

const _cache = configureCache(appConst.cacheDuration.default)

const data = (state = null, action) => {
	switch (action.type) {
		case GET_MENTIONS_LEGALES:
			return action.payload
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case GET_MENTIONS_LEGALES_LOADING:
			return action.payload
		case GET_MENTIONS_LEGALES:
			return false
		default:
			return state
	}
}

const expirationDate = (state = 0, action) => {
	switch (action.type) {
		case GET_MENTIONS_LEGALES:
			return _cache.mark()
		default:
			return state
	}
}

export default combineReducers({
	data,
	loading,
	expirationDate
})
