import _ from 'lodash'
import {repaymentFilters} from '../../ui/refundList/services/remboursementConstants'
import LocalStorage from '../../../business/storage/LocalStorage'
import RemboursementsService from '../../../api/gerep/RemboursementsService'
import moment from 'moment'


export const SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS'
export const setActiveFilters = ({ filters}) => dispatch => dispatch({ type: SET_ACTIVE_FILTERS, payload: { filters } })

export const RESET_ACTIVE_FILTERS = 'RESET_ACTIVE_FILTERS'
export const resetActiveFilters = () => dispatch => dispatch({ type: RESET_ACTIVE_FILTERS })

export const FETCH_REPAYMENTS_REQUEST = 'FETCH_REPAYMENTS_REQUEST'
export const FETCH_REPAYMENTS_SUCCESS = 'FETCH_REPAYMENTS_SUCCESS'
export const FETCH_REPAYMENTS_ERROR = 'FETCH_REPAYMENTS_ERROR'
export const fetchRepayments = (beneficiaries = []) => dispatch => {
	dispatch({ type: FETCH_REPAYMENTS_REQUEST })
	const actualYear = new Date().getFullYear()
	const promise1 = RemboursementsService.getRemboursementsV2(LocalStorage.getToken(), actualYear)
	const promise2 = RemboursementsService.getRemboursementsV2(LocalStorage.getToken(), actualYear - 1)
	const promise3 = RemboursementsService.getRemboursementsV2(LocalStorage.getToken(), actualYear - 2)
	return Promise.all([promise1, promise2, promise3]).then((values) => {
		// ce qu'on recoit du serveur
		/** Pour les filtres **/
		const filters = [
			{
				...repaymentFilters.dateDebut,
				values: moment().subtract(6, 'months').toDate().toLocaleDateString('fr-FR')
			},
			{
				...repaymentFilters.dateFin,
				values: moment().toDate().toLocaleDateString('fr-FR')
			},
			repaymentFilters.date,
			{
				...repaymentFilters.beneficaire,
				values: [
					repaymentFilters.beneficaire.values.ALL,
					...beneficiaries.map(beneficiary => ({ value: beneficiary[1].numIndividu, label: `${beneficiary[1].prenom} ${beneficiary[1].nom}` }))
				]
			}, {
				...repaymentFilters.type,
				values: _.uniqBy([...values[0], ...values[1], ...values[2]], 'libActe').map(repayment => ({ key: repayment.libActe, libelle: repayment.libActe }))
			},
			repaymentFilters.clear
		]

		dispatch({ type: FETCH_REPAYMENTS_SUCCESS, payload: { response: [...values[0], ...values[1], ...values[2]], filters } })
	})
}
