export enum REFUND_FILTERS_FIELDS {
	DATE_DEBUT = 'DATE_DEBUT',
	DATE_FIN = 'DATE_FIN',
	DATE = 'DATE',
	TYPE = 'TYPE',
	BENEFICIARY = 'RECIPIENT',
	CLEAR = 'CLEAR'
}

export const recipientFiltersAll = {
	ALL: {
		value: 'ALL',
		label: 'Tous les bénéficiaires'
	}
}

export const repaymentFilters = {
	dateDebut: {
		key: REFUND_FILTERS_FIELDS.DATE_DEBUT,
		values: undefined // défini par les ws
	},
	dateFin: {
		key: REFUND_FILTERS_FIELDS.DATE_FIN,
		values: undefined // défini par les ws
	},
	date: {
		key: REFUND_FILTERS_FIELDS.DATE,
		values: undefined // pas de filtre à sélectionner
	},
	beneficaire: {
		key: REFUND_FILTERS_FIELDS.BENEFICIARY,
		values: recipientFiltersAll
	},
	type: {
		key: REFUND_FILTERS_FIELDS.TYPE,
		values: undefined // défini par les ws
	},
	clear: {
		key: REFUND_FILTERS_FIELDS.CLEAR,
		type: 'clear'
	}
}

export const repaymentChartKeyType = {
	montantRembSS: 'montantRembSS',
	montantRembAutre: 'montantRembAutre',
	montantRembGerep: 'montantRembGerep',
	montantResteACharge: 'montantResteACharge',
	fraisReelSoins: 'fraisReelSoins'
}

export const repaymentChartKey = [
	repaymentChartKeyType.montantRembSS,
	repaymentChartKeyType.montantRembAutre,
	repaymentChartKeyType.montantRembGerep,
	repaymentChartKeyType.montantResteACharge
]

export const formatDate = {
	DoMMMYYYY: 'Do MMM YYYY',
	DoMMMYY: 'Do MMM YY',
	MMMMYYYY: 'MMMM YYYY',
	MM: 'MMMM',
	YYYY: 'YYYY',
	DDMMYYYY: 'DD/MM/YYYY',
	YYYYMMDDTHHmmssZ: 'YYYY-MM-DDTHH:mm:ssZ'
}

export const listCellType = {
	header: 'header',
	cell: 'cell'
}

export const gerepBase = 'base'

export enum chartColorRepayment {
	montantRembSS = '#B5BBFF',
	montantRembAutre = '#D2D6FF',
	montantRembGerep = '#ed8a41',
	montantRembGerepOption = '#F2A870',
	montantResteACharge = '#7a7a7a'
}

export enum PERIODE_OPTIONS {
	MENSUEL = 'MEN',
	TRIMESTRIEL = 'TRI',
	PERIODE = 'PER'
}

export enum PERIODE_FIELDS {
	TYPE = 'type',
	OPTION_1 = 'opt1',
	OPTION_2 = 'opt2',
	DATE_DEBUT = 'dateDebut',
	DATE_FIN = 'dateFin'
}
