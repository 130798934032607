import BeneficiaryAvatar from '../../../../components/BeneficiaryAvatar'
import { Grid, Stack, Typography } from '@mui/material'
import FormatUtils from '../../../../utils/FormatUtils'
import React from 'react'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

const getInfosComplementaires = (beneficiaire: any) => {
	const infos = []
	if (beneficiaire.infoGarantie.rangCouv === 2) {
		infos.push(<span>en complément 1<sup>ère</sup> mutuelle</span>)
	}
	if (beneficiaire.infoGarantie && beneficiaire.infoGarantie.dateFin) {
		infos.push(`Fin de garantie : ${FormatUtils.formatDate(beneficiaire.infoGarantie.dateFin)}`)
	}
	return infos
}

const BeneficiaireContrat = (
	{
		// @ts-ignore
		beneficiaire
	}
) => {

	const { isMobile } = useBreakpoints()

	const infos = getInfosComplementaires(beneficiaire)

	return (
		<Grid item xs={isMobile ? 12 : 6}>
			<Stack direction="column" alignItems="flex-start" spacing={1} pb="24px">
				<Stack direction="row" spacing={1}>
					<BeneficiaryAvatar size="small" firstname={beneficiaire.prenom} lastname={beneficiaire.nom} type={beneficiaire.typAdr} />
					<Stack direction="column">
						<Typography variant="h4">
							{`${beneficiaire.prenom} ${beneficiaire.nom}`}
						</Typography>
						<Typography variant="subtitle1">
							{`${beneficiaire.libTypAdr} `}
							{
								(infos.length > 0) && (
									<>
										{React.Children.toArray(infos)}
									</>
								)
							}
						</Typography>
					</Stack>
				</Stack>
			</Stack>
		</Grid>
	)
}

export default BeneficiaireContrat
