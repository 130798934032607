import { createSelectorCreator, defaultMemoize } from 'reselect'
import { is } from 'immutable'
import { isValueAnArrayOfFiles } from './formUtils'

/**
 * Créateur de sélecteur optimisé pour fonctionner avec les structure Immutable.
 */
export const createImmutableSelector = createSelectorCreator(
	defaultMemoize,
	is
)

/**
 * Méthode utilitaire pour calculer la taille des fichiers uploadés via un champ de formulaire.
 */
const getFileSize = (fileList) => {
	if (fileList instanceof FileList) {
		const fileArray = []
		for (let i = 0; i < fileList.length; i++) {
			fileArray.push(fileList[i])
		}
		return fileArray.reduce((sum, file) => sum + file.size, 0)
	}
	if (isValueAnArrayOfFiles(fileList)) {
		return fileList.reduce((sum, file) => sum + file.size, 0)
	}
	if (fileList instanceof File) {
		return fileList.size
	}
	return NaN
}

export default getFileSize

/**
 * Remplace les liens vers les médias Cardibox au sein des attributs de type CKEditor.
 *
 * Sans cette méthode, les liens conservent leur valeur brute qui ne mènent à rien.
 *
 * @param content la valeur brute de l'attribut en Cardibox
 * @return string contenu Cardibox avec les liens remplacés
 */
export const replaceEntityLinks = (content = '') => {
	return content.replace(/#MEDIALINK\(([a-zA-Z0-9]+)\)/, '/blobs/medias/s/$1/')
}
