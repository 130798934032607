import { types } from './inscriptionActions'
import { combineReducers } from 'redux'

const loading = (state = false, action) => {
	switch (action.type) {
		case types.LOADING:
			{
				return action.payload
			}
		default:
			return state
	}
}

const activationLoading = (state = false, action) => {
	switch (action.type) {
		case types.ACTIVATE_LOADING:
			{
				return action.payload
			}
		default:
			return state
	}
}

const activationStatus = (state = '', action) => {
	switch (action.type) {
		case types.ACTIVATE:
			{
				return action.payload
			}
		default:
			return state
	}
}

export default combineReducers({
	loading,
	activationLoading,
	activationStatus
})
