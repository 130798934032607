import moment from 'moment'
import 'moment/locale/fr'
import { createSelector } from 'reselect'
import {
	REFUND_FILTERS_FIELDS,
	repaymentChartKeyType,
	repaymentChartKey,
	recipientFiltersAll,
	formatDate,
	listCellType,
	gerepBase,
	chartColorRepayment
} from '../../ui/refundList/services/remboursementConstants'
import { capitalize } from '../../../utils/formUtils'
import FormatUtils from '../../../utils/FormatUtils';

const getLocalState = state => state.repayment

export const isLoading = state => state.repayment.isLoading

export const getFilters = state => state.repayment.filters

export const getActiveFilters = state => state.repayment.activeFilters

const applyFilters = (repayments, filters) => {
	const filteredRepayments = repayments.filter(rep => {

		let isInclude = true
		if (isInclude && filters[REFUND_FILTERS_FIELDS.DATE_DEBUT]) {
			const date = filters[REFUND_FILTERS_FIELDS.DATE_DEBUT]
			const repDate = moment(rep.datePayDate).locale('fr')
			isInclude = repDate.isSameOrAfter(date)
		}

		if (isInclude && filters[REFUND_FILTERS_FIELDS.DATE_FIN]) {
			const date = filters[REFUND_FILTERS_FIELDS.DATE_FIN]
			const repDate = moment(rep.datePayDate).locale('fr')
			isInclude = repDate.isSameOrBefore(date)
		}

		if (isInclude && filters[REFUND_FILTERS_FIELDS.DATE]) {
			const repDate = moment(rep.dateSoinsDate).locale('fr')
			const filterDate = moment(filters[REFUND_FILTERS_FIELDS.DATE], formatDate.DDMMYYYY).locale('fr')
			isInclude = repDate.isSame(filterDate)
		}
		if (isInclude && filters[REFUND_FILTERS_FIELDS.BENEFICIARY] && filters[REFUND_FILTERS_FIELDS.BENEFICIARY] !== recipientFiltersAll.ALL.value) {
			isInclude = rep.numBeneficiaire === filters[REFUND_FILTERS_FIELDS.BENEFICIARY]
		}

		if (isInclude && filters[REFUND_FILTERS_FIELDS.TYPE]) {
			isInclude = rep.libActe.toLowerCase().includes(filters[REFUND_FILTERS_FIELDS.TYPE].toLowerCase())
		}

		return isInclude
	})

	return filteredRepayments
}

export const getChartsList = createSelector(
	getLocalState,
	repayment => {
		moment.locale('fr')
		const repayments = applyFilters(repayment.repayments, repayment.activeFilters)

		/** pour les graphs **/
		/**
		 * TotalDepense = somme(fraisReelSoins)
		 */
		const chartReelExpenses = repayments.reduce((total, repayment) => {
			return total + repayment[repaymentChartKeyType.fraisReelSoins]
		}, 0)

		/**
		 * TotalRemSS = somme(montantRembSS)
		 * TotalRembAutre = somme(montantRembAutre)
		 * TotalRembGerep = somme(montantRembGerep)
		 * TotalRAC=TotalDepense–(TotalRembSS+TotalRembAutre+TotalRembGerep)
		 */
		const acc = () => repaymentChartKey.map(key => {
			return {
				key: key,
				libelle: `remboursementConsultation.chart.${key}`,
				val: key === repaymentChartKeyType.montantResteACharge ? chartReelExpenses : 0,
				color: chartColorRepayment[key]
			}
		})

		const objectToAddInAccu = (currentAcc, addInAcc) => {
			return currentAcc.map(repayment => {
				// Pour chaque entrée dans mon accumulateur je prends la valeur pour une clé et j'additionne la valeur du remboursement à ajouter
				// si je n'ai pas de valeur dans l'acc je prends celui du remboursement
				let val = addInAcc[repayment.key] ? repayment.val + addInAcc[repayment.key] : repayment.val
				// montantResteACharge n'est pas présent dans le bean, il faut le calculer
				if (repayment.key === repaymentChartKeyType.montantResteACharge) {
					// Si je suis dans le cas du RAC alors je dois soustraire les remboursements
					// TotalRAC=TotalDepense–(TotalRembSS+TotalRembAutre+TotalRembGerep)
					val = parseFloat(val - (
						addInAcc[repaymentChartKeyType.montantRembSS] +
						addInAcc[repaymentChartKeyType.montantRembAutre] +
						addInAcc[repaymentChartKeyType.montantRembGerep])
					).toFixed(2)

					if (val < 0) {
						// Si négatif on force à O
						val = 0
					}
				}
				return {
					...repayment,
					val
				}
			})
		}

		const chartData = repayments.reduce((accRepayment, repayment) => objectToAddInAccu(accRepayment, repayment), acc())

		return [{ chart: chartData, total: chartReelExpenses.toFixed(2) }]
	}
)

export const getRepaymentsList = createSelector(
	getLocalState,
	repayment => {
		const repayments = applyFilters(repayment.repayments, repayment.activeFilters)
		/** pour la liste **/
		let repaymentsList = []
		if (repayments && repayments.length > 0) {
			const compare = (a, b) => {
				if (moment(b.children[0].datePay, 'DD-MM-YYYY').locale('fr').isAfter(moment(a.children[0].datePay, 'DD-MM-YYYY').locale('fr'))) {
					return 1
				} else {
					return -1
				}
			}

			/**Grouper les lignes par datePayDate, prenomNomNumdest, total**/
			const groupedRepayments = repayments.reduce((accu, item) => ({
					...accu,
					[item.datePayDate + item.prenomNomNumdest + item.total]: [
						...(accu[item.datePayDate + item.prenomNomNumdest + item.total] || []),
						item
					]
				}),
				{})

			const formattedRepayments = Object.keys(groupedRepayments).map(groupedKey => {
				const currentRepayment = groupedRepayments[groupedKey][0]
				const dateFormatted = moment(currentRepayment.datePayDate).locale('fr').format(formatDate.DoMMMYYYY)
				const monthNumber = moment(currentRepayment.datePayDate).locale('fr').format(formatDate.MM)

				/**Grouper par dateSoinsDate, prenomNomBeneficiaire, codeActe **/
				const groupedChildren = groupedRepayments[groupedKey].reduce((accu, item) => {
						const isGarantieBase = item.garantie.toLowerCase().includes(gerepBase)
						let current = accu[item.dateSoinsDate + item.prenomNomBeneficiaire + item.codeActe]
						let cumul = null
						if (current) {
							cumul = {
								...current,
								fraisReelSoins: current.fraisReelSoins + item.fraisReelSoins,
								montantRembAutre: current.montantRembAutre + item.montantRembAutre,
								montantRembGerep: isGarantieBase ? current.montantRembGerep + item.montantRembGerep : current.montantRembGerep,
								montantRembGerepOption: isGarantieBase ? current.montantRembGerepOption : current.montantRembGerepOption + item.montantRembGerep,
								montantRembSS: current.montantRembSS + item.montantRembSS
							}
						} else {
							current = {
								...item,
								montantRembGerep: isGarantieBase ? item.montantRembGerep : 0,
								montantRembGerepOption: isGarantieBase ? 0 : item.montantRembGerep
							}
						}
						return {
							...accu,
							[item.dateSoinsDate + item.prenomNomBeneficiaire + item.codeActe]: cumul ? cumul : current
						}
					},
					{})

				const groupedChildrenArray = Object.keys(groupedChildren).map(groupedChildrenKey => groupedChildren[groupedChildrenKey])

				const formattedRequest = {
					type: listCellType.cell,
					title: dateFormatted,
					subtitle: currentRepayment.prenomNomNumdest,
					accessoryString: FormatUtils.formatMontant(currentRepayment.total),
					id: groupedKey,
					children: groupedChildrenArray,
					datePayDate: currentRepayment.datePayDate,
					monthNumber
				}
				return formattedRequest
			}).sort(compare)

			let currentMonth = ''
			formattedRepayments.forEach(repayments => {
				if (repayments.monthNumber !== currentMonth) {
					const headerTitle = capitalize(moment(repayments.datePayDate).locale('fr').format(formatDate.MMMMYYYY))
					repaymentsList.push({
						type: listCellType.header,
						title: headerTitle,
						id: headerTitle
					})
					currentMonth = repayments.monthNumber
				}
				repaymentsList.push(repayments)
			})
		}
		return repaymentsList
	}
)
