import React, {useContext, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Form} from 'react-final-form'
import {CircularProgress, Stack, Typography} from '@mui/material'
import TextInput from '../../../components/form/TextInput'
import Box from '@mui/material/Box'
import {FORCE_RESET_FIELDS} from '../../../univers/ui/authentication/services/loginConstants'
import ButtonConnexion from '../../../components/ButtonConnexion'
import {useBreakpoints} from '../../../components/breakpoints/BreakpointsProvider'
import LocalStorage from '../../../business/storage/LocalStorage'
import {MAIN_PATH_EE} from '../../../constant/path'
import {UNIVERS_ENTREPRISES_KPI} from '../../../univers/constant/univers'
import {ToastContext} from '../../../components/toast/ToastContext'
import {ToastType} from '../../../components/toast/toastConstants'
import UserServiceEE from '../../../api/gerepEe/UserServiceEE'
import {FORM_REGEX} from '../../../components/formConstants'

type ForceResetFormProps = {
    forceResetIntro: any
}

const ForceResetForm: React.FC<ForceResetFormProps> = (
    {
        forceResetIntro
    }
) => {
    const intl = useIntl()
    const {addToast} = useContext(ToastContext)
    const [loading, setLoading] = useState<boolean>(false)

    const {isMobile} = useBreakpoints()

    const onSubmit = (values: any) => {
        const {newPassword, password} = values
        setLoading(true)
        return UserServiceEE.forceResetPassword({password, newPassword}, LocalStorage.getToken())
            .then(() => window.location.href = UNIVERS_ENTREPRISES_KPI.to(MAIN_PATH_EE.DASHBOARD))
            .catch((e) => {
                if (e?.data) {
                    return e.data
                } else {
                    addToast(ToastType.ERROR, 'global.error.occurred')
                }
            })
            .finally(() => setLoading(false))
    }

    return (
        <Stack direction="column" spacing="48px" width={!isMobile ? '360px' : 'unset'} alignItems="center"
               pt={!isMobile ? '96px' : '48px'} px="24px">
            <Form
                onSubmit={onSubmit}
                validate={(values) => {
                    const errors: any = {}
                    if (!values[FORCE_RESET_FIELDS.OLD_PASSWORD]) {
                        errors[FORCE_RESET_FIELDS.NEW_PASSWORD] = intl.formatMessage({id: 'global.error.required'})
                    }
                    if (!values[FORCE_RESET_FIELDS.NEW_PASSWORD]) {
                        errors[FORCE_RESET_FIELDS.NEW_PASSWORD] = intl.formatMessage({id: 'global.error.required'})
                    } else if (!FORM_REGEX.PASSWORD.test(values[FORCE_RESET_FIELDS.NEW_PASSWORD])) {
                        errors[FORCE_RESET_FIELDS.NEW_PASSWORD] = intl.formatMessage({id: 'global.error.newpasswordbadFormat'})
                    }

                    if (!values[FORCE_RESET_FIELDS.CONFIRM_PASSWORD]) {
                        errors[FORCE_RESET_FIELDS.CONFIRM_PASSWORD] = intl.formatMessage({id: 'global.error.required'})
                    } else if (values[FORCE_RESET_FIELDS.NEW_PASSWORD] !== values[FORCE_RESET_FIELDS.CONFIRM_PASSWORD]) {
                        errors.confirmPassword = intl.formatMessage({id: 'global.error.passwordsDontMatch'})
                    }
                    return errors
                }}
                render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit} role="form" style={{width: '100%'}}>
                        <Stack direction="column" spacing="32px">
                            {forceResetIntro &&
                                <Stack direction="column" spacing="24px">
                                    <Typography color="secondary" variant="h2">
                                        {forceResetIntro.fields.title}
                                    </Typography>
                                    <Typography fontSize="14px">
                                        <div dangerouslySetInnerHTML={{__html: forceResetIntro.fields.body}}/>
                                    </Typography>
                                </Stack>
                            }
                            <TextInput
                                maxWidth={isMobile ? '100%' : '360px'}
                                size="medium"
                                type="password"
                                name={FORCE_RESET_FIELDS.OLD_PASSWORD}
                                label={intl.formatMessage({id: 'forceResetPassword.oldPassword'})}
                            />
                            <TextInput
                                maxWidth={isMobile ? '100%' : '360px'}
                                size="medium"
                                type="password"
                                name={FORCE_RESET_FIELDS.NEW_PASSWORD}
                                label={intl.formatMessage({id: 'forceResetPassword.newPassword'})}
                                helperChecksNeedeed
                            />
                            <TextInput
                                maxWidth={isMobile ? '100%' : '360px'}
                                size="medium"
                                type="password"
                                name={FORCE_RESET_FIELDS.CONFIRM_PASSWORD}
                                label={intl.formatMessage({id: 'forceResetPassword.confirmPassword'})}
                            />
                            <Box>
                                <ButtonConnexion type="submit" onClick={handleSubmit} variant="contained">
                                    {loading ?
                                        <CircularProgress size={24} color="inherit"/> :
                                        <FormattedMessage id="global.button.validate"/>
                                    }
                                </ButtonConnexion>
                            </Box>
                        </Stack>
                    </form>
                )}
            />
        </Stack>
    )
}

export default ForceResetForm
