import React from 'react'
import {
	Breadcrumbs,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
	useTheme
} from '@mui/material'
import {mdiLink} from '@mdi/js'
import Icon from '@mdi/react'
import {ChevronRight} from '@mui/icons-material'

type HelpLinkProps = {
	title: any
	displayBottomDivider?: boolean
	onClick?: () => void
	ariane : any
	forSearch: boolean
}

const HelpLink: React.FC<HelpLinkProps> = (
	{
		title,
		displayBottomDivider,
		onClick,
		ariane,
		forSearch
	}
) => {
	const theme = useTheme()
	const icon = mdiLink

	return (
		<ListItem disablePadding sx={{ borderTop: '1px solid #dedede', borderBottom: displayBottomDivider ? '1px solid #dedede' : 'unset' }}>
			<ListItemButton onClick={onClick}>
				<ListItemText
					primary={
						<Stack direction="row" spacing="12px" alignItems="center">
							<Stack direction="row" spacing="12px" alignItems="center">
								<Icon path={icon} size="16px" color={theme.palette.secondary.main} />
							</Stack>
							<Stack direction="column">
								{forSearch &&
									<Breadcrumbs
										separator={<ChevronRight sx={{fontSize: "0.9rem"}}/>}
										aria-label="breadcrumbDrawer"
									>
										{ariane.map((itemAriane: any, index: number) => {
											return (<Typography key={index} variant="subtitle1"> {itemAriane.label} </Typography>)
										})}
									</Breadcrumbs>
								}
								<Typography variant="body1">
									{title}
								</Typography>
							</Stack>
						</Stack>
					}
					primaryTypographyProps={{ variant: 'body1' }}
				/>
				<ListItemIcon>
					<ChevronRight />
				</ListItemIcon>
			</ListItemButton>
		</ListItem>
	)
}

export default HelpLink
