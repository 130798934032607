import React, { ReactNode } from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import { Link, Stack, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga'
import { SERVICES_BIEN_ETRE_GA_CATEGORY } from '../../dashboard/services/dashboardConstants'
import Box from '@mui/material/Box'
import ButtonDashboard from '../../../../components/ButtonDashboard'
import { getGALibelle } from '../../../../utils/servicesUtils'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

type TeleconsultationCardProps = {
	service: any,
	lienWebCustom?: ReactNode
}

const TeleconsultationCard: React.FC<TeleconsultationCardProps> = ({
	service,
	lienWebCustom
}) => {
	const theme = useTheme()
	const { isMobile } = useBreakpoints()
	const height = 'auto'

	const trackEvent = (event: any, {
		nomAssureur,
		idService,
		titre
	}: { nomAssureur: string, idService: number, titre: string }) => {
		ReactGA.event({
			category: SERVICES_BIEN_ETRE_GA_CATEGORY,
			action: titre,
			label: getGALibelle(idService, nomAssureur)
		})
	}

	const {
		titre,
		picto,
		definition,
		telephone,
		email,
		siteWeb,
		libelleLienWeb,
		protocole,
		contrat
	} = service

	return (
		<DashboardCard height={height} title={titre} cardContentPadding={!isMobile ? '0 24px 24px 24px' : undefined}>
			<Stack direction="column" spacing="12px" width="100%" alignItems="center">
				<Box display="flex" justifyContent="center">
					{picto && (
						<img src={picto} alt="service picto" style={{ maxHeight: '65px' }} />
					)}
				</Box>
				{definition && (
					<Typography variant="body2">
						<div dangerouslySetInnerHTML={{ __html: definition }} style={{ textAlign: 'left' }} />
					</Typography>
				)}
				{telephone && (
					<Stack width="100%" justifyContent="center" spacing="8px">
						<Box display="flex" width="100%">
							<Typography variant="body2">
								<FormattedMessage
									id="servicesSante.telephone"
									values={{
										telephone: (
											<Link
												href={`tel:${telephone}`}
												onClick={(event) => trackEvent(event, service)}
												style={{
													textDecoration: 'none',
													color: theme.palette.secondary.main
												}}
											>{telephone}
											</Link>
										)
									}}
								/>
							</Typography>
						</Box>
					</Stack>
				)}
				{email && (
					<Stack width="100%" justifyContent="center" spacing="8px">
						<Box display="flex" width="100%">
							<Typography variant="body2">
								<FormattedMessage
									id="servicesSante.email"
									values={{
										email: (
											<Link href={`mailto:${email}`}>
												{email}
											</Link>
										)
									}}
								/>
							</Typography>
						</Box>
					</Stack>
				)}
				{contrat && (
					< Stack width="100%" justifyContent="center" spacing="8px">
						<Box display="flex" width="100%">
							<Typography variant="body2" textAlign="center">
								<FormattedMessage id="servicesSante.contrat" values={{ contrat }} />
							</Typography>
						</Box>
					</Stack>
				)}
				{protocole && (
					<Stack width="100%" justifyContent="center" spacing="8px">
						<Box display="flex" width="100%">
							<Typography variant="body2" textAlign="center">
								<FormattedMessage id="servicesSante.protocole" values={{ protocole }} />
							</Typography>
						</Box>
					</Stack>
				)}
				{(siteWeb || lienWebCustom) && (
					<Stack width="100%" justifyContent="center" spacing="8px" paddingTop="16px">
						{lienWebCustom ? (lienWebCustom) :
							(
								<a
									href={`${!siteWeb.startsWith('https://') && !siteWeb.startsWith('http://') && !siteWeb.startsWith('www.') ? '//' : ''}${siteWeb}`}
									style={{ textDecoration: 'none' }}
									target="_blank"
									rel="noreferrer"
									onClick={(event) => trackEvent(event, service)}
								>
									<Box>
										<ButtonDashboard>
											{
												libelleLienWeb ? libelleLienWeb
													: <FormattedMessage id="dashboard.networkPartner.buttons.access" />
											}
										</ButtonDashboard>
									</Box>
								</a>
							)}
					</Stack>
				)}
			</Stack>
		</DashboardCard>
	)
}

export default TeleconsultationCard