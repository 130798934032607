import { CLOSE_INTERSTITIEL } from './interstitielTypes'

/**
 * Ce reducer est un peu particulier car il a vocation à être monté à 2 endroits différents du state et qu'un seul
 * doit se mettre à jour à la fois, d'où le flag passé en paramètre lors de sa création.
 *
 * @param updateIfClose
 */
const prefs = (updateIfClose = false) => (state = {}, action) => {
	switch (action.type) {
		case CLOSE_INTERSTITIEL: {
			const {
				payload: {
					idArticle,
					closePermanently
				}
			} = action
			if (closePermanently === updateIfClose) {
				// le reducer ne réagit que si le flag correspond au paramètre utilisé lors de sa création
				return {
					...state,
					[idArticle]: {
						close: true
					}
				}
			}
			return state
		}
		default:
			return state
	}
}

export default prefs
