import React, { useMemo } from 'react'
import { cardiboxSelectors } from 'redux-cardibox'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Divider, Link, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import SouscriptionCheckboxInput from './SouscriptionCheckboxInput'
import { STEP1_CHECKBOX_NAME, STEP1_FORM } from '../services/souscriptionConstants'
import {downloadGuide} from '../services/souscriptionActions'

const ARTICLE_SHORTCUT = 'intro_etape_1_option'

type GarantieSouscriptionPageProps = {
	garanties: Garantie[]
	errors: any,
	values: any
	onError?: () => void
}

const GarantieSouscriptionForm: React.FC<GarantieSouscriptionPageProps> = (
	{
		// @ts-ignore
		article,
		garanties,
		errors,
		values,
		onError,
		// @ts-ignore
		download
	}
) => {

	const selectedOptions = useMemo(() => {
		const selectedOptionKey = values[STEP1_CHECKBOX_NAME] && values[STEP1_CHECKBOX_NAME][0]?.idGarantie[0]
		return garanties.find((garantie: Garantie) => garantie.key === selectedOptionKey)
	}, [values, garanties])

	const showDureeEngagement = useMemo(() => selectedOptions && selectedOptions.dureeEngagement > 0, [selectedOptions])

	const infoGar1 = useMemo(() => selectedOptions && ((selectedOptions.prix > 0 || selectedOptions.libellePrix) && selectedOptions.infoGar1), [selectedOptions])

	const lastGarantie = useMemo(() => garanties[garanties.length - 1], [garanties])

	return (
		<Stack direction="column" spacing="24px">
			{article && (
				<div dangerouslySetInnerHTML={{ __html: article.fields.body }} />
			)}
			<Link
				href="#"
				color="secondary"
				onClick={() => {
					let dateEffetDate
					if (lastGarantie.dateEffet) {
						dateEffetDate = new Date(lastGarantie.dateEffet)
						if (isNaN(dateEffetDate.getTime())) {
							// la date passée en paramètre est invalide
							dateEffetDate = new Date()
						}
					} else {
						// pas de date
						dateEffetDate = new Date()
					}

					download({
						numGar: lastGarantie.numGar,
						siret: lastGarantie.siret,
						annee: dateEffetDate.getFullYear()
					}).catch(() => {
							onError && onError()
						})
					}
				}
			>
				<FormattedMessage id="souscription.gestionLink" />
			</Link>
			<Stack direction="column" spacing="12px">
				<Typography variant="body2">
					<FormattedMessage id="souscription.selectOption.title" />
				</Typography>
				<Divider />
				<SouscriptionCheckboxInput
					name={STEP1_CHECKBOX_NAME}
					data={garanties}
					values={values}
					onError={onError}
					error={errors[STEP1_FORM]}
				/>
			</Stack>
			<Stack direction="column">
				{infoGar1 &&
					<Typography variant="subtitle2">
						<FormattedMessage id="souscription.legends.1" values={{ text: infoGar1 }} />
					</Typography>
				}
				{showDureeEngagement &&
					<Typography variant="subtitle2">
						<FormattedMessage id="souscription.legends.2" />
					</Typography>
				}
			</Stack>
		</Stack>
	)
}

const mappedActions = {
	download: downloadGuide
}

const makeMapStateToProps = (state: any) => ({
	article: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_SHORTCUT]
})

export default compose(
	connect(makeMapStateToProps, mappedActions))(GarantieSouscriptionForm)
