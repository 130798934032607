import React, { Dispatch, SetStateAction } from 'react'
import Popup from '../../../components/Popup'
import { replaceEntityLinks } from '../../../utils/FormatUtils'
import { Box, Stack } from '@mui/material'
import { Form } from 'react-final-form'
import CheckboxInput from '../../../components/form/CheckboxInput'
import ButtonDefault from '../../../components/ButtonDefault'
import { FormattedMessage } from 'react-intl'

type NotationPopupProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	contenu: any
	addAffichagePopinNotation: (closePermanently: any, clicLien: boolean) => void
}

const NotationPopup: React.FC<NotationPopupProps> = (
	{
		open,
		setOpen,
		contenu,
		addAffichagePopinNotation
	}
) => {
	const noDisplay = localStorage.getItem('noDisplay') === 'true'

	const onSubmit = (values: any) => {
		if (values['stopDisplay'] && values['stopDisplay'] === true && !noDisplay) {
			localStorage.setItem('noDisplay', 'true')
		}
	}

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.currentTarget && e.currentTarget.className.includes('notationLink')) {
			addAffichagePopinNotation(null, true)
		}
	}

	return (
		<Popup open={open} setOpen={setOpen} title={contenu?.fields?.title || ''}>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Stack spacing="24px">
							{
								contenu?.fields.body &&
								<div
									onClick={handleClick}
									dangerouslySetInnerHTML={{ __html: replaceEntityLinks(contenu.fields.body) }}
								/>
							}
						</Stack>
						<CheckboxInput
							name="stopDisplay"
							data={[
								{
									label: 'Ne plus afficher ce message',
									value: true
								}
							]}
						/>
						<Box display="flex" justifyContent="center">
							<ButtonDefault
								type="submit"
								variant="contained"
								onClick={() => setOpen(false)}
							>
								<FormattedMessage id="global.button.close" />
							</ButtonDefault>
						</Box>
					</form>
				)}
			/>
		</Popup>
	)
}

export default NotationPopup