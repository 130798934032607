import React, { useMemo } from 'react'
import { Box, Stack } from '@mui/material'
import Notifications from '../Notifications'
import DownloadApp from '../DownloadApp'
import Welcome from '../Welcome'
import SendDocuments from '../SendDocuments'
import RefundCard from '../RefundCard'
import ServiceCard from '../ServiceCard'
import NetworkPartnerCard from '../NetworkPartnerCard'
import DemandsCard from '../DemandsCard'
import PersonalDataCard from '../PersonalDataCard'
import BeneficiaryCard from '../BeneficiaryCard'
import { useBreakpoints } from '../../../../../components/breakpoints/BreakpointsProvider'

type DashboardTabletPortraitProps = {
	affiliationExpired: boolean
	notifications: any[]
	remboursements: any[]
	user: any
	demandes: any[]
	assurePrincipal: any
	beneficiaires: any[]
}

const DashboardTabletPortrait: React.FC<DashboardTabletPortraitProps> = (
	{
		affiliationExpired,
		notifications,
		remboursements,
		user,
		demandes,
		assurePrincipal,
		beneficiaires
	}
) => {
	const { width } = useBreakpoints()
	const hasUserServices = user?.profil?.itelis || user?.profil?.santeclair
	const smallCardMaxWidth = useMemo(() => (width - 12 - 14 - 12) / 2, [width])

	return (
		<Stack width="100%" direction="column" spacing="12px">
			<Stack width="100%" direction="row" spacing="12px" alignItems="flex-end">
				<Box display="flex" flex={1}>
					<Welcome affiliationExpired={affiliationExpired} />
				</Box>
				<Box display="flex" flex={1} alignItems="flex-end" minHeight="200px">
					{notifications?.length > 0 ? <Notifications notifications={notifications} /> : <DownloadApp />}
				</Box>
			</Stack>

			<Box display="flex" flex={1}>
				{!affiliationExpired ?
					<SendDocuments /> :
					<ServiceCard affiliationExpired={affiliationExpired} servicesSante={user?.profil?.servicesSante} simulateur={user?.profil?.simulateur} virementCotisation={user?.profil?.virementCotisation} />
				}
			</Box>
			<Box display="flex" flex={1}>
				<RefundCard remboursements={remboursements} />
			</Box>

			{!affiliationExpired &&
				<Box display="flex" flex={1}>
					<ServiceCard affiliationExpired={affiliationExpired} servicesSante={user?.profil?.servicesSante} simulateur={user?.profil?.simulateur} virementCotisation={user?.profil?.virementCotisation} />
				</Box>
			}

			{hasUserServices && !affiliationExpired ?
				<Stack width="100%" direction="row" spacing="12px">
					<Box display="flex" flex={1} width={`${smallCardMaxWidth}px`}>
						<NetworkPartnerCard isItelis={user.profil.itelis} isSanteClair={user.profil.santeclair} />
					</Box>
					<Box display="flex" flex={1} width={`${smallCardMaxWidth}px`}>
						<DemandsCard demands={demandes} affiliationExpired={affiliationExpired} />
					</Box>
				</Stack> :
				<Stack width="100%" direction="row" spacing="12px">
					<Box display="flex" flex={1} width={`${smallCardMaxWidth}px`}>
						<DemandsCard demands={demandes} affiliationExpired={affiliationExpired} />
					</Box>
					<Box display="flex" flex={1} width={`${smallCardMaxWidth}px`}>
						<PersonalDataCard user={user} affiliation={assurePrincipal} affiliationExpired={affiliationExpired} />
					</Box>
				</Stack>
			}

			{hasUserServices && !affiliationExpired ?
				<Stack width="100%" direction="row" spacing="12px">
					<Box display="flex" flex={1} width={`${smallCardMaxWidth}px`}>
						<PersonalDataCard user={user} affiliation={assurePrincipal} affiliationExpired={affiliationExpired} />
					</Box>
					<Box display="flex" flex={1} width={`${smallCardMaxWidth}px`}>
						<BeneficiaryCard beneficiaries={beneficiaires} affiliationExpired={affiliationExpired} />
					</Box>
				</Stack> :
				<Stack width="100%" direction="row" spacing="12px">
					<Box display="flex" flex={1} width={`${smallCardMaxWidth}px`}>
						<BeneficiaryCard beneficiaries={beneficiaires} affiliationExpired={affiliationExpired} />
					</Box>
					<Box display="flex" flex={1} width={`${smallCardMaxWidth}px`} />
				</Stack>
			}
		</Stack>
	)
}

export default DashboardTabletPortrait
