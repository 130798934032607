import { GET_RIB_COTISATION, GET_RIB_COTISATION_LOADING } from './ribCotisationActionTypes'
import { combineReducers } from 'redux'

const data = (state = null, action) => {
	switch (action.type) {
		case GET_RIB_COTISATION:
			return action.payload
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case GET_RIB_COTISATION_LOADING:
			return action.payload
		default:
			return state
	}
}

const cache = (state = false, action) => {
	switch (action.type) {
		case GET_RIB_COTISATION:
			return true
		default:
			return state
	}
}

export default combineReducers({
	data,
	loading,
	cache
})
