import React from 'react'
import { Field } from 'react-final-form'
import ReCAPTCHA from 'react-google-recaptcha'
import appConst from '../../constant/appConstants'
import { Typography } from '@mui/material'

type CaptchaInputProps = {
	name: string
	recaptchaRef: any
}

const CaptchaInput: React.FC<CaptchaInputProps> = ({ name, recaptchaRef }) => {
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<>
					<ReCAPTCHA
						sitekey={appConst.recaptchaSiteKey}
						onChange={input.onChange}
						ref={recaptchaRef}
					/>
					{meta.error && meta.touched && <Typography variant="subtitle2" color="error">{meta.error}</Typography>}
				</>
			)}
		</Field>
	)
}

export default CaptchaInput