export enum DEMAND_REFUND_FIELDS {
	TYPE = 'type',
	BENEFICIARY = 'beneficiaire',
	MUTUELLE = 'mutuelle',
	MUTUELLE_FILE = 'mutuelleFile',
	DEMAND = 'demande',
	HEALING = 'soins',
	HEALING_DETAILS = 'detailsSoins',
	HEALING_DETAILS_ARRAY = 'detailsSoinsArray',
	FILES = 'files',
	FILES_SS = 'filesSS',
	FILES_OTHER = 'filesOther',
}

export enum DEMAND_REFUND_TYPES {
	BILL = 'facture',
	OTHER = 'releveComp',
	SS_NUMBER = 'releveSS',
}

export enum DEMAND_REFUND_DEMAND_TYPES {
	OPTIQUE = '1',
	DENTAIRE = '2',
	AUDITION = '3',
	HOSPITALISATION = '4',
	SOINS_EXTERNE = '5',
	MEDECINE_DOUCE = '6',
	CURE_THERMALE = '7',
	MUTUELLE = '8',
	AUTRES = '9',
}

// EAREA863 'enlever l'infobulle sur cette page, quel que soit le choix dans "Votre demande concerne", et d'enlever également au niveau des pièces
// justificatives demandées dans l'upload de fichier la partie "et relevé de remboursement de la Sécurité Sociale en cas de non télétransmission".
export const DEMAND_REFUND_DATA = [
	{
		'value': '1',
		'label': 'Optique',
		'entries': [
			{
				'value': '1',
				'label': 'Lentilles',
				'help': 'Facture acquittée et détaillée (marque, fréquence de renouvellement…) + Ordonnance de moins de 3 ans'
			},
			{
				'value': '2',
				'label': 'Verres/Monture',
				// 'help': 'Facture détaillée et acquittée + Ordonnance de moins de 3 ans et relevé de remboursement de la Sécurité Sociale en cas de non télétransmission',
				'help': 'Facture détaillée et acquittée + Ordonnance de moins de 3 ans',
				'infobulle': 'La télétransmission est un échange d\'informations informatisé entre les divers acteurs de santé. Elle vous permet, entre autre, d\'être dispensé d\'envoyer vos  feuilles de soins à votre assurance maladie et ensuite les relevés de Sécurité Sociale à votre Gestionnaire de «mutuelle santé» (ici Gerep).'
			},
			{
				'value': '3',
				'label': 'Chirurgie correctrice',
				'help': 'Facture originale et détaillée (œil concerné, honoraires…)'
			}
		]
	},
	{
		'value': '2',
		'label': 'Dentaire',
		'entries': [
			{
				'value': '4',
				'label': 'Prothèse dentaire (couronne, inlay-core, bridge, appareil mobile)',
				// 'help': 'Facture détaillée par élément et relevé de remboursement de la Sécurité Sociale en cas de non télétransmission',
				'help': 'Facture détaillée par élément',
				'infobulle': 'La télétransmission est un échange d\'informations informatisé entre les divers acteurs de santé. Elle vous permet, entre autre, d\'être dispensé d\'envoyer vos  feuilles de soins à votre assurance maladie et ensuite les relevés de Sécurité Sociale à votre Gestionnaire de «mutuelle santé» (ici Gerep).'
			},
			{
				'value': '5',
				'label': 'Implant, parodontie, prothèse NR ',
				'help': 'Facture acquittée et détaillée (numéro de dent, honoraires…) '
			},
			{
				'value': '6',
				'label': 'Orthodontie non prise en charge par la Sécurité sociale',
				'help': 'Facture détaillée et acquittée (mentionnant la période de traitement). Le remboursement, selon vos garanties, intervient une fois la période de traitement terminée et non en début de période.'
			}
		]
	},
	{
		'value': '3',
		'label': 'Prothèse auditive',
		'help': 'Facture acquittée et détaillée (oreille concernée, marque…) + Ordonnance en cours de validité.',
		'infobulle': 'La télétransmission est un échange d\'informations informatisé entre les divers acteurs de santé. Elle vous permet, entre autre, d\'être dispensé d\'envoyer vos  feuilles de soins à votre assurance maladie et ensuite les relevés de Sécurité Sociale à votre Gestionnaire de «mutuelle santé» (ici Gerep).'
	},
	{
		'value': '4',
		'label': 'Hospitalisation',
		'entries': [
			{
				'value': '7',
				'label': 'Hôpital',
				'help': 'Avis des sommes + attestation de paiement'
			},
			{
				'value': '8',
				'label': 'Clinique',
				'help': 'Bordereau de facturation + note d\'honoraires du praticien en cas de dépassement'
			}
		]
	},
	{
		'value': '5',
		'label': 'Soins Externes (Consultation, Radio, Examen réalisé en hôpital ou clinique)',
		'entries': [
			{
				'value': '7',
				'label': 'Hôpital',
				'help': 'Avis des sommes à payer + attestation de paiement'
			},
			{
				'value': '8',
				'label': 'Clinique',
				'help': 'Facture acquittée et détaillée'
			}
		]
	},
	{
		'value': '6',
		'label': 'Médecine douce et bien-être (ostéopathie, acupuncture, pharmacie non remboursée...) ',
		'help': 'Facture détaillée ou justificatif de paiement avec ordonnance pour les vaccins et la pharmacie non remboursée'
	},
	{
		'value': '7',
		'label': 'Cure Thermale',
		'help': 'Attestation de cure + justificatifs acquittés des frais engagés (hébergement, transport…)'
	},
	{
		'value': '8',
		'label': 'Paiement de la part mutuelle (kinésithérapeute, pharmacien, laboratoire…) en cas de non fonctionnement du Tiers Payant Intégral',
		'help': 'Justificatif de paiement détaillé uniquement si vous avez réglé une partie de la dépense auprès de votre professionnel de santé. Le ticket de Carte Bleue seul n\'est pas un justificatif de paiement suffisant.'
	},
	{
		'value': '9',
		'label': 'Autre Soin',
		'help': 'Justificatif de paiement détaillé'
	}
]
