import React, { useEffect, useState } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import BeneficiaryAvatar from '../../../../../components/BeneficiaryAvatar'
import InfoField from '../../../../../components/InfoField'
import FormatUtils from '../../../../../utils/FormatUtils'
import ButtonLink from '../../../../../components/ButtonLink'
import Icon from '@mdi/react'
import { mdiAccountEdit, mdiPlaylistEdit } from '@mdi/js'
import { useBreakpoints } from '../../../../../components/breakpoints/BreakpointsProvider'
import ModifyPersonalInfo from './ModifyPersonalInfo'
import SuggestService from '../../../../../api/gerep/SuggestService'
import { useHistory } from 'react-router-dom'
import { SECONDARY_PATH } from '../../../../../constant/path'
import { maskObj, maskTelephone } from '../../../../../utils/authentUtils'

type PersonalInfoProps = {
	assurePrincipal: any
	doubleAuthent: string
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ assurePrincipal, doubleAuthent }) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const history = useHistory()

	const [openModifyInfo, setOpenModifyInfo] = useState<boolean>(false)
	const [pays, setPays] = useState<Country[]>([])

	useEffect(() => {
		SuggestService.getPays()
			.then((response) => setPays(response))
	}, [])

	return (
		<Stack direction="column" spacing="24px">
			<Typography variant="h2">
				<FormattedMessage id="personalInfo.personalData.title" />
			</Typography>
			<Stack direction="column" spacing="14px" pl="24px" pr="12px">
				<Stack direction="row" spacing="8px" alignItems="center">
					<BeneficiaryAvatar firstname={assurePrincipal?.prenom} lastname={assurePrincipal?.nom} size="large" type={0} />
					<Typography variant="h3">
						{`${assurePrincipal?.prenom} ${assurePrincipal?.nom}`}
					</Typography>
				</Stack>
				<Stack direction="column" spacing="14px" alignItems="center">
					<Stack width="100%" direction={isMobile ? 'column' : 'row'} spacing="16px" justifyContent="space-between">
						<InfoField
							label={intl.formatMessage({ id: 'personalInfo.personalData.labels.birth' })}
							value={assurePrincipal?.dateNaissance}
						/>
						{!isMobile ?
							<InfoField
								label={intl.formatMessage({ id: 'personalInfo.personalData.labels.ssNumber' })}
								value={FormatUtils.formatNiss(maskObj(assurePrincipal?.matorg))}
							/>
							:
							<InfoField
								label={intl.formatMessage({ id: 'personalInfo.personalData.labels.address' })}
								value={
									<Stack direction="column">
										<Typography variant="body1">{assurePrincipal?.adresse?.adresse}</Typography>
										<Typography variant="body1">{`${assurePrincipal?.adresse?.codpos} ${assurePrincipal?.adresse?.ville}, ${assurePrincipal?.adresse?.libPays}`}</Typography>
									</Stack>
								}
							/>
						}
					</Stack>
					<Stack width="100%" direction={isMobile ? 'column' : 'row'} spacing="16px" justifyContent="space-between">
						{!isMobile ?
							<>
								<InfoField
									label={intl.formatMessage({ id: 'personalInfo.personalData.labels.address' })}
									value={
										<Stack direction="column">
											{assurePrincipal?.adresse?.adresse &&
												<Typography variant="body1">
													{assurePrincipal?.adresse?.adresse}
												</Typography>
											}
											{assurePrincipal?.adresse?.adresse2 &&
												<Typography variant="body1">
													{assurePrincipal?.adresse?.adresse2}
												</Typography>
											}
											{assurePrincipal?.adresse?.adresse3 &&
												<Typography variant="body1">
													{assurePrincipal?.adresse?.adresse3}
												</Typography>
											}
											{assurePrincipal?.adresse?.adresse4 &&
												<Typography variant="body1">
													{assurePrincipal?.adresse?.adresse4}
												</Typography>
											}
											{assurePrincipal?.adresse?.codpos && assurePrincipal?.adresse?.ville && assurePrincipal?.adresse?.libPays &&
												<Typography variant="body1">
													{`${assurePrincipal?.adresse?.codpos} ${assurePrincipal?.adresse?.ville}, ${assurePrincipal?.adresse?.libPays}`}
												</Typography>
											}
										</Stack>
									}
								/>
								<InfoField
									label={intl.formatMessage({ id: 'personalInfo.personalData.labels.phone' })}
									value={maskTelephone(assurePrincipal?.telephone)}
								/>
							</> :
							<>
								<InfoField
									label={intl.formatMessage({ id: 'personalInfo.personalData.labels.ssNumber' })}
									value={FormatUtils.formatNiss(assurePrincipal?.matorg)}
								/>
								<InfoField
									label={intl.formatMessage({ id: 'personalInfo.personalData.labels.phone' })}
									value={maskTelephone(assurePrincipal?.telephone)}
								/>
							</>
						}
					</Stack>
				</Stack>
				<Stack direction={isMobile || isTabletPortrait ? 'column' : 'row'} spacing="14px" alignItems={isMobile || isTabletPortrait ? 'flex-start' : 'center'}>
					<Box display="flex" flex={1}>
						<ButtonLink
							variant="contained"
							startIcon={<Icon size="20px" path={mdiAccountEdit} />}
							onClick={() => setOpenModifyInfo(true)}
							style={{ height: isMobile ? 'auto' : '36px' }}
						>
							<FormattedMessage id="personalInfo.personalData.buttons.update" />
						</ButtonLink>
					</Box>
					<Box display="flex" flex={1}>
						<ButtonLink variant="contained" startIcon={<Icon size="20px" path={mdiPlaylistEdit} />} onClick={() => history.push(SECONDARY_PATH.CONTACT_MODIFY_SOCIAL_SECURITY)}>
							<FormattedMessage id="personalInfo.personalData.buttons.modify" />
						</ButtonLink>
					</Box>
				</Stack>
			</Stack>
			<Divider />

			<ModifyPersonalInfo pays={pays} open={openModifyInfo} setOpen={setOpenModifyInfo} user={assurePrincipal} doubleAuthent={doubleAuthent} />
		</Stack>
	)
}

export default PersonalInfo
