import ApiError from '../../api/gerep/ApiError'
import appConst from '../../constant/appConstants';
import GerepAPI, { buildParams, buildUrlWithParams } from './GerepAPI';

export const downloadMandatSepa = (token, values) => (
	GerepAPI.download('/mandatSepa/download', values, token)
)

// TODO rendre générique
export const downloadGuide = (token, params) => {
	const prepareUrl = '/guideGestion/prepareDownload'
	const url = '/guideGestion/download'
	return GerepAPI.get('/ticket', null, token)
		.then((weakToken) => {
			const queryString = buildParams({
				...params,
				token: weakToken
			})
			const prepareDownloadUrl = `${appConst.gerepApi.baseUrl}${prepareUrl}?${queryString}`
			const documentUrl = `${appConst.gerepApi.baseUrl}${url}?${queryString}`
			return fetch(prepareDownloadUrl, {
				method: 'GET'
			}).then((response) => {
				if (!response.ok) {
					throw new ApiError(response.status, null, null)
				} else {
					window.location = documentUrl
					return null
				}
			})
		})
}


/**
 * Souscrit à une option.
 *
 * @param numAdherent   le numéro de l'adhérent
 * @param idAdhesion   l'id de l'adhésion de base
 * @param parts         les parts du formulaire
 * @param token         le token de connexion
 * @return la réponse du webservice
 */
export const souscrire = (numAdherent, idAdhesion, parts, token) => {
	const url = buildUrlWithParams('/souscription/souscrire', {
		numAdherent,
		idAdhesion
	})
	return GerepAPI.postMultipart(url, parts, token)
}
