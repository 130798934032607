import { types } from './reseauSoinActions'
import { combineReducers } from 'redux'
import configureCache from '../../../../utils/cache';
import appConst from '../../../../constant/appConstants';
// const

const _cache = configureCache(appConst.cacheDuration.default)

const data = (state = null, action) => {
	switch (action.type) {
		case types.LOAD_SANTECLAIR_TOKEN: {
			return action.payload
		}
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case types.SANTECLAIR_TOKEN_LOADING: {
			return action.payload
		}
		default:
			return state
	}
}

const expirationDate = (state = null, action) => {
	switch (action.type) {
		case types.LOAD_SANTECLAIR_TOKEN:
			return _cache.mark()
		default:
			return state
	}
}

const santeClair = combineReducers({
	data,
	loading,
	expirationDate
})

export default combineReducers({
	santeClair
})
