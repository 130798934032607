import {types} from './navMenuActions'
import {combineReducers} from 'redux'

const data = (state = [], action) => {
    switch (action.type) {
        case types.GET_NAV_MENU: {
            return action.payload
        }
        default:
            return state
    }
}

const loading = (state = false, action) => {
    switch (action.type) {
        case types.GET_NAV_MENU_LOADING: {
            return action.payload
        }
        default:
            return state
    }
}

export default combineReducers({
    data,
    loading
})
