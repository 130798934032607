import React, {useEffect, useState} from 'react'
import PageLayout from '../../../../components/PageLayout'
import {FormattedMessage, useIntl} from 'react-intl'
import { Divider, Stack, Typography } from '@mui/material'
import PaymentActions from '../components/PaymentActions'
import {profileSelector, userSelector} from '../../user/userSelectors'
import {getCarteTPActives, isCarteTPLoading} from '../../../webservices/carteTiersPayant/carteTiersPayantSelector'
import {downloadCarteTP, loadCarteTP} from '../../../webservices/carteTiersPayant/carteTiersPayantActions'
import {connect} from 'react-redux'
import Loader from '../../../../components/Loader'
import PaymentCardCell from '../components/PaymentCardCell'
import {getIndividuList, isAffiliationLoading} from '../../../webservices/affiliation/affiliationSelector'
import * as affiliationAction from '../../../webservices/affiliation/affiliationActions'
import Popup from '../../../../components/Popup'

type PaymentCardProps = {
	isCarteTpView?: boolean
}

const PaymentCardPage: React.FC<PaymentCardProps> = (
	{
		isCarteTpView,
		// @ts-ignore
		carteTP,
		// @ts-ignore
		loading,
		// @ts-ignore
		downloadCarteTP,
		// @ts-ignore
		loadCarteTP,
		// @ts-ignore
		isAffiliationLoading,
		// @ts-ignore
		allBeneficiaries,
		// @ts-ignore
		getAffiliation
	}
) => {
	const intl = useIntl()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [openNoCard, setOpenNoCard] = useState<boolean>(false)

	useEffect(() => {
		loadCarteTP()
		getAffiliation()
		setIsLoading(false)
	}, [getAffiliation, loadCarteTP])

	if (loading || isAffiliationLoading || isLoading) {
		return <Loader />
	}

	return (
		<PageLayout title={intl.formatMessage({ id: 'paymentCard.title' })}>
			<Stack direction="column" spacing="24px">
				{carteTP.map((carte: any, index: number) =>
					(
						<>
							{index !== 0 && <Divider />}
							<PaymentCardCell
								key={index}
								startingDate={carte?.assure?.debut || ''}
								endDate={carte?.assure?.fin || ''}
								beneficiaries={carte?.beneficiaires || []}
								numIndividu={carte?.assure?.numIndividu || ''}
								allBeneficiaries={allBeneficiaries}
								openNoCard={() => setOpenNoCard(true)}
								downloadCarteTP={downloadCarteTP}
							/>
						</>
					))}
				{!isCarteTpView && <>
					<Divider />
					<PaymentActions />
				</>}
				<Popup
					maxWidth="600px"
					open={openNoCard}
					setOpen={setOpenNoCard}
					title={intl.formatMessage({ id: 'paymentCard.cardNotAvailable.title' })}
				>
					<Typography variant="body1">
						<FormattedMessage id="paymentCard.cardNotAvailable.body" />
					</Typography>
				</Popup>
			</Stack>
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	carteTP: getCarteTPActives(state),
	loading: isCarteTPLoading(state),
	user: userSelector(state),
	profile: profileSelector(state),
	// @ts-ignore
	allBeneficiaries: getIndividuList(state),
	isAffiliationLoading: isAffiliationLoading(state)
})

const mappedActions = {
	loadCarteTP,
	downloadCarteTP,
	getAffiliation: affiliationAction.getAffiliation
}

export default connect(
	mapStateToProps,
	mappedActions
)(PaymentCardPage)
