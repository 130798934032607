import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import PageLayout from '../../../../components/PageLayout'
import SendContractForm from '../components/SendContractForm'
import { getIndividuList } from '../../../webservices/affiliation/affiliationSelector'
import { connect } from 'react-redux'
import * as affiliationAction from '../../../webservices/affiliation/affiliationActions'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

const SendContractPage = (
	{
		// @ts-ignore
		individuList,
		// @ts-ignore
		getAffiliation
	}
) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()

	useEffect(() => {
		getAffiliation()
	}, [getAffiliation])

	return (
		<PageLayout title={intl.formatMessage({ id: 'sendContract.title' })} bodyPadding={isMobile || isTabletPortrait ? '32px 32px 12px 32px' : '32px 32px 0 32px'}>
			<SendContractForm individuList={individuList || []} />
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	// @ts-ignore
	individuList: getIndividuList(state)
})

export default connect(
	mapStateToProps,
	{
		getAffiliation: affiliationAction.getAffiliation
	}
)(SendContractPage)