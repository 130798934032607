/**
 * Super classe des Articles en cardibox
 */
class GenericArticleCardibox {


  constructor() {
    this.name = '';
    this.shortcut = '';
    this.creationDate = 0;
    this.modificationDate = 0;
  }

  static getFromApiObject(article) {
    if (article) {
      let res = {
        name: article.name,
        shortcut: article.shortcut,
        creationDate: article.creationDate,
        modificationDate: article.modificationDate,
      };

      let fields = {};
      if (article.attributes && article.attributes.length > 0) {
        fields = article.attributes.reduce((prev, attribute) => {
          let values = 0;
          if (attribute.values) {
            if (attribute.values.length === 1) {
              values = attribute.values[0].value;
            } else {
              values = [];
              attribute.values.forEach((attributeValue) => {
                values.push(attributeValue.value);
              })
            }
          }
          return {
            ...prev,
            [attribute.shortcut]: values
          }
        }, {})
      }
      return {
        ...res,
        fields
      }
    }
    return null;
  }
}

export default GenericArticleCardibox;
