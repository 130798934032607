import React, {useEffect} from 'react'
import PageLayout from '../../../../components/PageLayout'
import {useIntl} from 'react-intl'
import {getIndividuList} from '../../../webservices/affiliation/affiliationSelector'
import {connect} from 'react-redux'
import * as affiliationAction from '../../../webservices/affiliation/affiliationActions'
import HospitalizationForm from '../components/HospitalizationForm'
import {dateDebutContratSelector} from '../../user/userSelectors'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'

const HospitalizationPage = (
	{
		// @ts-ignore
		individuList,
		// @ts-ignore
		getAffiliation,
		// @ts-ignore
		dateDebutContrat
	}
) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()

	useEffect(() => {
		getAffiliation()
	}, [getAffiliation])

	return (
		<PageLayout title={intl.formatMessage({ id: 'hospitalization.title' })} bodyPadding={isMobile || isTabletPortrait ? '32px 32px 12px 32px' : '32px 32px 0 32px'}>
			<HospitalizationForm individuList={individuList || []} dateDebutContrat={dateDebutContrat} />
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	// @ts-ignore
	individuList: getIndividuList(state),
	dateDebutContrat: dateDebutContratSelector(state)
})

export default connect(
	mapStateToProps,
	{
		getAffiliation: affiliationAction.getAffiliation
	}
)(HospitalizationPage)
