import {types} from './affiliationActions'
import {types as loginTypes} from '../../../univers/ui/authentication/services/login/loginActions'
import {combineReducers} from 'redux'
import appConst from '../../../constant/appConstants';

const data = (state = {}, action) => {
    switch (action.type) {
        case types.GET_AFFILIATION: {
            return action.payload
        }
        case loginTypes.LOG_USER: {
            if (action.payload) {
                return action.payload.affiliation
            }
            return state
        }
        default:
            return state
    }
}

const loading = (state = false, action) => {
    switch (action.type) {
        case types.GET_AFFILIATION_LOADING: {
            return action.payload
        }
        default:
            return state
    }
}

const expirationDate = (state = 0, action) => {
    switch (action.type) {
        case types.GET_AFFILIATION: {
            return (+new Date() + appConst.cacheDuration.affiliation * 1000)
        }
        case loginTypes.LOG_USER: {
            return (+new Date() + appConst.cacheDuration.affiliation * 1000)
        }
        default:
            return state
    }
}

export default combineReducers({
    data,
    loading,
    expirationDate
})
