import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import ButtonDefault from '../../../../components/ButtonDefault'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { ADD_BENEFICIARY_FIELDS } from '../services/modifyBeneficiaryConstants'
import { BENEFICIARY_TYPES } from '../../../../components/beneficiaryConstants'
import SelectInput from '../../../../components/form/SelectInput'
import TextInput from '../../../../components/form/TextInput'
import DatePickerInput from '../../../../components/form/DatePickerInput'
import FileInput from '../../../../components/fileInput/FileInput'
import RadioInput from '../../../../components/form/RadioInput'
import { mdiInformation } from '@mdi/js'
import { isBefore, isValid } from 'date-fns'
import { ToastContext } from '../../../../components/toast/ToastContext'
import ContactService from '../../../../api/gerep/ContactService'
import LocalStorage from '../../../../business/storage/LocalStorage'
import { ToastType } from '../../../../components/toast/toastConstants'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import Popover from '../../../../components/Popover'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import PatternTextInput from '../../../../components/PatternTextInput'
import { PATTERNS } from '../../../../components/formConstants'
import { SECONDARY_PATH } from '../../../../constant/path'
import { useHistory } from 'react-router-dom'
import NissTextInput from "../../../../components/NissTextInput";
import {toUpperCase} from "../../../../utils/stringUtils";

const ADD_BENEFICIARY_TYPES_DATA = BENEFICIARY_TYPES.map((type: any) => ({
	value: type.value,
	label: type.label
}))

const AddBeneficiaryForm = () => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const history = useHistory()

	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const required = (value: any) => (value ? undefined : intl.formatMessage({ id: 'global.error.required' }))

	const onSubmit = (values: Record<string, any>) => {
		setIsLoading(true)
		const {
			typeBeneficiaire,
			civilite,
			nom,
			prenom,
			dateNaissance,
			numSS,
			organisme,
			numSS2,
			organisme2,
			dateDebut,
			mutuelle,
			mutuelleFile,
			files
		} = values

		const filesUploaded = files.map((file: any, index: number) => ({
			key: `file${index}`,
			value: file
		}))

		const body = [
			{
				key: 'ajout',
				value: {
					typeBeneficiaire,
					civilite,
					nom,
					prenom,
					dateNaissance: new Date(dateNaissance).toLocaleDateString('fr-FR'),
					rangNaissance: '1',
					numSS: numSS && numSS.replace(/ /g, '').replaceAll('_', ''),
					numSS2: numSS2 && numSS2.replace(/ /g, '').replaceAll('_', ''),
					organisme: organisme && organisme.replaceAll('_', ''),
					organisme2: organisme2 && organisme2.replaceAll('_', ''),
					dateDebut: new Date(dateDebut).toLocaleDateString('fr-FR'),
					mutuelle
				}
			},
			...filesUploaded,
			{
				key: 'mutuelleFile',
				value: mutuelleFile
			}
		]

		return ContactService.ajoutBeneficiaire(LocalStorage.getToken(), body)
			.then(() => {
				setOpenConfirmation(true)
				addToast(ToastType.SUCCESS, 'global.success')
			})
			.catch((e) => {
				if (e?.data?._error) {
					addToast(ToastType.ERROR, '', e.data._error)
				} else if (e?.data) {
					return e.data
				} else {
					addToast(ToastType.ERROR, '', 'Echec de l\'ajout du bénéficiaire')
				}
			})
			.finally(() => setIsLoading(false))
	}

	const SEXE_DATA = [
		{
			value: '1',
			label: intl.formatMessage({ id: 'beneficiary.addBeneficiary.input.male' })
		},
		{
			value: '2',
			label: intl.formatMessage({ id: 'beneficiary.addBeneficiary.input.female' })
		}
	]

	// La question est inversée par rapport au webservice
	const YES_OR_NO_DATA = [
		{
			value: 'non',
			label: 'Oui'
		},
		{
			value: 'oui',
			label: 'Non'
		}
	]

	const getFilesMessage = (selectedType: string) => {
		switch (selectedType) {
			case '2_A':
				return intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.otherDocumentFile.underageChild' })
			case '2_B':
				return intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.otherDocumentFile.adultChild' })
			case '1':
				return intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.otherDocumentFile.spouse' })
			case '3':
				return intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.otherDocumentFile.concubine' })
			case '7':
				return intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.otherDocumentFile.pacs' })
			default:
				return ''
		}
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={(values: any) => {
				const errors: any = {}

				const dateNaissance = new Date(values.dateNaissance)
				const dateDebut = new Date(values.dateDebut)
				const dateJour = new Date()
				dateJour.setHours(0, 0, 0, 0)

				const dateMax = new Date()
				dateMax.setMonth(dateMax.getMonth() + 2)
				dateMax.setHours(0, 0, 0, 0)
				const dateMin = new Date()
				dateMin.setDate(dateMin.getDate() - 30)
				dateMin.setHours(0, 0, 0, 0)

				if (!values[ADD_BENEFICIARY_FIELDS.TYPES]) {
					errors[ADD_BENEFICIARY_FIELDS.TYPES] = intl.formatMessage({ id: 'global.error.required' })
				}
				if (!values[ADD_BENEFICIARY_FIELDS.SEXE]) {
					errors[ADD_BENEFICIARY_FIELDS.SEXE] = intl.formatMessage({ id: 'global.error.required' })
				}
				if (!values[ADD_BENEFICIARY_FIELDS.NAME]) {
					errors[ADD_BENEFICIARY_FIELDS.NAME] = intl.formatMessage({ id: 'global.error.required' })
				}
				if (!values[ADD_BENEFICIARY_FIELDS.FIRSTNAME]) {
					errors[ADD_BENEFICIARY_FIELDS.FIRSTNAME] = intl.formatMessage({ id: 'global.error.required' })
				}
				if (!values[ADD_BENEFICIARY_FIELDS.BIRTHDATE]) {
					errors[ADD_BENEFICIARY_FIELDS.BIRTHDATE] = intl.formatMessage({ id: 'global.error.required' })
				} else {
					if (!isValid(dateNaissance)) {
						errors[ADD_BENEFICIARY_FIELDS.BIRTHDATE] = intl.formatMessage({ id: 'global.error.invalidDate' })
					}
					if (dateJour < dateNaissance) {
						errors[ADD_BENEFICIARY_FIELDS.BIRTHDATE] = intl.formatMessage({ id: 'global.error.invalidDate' })
					}
				}
				if (!values[ADD_BENEFICIARY_FIELDS.SS_NUMBER]) {
					errors[ADD_BENEFICIARY_FIELDS.SS_NUMBER] = intl.formatMessage({ id: 'global.error.required' })
				}
				if (!values[ADD_BENEFICIARY_FIELDS.STARTING_DATE]) {
					errors[ADD_BENEFICIARY_FIELDS.STARTING_DATE] = intl.formatMessage({ id: 'global.error.required' })
				} else {
					if (!isValid(dateDebut)) {
						errors[ADD_BENEFICIARY_FIELDS.STARTING_DATE] = intl.formatMessage({ id: 'global.error.invalidDate' })
					}
					if (dateDebut < dateMin) {
						errors[ADD_BENEFICIARY_FIELDS.STARTING_DATE] = intl.formatMessage({ id: 'global.error.startingDateTooOld' })
					}
					if (dateMax < dateDebut) {
						errors[ADD_BENEFICIARY_FIELDS.STARTING_DATE] = intl.formatMessage({ id: 'global.error.invalidDate' })
					}
				}
				if (!values[ADD_BENEFICIARY_FIELDS.MUTUELLE]) {
					errors[ADD_BENEFICIARY_FIELDS.MUTUELLE] = intl.formatMessage({ id: 'global.error.required' })
				}
				if (!values[ADD_BENEFICIARY_FIELDS.SS_FILE] || values[ADD_BENEFICIARY_FIELDS.SS_FILE]?.length === 0) {
					errors[ADD_BENEFICIARY_FIELDS.SS_FILE] = intl.formatMessage({ id: 'global.error.required' })
				}
				if (!values[ADD_BENEFICIARY_FIELDS.FILES] || values[ADD_BENEFICIARY_FIELDS.FILES]?.length === 0) {
					errors[ADD_BENEFICIARY_FIELDS.FILES] = intl.formatMessage({ id: 'global.error.required' })
				}

				return errors
			}}
			render={({ handleSubmit, values, errors, touched, form: { reset } }) => {
				return (
					<form onSubmit={handleSubmit}>
						<Stack direction="column" spacing="24px">
							<Stack direction="row" spacing="24px">
								<Box flex={1}>
									<SelectInput
										name={ADD_BENEFICIARY_FIELDS.TYPES}
										data={ADD_BENEFICIARY_TYPES_DATA}
										label={intl.formatMessage({id: 'beneficiary.addBeneficiary.labels.type'})}
										mandatory
									/>
								</Box>
							</Stack>

							<Stack direction="row" spacing="24px">
								<Box flex={1}>
									<SelectInput
										name={ADD_BENEFICIARY_FIELDS.SEXE}
										data={SEXE_DATA}
										label={intl.formatMessage({id: 'beneficiary.addBeneficiary.labels.sexe'})}
										mandatory
									/>
								</Box>
							</Stack>

							<Stack direction="row" spacing="24px">
								<Box flex={1}>
									<TextInput
										maxWidth="unset"
										name={ADD_BENEFICIARY_FIELDS.NAME}
										label={intl.formatMessage({id: 'beneficiary.addBeneficiary.labels.name'})}
										validate={required}
										mandatory={true}
									/>
								</Box>
							</Stack>

							<Stack direction="row" spacing="24px">
								<Box flex={1}>
									<TextInput
										maxWidth="unset"
										name={ADD_BENEFICIARY_FIELDS.FIRSTNAME}
										label={intl.formatMessage({id: 'beneficiary.addBeneficiary.labels.firstName'})}
										validate={required}
										mandatory={true}
									/>
								</Box>
							</Stack>

							<Stack direction="row" spacing="24px">
								<Box flex={1}>
									<DatePickerInput
										maxWidth="unset"
										name={ADD_BENEFICIARY_FIELDS.BIRTHDATE}
										label={intl.formatMessage({id: 'beneficiary.addBeneficiary.labels.birthdate'})}
										initialValue={new Date('01/01/2000').toLocaleDateString('fr-FR')}
										mandatory={true}
									/>
								</Box>
							</Stack>

							{values[ADD_BENEFICIARY_FIELDS.TYPES] === '2_A' &&
								<Typography variant="body2" color="error">
									<FormattedMessage id="beneficiary.addBeneficiary.affiliatedChildren" />
								</Typography>
							}
							<Stack direction="row" spacing="24px">
								<Box flex="1">
									{/*<PatternTextInput*/}
									{/*	maxWidth="unset"*/}
									{/*	format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
									{/*	mask="_"*/}
									{/*	name={ADD_BENEFICIARY_FIELDS.SS_NUMBER}*/}
									{/*	label={intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.nss' })}*/}
									{/*	popoverContent={*/}
									{/*		<Typography variant="subtitle2">*/}
									{/*			<FormattedMessage id="beneficiary.addBeneficiary.popoverContent.nss" />*/}
									{/*		</Typography>*/}
									{/*	}*/}
									{/*	validate={required}*/}
									{/*/>*/}
									<NissTextInput
										name={ADD_BENEFICIARY_FIELDS.SS_NUMBER}
										label={intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.nss' })}
										popoverContent={
											<Typography variant="subtitle2">
												<FormattedMessage id="beneficiary.addBeneficiary.popoverContent.nss" />
											</Typography>
										}
										maxWidth="unset"
										validate={required}
										format={toUpperCase}
										mandatory
									/>
								</Box>
								{values[ADD_BENEFICIARY_FIELDS.TYPES] === '2_A' &&
									<Box flex="1">
										{/*<PatternTextInput*/}
										{/*	maxWidth="unset"*/}
										{/*	format={PATTERNS.SOCIAL_SECURITY_NUMBER}*/}
										{/*	mask="_"*/}
										{/*	name={ADD_BENEFICIARY_FIELDS.SS_NUMBER2}*/}
										{/*	label={intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.nss2' })}*/}
										{/*	popoverContent={*/}
										{/*		<Typography variant="subtitle2">*/}
										{/*			<FormattedMessage id="beneficiary.addBeneficiary.popoverContent.nss" />*/}
										{/*		</Typography>*/}
										{/*	}*/}
										{/*/>*/}
										<NissTextInput
											name={ADD_BENEFICIARY_FIELDS.SS_NUMBER2}
											label={intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.nss2' })}
											popoverContent={
												<Typography variant="subtitle2">
													<FormattedMessage id="beneficiary.addBeneficiary.popoverContent.nss" />
												</Typography>
											}
											format={toUpperCase}
											maxWidth="unset"
										/>
									</Box>
								}
							</Stack>

							<Stack direction="row" spacing="24px">
								<Box flex={1}>
									<PatternTextInput
										maxWidth="unset"
										format={PATTERNS.ORGANISME}
										mask="_"
										name={ADD_BENEFICIARY_FIELDS.COMPANY}
										label={intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.company' })}
										popoverContent={
											<Typography variant="subtitle2">
												<FormattedMessage id="beneficiary.addBeneficiary.popoverContent.company" values={{ br: <br /> }} />
											</Typography>
										}
									/>
								</Box>
								{values[ADD_BENEFICIARY_FIELDS.TYPES] === '2_A' &&
									<Box flex={1}>
										<PatternTextInput
											maxWidth="unset"
											format={PATTERNS.ORGANISME}
											mask="_"
											name={ADD_BENEFICIARY_FIELDS.COMPANY2}
											label={intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.company2' })}
										/>
									</Box>
								}
							</Stack>

							<Stack direction="row" spacing="24px">
								<Box flex={1}>
									<DatePickerInput
										maxWidth="unset"
										name={ADD_BENEFICIARY_FIELDS.STARTING_DATE}
										label={intl.formatMessage({id: 'beneficiary.addBeneficiary.labels.startingDate'})}
										initialValue={new Date().toLocaleDateString('fr-FR')}
										mandatory={true}
									/>
								</Box>
							</Stack>

							<Stack direction="row" spacing="24px">
								<Box flex={1}>
									<RadioInput
										name={ADD_BENEFICIARY_FIELDS.MUTUELLE}
										label={
											<>
												<FormattedMessage id="beneficiary.addBeneficiary.labels.isGerepFirstInsurance" />
												<span> *</span>
											</>
										}
										data={YES_OR_NO_DATA}
										error={touched && touched[ADD_BENEFICIARY_FIELDS.MUTUELLE] && errors && errors[ADD_BENEFICIARY_FIELDS.MUTUELLE]}
									/>
								</Box>
							</Stack>

							<Stack direction="row">
								<Box flex={1}>
									<FileInput
										maxFileNumber={1}
										name={ADD_BENEFICIARY_FIELDS.SS_FILE}
										title={intl.formatMessage({ id: 'beneficiary.addBeneficiary.input.ssFile' })}
										label={
											<>
												<FormattedMessage id="beneficiary.addBeneficiary.labels.ssDocumentFile" />
												<Popover iconPath={mdiInformation}>
													<Box p={2} maxWidth="242px">
														<Typography variant="subtitle2">
															<FormattedMessage id="beneficiary.addBeneficiary.popoverContent.attestation" />
														</Typography>
													</Box>
												</Popover>
												<span> *</span>
											</>
										}
										error={errors && errors[ADD_BENEFICIARY_FIELDS.SS_FILE]}
									/>
								</Box>
							</Stack>

							<Stack direction="row">
								<Box flex={1}>
									<FileInput
										name={ADD_BENEFICIARY_FIELDS.FILES}
										title={getFilesMessage(values[ADD_BENEFICIARY_FIELDS.TYPES])}
										label={intl.formatMessage({ id: 'beneficiary.addBeneficiary.labels.otherDocumentFile.title' })}
										error={errors && errors[ADD_BENEFICIARY_FIELDS.FILES]}
									/>
								</Box>
							</Stack>

							<Stack alignItems="center">
								<ButtonDefault
									variant="contained"
									onClick={handleSubmit}
								>
									{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
								</ButtonDefault>
								<Box display="flex" width="100%" flex={1} alignSelf={isMobile || isTabletPortrait ? 'unset' : 'baseline'}>
									<Typography sx={{ position: isMobile || isTabletPortrait ? 'unset' : 'relative', bottom: '32px', left: 0, height: '32px', display: 'flex', alignItems: 'flex-end' }} variant="body2" color="#7a7a7a">
										<FormattedMessage id="global.mandatory" />
									</Typography>
								</Box>
							</Stack>
						</Stack>
						<ConfirmationPopin
							title={intl.formatMessage({ id: 'beneficiary.addBeneficiary.title' })}
							open={openConfirmation}
							setOpen={setOpenConfirmation}
							resetForm={reset}
							goBackAction={{
								action: () => history.push(SECONDARY_PATH.ACCOUNT_BENEFICIARY),
								title: intl.formatMessage({ id: 'global.button.goBackBeneficiary' })
							}}
						/>
					</form>
				)
			}}
		/>
	)
}

export default AddBeneficiaryForm
