import { createSelector } from 'reselect'
import {
	getAffiliationAssurePrincipal,
	getAffiliationBeneficiairesForManage,
	getTousAssuresPrincipaux
} from '../affiliation/affiliationSelector'
import FormatUtils from '../../../utils/FormatUtils'
import TypeContrat from '../../../constant/TypeContrat'

const getContratsByCompanies = state => state.contrats.data && state.contrats.data
export const isContratsLoading = state => state.contrats.loading
export const getExpirationDate = state => state.contrats.expirationDate


const transformGarantie = g => ({
	...g,
	typeGar: g.typeGar === 'Garantie optionnelle' ? 'Garantie santé optionnelle' : 'Garantie Santé'
})

const selectGarantieToDisplay = (g) => {
	// on affiche uniquement les garanties qui portent des prestations
	if (g.deleg !== 2 && g.deleg !== 3) {
		return false
	}
	if (g.dateFin) {
		const dateFin = new Date(FormatUtils.toLocalDate(g.dateFin))
		const dateJour = new Date()
		return dateFin > dateJour
	}
	return true
}

const getContratsSanteByCompanies = createSelector(
	[getContratsByCompanies], contrats => (
		contrats.filter(contrat => (
			contrat.nature === TypeContrat.SANTE || contrat.nature === TypeContrat.SANTE_PREVOYANCE
		))
	)
)

/**
 * Le contrat santé de base de l'assuré principal.
 */
export const getContratBaseSante = createSelector([
	getTousAssuresPrincipaux,
	getContratsSanteByCompanies
], (affiliationsAssurePrincipal, contrats) => {
	if (affiliationsAssurePrincipal.length > 0 && contrats.length > 0) {
		const contratBase = affiliationsAssurePrincipal
			.map((affiliationAssurePrincipal) => {
				// on remplace l'aff par le contrat associé
				return contrats.find(contrat => contrat.numgar === affiliationAssurePrincipal.numgar)
			})
			.find((_contrat) => {
				// on retourne le premier contrat de base
				return _contrat && _contrat.base
			})
		if (contratBase) {
			const {
				querableNom,
				siret,
				numgar
			} = contratBase
			return {
				name: querableNom,
				siret,
				numgar
			}
		}
	}
})

export const getContratsSante = createSelector(
	getAffiliationAssurePrincipal,
	getAffiliationBeneficiairesForManage,
	getContratsSanteByCompanies,
	/**
	 * On commence par récupérer les garanties de l'assuré principal.
	 *
	 * Chaque garantie est lié à un contrat sante.
	 *
	 * On liste l'ensemble des garanties de l'assuré principal.
	 * On identifie ces garanties par le numéro de contrat + typeGar (Base ou Option)
	 * Pour chaque garantie de l'assuré principal, on considère qu'un bénéficiaire y est rattaché s'il possède une garantie ayant le même identifiant (numéro de contrat + typGar)
	 *
	 * Le regroupement retourne la liste [{
	 * 	garantie: la garantie de l'assure principale
	 * 	beneficiaires : les beneficiaire rattache et leur garantie
	 * }]
	 *
	 * @param assurePrincipal, l'assuré principal
	 * @param beneficiaires, les bénéficiaires
	 * @param contratsSante, les contrats santé
	 * @returns {*}
	 */
	(assurePrincipal, beneficiaires, contratsSante) => {
		if (!contratsSante || contratsSante.length === 0) {
			return []
		}
		// sans assuré principal, il n'y a rien à afficher
		if (!assurePrincipal) {
			return []
		}
		// récupération des garanties de l'assuré principal
		const affiliationList = [
			assurePrincipal,
			...beneficiaires
		]
		const assurePrincipalAffiliations = affiliationList.filter(b => b.numIndividu === assurePrincipal.numIndividu)

		let garantiesAssurePrincipal = []
		assurePrincipalAffiliations.forEach(aff => {
			aff.garanties.forEach(g => {
				const idGarantie = `${aff.refCie}_${g.typeGar.indexOf('option') !== -1 ? 'OPT' : 'BASE'}`
				garantiesAssurePrincipal.push({
					...g,
					refcie: aff.refCie,
					idGarantie
				})
			})
		})
		// filtrage des garanties sante
		garantiesAssurePrincipal = garantiesAssurePrincipal.filter(g => contratsSante.find(c => c.refcie === g.refcie))
		garantiesAssurePrincipal = garantiesAssurePrincipal.filter(selectGarantieToDisplay)

		const contrats = []
		garantiesAssurePrincipal.forEach(garantieMaitre => {
			// pour chaque contrat, on va chercher ses bénéficiaires par rapport à la garantie maître
			const beneficiairesContrat = affiliationList.map(aff => {
				// la garantie portant le même numFormule MAIS version bénéficiaire
				const garantieBeneficiaire = aff.garanties.find(g => {
					const idGarantie = `${aff.refCie}_${g.typeGar.indexOf('option') !== -1 ? 'OPT' : 'BASE'}`
					return idGarantie === garantieMaitre.idGarantie
				})
				if (garantieBeneficiaire && selectGarantieToDisplay(garantieBeneficiaire)) {
					return {
						...aff,
						infoGarantie: garantieBeneficiaire
					}
				}
				return null
			}).filter(b => b)

			if (beneficiairesContrat.length > 0) {
				contrats.push({
					garantie: transformGarantie(garantieMaitre),
					beneficiaires: beneficiairesContrat
				})
			}
		})
		return contrats
			.sort((c1, c2) => {
				const typGar1 = c1.garantie.typeGar === 'Garantie santé optionnelle' ? 2 : 1
				const typGar2 = c2.garantie.typeGar === 'Garantie santé optionnelle' ? 2 : 1
				const diffTypeGar = typGar1 - typGar2
				return diffTypeGar !== 0 ? diffTypeGar : (c1.numgar - c2.numgar)
			})
	}
)
