import { Stack, Typography, useTheme } from '@mui/material'
import React, { useRef, useState } from 'react'
import { Field } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import ButtonFile from './ButtonFile'
import BackupIcon from '@mui/icons-material/Backup'
import Box from '@mui/material/Box'
import HeightIcon from '@mui/icons-material/Height'
import BuildIcon from '@mui/icons-material/Build'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FileDisplay from './FileDisplay'
import { useBreakpoints } from '../breakpoints/BreakpointsProvider'

const ACCEPTED_FILE_TYPES = ['.pdf', '.jpg', '.jpeg', '.png', '.gif', '.bmp']
const MAX_FILE_SIZE = 5000000
const MAX_FILE_SIZE_MB = MAX_FILE_SIZE / 1000000

type FileInputProps = {
	name: string
	error?: string
	title: string | React.ReactNode
	label?: string | React.ReactNode
	required?: boolean,
	maxFileNumber?: number
}

const FileInput: React.FC<FileInputProps> = (
	{
		name,
		title,
		required,
		label,
		maxFileNumber = 4
	}) => {
	const intl = useIntl()
	const theme = useTheme()
	const inputRef = useRef<HTMLInputElement>(null)
	const [isError, setIsError] = useState<boolean>(false)
	const { isMobile, isTabletPortrait } = useBreakpoints()


	const maxFilesErrorMessage = 'form.fileinput.error.maxFiles'
	const noSelectedErrorMessage = 'form.fileinput.error.noSelected'

	const getFileTypes = (type: string) => {
		if (type.includes('image')) {
			return `.${type.split('image/')[1]}`
		} else if (type.includes('application')) {
			return `.${type.split('application/')[1]}`
		} else {
			return ''
		}
	}

	return (
		<Field
			name={name}
			validate={(value: File[]) => {
				const filesSize = value && value.reduce((acc, file) => acc + file.size, 0)

				if (value && value.length > maxFileNumber) {
					return intl.formatMessage({ id: maxFilesErrorMessage })
				} else if (value && value.length === 0) {
					return intl.formatMessage({ id: noSelectedErrorMessage })
				} else if (filesSize && value && value.length > 1 && (filesSize > maxFileNumber * MAX_FILE_SIZE)) {
					return intl.formatMessage({ id: 'global.error.totalSizeTooHeavy' })
				} else if (value && value[value.length - 1].size > MAX_FILE_SIZE) {
					return intl.formatMessage({ id: 'form.fileinput.error.maxSize' }, { size: MAX_FILE_SIZE_MB })
				} else if (value && getFileTypes(value[value.length - 1].type) && !ACCEPTED_FILE_TYPES.includes(getFileTypes(value[value.length - 1].type))) {
					return intl.formatMessage({ id: 'form.fileinput.error.acceptedFormats' }, { formats: ACCEPTED_FILE_TYPES.join(', ') })
				}

				return undefined
			}}
			render={({ input, meta: { error, touched, submitError } }) => {
				const handleFiles = (filesList: FileList | undefined | null) => {
					const maxFilesReached = input.value && input.value.length === maxFileNumber
					if (filesList !== undefined && filesList !== null && !maxFilesReached) {
						const filesArray = Array.from(filesList)
						const filesSize = filesArray.reduce((acc, file) => acc + file.size, 0)
						filesArray.some((file) => {
							if (!ACCEPTED_FILE_TYPES.some((type) => file.name.toLowerCase().endsWith(type))) {
								setIsError(true)
							} else if (file.size > MAX_FILE_SIZE) {
								setIsError(true)
							} else if (maxFilesReached) {
								setIsError(true)
							} else if (filesSize > maxFileNumber * MAX_FILE_SIZE) {
								setIsError(true)
							} else {
								setIsError(false)
							}
							return file.size > MAX_FILE_SIZE || !ACCEPTED_FILE_TYPES.some((type) => file.name.toLowerCase().endsWith(type)) || maxFilesReached || filesSize > maxFileNumber * MAX_FILE_SIZE
						})
						input.onChange([...input.value, ...filesArray])
					}
				}

				const handleChange = () => {
					const filesList = inputRef.current?.files
					handleFiles(filesList)
				}

				const handleDropChange = (event: React.DragEvent<HTMLButtonElement>) => {
					const { files } = event.dataTransfer
					handleFiles(files)
				}

				return (
					<>
						<input
							accept={ACCEPTED_FILE_TYPES.join(',')}
							id="fileId"
							type="file"
							style={{ display: 'none' }}
							ref={inputRef}
							onChange={handleChange}
							multiple
							onClick={(event) => (event.target as HTMLInputElement).value = ''}
						/>
						<Stack direction="column" spacing="12px">
							{label &&
								typeof label === "string" ? (
									<Typography variant="body2">
										{label} {required ? (<span>*</span>) : ''}
									</Typography>
								) : (
									label
								)
							}
							<Stack spacing={1}>
								<Stack spacing={2} direction={isMobile || isTabletPortrait ? 'column' : 'row'}>
									<Stack spacing={isMobile || isTabletPortrait ? 5 : 2} direction="row">
										<Stack direction="row" alignItems="center">
											<HeightIcon color="secondary" />
											<Typography variant="subtitle2" sx={{ color: '#7a7a7a' }}>
												<FormattedMessage id="form.fileinput.maxSize" values={{ size: 5 }} />
											</Typography>
										</Stack>
										<Stack flex={1} direction="row" alignItems="center" spacing={1}>
											<BuildIcon color="secondary" fontSize="small" />
											<Typography variant="subtitle2" sx={{ color: '#7a7a7a' }}>
												<FormattedMessage id="form.fileinput.acceptedFormat" values={{ formats: ACCEPTED_FILE_TYPES.map(type => type.substring(1)).join(', ') }} />
											</Typography>
										</Stack>
									</Stack>
									<Stack direction="row" alignItems="center" spacing={1}>
										<ContentCopyIcon color="secondary" fontSize="small" />
										<Typography variant="subtitle2" sx={{ color: '#7a7a7a' }}>
											<FormattedMessage id="form.fileinput.maxFiles" values={{ files: maxFileNumber }} />
										</Typography>
									</Stack>
								</Stack>
								<Box>
									<ButtonFile
										variant="outlined"
										disabled={(input.value && input.value.length === maxFileNumber) || (input.value && input.value.length > 0 && (isError || (touched && (error || submitError))))}
										error={(isError || (touched && (error || submitError)))}
										startIcon={<BackupIcon fontSize="large" sx={{ width: '40px', height: '40px', color: isError ? theme.palette.error.main : '#f6c5a0' }} />}
										onClick={() => inputRef?.current?.click()}
										onDrop={handleDropChange}
									>
										<Typography fontWeight={500} fontSize="0.875rem" color={isError || ((error || submitError) && touched) ? theme.palette.error.main : 'black'}>
											{title}
										</Typography>
										<Typography variant="subtitle1" sx={{ color: '#7a7a7a' }}>
											<FormattedMessage id="form.fileinput.button.webSubtitle" />
										</Typography>
									</ButtonFile>
								</Box>
								<Stack spacing={1}>
									{input.value && input.value.map((file: any, index: number) => (
										<FileDisplay
											key={index}
											name={file.name}
											onRemove={() => {
												setIsError(false)
												const files = input.value
												input.onChange(files.filter((_: any, i: number) => i !== index))
											}}
										/>
									))}
								</Stack>
								{(isError || (touched && (error || submitError))) && (
									<Typography variant="subtitle1" sx={{ color: theme.palette.error.main }}>
										{error || submitError}
									</Typography>
								)}
							</Stack>
						</Stack>
					</>
				)
			}}
		/>
	)
}

export default FileInput