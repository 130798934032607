import React, {createContext, useState} from 'react'
import {useBreakpoints} from '../breakpoints/BreakpointsProvider'
import { NavMenuEA, NavMenuEACarteTp, NavMenuEE } from '../NavMenu'
import {Stack} from '@mui/material'
import Box from '@mui/material/Box'
import {useHistory, useLocation} from 'react-router-dom'
import ButtonFlottant from '../ButtonFlottant'
import {FormattedMessage} from 'react-intl'
import {HelpOutline} from '@mui/icons-material'
import {MAIN_PATH} from '../../constant/path'
import AppBar from '../appbar/AppBar'
import AppBarEE from '../appbar/AppBarEE'
import Loader from '../Loader'

const NavMenuContext = createContext({})

type NavMenuProviderProps = {
	children: React.ReactNode,
	isEntreprise: boolean
	isCarteTP?: boolean
}

const NavMenuProvider: React.FC<NavMenuProviderProps> = ({ children, isEntreprise, isCarteTP = false }) => {
    const {isDesktop, isWidescreen, isMobile, isTabletPortrait, isTablet, height} = useBreakpoints()
    const location = useLocation()
    const history = useHistory()

    const isMobileOrTablet = isMobile || isTablet || isTabletPortrait
    const isDashboard = location.pathname.includes(MAIN_PATH.DASHBOARD)

    const [loading, setLoading] = useState<boolean>(false)

    return (
        <NavMenuContext.Provider value={{}}>
            <Stack
                bgcolor="#f7f5f4"
                direction="row"
                width="100%"
                onDragOver={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                }}
                onDrop={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                }}
            >
                {!isMobileOrTablet && (
                    <>
                        {isEntreprise ?
                            <NavMenuEE/>
                            :
                            (isCarteTP ? <NavMenuEACarteTp setLoading={setLoading}/> : <NavMenuEA />)
                        }
                    </>
                )}
                <Stack direction="column" width="100%" spacing={0}>
                    {isEntreprise ?
                        <AppBarEE isEntreprise={isEntreprise} displayInfo={!isDashboard}/>
                        :
                        <AppBar isEntreprise={isEntreprise} displayInfo={!isDashboard}/>
                    }
                    <Box
                        display="flex"
                        minHeight={height}
                        px={isDesktop || isWidescreen ? '50px' : '12px'}
                        pb={isMobile ? '80px' : '14px'}
                        sx={{bgcolor: '#f7f5f4', overflow: 'hidden', overflowY: 'scroll'}}
                        marginLeft={!isMobileOrTablet ? '276px' : '0px'}
                        justifyContent="center"
                    >
                        {loading ?
                            <Loader />
                            :
                            <>{children}</>
                        }
                    </Box>
                </Stack>
				{!isEntreprise && !isCarteTP && !location.pathname.includes(MAIN_PATH.HELP) &&
                    <ButtonFlottant onClick={() => history.push(MAIN_PATH.HELP)} variant="contained"
                                    startIcon={<HelpOutline/>}>
                        <FormattedMessage id="global.button.help"/>
                    </ButtonFlottant>
                }
            </Stack>
        </NavMenuContext.Provider>
    )
}

export default NavMenuProvider
