import React from 'react'
import { Stack, Typography } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../../../../components/ButtonDefault'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { listCellType } from '../services/remboursementConstants'
import { styled } from '@mui/material/styles'
import RemboursementCell from './RemboursementCell'
import { getIndividuList } from '../../../webservices/affiliation/affiliationSelector'
import { connect } from 'react-redux'
import DownloadRelevesPopin from './DownloadRelevesPopin'

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props}>
		{props.children}
	</MuiAccordion>
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0
	},
	'&:before': {
		display: 'none'
	}
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ExpandMoreIcon />}
		{...props}
	/>
))(({ theme }) => ({
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1)
	}
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

const RemboursementListCell = (
	{
		// @ts-ignore
		data,
		// @ts-ignore
		individuList
	}) => {

	const { isDesktop, isLaptop, isWidescreen } = useBreakpoints()
	const isDesktopOrLaptop = isDesktop || isLaptop || isWidescreen

	const [expanded, setExpanded] = React.useState<string | false>('panel0')
	const [openPopin, setOpenPopin] = React.useState(false)

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false)
		}

	return (
		<Stack direction="column">
			<Stack direction={isDesktopOrLaptop ? 'row' : 'column'} spacing="24px" pb="10px">
				<Typography flex={10} variant="h2">
					<FormattedMessage id="remboursementConsultation.list.title" />
				</Typography>
				<ButtonDefault
					variant="outlined"
					onClick={() => setOpenPopin(true)}
				>
					<FormattedMessage id="remboursementConsultation.download" />
				</ButtonDefault>
			</Stack>
			<Stack direction="column">
				{data.data.map((entry: any, index: number) => {
					if (entry.type === listCellType.header) {
						return (
							<Stack key={index} flex={1} direction="row" alignItems="center" sx={{ backgroundColor: '#dedede', minHeight: '43px' }}>
								<Typography variant="h4" sx={{ paddingLeft: '24px', color: '#606060', textTransform: 'uppercase' }}>
									{entry.title}
								</Typography>
							</Stack>
						)
					} else {
						return (
							<Accordion key={index} expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon sx={{ color: '#000000', fontSize: '40px', minWidth: '36px' }} />}
									aria-controls={'panel' + index + 'd-content'}
									id={'panel' + index + 'd-header'}
								>
									<Stack flex={10} direction="row" sx={{ paddingLetf: '12px' }}>
										<Stack flex={6} direction="column">
											<Typography variant="h4">
												<FormattedMessage id="remboursementConsultation.list.cell.header.title" values={{ date: entry.title }} />
											</Typography>
											<Typography variant="subtitle1">
												<FormattedMessage id="remboursementConsultation.list.cell.header.subtitle" values={{ who: entry.subtitle }} />
											</Typography>
										</Stack>
										<Stack flex={4} direction="row" alignContent="flex-end" justifyContent="flex-end">
											<Typography variant="h3">
												{entry.accessoryString}
											</Typography>
										</Stack>
									</Stack>
								</AccordionSummary>
								<AccordionDetails>
									<Stack direction="column">
										{
											entry.children.map((children: any, indexChildren: number) => {
													const beneficiary = individuList.filter((individu: any) => {
														return individu[0] === children.numBeneficiaire
													})
													return (
														<RemboursementCell key={indexChildren} children={children} beneficiary={beneficiary[0]} />
													)
												}
											)}
									</Stack>
								</AccordionDetails>
							</Accordion>
						)
					}

				})}
			</Stack>
			<DownloadRelevesPopin open={openPopin} setOpen={setOpenPopin} />
		</Stack>
	)
}

const mapStateToProps = (state: any) => ({
	// @ts-ignore
	individuList: getIndividuList(state)
})


export default connect(
	mapStateToProps
)(RemboursementListCell)
