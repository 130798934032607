import React from 'react'
import BankAccountRibCell from './BankAccountRibCell'
import { NATURE } from '../../services/personalInfoConstants'
import BankAccountChequeCell from './BankAccountChequeCell'

type BankAccountRemboursementCell = {
	ribInfo: any
	ribs: any[]
	individuList: any[]
	assurePrincipal: any
	haveBBAN?: boolean
}

const BankAccountRemboursementCell: React.FC<BankAccountRemboursementCell> = (
	{
		ribInfo,
		ribs,
		individuList,
		assurePrincipal,
		haveBBAN
	}) => {
	if (ribInfo?.nature === NATURE.VIREMENT) {
		return (
			<BankAccountRibCell
				ribInfo={ribInfo}
				ribs={ribs}
				individuList={individuList}
				assurePrincipal={assurePrincipal}
				haveBBAN={haveBBAN}
			/>
		)
	} else if (ribInfo?.nature === NATURE.CHEQUE) {
		return (
			<BankAccountChequeCell
				ribInfo={ribInfo}
				ribs={ribs}
				individuList={individuList}
				assurePrincipal={assurePrincipal}
			/>
		)
	} else {
		return null
	}
}

export default BankAccountRemboursementCell
