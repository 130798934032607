import React from 'react'

type BreakpointsContextProps = {
	isWidescreen: boolean
	isDesktop: boolean
	isLaptop: boolean
	isTablet: boolean
	isTabletPortrait: boolean
	isMobile: boolean
	width: number
	height: number
}

const BreakpointsContext = React.createContext<BreakpointsContextProps>({
	isWidescreen: false,
	isDesktop: false,
	isLaptop: false,
	isTablet: false,
	isTabletPortrait: false,
	isMobile: false,
	width: 0,
	height: 0
})

type BreakpointsProviderProps = {
	children: React.ReactNode
}

const BreakpointsProvider: React.FC<BreakpointsProviderProps> = ({ children }) => {
	const [isWidescreen, setIsWidescreen] = React.useState<boolean>(false)
	const [isDesktop, setIsDesktop] = React.useState<boolean>(false)
	const [isLaptop, setIsLaptop] = React.useState<boolean>(false)
	const [isTablet, setIsTablet] = React.useState<boolean>(false)
	const [isTabletPortrait, setIsTabletPortrait] = React.useState<boolean>(false)
	const [isMobile, setIsMobile] = React.useState<boolean>(false)
	const [width, setWidth] = React.useState<number>(0)
	const [height, setHeight] = React.useState<number>(0)

	const checkBreakpoint = (query: string) => {
		return window.matchMedia(query).matches
	}

	const handleWindowResize = () => {
		const newWidth = window.innerWidth
		const newHeight = window.innerHeight

		setIsWidescreen(checkBreakpoint('(min-width: 1920px)'))
		setIsDesktop(checkBreakpoint('(min-width: 1440px) and (max-width: 1919px)'))
		setIsLaptop(checkBreakpoint('(min-width: 1280px) and (max-width: 1439px)'))
		setIsTablet(checkBreakpoint('(min-width: 1024px) and (max-width: 1279px)'))
		setIsTabletPortrait(checkBreakpoint('(min-width: 600px) and (max-width: 1023px)'))
		setIsMobile(checkBreakpoint('(max-width: 599px)'))

		setWidth(newWidth)
		setHeight(newHeight)
	}

	React.useEffect(() => {
		window.addEventListener('resize', handleWindowResize)
		handleWindowResize()
		return () => window.removeEventListener('resize', handleWindowResize)
	}, [])

	return (
		<BreakpointsContext.Provider value={{ isWidescreen, isDesktop, isLaptop, isTablet, isTabletPortrait, isMobile, width, height }}>
			{children}
		</BreakpointsContext.Provider>
	)
}

export default BreakpointsProvider

export const useBreakpoints = () => {
	const { isWidescreen, isDesktop, isLaptop, isTablet, isTabletPortrait, isMobile, width, height } = React.useContext(BreakpointsContext)
	return { isWidescreen, isDesktop, isLaptop, isTablet, isTabletPortrait, isMobile, width, height }
}