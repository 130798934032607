import React, {Dispatch, FC, SetStateAction} from 'react'
import Popup from '../../../../components/Popup'
import {FormattedMessage, useIntl} from 'react-intl'
import {Typography} from '@mui/material'

type ContratNotAvailableProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}


const ContratNotAvailable : FC<ContratNotAvailableProps> = (
	{
		open,
		setOpen
	}) => {
	const intl = useIntl()

	return (
		<Popup
			maxWidth="600px"
			open={open}
			setOpen={setOpen}
			title={intl.formatMessage({ id: 'contrat.contratNotAvailable.title' })}
		>
			<Typography variant="body1">
				<FormattedMessage id="contrat.contratNotAvailable.body" />
			</Typography>
		</Popup>
	)
}

export default ContratNotAvailable
