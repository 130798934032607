import { types } from './NotificationActions'
import { Map } from 'immutable'
import { combineReducers } from 'redux'
import configureCache from '../../../utils/cache'
import appConst from '../../../constant/appConstants'

const _cache = configureCache(appConst.cacheDuration.default)

/**
 * Les objets complets.
 */
const objects = (state = new Map(), action = {}) => {
	switch (action.type) {
		case types.GET_ALL:
			return action.payload.reduce((map, element) => map.set(element.id, element), new Map())
		case types.ADD: {
			const element = action.payload
			return state.set(element.id, element)
		}
		case types.REMOVE:
			return state.delete(action.payload.id)
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case types.GET_ALL_LOADING: {
			return action.payload
		}
		default:
			return state
	}
}

/**
 * Si des données ont été chargées.
 */
const loaded = (state = false, action) => {
	switch (action.type) {
		case types.GET_ALL: {
			return true
		}
		default:
			return state
	}
}

const expirationDate = (state = 0, action) => {
	switch (action.type) {
		case types.GET_ALL:
			return _cache.mark()
		default:
			return state
	}
}

export default combineReducers({
	objects,
	loading,
	loaded,
	expirationDate
})
