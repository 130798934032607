export enum MAIN_PATH_UNIVERS {
    LOGIN_EA = '/particuliers/login',
    ACTIVATION = '/particuliers/activation/:idAdherent/:codeActivation',
    LOGIN_EE = '/entreprises/login',
    ACTIVATION_EE = '/entreprises/activation/:idAdherent/:codeActivation',
    LOGIN_PREAFFILIATION_BIS = '/affiliations',
    LOGIN_PREAFFILIATION = '/affiliations/login',
    HOME_PREAFFILIATION = '/affiliations/home',
    PREAFFILIATION_SOUSCRIPTION = '/affiliations/souscription',
    NOT_FOUND = '/error/404',
    INTERNAL_ERROR = '/error/500',
    NOT_AUTHORIZED = '/error/401',
    DEFAULT = '/',
}

export enum MAIN_PATH {
    DASHBOARD = '/home',
    REFUND = '/remboursements',
    ACCOUNT = '/compte',
    SERVICES = '/services',
    HELP = '/help',
    LOGOUT = '/logout',
    LOGIN = '/login',
    COMPONENTS = '/components',
    FORCE_RESET_PASSWORD = '/reset-password',
    ACTIVATE_DOUBLE_AUTHENT = '/activate-double-authentification',
    CARTE_TP = '/carte-tp',
    DEFAULT = '/',
    OAUTH_TOKEN = '/oauth/authorize',
}

export enum MAIN_PATH_EE {
    DASHBOARD = '/home',
    ACCOUNT = '/compte',
    CONTACT = '/contact',
    LOGOUT = '/logout',
    LOGIN = '/login',
    FORCE_RESET_PASSWORD = '/reset-password',
    ACTIVATE_DOUBLE_AUTHENT = '/activate-double-authentification',
    DEFAULT = '/',
    SANTE = '/sante',
    SANTE_CONSOMMATION= '/sante/consommation',
    SANTE_FINANCE_CONTISATION= '/sante/finance-cotisation',
    SANTE_DEMOGRAPHIE= '/sante/demographie',
    PREVOYANCE_ARRET_TRAVAIL= '/prevoyance/arret-travail',
    QUALITE_SERVICE= '/qualite-service',
    CONTACTER_GEREP= '/contacter/gerep'
}

export enum SECONDARY_PATH {
    REFUND_LIST = '/remboursements/list',
    REFUND_DEMAND = '/remboursements/demande-remboursement',
    ACCOUNT_PERSONAL_DATA = '/compte/informations-personnelles',
    ACCOUNT_CONTRACT = '/compte/contrats',
    ACCOUNT_SUBSCRIPTION = '/compte/souscription',
    ACCOUNT_BENEFICIARY = '/compte/beneficiaires',
    ACCOUNT_CARD = '/compte/carte-tiers-payant',
    ACCOUNT_DEADLINE = '/compte/echeancier',
    SERVICES_SEND_QUOTE = '/services/transmettre-devis',
    SERVICES_HOSTPITALIZATION = '/services/demander-prise-en-charge-hospitaliere',
    SERVICES_TELECONSULTATION = '/services/sante',
    SERVICES_HISTORIQUE_DEMANDE = '/compte/historique-demandes',
    SERVICES_EXTEND_COVER = '/services/formulaire-prolongation-couverture-ani',
    SERVICES_ADULT_CHILDREN = '/services/enfant-majeur',
    CONTACT_MODIFY_SOCIAL_SECURITY = '/contact/formulaire-modification-informations-securite-sociale',
    CONTACT_DELETE_BENEFICIARY = '/contact/formulaire-radiation-beneficiaire',
    CONTACT_ADD_BENEFICIARY = '/contact/formulaire-ajout-beneficiaire',
    SERVICES_SIMULATION = '/services/simulateur-remboursement',
    CONTACT_MISSING_FILE = '/contact/piece-manquante',
    CONTACT_POLE_EMPLOI = '/contact/parcours-pole-emploi',
    CONTACT_REFUND_DOCUMENT = '/contact/justificatif-remboursement',
    CONTACT_MISSING_FILE_GLOBAL = '/contact/:domain/piece-manquante',
    ACCOUNT_BIRTH_CERTIFICATE = '/compte/acte-naissance-formulaire',
}
