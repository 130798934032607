import React from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import { FormattedMessage, useIntl } from 'react-intl'
import { Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { isAffiliationLoading } from '../../../webservices/affiliation/affiliationSelector'
import { useHistory } from 'react-router-dom'
import { SECONDARY_PATH } from '../../../../constant/path'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import {maskEmail, maskNumeroAssure, maskTelephone} from '../../../../utils/authentUtils'


type PersonalDataCardProps = {
	affiliation: any
	affiliationExpired: boolean
	user: any
}

const PersonalDataCard: React.FC<PersonalDataCardProps> = (
	{
		affiliation,
		affiliationExpired,
		user,
		// @ts-ignore
		loading
	}) => {
	const intl = useIntl()
	const history = useHistory()
	const { isTabletPortrait } = useBreakpoints()

	return (
		<DashboardCard
			title={intl.formatMessage({ id: 'dashboard.personalData.title' })}
			action={!affiliationExpired ? {
				label: intl.formatMessage({ id: 'global.button.seeAll' }),
				onClick: () => history.push(SECONDARY_PATH.ACCOUNT_PERSONAL_DATA)
			} : undefined}
			loading={loading}
		>
			<Stack width="100%" direction="column" spacing={1}>
				<Typography variant="body2" fontSize={isTabletPortrait ? '0.8125rem' : '0.875rem'}>
					<FormattedMessage id="dashboard.personalData.insuranceNumber" values={{ insuranceNumber: maskNumeroAssure(affiliation?.numAdhesion), bold: (...chunks) => <b>{chunks}</b> }} />
				</Typography>
				<Typography variant="body2" fontSize={isTabletPortrait ? '0.8125rem' : '0.875rem'}>
					{`${affiliation?.prenom} ${affiliation?.nom}`}
				</Typography>
				{affiliation?.adresse &&
					<>
						{affiliation.adresse?.adresse &&
							<Typography variant="body2" fontSize={isTabletPortrait ? '0.8125rem' : '0.875rem'}>
								{affiliation.adresse?.adresse}
							</Typography>
						}
						<Typography variant="body2" fontSize={isTabletPortrait ? '0.8125rem' : '0.875rem'}>
							{`${affiliation.adresse?.codpos} ${affiliation.adresse?.ville}, ${affiliation.adresse?.libPays}`}
						</Typography>
					</>
				}
				{affiliation?.telephone && (
					<Typography variant="body2" fontSize={isTabletPortrait ? '0.8125rem' : '0.875rem'}>
						{maskTelephone(affiliation?.telephone)}
					</Typography>
				)}
				{affiliation?.email && (
					<Typography variant="body2" fontSize={isTabletPortrait ? '0.8125rem' : '0.875rem'}>
						{maskEmail(user?.email)}
					</Typography>
				)}
			</Stack>
		</DashboardCard>
	)
}

const mapStateToProps = (state: any) => ({
	loading: isAffiliationLoading(state)
})


export default connect(
	mapStateToProps,
	{}
)(PersonalDataCard)
