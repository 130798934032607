import ArticleService from '../../../api/gerep/ArticleService'
import LocalStorage from '../../../business/storage/LocalStorage'

const ACTION_NAMESPACE = '/DOUBLE_AUTHENT'

export const types = {
	GET_ARTICLES: `${ACTION_NAMESPACE}/GET_ARTICLES`,
	GET_ARTICLES_LOADING: `${ACTION_NAMESPACE}/GET_ARTICLES_LOADING`
}

export const loadArticles = () => (dispatch, getState) => {
	dispatch({ type: types.GET_ARTICLES_LOADING, payload: true })

	return ArticleService.getArticles(LocalStorage.getToken())
		.then((articles) => {
			dispatch({ type: types.GET_ARTICLES_LOADING, payload: false })
			return dispatch({
				type: types.GET_ARTICLES,
				payload: articles
			})
		}).catch((e) => {
			dispatch({ type: types.GET_ARTICLES_LOADING, payload: false })
			throw e
		})

}
