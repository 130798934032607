import { types } from './remboursementsActions'
import { combineReducers } from 'redux'
import { Map, Set } from 'immutable'
import configureCache from '../../../utils/cache'
import appConst from '../../../constant/appConstants'
import FormatUtils from '../../../utils/FormatUtils'

const _cache = configureCache(appConst.cacheDuration.remboursement)

const transformIntervalle = intervalle => ({
	dateDebut: new Date(intervalle.start),
	dateFin: new Date(intervalle.end)
})

const remboursementsByAnnee = (state = Map(), action) => {
	switch (action.type) {
		case types.GET_REMBOURSEMENTS:
		case types.GET_DERNIERS_REMBOURSEMENTS:
			return action.payload.remboursements.reduce((map, element) => {
				const annee = FormatUtils.parseDate(element.datePay).getFullYear()
				return map.updateIn([annee], Set(), list => list.add(element.id))
			}, state)
		default:
			return state
	}
}

const derniersRemboursements = (state = {}, action) => {
	switch (action.type) {
		case types.GET_DERNIERS_REMBOURSEMENTS: {
			const { remboursements, intervalle } = action.payload
			return {
				remboursements,
				intervalle: transformIntervalle(intervalle)
			}
		}
		default:
			return state
	}
}

const remboursements = (state = new Map(), action) => {
	switch (action.type) {
		case types.GET_DERNIERS_REMBOURSEMENTS:
		case types.GET_REMBOURSEMENTS: {
			return state.withMutations((mutableMap) => {
				(action.payload.remboursements || action.payload.decomptes).forEach(element => {
					mutableMap.set(element.id, element)
				})
			})
		}
		default:
			return state
	}
}

const loading = (state = Set(), action) => {
	switch (action.type) {
		case types.GET_REMBOURSEMENTS_LOADING: {
			const key = action.payload.annee
			if (action.payload.loading) {
				return state.add(key)
			}
			return state.delete(key)
		}
		default:
			return state
	}
}

const cache = (state = Map(), action) => {
	switch (action.type) {
		case types.GET_REMBOURSEMENTS:
		case types.GET_DERNIERS_REMBOURSEMENTS:
			return state.set(action.payload.annee, _cache.mark())
		default:
			return state
	}
}

export default combineReducers({
	remboursementsByAnnee,
	derniersRemboursements,
	loading,
	remboursements,
	cache
})
