import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const ButtonCookies = styled(ButtonMUI)<ButtonProps>(({ theme }) => ({
	'&.MuiButton-root': {
		borderRadius: 24,
		height: 36,
		fontSize: '14px',
		fontWeight: 500,
		'&.MuiButton-contained': {
			color: 'white'
		},
		'&.MuiButton-outlined': {
			color: theme.palette.primary.main,
			border: '2px solid ' + theme.palette.primary.main,
			backgroundColor: '#fff'
		}
	}
}))

export default ButtonCookies
