import React, { useContext, useState } from 'react'
import PageLayout from '../../../../components/PageLayout'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { CircularProgress, Link, Stack, Typography } from '@mui/material'
import ButtonDefault from '../../../../components/ButtonDefault'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import FileInput from '../../../../components/fileInput/FileInput'
import * as contratsActions from '../../../webservices/contrats/contratsActions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import ContratNotAvailable from '../../contrats/components/ContratNotAvailable'
import { getContratBaseSante } from '../../../webservices/contrats/contratsSelector'
import LocalStorage from '../../../../business/storage/LocalStorage'
import ContactService from '../../../../api/gerep/ContactService'
import { ToastContext } from '../../../../components/toast/ToastContext'
import { ToastType } from '../../../../components/toast/toastConstants'

const ActeNaissanceForm = (
	{
		// @ts-ignore
		download,
		// @ts-ignore
		company
	}
) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
	const [openContratNotAvailable, setOpenContratNotAvailable] = useState<boolean>(false)

	const onSubmit = (values: any) => {
		const { files } = values
		setIsLoading(true)

		const body = files.map((file: any, index: number) => ({
			key: `file${index}`,
			value: file
		}))

		ContactService.envoyerActeNaissance(LocalStorage.getToken(), body)
			.then(() => {
				addToast(ToastType.SUCCESS, 'global.success')
				setOpenConfirmation(true)
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<PageLayout title={intl.formatMessage({ id: 'acteNaissance.title' })}>
			<Form
				onSubmit={onSubmit}
				validate={(values: any) => {
					const errors: any = {}
					if (!values['files']) {
						errors['files'] = intl.formatMessage({ id: 'global.error.required' })
					}
					return errors
				}}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Stack direction="column" spacing="24px">
							<FileInput
								name="files"
								title={intl.formatMessage({ id: 'acteNaissance.confirmation' })}
								label={intl.formatMessage({ id: 'acteNaissance.text' })}
								maxFileNumber={1}
							/>
							<Typography variant="body2">
								<FormattedMessage
									id="acteNaissance.subtext"
									values={{
										link: <Link
											href="#"
											color="secondary"
											onClick={() => download(company?.numgar, company?.siret)
												.catch(() => {
													setOpenContratNotAvailable(true)
												})
											}
										>
											<FormattedMessage id="souscription.gestionLink" />
										</Link>
									}}
								/>
							</Typography>
							<Stack alignItems="center">
								<ButtonDefault
									type="submit"
									variant="contained"
								>
									{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
								</ButtonDefault>
							</Stack>
							<ConfirmationPopin
								title={intl.formatMessage({ id: 'acteNaissance.confirmation' })}
								open={openConfirmation}
								setOpen={setOpenConfirmation}
							/>
							<ContratNotAvailable open={openContratNotAvailable} setOpen={setOpenContratNotAvailable} />
						</Stack>
					</form>
				)}
			/>
		</PageLayout>
	)
}

const mappedActions = {
	download: contratsActions.download
}

const mapStateToProps = (state: any) => ({
	company: getContratBaseSante(state)
})

export default compose(
	connect(mapStateToProps, mappedActions))(ActeNaissanceForm)