import UserService from '../../../../../api/gerep/UserService'
import {Dispatch} from 'react'
import UserServiceEE from '../../../../../api/gerepEe/UserServiceEE'

const ACTION_NAMESPACE = '/FORGOT_PASSWORD'

export const types = {
    CHANGE_PASSWORD: `${ACTION_NAMESPACE}/CHANGE_PASSWORD`,
    LOADING: `${ACTION_NAMESPACE}/LOADING`,
    OPEN_POPUP: `${ACTION_NAMESPACE}/OPEN_DIALOG`,
    CLOSE_POPUP: `${ACTION_NAMESPACE}/CLOSE_DIALOG`,
    CHANGE_PASSWORD_SUCCESS: `${ACTION_NAMESPACE}/CHANGE_PASSWORD_SUCCESS`,
    CLOSE_CONFIRMATION: `${ACTION_NAMESPACE}/CLOSE_CONFIRMATION`
}

export const openPopup = () => ({
    type: types.OPEN_POPUP
})

export const closePopup = () => ({
    type: types.CLOSE_POPUP
})


export const changePasswordSuccess = () => ({
    type: types.CHANGE_PASSWORD_SUCCESS
})

export const closeConfirmation = () => ({
    type: types.CLOSE_CONFIRMATION
})

export const changePassword = (idAdherent: string, mailAdherent: string, dateNaissance: string, captcharesponse: any) => (dispatch: Dispatch<any>) => {
    dispatch({type: types.LOADING, payload: true})
    return UserService.changePassword(idAdherent, mailAdherent, dateNaissance, captcharesponse).then(() => {
        dispatch(changePasswordSuccess())
    }).catch((e) => {
        dispatch({type: types.LOADING, payload: false})
        throw e
    })
}

export const changePasswordEE = (email: string, captcharesponse: any) => (dispatch: Dispatch<any>) => {
    dispatch({type: types.LOADING, payload: true})
    return UserServiceEE.changePassword(email, captcharesponse).then(() => {
        dispatch(changePasswordSuccess())
    }).catch((e) => {
        dispatch({type: types.LOADING, payload: false})
        throw e
    })
}
