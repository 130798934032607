import {call, put, race, take} from 'redux-saga/effects'
import {loadInterstitiels} from './interstitielActions'
import LocalStorage from '../../../business/storage/LocalStorage'
import {types as loginTypes} from '../../../univers/ui/authentication/services/login/loginActions'

/**
 * Tâche qui récupère à intervalle régulier la liste des interstitiels
 */
function* backgroundTask(getInterstitiels) {
    // while (true) {
    //
    // 	// delay next execution
    // 	yield call(delay, 5000)
    // }
    try {
        const interstitiels = yield call(getInterstitiels, LocalStorage.getToken())
        yield put(loadInterstitiels(interstitiels))
    } catch (error) {
        console.error(error)
    }
}

function* watchStartBackgroundTask(getInterstitiels) {
    while (true) {
        // on bloque à cette étape jusqu'à recevoir un event indiquant qu'on a un utilisateur dans le store
        yield race({
            init: take(loginTypes.INIT_USER),
            login: take(loginTypes.LOG_USER)
        })
        // on lance la tâche de récupération des interstitiels jusqu'à ce que l'on recoive l'event de déconnexion
        yield race({
            task: call(backgroundTask, getInterstitiels),
            cancel: take(loginTypes.LOGOUT)
        })
    }
}

export default function saga(getInterstitiels) {
    return call(watchStartBackgroundTask, getInterstitiels)
}
