import { getCache, isRibLoading } from './ribCotisationSelector'
import { GET_RIB_COTISATION, GET_RIB_COTISATION_LOADING } from './ribCotisationActionTypes'
import RibService from '../../../../api/gerep/RibService';
import LocalStorage from '../../../../business/storage/LocalStorage';

export const downloadMandatSepa = (iban) => dispatch => (
	RibService.downloadMandatSepa(LocalStorage.getToken(), { iban })
		.catch((e) => {
			console.error(e)
		})
)

export const getRibCotisation = () => (dispatch, getState) => {
	if (!isRibLoading(getState()) && !getCache(getState())) {
		dispatch({
			type: GET_RIB_COTISATION_LOADING,
			payload: true
		})
		return RibService.getRibCotisation(LocalStorage.getToken())
			.then((rib) => {
				dispatch({
					type: GET_RIB_COTISATION_LOADING,
					payload: false
				})
				dispatch({
					type: GET_RIB_COTISATION,
					payload: rib
				})
			}).catch((e) => {
				dispatch({
					type: GET_RIB_COTISATION_LOADING,
					payload: false
				})
				throw e
			})
	}
}
