import React, {useEffect} from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import UserService from '../../../../api/gerep/UserService'
import LocalStorage from '../../../../business/storage/LocalStorage'
import GerepAPI from '../../../../api/gerep/GerepAPI'

const OauthPage = (
	{

	}
) => {

	const urlParams = new URLSearchParams(window.location.search)

	useEffect(() => {
		// On appel le service de ticket
		if (urlParams != undefined) {
			UserService.getAuthCode(LocalStorage.getToken()).then((weakToken) => {
				GerepAPI.post('/loginAuthCode', {token: weakToken, redirectUri: urlParams.get('redirect_uri'), nonce: urlParams.get('nonce')}, LocalStorage.getToken(), true).then((authCode) => {
					window.location.href =  urlParams.get('redirect_uri') + "?state=" +  urlParams.get('state') + "&nonce=" +  urlParams.get('nonce') + "&code=" + authCode.code
				})
			})
		}
	}, [urlParams])


	return (
		<>Will redirect in few seconds...</>
	)
}

const mappedActions = {

}

const mapStateToProps = (state: any) => ({

})

export default compose(
	connect(mapStateToProps, mappedActions))(OauthPage)
