import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const ButtonDefault = styled(ButtonMUI)<ButtonProps>(({ theme }) => ({
	'&.MuiButton-root': {
		borderRadius: 24,
		minWidth: '230px',
		height: 36,
		fontSize: '15px',
		fontWeight: 500,
		textTransform: 'none'
	}
}))

export default ButtonDefault
