import React from 'react'
import {Stack, Typography} from '@mui/material'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import Icon from '@mdi/react'
import {mdiHumanFemale, mdiHumanMale} from '@mdi/js'

type GridChartProps = {
	taux: Number
	nbPersonne: Number
	color: string
	woman: boolean
}
const ParityChart: React.FC<GridChartProps> = (
	{
		taux,
		nbPersonne,
		color,
		woman
	}
) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const isSmallScreen = isMobile || isTabletPortrait

	return (
		<Stack sx={{width: '100%', height: '400px'}} direction="column" flex="1">

			<Stack direction="row" alignSelf="center" >
				<Stack
					alignItems="center"
					sx={{
						position: 'relative',
						minHeight: '75px',
						minWidth: '35px'
					}}
				>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							maxWidth: '35px',
							overflow: 'hidden',
							position: 'absolute',
							left: 0
						}}
					>
						<Icon
							path={woman ? mdiHumanFemale : mdiHumanMale}
							size={3}
							color={color}
							style={{display: (taux > 90) ? 'block': 'none'}}
						/>
					</Stack>

					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							minWidth: '35px',
							position: 'absolute',
							left: 4,
							backgroundColor: 'white',
							// @ts-ignore
							marginLeft: `${(taux-90)*2.7}px`,
							zIndex: 10,
							display: (taux > 90) ? 'block': 'none'
						}}
					/>
				</Stack>
			</Stack>

			<Stack direction="row" alignSelf="center">
				<Stack
					alignItems="center"
					sx={{
						position: 'relative',
						minHeight: '75px',
						minWidth: '35px'
					}}
				>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							maxWidth: '35px',
							overflow: 'hidden',
							position: 'absolute',
							left: 0
						}}
					>
						<Icon
							path={woman ? mdiHumanFemale : mdiHumanMale}
							size={3}
							color={color}
							style={{display: (taux > 70) ? 'block': 'none'}}
						/>
					</Stack>

					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							minWidth: '35px',
							position: 'absolute',
							left: 4,
							backgroundColor: 'white',
							// @ts-ignore
							marginLeft: `${(taux-70)*2.7}px`,
							zIndex: 10,
							display: taux > 70 && taux < 80 ? 'block': 'none'
						}}
					/>
				</Stack>

				<Stack
					alignItems="center"
					sx={{
						position: 'relative',
						minHeight: '75px',
						minWidth: '35px'
					}}
				>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							maxWidth: '35px',
							overflow: 'hidden',
							position: 'absolute',
							left: 0
						}}
					>
						<Icon
							path={woman ? mdiHumanFemale : mdiHumanMale}
							size={3}
							color={color}
							style={{display: (taux > 80) ? 'block': 'none'}}
						/>
					</Stack>

					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							minWidth: '35px',
							position: 'absolute',
							left: 4,
							backgroundColor: 'white',
							// @ts-ignore
							marginLeft: `${(taux-80)*2.7}px`,
							zIndex: 10,
							display: taux > 80 && taux < 90 ? 'block': 'none'
						}}
					/>
				</Stack>
			</Stack>

			<Stack direction="row" alignSelf="center" >
				<Stack
					alignItems="center"
					sx={{
						position:	'relative',
						minHeight: '75px',
						minWidth: '35px'
					}}
				>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							maxWidth: '35px',
							overflow: 'hidden',
							position: 'absolute',
							left: 0
						}}
					>
						<Icon
							path={woman ? mdiHumanFemale : mdiHumanMale}
							size={3}
							color={color}
							style={{display: (taux > 40) ? 'block': 'none'}}
						/>
					</Stack>

					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							minWidth: '35px',
							position: 'absolute',
							left: 4,
							backgroundColor: 'white',
							// @ts-ignore
							marginLeft: `${(taux-40)*2.7}px`,
							zIndex: 10,
							display: (taux > 40 && taux < 50) ? 'block': 'none'
						}}
					/>
				</Stack>

				<Stack
					alignItems="center"
					sx={{
						position:'relative',
						minHeight: '75px',
						minWidth: '35px',
					}}
				>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							maxWidth: '35px',
							overflow: 'hidden',
							position: 'absolute',
							left: 0
						}}
					>
						<Icon
							path={woman ? mdiHumanFemale : mdiHumanMale}
							size={3}
							color={color}
							style={{display: (taux > 50) ? 'block': 'none'}}
						/>
					</Stack>

					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							minWidth: '35px',
							position: 'absolute',
							left: 4,
							backgroundColor: 'white',
							// @ts-ignore
							marginLeft: `${(taux-50)*2.7}px`,
							zIndex: 10,
							display: (taux > 50 && taux < 60) ? 'block': 'none'
						}}
					/>
				</Stack>

				<Stack
					alignItems="center"
					sx={{
						position:	'relative',
						minHeight: '75px',
						minWidth: '35px',
					}}
				>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							maxWidth: '35px',
							overflow: 'hidden',
							position: 'absolute',
							left: 0
						}}
					>
						<Icon
							path={woman ? mdiHumanFemale : mdiHumanMale}
							size={3}
							color={color}
							style={{display: (taux > 60) ? 'block': 'none'}}
						/>
					</Stack>

					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							minWidth: '35px',
							position: 'absolute',
							left: 4,
							backgroundColor: 'white',
							// @ts-ignore
							marginLeft: `${(taux-60)*2.7}px`,
							zIndex: 10,
							display: (taux > 60 && taux < 70) ? 'block': 'none'
						}}
					/>
				</Stack>
			</Stack>

			<Stack direction="row" alignSelf="center" >
				<Stack
					alignItems="center"
					sx={{
						position:	'relative',
						minHeight: '75px',
						minWidth: '35px'
					}}
				>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							maxWidth: '35px',
							overflow: 'hidden',
							position: 'absolute',
							left: 0
						}}
					>
						<Icon
							path={woman ? mdiHumanFemale : mdiHumanMale}
							size={3}
							color={color}
							style={{display: (taux > 0) ? 'block': 'none'}}
						/>
					</Stack>

					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							minWidth: '35px',
							position: 'absolute',
							left: 4,
							backgroundColor: 'white',
							// @ts-ignore
							marginLeft: `${(taux)*2.7}px`,
							zIndex: 10,
							display: (taux > 0 && taux < 10) ? 'block': 'none'
						}}
					/>
				</Stack>

				<Stack
					alignItems="center"
					sx={{
						position:	'relative',
						minHeight: '75px',
						minWidth: '35px',
					}}
				>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							maxWidth: '35px',
							overflow: 'hidden',
							position: 'absolute',
							left: 0
						}}
					>
						<Icon
							path={woman ? mdiHumanFemale : mdiHumanMale}
							size={3}
							color={color}
							style={{display: (taux > 10) ? 'block': 'none'}}
						/>
					</Stack>

					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							minWidth: '35px',
							position: 'absolute',
							left: 4,
							backgroundColor: 'white',
							// @ts-ignore
							marginLeft: `${(taux-10)*2.7}px`,
							zIndex: 10,
							display: (taux > 10 && taux < 20) ? 'block': 'none'
						}}
					/>
				</Stack>

				<Stack
					alignItems="center"
					sx={{
						position: 'relative',
						minHeight: '75px',
						minWidth: '35px',
					}}
				>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							maxWidth: '35px',
							overflow: 'hidden',
							position: 'absolute',
							left: 0
						}}
					>
						<Icon
							path={woman ? mdiHumanFemale : mdiHumanMale}
							size={3}
							color={color}
							style={{display: (taux > 20) ? 'block': 'none'}}/>
					</Stack>

					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							minWidth: '35px',
							position: 'absolute',
							left: 4,
							backgroundColor: 'white',
							// @ts-ignore
							marginLeft: `${(taux-20)*2.7}px`,
							zIndex: 10,
							display: (taux > 20 && taux < 30) ? 'block': 'none'
						}}
					/>
				</Stack>

				<Stack
					alignItems="center"
					sx={{
						position: 'relative',
						minHeight: '75px',
						minWidth: '35px',
					}}
				>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							maxWidth: '35px',
							overflow: 'hidden',
							position: 'absolute',
							left: 0
						}}
					>
						<Icon
							path={woman ? mdiHumanFemale : mdiHumanMale}
							size={3}
							color={color}
							style={{display:(taux > 30) ? 'block': 'none'}}
						/>
					</Stack>
					<Stack
						alignItems="center"
						sx={{
							minHeight: '75px',
							minWidth: '35px',
							position: 'absolute',
							left: 4,
							backgroundColor: 'white',
							// @ts-ignore
							marginLeft: `${(taux-30)*2.7}px`,
							zIndex: 10,
							display: (taux > 30 && taux < 40) ? 'block': 'none'
						}}
					/>
				</Stack>
			</Stack>

			<Stack
				direction="column"
				alignItems="center"
				paddingLeft={isMobile ? '' : '8px'}
				style={isMobile ? {} : {minHeight: '75px', maxWidth: '231px'} }
			>
				<Typography fontWeight="bold" color={color} variant="body1" fontSize="2rem">
					{`${taux} %`}
				</Typography>
				<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
					{woman ? 'Femmes' : 'Hommes'}
				</Typography>
			</Stack>
		</Stack>
	)
}

export default ParityChart
