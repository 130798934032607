import React from 'react'
import { Field } from 'react-final-form'
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { useBreakpoints } from '../breakpoints/BreakpointsProvider'
import { styled } from '@mui/material/styles'
import Icon from '@mdi/react'

type ToggleInputProps = {
	name: string
	data: SelectOption[]
	label?: string | React.ReactNode
	error?: string
	onChange?: (newValue: any) => void
}

const StyledToggleButton = styled(ToggleButton)(() => ({
	'&.MuiToggleButton-root': {
		textTransform: 'none',
		flex: 1
	}
}))

const ToggleInput: React.FC<ToggleInputProps> = (
	{
		data,
		name,
		label,
		onChange
	}) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()

	return (
		<Field name={name}>
			{({ input }) => {
				const handleChange = (event: React.MouseEvent<HTMLElement>, value: string | null) => {
					if (value !== null) {
						onChange && onChange(value)
						input.onChange(value)
					}
				}

				return (
					<Stack direction="column" spacing="12px" width="100%">
						<Typography variant="body2">
							{label}
						</Typography>
						<ToggleButtonGroup
							value={input.value}
							exclusive
							onChange={handleChange}
							color="secondary"
							orientation={isMobile || isTabletPortrait ? 'vertical' : 'horizontal'}
							sx={{ borderRadius: '5px', display: 'flex' }}
						>
							{data.map(({ value, label, icon }) => (
								<StyledToggleButton value={value} key={`${label}-${value}`} style={{ height: icon ? 'auto' : '52px', flex: 1 }}>
									<Stack px={icon ? 'auto' : '40px'} direction={icon ? 'column' : 'row'} spacing={1} alignItems="center">
										{icon ?
											<Icon path={icon} size="44px" color={input.value === value ? '#e96e11' : '#232323'} /> :
											<CheckIcon fontSize="medium" sx={{ position: 'absolute', left: '24px', color: input.value === value ? '#e96e11' : 'transparent' }} />
										}
										<Typography sx={{ textAlign: 'center', color: input.value === value ? '#e96e11' : '#232323' }} fontSize="0.875rem" fontWeight={500}>{label}</Typography>
									</Stack>
								</StyledToggleButton>
							))}
						</ToggleButtonGroup>
					</Stack>
				)
			}}
		</Field>
	)
}

export default ToggleInput