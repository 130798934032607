import {flatten} from 'flat'
import appConst from '../constant/appConstants'

/* eslint-disable max-len, quotes, quote-props */

export default flatten({
    global: {
        no_results: 'Il n\'y a aucun résultats',
        button: {
            confirm: 'Confirmer',
            ok: 'OK',
            seeAll: 'Voir plus',
            modify: 'Modifier',
            help: 'Besoin d’aide ?',
            validate: 'Valider',
            goBackToHome: 'Revenir à l’accueil',
            continue: 'Continuer',
            close: 'Fermer',
            login: 'Se connecter',
            meLogin: 'Me connecter'
        },
        error: {
            required: 'Champ obligatoire',
            login: 'Identifiant ou mot de passe incorrect. Vérifiez que vous avez bien saisi le n° d\'adhérent ou l\'adresse email de l\'assuré principal, ainsi que le bon mot de passe.',
            generalCondition: 'Vous devez accepter les conditions générales d\'utilisation',
            passwordsDontMatch: 'La confirmation de mot de passe est différente',
            emailsDontMatch: 'L\'adresse email saisie ne correspond pas',
            format: 'Format incorrect',
            occurred: 'Une erreur est survenue',
            noPersonalInfoEdited: 'Echec de la modification des informations personnelles. Aucune donnée n\'a été modifiée',
            personInfoFailed: 'Echec de la modification des informations personnelles',
            invalidEmail: 'Adresse email invalide',
            validEmail: 'La confirmation d\'email est différente',
            matchingEmail: 'Veuillez choisir une adresse mail différente',
            passwordsNotMatch: 'La confirmation de mot de passe est différente',
            dateTooOld: 'Date invalide. Veuillez choisir une date plus récente',
            dateTooFar: 'Veuillez choisir une date plus ancienne',
            sizeTooHeavy: 'Fichier trop lourd (Ne doit pas excéder ' + appConst.fileUpload.maxSizMo + 'Mo)',
            totalSizeTooHeavy: 'Taille totale des pièces jointes supérieure à 10Mo',
            fieldRequired: 'Modifiez au moins un champ',
            invalidDate: 'Date invalide',
            chooseOption: 'Veuillez choisir une option',
            dateMin: 'Veuillez saisir une date après le {date}',
            dateBeforeToday: 'Date antérieure à la date du jour',
            invalidPhone: 'Numéro de téléphone invalide',
            newpasswordbadFormat: 'Votre mot de passe doit être constitué d\'au moins 12 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial (#?!@$%^&*-\\;/_).'

        },
        input: {
            yes: 'Oui',
            no: 'Non'
        },
        mandatory: '* : Champs obligatoires',
        success: 'Enregistrement de votre demande effectuée avec succès',
        majData: 'Données actualisées au ',
        noGraphData: 'Les données nécessaires pour afficher le graphique {titreGraph} ne sont pas disponibles.',
        noPageData: 'Les données nécessaires pour afficher les graphiques de la page {titrePage} ne sont pas disponibles.',
        noKpiNumbers: 'Donnée non disponible',
        noPrevDataHome: 'Cette société n\'a pas souscrit de contrat prévoyance avec Gerep',
        noSanteDataHome: 'Cette société n\'a pas souscrit de contrat santé avec Gerep',
        noData: 'Aucune donnée'
    },
    dashboard: {
        welcome: {
            message: 'Vous êtes décideur dans votre entreprise et interlocuteur clé de Gerep. Nous mettons à votre disposition dans cet espace les indicateurs clés de vos contrats d\'assurance collective.',
            readOnly: 'Consultation uniquement',
        }
    },
    forceResetPassword: {
        oldPassword: 'Mot de passe actuel',
        newPassword: 'Nouveau mot de passe',
        confirmPassword: 'Confirmation nouveau mot de passe'
    },
    login: {
        resetPassword: {
            tokenInvalid: {
                title: 'Réinitialisation mon mot de passe',
                body: 'Le lien sur lequel vous avez cliqué n\'est plus valide.'
            }
        },
        tabs: {
            civil: 'Espace Assuré',
            professional: 'Espace Entreprise'
        },
        form: {
            title: 'Connexion Espace Assuré',
            titleEE: 'Connexion Espace Entreprise',
            forgottenPassword: {
                title: 'Vous avez oublié votre mot de passe ?',
                text: 'Pour réinitialiser votre mot de passe, veuillez saisir votre adresse e-mail, votre numéro d\'assuré ainsi que votre date de naissance.'
            },
            login: {
                label: 'Votre identifiant',
                labelEE: 'Identifiant',
                info: 'N° adhérent ou adresse e-mail',
                infoEE: 'votre adresse e-mail',
                popover1: 'Pour vous identifier, indiquez soit votre N° adhérent, soit votre adresse email.\n' +
                    'Vous trouverez votre N° d\'adhérent en haut à gauche de votre carte de tiers-payant.',
                popover2: 'Seul l\'adhérent principal peut se connecter sur cet espace avec ses identifiants.',
                popoverEE1: 'Pour vous identifier, indiquez votre adresse email.'
            },
            signup: {
                title: 'Inscription sur l\'Espace Assuré',
                subtitle: 'Par mesure de sécurité, tous les champs ci-dessous sont obligatoires pour activer votre compte.',
                insuranceNumber: {
                    label: 'Numéro d\'assuré',
                    placeholder: 'XXXXXXXXX',
                    info: 'Vous trouverez votre numéro d’adhérent sur l’email de Bienvenue que vous avez reçu ou en haut à gauche de votre carte de tiers-payant'
                },
                lastName: {
                    label: 'Nom',
                    placeholder: 'Entrez votre nom'
                },
                firstName: {
                    label: 'Prénom',
                    placeholder: 'Entrez votre prénom'
                },
                socialSecurityNumber: {
                    label: 'Numéro de sécurité sociale',
                    placeholder: 'X-XX-XX-XXXXX-XXXXX-XX',
                    info: 'Votre numéro de sécurité sociale est constitué de 15 chiffres et se trouve sur votre carte vitale.'
                },
                birthDate: {
                    label: 'Date de naissance',
                    placeholder: 'JJ/MM/AAAA'
                },
                email: {
                    label: 'Adresse email',
                    placeholder: 'exemple@mail.fr',
                    info: 'Veuillez saisir l’adresse email de l’assuré principal. Cette adresse email sera utilisée pour toutes les notifications et correspondances ultérieures.'
                },
                password: {
                    label: 'Mot de passe',
                    info: 'Votre mot de passe doit être constitué d\'au moins 12 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial (#?!@$%^&*-\\;/_).'
                },
                confirmPassword: 'Confirmez votre mot de passe',
                confirmEmail: 'Confirmez votre adresse email',
                termsAndConditions: {
                    newsletter: 'J\'accepte de recevoir des informations commerciales de la part de Gerep. En aucun cas mon adresse email ne sera cédée à des tiers.',
                    generalUse: {
                        link: 'Conditions générales d\'utilisation',
                        body: 'Je certifie avoir pris connaissance des {link} et je les accepte.'
                    }
                }
            },
            password: 'Votre mot de passe',
            rememberMe: 'Rester connecté',
            forgotPassword: 'Mot de passe oublié ?'
        },
        firstVisit: {
            title: 'Première visite ?',
            text: 'Vous êtes adhérent à un contrat santé Gerep, et vous souhaitez accéder à votre Espace Assuré pour la première fois. Rien de plus simple : il suffit d\'activer votre compte.'
        },
        firstVisitEE: {
            title: 'Première visite ?',
            text: 'Vous représentez une entreprise cliente de Gerep, et vous souhaitez accéder à votre Espace Entreprise pour la première fois. Rien de plus simple : il suffit de vous inscrire.'
        },
        title: 'Bienvenue sur votre Espace Assuré !',
        subtitle: 'En quelques clics, sur votre Espace Assuré Gerep : \n' +
            '\u3164 \u2022 Consultez vos remboursements et garanties\n' +
            '\u3164 \u2022 Gérez votre compte et vos bénéficiaires\n' +
            '\u3164 \u2022 Transmettez-nous vos documents\n' +
            '\u3164 \u2022 Téléchargez votre carte de tiers-payant et effectuez\n' +
            '\u3164\u3164 vos démarches en ligne',
        button: {
            login: 'Connexion',
            activateAccount: 'Activer mon compte',
            inscription: 'S\'inscrire',
            resetPassword: 'Réinitialiser mon mot de passe'
        }
    },
    form: {
        select: {
            placeholder: 'Choisissez'
        },
        date: {
            title: 'Date'
        }
    },
    cookies: {
        content: 'En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de cookies permettant son bon fonctionnement et la réalisation de statistiques d\'utilisation.',
        buttons: {
            accept: 'J\'accepte',
            decline: 'Je refuse',
            info: 'En savoir plus et gérer mes cookies',
            manage: 'Gérer mes cookies'
        },
        popup: {
            title: 'Gestion de vos préférences sur les cookies',
            text: 'En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de traceurs (cookies) afin de sécuriser votre connexion au site et de réaliser des statistiques anonymes de visites ou vous proposer des contenus et services adaptés à vos centres d\'intérêts.\n' +
                '\n' +
                'Pour en savoir plus et paramétrer les traceurs, cliquez sur ce lien {link}.',
            whatIsCookie: {
                title: 'Qu\'est-ce qu\'un cookie ?',
                text: 'Les cookies sont des fichiers texte qui sont envoyés à votre outil informatique lorsque vous naviguez sur un site puis stockés sur un fichier de votre outil informatique par votre navigateur. Lors de chaque visite, les cookies sont alors renvoyés au site visité, ou à tout autre site qui reconnait ce cookie. Les cookies se comportent comme une « mémoire » pour le site internet, lui permettant de reconnaître votre outil informatique lorsque vous revenez sur ce site.'
            },
            cookieUsed: {
                title: 'Cookies utilisés par ce site',
                text: 'Le site que vous visitez utilise des traceurs (cookies). Ainsi, le site est susceptible d\'accéder à des informations déjà stockées dans votre équipement terminal de communications électroniques et d\'y inscrire des informations.\n' +
                    '\n' +
                    'Les données recueillies ne sont réservées qu’à un usage interne. Elles sont stockées en France et ne sont pas destinées à être revendues.\n' +
                    'Les traceurs utilisés par le site relèvent de deux catégories :\n' +
                    '\u3164 \u2022  traceurs dits strictement nécessaires, qui ne nécessitent pas votre consentement préalable,\n' +
                    '\u3164 \u2022 autres traceurs soumis à votre consentement préalable.'
            },
            noConsentTrackers: {
                title: '1 - Traceurs exemptés de consentement préalable',
                text: 'Nous utilisons ces traceurs pour permettre et faciliter la navigation sur le site notamment en mémorisant vos préférences de navigation définies au cours de votre session.\n' +
                    'Ces traceurs ne peuvent pas, techniquement, être désactivés depuis le site.'
            },
            consentTrackers: {
                title: '2 - Traceurs soumis à votre consentement préalable',
                text: 'Le site utilise des traceurs sous notre contrôle et d\'autres placés sous le contrôle de tiers qui sont soumis à votre consentement préalable.\n',
                underControl: {
                    title: 'Traceurs sous notre contrôle',
                    text: 'Nous utilisons ces traceurs pour recueillir des statistiques. Ces cookies statistiques nous permettent de connaître l\'utilisation et les performances du site dans un but d’amélioration de nos services.\n'
                },
                survey: {
                    title: 'J\'accepte l\'utilisation des cookies statistique\n',
                    text: 'Vos préférences de gestion des cookies peuvent être modifiées à tout moment dans la rubrique « Mon compte » puis « Gérer mes cookies ».\n'
                },
                thirdParty: {
                    title: 'Traceurs placés sous le contrôle de tiers',
                    text: 'Des cookies de sites tiers peuvent être installés par des services qui apparaissent sur ce site tiers.\n' +
                        '\n' +
                        'Ces cookies ne sont pas sous le contrôle du site visité. Pour plus d’information sur ces cookies Tierce partie, nous vous invitons à visiter le site internet tiers.\n' +
                        '\n' +
                        'L\'utilisation des traceurs est régie par l\'article 32 II de la loi n° 78-17 du 6 janvier 1978, transposant l\'article 5.3 de la directive 2002/58/CE du parlement européen et du conseil du 12 juillet 2002 modifiée par la directive 2009/136/CE.\n' +
                        '\n' +
                        'Pour en savoir plus sur les cookies et traceurs, nous vous invitons à consulter le site de la CNIL : {link}'
                }
            }
        }
    },
    appbar: {
        lastConnexion: 'Dernière connexion le {date}',
        selector: {
            title : "Société"
        }
    },
    codeError: {
        notFound: {
            title: 'Erreur 404',
            text: 'Oups, cette page n\'est malheureusement pas disponible. Peut-être qu\'une de ces pages vous sera utile :'
        },
        unauthorized: {
            title: 'Erreur 401',
            text: 'Oups, vous n\'êtes pas autorisé à accéder à cette page.'
        },
        buttons: {
            home: 'Retour à mon tableau de bord',
            help: 'Consulter l\'aide',
            refund: 'Consulter mes remboursements'
        }
    },
    internalError: {
        title: 'Erreur 500',
        text: {
            1: 'L\'accès à vos données est momentanément indisponible.',
            2: 'Nous vous prions de nous excuser pour la gêne occasionnée.',
            3: 'Merci de ré-essayer ultérieurement.'
        },
        altText: {
            1: 'Une erreur technique est survenue.',
            2: 'Nous allons régler ce souci. Merci de réessayer ultérieurement.',
            3: 'Si le problème persiste, vous pouvez nous contacter {link}.'
        },
        buttons: {
            home: 'Retour à mon tableau de bord'
        },
        nonApplicable: 'N/A'
    },
    appMenu: {
        dashboard: {
            title: 'Tableau de bord'
        },
        account: {
            title: 'Mon compte'
        },
        redirectEE: {
            title: 'Retour'
        },
        contact: {
            title: 'Nous contacter'
        },
        logout: {
            title: 'Déconnexion'
        }
    },
    doubleAuthent : {
	    title: 'Authentification sécurisée',
	    activation: {
		    title: 'Activation de la double authentification',
		    faq: {
			    label: 'En savoir plus sur la double authentification ? ',
			    link: 'Voir la FAQ'
		    },
		    button: {
			    activate: 'Activer',
			    inactivate: 'Ne pas activer',
		    }
	    },
	    confirmation: {
		    title: {
			    activation: 'Confirmer l\'activation de la double authentification',
			    modification: 'Confirmer la méthode de double authentification '
		    },
		    label: {
			    sms: 'Un code de validation a été envoyé au {contact}',
			    mail: 'Un code de validation a été envoyé à {contact}',
			    code: 'Veuillez saisir le code reçu.'
		    },
		    error: {
			    success: 'Double authentification validée'
		    },
		    renvoi: 'Me renvoyer un code',
		    button: {
			    retour: 'Modifier de numéro',
			    valider: 'Valider'
		    }
	    },
	    finalPopin: {
		    title: 'Double authentification activée',
		    label: 'Pour encore plus de sécurité, nous vous recommandons d’ajouter {methode} pour récupérer votre compte.',
		    button: {
			    ajout: {
				    mail: 'Ajouter une adresse mail',
				    sms: 'Ajouter un n° de téléphone'
			    },
			    terminer: 'Terminer'
		    }
	    },
        recup: {
            title: 'Ajouter une méthode de récupération',
            popin: 'Vous avez la possibilité d\'ajouter un {contact} de récupération'
        }
    },
    personalInfo: {
        title: 'Mes données personnelles',
        connexionInfo: {
	        title: 'Mes informations de connexion',
	        modifyPassword: {
		        title: 'Modifier mon mot de passe',
		        popup: 'Votre mot de passe doit être constitué d\'au moins 12 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial (#?!@$%^&*-\\;/_).',
		        success: 'Votre mot de passe a bien été modifié'
	        },
	        resetPassword: {
		        title: 'Réinitialiser mon mot de passe',
		        success: 'Votre mot de passe a bien été réinitialisé'
	        },
	        labels: {
		        email: 'Email',
		        password: 'Mot de passe',
		        doubleAuthent: 'Méthode d\'authentification',
		        oldPassword: 'Ancien mot de passe',
		        newPassword: 'Nouveau mot de passe',
		        confirmNewPassword: 'Confirmez votre mot de passe',
                emailAuthent: 'Adresse mail',
	        },
	        buttons: {
		        modifyPassword: 'Modifier mon mot de passe',
		        manageCookies: 'Gérer mes cookies',
		        modifierAuthent: 'Modifier ma méthode d\'authentification',
		        activerAuthent: 'Activer ma méthode d\'authentification'
	        },
	        modifyAuthent: {
		        title: {
			        activation: 'Activation de la double authentification',
			        modification: 'Modification de la double authentification',
		        },
		        helperText: {
			        mail: 'Veuillez renseigner votre adresse mail personnelle',
			        sms: 'Veuillez renseigner votre n° de téléphone portable personnel'
		        },
		        warningText: {
			        mail: 'Si vous validez, l’adresse email remplacera l’adresse email actuellement enregistrée sur votre compte.',
			        sms: 'Si vous validez, le numéro renseigné remplacera le n° de téléphone actuellement enregistré sur votre compte.'
		        },
		        error: 'Le format n\'est pas correct',
		        success: 'Le code vous a été envoyé',
                text: 'Protéger votre compte en exigeant une méthode d\'authentification supplémentaire à la saisie de votre mot de passe.'
	        }
        }
    },
    contacter:{
        title:''
    },
    sante: {
        consommation: {
            title: 'Consommation de mon assurance santé',
            consoNumbers: {
                refund: 'Actes remboursés',
                rest: 'Actes sans reste à charge'
            },
            utilisationChart: {
                title: 'Mon assurance santé est-elle bien utilisée ?',
                beneficiairesTitle: 'Bénéficiaires',
                utilisateursTitle: 'Utilisateurs'
            },
            couvertureChart: {
                title: 'Mon assurance santé couvre-t-elle bien ?',
                depensesTitle: 'Dépenses',
                remboursementsTitle: 'Remboursements'
            },
            familleSoin: {
                title: 'Quels sont les soins les plus consommés?',
                sointCourantTitle: 'Soins courants',
                dentaireTitle: 'Dentaire',
                // refundTitle:'Remboursements',
                optiqueTitle: 'Optique',
                hospitalisationTitle: 'Hospitalisation',
                autresTitle: 'Autres prestations'
            },
            coutMoyen: {
                title: 'Quels sont les coûts moyens et les restes à charge par acte?',
                refund: 'Remboursement',
                rest: 'Reste à charge'
            }
        },
        financeCotisation: {
            title: 'Finance et cotisations',
            financeNumbers: {
                cotisNette: 'Cotisations Nettes',
                cotisTTC: 'Cotisations TTC',
                prestations: 'Prestations',
                ratio: 'Ratio Prestations à Cotisations'
            },
            periode: {
                title: 'Quel est le montant de mes cotisations?',
                info: 'A partir du 01/07, les cotisations des trimestres 3 et 4 sont grisées car il ne ' +
                    's’agit pas des cotisations que vous allez réellement versées mais de cotisations prévisionnelles ' +
                    'qui tiennent compte des provisions.'
            },
            financeTotal: {
                title: 'Quelle est la performance de mon assurance Santé?',
                resultLabel: 'Résultat',
                info: 'A partir du 01/07 les données des trimestres 3 et 4 sont des données prévisionnelles qui tiennent ' +
                    'compte des provisions. Les données qui alimentent ce graphique ne sont donc pas les montants réels.'
            }

        },
        demographie: {
            title: 'Démographie',
            demoNumbers: {
                ageMoyen: 'Age moyen',
                assures: 'Assurés'
            },
            beneficaires: {
                title: 'Qui sont les bénéficiaires?',
                assures: 'Assurés',
                conjoints: 'Conjoints',
                enfants: 'Enfants'
            },
            portabilite: {
                title: 'Quel est le taux de portabilité?',
                enPostes: 'En poste',
                enPortabilite: 'En portabilité'
            },
            evolutionNbAssures: {
                title: 'Quelle est l\'évolution des salariés assurés?'
            },
            ageAssurer: {
                title: 'Quelle est la répartition par tranche d\'âge?'
            },
            ratioSex: {
                title: 'Quel est le ratio Homme/Femme?'
            },
        }
    },
    prevoyance: {
        arretTravail: {
            title:'Suivi de la volumétrie des arrêts de travail'
        },
        dataSelector: {
            monthLabel: 'Mois',
            trimesterLabel: 'Trimestre'
        },
        dirNumbers: {
            inProgress: 'Dossiers en cours',
            instantAverageRate: 'Taux d\'arrêt de travail instantané',
            averageRate: 'Taux d\'arrêt de travail',
            openedDir: 'Dossiers ouverts',
            closedDir: 'Dossiers fermés',
        },
        gridChart: {
            axisLabels: {
                yAxis: 'Nombre de dossiers',
                ratioAxis: 'Taux moyen d\'arrêt de travail (en %)'
            },
            averageRate: 'Taux d\'arrêt de travail',
            openedDir: 'Dossiers ouverts',
            closedDir: 'Dossiers fermés',
        }
    },
    qualiteService: {
        title :'Accueil téléphonique',
        info: 'La page "Accueil téléphonique" présente les données de l\'ensemble des sociétés dont vous avez la charge',
        qualityNumbers: {
            incomingCalls: 'Appels entrants',
            answeredRate: 'Taux de décrochés'
        },
        qualityEvolution: {
            title : 'Evolution de la qualité de l\'accueil téléphonique',
            axisLabels: {
                yAxis: 'Nombre d\'appels entrants',
                dureeAttenteAxis: 'Taux de décrochés (en %)'
            },
            incomingCalls: 'Appels entrants',
            answeredRate: 'Taux de décrochés'
        }
    },
    dashBoard: {
        titles: {
          sante: 'Santé',
          prevoyance: 'Prévoyance'
        },
        button: {
            sante : 'Voir plus d\'indicateurs Santé',
            prevoyance :'Voir plus ...'
        },
        utilisationChart: {
            title: 'Taux d\'utilisation de l\'assurance santé',
            hoverTitle : 'Taux d\'utilisation '
        },
        remboursementChart: {
            title: 'Taux de couverture des dépenses santé',
            hoverTitle : 'Taux de couverture'
        },
        tauxPrevoyanceChart: {
            title: 'Taux instantané d\'arrêts de travail',
            hoverTitle : 'Arrêts de travail'
        }
    },
    toutesSocietes: {
        dashBoard: {
            titleCard: 'Vue consolidée de mes sociétés',
            helperCard: 'Les indicateurs à droite représentent les données de l\'ensemble de vos société, tandis que ceux en dessous sont spécifiques à chaque société individuellement'
        }
    },
    contacterGerep: {
        title: 'Contacter Gerep',
        assureur: 'Mon chargé de clientèle',
        noCDC: 'Aucun chargé de clientèle ne semble associé à cette société',
        mail : {
            title: 'Mon message',
            company: ' Société',
            objet: 'Objet',
            body: 'Message *',
            sendCopy: 'M\'envoyer une copie de l\'email',
            sendButton: 'Envoyer'
        },
        popin: {
            success: 'Votre message a bien été envoyé à Gerep.',
            close: 'Fermer'
        }
    }
})
