import React, { useMemo, useState } from 'react'
import { ToastContext } from './ToastContext'
import { Alert, Snackbar, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type ToastType = 'success' | 'error' | 'warning' | 'info'

type ToastProviderProps = {
	children: React.ReactNode
}

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
	const [openToast, setOpenToast] = useState(false)
	const [toastType, setToastType] = useState<ToastType>('success')
	const [toastMessage, setToastMessage] = useState<string>('')
	const [rawMessage, setRawMessage] = useState<string | React.ReactNode>('')

	const addToast = (type: ToastType, message: string, rawMessage?: string | React.ReactNode) => {
		setToastType(type)
		setToastMessage(message)
		setRawMessage(rawMessage || '')
		setOpenToast(true)
	}

	const handleClose = () => setOpenToast(false)

	const contextValue = useMemo(() => ({ addToast }), [addToast])
	const toastMaxWidth = useMemo(() => toastType === 'info' ? '600px' : '450px', [toastType])

	return (
		<ToastContext.Provider value={contextValue}>
			<Snackbar
				open={openToast}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
				autoHideDuration={toastType !== 'info' ? 10000 : undefined}
				onClose={handleClose}
			>
				<Alert
					severity={toastType}
					variant="filled"
					icon={false}
					onClose={toastType === 'info' ? handleClose : undefined}
					sx={{ maxWidth: toastMaxWidth }}
				>
					<Typography variant="body2" color="#fff">
						{rawMessage ? rawMessage : <FormattedMessage id={toastMessage} />}
					</Typography>
				</Alert>
			</Snackbar>
			{children}
		</ToastContext.Provider>
	)
}

export default ToastProvider