import { types } from './popinNotationActions'
import { combineReducers } from 'redux'

const open = (state = false, action) => {
	switch (action.type) {
		case types.OPEN_POPIN: {
			return true
		}
		case types.CLOSE_POPIN: {
			return false
		}
		default:
			return state
	}
}

export default combineReducers({
	open
})
