import React, { useState } from 'react'
import {
	Breadcrumbs,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
	useTheme
} from '@mui/material'
import { mdiCommentQuestion } from '@mdi/js'
import Icon from '@mdi/react'
import { ChevronRight } from '@mui/icons-material'
import RightDrawer from '../../../../components/RightDrawer'


type HelpFaqProps = {
	question: string
	reponse: string
	displayBottomDivider?: boolean
	ariane: any
	forSearch: boolean
}

const HelpFaq: React.FC<HelpFaqProps> = (
	{
		question,
		reponse,
		displayBottomDivider,
		ariane,
		forSearch
	}
) => {
	const theme = useTheme()
	const icon = mdiCommentQuestion
	const [openDrawer, setOpenDrawer] = useState<boolean>(false)

	return (
		<ListItem disablePadding sx={{ borderTop: '1px solid #dedede', borderBottom: displayBottomDivider ? '1px solid #dedede' : 'unset' }}>
			<ListItemButton onClick={() => setOpenDrawer(true)}>
				<ListItemText
					primary={
						<Stack direction="row" spacing="12px" alignItems="center">
							<Stack direction="row" spacing="12px" alignItems="center">
								<Icon path={icon} size="16px" color={theme.palette.secondary.main} />
							</Stack>
							<Stack direction="column">
								{forSearch &&
									<Breadcrumbs
										separator={<ChevronRight sx={{ fontSize: '0.9rem' }} />}
										aria-label="breadcrumbDrawer"
									>
										{ariane.map((itemAriane: any, index: number) => {
											return (<Typography key={index} variant="subtitle1"> {itemAriane.label} </Typography>)
										})}
									</Breadcrumbs>
								}
								<Typography variant="body1">
									{question}
								</Typography>
							</Stack>
						</Stack>
					}
					primaryTypographyProps={{ variant: 'body1' }}
				/>
				<ListItemIcon>
					<ChevronRight />
				</ListItemIcon>
			</ListItemButton>
			<RightDrawer openModal={openDrawer} setOpenModal={setOpenDrawer} title={question}>
				<Stack flex={10} direction="row" spacing="12px">
					<Typography variant="subtitle2">
						{ariane.map((itemAriane: any) => itemAriane.label).join(' > ')}
					</Typography>
					{/*<Breadcrumbs*/}
					{/*	separator={<ChevronRight sx={{ fontSize: '0.9rem' }} />}*/}
					{/*	aria-label="breadcrumbDrawer"*/}
					{/*>*/}
					{/*	{ariane.map((itemAriane: any, index: number) => {*/}
					{/*		return (<Typography key={index} variant="subtitle2"> {itemAriane.label} </Typography>)*/}
					{/*	})}*/}
					{/*</Breadcrumbs>*/}
				</Stack>

				<Stack flex={10} direction="row" spacing="12px">
					<Typography variant="body1">
						<div
							dangerouslySetInnerHTML={{ __html: reponse }}
						/>
					</Typography>
				</Stack>
			</RightDrawer>
		</ListItem>
	)
}

export default HelpFaq
