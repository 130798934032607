import {
    IDENTIFICATION_FIELDS,
    BENEFICIARY_PREAFF_FIELDS,
    INFOS_PERSOS_FIELDS,
    BANK_FIELDS, CONTRACT_FIELDS, DISPENSE_PREAFF_FIELDS
} from './preaffiliationConstants'
import {addDays, addMonths, isValid, subYears} from 'date-fns'
import {isNssValid, nissAndBirthCoherence} from '../../../../components/fieldsValidation'
import {FORM_REGEX} from '../../../../components/formConstants'
import IBAN from 'iban'
import FormatUtils from '../../../../utils/FormatUtils'
import {motifDispenseChosen} from '../preaffiliationUtils'


const required = (intl: any) => intl.formatMessage({id: 'global.error.required'})
const badFormat = (intl: any) => intl.formatMessage({id: 'global.error.format'})
const badPhoneFormat = (intl: any) => intl.formatMessage({id: 'global.error.phoneFormat'})

export const validIdentification = (values: any, errors: any, intl: any) => {

    if (!values[IDENTIFICATION_FIELDS.CIVILITY]) {
        errors[IDENTIFICATION_FIELDS.CIVILITY] = required(intl)
    }
    if (!values[IDENTIFICATION_FIELDS.SEX]) {
        errors[IDENTIFICATION_FIELDS.SEX] = required(intl)
    }
    if (!values[IDENTIFICATION_FIELDS.LAST_NAME]) {
        errors[IDENTIFICATION_FIELDS.LAST_NAME] = required(intl)
    }
    if (!values[IDENTIFICATION_FIELDS.FIRST_NAME]) {
        errors[IDENTIFICATION_FIELDS.FIRST_NAME] = required(intl)
    }
    if (!values[IDENTIFICATION_FIELDS.BIRTH_DATE]) {
        errors[IDENTIFICATION_FIELDS.BIRTH_DATE] = required(intl)
    } else if (!isValid(values[IDENTIFICATION_FIELDS.BIRTH_DATE])) {
        errors[IDENTIFICATION_FIELDS.BIRTH_DATE] = badFormat(intl)
    } else if (values[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER]
        && !nissAndBirthCoherence(values[IDENTIFICATION_FIELDS.BIRTH_DATE], values[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER])) {
        errors[IDENTIFICATION_FIELDS.BIRTH_DATE] = intl.formatMessage({id: 'preaffiliation.error.birthNissCheck'})
    }
    if (!values[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER]) {
        errors[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER] = required(intl)
    } else if ((values[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER]).replace(/ |_/g, '').length !== 15) {
        errors[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER] = badFormat(intl)
    } else if (!isNssValid(values[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER])) {
        errors[IDENTIFICATION_FIELDS.SOCIAL_SECURITY_NUMBER] = intl.formatMessage({id: 'global.error.invalidNiss'})
    }
    if (!values[IDENTIFICATION_FIELDS.CONTRACT_MANAGEMENT] || values[IDENTIFICATION_FIELDS.CONTRACT_MANAGEMENT].length !== 1) {
        errors[IDENTIFICATION_FIELDS.CONTRACT_MANAGEMENT] = required(intl)
    }
    if (!values[IDENTIFICATION_FIELDS.GENERAL_USE] || values[IDENTIFICATION_FIELDS.GENERAL_USE].length !== 1) {
        errors[IDENTIFICATION_FIELDS.GENERAL_USE] = required(intl)
    }
}

export const validInfosPerso = (values: any, errors: any, intl: any, isDispense: boolean, wantDispense?: boolean) => {

    if (!wantDispense) {
        if (!values[INFOS_PERSOS_FIELDS.SOCIAL_SECURITY_ATTESTATION] || values[INFOS_PERSOS_FIELDS.SOCIAL_SECURITY_ATTESTATION].length === 0) {
            errors[INFOS_PERSOS_FIELDS.SOCIAL_SECURITY_ATTESTATION] = required(intl)
        }
        if (!values[INFOS_PERSOS_FIELDS.TELETRANSMISSION]) {
            errors[INFOS_PERSOS_FIELDS.TELETRANSMISSION] = required(intl)
        }
    }
    if (isDispense && !values[DISPENSE_PREAFF_FIELDS.EXEMPTION_WISH]) {
        errors[DISPENSE_PREAFF_FIELDS.EXEMPTION_WISH] = required(intl)
    }
    if (!values[INFOS_PERSOS_FIELDS.ADRESS1]) {
        errors[INFOS_PERSOS_FIELDS.ADRESS1] = required(intl)
    }
    if (!values[INFOS_PERSOS_FIELDS.ZIP_CODE]) {
        errors[INFOS_PERSOS_FIELDS.ZIP_CODE] = required(intl)
    } else if ((values[INFOS_PERSOS_FIELDS.ZIP_CODE].replace(/ |_/g, '')).length !== 5) {
        errors[INFOS_PERSOS_FIELDS.ZIP_CODE] = badFormat(intl)
    }
    if (!values[INFOS_PERSOS_FIELDS.CITY]) {
        errors[INFOS_PERSOS_FIELDS.CITY] = required(intl)
    }
    if (!values[INFOS_PERSOS_FIELDS.COUNTRY] || values[INFOS_PERSOS_FIELDS.COUNTRY] === null) {
        errors[INFOS_PERSOS_FIELDS.COUNTRY] = required(intl)
    }
    if (!values[INFOS_PERSOS_FIELDS.PHONE]) {
        errors[INFOS_PERSOS_FIELDS.PHONE] = required(intl)
    } else if ((values[INFOS_PERSOS_FIELDS.PHONE].replace(/ |_/g, '')).length !== 10) {
        errors[INFOS_PERSOS_FIELDS.PHONE] = badFormat(intl)
    } else if (!/^0[6-7]/.test(values[INFOS_PERSOS_FIELDS.PHONE]?.substring(0, 2))) {
        errors[INFOS_PERSOS_FIELDS.PHONE] = badPhoneFormat(intl)
    }
    if (!values[INFOS_PERSOS_FIELDS.MAIL]) {
        errors[INFOS_PERSOS_FIELDS.MAIL] = required(intl)
    } else if (!FORM_REGEX.EMAIL.test(values[INFOS_PERSOS_FIELDS.MAIL])) {
        errors[INFOS_PERSOS_FIELDS.MAIL] = badFormat(intl)
    }
}

export const validBankInfos = (values: any, errors: any, intl: any) => {

    if (!values[BANK_FIELDS.IBAN]) {
        errors[BANK_FIELDS.IBAN] = required(intl)
    } else if (!IBAN.isValid(values[BANK_FIELDS.IBAN])) {
        errors[BANK_FIELDS.IBAN] = badFormat(intl)
    }
    if (!values[BANK_FIELDS.BANK_NAME]) {
        errors[BANK_FIELDS.BANK_NAME] = required(intl)
    }
    if (!values[BANK_FIELDS.ACCOUNT_OWNER]) {
        errors[BANK_FIELDS.ACCOUNT_OWNER] = required(intl)
    }
}

export const validFieldArrayBeneficiaire = (values: any, valuesBeneficiaire: any, intl: any) => {

    if (values[CONTRACT_FIELDS.MORE_BENEFICIARY] && values[CONTRACT_FIELDS.MORE_BENEFICIARY].length === 1) {

        if (!valuesBeneficiaire.length) return
        // @ts-ignore
        const errorsArray = []

        valuesBeneficiaire.forEach(function callback(value: any) {
            // @ts-ignore
            const errors: any = {}
            if(value !== undefined) {
                if (!value[BENEFICIARY_PREAFF_FIELDS.TYPES]) {
                    errors[BENEFICIARY_PREAFF_FIELDS.TYPES] = required(intl)
                }
                if (!value[BENEFICIARY_PREAFF_FIELDS.COVERAGE]) {
                    errors[BENEFICIARY_PREAFF_FIELDS.COVERAGE] = required(intl)
                }
                if (!value[BENEFICIARY_PREAFF_FIELDS.CIVILITY]) {
                    errors[BENEFICIARY_PREAFF_FIELDS.CIVILITY] = required(intl)
                }
                if (!value[BENEFICIARY_PREAFF_FIELDS.SEX]) {
                    errors[BENEFICIARY_PREAFF_FIELDS.SEX] = required(intl)
                }
                if (!value[BENEFICIARY_PREAFF_FIELDS.LAST_NAME]) {
                    errors[BENEFICIARY_PREAFF_FIELDS.LAST_NAME] = required(intl)
                }
                if (!value[BENEFICIARY_PREAFF_FIELDS.FIRSTNAME]) {
                    errors[BENEFICIARY_PREAFF_FIELDS.FIRSTNAME] = required(intl)
                }
                if (!value[BENEFICIARY_PREAFF_FIELDS.BIRTH_DATE]) {
                    errors[BENEFICIARY_PREAFF_FIELDS.BIRTH_DATE] = required(intl)
                } else if (!isValid(value[BENEFICIARY_PREAFF_FIELDS.BIRTH_DATE])) {
                    errors[BENEFICIARY_PREAFF_FIELDS.BIRTH_DATE] = badFormat(intl)
                } else if (value[BENEFICIARY_PREAFF_FIELDS.TYPES] !== "2_A"
                    && value[BENEFICIARY_PREAFF_FIELDS.NISS]
                    && !nissAndBirthCoherence(value[BENEFICIARY_PREAFF_FIELDS.BIRTH_DATE], value[BENEFICIARY_PREAFF_FIELDS.NISS])) {
                        errors[BENEFICIARY_PREAFF_FIELDS.BIRTH_DATE] = intl.formatMessage({id: 'preaffiliation.error.birthNissCheck'})
                } else if (value[BENEFICIARY_PREAFF_FIELDS.TYPES] === "2_A"
                    && value[BENEFICIARY_PREAFF_FIELDS.BIRTH_DATE] < subYears(new Date(), 18)) {
                        errors[BENEFICIARY_PREAFF_FIELDS.BIRTH_DATE] = intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.form.birthDate.minorError'})
                }
                if (!value[BENEFICIARY_PREAFF_FIELDS.NISS]) {
                    errors[BENEFICIARY_PREAFF_FIELDS.NISS] = required(intl)
                } else if ((value[BENEFICIARY_PREAFF_FIELDS.NISS]).replace(/ |_/g, '').length !== 15) {
                    errors[BENEFICIARY_PREAFF_FIELDS.NISS] = badFormat(intl)
                } else if (!isNssValid(value[BENEFICIARY_PREAFF_FIELDS.NISS])) {
                    errors[BENEFICIARY_PREAFF_FIELDS.NISS] = intl.formatMessage({id: 'global.error.invalidNiss'})
                }
                if (value[BENEFICIARY_PREAFF_FIELDS.TYPES] === "2_A" && value[BENEFICIARY_PREAFF_FIELDS.NISS_PARENT]) {
                    if ((value[BENEFICIARY_PREAFF_FIELDS.NISS_PARENT]).replace(/ |_/g, '').length !== 15) {
                        errors[BENEFICIARY_PREAFF_FIELDS.NISS_PARENT] = badFormat(intl)
                    } else if (!isNssValid(value[BENEFICIARY_PREAFF_FIELDS.NISS_PARENT])) {
                        errors[BENEFICIARY_PREAFF_FIELDS.NISS_PARENT] = intl.formatMessage({id: 'global.error.invalidNiss'})
                    }
                }
                if (!value[BENEFICIARY_PREAFF_FIELDS.NISS_ATTESTATION] || value[BENEFICIARY_PREAFF_FIELDS.NISS_ATTESTATION].length === 0) {
                    errors[BENEFICIARY_PREAFF_FIELDS.NISS_ATTESTATION] = required(intl)
                }
                if (value[BENEFICIARY_PREAFF_FIELDS.TYPES] === "3" || value[BENEFICIARY_PREAFF_FIELDS.TYPES] === "7") {
                    if (!value[BENEFICIARY_PREAFF_FIELDS.FILES] || value[BENEFICIARY_PREAFF_FIELDS.FILES].length === 0) {
                        errors[BENEFICIARY_PREAFF_FIELDS.FILES] = required(intl)
                    }
                }
            }
            errorsArray.push(errors);
        })
        // @ts-ignore
        return errorsArray

    } else {
        return
    }
}

export const validContrat = (values: any, errors: any, intl: any, dateDebutMin: Function, contract: any) => {

    if (!values[CONTRACT_FIELDS.COVERAGE]) {
        errors[CONTRACT_FIELDS.COVERAGE] = required(intl)
    }
    if (!contract.debutAffiliation) {
        const datedebutMax = addDays(new Date(), 60).setHours(0,0,0,0);
        if (!values[CONTRACT_FIELDS.AFFILIATION_START]) {
            errors[CONTRACT_FIELDS.AFFILIATION_START] = required(intl)
        } else if (!isValid(values[CONTRACT_FIELDS.AFFILIATION_START])) {
            errors[CONTRACT_FIELDS.AFFILIATION_START] = badFormat(intl)
        } else if (dateDebutMin(values) > values[CONTRACT_FIELDS.AFFILIATION_START]) {
            errors[CONTRACT_FIELDS.AFFILIATION_START] = intl.formatMessage(
                { id: 'preaffiliation.error.beforeDateDebutMin' },
                { dateDebutMin: FormatUtils.formatDate(dateDebutMin(values)) }
            )
        } else if (values[CONTRACT_FIELDS.AFFILIATION_START] > datedebutMax) {
            errors[CONTRACT_FIELDS.AFFILIATION_START] = intl.formatMessage(
                { id: 'preaffiliation.error.afterDateDebutMax' },
                { dateDebutMax: FormatUtils.formatDate(datedebutMax) }
            )
        }
    }
}

export const validChoixDispense = (values: any, errors: any, intl: any, dateDebutMin: Function, contract: any) => {

    if (!values[DISPENSE_PREAFF_FIELDS.MOTIF]) {
        errors[DISPENSE_PREAFF_FIELDS.MOTIF] = required(intl)
    } else {
        if (!contract.debutAffiliation) {
            const datedebutMax = addDays(new Date(), 60)

            if (!values[DISPENSE_PREAFF_FIELDS.START_DATE]) {
                errors[DISPENSE_PREAFF_FIELDS.START_DATE] = required(intl)
            } else if (!isValid(values[DISPENSE_PREAFF_FIELDS.START_DATE])) {
                errors[DISPENSE_PREAFF_FIELDS.START_DATE] = badFormat(intl)
            } else if (dateDebutMin(values) > values[DISPENSE_PREAFF_FIELDS.START_DATE]) {
                errors[DISPENSE_PREAFF_FIELDS.START_DATE] = intl.formatMessage(
                    { id: 'preaffiliation.error.beforeDateDebutMin' },
                    { dateDebutMin: FormatUtils.formatDate(dateDebutMin(values)) }
                )
            } else if (values[DISPENSE_PREAFF_FIELDS.START_DATE] > datedebutMax) {
                errors[DISPENSE_PREAFF_FIELDS.START_DATE] = intl.formatMessage(
                    { id: 'preaffiliation.error.afterDateDebutMax' },
                    { dateDebutMax: FormatUtils.formatDate(datedebutMax) }
                )
            }
        }
        if (motifDispenseChosen(contract, values[DISPENSE_PREAFF_FIELDS.MOTIF]).organisme === "O" && !values[DISPENSE_PREAFF_FIELDS.ORGANISM]) {
            errors[DISPENSE_PREAFF_FIELDS.ORGANISM] = required(intl)
        }
        if (motifDispenseChosen(contract, values[DISPENSE_PREAFF_FIELDS.MOTIF]).ctrl === "O") {
            if (!values[DISPENSE_PREAFF_FIELDS.END_DATE]) {
                errors[DISPENSE_PREAFF_FIELDS.END_DATE] = required(intl)
            } else if (!isValid(values[DISPENSE_PREAFF_FIELDS.END_DATE])) {
                errors[DISPENSE_PREAFF_FIELDS.END_DATE] = badFormat(intl)
            }
            else if (values[DISPENSE_PREAFF_FIELDS.END_DATE] < addDays(values[DISPENSE_PREAFF_FIELDS.START_DATE], 1)) {
                errors[DISPENSE_PREAFF_FIELDS.END_DATE] = intl.formatMessage(
                    { id: 'preaffiliation.dispense.dispenseChoiceStep.form.endDate.errorBefore' },
                    { dateFinDispenseMin: FormatUtils.formatDate(addDays(values[DISPENSE_PREAFF_FIELDS.START_DATE], 1)) }
                )
            }
            else if (values[DISPENSE_PREAFF_FIELDS.END_DATE] > addMonths(values[DISPENSE_PREAFF_FIELDS.START_DATE], 12)) {
                errors[DISPENSE_PREAFF_FIELDS.END_DATE] = intl.formatMessage(
                    { id: 'preaffiliation.dispense.dispenseChoiceStep.form.endDate.errorAfter' },
                    { dateFinDispenseMax: FormatUtils.formatDate(addMonths(values[DISPENSE_PREAFF_FIELDS.START_DATE], 12)) }
                )
            }
        }
    }
}

export const validConfirmation = (values: any, errors: any, intl: any) => {

    if (!values[DISPENSE_PREAFF_FIELDS.SITUATION_CHANGEMENT] || values[DISPENSE_PREAFF_FIELDS.SITUATION_CHANGEMENT].length !== 1) {
        errors[DISPENSE_PREAFF_FIELDS.SITUATION_CHANGEMENT] = required(intl)
    }
    if (!values[DISPENSE_PREAFF_FIELDS.CHOICE_CONSEQUENCIES] || values[DISPENSE_PREAFF_FIELDS.CHOICE_CONSEQUENCIES].length !== 1) {
        errors[DISPENSE_PREAFF_FIELDS.CHOICE_CONSEQUENCIES] = required(intl)
    }
}

export const validPopinConfirmation = (values: any, errors: any, intl: any, attestationDownloaded: boolean) => {

    if (!attestationDownloaded) {
        errors[DISPENSE_PREAFF_FIELDS.DOWNLOAD_ATTESTATION] = intl.formatMessage({ id: 'global.error.downloadObligation'})
    }
    if (!values[DISPENSE_PREAFF_FIELDS.ENGAGEMENT_ATTESTATION] || values[DISPENSE_PREAFF_FIELDS.ENGAGEMENT_ATTESTATION].length !== 1) {
        errors[DISPENSE_PREAFF_FIELDS.ENGAGEMENT_ATTESTATION] = required(intl)
    }
}
