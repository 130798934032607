import { combineReducers } from 'redux'
import { LOAD_INTERSTITIELS } from './interstitielTypes'
import prefReducer from './interstitielPrefsReducer'

const articles = (state = [], action) => {
	switch (action.type) {
		case LOAD_INTERSTITIELS:
			return action.payload
		default:
			return state
	}
}

export default combineReducers({
	articles,
	prefs: prefReducer(false)
})
