import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Popup from '../../../../components/Popup'
import { Form } from 'react-final-form'
import RadioInput from '../../../../components/form/RadioInput'
import { PERIODE_FIELDS, PERIODE_OPTIONS } from '../services/remboursementConstants'
import { Box, CircularProgress, Stack } from '@mui/material'
import ButtonDefault from '../../../../components/ButtonDefault'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import SelectInput from '../../../../components/form/SelectInput'
import { format, isBefore, isValid } from 'date-fns'
import DatePickerInput from '../../../../components/form/DatePickerInput'
import { connect } from 'react-redux'
import * as actions from '../../../webservices/remboursements/remboursementsActions'
import { ToastType } from '../../../../components/toast/toastConstants'
import { ToastContext } from '../../../../components/toast/ToastContext'

type DownloadRelevesPopinProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}

// le nombre d'années à afficher dans les plages
const maxYears = 2

const DOWNLOAD_PDF = 'PDF'
const DOWNLOAD_EXCEL = 'XLS'

const DownloadRelevesPopin: React.FC<DownloadRelevesPopinProps> = (
	{
		open,
		setOpen,
		// @ts-ignore
		downloadRemboursements
	}
) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)

	const [releveFormat, setReleveFormat] = useState<'PDF' | 'XLS'>(DOWNLOAD_PDF)
	const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false)
	const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false)

	const { isMobile } = useBreakpoints()

	const startDate = useMemo(() => {
		const date = new Date()
		date.setMonth(date.getMonth() - 1)
		return date.toLocaleDateString('fr-FR')
	}, [])

	const getMonths = () => {
		const now = new Date()
		const currentYear = now.getFullYear()
		const currentMonth = now.getMonth()
		const values: SelectOption[] = []
		for (let y = currentYear; y >= currentYear - maxYears; --y) {
			const startIndex = y === currentYear ? currentMonth : 11
			for (let m = startIndex; m >= 0; --m) {
				const date = new Date(y, m, 1)
				values.push({
					value: `${date.getMonth() + 1}_${date.getFullYear()}`,
					label: intl.formatDate(date, { month: 'long', year: 'numeric' })
				})
			}
		}
		return values
	}

	const getTrimestres = () => {
		const now = new Date()
		const currentYear = now.getFullYear()
		const currentMonth = now.getMonth()
		const values = []
		for (let y = currentYear; y >= currentYear - maxYears; --y) {
			const startIndex = y === currentYear ? Math.floor(currentMonth / 4) : 3
			for (let index = startIndex; index >= 0; --index) {
				values.push({
					value: `${index + 1}_${y}`,
					label: intl.formatMessage({ id: 'downloadReleve.label.refundTrimester' }, { index: index + 1, year: y })
				})
			}
		}
		return values
	}

	const onSubmit = (values: any) => {
		const {
			type,
			opt1,
			opt2,
			dateDebut,
			dateFin
		} = values

		const body = type === PERIODE_OPTIONS.TRIMESTRIEL ? {
			trimestre: opt2
		} : type === PERIODE_OPTIONS.MENSUEL ? {
			mois: opt1
		} : type === PERIODE_OPTIONS.PERIODE ? {
			dateDebut: new Date(dateDebut).toLocaleDateString('fr-FR'),
			dateFin: new Date(dateFin).toLocaleDateString('fr-FR')
		} : {}

		releveFormat === DOWNLOAD_PDF ? setIsLoadingPdf(true) : setIsLoadingExcel(true)
		downloadRemboursements({
			...body,
			download: releveFormat
		})
			.then(() => setOpen(false))
			.catch(() => addToast(ToastType.ERROR, 'global.error.occurred'))
			.finally(() => releveFormat === DOWNLOAD_PDF ? setIsLoadingPdf(false) : setIsLoadingExcel(false))
	}

	return (
		<Popup open={open} setOpen={setOpen} title={intl.formatMessage({ id: 'downloadReleve.title' })}>
			<Form
				onSubmit={onSubmit}
				validate={(values: any) => {
					const now = new Date()
					const errors: any = {}
					if (!values[PERIODE_FIELDS.TYPE]) {
						errors[PERIODE_FIELDS.TYPE] = intl.formatMessage({ id: 'global.error.chooseOption' })
					} else if (values[PERIODE_FIELDS.TYPE] === PERIODE_OPTIONS.MENSUEL) {
						if (!values[PERIODE_FIELDS.OPTION_1]) {
							errors[PERIODE_FIELDS.OPTION_2] = intl.formatMessage({ id: 'global.error.required' })
						}
					} else if (values[PERIODE_FIELDS.TYPE] === PERIODE_OPTIONS.TRIMESTRIEL) {
						if (!values[PERIODE_FIELDS.OPTION_2]) {
							errors[PERIODE_FIELDS.OPTION_2] = intl.formatMessage({ id: 'global.error.required' })
						}
					} else if (values[PERIODE_FIELDS.TYPE] === PERIODE_OPTIONS.PERIODE) {
						const minDate = new Date(now.getFullYear() - maxYears, 1)
						const dateDebut = new Date(values[PERIODE_FIELDS.DATE_DEBUT])
						const dateFin = new Date(values[PERIODE_FIELDS.DATE_FIN])

						if (!dateDebut || !isValid(dateDebut)) {
							errors[PERIODE_FIELDS.DATE_DEBUT] = intl.formatMessage({ id: 'global.error.required' })
						} else if (isBefore(dateDebut, minDate)) {
							errors[PERIODE_FIELDS.DATE_DEBUT] = intl.formatMessage({ id: 'global.error.dateMin' }, { date: format(minDate, 'dd/MM/yyyy') })
						} else if (!dateFin || !isValid(dateFin)) {
							errors[PERIODE_FIELDS.DATE_FIN] = intl.formatMessage({ id: 'global.error.required' })
						} else if (isBefore(dateFin, dateDebut)) {
							errors[PERIODE_FIELDS.DATE_FIN] = intl.formatMessage({ id: 'global.error.invalidPlage' })
						}
					}
					return errors
				}}
				render={({ handleSubmit, values, errors, touched }) => {
					const PERIODE_DATA: SelectOption[] = [
						{
							label: 'MENSUEL',
							value: PERIODE_OPTIONS.MENSUEL,
							radioContent: (
								<SelectInput
									name={PERIODE_FIELDS.OPTION_1}
									data={getMonths()}
									disabled={values[PERIODE_FIELDS.TYPE] !== PERIODE_OPTIONS.MENSUEL}
								/>
							)
						},
						{
							label: 'TRIMESTRIEL',
							value: PERIODE_OPTIONS.TRIMESTRIEL,
							radioContent: (
								<SelectInput
									name={PERIODE_FIELDS.OPTION_2}
									data={getTrimestres()}
									disabled={values[PERIODE_FIELDS.TYPE] !== PERIODE_OPTIONS.TRIMESTRIEL}
								/>
							)
						},
						{
							label: 'PÉRIODE PERSONNALISÉE',
							value: PERIODE_OPTIONS.PERIODE,
							radioContent: (
								<Stack direction={isMobile ? 'column' : 'row'} spacing="12px" justifyContent="space-between">
									<Box>
										<DatePickerInput
											name={PERIODE_FIELDS.DATE_DEBUT}
											label={intl.formatMessage({ id: 'downloadReleve.label.startDate' })}
											initialValue={startDate}
											disabled={values[PERIODE_FIELDS.TYPE] !== PERIODE_OPTIONS.PERIODE}
										/>
									</Box>
									<Box>
										<DatePickerInput
											name={PERIODE_FIELDS.DATE_FIN}
											label={intl.formatMessage({ id: 'downloadReleve.label.endDate' })}
											initialValue={new Date().toLocaleDateString('fr-FR')}
											disabled={values[PERIODE_FIELDS.TYPE] !== PERIODE_OPTIONS.PERIODE}
										/>
									</Box>
								</Stack>
							)
						}
					]

					return (
						<form onSubmit={handleSubmit}>
							<Stack direction="column" spacing="24px">
								<RadioInput
									name={PERIODE_FIELDS.TYPE}
									data={PERIODE_DATA}
									error={touched && touched[PERIODE_FIELDS.TYPE] && errors && errors[PERIODE_FIELDS.TYPE]}
								/>
								<Stack direction={isMobile ? 'column' : 'row'} spacing="12px" justifyContent="space-between">
									<Box>
										<ButtonDefault
											variant="contained"
											onClick={() => {
												setReleveFormat(DOWNLOAD_PDF)
												setTimeout(() => handleSubmit(), 100)
											}}
										>
											{isLoadingPdf ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="downloadReleve.buttons.pdf" />}
										</ButtonDefault>
									</Box>
									<Box>
										<ButtonDefault
											variant="contained"
											onClick={() => {
												setReleveFormat(DOWNLOAD_EXCEL)
												setTimeout(() => handleSubmit(), 100)
											}}
										>
											{isLoadingExcel ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="downloadReleve.buttons.excel" />}
										</ButtonDefault>
									</Box>
								</Stack>
							</Stack>
						</form>
					)
				}}
			/>
		</Popup>
	)
}

const mapStateToProps = () => ({})

const mappedActions = {
	downloadRemboursements: actions.downloadRemboursements
}

export default connect(
	mapStateToProps,
	mappedActions
)(DownloadRelevesPopin)
