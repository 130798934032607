import React, {useLayoutEffect} from 'react'
import {Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import EmptyResult from '../../components/EmptyResult'

type GridChartProps = {
    id_title: string | React.ReactNode | any
    idChart: string
    dataChart: any[]
}

const GridDemographieChart: React.FC<GridChartProps> = (
    {
        id_title,
        idChart,
        dataChart
    }
) => {
    const intl = useIntl()
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const isSmallScreen = isMobile || isTabletPortrait

    useLayoutEffect(() => {
        if (dataChart) {
            let root = am5.Root.new(idChart)
            root.setThemes([am5themes_Animated.new(root)])
            root.numberFormatter.set("intlLocales", "fr-FR");
            root.numberFormatter.set("numberFormat", {
                maximumSignificantDigits: 2
            })
            root.setThemes([
                am5themes_Animated.new(root)
            ])

            root.interfaceColors.set("grid", am5.color(0xffffff))

            // @ts-ignore
            root._logo.dispose()

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    layout: root.verticalLayout,
                    arrangeTooltips: false
                })
            )

            // @ts-ignore
            chart.get("colors").set("colors", [
                am5.color(0xe96e11),
                am5.color(0xb5bbff),
            ])

            chart.getNumberFormatter().set("numberFormat", "#.#s")

            let yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: "age",
                    renderer: am5xy.AxisRendererY.new(root, {
                        inversed: true,
                        cellStartLocation: 0.1,
                        cellEndLocation: 0.9
                    })
                })
            )

            yAxis.data.setAll(dataChart);

            let minData = 0
            let maxData = 0
            dataChart.forEach((data) => {
                if (data.hommes < minData) {
                    minData = data.hommes
                }
                if (data.femmes > maxData) {
                    maxData = data.femmes
                }
            })

            let xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: Math.abs(minData) < maxData ? -2 * maxData : 2 * minData,
                    max: maxData < Math.abs(minData) ? 2 * Math.abs(minData) : 2 * maxData,
                    strictMinMax: true,
                    renderer: am5xy.AxisRendererX.new(root, {
                        strokeOpacity: 0.1
                    })
                })
            )

            // @ts-ignore
            function createSeries(field, labelCenterX, pointerOrientation, rangeValue) {
                let series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueXField: field,
                        categoryYField: "age",
                        sequencedInterpolation: true,
                        clustered: false,
                        // tooltip: am5.Tooltip.new(root, {
                        // 	pointerOrientation: pointerOrientation,
                        // 	labelText: "{categoryY}: {valueX}"
                        // })
                    })
                )

                series.columns.template.setAll({
                    height: am5.p100,
                    strokeOpacity: 0,
                    cornerRadiusTR: 10,
                    cornerRadiusBR: 10,
                })

                series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        locationX: 1,
                        locationY: 0.5,
                        sprite: am5.Label.new(root, {
                            centerY: am5.p50,
                            text: "{valueX}",
                            populateText: true,
                            centerX: labelCenterX,
                        })
                    })
                })

                series.data.setAll(dataChart)
                series.appear()
                let rangeDataItem = xAxis.makeDataItem({
                    value: rangeValue
                })
                xAxis.createAxisRange(rangeDataItem);
                // @ts-ignore
                rangeDataItem.get("grid").setAll({
                    strokeOpacity: 1,
                    stroke: series.get("stroke")
                })

                let label = rangeDataItem.get("label")
                // @ts-ignore
                label.setAll({
                    text: field.toUpperCase(),
                    fontSize: "1.1em",
                    fill: series.get("stroke"),
                    paddingTop: -20,
                    isMeasured: false,
                    centerX: labelCenterX
                })
                // @ts-ignore
                label.adapters.add("dy", function () {
                    return -chart.plotContainer.height()
                })

                return series
            }

            createSeries("hommes", am5.p100, "right", 0)
            createSeries("femmes", 0, "left", 0)

            let cursor = chart.set("cursor",
                am5xy.XYCursor.new(root, {
                    behavior: "zoomY"
                })
            )
            cursor.lineY.set("forceHidden", true)
            cursor.lineX.set("forceHidden", true)

            chart.appear(1000, 100)

            return () => root.dispose()
        }

    }, [dataChart, idChart])

    const titleGraph = () => {
        return (
            <Typography
                textAlign="left"
                variant="h2"
                fontSize="1.125rem"
                dangerouslySetInnerHTML={{__html: id_title}}
            />
        )
    }

    if (!dataChart) {
        return (
            <Stack direction="column" spacing="24px" flex="1">
                {titleGraph()}
                <Stack alignItems="center" direction="column">
                    <EmptyResult
                        nature="GRAPH"
                        message={
                            <FormattedMessage
                                id="global.noGraphData"
                                values={{
                                    titreGraph:
                                        <Typography
                                            variant="body2"
                                            fontSize="0,875rem"
                                            fontWeight="bold"
                                            color="#929292"
                                            dangerouslySetInnerHTML={{__html: id_title}}
                                        />
                                }}
                            />
                        }
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column" spacing="12px" flex="1">
            {titleGraph()}
            <div
                id={idChart}
                style={{
                    width: '100%',
                    height: isSmallScreen ? '400px' : '500px'
                }}
            />
        </Stack>
    )
}

export default GridDemographieChart
