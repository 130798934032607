import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import Popup from '../../../../../components/Popup'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import TextInput from '../../../../../components/form/TextInput'
import { MODIFY_EMAIL_FIELDS } from '../../services/personalInfoConstants'
import ButtonDefault from '../../../../../components/ButtonDefault'
import { ToastType } from '../../../../../components/toast/toastConstants'
import { ToastContext } from '../../../../../components/toast/ToastContext'
import LocalStorage from '../../../../../business/storage/LocalStorage'
import UserService from '../../../../../api/gerep/UserService'
import ConfirmMailPopin from './ConfirmMailPopin'
import { useDispatch } from 'react-redux'
import { refreshUser } from '../../services/suggest/suggestActions'
import ConfirmationAuthent from '../../../../../components/doubleAuthentComponents/ConfirmationAuthent'

type ModifyMailProps = {
	openForm: boolean
	setOpenForm: Dispatch<SetStateAction<boolean>>
	currentEmail?: string
	currentAuthent?: string
}

const ModifyMail: React.FC<ModifyMailProps> = ({
	setOpenForm,
	openForm,
	currentEmail,
	currentAuthent
}) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const { addToast } = useContext(ToastContext)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
	const [openDoubleAuthent, setOpenDoubleAuthent] = useState<boolean>(false)
	const [email, setEmail] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const handleSubmit = (values: Record<string, string>) => {
		if (currentAuthent && currentAuthent.includes('mail')) {
			UserService.envoyerCode({ email: values[MODIFY_EMAIL_FIELDS.NEW_EMAIL] }, LocalStorage.getToken())
				.then(() => setOpenDoubleAuthent(true))
				.catch((error) => {
					if (error?.data) {
						const errorMessages = Object.values(error.data as Record<string, string>)
						errorMessages.forEach(errorMessage => addToast(ToastType.ERROR, errorMessage))
					} else {
						addToast(ToastType.ERROR, 'global.error.occurred')
					}
					setIsLoading(false)
				})
		} else {
			onSubmit(values)
		}
	}

	const validateModification = () => {
		setOpenDoubleAuthent(false)
		onSubmit({ newMail: email })
	}

	const onSubmit = (values: Record<string, string>) => {
		setIsLoading(true)
		return UserService.editMail(values[MODIFY_EMAIL_FIELDS.NEW_EMAIL], LocalStorage.getToken())
			.then(() => {
				refreshUser(dispatch)
				addToast(ToastType.SUCCESS, 'personalInfo.connexionInfo.modifyMail.success')
				setOpenForm(false)
				setOpenConfirmation(true)
			})
			.catch((error) => {
				if (error?.data) {
					return error.data
				} else {
					addToast(ToastType.ERROR, 'global.error.occurred')
				}
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<>
			<Popup
				open={openForm}
				setOpen={setOpenForm}
				width="576px"
				title={intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyMail.title' })}
			>
				<Form
					onSubmit={handleSubmit}
					validate={(values) => {
						const errors: Record<string, string> = {}
						if (!values[MODIFY_EMAIL_FIELDS.NEW_EMAIL]) {
							errors[MODIFY_EMAIL_FIELDS.NEW_EMAIL] = intl.formatMessage({ id: 'global.error.required' })
						} else if (values[MODIFY_EMAIL_FIELDS.NEW_EMAIL] && values[MODIFY_EMAIL_FIELDS.NEW_EMAIL] === currentEmail) {
							errors[MODIFY_EMAIL_FIELDS.NEW_EMAIL] = intl.formatMessage({ id: 'global.error.matchingEmail' })
						}

						if (!values[MODIFY_EMAIL_FIELDS.CONFIRM_EMAIL]) {
							errors[MODIFY_EMAIL_FIELDS.CONFIRM_EMAIL] = intl.formatMessage({ id: 'global.error.required' })
						} else if (values[MODIFY_EMAIL_FIELDS.NEW_EMAIL] && values[MODIFY_EMAIL_FIELDS.CONFIRM_EMAIL] && values[MODIFY_EMAIL_FIELDS.NEW_EMAIL] !== values[MODIFY_EMAIL_FIELDS.CONFIRM_EMAIL]) {
							errors[MODIFY_EMAIL_FIELDS.CONFIRM_EMAIL] = intl.formatMessage({ id: 'global.error.validEmail' })
						}

						return errors
					}}
					render={({ handleSubmit, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Stack direction="column" spacing="24px">
								<Typography variant="body2">
									<FormattedMessage id="personalInfo.connexionInfo.modifyMail.subtitle" values={{ br: <br />, email: currentEmail || '' }} />
								</Typography>
								<TextInput
									type="email"
									maxWidth="unset"
									name={MODIFY_EMAIL_FIELDS.NEW_EMAIL}
									label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.newEmail' })}
									onChange={(value) => setEmail(value)}
								/>
								<TextInput
									type="email"
									maxWidth="unset"
									name={MODIFY_EMAIL_FIELDS.CONFIRM_EMAIL}
									label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.confirmNewMail' })}
								/>
								<Box display="flex" justifyContent="center">
									<ButtonDefault variant="contained" onClick={handleSubmit} disabled={pristine}>
										{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
									</ButtonDefault>
								</Box>
							</Stack>
						</form>
					)}
				/>
			</Popup>
			<ConfirmMailPopin
				open={openConfirmation}
				setOpen={setOpenConfirmation}
			/>
			{openDoubleAuthent &&
				<Popup
					open={openDoubleAuthent}
					setOpen={setOpenDoubleAuthent}
					title={intl.formatMessage({ id: 'doubleAuthent.confirmation.title.modification' })}
					width="720px"
					showCloseButton={true}
					onClose={() => setOpenDoubleAuthent(false)}
				>
					<ConfirmationAuthent nextStep={validateModification} currentAuthent={'mail'} email={email} modificationMailOuSms />
				</Popup>
			}
		</>
	)
}

export default ModifyMail