import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'
import { ChevronRight } from '@mui/icons-material'
import { FormattedMessage, useIntl } from 'react-intl'
import RightDrawer from '../../../../../components/RightDrawer'
import Icon from '@mdi/react'
import { mdiPencil, mdiBank, mdiDelete } from '@mdi/js'
import ButtonLink from '../../../../../components/ButtonLink'
import InfoField from '../../../../../components/InfoField'
import FormatUtils from '../../../../../utils/FormatUtils'
import BankActionFormPopin from './BankActionFormPopin'
import { estMajeurFinAnnee } from '../../../../../utils/dateUtils'
import { TypeAdherent } from '../../../../../constant/TypeAdherent'
import RibService from '../../../../../api/gerep/RibService'
import LocalStorage from '../../../../../business/storage/LocalStorage'
import { ToastContext } from '../../../../../components/toast/ToastContext'
import { ToastType } from '../../../../../components/toast/toastConstants'
import DeleteRibPopin from './DeleteRibPopin'
import ConfirmDeleteRibPopin from './ConfirmDeleteRibPopin'
import { COTISATIONS } from '../../services/personalInfoConstants'

type BankAccountRibCellProps = {
	ribInfo: any
	ribs: any[]
	individuList: any[]
	assurePrincipal: any
	haveBBAN?: boolean
}

const BankAccountRibCell: React.FC<BankAccountRibCellProps> = (
	{
		ribInfo,
		ribs,
		individuList,
		assurePrincipal,
		haveBBAN = true
	}
) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)

	const [openDrawer, setOpenDrawer] = useState<boolean>(false)
	const [openPopin, setOpenPopin] = useState<boolean>(false)
	const [openAddPopin, setOpenAddPopin] = useState<boolean>(false)
	const [openDeletePopin, setOpenDeletePopin] = useState<boolean>(false)
	const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false)
	const [loadingDeleteRib, setLoadingDeleteRib] = useState<boolean>(false)

	const beneficiaires = useMemo(() => ribInfo?.beneficiaires || [], [ribInfo])
	const isCotisation = ribInfo.type === COTISATIONS
	const titleMessage = 'personalInfo.bankInfo.refund.title'
	const noTitulaireTitleMessage = 'personalInfo.bankInfo.refund.titleNoTitulaire'
	const isAssurePrincipal = ribInfo.numindiv !== assurePrincipal.numIndividu
	const subtitleMessage = beneficiaires.length === 0 || !haveBBAN ? 'personalInfo.bankInfo.contribution.subtitle' : 'personalInfo.bankInfo.refund.subtitle'
	const intitule: any[] = useMemo(() => beneficiaires ? beneficiaires.map((be: any) => `${be[1].prenom} ${be[1].nom}`) : [], [beneficiaires])

	const titulaire = useMemo(() => `${ribInfo?.titulaire[1].nom} ${ribInfo?.titulaire[1].prenom}`, [ribInfo])

	const displayedTitleMessage = useMemo(() => titulaire ? titleMessage : noTitulaireTitleMessage, [titulaire, titleMessage])

	const titulaireList = useMemo(() => ribs.filter((rib: any) => rib.bban).map((rib: any) => rib.titulaire), [ribs])
	const titulaireCheque = useMemo(() => ribs.filter((rib: any) => !rib.bban).map((rib: any) => rib.titulaire), [ribs])
	const titulairesRibDispo = useMemo(() => individuList
		.filter((benef) => {
			return !titulaireList.find((titulaire: any) => titulaire[1].numIndividu === benef[1].numIndividu)
		})
		.filter(beneficiaire => estMajeurFinAnnee(beneficiaire[1].dateNaissance)), [titulaireList, individuList])
	const optionsTitulairesRib = useMemo(() => {
		if (isAssurePrincipal) {
			return titulaireList
		}
		return individuList.filter(benef => benef[1].typAdr !== TypeAdherent.ASSURE_PRINCIPAL && estMajeurFinAnnee(benef[1].dateNaissance))
	}, [titulairesRibDispo, titulaireList, isAssurePrincipal, individuList])

	const { libNature } = ribInfo

	const handleDeletePopin = () => {
		setLoadingDeleteRib(true)
		RibService.deleteRib({
			idrib: ribInfo.idrib,
			numIndiv: ribInfo.numindiv
		}, LocalStorage.getToken())
			.then(() => {
				setOpenDeletePopin(false)
				setOpenConfirmDelete(true)
			})
			.catch(() => addToast(ToastType.ERROR, '', 'Echec de la suppression du RIB'))
			.finally(() => setLoadingDeleteRib(false))
	}

	return (
		<>
			<ListItem disablePadding sx={{ borderTop: '1px solid #dedede' }}>
				<ListItemButton onClick={() => setOpenDrawer(true)} sx={{ paddingRight: 0, paddingLeft: 0 }}>
					<ListItemText
						primary={intl.formatMessage({ id: displayedTitleMessage }, { titulaire })}
						primaryTypographyProps={{ variant: 'h4' }}
						secondary={intl.formatMessage({ id: subtitleMessage }, {
							type: libNature?.toUpperCase(),
							beneficiaries: intitule.join(', ')
						})}
						secondaryTypographyProps={{ variant: 'subtitle1' }}
					/>
					<ListItemIcon style={{ justifyContent: 'flex-end' }}>
						<ChevronRight />
					</ListItemIcon>
				</ListItemButton>
			</ListItem>
			<RightDrawer openModal={openDrawer} setOpenModal={setOpenDrawer} title={intl.formatMessage({ id: 'personalInfo.bankInfo.title' })}>
				<Stack direction="column">
					<Typography variant="h4">
						{intl.formatMessage({ id: noTitulaireTitleMessage })}
					</Typography>
					<Typography variant="subtitle1">
						{intl.formatMessage({ id: 'personalInfo.bankInfo.contribution.subtitle' }, {
							type: libNature?.toUpperCase()
						})}
					</Typography>
				</Stack>

				<Stack direction="column" spacing="24px">
					<InfoField
						label={intl.formatMessage({ id: 'personalInfo.bankInfo.labels.titulaire' })}
						value={ribInfo?.intitule || ''}
						variantValue="body1"
					/>
					<InfoField
						label={intl.formatMessage({ id: 'personalInfo.bankInfo.labels.iban' })}
						value={`${ribInfo.clefIban} ${FormatUtils.formatBban(ribInfo.bban, true)}`}
						variantValue="body1"
					/>
					{
						(ribInfo.domiciliation && !!ribInfo.domiciliation.length) && (
							<InfoField
								label={intl.formatMessage({ id: 'personalInfo.bankInfo.labels.bank' })}
								value={ribInfo?.domiciliation || ''}
								variantValue="body1"
							/>
						)
					}
					{!isCotisation && beneficiaires && beneficiaires.length > 0 &&
						<InfoField
							label={intl.formatMessage({ id: 'personalInfo.bankInfo.labels.beneficiaires' })}
							value={
								<Stack direction="column" spacing="8px">
									{intitule.map((beneficiaire, index) => (
										<Typography flex={3} variant="body1" key={index}>
											{beneficiaire}
										</Typography>
									))}
								</Stack>
							}
						/>
					}
				</Stack>
				<Box display="flex" flex={1}>
					<ButtonLink
						variant="contained"
						startIcon={<Icon size="20px" path={mdiPencil} />}
						onClick={() => setOpenPopin(true)}
					>
						<FormattedMessage id="personalInfo.bankInfo.buttons.modify" />
					</ButtonLink>
				</Box>

				{ribInfo.numindiv !== assurePrincipal.numIndividu &&
					<Box display="flex" flex={1}>
						<ButtonLink
							variant="contained"
							startIcon={<Icon size="20px" path={mdiDelete} />}
							onClick={() => setOpenDeletePopin(true)}
						>
							<FormattedMessage id="personalInfo.bankInfo.buttons.delete" />
						</ButtonLink>
					</Box>
				}
				{!isCotisation && beneficiaires && beneficiaires.length > 0 &&
					<>
						<Typography variant="body2">
							<FormattedMessage id="personalInfo.bankInfo.addRibText" />
						</Typography>

						<Box display="flex" flex={1}>
							<ButtonLink
								variant="contained"
								startIcon={<Icon size="20px" path={mdiBank} />}
								onClick={() => setOpenAddPopin(true)}
							>
								<FormattedMessage id="personalInfo.bankInfo.buttons.add" />
							</ButtonLink>
						</Box>
					</>
				}

			</RightDrawer>
			<BankActionFormPopin
				open={openAddPopin}
				setOpen={setOpenAddPopin}
				title={intl.formatMessage({ id: 'personalInfo.bankInfo.input.title.add' })}
				ribInfo={ribInfo}
				titulaireCheque={titulaireCheque}
				formType="add"
				assurePrincipal={assurePrincipal}
				individuList={individuList}
				optionsTitulairesRib={titulairesRibDispo}
			/>
			<BankActionFormPopin
				open={openPopin}
				setOpen={setOpenPopin}
				title={intl.formatMessage({ id: 'personalInfo.bankInfo.input.title.modifyRefund' })}
				ribInfo={ribInfo}
				titulaireCheque={titulaireCheque}
				formType="modify_refund"
				assurePrincipal={assurePrincipal}
				individuList={individuList}
				optionsTitulairesRib={optionsTitulairesRib}
			/>
			<DeleteRibPopin loading={loadingDeleteRib} open={openDeletePopin} setOpen={setOpenDeletePopin} onConfirm={handleDeletePopin} />
			<ConfirmDeleteRibPopin open={openConfirmDelete} setOpen={setOpenConfirmDelete} />
		</>
	)
}

export default BankAccountRibCell
