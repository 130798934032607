import React, { useState, useRef } from 'react'
import TextInput from './TextInput'
import { Box, FormHelperText, Stack } from '@mui/material'
import {useBreakpoints} from "../breakpoints/BreakpointsProvider";

type AuthentInputProps = {
	name: string
	codeLength: number
	error?: string
}

const AuthentInput: React.FC<AuthentInputProps> = ({ name, codeLength, error }) => {
	const [authent, setAuthent] = useState(new Array(codeLength).fill(''))
	const inputRefs = useRef<(HTMLDivElement)[]>([])
	const { isMobile } = useBreakpoints()
	const handleChange = (event: string, index: number) => {
		if (isNaN(Number(event))) return false

		const newValues = [...authent]
		newValues[index] = event
		setAuthent(newValues)
		if (event !== '') {
			if (index < codeLength - 1) {
				const nextInput = inputRefs.current[index + 1]?.querySelector('input')
				if (nextInput) {
					nextInput.focus()
				}
			}
		}
	}

	const handleOnKeyUp = (event: any, index: number) => {
		if(event.key==='Backspace'){
			if (index > 0) {
				const prevInput = inputRefs.current[index - 1]?.querySelector('input')
				if (prevInput) {
					prevInput.focus()
				}
			}
		}
	}

	const handleOnKeyDown = (event: any, index: number) => {
		if(!isNaN(Number(event.key)) && authent[index]!==''){
			if (index < codeLength - 1) {
				const nextInput = inputRefs.current[index + 1]?.querySelector('input')
				if (nextInput) {
					nextInput.focus()
				}
			}
		}
	}

	return (
		<>
			<Stack direction="row" spacing="14px" justifyContent="center">
				{authent.map((data, index) => (
					<Box ref={(e: HTMLDivElement) => inputRefs.current[index] = e} key={index}>
						<TextInput
							name={name + index.toString()}
							numberOnly={true}
							maxLength={1}
							onChange={(event: string) => {
								handleChange(event, index)
							}}
							onKeyUp={(event: string) => {
								handleOnKeyUp(event, index)
							}}
							onKeyDown={(event: string) => {
								handleOnKeyDown(event, index)
							}}
							size="small"
							maxWidth="42px"
							style={isMobile? { padding: "8.5px 7px",textAlign: 'center' }: { textAlign: 'center' }}
						/>
					</Box>
				))}
			</Stack>
			{error && <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>}
		</>
	)
}

export default AuthentInput
