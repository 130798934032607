import React, {useEffect, useState} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import Loader from '../../../../components/Loader'
import {Divider, Stack, Typography} from '@mui/material'
import PageLayoutEE from '../../../../components/PageLayoutEE'
import {FormattedMessage, useIntl} from 'react-intl'
import GridFinancePeriodeChart from '../components/GridFinancePeriodeChart'
import GridFinanceTotalChart from '../components/GridFinanceTotalChart'
import GridFinanceYearChart from '../components/GridFinanceYearChart'
import {isDataKPILoading, santeCotisationSelector} from '../../data/dataSelectors'
import AnneeSelector from '../../components/AnneeSelector'
import TypeDataSelector from '../../components/TypeDataSelector'
import FormatUtils from '../../../../utils/FormatUtils'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import appConst from '../../../../constant/appConstants'
import EmptyResult from '../../components/EmptyResult'


const ARTICLE_FINANCE_FIRST_BAR_CHART = 'finance_first_bar_chart'
const ARTICLE_FINANCE_SECOND_BAR_CHART = 'finance_second_bar_chart'

const SanteFinanceCotisationPageEE = (
	{
		// @ts-ignore
		loading,
		// @ts-ignore
		dataCotisation,
		// @ts-ignore
		getArticleByShortcut,
		// @ts-ignore
		financeFirstBarChartArticle,
		// @ts-ignore
		financeSecondBarChartArticle
	}
) => {

	const intl = useIntl()

	const { isMobile, isTabletPortrait } = useBreakpoints()
	const isSmallScreen = isMobile || isTabletPortrait
	const initialeAnnee = Number(Object.keys(dataCotisation)[2])
	const [annee, setAnnee] = useState<number>(initialeAnnee)
	const [nette, setNette] = useState<boolean>(true)
	const santeFinanceAnneeN = dataCotisation[annee.toString()]
	const santeFinanceAnneeNMoins1 = dataCotisation[(annee - 1).toString()]

	useEffect(() => {
		setAnnee(Number(Object.keys(dataCotisation)[2]))
	}, [dataCotisation])


	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_FINANCE_FIRST_BAR_CHART)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_FINANCE_SECOND_BAR_CHART)
	}, [getArticleByShortcut])

	function handleChangeYear(value:number){
		setAnnee(value)
	}

	function handleChangeNette(){
		setNette(!nette)
	}

	const nothingToShow = (financeAnneeX : any) => {
		if (financeAnneeX !== undefined) {
			if(financeAnneeX.donneesActualisees){
				return false
			}
			return true
		}
	}

	const financeAnneeSelector = () => {
		const selector = [
			{
				value: Object.keys(dataCotisation)[2],
				label: Object.keys(dataCotisation)[2]
			}
		]
		if (!nothingToShow(dataCotisation[Object.keys(dataCotisation)[1]])
			|| (nothingToShow(dataCotisation[Object.keys(dataCotisation)[1]]) && !nothingToShow(dataCotisation[Object.keys(dataCotisation)[0]]))) {
			selector.push({
				value: Object.keys(dataCotisation)[1],
				label: Object.keys(dataCotisation)[1]
			})
		}
		if (!nothingToShow(dataCotisation[Object.keys(dataCotisation)[0]])) {
			selector.push({
				value: Object.keys(dataCotisation)[0],
				label: Object.keys(dataCotisation)[0]
			})
		}
		return selector
	}

	const dataGridFinanceYear = () => {
		const array = []
		if (!nothingToShow(dataCotisation[(initialeAnnee - 2).toString()])) {
			array.push({
				year: (initialeAnnee - 2).toString(),
				ratio: dataCotisation[(initialeAnnee - 2).toString()].ratioNette
			})
		}
		if (!nothingToShow(dataCotisation[(initialeAnnee - 1).toString()])
			|| (nothingToShow(dataCotisation[(initialeAnnee - 1).toString()]) && !nothingToShow(dataCotisation[(initialeAnnee - 2).toString()]))) {
			array.push({
				year: (initialeAnnee - 1).toString(),
				ratio: dataCotisation[(initialeAnnee - 1).toString()].ratioNette
			})
		}
		array.push({
			year: initialeAnnee.toString(),
			ratio: dataCotisation[initialeAnnee.toString()].ratioNette
		})
		return array
	}


	if (loading || santeFinanceAnneeN === undefined || !financeFirstBarChartArticle || !financeSecondBarChartArticle) {
		return <Loader />
	} else {
		return (
			<PageLayoutEE
				title={intl.formatMessage({ id: 'sante.financeCotisation.title' })}
				subtitle={santeFinanceAnneeN.donneesActualisees && !nothingToShow(santeFinanceAnneeN) && (
					intl.formatMessage({id: 'global.majData'}) + santeFinanceAnneeN.donneesActualisees
				)}
			>
				<Stack spacing="24px" alignItems={isSmallScreen? 'center' : ''}>
					<Stack direction={isSmallScreen ? 'column' : 'row'} spacing={isSmallScreen ? '24px' : 0} width="100%">
						<Stack direction="column" flex="1">
							<TypeDataSelector
								onChange={handleChangeNette}
								stateValue={!nette}
								name="typeData"
								label1={intl.formatMessage({id: 'sante.financeCotisation.financeNumbers.cotisNette'})}
								label2={intl.formatMessage({id: 'sante.financeCotisation.financeNumbers.cotisTTC'})}
							/>
						</Stack>
						<Stack direction="column" flex="1" width={isSmallScreen ? '100%' : '50%'}>
							<AnneeSelector
								onChange={handleChangeYear}
								name="toggleYears"
								stateValue={annee}
								data={financeAnneeSelector()}
							/>
						</Stack>
					</Stack>

					<Divider style={{ border: '1px solid #dedede', width: '100%' }}/>

					{nothingToShow(santeFinanceAnneeN) ?
						(
							<EmptyResult
								nature="PAGE"
								message={
									<FormattedMessage
										id="global.noPageData"
										values={{titrePage:
												<Typography
													variant="body2"
													fontSize="0,875rem"
													fontWeight="bold"
													color="#929292"
												>
													{intl.formatMessage({id: 'sante.financeCotisation.title'})}
												</Typography>
										}}
									/>
								}
							/>
						) : (
							<>
								<Stack direction="column" spacing="16px">
									<Stack direction="column" flex="1">
										<Stack
											direction={isSmallScreen ? 'column' : 'row'}
											spacing="24px"
											alignItems="center"
											justifyContent="space-around"
										>
											<Stack
												direction={isMobile ? 'column' : 'row'}
												spacing="24px"
												alignItems="center"
												justifyContent="space-around"
												flex="2"
											>
												<Stack direction="column" alignItems="flex-start">
													<Typography alignSelf="start" fontWeight="500" variant="body1" fontSize="0.875rem">
														{nette
															? intl.formatMessage({id: 'sante.financeCotisation.financeNumbers.cotisNette'})
															: intl.formatMessage({id: 'sante.financeCotisation.financeNumbers.cotisTTC'})
														}
													</Typography>

													{(nette ? (santeFinanceAnneeN.cotisationNetteTotal > 0 ) : (santeFinanceAnneeN.cotisationTTCTotal > 0 ))
														? <>
															<Stack direction="row" alignItems="baseline" spacing="2px">
																<Typography fontSize="2rem" fontWeight="600" color="#4bc4c2" variant="body1">
																	{nette
																		? FormatUtils.formatNumber(santeFinanceAnneeN.cotisationNetteTotal)
																		: FormatUtils.formatNumber(santeFinanceAnneeN.cotisationTTCTotal)
																	}€
																</Typography>
																<Typography variant="subtitle1" fontSize="0.813rem">
																	en {santeFinanceAnneeN.annee}
																</Typography>
															</Stack>
															{(santeFinanceAnneeNMoins1 && santeFinanceAnneeNMoins1.cotisationNetteTotal > 0) &&
																<Stack direction="row" alignItems="baseline" spacing="2px">
																	<Typography fontWeight="600" variant="body1" fontSize="1rem">
																		{nette
																			? FormatUtils.formatNumber(santeFinanceAnneeNMoins1.cotisationNetteTotal)
																			: FormatUtils.formatNumber(santeFinanceAnneeNMoins1.cotisationTTCTotal)
																		}€
																	</Typography>
																	<Typography variant="subtitle1" fontSize="0.813rem">
																		en {santeFinanceAnneeNMoins1.annee}
																	</Typography>
																</Stack>
															}
														</> : <>
															<EmptyResult
																nature="NUMBER"
																message={intl.formatMessage({id: 'global.noKpiNumbers'})}
															/>
														</>
													}
												</Stack>

												<Stack direction="column" alignSelf="flex-start">
													<Typography alignSelf="start" fontWeight="500" variant="body1" fontSize="0.875rem">
														{intl.formatMessage({id: 'sante.financeCotisation.financeNumbers.prestations'})}
													</Typography>

													{santeFinanceAnneeN.prestationTotal > 0
														? <>
															<Stack direction="row" alignItems="baseline" spacing="2px">
																<Typography fontSize="2rem" fontWeight="600" color="#8ac11d" variant="body1">
																	{FormatUtils.formatNumber(santeFinanceAnneeN.prestationTotal)}€
																</Typography>
																<Typography variant="subtitle1" fontSize="0.813rem">
																	en {santeFinanceAnneeN.annee}
																</Typography>
															</Stack>
															{(santeFinanceAnneeNMoins1 && santeFinanceAnneeNMoins1.prestationTotal > 0) &&
																<Stack direction="row" alignItems="baseline" spacing="2px">
																	<Typography fontWeight="600" variant="body1" fontSize="1rem">
																		{FormatUtils.formatNumber(santeFinanceAnneeNMoins1.prestationTotal)}€
																	</Typography>
																	<Typography variant="subtitle1" fontSize="0.813rem">
																		en {santeFinanceAnneeNMoins1.annee}
																	</Typography>
																</Stack>
															}
														</> : <>
															<EmptyResult
																nature="NUMBER"
																message={intl.formatMessage({id: 'global.noKpiNumbers'})}
															/>
														</>
													}
												</Stack>
											</Stack>
											<Stack flex="1" direction="column" alignItems="center">
												<Stack direction="column" margin="auto">
													<Typography alignSelf="center" fontWeight="500" variant="body1" fontSize="0.875rem">
														{intl.formatMessage({id: 'sante.financeCotisation.financeNumbers.ratio'})}
													</Typography>

													<GridFinanceYearChart
														idChart="sante_financeCotisation_year"
														dataChart={dataGridFinanceYear()}
													/>
												</Stack>
											</Stack>
										</Stack>
										{santeFinanceAnneeN.dateCotisationOld
											&& santeFinanceAnneeN.donneesActualisees !== santeFinanceAnneeN.dateCotisationOld
											&&
											<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" mb="-12px" pt="4px">
												{intl.formatMessage({id: 'global.majData'}) + santeFinanceAnneeN.dateCotisationOld}
											</Typography>
										}
									</Stack>
								</Stack>

								<Divider style={{ border: '1px solid #dedede', width: '100%' }} />

								<Stack direction="column" flex="1">
									<Stack direction={isSmallScreen ? 'column' : 'row'} spacing="24px">
										<Stack
											direction="column"
											flex="1"
											sx={{
												borderRight: isSmallScreen ? undefined : '1px solid rgb(222, 222, 222)',
												borderBottom: isSmallScreen ? '1px solid rgb(222, 222, 222)' : undefined,
												paddingBottom: isSmallScreen ? '12px' : 0
											}}
										>
											<GridFinancePeriodeChart
												id_title={financeFirstBarChartArticle && financeFirstBarChartArticle.fields}
												idChart="sante_financeCotisation_periode"
												dataChart={nette ? santeFinanceAnneeN.dataCotisationNette : santeFinanceAnneeN.dataCotisationTTC}
											/>
										</Stack>
										<Stack direction="column" flex="1" spacing="24px">
											<GridFinanceTotalChart
												id_title={financeSecondBarChartArticle && financeSecondBarChartArticle.fields}
												idChart="sante_financeCotisation_financeTotal"
												dataChart={[{
													"year": annee.toString(),
													"S": santeFinanceAnneeN.cotisationNetteTotal,
													"P": santeFinanceAnneeN.prestationTotal,
													"S/P": santeFinanceAnneeN.cotisationNetteTotal - santeFinanceAnneeN.prestationTotal
												}]}
											/>
										</Stack>
									</Stack>
									{santeFinanceAnneeN.dateCotisationOld
										&& santeFinanceAnneeN.donneesActualisees !== santeFinanceAnneeN.dateCotisationOld
										&&
										<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem">
											{intl.formatMessage({id: 'global.majData'}) + santeFinanceAnneeN.dateCotisationOld}
										</Typography>
									}
								</Stack>
							</>
						)
					}
				</Stack>
			</PageLayoutEE>
		)
	}
}

const actions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}
const mapStateToProps = (state: any) => {
	return ({
		loading: isDataKPILoading(state),
		dataCotisation : santeCotisationSelector(state),
		financeFirstBarChartArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_FINANCE_FIRST_BAR_CHART],
		financeSecondBarChartArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_FINANCE_SECOND_BAR_CHART]
	})
}
export default compose(
	connect(mapStateToProps, actions)
)(SanteFinanceCotisationPageEE)

