import React, {Dispatch, SetStateAction} from 'react'
import {getAffiliationAssurePrincipal, getIndividuList} from '../../../webservices/affiliation/affiliationSelector'
import {connect} from 'react-redux'
import Popup from '../../../../components/Popup'
import {FormattedMessage, useIntl} from 'react-intl'
import {Form} from 'react-final-form'
import {CircularProgress, Stack} from '@mui/material'
import SelectInput from '../../../../components/form/SelectInput'
import ButtonDefault from '../../../../components/ButtonDefault'
import Box from '@mui/material/Box'
import appConst from "../../../../constant/appConstants";
import FormatUtils from "../../../../utils/FormatUtils";

type TeleconsultationVyvFormProps = {
	individuList: any,
	assurePrincipal: any,
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	isLoading: boolean
	setIsLoading: Dispatch<SetStateAction<boolean>>
}

const TeleconsultationVyvForm: React.FC<TeleconsultationVyvFormProps> = (
	{
		individuList,
		assurePrincipal,
		open,
		setOpen,
		isLoading,
		setIsLoading
	}
) => {
	const intl = useIntl()

	const onSubmit = (values: any) => {
		const { beneficiaire } = values
		setIsLoading(true)

		if(assurePrincipal.numIndividu === beneficiaire){
			// assurer principale
			appConst.openApplication('maiia-tlc',
				appConst.passConnectUrl,
				'gerep',
				null,
				[
					{key:'nom_beneficiaire', value:assurePrincipal.nom},
					{key:'prenom_beneficiaire', value:assurePrincipal.prenom},
					{key:'birthdate_beneficiaire', value:FormatUtils.toStrLocalDate(FormatUtils.parseDate(assurePrincipal.dateNaissance))}
				]
				,'maiia.maiia-teleconsultation')
			setIsLoading(false)
		} else {
			// Benef
			let individuData = individuList.find((ind:any) => ind[0] === beneficiaire)
			appConst.openApplicationPourUnBeneficiaire('maiia-tlc',
				appConst.passConnectUrl,
				'gerep',
				null,
				individuData[1].nom,
				individuData[1].prenom,
				"",
				FormatUtils.toStrLocalDate(FormatUtils.parseDate(individuData[1].dateNaissance)),
				[
					{key:'nom_beneficiaire', value:individuData[1].nom},
					{key:'prenom_beneficiaire', value:individuData[1].prenom}
				]
				,'maiia.maiia-teleconsultation')
			setIsLoading(false)
		}

	}

	return (
		<Popup maxWidth="600px" open={open} setOpen={setOpen} title={intl.formatMessage({ id: 'servicesSante.vyv.title' })}>
			<Form
				onSubmit={onSubmit}
				validate={(values: any) => {
					const errors: any = {}
					if (!values['beneficiaire']) {
						errors['beneficiaire'] = intl.formatMessage({ id: 'global.error.required' })
					}
					return errors
				}}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Stack direction="column" spacing="24px">
							<SelectInput
								name="beneficiaire"
								data={individuList.map((individu: any) => ({ label: `${individu[1].prenom} ${individu[1].nom}`, value: individu[1].numIndividu }))}
								label={intl.formatMessage({ id: 'servicesSante.vyv.input.beneficiary' })}
							/>
							<Box display="flex" justifyContent="center">
								<ButtonDefault type="submit" variant="contained" onClick={handleSubmit}>
									{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.login" />}
								</ButtonDefault>
							</Box>
						</Stack>
					</form>
				)}
			/>
		</Popup>
	)
}

const mapStateToProps = (state: any) => ({
	// @ts-ignore
	individuList: getIndividuList(state),
	assurePrincipal: getAffiliationAssurePrincipal(state)
})

const mappedAtions = {}

export default connect(mapStateToProps, mappedAtions)(TeleconsultationVyvForm)
