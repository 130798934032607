import {Stack, Typography} from '@mui/material'
import React, {useLayoutEffect} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import EmptyResult from "../../components/EmptyResult";

type GridPrevoyanceChartProps = {
    id_title: string | React.ReactNode | any
    idChart: string
    dataChart: any[]
}

const GridPrevoyanceChart: React.FC<GridPrevoyanceChartProps> = (
    {
        id_title,
        idChart,
        dataChart
    }
) => {
    const intl = useIntl()
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const isSmallScreen = isMobile || isTabletPortrait

    useLayoutEffect(() => {
        if (dataChart && dataChart.length) {
            var maxData = 0

            dataChart.forEach((data) => {
                if (data.ratio > maxData) {
                    maxData = data.ratio
                }
            })

            var maxDataDossier = 0

            dataChart.forEach((data) => {
                if (Math.abs(data.ferme) > maxDataDossier) {
                    maxDataDossier = Math.abs(data.ferme)
                }
                if (data.ouvert > maxDataDossier) {
                    maxDataDossier = data.ouvert
                }
            })

            let root = am5.Root.new(idChart)
            root.setThemes([am5themes_Animated.new(root)])
            root.interfaceColors.set("grid", am5.color(0xffffff))
            // @ts-ignore
            root._logo.dispose()

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    layout: root.verticalLayout
                })
            )
            chart.chartContainer.setAll({
                paddingLeft: -32
            })
            // @ts-ignore
            chart.get("colors").set("colors", [
                am5.color(0xb5bbff),
                am5.color(0x4bc4c2),
                am5.color(0xfc454a),
            ])

            let xRenderer = am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                minGridDistance: 12
            })
            xRenderer.grid.template.setAll({
                location: 1
            })
            xRenderer.labels.template.setAll({
                rotation: -45,
                centerY: am5.p50,
                centerX: am5.p100,
                textAlign: 'center'
            })

            let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: isSmallScreen ? 'sectionShort' : 'section',
                    renderer: xRenderer,
                    tooltip: am5.Tooltip.new(root, {})
                })
            )
            xAxis.data.setAll(dataChart)

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {
                        strokeOpacity: 10
                    }),
                    min: -maxDataDossier - 10,
                    max: maxDataDossier + 10,
                })
            )

            yAxis.children.unshift(am5.Label.new(root, {
                text: `[#7a7a7a]${intl.formatMessage({id: 'prevoyance.gridChart.axisLabels.yAxis'})}`,
                textAlign: 'center',
                y: am5.p50,
                rotation: -90
            }))

            // @ts-ignore
            function makeSeries(name: string, fieldName: string) {

                let series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: fieldName,
                        categoryXField: isSmallScreen ? 'sectionShort' : 'section',
                    })
                )
                series.columns.template.setAll({
                    tooltipText: '{name} en {categoryX} : {valueY.formatNumber(\'#s\')}',
                    width: am5.percent(90),
                    tooltipY: 0,
                    strokeOpacity: 0,
                    cornerRadiusTL: 10,
                    cornerRadiusTR: 10,
                })
                series.data.setAll(dataChart)

                series.appear()
            }

            makeSeries(intl.formatMessage({id: 'prevoyance.gridChart.openedDir'}), "ouvert")
            makeSeries(intl.formatMessage({id: 'prevoyance.gridChart.closedDir'}), "ferme")

            let AxisRatio = am5xy.AxisRendererY.new(root, {opposite: true})
            let RatioAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: AxisRatio,
                    min: -maxData - 10,
                    max: maxData + 10,
                    strictMinMax: true
                })
            )
            RatioAxis.children.unshift(
                am5.Label.new(root, {
                    text: `[#7a7a7a]${intl.formatMessage({id: 'prevoyance.gridChart.axisLabels.ratioAxis'})}`,
                    textAlign: 'center',
                    rotation: 90,
                    y: am5.p50,
                    x: am5.percent(190),
                    position: 'absolute'
                })
            )

            let series1 = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    name: intl.formatMessage({id: 'prevoyance.gridChart.averageRate'}),
                    xAxis: xAxis,
                    yAxis: RatioAxis,
                    valueYField: 'ratio',
                    categoryXField: isSmallScreen ? 'sectionShort' : 'section',
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: 'horizontal',
                        labelText: '{name} en {categoryX} : {valueY} %'
                    })
                })
            )
            series1.strokes.template.setAll({
                templateField: 'strokeSettings',
                strokeWidth: 3
            })
            series1.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(root, {
                        strokeWidth: 3,
                        stroke: series1.get("stroke"),
                        radius: 5,
                        fill: root.interfaceColors.get("background")
                    })
                })
            })
            series1.data.setAll(dataChart)

            let legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                })
            )
            legend.data.setAll(chart.series.values)

            chart.set("cursor", am5xy.XYCursor.new(root, {}))
            let cursor = chart.get("cursor")
            cursor?.lineX.setAll({visible: false})
            cursor?.lineY.setAll({visible: false})

            chart.appear(1000, 100)

            return () => root.dispose()
        }
    }, [dataChart, idChart])

    const titleGraph = () => {
        return (
            <Typography
                textAlign="left"
                variant="h2"
                fontSize="1.125rem"
                dangerouslySetInnerHTML={{__html: id_title}}
            />
        )
    }

    if (!dataChart || (dataChart && !dataChart.length)) {
        return (
            <Stack direction="column" spacing="24px" flex="1">
                {titleGraph()}
                <Stack alignItems="center" direction="column">
                    <EmptyResult
                        nature="GRAPH"
                        message={
                            <FormattedMessage
                                id="global.noGraphData"
                                values={{
                                    titreGraph:
                                        <Typography
                                            variant="body2"
                                            fontSize="0,875rem"
                                            fontWeight="bold"
                                            color="#929292"
                                            dangerouslySetInnerHTML={{__html: id_title}}
                                        />
                                }}
                            />
                        }
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column" spacing="12px" flex="1">
            {titleGraph()}
            <div
                id={idChart}
                style={{
                    width: '100%',
                    height: isSmallScreen ? '400px' : '500px'
                }}
            />
        </Stack>
    )
}

export default GridPrevoyanceChart
