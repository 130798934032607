import React, {useLayoutEffect} from 'react'
import {Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5percent from '@amcharts/amcharts5/percent'
import EmptyResult from '../../components/EmptyResult'

type PieChartconsomationEuroProps = {
    id_title: string | React.ReactNode | any
    idChart: string
    dataChart: any[]
}

const PieChartConsommationEuro: React.FC<PieChartconsomationEuroProps> = (
    {
        id_title,
        idChart,
        dataChart
    }
) => {
    const intl = useIntl()
    const {isMobile, isTabletPortrait, isTablet} = useBreakpoints()
    const isSmallScreen = isMobile || isTabletPortrait

    useLayoutEffect(() => {
        if (dataChart.length > 0) {
            let root = am5.Root.new(idChart)
            root.setThemes([am5themes_Animated.new(root)])
            root.setThemes([am5themes_Animated.new(root)])
            root.numberFormatter.set("intlLocales", "fr-FR");
            root.numberFormatter.set("numberFormat", {
                maximumFractionDigits: 0
            })
            // @ts-ignore
            root._logo.dispose()

            let chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    layout: root.verticalLayout,
                    innerRadius: am5.percent(50),
                    radius: am5.percent(63)
                })
            )

            let series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    valueField: "value",
                    categoryField: "category",
                    legendLabelText: '[{fill}]{category}[/]',
                    legendValueText: '[bold]{value} €[/]'
                })
            )
            series.slices.template.setAll({
                strokeWidth: 3,
                stroke: am5.color(0xffffff),
                cornerRadius: 15
            })

            series.labels.template.setAll({
                text: isSmallScreen ? '[bold ]{valuePercentTotal.formatNumber(\'#.##\')}%[/]' :'{category}: [bold ]{valuePercentTotal.formatNumber(\'#.##\')}%[/]',
                maxWidth: 80,
                oversizedBehavior: "wrap-no-break"
            })


            let colorFinal : String[] =[]
            dataChart.forEach((data) => {
                colorFinal.push(data.color)
            })
            // @ts-ignore
            series.get("colors").set("colors", colorFinal)

            let dataFinal: any[] = []
            dataChart.forEach((data) => {
                dataFinal.push({value: data.oldValue, category: intl.formatMessage({id: data.title})})
            })
            series.data.setAll(dataFinal)

            let legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.percent(30),
                    layout: root.verticalLayout
                })
            );
            legend.data.setAll(series.dataItems);

            series.appear(1000, 100)

            return () => root.dispose()
        }

    }, [dataChart, idChart])

    const titleGraph = () => {
        return (
            <Typography
                minHeight={44}
                textAlign="left"
                variant="h2"
                fontSize="1.125rem"
                dangerouslySetInnerHTML={{__html: id_title}}
            />
        )
    }

    if (!(dataChart.length > 0)) {
        return (
            <Stack direction="column" spacing="24px" flex="1">
                {titleGraph()}
                <Stack alignItems="center" direction="column">
                    <EmptyResult
                        nature="GRAPH"
                        message={
                            <FormattedMessage
                                id="global.noGraphData"
                                values={{
                                    titreGraph:
                                        <Typography
                                            variant="body2"
                                            fontSize="0,875rem"
                                            fontWeight="bold"
                                            color="#929292"
                                            dangerouslySetInnerHTML={{__html: id_title}}
                                        />
                                }}
                            />
                        }
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column" spacing="12px" flex="1">
            {titleGraph()}
            <div
                id={idChart}
                style={{
                    width: '100%',
                    height: '500px'
                }}
            />
        </Stack>
    )
}

export default PieChartConsommationEuro
