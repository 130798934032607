import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const ButtonFlottant = styled(ButtonMUI)<ButtonProps>(({ theme }) => ({
	'&.MuiButton-root': {
		borderRadius: 24,
		minWidth: 180,
		height: 36,
		color: 'white',
		fontSize: '0.875rem',
		backgroundColor: '#4bc4c2',
		fontWeight: 500,
		textTransform: 'none',
		boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 40%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 40%)',
		position: 'fixed',
		right: '20px',
		bottom: '20px'
	}
}))

export default ButtonFlottant
