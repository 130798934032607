import React, { useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import TeleconsultationVyvForm from './TeleconsultationVyvForm'
import TeleconsultationCard from './TeleconsultationCard'
import ButtonDashboard from '../../../../components/ButtonDashboard'

type TeleconsultationVyvBlockProps = {
	service: any
}

const TeleconsultationVyvBlock: React.FC<TeleconsultationVyvBlockProps> = ({ service }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [openForm, setOpenForm] = useState<boolean>(false)

	const buttonLibelle = service?.libelleLienWeb || 'Accédez au site'

	return (
		<TeleconsultationCard
			service={service}
			lienWebCustom={
				<>
					<Box display="flex" width="100%" justifyContent="center">
						<ButtonDashboard variant="outlined" onClick={() => setOpenForm(true)}>
							{isLoading ? <CircularProgress size={24} color="inherit" /> : buttonLibelle}
						</ButtonDashboard>
					</Box>
					<TeleconsultationVyvForm open={openForm} setOpen={setOpenForm} isLoading={isLoading} setIsLoading={setIsLoading} />
				</>
			}
		/>
	)
}

export default TeleconsultationVyvBlock