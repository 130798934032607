// api
import {
	identifier as identifierApi,
	getBeneficiaires as beneficiairesApi,
	downloadGaranties as downloadGarantiesApi,
	downloadAttestationDispense as downloadAttestationDispenseApi,
	validerPreAffiliation as validerPreAffiliationApi,
	changeCarteTPOption as changeCarteTPOptionApi,
	preSetFields as preSetFieldsApi
} from '../preaffiliation/api/PreAffiliationService'
// selector
import {getToken} from '../preaffiliation/user/userSelector'
import { getAffiliation } from './WsSelector'
import {ACTION_NAMESPACE} from '../preaffiliation/services/preaffiliationActions'
import {getStates} from '../errorPage/surveillance/surveillanceActions'
// utils
// const

export const types = {
	GET_AFFILIATION: `${ACTION_NAMESPACE}/GET_AFFILIATION`,
	RESET_AFFILIATION: `${ACTION_NAMESPACE}/RESET_AFFILIATION`,
	GET_BENEFICIAIRES: `${ACTION_NAMESPACE}/GET_BENEFICIAIRES`,
	SUBSCRIBE_ADHESION: `${ACTION_NAMESPACE}/SUBSCRIBE_ADHESION`,
	GET_PRESET_FIELDS: `${ACTION_NAMESPACE}/GET_PRESET_FIELDS`
}

/* FIXME Trouver un moyen d'initialiser le formulaire mais de conserver les données saisies par l'utilisateur */
/**
 * La méthode permet de remettre le flag new à false si l'affiliation est "nouvelle"
 * Si new === true, on écrase tous les champs relatifs à l'étape Informations Personnelles;
 * sinon on conserve les données renseignées par l'utilisateur
 * @returns {function(*, *)}
 */
export const resetAffiliation = () => (dispatch, getState) => {
	const affl = getAffiliation(getState())
	if (affl.new) {
		dispatch({
			type: types.RESET_AFFILIATION,
			payload: affl
		})
	}
}

export const identifier = values => (dispatch, getState) => {
	return identifierApi(getToken(getState()), values)
		.then((res) => {
			const affiliation = (res.affiliations && res.affiliations[0]) || {}
			affiliation.contratEnVigueur = res.contractInForce
			dispatch({
				type: types.GET_AFFILIATION,
				payload: {
					...affiliation,
					identification: res.status.code
				}
			})
			return res
		})
}

export const preSetFields = (idSociete, numIndiv) => (dispatch, getState) => {
	return preSetFieldsApi(getToken(getState()), idSociete, numIndiv)
		.then((res) => {
			const preset_infos = (res.affiliations && res.affiliations[0]) || {}
			const preset_bank = (res.bank && res.bank) || {}
			const preremplissage = {
				...preset_infos,
				libType: preset_bank.libType,
				clefIban: preset_bank.clefIban,
				bban: preset_bank.bban,
				domiciliation: preset_bank.domiciliation,
				intitule: preset_bank.intitule
			}
			dispatch({
				type: types.GET_PRESET_FIELDS,
				payload: {
					...preremplissage
				}
			})
			return res
		})
}


export const getBeneficiaires = idSociete => (dispatch, getState) => {
	return beneficiairesApi(getToken(getState()), idSociete)
		.then((res) => {
			dispatch({
				type: types.GET_BENEFICIAIRES,
				payload: res && res.beneficiaires
			})
			return res
		})
}

export const downloadGaranties = (idSociete, siret, annee) => (dispatch, getState) => {
	return downloadGarantiesApi(getToken(getState()), idSociete, { siret, annee })
}

export const downloadAttestationDispense = (values) => (dispatch, getState) => {
	return downloadAttestationDispenseApi(getToken(getState()), values)
}

export const validerPreAffiliation = values => (dispatch, getState) => {
	return validerPreAffiliationApi(getToken(getState()), values)
		.then((res) => {
			dispatch({
				type: types.SUBSCRIBE_ADHESION,
				payload: res
			})
			return res
		})
}

export const changeCarteTPOption = values => (dispatch, getState) => (
	changeCarteTPOptionApi(getToken(getState()), values)
)
