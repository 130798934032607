import {subDays} from 'date-fns'

export const motifDispenseChosen = (contract: any, value: number) => {
    if (contract.justifsDispense && value) {
        return contract.justifsDispense.find((motif: any) => motif.codedispense === value)
    }
}

export const couvertureChosen = (couvertures: any, value: any) => {
    if (couvertures && value) {
        return couvertures.find((couv: any) => couv.numFor === value)
    }
}

export	const oneCheckBoxListBool = (checkBoxList: Array<string>) => {
    return checkBoxList.length === 1
}
