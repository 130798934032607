import LocalStorage from '../../../business/storage/LocalStorage'
import KpiService from '../../../api/gerepEe/KpiService'
import {isDataKPILoading} from './dataSelectors'


const ACTION_NAMESPACE = '/DATA_KPI'

export const types = {
	LOADING: `${ACTION_NAMESPACE}/LOADING`,
	HOMEPAGE: `${ACTION_NAMESPACE}/HOMEPAGE`,
	SELECT_SOCIETE: `${ACTION_NAMESPACE}/SELECT_SOCIETE`,
	SANTE_COTISATION: `${ACTION_NAMESPACE}/SANTE_COTISATION`,
	SANTE_DEMOGRAPHIE: `${ACTION_NAMESPACE}/SANTE_DEMOGRAPHIE`,
	SANTE_COMSOMMATION: `${ACTION_NAMESPACE}/SANTE_COMSOMATION`,
	QUALITE: `${ACTION_NAMESPACE}/QUALITE`,
	PREVOYANCE: `${ACTION_NAMESPACE}/PREVOYANCE`,
	GET_LIST_CONTACTS: `${ACTION_NAMESPACE}/GET_LIST_CONTACTS`,
	CONTACTER_GEREP: `${ACTION_NAMESPACE}/CONTACTER_GEREP`,
	RESET: `${ACTION_NAMESPACE}/RESET`
}

export const getDataHomePage = (annee) => (dispatch, getState) => {
	KpiService.getDataKPI(LocalStorage.getToken(), 0, annee, "dataHomePage").then((values) => {
		dispatch({
			type: types.HOMEPAGE,
			payload: values
		})
	}).catch(e => {
		dispatch({
			type: types.HOMEPAGE,
			payload: []
		})
	})
}

export const getDataKpiSociete = (idSociete, annee, force) => (dispatch, getState) => {
	if (force || !isDataKPILoading(getState())) {
		dispatch({
			type: types.LOADING,
			payload: true
		})
		Promise.allSettled([
			KpiService.getDataKPI(LocalStorage.getToken(), idSociete, annee, "dataSanteCotisation"),
			KpiService.getDataKPI(LocalStorage.getToken(), idSociete, annee, "dataSanteDemographie"),
			KpiService.getDataKPI(LocalStorage.getToken(), idSociete, annee, "dataSanteConsomation"),
			KpiService.getDataKPI(LocalStorage.getToken(), idSociete, annee, "dataQualite"),
			KpiService.getDataKPI(LocalStorage.getToken(), idSociete, annee, "dataPrevoyance"),
		]).then((values) => {
			if(values[0].status === "fulfilled"){
				dispatch({
					type: types.SANTE_COTISATION,
					payload: values[0].value
				})
			}
			if(values[1].status === "fulfilled"){
				dispatch({
					type: types.SANTE_DEMOGRAPHIE,
					payload: values[1].value
				})
			}
			if(values[2].status === "fulfilled"){
				dispatch({
					type: types.SANTE_COMSOMMATION,
					payload: values[2].value
				})
			}
			if(values[3].status === "fulfilled"){
				dispatch({
					type: types.QUALITE,
					payload: values[3].value
				})
			}
			if(values[4].status === "fulfilled"){
				dispatch({
					type: types.PREVOYANCE,
					payload: values[4].value
				})
			}
			dispatch({
				type: types.LOADING,
				payload: false
			})

		}).catch(e => {
			dispatch({
				type: types.LOADING,
				payload: false
			})
		})
	}
}

export const handleSociete = (value) => (dispatch, getState) => {
	dispatch({
		type: types.SELECT_SOCIETE,
		payload: value
	})
	dispatch(getDataKpiSociete(value, (new Date()).getFullYear(), true))
}


export const getListContactsSociete = (idSociete) => (dispatch, getState) => {
	return KpiService.getListContacts(LocalStorage.getToken(), idSociete)
		.then((res) => {
			dispatch({
				type: types.GET_LIST_CONTACTS,
				payload: res
			})
			return res
		})
}

export const contacterGerep = values => (dispatch, getState) => {
	return KpiService.contacterGerep(LocalStorage.getToken(), values)
		.then((res) => {
			dispatch({
				type: types.CONTACTER_GEREP,
				payload: res
			})
			return res
		})
}


/**
 * Commande de nettoyage du module.
 */
export const reset = () => (dispatch) => {
	dispatch({
		type: types.RESET
	})
}
