import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import DashboardCard from '../../../../components/DashboardCard'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { Stack, Typography } from '@mui/material'
import BeneficiaryAvatar from '../../../../components/BeneficiaryAvatar'
import { useHistory } from 'react-router-dom'
import { SECONDARY_PATH } from '../../../../constant/path'

type BeneficiaryCardProps = {
	beneficiaries: any[]
	affiliationExpired?: boolean
}

const BeneficiaryCard: React.FC<BeneficiaryCardProps> = ({ beneficiaries, affiliationExpired }) => {
	const intl = useIntl()
	const history = useHistory()

	const { isMobile } = useBreakpoints()

	const height = isMobile ? 'auto' : '236px'

	const BENEFCIARY_LIST = beneficiaries.length > 3 ? beneficiaries.slice(0, 2) : beneficiaries

	const renderOtherAvatar = useCallback(() => {
		if (beneficiaries.length > 3) {
			return (
				<Stack direction="row" alignItems="center" spacing="16px">
					<BeneficiaryAvatar size="large" lastname="..." />
					<Typography variant="body2">
						<FormattedMessage id="dashboard.beneficiary.other" values={{ count: beneficiaries.length - 2 }} />
					</Typography>
				</Stack>
			)
		}
		return <></>
	}, [beneficiaries])

	return (
		<DashboardCard
			height={height}
			title={intl.formatMessage({ id: 'dashboard.beneficiary.title' })}
			action={!affiliationExpired ? ({
				label: intl.formatMessage({ id: 'global.button.seeAll' }),
				onClick: () => history.push(SECONDARY_PATH.ACCOUNT_BENEFICIARY)
			}) as ButtonAction : undefined}
		>
			<Stack width="100%" direction="column" spacing="12px">
				{BENEFCIARY_LIST.map((beneficiary, index) => {
					return (
						<Stack key={index} direction="row" alignItems="center" spacing="16px">
							<BeneficiaryAvatar size="large" firstname={beneficiary[1].prenom} lastname={beneficiary[1].nom} type={beneficiary[1].typAdr} />
							<Typography variant="body2">
								{`${beneficiary[1].prenom} ${beneficiary[1].nom}`}
							</Typography>
						</Stack>
					)
				})}
				{renderOtherAvatar()}
			</Stack>
		</DashboardCard>
	)
}

export default BeneficiaryCard
