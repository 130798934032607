import GerepAPI from './GerepAPI'

export default class UserService {

    static getUser(token) {
        return GerepAPI.get('/users/me', null, token)
    }

    static login(login, password, captcharesponse, rememberMe, viewCarteTP) {
	    return GerepAPI.post('/login', {
		    login,
		    password,
		    captcharesponse,
		    rememberMe,
		    viewCarteTP
	    }, null, true)
    }

    static logout(token) {
        return GerepAPI.post('/logout', null, token)
    }

    static getAuthCode(token) {
        return GerepAPI.get('/ticket', null, token)
    }
    static subscribe(idAdherent, nom, prenom, niss, password, dateNaissance, email, newsletter, captcharesponse) {
        return GerepAPI.post('/users/subscribe', {
            idAdherent,
            nom,
            prenom,
            niss,
            password,
            dateNaissance,
            email,
            newsletter,
            captcharesponse
        })
    }

    static activate(idAdherent, activationCode) {
        return GerepAPI.post(`/users/activate/${idAdherent}/${activationCode}`)
    }

    static changePassword(idAdherent, mailAdherent, dateNaissance, captcharesponse) {
        return GerepAPI.post('/usersPassword/changePassword', {
            idAdherent,
            mailAdherent,
            dateNaissance,
            captcharesponse
        })
    }

    static validatePasswordToken(passwordToken) {
        return GerepAPI.post(`/usersPassword/validatePasswordToken`, {
            passwordToken
        })
    }

    static editPassword(password, newPassword, token) {
        return GerepAPI.post('/usersPassword/editPassword', {
            password,
            newPassword
        }, token)
    }

    static editPasswordExt(token, password, newPassword, passwordToken) {
        return GerepAPI.post('/usersPassword/editPasswordExt', {
            password,
            newPassword,
            passwordToken
        }, token)
    }

    static editMail(newMail, token) {
        return GerepAPI.post('/users/editMail', {
            newMail
        }, token)
    }

    static editAuthent(values, token) {
        return GerepAPI.post('/users/editAuthent', values, token)
    }

    static verifierCode(values, token) {
        return GerepAPI.post('/users/verifierCode', values, token)
    }


    static verifierCodeConnexion(values, token) {
        return GerepAPI.post('/users/verifierCodeConnexion', values, token)
    }

    static envoyerCode(values, token) {
        return GerepAPI.post('/users/envoyerCode', values, token)
    }

    static envoyerCodeConnexion(values, token) {
        return GerepAPI.post('/login/envoyerCode', values, token)
    }

    static editInformationsPersonnelles(values, token) {
        return GerepAPI.post('/users/editInformationsPersonnelles', values, token)
    }

    static getInfoPersonelles(token) {
        return GerepAPI.get('/users/info', null, token)
    }

    static forceResetPassword(values, token) {
        return GerepAPI.post('/usersPassword/forceResetPassword', values, token)
    }
}
