import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import InterstitielPopin from './InterstitielPopin'
import { Box } from '@mui/material'
import { isAffiliationLoading } from '../../ea/webservices/affiliation/affiliationSelector'
import { isNotificationLoading } from '../../ea/webservices/notifications/NotificationSelectors'
import { isLoading } from '../../ea/webservices/remboursements/remboursementsSelector'
import { isContratsLoading } from '../../ea/webservices/contrats/contratsSelector'
import { closeInterstitiel } from '../../ea/webservices/interstitiel/interstitielActions'
import LocalStorage from '../../business/storage/LocalStorage'
import { userSelector } from '../../ea/ui/user/userSelectors'

type InterstitielContextProps = {}

const InterstitielValues: InterstitielContextProps = {}

export const InterstitielContext = React.createContext<InterstitielContextProps>(InterstitielValues)

type InterstitielProviderProps = {
	children: React.ReactNode
}

const InterstitielProvider: React.FC<InterstitielProviderProps> = (
	{
		children,
		// @ts-ignore
		interstitiels,
		// @ts-ignore
		affiliationLoading,
		// @ts-ignore
		notificationLoading,
		// @ts-ignore
		refundLoading,
		// @ts-ignore
		isContratsLoading,
		// @ts-ignore
		closeInterstitiel,
		// @ts-ignore
		user
	}) => {
	const [open, setOpen] = useState<boolean>(true)
	const [displayedInterstitiel, setDisplayedInterstitiel] = useState<any>()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [interstitielIndex, setInterstitielIndex] = useState<number>(0)

	const filteredInterstitiels = useMemo(() => {
		const localPrefs = LocalStorage.getItem('interstitielPrefs')
		const interstitielPrefs = localPrefs ? JSON.parse(localPrefs) : {}
		const userPrefs = interstitielPrefs[user?.id]

		return interstitiels?.filter((interstitiel: any) => userPrefs && userPrefs[interstitiel.idArticle] !== undefined ? !userPrefs[interstitiel.idArticle] : true)
	}, [interstitiels])

	const updateInterstitiel = () => {
		if (!displayedInterstitiel && filteredInterstitiels?.length > 0) {
			setDisplayedInterstitiel(filteredInterstitiels[0])
		} else if (displayedInterstitiel && interstitielIndex + 1 <= filteredInterstitiels?.length) {
			setDisplayedInterstitiel(filteredInterstitiels[interstitielIndex + 1])
			setInterstitielIndex(interstitielIndex + 1)
		} else if (displayedInterstitiel && interstitielIndex + 1 > filteredInterstitiels?.length) {
			setDisplayedInterstitiel(undefined)
		}
	}

	useEffect(() => {
		updateInterstitiel()
		setIsLoading(false)
	}, [interstitiels])

	useEffect(() => {
		if(displayedInterstitiel){
			setOpen(true)
		}
	}, [displayedInterstitiel])

	const onPermanentClose = (permanentClose: boolean) => {
		const localPrefs = LocalStorage.getItem('interstitielPrefs')
		const currentPrefs = localPrefs ? JSON.parse(localPrefs) : {}
		const updatedPrefs = currentPrefs[user?.id] ? { ...currentPrefs[user?.id], [displayedInterstitiel?.idArticle]: permanentClose } : { [displayedInterstitiel?.idArticle]: permanentClose }
		LocalStorage.setItem('interstitielPrefs', JSON.stringify({ ...currentPrefs, [user?.id]: updatedPrefs }))
		displayedInterstitiel && closeInterstitiel(displayedInterstitiel?.idArticle, permanentClose)
	}

	return (
		<InterstitielContext.Provider value={{}}>
			{children}
			{displayedInterstitiel && (!isLoading && !affiliationLoading && !notificationLoading && !refundLoading && !isContratsLoading) && (
				<InterstitielPopin
					openForm={open}
					setOpenForm={setOpen}
					title={displayedInterstitiel?.title}
					onClose={updateInterstitiel}
					onPermanentClose={onPermanentClose}
					closePermanently={interstitielIndex + 1 === interstitiels?.length}
				>
					<Box
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
						dangerouslySetInnerHTML={{ __html: displayedInterstitiel?.html }}
					/>
				</InterstitielPopin>
			)}
		</InterstitielContext.Provider>
	)
}

const mapStateToProps = (state: any) => ({
	interstitiels: state.interstitiel.articles,
	affiliationLoading: isAffiliationLoading(state),
	notificationLoading: isNotificationLoading(state),
	refundLoading: isLoading(state),
	isContratsLoading: isContratsLoading(state),
	user: userSelector(state)
})
const actions = {
	closeInterstitiel
}

export default connect(mapStateToProps, actions)(InterstitielProvider)
