import React, { useContext, useState } from 'react'
import { CircularProgress, Divider, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import TextInput from '../../../../components/form/TextInput'
import { LOGIN_FIELDS } from '../services/loginConstants'
import ButtonLink from '../../../../components/ButtonLink'
import CheckboxInput from '../../../../components/form/CheckboxInput'
import Box from '@mui/material/Box'
import ButtonConnexion from '../../../../components/ButtonConnexion'
import SignupFormEE from './SignupFormEE'
import ForgottenPasswordFormEE from './ForgottenPasswordFormEE'
import { loginEE } from '../services/login/loginActions'
import ReactGA from 'react-ga'
import { useAppDispatch } from '../../../../config/hooksUnivers'
import { MAIN_PATH_EE } from '../../../../constant/path'
import { UNIVERS_ENTREPRISES, UNIVERS_ENTREPRISES_KPI } from '../../../constant/univers'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { trim } from '../../../../utils/stringUtils'
import AuthentificationSecureEE from './AuthentificationSecureEE'
import { ToastType } from '../../../../components/toast/toastConstants'
import LocalStorage from '../../../../business/storage/LocalStorage'
import { ToastContext } from '../../../../components/toast/ToastContext'
import UserServiceEE from '../../../../api/gerepEe/UserServiceEE'

const LoginFormEE = () => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)
	const dispatch = useAppDispatch()
	const { isMobile } = useBreakpoints()
	const [openSignup, setOpenSignup] = useState<boolean>(false)
	const [openSecureAuthent, setOpenSecureAuthent] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [connexionIsLoading, setConnexionIsLoading] = useState<boolean>(false)
	const [openForgottenPassword, setOpenForgottenPassword] = useState<boolean>(false)
	const [dataInternaute, setDataInternaute] = useState<Record<string, any>>({})

	/**
	 * On submit login
	 * @param login
	 * @param password
	 * @param captcharesponse
	 * @param rememberMe
	 */
	const onSubmit = (
		{
			login,
			password,
			captcharesponse,
			rememberMe
		}: LoginParams) => {
		setLoading(true)
		const rememberMeValue = rememberMe ? rememberMe.length > 0 : false
		return dispatch(loginEE(login, password, captcharesponse, rememberMeValue))
			.then((data) => {
				if (data.user.doubleAuthentification) {
					setDataInternaute({ token: data.user.token, email: data.user.email, doubleAuthent: data.user.doubleAuthentification, profil: data.user.profil })
					setOpenSecureAuthent(true)
				} else {
					ReactGA.event({
						category: 'Connexion',
						action: 'ee.login-success',
						nonInteraction: true
					})

					if (data.user.profil.kpi && !data.user.profil.affiliations && !data.user.profil.prevoyance && !data.user.profil.dispense) {
						window.location.href = UNIVERS_ENTREPRISES_KPI.to(MAIN_PATH_EE.DASHBOARD)
					} else {
						window.location.href = UNIVERS_ENTREPRISES.to(MAIN_PATH_EE.DASHBOARD)
					}
				}

			})
			.catch((e: any) => {
				// login failed
				if (e.data) {
					if (e.data.captcharesponse) {
						return e.data
					}
					ReactGA.event({
						category: 'Connexion',
						action: 'ee.login-failed',
						nonInteraction: true
					})
					return e.data
				}
				if (e.status === 401) {
					ReactGA.event({
						category: 'Connexion',
						action: 'ee.login-failed',
						nonInteraction: true
					})
					// message par defaut
					return { [LOGIN_FIELDS.LOGIN]: intl.formatMessage({ id: 'global.error.login' }) }
				} else {
					ReactGA.event({
						category: 'Connexion',
						action: 'ee.login-error',
						nonInteraction: true
					})
					return { [LOGIN_FIELDS.LOGIN]: intl.formatMessage({ id: 'global.error.login' }) }
				}
			})
			.finally(() => setLoading(false))
	}

	const validateDoubleAuthent = (code: string) => {
		setConnexionIsLoading(true)
		return UserServiceEE.validateDoubleAuthentConnexion({ code: code }, dataInternaute.token)
			.then((response) => {
				addToast(ToastType.SUCCESS, 'doubleAuthent.confirmation.success')

				setOpenSecureAuthent(false)
				LocalStorage.setSession(response.token, UNIVERS_ENTREPRISES.id, dataInternaute.profil.kpi)
				ReactGA.event({
					category: 'Connexion',
					action: 'ee.login-success',
					nonInteraction: true
				})

				if (dataInternaute.profil.kpi && !dataInternaute.profil.affiliations && !dataInternaute.profil.prevoyance && !dataInternaute.profil.dispense) {
					window.location.href = UNIVERS_ENTREPRISES_KPI.to(MAIN_PATH_EE.DASHBOARD)
				} else {
					window.location.href = UNIVERS_ENTREPRISES.to(MAIN_PATH_EE.DASHBOARD)
				}
			})
			.catch((error: any) => {
				if (error?.data) {
					if (error.data.blocage) {
						addToast(ToastType.ERROR, error.data.blocage)
						setDataInternaute({ ...dataInternaute, blocage: error.data.duration })
					} else if (error.data.warning) {
						addToast(ToastType.ERROR, error.data.warning)
					} else {
						addToast(ToastType.ERROR, error.data.code)
					}
				} else {
					addToast(ToastType.ERROR, 'global.error.occurred')
				}
			})
			.finally(() => setConnexionIsLoading(false))
	}

	/**
	 * Check if there is a value, else display required error message
	 * @param value
	 */
	const required = (value: string) => (value ? undefined : intl.formatMessage({ id: 'global.error.required' }))

	return (
		<Stack direction="column" spacing="48px" width={!isMobile ? '360px' : 'unset'} alignItems="center" pt="48px">
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit} role="form" style={{ width: '100%' }}>
						<Stack direction="column" spacing="24px">
							<Stack direction="row" justifyContent="space-between">
								<Typography color="secondary" variant="h2">
									<FormattedMessage id="login.form.titleEE" />
								</Typography>
								<img style={{ width: '80px' }}
								     src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
								     alt="logo" />
							</Stack>
							<TextInput
								maxWidth={isMobile ? '100%' : '360px'}
								size="medium"
								popoverContent={
									<Stack direction="column" spacing={1}>
										<Typography variant="subtitle2" whiteSpace="pre-line">
											<FormattedMessage id="login.form.login.popoverEE1" />
										</Typography>
									</Stack>
								}
								name={LOGIN_FIELDS.LOGIN}
								label={intl.formatMessage({ id: 'login.form.login.labelEE' })}
								helperText={intl.formatMessage({ id: 'login.form.login.infoEE' })}
								validate={required}
								format={trim}
							/>
							<TextInput
								maxWidth={isMobile ? '100%' : '360px'}
								size="medium"
								type="password"
								name={LOGIN_FIELDS.PASSWORD}
								label={intl.formatMessage({ id: 'login.form.password' })}
								validate={required}
								format={trim}
							/>
							<Stack direction="row" justifyContent="space-between" alignItems="center">
								<CheckboxInput
									name={LOGIN_FIELDS.REMEMBER_ME}
									data={[{ value: 'true', label: intl.formatMessage({ id: 'login.form.rememberMe' }) }]}
								/>
								<ButtonLink onClick={() => setOpenForgottenPassword(true)}>
									<FormattedMessage id="login.form.forgotPassword" />
								</ButtonLink>
							</Stack>
							<Box>
								<ButtonConnexion type="submit" onClick={handleSubmit} variant="contained">
									{loading ?
										<CircularProgress size={24} color="inherit" /> :
										<FormattedMessage id="login.button.login" />
									}
								</ButtonConnexion>
							</Box>
						</Stack>
					</form>
				)}
			/>
			<Divider sx={{ borderColor: '#606060', width: '100%' }} />
			<Stack direction="column" spacing="24px">
				<Typography color="secondary" variant="h2">
					<FormattedMessage id="login.firstVisitEE.title" />
				</Typography>
				<Typography variant="body2">
					<FormattedMessage id="login.firstVisitEE.text" />
				</Typography>
			</Stack>
			<ButtonConnexion variant="outlined" onClick={() => setOpenSignup(true)}>
				<FormattedMessage id="login.button.inscription" />
			</ButtonConnexion>
			<SignupFormEE openSignup={openSignup} setOpenSignup={setOpenSignup} />
			<ForgottenPasswordFormEE open={openForgottenPassword} setOpen={setOpenForgottenPassword} />
			<AuthentificationSecureEE
				open={openSecureAuthent}
				setOpen={setOpenSecureAuthent}
				onSubmit={(code) => validateDoubleAuthent(code)}
				currentAuthent={dataInternaute.doubleAuthent}
				currentEmail={dataInternaute.email}
				token={dataInternaute.token}
				blocage={dataInternaute.blocage}
				connexionIsLoading={connexionIsLoading} />
		</Stack>
	)
}

export default LoginFormEE
