import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

type ButtonFileProps = ButtonProps & {
	error?: boolean
}

const ButtonFile = styled(ButtonMUI)<ButtonFileProps>(({ theme, error }) => ({
	'&.MuiButton-root': {
		borderRadius: '8px',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		'&.MuiButton-outlined': {
			color: theme.palette.primary.main,
			border: `2px dashed ${error ? theme.palette.error.main : '#dedede'}`
		}
	},
	'& .MuiButton-startIcon': {
		margin: 0
	}
}))

export default ButtonFile
