import React, {useEffect} from 'react'
import appConst from '../../../../../constant/appConstants'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import {connect} from 'react-redux'
import {alpha, Stack, ThemeProvider, Typography} from '@mui/material'
import {themeEA} from '../../../../constant/univers'
import Box from '@mui/material/Box'
import loginImg from '../../../../../resources/img/login_img.jpg'
import {useBreakpoints} from '../../../../../components/breakpoints/BreakpointsProvider'
import LoginPreaffiliationForm from '../../components/login/LoginPreaffiliationForm'


const ARTICLE_INTRO_PREAFF = 'preaffiliation_login_bienvenue'

const LoginPreafffiliationPage = (
    {
        // @ts-ignore
        getArticleByShortcut,
        // @ts-ignore
        isArticleLoadingPreaff,
        // @ts-ignore
        loginBienvenueArticlePreaff,
        // @ts-ignore
        token
    }
    ) => {

    const {isMobile, isTabletPortrait, width, height} = useBreakpoints()

    useEffect(() => {
        if (!loginBienvenueArticlePreaff) {
            getArticleByShortcut(appConst.cardiboxApi, ARTICLE_INTRO_PREAFF)
        }
    }, [getArticleByShortcut, loginBienvenueArticlePreaff, token])

    return (
        <>
            <ThemeProvider theme={themeEA}>
                <Stack direction="row" justifyContent="flex-end">
                    {!(isMobile || isTabletPortrait) && loginBienvenueArticlePreaff && (
                        <Box flex={1} width="100%" height={height} position="relative">
                            <img
                                style={{
                                    width: '100%',
                                    height: height,
                                    objectFit: 'cover',
                                    zIndex: 0
                                }}
                                src={loginImg}
                                alt="background"/>
                            <Stack
                                direction="column"
                                sx={{position: 'absolute', top: '0', left: '0', width: '100%', height: height, zIndex: 1}}
                                bgcolor={alpha('#e0e4ff', 0.65)}
                                alignItems="center"
                                spacing="80px"
                            >
                                <img
                                    style={{width: '300px', marginTop: '80px'}}
                                    src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
                                    alt="logo"
                                />
                                <Stack direction="column" spacing="48px" px="24px" style={{maxWidth: '480px'}}>
                                    <Typography color="secondary" variant="h1" fontSize="1,5rem">
                                        {loginBienvenueArticlePreaff.fields.title}
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        fontSize="1erm"
                                        dangerouslySetInnerHTML={{__html: loginBienvenueArticlePreaff.fields.body}}
                                    />
                                </Stack>
                            </Stack>
                        </Box>)
                    }
                    <Stack
                        width={!(isMobile || isTabletPortrait) ? '600px' : width}
                        direction="column"
                        alignItems="center"
                        justifyContent="space-around"
                    >
                        <LoginPreaffiliationForm token={token}/>
                    </Stack>

                </Stack>
            </ThemeProvider>
        </>
    )
}

const actions = {
    getArticleByShortcut: cardiboxActions.articles.getByShortcut,
}
const mapStateToProps = () => {
    const params = new URLSearchParams(document.location.search);
    const token = params.get("token")
    return (state: any) => ({
        loginBienvenueArticlePreaff: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_INTRO_PREAFF],
        isArticleLoadingPreaff: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_INTRO_PREAFF],
        token : token,
        loading:state.pages && state.pages.login && state.pages.login.loading
    })
}
export default connect(mapStateToProps, actions)(LoginPreafffiliationPage)
