import appConst from '../../constant/appConstants'
import ApiError from './ApiError'
import {logoutApi, logoutEE} from '../../univers/ui/authentication/services/login/loginActions'
import qs from 'qs'
import serializeFormValues from './serialize'
import {openReadOnlyModal} from '../../ea/ui/readOnlyModal/ReadOnlyActions'
import {openPopin} from '../../ea/ui/notation/popinNotationActions'

let store = null

const qsOptions = {
    skipNulls: true,
    arrayFormat: 'repeat'
}

export function saveStore(createdStore) {
    store = createdStore
}

export const buildParams = params => (
    qs.stringify(params, {
        skipNulls: true,
        arrayFormat: 'repeat'
    })
)

/**
 * Retourne l'URL avec les paramètres en GET.
 *
 * @param url       l'URL, pouvant déjà contenir des paramètres
 * @param params    les paramètres à ajouter
 * @return l'URL contenant les paramètres, fusionnés avec ses propres paramètres
 */
export const buildUrlWithParams = (url, params) => {
    const indexOfQueryString = url.indexOf('?')
    if (indexOfQueryString >= 0) {
        // s'il y a des paramètres dans l'URL d'origine, on les sort et on recommence
        const initialQs = qs.parse(url.substr(indexOfQueryString + 1), qsOptions)
        return buildUrlWithParams(url.substr(0, indexOfQueryString), {
            ...initialQs,
            ...params
        })
    }
    const querystring = qs.stringify(params, qsOptions)
    if (querystring) {
        return `${url}?${querystring}`
    }
    return url
}

const getFilenameFromResponse = (response) => {
    let filename = ''
    const disposition = response.headers.get('Content-Disposition')
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
        }
    }
    return filename
}

class GerepAPI {

    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    get(method, params, token, headers) {
        return this._request(method, params, 'GET', headers, token, null, false)
    }

    post(method, params, token, addTime) {
        return this._request(method, params, 'POST', null, token, null, addTime)
    }

    postMultipart(method, params, token) {
        return this._request(method, params, 'POST', null, token, true, false)
    }

    /**
     *
     * @param url
     * @param params
     * @param token
     * @returns {PromiseLike<T> | Promise<T> | *}
     * si download est true, la navigateur fait télécharger le ficier; sinon, il retourne un lien du fichier
     * @deprecated
     */
    download(url, params, token) {
        return this.getTicket(url, params, token)
            .then((downloadURL) => {
                window.location.href = downloadURL
            })
    }

    /**
     *
     * @param url
     * @param params
     * @param token
     * @param callback
     * @param callback
     * @param callBackError
     * @returns {PromiseLike<T> | Promise<T> | *}
     * si download est true, la navigateur fait télécharger le ficier; sinon, il retourne un lien du fichier
     * @deprecated
     */
    // Code un peu sale pour gere un callback pour aprés le dl d
    downloadLoader(url, params, token, callback, callBackError) {
        return this.getTicket(url, params, token)
            .then((downloadURL) => {
                fetch(downloadURL).then(response => {
                    if (response.headers.get('Content-Disposition') === null) {
                        throw new ApiError(500, null, 'GEREP API Aucun document')
                    } else {
                        let filename = ''
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = filenameRegex.exec(response.headers.get('Content-Disposition'));
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }

                        response.blob().then(blob => {
                            // Create blob link to download
                            const url = window.URL.createObjectURL(
                                new Blob([blob])
                            );

                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute(
                                'download',
                                filename
                            );

                            // Append to html link element page
                            document.body.appendChild(link);

                            // Start download
                            link.click();

                            // Clean up and remove the link
                            link.parentNode.removeChild(link);
                        })
                    }
                }).then(() => {
                    callback()
                }).catch((e) => {
                    console.error(e)
                    callBackError()
                })
            })
    }

    download2(url, params, token) {
        return this.get(url, params, token)
            .then(this.redirectToDownloadFile)
    }

    redirectToDownloadFile = (download) => {
        window.location.href = `${this.apiUrl}/download/${download.uuid}`
    }

    downloadFile = (download) => {
        return fetch(`${this.apiUrl}/download/${download.uuid}`)
            .then(response => response.blob())
    }

    getTicket(url, params, token) {
        return this.get('/ticket', null, token)
            .then((receivedToken) => {
                const queryString = buildParams({
                    ...params,
                    token: receivedToken
                })
                return `${this.apiUrl}${url}?${queryString}`
            })
    }

    async _request(method, params, verb, headers, token, multipart, addtime) {
        if (verb !== 'GET' && store.getState().user && store.getState().user.readOnly) {
            store.dispatch(openReadOnlyModal())
            throw new Error()
        }
        const headerArgs = {}
        if (token) {
            headerArgs[appConst.authorizationHeader || 'Authorization'] = `Bearer ${token}`
        }
        if (!multipart) {
            headerArgs['content-type'] = 'application/json'
        }

        let args = ''
        if (verb === 'GET') {
            let paramsWithTime = null
            const time = +new Date()
            if (!params) {
                paramsWithTime = {timestamp: time}
            } else {
                paramsWithTime = {
                    ...params,
                    timestamp: time
                }
            }
            args = buildParams(paramsWithTime)
            args = args === '' ? '' : (`?${args}`)
        } else if (verb !== 'GET' && addtime != null && addtime) {
            let paramsWithTime = null
            const time = +new Date()
            paramsWithTime = {timestamp: time}
            args = buildParams(paramsWithTime)
            args = args === '' ? '' : (`?${args}`)
        }

        const url = this.apiUrl + method + args
        const body = (verb !== 'GET') ? params && JSON.stringify(params) : undefined

        try {
            const response = await fetch(url, {
                method: verb,
                headers: {
                    'cache-control': 'no-cache, no-store',
                    ...headers,
                    ...headerArgs
                },
                body: multipart ? serializeFormValues(params) : body
            })
            if (!response.ok) {
                if (response.status === 401 && method !== '/login') {
                    if( this.apiUrl === appConst.gerepApi.baseUrlEE){
                        logoutEE('Votre session a expiré')(store.dispatch)
                    } else {
                        logoutApi('Votre session a expiré')(store.dispatch)
                    }
                }

                if (response.status === 410 || response.status === 503) {
                    // maintenance - on recharge pour afficher la bonne page
                    window.location.reload()
                    return
                }

                let result = null
                try {
                    const contentType = response.headers.get('content-type')
                    if (contentType) {
                        if (contentType.indexOf('application/json') !== -1) {
                            result = await response.json()
                        } else if (contentType.indexOf('text/plain') !== -1) {
                            result = await response.text()
                                .then(text => ({
                                    _error: text
                                }))
                        }
                    }
                } catch (e) {
                    // ¯\_(ツ)_/¯
                }
                throw new ApiError(response.status, null, result)
            }

            if (response.status === 204) {
                // no content
                return {}
            }

            if (response.headers.get('X-Affichage-Popin-Notation')) {
                openPopin()(store.dispatch)
            }

            // json
            const contentType = response.headers.get('content-type')
            if (contentType) {
                if (contentType.indexOf('application/json') !== -1) {
                    return await response.json()
                } else if (contentType.indexOf('text/') !== -1) {
                    return await response.text()
                }
            }
            // others
            return response
        } catch (error) {
            console.log(error)
            if (error instanceof ApiError) {
                throw error
            }
            throw new ApiError(500, null, 'GEREP API unreachable')
        }
    }
}

export const configureApi = prefix => new GerepAPI(prefix)

export const GerepAPI_EE = new GerepAPI(appConst.gerepApi.baseUrlEE)

export default new GerepAPI(appConst.gerepApi.baseUrl)

