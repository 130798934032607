import React from 'react'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import demandeUtils from '../../../../utils/demandeUtils'

type DemandsCellProps = {
	demand: any
}

const DemandsCell: React.FC<DemandsCellProps> = ({ demand }) => {
	const intl = useIntl()
	const demandStatusMessage = demandeUtils.demandStatus(demand?.etat, { showDash: true })

	return (
		<Stack direction="column">
			<Stack direction="row" display="flex" justifyContent="space-between">
				<Typography variant="h4" noWrap>
					{demand?.libelle}
				</Typography>
				{demandStatusMessage}
			</Stack>
			{(demand?.beneficiaire || demand?.dateDemande || demand?.etat) &&
				<Stack direction="row" alignItems="center" spacing="4px">
					<Typography variant="subtitle1" noWrap>
						{demand?.dateDemande &&
							<FormattedMessage
								id="dashboard.demands.date"
								values={{
									date: intl.formatDate(demand.dateDemande, { year: 'numeric', day: '2-digit', month: 'short' })
								}}
							/>
						}
						{demand?.beneficiaire &&
							<FormattedMessage
								id="dashboard.demands.beneficiary"
								values={{
									beneficiary: demand.beneficiaire
								}}
							/>
						}
					</Typography>
				</Stack>
			}
		</Stack>
	)
}

export default DemandsCell
