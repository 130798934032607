import React, { useState } from 'react'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../ButtonDefault'

type PopinAuthentKPIProps = {
	terminer: () => void
	textCardibox?: string
}

const FinalPopinAuthentKPI: React.FC<PopinAuthentKPIProps> = ({
	terminer,
	textCardibox
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	return (
		<Stack direction="column" spacing="24px">
			{
				textCardibox && <Typography variant="body2">
					<div dangerouslySetInnerHTML={{ __html: textCardibox }} />
				</Typography>
			}
			<Stack direction="row" spacing="16px" display="flex" justifyContent="center">
				<ButtonDefault
					variant="contained"
					onClick={ () => {
						setIsLoading(true)
						terminer()
					}}>
					{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="doubleAuthent.finalPopin.button.terminer" />}
				</ButtonDefault>
			</Stack>
		</Stack>
	)
}

export default FinalPopinAuthentKPI
