import React from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import BeneficiaryAvatar from '../../../../components/BeneficiaryAvatar'
import InfoField from '../../../../components/InfoField'
import { FormattedMessage, useIntl } from 'react-intl'
import FormatUtils from '../../../../utils/FormatUtils'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

type BeneficiaryCellProps = {
	beneficiary: any
}

const BeneficiaryCell: React.FC<BeneficiaryCellProps> = ({ beneficiary }) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()

	const birthDateMessage = beneficiary?.sexe ? beneficiary.sexe === 'Monsieur' ? 'beneficiary.labels.birthMale' : 'beneficiary.labels.birthFemale' : 'beneficiary.labels.birth'
	const typeAdherentMessage = `enums.typeAdherent.${beneficiary.typAdr}`

	return (
		<Stack direction="column" spacing="16px">
			<Stack direction="row" spacing="8px">
				<BeneficiaryAvatar size="large" firstname={beneficiary.prenom} lastname={beneficiary.nom} type={beneficiary.typAdr} />
				<Stack direction="column">
					<Typography variant="h3">{beneficiary.prenom} {beneficiary.nom}</Typography>
					<Typography variant="subtitle1">
						<FormattedMessage id={typeAdherentMessage} />
					</Typography>
				</Stack>
			</Stack>

			<Stack direction={isMobile || isTabletPortrait ? 'column' : 'row'} spacing="8px">
				<Stack flex={2} direction="column" spacing="4px">
					<InfoField variantValue="body1" label={intl.formatMessage({ id: birthDateMessage, defaultMessage: 'Autres' })} value={beneficiary.dateNaissance} />
					{beneficiary.telephone && <InfoField variantValue="body1" label={intl.formatMessage({ id: 'beneficiary.labels.phone' })} value={beneficiary.telephone} />}
					{beneficiary.email && <InfoField variantValue="body1" label={intl.formatMessage({ id: 'beneficiary.labels.email' })} value={beneficiary.email} />}
				</Stack>

				<Stack flex={3} direction="column" spacing="8px">
					<InfoField
						label={intl.formatMessage({ id: 'beneficiary.labels.nss' })}
						value={
							<Stack direction={isMobile ? 'column' : 'row'} spacing="8px">
								<Typography flex={1} variant="body1">{FormatUtils.formatNiss(beneficiary.matorg)}</Typography>
								{beneficiary.matorg2 && <Typography flex={1} variant="body1">{FormatUtils.formatNiss(beneficiary.matorg2)}</Typography>}
							</Stack>
						}
					/>
					<InfoField
						label={intl.formatMessage({ id: 'beneficiary.labels.regime' })}
						value={
							<Stack direction={isMobile ? 'column' : 'row'} spacing="8px">
								{beneficiary.regime && beneficiary.libRegime && <Typography flex={1} variant="body1">{`${beneficiary.regime} - ${beneficiary.libRegime}`}</Typography>}
								{beneficiary.matorg2 && beneficiary.regime2 && beneficiary.libRegime2 && <Typography flex={1} variant="body1">{`${beneficiary.regime2} - ${beneficiary.libRegime2}`}</Typography>}
							</Stack>
						}
					/>
					<InfoField
						label={intl.formatMessage({ id: 'beneficiary.labels.caisse' })}
						value={
							<Stack direction={isMobile ? 'column' : 'row'} spacing="8px">
								{beneficiary.caisse && beneficiary.libCaisse && <Typography flex={1} variant="body1">{`${beneficiary.caisse} - ${beneficiary.libCaisse}`}</Typography>}
								{beneficiary.matorg2 && beneficiary.libCaisse2 && <Typography flex={1} variant="body1">{beneficiary.libCaisse2}</Typography>}
							</Stack>
						}
					/>
				</Stack>
			</Stack>

			<Divider />
		</Stack>
	)
}

export default BeneficiaryCell
