import React from 'react'
import { Divider, Grid, Stack, Typography } from '@mui/material'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { FormattedMessage } from 'react-intl'
import BeneficiaireContrat from './BeneficiaireContrat'
import { TypeAdherent } from '../../../../constant/TypeAdherent'


const ContratList = (
	{
		// @ts-ignore
		contrats
	}
) => {

	const { isMobile, isTabletPortrait } = useBreakpoints()

	return (
		<Stack direction="column" spacing="24px" pb="24px" pt="24px">
			{contrats && contrats.map((contrat: any, contratIndex: number) => (
				<>
					<Stack key={contratIndex} direction={(isMobile || isTabletPortrait) ? 'column' : 'row'} spacing="32px">
						<Stack flex={3} direction="column" alignItems="start">
							<Typography variant="h3" sx={{ marginBottom: '24px' }}>
								{contrat.garantie.typeGar}
							</Typography>

							<Typography variant="body2">
								{contrat.garantie.libelleGarantie}
							</Typography>
							<Typography variant="body2" sx={{ paddingTop: '8px' }}>
								{`Contrat : ${contrat.garantie.refcie}`}
							</Typography>
						</Stack>
						<Stack flex={5} direction="column" alignItems="start" spacing="24px">
							<Typography flex={1} variant="body2">
								<FormattedMessage id="contrat.beneficaire.title" />
							</Typography>
							<Grid container sx={{ paddingRight: '30px' }} direction={isMobile ? 'column' : 'row'}>
								{contrat.beneficiaires && contrat.beneficiaires
									.filter((b: any) => b.typAdr !== TypeAdherent.ENFANTS)
									.map((beneficiaire: any, beneficiaireIndex: number) => (
										<BeneficiaireContrat
											key={beneficiaireIndex}
											beneficiaire={beneficiaire}
										/>
									))
								}
								{contrat.beneficiaires && contrat.beneficiaires
									.filter((b: any) => b.typAdr === TypeAdherent.ENFANTS)
									.map((beneficiaire: any, beneficiaireIndex: number) => (
										<BeneficiaireContrat
											key={beneficiaireIndex}
											beneficiaire={beneficiaire}
										/>
									))
								}
							</Grid>
						</Stack>
					</Stack>
					<Divider />
				</>
			))}
		</Stack>
	)
}

export default ContratList
