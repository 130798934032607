import { CLOSE_INTERSTITIEL, LOAD_INTERSTITIELS } from './interstitielTypes'

export const closeInterstitiel = (idArticle, closePermanently) => ({
	type: CLOSE_INTERSTITIEL,
	payload: {
		idArticle,
		closePermanently
	}
})

export const loadInterstitiels = (interstitiels) => ({
	type: LOAD_INTERSTITIELS,
	payload: interstitiels
})
