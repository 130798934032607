import React, {Dispatch, SetStateAction} from 'react'
import ButtonLink from '../../../../components/ButtonLink'
import Icon from '@mdi/react'
import {mdiDownload} from '@mdi/js'
import {FormattedMessage} from 'react-intl'
import {Box} from '@mui/material'


type DownloadDispenseAttestationButtonProps = {
    downloadAction: Function
    attestationDownloaded: boolean
    setAttestationDownloaded: Dispatch<SetStateAction<boolean>>
}

const DownloadDispenseAttestationButton: React.FC<DownloadDispenseAttestationButtonProps> = (
    {
        downloadAction,
        attestationDownloaded,
        setAttestationDownloaded
    }
) => {

    const downloadDispenseAttestation = () => {
        return downloadAction().then((fileURL: any) => {
                 const link = document.createElement('a')
                 link.href = fileURL
                 link.target = '_blank'
                 link.click()
             }, (e: any) => {
                 console.error(e)
             })
    }

    return (

        <Box>
            <ButtonLink
                variant="contained"
                startIcon={<Icon size="20px" path={mdiDownload}/>}
                onClick={() => downloadDispenseAttestation()
                    .then(!attestationDownloaded && setAttestationDownloaded(!attestationDownloaded))
                }
                sx={{textAlign: 'center', lineHeight: '18px'}}
            >
                <FormattedMessage id="preaffiliation.dispense.popin.download"/>
            </ButtonLink>
        </Box>


    )
}
export default DownloadDispenseAttestationButton
