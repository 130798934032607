import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Stack, Typography } from '@mui/material'
import { VictoryPie, VictoryLabel } from 'victory'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import FormatUtils from '../../../../utils/FormatUtils'


const ChartCell = ({
	                   // @ts-ignore
	                   data,
	                   // @ts-ignore
	                   activeFilters
                   }) => {

	const intl = useIntl()
	const { isDesktop, isLaptop, isMobile, isTabletPortrait, isWidescreen } = useBreakpoints()
	const isDesktopOrLaptop = isDesktop || isLaptop || isWidescreen

	const chartData = data.data[0].chart.filter((entry: any) => entry.val > 0).map((entry: any) => ({
		x: ' ',
		y: parseFloat(parseFloat(entry.val).toFixed(2)),
		fill: entry.color
	}))

	return (
		<Stack flex={10} direction={isDesktopOrLaptop ? 'row' : 'column'} alignItems={isDesktopOrLaptop ? 'flex-start' : 'center'}>
			<Stack direction="column" sx={isMobile || isTabletPortrait ? { width: '100%' } : { minWidth: '185px' }} alignItems="flex-start">
				<Typography variant="h2">
					<FormattedMessage id="remboursementConsultation.chart.title" />
				</Typography>
				<Typography variant="subtitle2">
					{`Du ${new Date(activeFilters.DATE_DEBUT).toLocaleDateString('fr-FR')} au ${new Date(activeFilters.DATE_FIN).toLocaleDateString('fr-FR')}`}
				</Typography>
			</Stack>
			<Stack direction={(isMobile || isTabletPortrait) ? 'column' : 'row'} sx={{ width: '100%' }}>
				<Stack direction={isMobile || isTabletPortrait ? 'column' : 'row'} alignItems={(isMobile || isTabletPortrait) ? 'center' : 'flex-start'}>
					<svg width={300} height={300}>
						<VictoryPie
							standalone={false}
							width={300} height={300}
							data={chartData}
							style={{
								data: {
									fill: ({ datum }) => datum.fill
								}
							}}
							innerRadius={60}
							labelRadius={100}
						/>
						<VictoryLabel
							textAnchor="middle"
							x={150} y={140}
							style={{
								fontSize: '16px',
								fontWeight: 500,
								color: '#232323',
								fontFamily: 'Montserrat,sans-serif'
							}}
							text={`${intl.formatMessage({ id: 'remboursementConsultation.chart.total' })}`}
						/>
						<VictoryLabel
							textAnchor="middle"
							x={150} y={160}
							style={{
								fontSize: '16px',
								fontWeight: 600,
								color: '#232323',
								fontFamily: 'Montserrat,sans-serif'
							}}
							text={` ${FormatUtils.formatMontant(parseFloat(data.data[0].total))}`}
						/>
					</svg>
				</Stack>
				<Stack direction="row" sx={!(isMobile || isTabletPortrait) ? { paddingTop: '69px', width: '100%' } : { width: '100%' }}>
					<Stack flex={1} direction="column" spacing="18px">
						{data.data[0].chart.map((entry: any, indexChildren: number) => {
							return (
								<Stack key={indexChildren} sx={{ height: '24px' }} justifyContent="center">
									<Stack sx={{ height: '16px', width: '16px', borderRadius: '16px', backgroundColor: entry.color }} />
								</Stack>
							)
						})}
					</Stack>
					<Stack flex={4} direction="column" alignContent="flex-start" spacing="18px">
						{data.data[0].chart.map((entry: any, indexChildren: number) => {
							return (
								<Typography key={indexChildren} variant="body1" sx={{ paddingLeft: '12px', height: '24px', justifyContent: 'center' }}>
									<FormattedMessage id={entry.libelle} />
								</Typography>)
						})}
					</Stack>
					<Stack flex={4} direction="column" alignContent="flex-end" spacing="18px">
						{data.data[0].chart.map((entry: any, indexChildren: number) => {
							return (
								<Typography key={indexChildren} variant="h3" sx={{ height: '24px', justifyContent: 'center' }} alignSelf="flex-end">
									{`${FormatUtils.formatMontant(parseFloat(entry.val))}`}
								</Typography>)
						})}
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	)
}

export default ChartCell
