import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress, Divider, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import TextInput from '../../../../components/form/TextInput'
import { LOGIN_FIELDS } from '../services/loginConstants'
import ButtonLink from '../../../../components/ButtonLink'
import CheckboxInput from '../../../../components/form/CheckboxInput'
import Box from '@mui/material/Box'
import ButtonConnexion from '../../../../components/ButtonConnexion'
import SignupForm from './SignupForm'
import ForgottenPasswordForm from './ForgottenPasswordForm'
import { login as loginAction } from '../services/login/loginActions'
import ReactGA from 'react-ga'
import { useAppDispatch } from '../../../../config/hooksUnivers'
import exampleCard from '../../../../resources/img/exemple_carte_tp.png'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { useLocation } from 'react-router-dom'
import { UNIVERS_PARTICULIERS } from '../../../constant/univers'
import { MAIN_PATH, MAIN_PATH_UNIVERS } from '../../../../constant/path'
import { trim } from '../../../../utils/stringUtils'
import AuthentificationSecure from './AuthentificationSecure'
import LocalStorage from '../../../../business/storage/LocalStorage'
import UserService from '../../../../api/gerep/UserService'
import { ToastContext } from '../../../../components/toast/ToastContext'
import { ToastType } from '../../../../components/toast/toastConstants'

const LoginForm = () => {
    const intl = useIntl()
	const { addToast } = useContext(ToastContext)
	const location = useLocation()
    const dispatch = useAppDispatch()
    const {isMobile} = useBreakpoints()

    const [openSignup, setOpenSignup] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [connexionIsLoading, setConnexionIsLoading] = useState<boolean>(false)
    const [openForgottenPassword, setOpenForgottenPassword] = useState<boolean>(false)
	const [openAuthentificationSecure, setOpenAuthentificationSecure] = useState<boolean>(false)
	const [dataInternaute, setDataInternaute] = useState<Record<string, any>>({})
	const [carteTp, setCarteTp] = useState<boolean>(false)
    const urlParams = new URLSearchParams(window.location.search)

    useEffect(() => {
        if (urlParams.has('openSignup')) {
            const openSignupBool = urlParams.get('openSignup')
            setOpenSignup(openSignupBool === "true")
        }
		if (urlParams.has('carteTp')) {
            setCarteTp(true)
        }
    }, [])

    /**
     * On submit login
     * @param login
     * @param password
     * @param captcharesponse
     * @param rememberMe
     */
    const onSubmit = (
        {
            login,
            password,
            captcharesponse,
            rememberMe
        }: LoginParams) => {
        setLoading(true)
        const rememberMeValue = rememberMe ? rememberMe.length > 0 : false
        return dispatch(loginAction(login, password, captcharesponse, rememberMeValue, carteTp))
	        .then((response) => {
		        if (response.doubleAuthent) {
			        setDataInternaute({ token: response.token, email: response.email, telephone: response.telephone, doubleAuthent: response.doubleAuthent })
			        setOpenAuthentificationSecure(true)
		        } else {
			        ReactGA.event({
				        category: 'Connexion',
				        action: 'login-success',
				        nonInteraction: true
			        })

			        if (location.pathname === MAIN_PATH.LOGIN || location.pathname === MAIN_PATH_UNIVERS.LOGIN_EA) {
				        window.location.href = UNIVERS_PARTICULIERS.to(MAIN_PATH.DASHBOARD)
			        } else {
				        window.location.href = location.pathname + location.search
			        }
		        }
	        })
            .catch((e: any) => {
                // login failed
                if (e.data) {
                    if (e.data.captcharesponse) {
                        return e.data
                    }
                    ReactGA.event({
                        category: 'Connexion',
                        action: 'login-failed',
                        nonInteraction: true
                    })
                    return e.data
                }
                if (e.status === 401) {
                    ReactGA.event({
                        category: 'Connexion',
                        action: 'login-failed',
                        nonInteraction: true
                    })
                    // message par defaut
                    return {[LOGIN_FIELDS.LOGIN]: intl.formatMessage({id: 'global.error.login'})}
                } else if (e.status === 504) {
                    ReactGA.event({
                        category: 'Connexion',
                        action: 'login-error',
                        nonInteraction: true
                    })
                    // message par TimeOut
                    return {[LOGIN_FIELDS.LOGIN]: intl.formatMessage({id: 'global.error.timeOut'})}
                } else {
                    ReactGA.event({
                        category: 'Connexion',
                        action: 'login-error',
                        nonInteraction: true
                    })
                    return {[LOGIN_FIELDS.LOGIN]: intl.formatMessage({id: 'global.error.login'})}
                }
            })
            .finally(() => setLoading(false))
    }

	const validateDoubleAuthent = (code: string) => {
		setConnexionIsLoading(true)
		return UserService.verifierCodeConnexion({ code: code }, dataInternaute.token)
			.then((response) => {
				addToast(ToastType.SUCCESS, 'doubleAuthent.confirmation.success')
				setOpenAuthentificationSecure(false)

				LocalStorage.setSession(response.token, UNIVERS_PARTICULIERS.id, false)
				ReactGA.event({
					category: 'Connexion',
					action: 'login-success',
					nonInteraction: true
				})

				if (location.pathname === MAIN_PATH.LOGIN || location.pathname === MAIN_PATH_UNIVERS.LOGIN_EA) {
					window.location.href = UNIVERS_PARTICULIERS.to(MAIN_PATH.DASHBOARD)
				} else {
					window.location.href = location.pathname + location.search
				}
			})
			.catch((error: any) => {
				if (error?.data) {
					if (error.data.blocage) {
						addToast(ToastType.ERROR, error.data.blocage)
						setDataInternaute({ ...dataInternaute, blocage: error.data.duration })
					} else if (error.data.warning) {
						addToast(ToastType.ERROR, error.data.warning)
					} else {
						addToast(ToastType.ERROR, error.data.code)
					}
				} else {
					addToast(ToastType.ERROR, 'global.error.occurred')
				}
			})
			.finally(() => setConnexionIsLoading(false))
	}

    /**
     * Check if there is a value, else display required error message
     * @param value
     */
    const required = (value: string) => (value ? undefined : intl.formatMessage({id: 'global.error.required'}))

    return (
        <Stack direction="column" spacing="48px" width={!isMobile ? '360px' : 'unset'} alignItems="center" pt="48px">
            <Form
                onSubmit={onSubmit}
                render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit} role="form" style={{width: '100%'}}>
                        <Stack direction="column" spacing="24px">
                            <Stack direction="row" justifyContent="space-between">
                                <Typography color="secondary" variant="h2">
                                    <FormattedMessage id="login.form.title"/>
                                </Typography>
                                <img style={{width: '80px'}}
                                     src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
                                     alt="logo"/>
                            </Stack>
                            <TextInput
                                maxWidth={isMobile ? '100%' : '360px'}
                                size="medium"
                                popoverContent={
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="subtitle2" whiteSpace="pre-line">
                                            <FormattedMessage id="login.form.login.popover1"/>
                                        </Typography>
                                        <Box>
                                            <img style={{width: '100%'}} src={exampleCard} alt="exemple carte"/>
                                        </Box>
                                        <Typography variant="subtitle2" whiteSpace="pre-line">
                                            <FormattedMessage id="login.form.login.popover2"/>
                                        </Typography>
                                    </Stack>
                                }
                                name={LOGIN_FIELDS.LOGIN}
                                label={intl.formatMessage({id: 'login.form.login.label'})}
                                helperText={intl.formatMessage({id: 'login.form.login.info'})}
                                validate={required}
                                format={trim}
                            />
                            <TextInput
                                maxWidth={isMobile ? '100%' : '360px'}
                                size="medium"
                                type="password"
                                name={LOGIN_FIELDS.PASSWORD}
                                label={intl.formatMessage({id: 'login.form.password'})}
                                validate={required}
                                format={trim}
                            />
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <CheckboxInput
                                    name={LOGIN_FIELDS.REMEMBER_ME}
                                    data={[{value: 'true', label: intl.formatMessage({id: 'login.form.rememberMe'})}]}
                                />
                                <ButtonLink onClick={() => setOpenForgottenPassword(true)}>
                                    <FormattedMessage id="login.form.forgotPassword"/>
                                </ButtonLink>
                            </Stack>
                            <Box>
                                <ButtonConnexion type="submit" onClick={handleSubmit} variant="contained">
                                    {loading ?
                                        <CircularProgress size={24} color="inherit"/> :
                                        <FormattedMessage id="login.button.login"/>
                                    }
                                </ButtonConnexion>
                            </Box>
                        </Stack>
                    </form>
                )}
            />
            <Divider sx={{borderColor: '#606060', width: '100%'}}/>
            <Stack direction="column" spacing="24px">
                <Typography color="secondary" variant="h2">
                    <FormattedMessage id="login.firstVisit.title"/>
                </Typography>
                <Typography variant="body2">
                    <FormattedMessage id="login.firstVisit.text"/>
                </Typography>
            </Stack>
            <ButtonConnexion variant="outlined" onClick={() => setOpenSignup(true)}>
                <FormattedMessage id="login.button.activateAccount"/>
            </ButtonConnexion>
            <SignupForm openSignup={openSignup} setOpenSignup={setOpenSignup}/>
            <ForgottenPasswordForm open={openForgottenPassword} setOpen={setOpenForgottenPassword}/>
	        <AuthentificationSecure
		        open={openAuthentificationSecure}
		        setOpen={setOpenAuthentificationSecure}
		        onSubmit={(code) => validateDoubleAuthent(code)}
		        currentAuthent={dataInternaute.doubleAuthent}
		        currentTelephone={dataInternaute.telephone}
		        currentEmail={dataInternaute.email}
		        token={dataInternaute.token}
		        blocage={dataInternaute.blocage}
		        connexionIsLoading={connexionIsLoading} />
        </Stack>
    )
}

export default LoginForm
