import { createSelector } from 'reselect'
import FormatUtils from '../../../utils/FormatUtils';

// TODO repasser sur tout le code pour s'assurer que c'est bien le sélecteur qui est utilisé
export const userSelector = state => state.user

export const dateDebutContratSelector = createSelector([
	userSelector
], (user) => {
	if (user && user.dateDebutContrat) {
		return FormatUtils.toLocalDate(user.dateDebutContrat)
	}
	return null
})
