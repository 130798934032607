import React, { useEffect, useMemo } from 'react'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import appConst from '../../../../constant/appConstants'
import { Divider, Link, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import InfoField from '../../../../components/InfoField'
import { STEP1_CHECKBOX_NAME, STEP2_FORM_NAME, STEP3_FORM_NAME } from '../services/souscriptionConstants'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { positiveOrNaN } from '../../../../utils/numberUtils'
import CheckboxInput from '../../../../components/form/CheckboxInput'
import { downloadGuide } from '../services/souscriptionActions'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

const ARTICLE_3_SHORTCUT = 'intro_etape_3_option'

type GarantieSouscriptionEtape3FormProps = {
	rib: any
	values: any
	garanties: Garantie[]
	errors: any
	onError?: () => void
}

const GarantieSouscriptionEtape3Form: React.FC<GarantieSouscriptionEtape3FormProps> = (
	{
		// @ts-ignore
		getArticleByShortcut,
		// @ts-ignore
		article,
		rib,
		values,
		garanties,
		errors,
		onError,
		// @ts-ignore
		download
	}
) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()

	const prelevementBancaire = useMemo(() => !!rib?.iban || (!!values[STEP2_FORM_NAME.IBAN] && !!values[STEP2_FORM_NAME.RIB] || !!values[STEP2_FORM_NAME.ACCOUNT_HOLDER]), [rib, values])
	const selectedOptions = useMemo(() => values[STEP1_CHECKBOX_NAME] ? values[STEP1_CHECKBOX_NAME].filter((option: any) => option.selected) : [], [values])
	const selectedGaranties = useMemo(() => garanties.filter((garantie: Garantie) => garantie.key === selectedOptions[0].idGarantie[0]), [garanties, selectedOptions])

	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_3_SHORTCUT)
	}, [getArticleByShortcut])

	const getPrixOption = () => {
		if (selectedGaranties[0]?.cumulative) {
			// le prix de l'option varie en fonction du nombre de bénéficiaires
			const selectedBenef = selectedOptions[0].beneficiaires
			if (selectedBenef.length === 0) {
				return positiveOrNaN(selectedGaranties[0].prix)
			}
			return selectedGaranties[0].beneficiaires.filter((benef: any) => selectedBenef.includes(benef.id)).reduce((acc: number, benef: any) => acc + benef.prix, 0)
		}
		// le prix placé sur l'option, qui est normalement le prix le plus bas
		return positiveOrNaN(selectedGaranties[0]?.prix)
	}

	const prix = getPrixOption()

	return (
		<Stack direction="column" spacing="24px">
			{article && (
				<div dangerouslySetInnerHTML={{ __html: article.fields.body }} />
			)}
			<Stack direction="column" spacing="12px">
				<Typography variant="h2">
					<FormattedMessage id="souscription.step3.title" />
				</Typography>
				<Stack direction={isMobile || isTabletPortrait ? 'column' : 'row'} spacing="8px">
					<Stack direction="column" spacing="8px" flex={1}>
						<InfoField
							flexLabel={2}
							label={intl.formatMessage({ id: 'souscription.step3.info.mode.title' })}
							value={prelevementBancaire ? intl.formatMessage({ id: 'souscription.step3.info.mode.prelevement' }) : intl.formatMessage({ id: 'souscription.step3.info.mode.salary' })}
						/>
						{prelevementBancaire && selectedGaranties && selectedGaranties[0]?.fract && selectedGaranties[0]?.echeance && (
							<InfoField
								flexLabel={2}
								label={intl.formatMessage({ id: 'souscription.step3.info.datePrelev' })}
								value={intl.formatMessage({ id: `souscription.datePrelevement.${selectedGaranties[0].fract}` }, { echeance: selectedGaranties[0].echeance })}
							/>
						)}
					</Stack>
					{prelevementBancaire &&
						<Stack direction="column" spacing="8px" flex={1}>
							<InfoField
								flexLabel={2}
								label={intl.formatMessage({ id: 'souscription.step3.info.titulaire' })}
								value={rib?.titulaire || values[STEP2_FORM_NAME.ACCOUNT_HOLDER]}
							/>
							<InfoField
								flexLabel={2}
								label={intl.formatMessage({ id: 'souscription.step3.info.iban' })}
								value={rib?.iban || values[STEP2_FORM_NAME.IBAN]}
							/>
							<InfoField
								flexLabel={2}
								label={intl.formatMessage({ id: 'souscription.step3.info.bank' })}
								value={rib?.banque || values[STEP2_FORM_NAME.BANK]}
							/>
						</Stack>
					}
				</Stack>
			</Stack>
			<Divider />
			{selectedGaranties && selectedGaranties.length > 0 && selectedGaranties.map((option) => (
				<Stack direction="column" spacing="12px" key={option.key}>
					<Typography variant="h2">
						{option.libelle}
					</Typography>
					<Stack direction="column" spacing="8px">
						<InfoField
							flexLabel={2}
							label={intl.formatMessage({ id: 'souscription.step3.info.beneficiaires' })}
							value={
								<Stack direction="column" spacing="8px">
									{option.beneficiaires.map((beneficiaire) => (
										<Typography fontWeight={400} fontSize="0.875rem">
											{`${beneficiaire.nom} ${beneficiaire.prenom}`}
										</Typography>
									))}
								</Stack>
							}
						/>
						{option.dateEffet &&
							<InfoField
								flexLabel={2}
								label={intl.formatMessage({ id: 'souscription.step3.info.dateEffet' })}
								value={new Date(option.dateEffet).toLocaleDateString('fr-FR')}
							/>
						}
						{(prix > 0 || option.libellePrix) && (
							<InfoField
								flexLabel={2}
								label={intl.formatMessage({ id: 'souscription.step3.info.cout' })}
								value={option?.libellePrix ? option.libellePrix : `${prix} €`}
							/>
						)}
					</Stack>
				</Stack>
			))}
			<Divider />
			<CheckboxInput
				name={STEP3_FORM_NAME}
				data={[
					{
						label: intl.formatMessage({ id: 'souscription.step3.info.acceptConditions' }, {
							link: (
								<Link
									onClick={() => {
										let dateEffetDate
										if (selectedGaranties[0]?.dateEffet) {
											dateEffetDate = new Date(selectedGaranties[0]?.dateEffet)
											if (isNaN(dateEffetDate.getTime())) {
												// la date passée en paramètre est invalide
												dateEffetDate = new Date()
											}
										} else {
											// pas de date
											dateEffetDate = new Date()
										}
										download({ numGar: selectedGaranties[0]?.numGar, siret: selectedGaranties[0]?.siret, dateEffet: dateEffetDate.getFullYear() })
											.catch(() => {
												onError && onError()
											})
									}}
									href="#"
								>
									<FormattedMessage id="souscription.step3.info.conditions" />
								</Link>
							)
						}),
						value: 'acceptMandatSepa'
					}
				]}
				error={errors[STEP3_FORM_NAME]}
			/>
		</Stack>
	)
}

const mapStateToProps = (state: any) => {
	return {
		article: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_3_SHORTCUT]
	}
}

const mappedActions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut,
	download: downloadGuide
}

export default compose(
	connect(mapStateToProps, mappedActions))(GarantieSouscriptionEtape3Form)