import React from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {MAIN_PATH, MAIN_PATH_UNIVERS, SECONDARY_PATH} from './constant/path'
import DashboardPage from './ea/ui/dashboard/pages/DashboardPage'
import {userSelector} from './ea/ui/user/userSelectors'
import {compose} from 'redux'
import {connect} from 'react-redux'
import NavMenuProvider from './components/navMenu/NavMenuProvider'
import HelpPage from './ea/ui/help/pages/HelpPage'
import PersonalInfoPage from './ea/ui/personalInfo/pages/PersonalDataPage'
import EcheancierCotisationPage from './ea/ui/echeancier/pages/EcheancierCotisationPage'
import RefreshProvider from './ea/ui/personalInfo/services/refresh/RefreshProvider'
import ContratPage from './ea/ui/contrats/pages/ContratPage'
import ModifySocialSecurityPage from './ea/ui/personalInfo/pages/ModifySocialSecurityPage'
import DeleteBeneficiaryPage from './ea/ui/personalInfo/pages/DeleteBeneficiaryPage'
import BeneficiaryPage from './ea/ui/beneficiary/pages/BeneficiaryPage'
import SuivieDemandePage from './ea/ui/suivieDemande/pages/SuivieDemandePage'
import AddBeneficiaryPage from './ea/ui/beneficiary/pages/AddBeneficiaryPage'
import PaymentCardPage from './ea/ui/paymentCard/pages/PaymentCardPage'
import DemandRefundPage from './ea/ui/refund/pages/DemandRefundPage'
import MaintenirEnfantMajeurPage from './ea/ui/maintenirEnfant/pages/MaintenirEnfantMajeurPage'
import SendContractPage from './ea/ui/sendContract/pages/SendContractPage'
import DemanderHopitalPage from './ea/ui/demanderHopital/pages/HospitalizationPage'
import RemboursementListPage from './ea/ui/refundList/pages/RemboursementListPage'
import PortabilitePage from './ea/ui/portabilite/pages/PortabilitePage'
import ServicesSantesPage from './ea/ui/serviceSante/pages/ServicesSantesPage'
import SimulationPage from './ea/ui/simulation/pages/SimulationPage'
import InterstitielProvider from './components/interstitiel/InterstitielProvider'
import WithReadOnlyModalProvider from './ea/ui/readOnlyModal/withReadOnlyModal'
import NotFoundPage from './univers/ui/errorPage/NotFoundPage'
import PoleEmploiPage from './ea/ui/poleEmploi/pages/PoleEmploiPage'
import NotationProvider from './ea/ui/notation/NotationProvider'
import InternalErrorPage from './univers/ui/errorPage/InternalErrorPage'
import GarantieSouscriptionPage from './ea/ui/subscription/pages/GarantieSouscriptionPage'
import ActeNaissanceForm from './ea/ui/acteNaissance/pages/ActeNaissanceForm'
import UnauthorizedErrorPage from './univers/ui/errorPage/UnauthorizedErrorPage'
import ForceResetPage from './ea/ui/forceReset/ForceResetPage'
import DoubleAuthentificationPage from './ea/ui/doubleAuthent/DoubleAuthentificationPage'
import CarteTpPage from './ea/ui/paymentCard/viewCarteTP/CarteTpPage'

const App = (
    {
        // @ts-ignore
        user
    }) => {
    if (user && (!user.passwordValid || user.needResetPassword)) {
        return (
            <Switch>
                <Route path={MAIN_PATH.FORCE_RESET_PASSWORD} component={ForceResetPage} />
                <Redirect path="*" to={MAIN_PATH.FORCE_RESET_PASSWORD} />
            </Switch>)
    } else if (user && (!user.doubleAuthent && user.doubleAuthentObligatoire)) {
        return (
            <Switch>
                <Route path={MAIN_PATH.ACTIVATE_DOUBLE_AUTHENT} component={DoubleAuthentificationPage} />
                <Redirect path="*" to={MAIN_PATH.ACTIVATE_DOUBLE_AUTHENT} />
            </Switch>)
    } else if (user && user.viewCarteTP) {
        return (
            <Switch>
                <Route path={MAIN_PATH.CARTE_TP} component={CarteTpPage} />
                <Redirect path="*" to={MAIN_PATH.CARTE_TP} />
            </Switch>)
    } else if (user) {
        return (
            <NavMenuProvider isEntreprise={false}>
                <RefreshProvider>
                    <WithReadOnlyModalProvider>
                        <InterstitielProvider>
                            <NotationProvider>
                                <Switch>
                                    <Route path={MAIN_PATH.DASHBOARD} component={DashboardPage}/>
                                    <Route path={MAIN_PATH.HELP} component={HelpPage}/>
                                    <Route path={SECONDARY_PATH.ACCOUNT_PERSONAL_DATA} component={PersonalInfoPage}/>
                                    <Route path={SECONDARY_PATH.ACCOUNT_DEADLINE} component={EcheancierCotisationPage}/>
                                    <Route path={SECONDARY_PATH.ACCOUNT_PERSONAL_DATA} component={PersonalInfoPage}/>
                                    <Route path={SECONDARY_PATH.ACCOUNT_BENEFICIARY} component={BeneficiaryPage}/>
                                    <Route path={SECONDARY_PATH.CONTACT_MODIFY_SOCIAL_SECURITY}
                                           component={ModifySocialSecurityPage}/>
                                    <Route path={SECONDARY_PATH.CONTACT_DELETE_BENEFICIARY}
                                           component={DeleteBeneficiaryPage}/>
                                    <Route path={SECONDARY_PATH.ACCOUNT_BENEFICIARY} component={BeneficiaryPage}/>
                                    <Route path={SECONDARY_PATH.ACCOUNT_CONTRACT} component={ContratPage}/>
                                    <Route path={SECONDARY_PATH.SERVICES_HISTORIQUE_DEMANDE}
                                           component={SuivieDemandePage}/>
                                    <Route path={SECONDARY_PATH.CONTACT_ADD_BENEFICIARY}
                                           component={AddBeneficiaryPage}/>
                                    <Route path={SECONDARY_PATH.ACCOUNT_CARD} component={PaymentCardPage}/>
                                    <Route path={SECONDARY_PATH.REFUND_LIST} component={RemboursementListPage} exact/>
                                    <Route path={SECONDARY_PATH.REFUND_DEMAND} component={DemandRefundPage}/>
                                    <Route path={SECONDARY_PATH.SERVICES_SEND_QUOTE} component={SendContractPage}/>
                                    <Route path={SECONDARY_PATH.SERVICES_HOSTPITALIZATION}
                                           component={DemanderHopitalPage}/>
                                    <Route path={SECONDARY_PATH.SERVICES_ADULT_CHILDREN}
                                           component={MaintenirEnfantMajeurPage}/>
                                    <Route path={SECONDARY_PATH.SERVICES_EXTEND_COVER} component={PortabilitePage}/>
                                    <Route path={SECONDARY_PATH.SERVICES_TELECONSULTATION}
                                           component={ServicesSantesPage}/>
                                    <Route path={SECONDARY_PATH.SERVICES_SIMULATION} component={SimulationPage}/>
                                    <Route path={SECONDARY_PATH.CONTACT_MISSING_FILE} component={PoleEmploiPage}/>
                                    <Route path={SECONDARY_PATH.CONTACT_POLE_EMPLOI} component={PoleEmploiPage}/>
                                    <Route path={SECONDARY_PATH.CONTACT_REFUND_DOCUMENT} component={PoleEmploiPage}/>
                                    <Route path={SECONDARY_PATH.CONTACT_MISSING_FILE_GLOBAL}
                                           component={PoleEmploiPage}/>
                                    <Route path={SECONDARY_PATH.ACCOUNT_SUBSCRIPTION}
                                           component={GarantieSouscriptionPage}/>
                                    <Route path={SECONDARY_PATH.ACCOUNT_BIRTH_CERTIFICATE}
                                           component={ActeNaissanceForm}/>
                                    <Route path={MAIN_PATH_UNIVERS.NOT_FOUND} component={NotFoundPage}/>
                                    <Route path={MAIN_PATH_UNIVERS.NOT_AUTHORIZED} component={UnauthorizedErrorPage}/>
                                    <Route path={MAIN_PATH_UNIVERS.INTERNAL_ERROR} component={InternalErrorPage}/>
                                    <Redirect path={MAIN_PATH.DEFAULT} exact to={MAIN_PATH.DASHBOARD}/>
                                    <Route path="*" component={NotFoundPage}/>
                                </Switch>
                            </NotationProvider>
                        </InterstitielProvider>
                    </WithReadOnlyModalProvider>
                </RefreshProvider>
            </NavMenuProvider>
        )
    }

    return <Redirect to={MAIN_PATH.LOGIN}/>
}

const mapStateToProps = (state: any) =>
    ({
        user: userSelector(state)
    })

export default compose(
    connect(mapStateToProps, {})
)(App)
