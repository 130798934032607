import React, { useEffect } from 'react'
import PageLayout from '../../../../components/PageLayout'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import { connect } from 'react-redux'
import appConst from '../../../../constant/appConstants'
import { useIntl } from 'react-intl'
import { Stack, Typography } from '@mui/material'
import Loader from '../../../../components/Loader'
import AddBeneficiaryForm from '../components/AddBeneficiaryForm'

const ARTICLE_NAME = 'demande_ajout_beneficiaire'

const AddBeneficiaryPage = (
	{
		// @ts-ignore
		introduction,
		// @ts-ignore
		getArticleByShortcut
	}
) => {
	const intl = useIntl()

	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_NAME)
	}, [])

	if (!introduction) {
		return <Loader />
	}

	return (
		<PageLayout title={intl.formatMessage({ id: 'beneficiary.addBeneficiary.title' })}>
			<Stack direction="column" spacing="24px">
				<Typography variant="body2" dangerouslySetInnerHTML={{ __html: introduction.fields.body }} />
				<AddBeneficiaryForm />
			</Stack>
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	introduction: cardiboxSelectors.articles.getByShortcut(state.cardibox).demande_ajout_beneficiaire
})

const actions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}

export default connect(
	mapStateToProps,
	actions
)(AddBeneficiaryPage)