import React, {useContext, useState} from 'react'
import {Form} from 'react-final-form'
import {Box, CircularProgress, Stack, Typography} from '@mui/material'
import RadioInput from '../../../../components/form/RadioInput'
import {PORTABILITE_FIELDS} from '../services/portabiliteConstants'
import {FormattedMessage, useIntl} from 'react-intl'
import DatePickerInput from '../../../../components/form/DatePickerInput'
import FileInput from '../../../../components/fileInput/FileInput'
import ButtonDefault from '../../../../components/ButtonDefault'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import ContactService from '../../../../api/gerep/ContactService'
import LocalStorage from '../../../../business/storage/LocalStorage'
import {ToastContext} from '../../../../components/toast/ToastContext'
import {ToastType} from '../../../../components/toast/toastConstants'
import {isValid} from 'date-fns'

const PORTABILITE_CHOICE = [
    {
        value: 'oui',
        label: 'Oui'
    },
    {
        value: 'non',
        label: 'Non'
    }
]

const PortabiliteForm = () => {
    const intl = useIntl()
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const {addToast} = useContext(ToastContext)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

    const onSubmit = (values: any) => {
        const {
            couvertureANI,
            dateDebut,
            files
        } = values

        const filesUploaded = (
            couvertureANI ? files.map((file: any, index: number) => ({
                key: `file${index}`,
                value: file
            })) : []
        )

        const body = [{
            key: 'prolongation',
            value: {
                couvertureANI: couvertureANI === 'oui',
                dateDebut: new Date(dateDebut).toLocaleDateString('fr-FR')
            }
        }, ...filesUploaded]

        setIsLoading(true)
        return ContactService.prolongationCouvertureANI(LocalStorage.getToken(), body)
            .then(() => {
                setOpenConfirmation(true)
                addToast(ToastType.SUCCESS, 'global.success')
            })
            .catch((e) => {
                const error = 'Echec de la demande de prolongation de votre couverture ANI'
                if (e?.data?._error) {
                    addToast(ToastType.ERROR, e.data._error)
                } else if (e?.data) {
                    return e.data
                } else {
                    addToast(ToastType.ERROR, '', error)
                }
            })
            .finally(() => setIsLoading(false))

    }

    return (
        <Form
            onSubmit={onSubmit}
            validate={(values: any) => {
                const errors: any = {}
                if (!values[PORTABILITE_FIELDS.COVER]) {
                    errors[PORTABILITE_FIELDS.COVER] = intl.formatMessage({id: 'global.error.required'})
                }

                if (!values[PORTABILITE_FIELDS.DATE_DEBUT]) {
                    errors[PORTABILITE_FIELDS.DATE_DEBUT] = intl.formatMessage({id: 'global.error.required'})
                } else if (!isValid(values[PORTABILITE_FIELDS.DATE_DEBUT])) {
                    errors[PORTABILITE_FIELDS.DATE_DEBUT] = intl.formatMessage({id: 'global.error.invalidDate'})
                }

                if (!values[PORTABILITE_FIELDS.FILES] || values[PORTABILITE_FIELDS.FILES]?.length === 0) {
                    errors[PORTABILITE_FIELDS.FILES] = intl.formatMessage({id: 'global.error.required'})
                }

                return errors
            }}
            render={({handleSubmit, values, form: {reset, change}, pristine, errors, touched}) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Stack direction="column" spacing="24px">
                            <RadioInput
                                name={PORTABILITE_FIELDS.COVER}
                                error={touched && touched[PORTABILITE_FIELDS.COVER] && errors && errors[PORTABILITE_FIELDS.COVER]}
                                data={PORTABILITE_CHOICE}
                                direction="row"
                                label={intl.formatMessage({id: 'portabilite.input.already'})}
                                onChange={() => {
                                    values[PORTABILITE_FIELDS.DATE_DEBUT] && change(PORTABILITE_FIELDS.DATE_DEBUT, undefined)
                                    values[PORTABILITE_FIELDS.FILES] && change(PORTABILITE_FIELDS.FILES, undefined)
                                }}
                            />
                            {values[PORTABILITE_FIELDS.COVER] === 'oui' && (
                                <Stack direction="column" spacing="24px">
                                    <Stack direction="column" spacing="12px">
                                        <Typography variant="body2">
                                            <FormattedMessage id="portabilite.input.lastDay.description"/>
                                        </Typography>
                                        <DatePickerInput
                                            maxWidth="unset"
                                            name={PORTABILITE_FIELDS.DATE_DEBUT}
                                            label={intl.formatMessage({id: 'portabilite.input.lastDay.label'})}
                                        />
                                    </Stack>

                                    <FileInput
                                        name={PORTABILITE_FIELDS.FILES}
                                        label={
                                            <Stack direction="column" spacing="12px">
                                                <Typography variant="body2">
                                                    <FormattedMessage id="portabilite.input.file.label"/>
                                                </Typography>
                                                <Typography variant="body2" color="secondary">
                                                    <FormattedMessage id="portabilite.input.file.warning"/>
                                                </Typography>
                                            </Stack>
                                        }
                                        title={intl.formatMessage({id: 'portabilite.input.file.title'})}
                                    />
                                </Stack>
                            )}

                            {values[PORTABILITE_FIELDS.COVER] === 'non' && (
                                <Stack direction="column" spacing="24px">
                                    <Stack direction="column" spacing="12px">
                                        <Typography variant="body2">
                                            <FormattedMessage id="portabilite.input.endDay.description"/>
                                        </Typography>
                                        <Typography variant="body2">
                                            <FormattedMessage id="portabilite.input.endDay.description2"/>
                                        </Typography>
                                        <DatePickerInput
                                            maxWidth="unset"
                                            name={PORTABILITE_FIELDS.DATE_DEBUT}
                                            label={intl.formatMessage({id: 'portabilite.input.endDay.label'})}
                                        />
                                    </Stack>

                                    <FileInput
                                        name={PORTABILITE_FIELDS.FILES}
                                        label={intl.formatMessage({id: 'portabilite.input.filePoleEmploi.label'})}
                                        title={intl.formatMessage({id: 'portabilite.input.filePoleEmploi.title'})}
                                        error={errors && errors[PORTABILITE_FIELDS.FILES]}
                                    />
                                </Stack>
                            )}

                            {values[PORTABILITE_FIELDS.COVER] && (
                                <Typography variant="body2">
                                    <FormattedMessage id="portabilite.info"/>
                                </Typography>
                            )}

                            <Stack alignItems="center">
                                <ButtonDefault
                                    variant="contained"
                                    onClick={handleSubmit}
                                    type="submit"
                                    disabled={pristine}
                                >
                                    {isLoading ? <CircularProgress size={24} color="inherit"/> :
                                        <FormattedMessage id="global.button.validate"/>}
                                </ButtonDefault>
                                <Box display="flex" width="100%" flex={1}
                                     alignSelf={isMobile || isTabletPortrait ? 'unset' : 'baseline'}>
                                    <Typography sx={{
                                        position: isMobile || isTabletPortrait ? 'unset' : 'relative',
                                        bottom: '32px',
                                        left: 0,
                                        height: '32px',
                                        display: 'flex',
                                        alignItems: 'flex-end'
                                    }} variant="body2" color="#7a7a7a">
                                        <FormattedMessage id="global.mandatory"/>
                                    </Typography>
                                </Box>
                            </Stack>

                            <ConfirmationPopin
                                title={intl.formatMessage({id: 'portabilite.title'})}
                                open={openConfirmation}
                                setOpen={setOpenConfirmation}
                                resetForm={reset}
                            />
                        </Stack>
                    </form>
                )
            }}
        />
    )
}

export default PortabiliteForm
