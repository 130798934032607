import React, { useState } from 'react'
import { Box, Collapse, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import Icon from '@mdi/react'
import { mdiMinusBox, mdiPlusBox } from '@mdi/js'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { simulateurConsts } from '../services/simulatorConstants'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

type SimulationChartProps = {
	depense: string
	hypotheses: string
	rembSs: number
	resteACharge: number
	rembGerep: number
	sousTypeActe: string
}

const SimulationChart: React.FC<SimulationChartProps> = (
	{
		depense,
		hypotheses,
		rembSs,
		resteACharge,
		rembGerep,
		sousTypeActe
	}
) => {
	const intl = useIntl()
	const theme = useTheme()
	const { isMobile } = useBreakpoints()

	const [openInfo, setOpenInfo] = useState<boolean>(true)

	const getPercentage = (value: number) => {
		const percent = (value / parseFloat(depense)) * 100
		return parseFloat(percent.toFixed(2))
	}

	const getOptions = () => {
		return {
			chart: {
				height: isMobile ? 300 : 400,
				type: 'pie',
				backgroundColor: null,
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false
			},
			title: {
				text: ''
			},
			tooltip: {
				enabled: false
			},
			plotOptions: {
				pie: {
					allowPointSelect: false,
					cursor: 'pointer',
					dataLabels: {
						enabled: !isMobile,
						style: {
							fontSize: '15px',
							fontWeight: 'normal',
							fontFamily: 'Montserrat'
						}
						// format: '<b>{point.name}</b> :<br/>{point.y}'
					}
					// states: {
					// 	hover: {
					// 		enabled: false
					// 	},
					// 	select: {
					// 		enabled: false
					// 	}
					// }
				}
			},
			series: [{
				innerSize: '30%',
				name: 'Brands',
				colorByPoint: true,
				dataLabels: {
					align: 'center',
					// formatter() {
					// 	return this.y > 5 ? this.point.name : null
					// },
					color: '#000'
				},
				borderWidth: 10,
				startAngle: -70,
				data: [{
					name: intl.formatMessage({ id: 'simulation.result.label.socialSecurity' }, { amount: intl.formatNumber(rembSs, { minimumFractionDigits: 2 }) }).replace('.', ','),
					y: rembSs,
					color: simulateurConsts.COLOR_REMB_SS
				}, {
					name: intl.formatMessage({ id: 'simulation.result.label.gerep' }, { amount: intl.formatNumber(rembGerep, { minimumFractionDigits: 2 }) }).replace('.', ','),
					y: rembGerep,
					color: simulateurConsts.COLOR_REMB_GEREP
				}, {
					name: intl.formatMessage({ id: 'simulation.result.label.rest' }, { amount: intl.formatNumber(resteACharge, { minimumFractionDigits: 2 }) }).replace('.', ','),
					y: resteACharge,
					color: simulateurConsts.COLOR_RAC
				}].filter(serie => serie.y > 0)
			}],
			credits: {
				enabled: false
			},
			exporting: {
				enabled: false
			}
		}
	}

	const CHART_DATA = [
		{
			x: ' ',
			label: (resteACharge !== 0 && !isMobile && intl.formatMessage({ id: 'simulation.result.label.rest' }, { amount: resteACharge })) || undefined,
			legend: intl.formatMessage({ id: 'simulation.result.label.rest' }, { amount: resteACharge.toFixed(2).replace('.', ',') }).toUpperCase(),
			y: getPercentage(resteACharge),
			fill: simulateurConsts.COLOR_RAC,
			total: resteACharge
		},
		{
			x: ' ',
			label: (rembSs !== 0 && !isMobile && intl.formatMessage({ id: 'simulation.result.label.socialSecurity' }, { amount: rembSs })) || undefined,
			legend: intl.formatMessage({ id: 'simulation.result.label.socialSecurity' }, { amount: rembSs.toFixed(2).replace('.', ',') }),
			y: getPercentage(rembSs),
			fill: simulateurConsts.COLOR_REMB_SS,
			total: rembSs
		},
		{
			x: ' ',
			label: (rembGerep !== 0 && !isMobile && intl.formatMessage({ id: 'simulation.result.label.gerep' }, { amount: rembGerep })) || undefined,
			legend: intl.formatMessage({ id: 'simulation.result.label.gerep' }, { amount: rembGerep.toFixed(2).replace('.', ',') }),
			y: getPercentage(rembGerep),
			fill: simulateurConsts.COLOR_REMB_GEREP,
			total: rembGerep
		}
	]

	return (
		<Stack direction="column" spacing="12px" justifyContent="center">
			<Typography textAlign="center" variant="h2">
				{intl.formatMessage({ id: 'simulation.result.title' }).toUpperCase()}
			</Typography>
			<Typography textAlign="center" variant="body1">
				<FormattedMessage id="simulation.result.cost" />
			</Typography>
			<Typography textAlign="center" variant="h3">
				<FormattedMessage id="simulation.result.amount" values={{ amount: parseFloat(depense).toFixed(2).replace('.', ',') }} />
			</Typography>
			<Typography textAlign="center" variant="body1">
				{sousTypeActe}
			</Typography>
			<Stack direction="column" width="100%" alignItems="center">
				<HighchartsReact highcharts={Highcharts} options={getOptions()} />
			</Stack>
			<Stack direction="column" width="100%" alignItems="center">
				<Stack direction="column" spacing="16px">
					{CHART_DATA.map((data, index) => (
						<Stack key={index} direction="row" alignItems="center" spacing="12px">
							<Box sx={{ height: '16px', width: '16px', borderRadius: '16px', backgroundColor: data.fill }} />
							<Typography variant="h3">
								{data.legend}
							</Typography>
						</Stack>
					))}
				</Stack>
			</Stack>
			<ListItemButton style={{ paddingLeft: 0, paddingBottom: 0 }} onClick={() => setOpenInfo(prevState => !prevState)}>
				<ListItemIcon>
					<Icon path={openInfo ? mdiMinusBox : mdiPlusBox} size="24px" color={theme.palette.primary.main} />
				</ListItemIcon>
				<ListItemText primary={intl.formatMessage({ id: 'simulation.result.calcul' })} />
			</ListItemButton>
			<Collapse in={openInfo}>
				<Typography variant="body2">
					<div dangerouslySetInnerHTML={{ __html: hypotheses }} />
				</Typography>
			</Collapse>
		</Stack>
	)
}

export default SimulationChart