import * as React from 'react'
import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const AppBarCardButton = styled(ButtonMUI)<ButtonProps>(({ theme }) => ({
	'&.MuiButton-root': {
		borderRadius: 24,
		height: 20,
		fontSize: '0.8125rem',
		fontWeight: 500,
		color: theme.palette.primary.main,
		border: '1px solid ' + theme.palette.primary.main
	}
}))

export default AppBarCardButton