import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Form} from 'react-final-form'
import Popup from '../../../../components/Popup'
import {Box, CircularProgress, Stack, Typography} from '@mui/material'
import {SIGNUP_FIELDS} from '../services/loginConstants'
import TextInput from '../../../../components/form/TextInput'
import CaptchaInput from '../../../../components/form/CaptchaInput'
import {changePasswordEE as changePasswordAction} from '../services/forgottenPassword/forgotPasswordActions'
import {useAppDispatch} from '../../../../config/hooksUnivers'
import ReCAPTCHA from 'react-google-recaptcha'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import {compose} from 'redux'
import {connect} from 'react-redux'
import appConst from '../../../../constant/appConstants'
import ButtonDefault from '../../../../components/ButtonDefault'
import {FormApi} from 'final-form'

type ForgottenPasswordFormProps = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}

const ARTICLE = 'ee_forgot_password'
const CONFIRMATION_ARTICLE = 'forgot_password_confirmation'

const ForgottenPasswordFormEE: React.FC<ForgottenPasswordFormProps> = (
    {
        open,
        setOpen,
        // @ts-ignore
        getArticleByShortcut,
        // @ts-ignore
        forgotPasswordArticle,
        // @ts-ignore
        forgotPasswordConfirmationArticle
    }) => {
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const recaptchaRef = React.createRef<ReCAPTCHA>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

    /**
     * Submit function
     * @param values
     */
    const onSubmit = (values: ChangePasswordParams, form: FormApi<ChangePasswordParams, Partial<Record<string, any>>>) => {
        setIsLoading(true)
        dispatch(changePasswordAction(values.mailAdherent, values.captcharesponse))
            .finally(() => {
                setOpen(false)
                setOpenConfirmation(true)
                setIsLoading(false)
                form.reset()
            })
    }
    const required = (value: any) => (value ? undefined : intl.formatMessage({id: 'global.error.required'}))

    useEffect(() => {
        getArticleByShortcut(appConst.cardiboxApi, ARTICLE)
        getArticleByShortcut(appConst.cardiboxApi, CONFIRMATION_ARTICLE)
    }, [getArticleByShortcut])

    return (
        <>
            <Form
                onSubmit={onSubmit}
                validate={(values) => {
                    const errors: Record<string, string> = {}
                    if (values.mailAdherent && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mailAdherent)) {
                        errors.mailAdherent = intl.formatMessage({id: 'global.error.invalidEmail'})
                    }
                    if (!values[SIGNUP_FIELDS.CAPTCHA_RESPONSE]) {
                        errors[SIGNUP_FIELDS.CAPTCHA_RESPONSE] = intl.formatMessage({id: 'global.error.required'})
                    }

                    return errors
                }}
                render={({handleSubmit}) => {
                    return (
                        <>
                            <Popup
                                open={open}
                                setOpen={setOpen}
                                title={forgotPasswordArticle && forgotPasswordArticle.fields && forgotPasswordArticle.fields.title}
                            >
                                <form onSubmit={handleSubmit}>
                                    <Stack direction="column" spacing="24px">
                                        <Typography variant="body2">
                                            <div
                                                dangerouslySetInnerHTML={{__html: (forgotPasswordArticle && forgotPasswordArticle.fields.body)}}
                                            />
                                        </Typography>
                                        <TextInput
                                            name={SIGNUP_FIELDS.MEMBER_EMAIL}
                                            type="email"
                                            label={intl.formatMessage({id: 'login.form.signup.email.label'})}
                                            placeholder={intl.formatMessage({id: 'login.form.signup.email.placeholder'})}
                                            validate={required}
                                            maxWidth="unset"
                                        />
                                        <Stack direction="column" spacing={3} maxWidth="700px" bgcolor="#e0e4ff"
                                               p="16px" borderRadius="5px" alignItems="center">
                                            <Box>
                                                <CaptchaInput name={SIGNUP_FIELDS.CAPTCHA_RESPONSE}
                                                              recaptchaRef={recaptchaRef}/>
                                            </Box>
                                        </Stack>

                                        <Box display="flex" justifyContent="center">
                                            <ButtonDefault variant="contained" type="submit" onClick={handleSubmit}>
                                                {isLoading ? <CircularProgress size={24} color="inherit"/> :
                                                    <FormattedMessage id="login.button.resetPassword"/>}
                                            </ButtonDefault>
                                        </Box>
                                    </Stack>
                                </form>
                            </Popup>
                        </>
                    )
                }}
            />
            <Popup
                open={openConfirmation}
                setOpen={setOpenConfirmation}
                title={forgotPasswordConfirmationArticle && forgotPasswordConfirmationArticle.fields && forgotPasswordConfirmationArticle.fields.title}
            >
                <Stack direction="column" spacing="24px">
                    <Box
                        dangerouslySetInnerHTML={{__html: (forgotPasswordConfirmationArticle && forgotPasswordConfirmationArticle.fields && forgotPasswordConfirmationArticle.fields.body)}}/>
                </Stack>
                <Box display="flex" flex={1} justifyContent="center">
                    <ButtonDefault variant="contained" onClick={() => setOpenConfirmation(false)}>
                        <FormattedMessage id="global.button.ok"/>
                    </ButtonDefault>
                </Box>
            </Popup>
        </>
    )
}

const actions = {
    getArticleByShortcut: cardiboxActions.articles.getByShortcut
}

const mapStateToProps = (state: any) => ({
    forgotPasswordArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE],
    forgotPasswordConfirmationArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[CONFIRMATION_ARTICLE]
})

export default compose(
    connect(mapStateToProps, actions)
)(ForgottenPasswordFormEE)
