import React, { useContext, useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import * as affiliationActions from '../../../webservices/affiliation/affiliationActions'
import * as ribCotisationActions from '../../../webservices/rib/cotisation/ribCotisationActions'
import { getAffiliationAssurePrincipal, getIndividuListForRefund, getPorteursRib, isAffiliationLoading } from '../../../webservices/affiliation/affiliationSelector'
import Loader from '../../../../components/Loader'
import PersonalInfo from '../components/personalInfo/PersonalInfo'
import { getLoading, getRibList } from '../../../webservices/rib/ribSelector'
import BankAccountInfo from '../components/bankingInfo/BankAccountInfo'
import { profileSelector, userSelector } from '../../user/userSelectors'
import { getRib } from '../../../webservices/rib/cotisation/ribCotisationSelector'
import ConnexionInfo from '../components/connexionInfo/ConnexionInfo'
import { RefreshContext } from '../services/refresh/RefreshContext'
import PageLayout from '../../../../components/PageLayout'
import * as echeancierActions from '../../../webservices/echeancier/echeancierActions'
import { getEcheancier, isEcheancierLoading } from '../../../webservices/echeancier/echeancierSelector'

const PersonalDataPage = (
	{
		// @ts-ignore
		assurePrincipal,
		// @ts-ignore
		getAffiliation,
		// @ts-ignore
		affiliationLoading,
		// @ts-ignore
		getRibCotisation,
		// @ts-ignore
		ribLoading,
		// @ts-ignore
		ribs,
		// @ts-ignore
		rib,
		// @ts-ignore
		user,
		// @ts-ignore
		individuList,
		// @ts-ignore
		profile,
		// @ts-ignore
		echeancier,
		// @ts-ignore
		loadEcheancier,
		// @ts-ignore
		isEcheancierLoading
	}
) => {
	const intl = useIntl()
	const { refreshing } = useContext(RefreshContext)
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		setLoading(true)
		getAffiliation()
		getRibCotisation()
		loadEcheancier()
		setLoading(false)
	}, [refreshing])

	if (affiliationLoading || loading || ribLoading || refreshing || isEcheancierLoading) {
		return <Loader />
	}

	return (
		<PageLayout title={intl.formatMessage({ id: 'personalInfo.title' })}>
			<Stack direction="column" spacing="24px">
				<PersonalInfo assurePrincipal={assurePrincipal} doubleAuthent={user.doubleAuthent} />
				<BankAccountInfo profile={profile} ribs={ribs} rib={rib} individuList={individuList} assurePrincipal={assurePrincipal} echeancier={echeancier} />
				<ConnexionInfo user={user} />
			</Stack>
		</PageLayout>
	)
}


const mapStateToProps = (state: any) => ({
	assurePrincipal: getAffiliationAssurePrincipal(state),
	affiliationLoading: isAffiliationLoading(state),
	isEcheancierLoading: isEcheancierLoading(state),
	ribLoading: getLoading(state),
	ribs: getRibList(state),
	rib: getRib(state),
	profile: profileSelector(state),
	porteurs: getPorteursRib(state),
	user: userSelector(state),
	echeancier: getEcheancier(state),
	// @ts-ignore
	individuList: getIndividuListForRefund(state)
})

const actions = {
	getAffiliation: affiliationActions.getAffiliation,
	getRibCotisation: ribCotisationActions.getRibCotisation,
	loadEcheancier: echeancierActions.loadEcheancier
}

export default connect(
	mapStateToProps, actions
)(PersonalDataPage)
