import React from 'react'
import { Stack } from '@mui/material'
import NavMenuProvider from '../../../../components/navMenu/NavMenuProvider'
import PaymentCardPage from '../pages/PaymentCardPage'

const CarteTpPage = () => {
	return (
		<NavMenuProvider isEntreprise={false} isCarteTP={true}>
			<Stack direction="row" justifyContent="flex-start">
				<PaymentCardPage isCarteTpView={true} />
			</Stack>
		</NavMenuProvider>
	)
}

export default CarteTpPage
