const ACTION_NAMESPACE = '/READ_ONLY_MODAL'

export const types = {
	OPEN: `${ACTION_NAMESPACE}/OPEN`,
	CLOSE: `${ACTION_NAMESPACE}/CLOSE`
}

export const openReadOnlyModal = () => (dispatch) => {
	dispatch({
		type: types.OPEN
	})
}

export const closeReadOnlyModal = () => (dispatch) => {
	dispatch({
		type: types.CLOSE
	})
}
