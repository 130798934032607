import React, {useLayoutEffect} from 'react'
import {Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import EmptyResult from '../../components/EmptyResult'

type GridFinanceTotalChartProps = {
    id_title: string | React.ReactNode | any
    idChart: string
    dataChart: any[]
}

const GridFinanceTotalChart: React.FC<GridFinanceTotalChartProps> = (
    {
        id_title,
        idChart,
        dataChart
    }
) => {
    const intl = useIntl()
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const isSmallScreen = isMobile || isTabletPortrait

    useLayoutEffect(() => {
        // @ts-ignore
        if (dataChart[0].S > 0) {
            let root = am5.Root.new(idChart)
            root.setThemes([am5themes_Animated.new(root)])
            root.numberFormatter.set("intlLocales", "fr-FR");
            root.numberFormatter.set("numberFormat", {
                maximumSignificantDigits: 2
            })
            root.numberFormatter.setAll({
                // le ! force le format pour les valeurs < 1000
                numberFormat: "#.00 !a'€'",
                numericFields: ["valueY"]
            })
            // @ts-ignore
            root._logo.dispose()

            root.setThemes([
                am5themes_Animated.new(root)
            ])
            root.interfaceColors.set("grid", am5.color(0xffffff))


            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    layout: root.verticalLayout
                })
            )
            // @ts-ignore
            chart.get("colors").set("colors", [
                am5.color(0x4bc4c2),
                am5.color(0x8ac11d),
                am5.color(0xfc454a),
            ])

            let legend = chart.children.push(
                am5.Legend.new(root, {
                    layout: root.horizontalLayout,
                    centerX: am5.p50,
                    x: am5.percent(65)
                })
            )
            legend.itemContainers.template.setAll({
                marginLeft: 0,
                marginTop: 0,
                marginRight: 0,
                marginBottom: 0,
                paddingLeft: -20,
                paddingTop: 16,
                paddingRight: -20,
            })

            let xRenderer = am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9
            })
            xRenderer.grid.template.setAll({
                location: 1
            })

            let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: "year",
                    renderer: xRenderer,
                    visible: false,
                    tooltip: am5.Tooltip.new(root, {})
                })
            )
            xAxis.data.setAll(dataChart);

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {
                        strokeOpacity: 0.1
                    })
                })
            )

            // @ts-ignore
            function makeSeries(name: string, fieldName: string) {
                let series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: fieldName,
                        categoryXField: "year"
                    })
                )
                series.columns.template.setAll({
                    tooltipText: "{name} : {valueY}",
                    width: am5.percent(90),
                    tooltipY: 0,
                    strokeOpacity: 0,
                    cornerRadiusTL: 5,
                    cornerRadiusTR: 5,
                })

                // On retire les montants financiers en bas de chaque barre du graph (EAKPI1156)

                // series.bullets.push(function () {
                //     return am5.Bullet.new(root, {
                //         locationY: 0,
                //         sprite: am5.Label.new(root, {
                //             text: "{valueY}",
                //             fill: am5.color(0x000000),
                //             centerY: am5.p100,
                //             centerX: am5.p50,
                //             populateText: true
                //         })
                //     })
                // })

                series.data.setAll(dataChart);
                series.appear()

                legend.data.push(series)
            }

            makeSeries(intl.formatMessage({id: 'sante.financeCotisation.financeNumbers.cotisNette'}), "S")
            makeSeries(intl.formatMessage({id: 'sante.financeCotisation.financeNumbers.prestations'}), "P")
            makeSeries(intl.formatMessage({id: 'sante.financeCotisation.financeTotal.resultLabel'}), "S/P")
            chart.appear(1000, 100)

            return () => root.dispose()
        }

    }, [dataChart, idChart, intl])

    const titleGraph = () => {
        return (
            <Typography
                textAlign="left"
                variant="h2"
                fontSize="1.125rem"
                dangerouslySetInnerHTML={{__html: id_title.title}}
            />
        )
    }

    if (dataChart[0].S === 0) {
        return (
            <Stack direction="column" spacing="24px" flex="1">
                {titleGraph()}
                <Stack alignItems="center" direction="column">
                    <EmptyResult
                        nature="GRAPH"
                        message={
                            <FormattedMessage
                                id="global.noGraphData"
                                values={{
                                    titreGraph:
                                        <Typography
                                            variant="body2"
                                            fontSize="0,875rem"
                                            fontWeight="bold"
                                            color="#929292"
                                            dangerouslySetInnerHTML={{__html: id_title.title}}
                                        />
                                }}
                            />
                        }
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column" spacing="24px">
            <Stack direction="column" spacing="12px" flex="1">
                {titleGraph()}
                <div
                    id={idChart}
                    style={{
                        width: '100%',
                        height: isSmallScreen ? '400px' : '500px'
                    }}
                />
            </Stack>
            <Typography variant="subtitle2" fontSize="0.75rem" dangerouslySetInnerHTML={{__html: id_title.body}}/>
        </Stack>
    )
}

export default GridFinanceTotalChart
