/* global safari, opr */

export default () => {
	// Opera 8.0+
	const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
	// Firefox 1.0+
	const isFirefox = typeof InstallTrigger !== 'undefined'
	// Safari 3.0+ "[object HTMLElementConstructor]"
	const isSafari = /constructor/i.test(window.HTMLElement) || ((p) => p.toString() === '[object SafariRemoteNotification]')(!window.safari || safari.pushNotification)
	// Internet Explorer 6-11
	const isIE = /* @cc_on!@*/false || !!document.documentMode
	// Edge 20+
	const isEdge = !isIE && !!window.StyleMedia
	// Chrome 1+
	const isChrome = !!window.chrome && !!window.chrome.webstore
	// Blink engine detection
	// var isBlink = (isChrome || isOpera) && !!window.CSS;
	if (isOpera) {
		return 'Opera'
	}
	if (isFirefox) {
		return 'Firefox'
	}
	if (isSafari) {
		return 'Safari'
	}
	if (isIE) {
		return 'Internet Explorer'
	}
	if (isEdge) {
		return 'Edge'
	}
	if (isChrome) {
		return 'Chrome'
	}
	return ''
}
