import React, {useEffect, useState} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import Loader from '../../../../components/Loader'
import {Box, Stack} from '@mui/material'
import Welcome from '../components/WelcomeEE'
import {getListCompany, isListCompanyLoading} from '../../listCompany/ListCompanySelector'
import {homePageOrderSelector} from '../../data/dataSelectors'
import * as dataActions from '../../data/dataActions'
import DashboardCardEE from '../components/DashboardCardEE'

const DashboardPageEE = (
    {
        // @ts-ignore
        dateHomePage,
        // @ts-ignore
        loadingSociete,
        // @ts-ignore
        societes,
        // @ts-ignore
        getDataHomePage
    }
) => {

    useEffect(() => {
        if (societes.length > 0) {
            getDataHomePage((new Date()).getFullYear())
        }
    }, [societes])


    const [currentOpenedKey, setCurrentOpenedKey] = useState<number>(-1)

    useEffect(() => {
        if (dateHomePage && dateHomePage.length > 0) {
            setCurrentOpenedKey(dateHomePage[0].societe.numindiv)
        }
    }, [dateHomePage])

    if (loadingSociete || !(societes.length > 0 && dateHomePage.length !== 0)) {
        return <Loader/>
    }

    return (
        <Stack width="100%" direction="column" spacing="24px">
            <Stack width="100%" direction="row" spacing="24px" alignItems="flex-end" marginTop="-24px">
                <Box display="flex" flex="1">
                    <Welcome/>
                </Box>
            </Stack>

            <Stack width="100%" direction="column" spacing="24px">
                {dateHomePage.map((data: any) => (
                    <div key={data.societe.numindiv}>
                        <DashboardCardEE
                            loading={loadingSociete}
                            data={data}
                            keyData={currentOpenedKey}
                            setKeyData={setCurrentOpenedKey}
                        />
                    </div>
                ))}
            </Stack>
        </Stack>
    )
}

const actions = {
    getDataHomePage: dataActions.getDataHomePage,
}
const mapStateToProps = (state: any) => {

    return ({
        loadingSociete: isListCompanyLoading(state),
        dateHomePage: homePageOrderSelector(state),
        societes: getListCompany(state)
    })
}
export default compose(
    connect(mapStateToProps, actions)
)(DashboardPageEE)

