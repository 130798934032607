export enum MODIFY_BENEFICIARY_FIELDS {
	NAME = 'nom',
	FIRSTNAME = 'prenom',
	BIRTHDATE = 'dateNaissance',
	EMAIL = 'email',
	PHONE = 'telephone',
	STARTING_DATE = 'dateCompter',
	BENEFICIARY = 'beneficiaire',
}


export enum ADD_BENEFICIARY_FIELDS {
	TYPES = 'typeBeneficiaire',
	SEXE = 'civilite',
	NAME = 'nom',
	FIRSTNAME = 'prenom',
	BIRTHDATE = 'dateNaissance',
	RANKING_BIRTH = 'rangNaissance',
	SS_NUMBER = 'numSS',
	SS_NUMBER2 = 'numSS2',
	COMPANY = 'organisme',
	COMPANY2 = 'organisme2',
	STARTING_DATE = 'dateDebut',
	MUTUELLE = 'mutuelle',
	SS_FILE = 'mutuelleFile',
	FILES = 'files',
}