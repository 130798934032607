import React, {Dispatch, SetStateAction, useContext, useMemo, useState} from 'react'
import Popup from '../../../../components/Popup'
import {FormattedMessage, useIntl} from 'react-intl'
import {CircularProgress, Stack, Typography} from '@mui/material'
import SelectInput from '../../../../components/form/SelectInput'
import {MODIFY_BENEFICIARY_FIELDS} from '../services/modifyBeneficiaryConstants'
import {Form} from 'react-final-form'
import Box from '@mui/material/Box'
import ButtonDefault from '../../../../components/ButtonDefault'
import ModifyBeneficiaryForm from './ModifyBeneficiaryForm'
import LocalStorage from '../../../../business/storage/LocalStorage'
import AffiliationService from '../../../../api/gerep/AffiliationService'
import {ToastContext} from '../../../../components/toast/ToastContext'
import {ToastType} from '../../../../components/toast/toastConstants'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import {isBefore, isValid} from 'date-fns'
import FormatUtils from '../../../../utils/FormatUtils'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import {FORM_REGEX} from '../../../../components/formConstants'

type ModifyBeneficiaryDataProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	beneficiaireList: any[]
}

const ModifyBeneficiaryPopup: React.FC<ModifyBeneficiaryDataProps> = (
	{
		open,
		setOpen,
		beneficiaireList
	}) => {
	const intl = useIntl()
	const {addToast} = useContext(ToastContext)
	const {isTabletPortrait} = useBreakpoints()

	const [openSuccess, setOpenSuccess] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const BENEFICIARY_LIST = useMemo(() => beneficiaireList.map(beneficiary => ({
		value: beneficiary[0],
		label: `${beneficiary[1].nom} ${beneficiary[1].prenom}`
	})), [beneficiaireList])

	const onSubmit = (values: Record<string, any>) => {

		const {beneficiaire, nom, prenom, dateNaissance, email, telephone, dateCompter} = values

		const formattedDateCompter = new Date(dateCompter).toLocaleDateString('fr-FR')
		const formattedDateNaissance = new Date(dateNaissance).toLocaleDateString('fr-FR')
		const selectedBeneficiary = beneficiaireList.find(beneficiary => beneficiary[0] === beneficiaire)
		const initialValues = {
			beneficiaire: selectedBeneficiary[0],
			nom: selectedBeneficiary[1].nom,
			prenom: selectedBeneficiary[1].prenom,
			dateNaissance: FormatUtils.parseDate(selectedBeneficiary[1].dateNaissance).toLocaleDateString('fr-FR'),
			email: selectedBeneficiary[1].email,
			telephone: selectedBeneficiary[1].telephone,
			dateCompter: new Date().toLocaleDateString('fr-FR')
		}
		// Check if the form fields have been modified
		const isNomModified = values[MODIFY_BENEFICIARY_FIELDS.NAME] !== initialValues.nom
		const isPrenomModified = values[MODIFY_BENEFICIARY_FIELDS.FIRSTNAME] !== initialValues.prenom
		const isDateNaissanceModified = formattedDateNaissance !== initialValues.dateNaissance
		const isEmailModified = values[MODIFY_BENEFICIARY_FIELDS.EMAIL] !== initialValues.email
		const isTelephoneModified = values[MODIFY_BENEFICIARY_FIELDS.PHONE] !== initialValues.telephone
		const isDateCompterModified = formattedDateCompter !== initialValues.dateCompter

		const changedValues = {
			nom: isNomModified ? nom : undefined,
			prenom: isPrenomModified ? prenom : undefined,
			dateNaissance: isDateNaissanceModified ? formattedDateNaissance : undefined,
			email: isEmailModified ? email : undefined,
			telephone: isTelephoneModified ? telephone : undefined,
			dateCompter: isDateCompterModified ? formattedDateCompter : undefined
		}

		if (isNomModified || isPrenomModified || isDateNaissanceModified || isEmailModified || isTelephoneModified || isDateCompterModified) {
			setIsLoading(true)
			AffiliationService.editBeneficiaire({
				values: initialValues,
				modifiedValues: {
					...changedValues,
					beneficiaire,
					dateCompter: formattedDateCompter
				}
			}, LocalStorage.getToken())
				.then(() => {
					setOpenSuccess(true)
					addToast(ToastType.SUCCESS, 'global.success')
					setOpen(false)
				})
				.catch(() => addToast(ToastType.ERROR, 'global.error.occurred'))
				.finally(() => setIsLoading(false))
		}
	}

	return (
		<>
			<Popup
				maxWidth={isTabletPortrait ? '800px' : '600px'}
				open={open}
				setOpen={setOpen}
				title={intl.formatMessage({id: 'beneficiary.modifyPopin.title'})}
			>
				<Form
					onSubmit={onSubmit}
					validate={values => {
						const errors: any = {}
						const dateJour = new Date()
						dateJour.setHours(0, 0, 0, 0)
						const birthDate = new Date(values[MODIFY_BENEFICIARY_FIELDS.BIRTHDATE])
						const startingDate = new Date(values[MODIFY_BENEFICIARY_FIELDS.STARTING_DATE])
						if (!values[MODIFY_BENEFICIARY_FIELDS.BENEFICIARY]) {
							errors[MODIFY_BENEFICIARY_FIELDS.BENEFICIARY] = intl.formatMessage({id: 'global.error.required'})
						} else if (!values[MODIFY_BENEFICIARY_FIELDS.EMAIL] && !values[MODIFY_BENEFICIARY_FIELDS.PHONE] && !values[MODIFY_BENEFICIARY_FIELDS.NAME] && !values[MODIFY_BENEFICIARY_FIELDS.FIRSTNAME] && !values[MODIFY_BENEFICIARY_FIELDS.BIRTHDATE]) {
							errors[MODIFY_BENEFICIARY_FIELDS.BENEFICIARY] = intl.formatMessage({id: 'global.error.fieldRequired'})
						}

						if (values[MODIFY_BENEFICIARY_FIELDS.BIRTHDATE] && !isValid(birthDate)) {
							errors[MODIFY_BENEFICIARY_FIELDS.BIRTHDATE] = intl.formatMessage({id: 'global.error.invalidDate'})
						}

						if (!values[MODIFY_BENEFICIARY_FIELDS.STARTING_DATE]) {
							errors[MODIFY_BENEFICIARY_FIELDS.STARTING_DATE] = intl.formatMessage({id: 'global.error.required'})
						} else if (values[MODIFY_BENEFICIARY_FIELDS.STARTING_DATE] && !isValid(startingDate)) {
							errors[MODIFY_BENEFICIARY_FIELDS.STARTING_DATE] = intl.formatMessage({id: 'global.error.invalidDate'})
						} else if (values[MODIFY_BENEFICIARY_FIELDS.STARTING_DATE] && isBefore(startingDate, dateJour)) {
							errors[MODIFY_BENEFICIARY_FIELDS.STARTING_DATE] = intl.formatMessage({id: 'global.error.dateBeforeToday'})
						}

						if (values[MODIFY_BENEFICIARY_FIELDS.EMAIL] && !FORM_REGEX.EMAIL.test(values[MODIFY_BENEFICIARY_FIELDS.EMAIL])) {
							errors[MODIFY_BENEFICIARY_FIELDS.EMAIL] = intl.formatMessage({id: 'global.error.invalidEmail'})
						}

						if (values[MODIFY_BENEFICIARY_FIELDS.PHONE] && values[MODIFY_BENEFICIARY_FIELDS.PHONE].length < 10) {
							errors[MODIFY_BENEFICIARY_FIELDS.PHONE] = intl.formatMessage({id: 'global.error.invalidPhone'})
						}

						return errors
					}}
					render={({handleSubmit, pristine, values}) => {
						const {dateNaissance, dateCompter} = values
						const formattedDateCompter = new Date(dateCompter).toLocaleDateString('fr-FR')
						const formattedDateNaissance = new Date(dateNaissance).toLocaleDateString('fr-FR')

						const selectedBeneficiary = beneficiaireList.find(beneficiary => beneficiary[0] === values[MODIFY_BENEFICIARY_FIELDS.BENEFICIARY])

						const initialValues = {
							beneficiaire: selectedBeneficiary && selectedBeneficiary[0],
							nom: selectedBeneficiary && selectedBeneficiary[1].nom,
							prenom: selectedBeneficiary && selectedBeneficiary[1].prenom,
							dateNaissance: selectedBeneficiary && FormatUtils.parseDate(selectedBeneficiary[1].dateNaissance).toLocaleDateString('fr-FR'),
							email: selectedBeneficiary && selectedBeneficiary[1].email,
							telephone: selectedBeneficiary && selectedBeneficiary[1].telephone,
							dateCompter: selectedBeneficiary && new Date().toLocaleDateString('fr-FR')
						}

						// Check if the form fields have been modified
						const isNomModified = values[MODIFY_BENEFICIARY_FIELDS.NAME] !== initialValues?.nom
						const isPrenomModified = values[MODIFY_BENEFICIARY_FIELDS.FIRSTNAME] !== initialValues?.prenom
						const isDateNaissanceModified = formattedDateNaissance !== initialValues?.dateNaissance
						const isEmailModified = values[MODIFY_BENEFICIARY_FIELDS.EMAIL] !== initialValues?.email
						const isTelephoneModified = values[MODIFY_BENEFICIARY_FIELDS.PHONE] !== initialValues?.telephone
						const isDateCompterModified = formattedDateCompter !== initialValues?.dateCompter

						return (
							<form onSubmit={handleSubmit}>
								<Stack direction="column" spacing="24px">
									<Typography variant="body2">
										<FormattedMessage id="beneficiary.modifyPopin.text"/>
									</Typography>

									<Box>
										<SelectInput
											name={MODIFY_BENEFICIARY_FIELDS.BENEFICIARY}
											data={BENEFICIARY_LIST}
											label={intl.formatMessage({id: 'beneficiary.modifyPopin.labels.beneficiary'})}
										/>
									</Box>

									<ModifyBeneficiaryForm beneficiaireList={beneficiaireList}
														   selectedBenef={values[MODIFY_BENEFICIARY_FIELDS.BENEFICIARY]}/>

									<Box display="flex" justifyContent="center">
										<ButtonDefault
											type="submit"
											variant="contained"
											onClick={handleSubmit}
											disabled={pristine || !(isNomModified || isPrenomModified || isDateNaissanceModified || isEmailModified || isTelephoneModified || isDateCompterModified)}
										>
											{isLoading ? <CircularProgress size={24} color="inherit"/> :
												<FormattedMessage id="global.button.validate"/>}
										</ButtonDefault>
									</Box>
								</Stack>
							</form>
						)
					}}
				/>
			</Popup>
			<ConfirmationPopin title={intl.formatMessage({id: 'beneficiary.modifyPopin.title'})} open={openSuccess}
							   setOpen={setOpenSuccess}/>
		</>
	)
}

export default ModifyBeneficiaryPopup
