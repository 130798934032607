import {Dispatch} from 'react'
import UserService from '../../../../../api/gerep/UserService'
import LocalStorage from '../../../../../business/storage/LocalStorage'
import {UNIVERS_ENTREPRISES, UNIVERS_PARTICULIERS} from '../../../../constant/univers'
import UserServiceEE from '../../../../../api/gerepEe/UserServiceEE'

const ACTION_NAMESPACE = '/LOGIN'

export const types = {
    INIT_USER: `${ACTION_NAMESPACE}/INIT_USER`,
    LOG_USER: `${ACTION_NAMESPACE}/LOG_USER`,
    LOGOUT: `${ACTION_NAMESPACE}/LOGOUT`,
    LOADING: `${ACTION_NAMESPACE}/LOADING`,
    DISPLAY_CAPTCHA: `${ACTION_NAMESPACE}/DISPLAY_CAPTCHA`,
    REGISTER_USER_REDIRECTION: `${ACTION_NAMESPACE}/REGISTER_USER_REDIRECTION`,
    GET_REDIRECTION_BOOLEAN: `${ACTION_NAMESPACE}/GET_REDIRECTION_BOOLEAN`
}

export const registerRedirection = (path: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: types.REGISTER_USER_REDIRECTION,
        path
    })
}

export const login = (username: string, password: string, captcharesponse: any, rememberMe: boolean, viewCarteTP: boolean) => (dispatch: Dispatch<any>) => {
    return UserService.login(username, password, captcharesponse, rememberMe, viewCarteTP).then((loginResponse) => {
        dispatch({type: types.LOADING, payload: false})
        if (loginResponse) {
            if (!loginResponse.doubleAuthent){
                LocalStorage.setSession(loginResponse.token, UNIVERS_PARTICULIERS.id, false)
            }
            return loginResponse
        }
        throw new Error('Login failed')
    }).catch((e) => {
        dispatch({type: types.LOADING, payload: false})
        throw e
    })
}

export const loginEE = (username: string, password: string, captcharesponse: any, rememberMe: boolean) => (dispatch: Dispatch<any>) => {
    return UserServiceEE.login(username, password, captcharesponse, rememberMe).then((loginResponse) => {
        dispatch({type: types.LOADING, payload: false})
        if (loginResponse) {
            if (!loginResponse.user.doubleAuthentification) {
                LocalStorage.setSession(loginResponse.user.token, UNIVERS_ENTREPRISES.id, loginResponse.user.profil.kpi)
            }
            return loginResponse
        }
        throw new Error('Login failed')
    }).catch((e) => {
        dispatch({type: types.LOADING, payload: false})
        throw e
    })
}

export const logoutEA = (reason: string) => (dispatch: Dispatch<any>) => {
    // TODO fix temporaire
    return UserService.logout(LocalStorage.getToken()).catch((e) => {
        console.error(e)
    }).then(() => {// même si la suppression du token est en erreur on ne bloque pas la déconnexion
        LocalStorage.clearSession()
        window.location.href = UNIVERS_PARTICULIERS.root()
    })
}

export const logoutEE = (reason: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: types.LOGOUT,
        payload: reason
    })
    LocalStorage.clearSession()
    window.location.href = UNIVERS_ENTREPRISES.root()
}

export const logoutApi = (reason: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: types.LOGOUT,
        payload: reason
    })
    LocalStorage.clearSession()
    window.location.href = UNIVERS_PARTICULIERS.root()
}

export const displayCaptcha = () => (dispatch: Dispatch<any>) => {
    /*
     * TODO
     *  le captcha était jusque là partagé entre EA et EE (même reducer)
     *  ce qui pouvait engendrer des comportements bizarres.
     *  Le jour où captcha sera réactivé pour la connexion, il faudra changer
     *  le système en passant par un local state par exemple (pour que EA et EE
     *  aient chacun leur système)
     */
}

export const loadingOn = (dispatch: Dispatch<any>) => {
    dispatch({
        type: types.LOADING,
        payload: true
    })
}

export const setRedirectionBoolean = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: types.GET_REDIRECTION_BOOLEAN,
        payload: true
    })
}
