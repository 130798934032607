import React, { useCallback, useMemo } from 'react'
import TextInput from '../../../../components/form/TextInput'
import { MODIFY_BENEFICIARY_FIELDS } from '../services/modifyBeneficiaryConstants'
import DatePickerInput from '../../../../components/form/DatePickerInput'
import { useIntl } from 'react-intl'
import PatternTextInput from '../../../../components/PatternTextInput'
import { PATTERNS } from '../../../../components/formConstants'

type ModifyBeneficiaryFormProps = {
	beneficiaireList: any[]
	selectedBenef: any
}

const ModifyBeneficiaryForm: React.FC<ModifyBeneficiaryFormProps> = (
	{
		beneficiaireList,
		selectedBenef
	}
) => {
	const intl = useIntl()
	const selectedBeneficiary = useMemo(() => beneficiaireList.find(benef => benef[0] === selectedBenef), [beneficiaireList, selectedBenef])
	const renderForm = useCallback(() => {
		return (
			<>
				<TextInput
					maxWidth="unset"
					name={MODIFY_BENEFICIARY_FIELDS.NAME}
					label={intl.formatMessage({ id: 'beneficiary.modifyPopin.labels.name' })}
					disabled={!selectedBeneficiary}
					defaultValue={selectedBeneficiary && selectedBeneficiary[1].nom}
				/>
				<TextInput
					maxWidth="unset"
					name={MODIFY_BENEFICIARY_FIELDS.FIRSTNAME}
					label={intl.formatMessage({ id: 'beneficiary.modifyPopin.labels.firstName' })}
					disabled={!selectedBeneficiary}
					defaultValue={selectedBeneficiary && selectedBeneficiary[1].prenom}
				/>
				<DatePickerInput
					maxWidth="unset"
					name={MODIFY_BENEFICIARY_FIELDS.BIRTHDATE}
					label={intl.formatMessage({ id: 'beneficiary.modifyPopin.labels.birthdate' })}
					disabled={!selectedBeneficiary}
					initialValue={selectedBeneficiary && selectedBeneficiary[1].dateNaissance}
				/>
				<TextInput
					maxWidth="unset"
					name={MODIFY_BENEFICIARY_FIELDS.EMAIL}
					label={intl.formatMessage({ id: 'beneficiary.modifyPopin.labels.email' })}
					disabled={!selectedBeneficiary}
					defaultValue={selectedBeneficiary && selectedBeneficiary[1].email}
				/>
				<PatternTextInput
					maxWidth="unset"
					format={PATTERNS.PHONE}
					name={MODIFY_BENEFICIARY_FIELDS.PHONE}
					label={intl.formatMessage({ id: 'beneficiary.modifyPopin.labels.phone' })}
					disabled={!selectedBeneficiary}
					defaultValue={selectedBeneficiary && selectedBeneficiary[1].telephone}
				/>
				<DatePickerInput
					maxWidth="unset"
					name={MODIFY_BENEFICIARY_FIELDS.STARTING_DATE}
					label={intl.formatMessage({ id: 'beneficiary.modifyPopin.labels.startingDate' })}
					disabled={!selectedBeneficiary}
					initialValue={selectedBeneficiary && new Date().toLocaleDateString('fr-FR')}
				/>
			</>
		)
	}, [selectedBeneficiary])
	return (
		<>
			{renderForm()}
		</>
	)
}

export default ModifyBeneficiaryForm