import React from 'react'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const NoGaranties: React.FC = () => {
	return (
		<Stack direction="column" spacing="24px">
			<Stack direction="column">
				<Typography variant="body2" fontWeight={600}>
					<FormattedMessage id="souscription.noGaranties.main.title" />
				</Typography>
				<Typography variant="body2" fontWeight={600}>
					<ul>
						<li>
							<FormattedMessage id="souscription.noGaranties.main.1" />
						</li>
						<li>
							<FormattedMessage id="souscription.noGaranties.main.2" />
						</li>
						<li>
							<FormattedMessage id="souscription.noGaranties.main.3" />
						</li>
						<li>
							<FormattedMessage id="souscription.noGaranties.main.4" />
						</li>
					</ul>
				</Typography>
			</Stack>
			<Typography variant="body2">
				<FormattedMessage id="souscription.noGaranties.subtext" />
			</Typography>
		</Stack>
	)
}

export default NoGaranties