import FormatUtils from './FormatUtils'

export const calculAge = (dateNaissance) => {
	const dateJour = new Date()
	const anneeJ = dateJour.getFullYear()
	const anneeN = dateNaissance.getFullYear()
	const moisJ = dateJour.getMonth()
	const moisN = dateNaissance.getMonth()
	const jourJ = dateJour.getDate()
	const jourN = dateNaissance.getDate()
	let age = anneeJ - anneeN
	if (moisJ < moisN) {
		age--
	} else if (moisN === moisJ) {
		if (jourJ < jourN) {
			age--
		}
	}
	return age
}

/**
 * @param dateNaissance
 * @return l'age qu'aura l'individu en fin d'année
 */
const calculAgeFinAnnee = (dateNaissance) => {
	const dateJour = new Date()
	const anneeJ = dateJour.getFullYear()
	const anneeN = dateNaissance.getFullYear()
	return anneeJ - anneeN
}

export const estMajeur = (dateNaissanceString) => {
	const dateNaissance = FormatUtils.parseDate(dateNaissanceString)
	if (isNaN(+dateNaissance)) {
		return false
	}
	const age = calculAge(dateNaissance)
	return age >= 18
}

export const estMineur = (dateNaissanceString) => {
	const dateNaissance = FormatUtils.parseDate(dateNaissanceString)
	if (isNaN(+dateNaissance)) {
		return false
	}
	const age = calculAge(dateNaissance)
	return age < 18
}

export const estMajeurFinAnnee = (dateNaissanceString) => {
	const dateNaissance = FormatUtils.parseDate(dateNaissanceString)
	const age = calculAgeFinAnnee(dateNaissance)
	return age >= 18
}

export const newDatePlusYears = (nbYears) => {
	const date = new Date()
	const year = date.getFullYear()
	const month = date.getMonth()
	const day = date.getDate()
	return new Date(year + nbYears, month, day)
}

const newDatePlusMonths = (nbMonths) => {
	const date = new Date()
	const year = date.getFullYear()
	const month = date.getMonth()
	const day = date.getDate()
	return new Date(year, month + nbMonths, day)
}

export const isTooOldByMonths = (date, nbMonths) => {
	return date < newDatePlusMonths(-nbMonths)
}
