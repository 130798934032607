export enum HOSPITALIZATION_FIELDS {
	BENEFICIARY = 'beneficiaire',
	MUTUELLE = 'mutuelle',
	NATURE = 'type',
	HOSPITAL_NAME = 'nomEtablissement',
	NUM_FINESS = 'numFiness',
	ADDRESS = 'adresse',
	POSTAL_CODE = 'codePostal',
	CITY = 'ville',
	FAX = 'fax',
	TELEPHONE = 'telephone',
	EMAIL = 'email',
	DATE_ENTRY = 'dateEntree',
}