import ContratsService from '../../../api/gerep/ContratsService'
import { getExpirationDate } from './contratsSelector'
import LocalStorage from '../../../business/storage/LocalStorage'

const ACTION_NAMESPACE = '/CONTRATS'

export const types = {
	GET_CONTRATS: `${ACTION_NAMESPACE}/GET_CONTRATS`,
	GET_CONTRATS_LOADING: `${ACTION_NAMESPACE}/GET_CONTRATS_LOADING`
}

export const loadContrats = () => (dispatch, getState) => {
	// si pas d'expiration du state
	if (getExpirationDate(getState()) < (+new Date())) {
		dispatch({ type: types.GET_CONTRATS_LOADING, payload: true })
		return ContratsService.getContrats(LocalStorage.getToken())
			.then((contrats) => {
				dispatch({ type: types.GET_CONTRATS_LOADING, payload: false })
				return dispatch({
					type: types.GET_CONTRATS,
					payload: contrats
				})
			}).catch((e) => {
				dispatch({ type: types.GET_CONTRATS_LOADING, payload: false })
				throw e
			})
	}
}


export const download = (numGar, siret, annee) => () => {
	return ContratsService.download(numGar, siret, annee, LocalStorage.getToken())
}

export const downloadEcheancierCotisations = (callBackonDlEcheancier) => dispatch => (
	ContratsService.downloadEcheancierCotisations(LocalStorage.getToken(), callBackonDlEcheancier)
)
