export const simulatorGroupBy = (list: SimulatorCell[]) => {
	const map: any = {}
	list.forEach((item) => {
		const key = map[item.poste] && map[item.poste][item.typeActe]
		if (!map[item.poste]) {
			map[item.poste] = {}
		} else if (!key) {
			map[item.poste][item.typeActe] = [item]
		} else {
			map[item.poste][item.typeActe].push(item)
		}
	})
	return map
}