import React, {useLayoutEffect} from 'react'
import {Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5percent from '@amcharts/amcharts5/percent'
import EmptyResult from '../../components/EmptyResult'

type PieChartPortabiliteProps = {
    id_title: string | React.ReactNode | any
    idChart: string
    dataChart: any[]
}

const PieChartPortabilite: React.FC<PieChartPortabiliteProps> = (
    {
        id_title,
        idChart,
        dataChart
    }
) => {
    const intl = useIntl()
    const {isMobile, isTabletPortrait, isTablet} = useBreakpoints()
    const isSmallScreen = isMobile || isTabletPortrait

    useLayoutEffect(() => {
        if (dataChart[0] > 0) {
            let root = am5.Root.new(idChart)
            root.setThemes([am5themes_Animated.new(root)])
            root.setThemes([am5themes_Animated.new(root)])
            root.numberFormatter.set("intlLocales", "fr-FR");
            root.numberFormatter.set("numberFormat", {
                maximumFractionDigits: 0
            })
            // @ts-ignore
            root._logo.dispose()

            let chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    layout: root.verticalLayout,
                    radius: am5.percent(80)
                })
            )

            let series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    valueField: "value",
                    categoryField: "category",
                    alignLabels: false
                })
            )
            series.slices.template.setAll({
                strokeWidth: 3,
                stroke: am5.color(0xffffff),
                cornerRadius: 15,

            })
            // @ts-ignore
            series.get("colors").set("colors", [
                am5.color(0x8ac11d),
                am5.color(0x7a7a7a)
            ])
            series.data.setAll([
                {value: dataChart[0], category: intl.formatMessage({id: "sante.demographie.portabilite.enPostes"})},
                {value: dataChart[1], category: intl.formatMessage({id: "sante.demographie.portabilite.enPortabilite"})}
            ])
            series.labels.template.setAll({
                text: isSmallScreen || isTablet ? "{value}" : "{category} : {value}",
                centerX: 0,
                centerY: 0,
                fontSize: 17,
                textType: 'circular'
            })

            let legend = chart.children.push(
                am5.Legend.new(root, {
                    x: am5.percent(50),
                    centerX: am5.p50
                })
            )
            // pour ne pas afficher le pourcentage de chaque part
            legend.valueLabels.template.setAll({visible: false})
            legend.data.setAll(series.dataItems)


            series.appear(1000, 100)

            return () => root.dispose()
        }

    }, [dataChart, idChart])

    const titleGraph = () => {
        return (
            <Typography
                textAlign="left"
                variant="h2"
                fontSize="1.125rem"
                dangerouslySetInnerHTML={{__html: id_title}}
            />
        )
    }

    if (!(dataChart[0] > 0)) {
        return (
            <Stack direction="column" spacing="24px" flex="1">
                {titleGraph()}
                <Stack alignItems="center" direction="column">
                    <EmptyResult
                        nature="GRAPH"
                        message={
                            <FormattedMessage
                                id="global.noGraphData"
                                values={{
                                    titreGraph:
                                        <Typography
                                            variant="body2"
                                            fontSize="0,875rem"
                                            fontWeight="bold"
                                            color="#929292"
                                            dangerouslySetInnerHTML={{__html: id_title}}
                                        />
                                }}
                            />
                        }
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column" spacing="12px" flex="1">
            {titleGraph()}
            <div
                id={idChart}
                style={{
                    width: '100%',
                    height: '400px'
                }}
            />
        </Stack>
    )
}

export default PieChartPortabilite
