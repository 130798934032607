import React from 'react'
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useTheme } from '@mui/material'
import { mdiFolder } from '@mdi/js'
import Icon from '@mdi/react'
import { ChevronRight } from '@mui/icons-material'

type HelpFolderProps = {
	title: string
	displayBottomDivider?: boolean
	onClick?: () => void
}

const HelpFolder: React.FC<HelpFolderProps> = (
	{
		title,
		displayBottomDivider,
		onClick
	}
) => {
	const theme = useTheme()
	
	return (
		<ListItem disablePadding sx={{ borderTop: '1px solid #dedede', borderBottom: displayBottomDivider ? '1px solid #dedede' : 'unset' }}>
			<ListItemButton onClick={onClick}>
				<ListItemText
					primary={
						<Stack direction="row" spacing="12px" alignItems="center">
							<Stack direction="row" spacing="12px" alignItems="center">
								<Icon path={mdiFolder} size="16px" color={theme.palette.secondary.main} />
							</Stack>
							<Typography variant="body1">
								{title}
							</Typography>
						</Stack>
					}
					primaryTypographyProps={{ variant: 'body1' }}
				/>
				<ListItemIcon>
					<ChevronRight />
				</ListItemIcon>
			</ListItemButton>
		</ListItem>
	)
}

export default HelpFolder
