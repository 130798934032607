let _configuration

/**
 * Méthode permettant de faire persister l'objet configuration afin qu'il puisse
 * être interrogé depuis n'importe où dans le projet. Comme la configuration n'a
 * pas vocation à être modifiée après la connexion, il est inutile de la stocker
 * dans redux.
 * @param configuration
 */
export const setConfiguration = (configuration) => {
	_configuration = configuration
}

/**
 * Méthode interne pour ne pas exposer l'objet configuration aux mutations.
 * @param code
 * @return {*}
 */
const getValue = (code) => {
	if (typeof _configuration !== 'object') {
		throw new Error('Configuration not set')
	}
	return _configuration[code]
}

/**
 * Retourne la durée au bout de laquelle il faut afficher la popin de contact.
 * @return {*}
 */
export const getShowHelpDuration = () => {
	return getValue('showHelpDuration')
}

/**
 * Retourne la date à laquelle la double authentification devient obligatoire.
 * @return {*}
 */
export const getDateDoubleAuthent = () => {
	return getValue('dateDoubleAuthent')
}
