/* eslint-disable class-methods-use-this */

export const TOKEN_KEY = 'token'
export const UNIVERS_KEY = 'univers'
export const IS_KPI = 'iskpi'

class LocalStorage {

	constructor() {
		this.values = {}
	}

	/**--------
	 * TOKEN JWT POUR LA SESSION
	 -----------*/
	getToken() {
		return this.getItem(TOKEN_KEY)
	}

	getUnivers() {
		return this.getItem(UNIVERS_KEY)
	}

	isKpi() {
		if(!this.getItem(IS_KPI)){
			return false
		} else {
			return this.getItem(IS_KPI) === "true"
		}
	}

	setSession(token, univers, iskpi) {
		this.setItem(TOKEN_KEY, token)
		this.setItem(UNIVERS_KEY, univers)
		this.setItem(IS_KPI, iskpi)
	}

	getItem(key) {
		return window.localStorage.getItem(key)
	}

	setItem(key, value) {
		window.localStorage.setItem(key, value)
	}

	removeItem(key) {
		return window.localStorage.removeItem(key)
	}

	getJSON(key) {
		const value = this.getItem(key)
		if (value) {
			try {
				return JSON.parse(value)
			} catch (error) {
				return undefined
			}
		}
		return value
	}

	setJSON(key, value) {
		if (value) {
			try {
				return this.setItem(key, JSON.stringify(value))
			} catch (error) {
				return undefined
			}
		}
		return this.setItem(key, value)
	}

	clearSession() {
		this.removeItem(TOKEN_KEY)
		this.removeItem(UNIVERS_KEY)
		this.removeItem(IS_KPI)
	}
}

export default new LocalStorage()
