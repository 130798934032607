import React, { useState } from 'react'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Typography } from '@mui/material'
import { Field } from 'react-final-form'

type CheckboxInputProps = {
	name: string
	data: SelectOption[]
	error?: string
	defaultValues?: (number | string)[]
}

const CheckboxInput: React.FC<CheckboxInputProps> = (
	{
		data,
		name,
		error,
		defaultValues
	}) => {
	const [touched, setTouched] = useState<boolean>(false)

	return (
		<FormControl error={!!error} required>
			<FormGroup>
				{data.map(({ value, label, validate, disabled }) => (
					<FormControlLabel
						key={`${label}-${value}`}
						value={value}
						label={
							<Typography fontSize="0.875rem" fontWeight={500}>
								{label}
							</Typography>
						}
						control={
							<Field
								name={name}
								type="checkbox"
								value={value}
								validate={validate}
								// TODO : Fix le initialvalue (mettre dans les initialValues du form direct pour le moment)
								// initialValue={defaultValues?.includes(value) ? value : undefined}
								render={({ input, meta }) => {
									if (meta.touched && !touched) {
										setTouched(true)
									}

									return (
										<Checkbox color="secondary" disabled={disabled} {...input} />
									)
								}}
							/>
						}
					/>
				))}
				{touched && <FormHelperText sx={{ marginLeft: 0 }}>{error}</FormHelperText>}
			</FormGroup>
		</FormControl>
	)
}

export default CheckboxInput
