import React, { Dispatch, SetStateAction } from 'react'
import Popup from '../Popup'
import { Box, Stack } from '@mui/material'
import CheckboxInput from '../form/CheckboxInput'
import ButtonDefault from '../ButtonDefault'
import { useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { FormApi } from 'final-form'

type InterstitielPopinProps = {
	openForm: boolean
	setOpenForm: Dispatch<SetStateAction<boolean>>
	title: string
	onClose?: () => void
	onPermanentClose?: (permanentClose: boolean) => void
	closePermanently?: boolean
	children: React.ReactNode
}

const InterstitielPopin: React.FC<InterstitielPopinProps> = (
	{
		setOpenForm,
		openForm,
		title,
		children,
		onClose,
		closePermanently,
		onPermanentClose
	}) => {
	const intl = useIntl()

	const onSubmit = (values: any, form: FormApi<any, Partial<any>>) => {
		onPermanentClose && onPermanentClose(values?.noDisplay?.length > 0)
		onClose && onClose()
		form.reset()
		closePermanently && setOpenForm(false)
	}

	return (
		<Popup
			open={openForm}
			setOpen={setOpenForm}
			title={title}
			onClose={onClose}
		>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Stack direction="column" spacing="12px">
							<Box>
								{children}
							</Box>
							<CheckboxInput
								name="noDisplay"
								data={[{ label: 'Ne plus afficher ce message', value: 'noDisplay' }]}
							/>
							<Box display="flex" justifyContent="center">
								<ButtonDefault variant="contained" type="submit" onClick={handleSubmit}>{intl.formatMessage({ id: 'global.button.close' })}</ButtonDefault>
							</Box>
						</Stack>
					</form>
				)}
			/>
		</Popup>
	)
}

export default InterstitielPopin
