import * as React from 'react'
import {useEffect, useState} from 'react'
import {AppBar as MuiAppBar, Drawer, IconButton, Stack} from '@mui/material'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import {NavMenuEA, NavMenuEE} from '../NavMenu'
import {useBreakpoints} from '../breakpoints/BreakpointsProvider'
import {userSelector} from '../../ea/ui/user/userSelectors'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {FormattedMessage, useIntl} from 'react-intl'
import EntrepriseSelector from '../../kpi/ui/components/EntrepriseSelector'
import {getListCompany} from '../../kpi/ui/listCompany/ListCompanySelector'
import * as dataActions from '../../kpi/ui/data/dataActions'
import {societeSelectSelector} from '../../kpi/ui/data/dataSelectors'
import {MAIN_PATH_EE} from '../../constant/path'
import {useLocation} from 'react-router-dom'
import FormatUtils from '../../utils/FormatUtils'

type AppBarProps = {
	displayInfo?: boolean
	isEntreprise: boolean
}

const AppBarEE: React.FC<AppBarProps> = (
	{
		displayInfo = true,
		// @ts-ignore
		user,
		isEntreprise,
		// @ts-ignore
		listCompany,
		// @ts-ignore
		societeSelect,
		// @ts-ignore
		handleSociete
	}) => {
	const [openDrawer, setOpenDrawer] = useState<boolean>(false)
	const { isMobile, isTabletPortrait, isTablet } = useBreakpoints()
	const isMobileOrTablet = isMobile || isTablet || isTabletPortrait
	const intl = useIntl()
	const location = useLocation()
	const [withSelectorSociete, setWithSelectorSociete] = useState(true)

	useEffect(() => {
		if(isEntreprise && listCompany.length >0){
			handleSociete(listCompany[0].numindiv)
		}
	}, [listCompany])

	useEffect(() => {
		if (isEntreprise) {
			if (location.pathname === `${MAIN_PATH_EE.ACCOUNT}`
				|| location.pathname === `${MAIN_PATH_EE.CONTACTER_GEREP}`
				|| location.pathname === `${MAIN_PATH_EE.CONTACT}`
				|| location.pathname === `${MAIN_PATH_EE.QUALITE_SERVICE}`
			) {
				setWithSelectorSociete(false)

			} else {
				setWithSelectorSociete(true)
			}
		}
	}, [location])

	return (
		<Box bgcolor="#f7f5f4" height="72px" ml={!isMobileOrTablet ? '260px' : 0} display="flex" alignItems="center">
			<MuiAppBar position="static" sx={{ bgcolor: '#f7f5f4', boxShadow: 0 }}>
				<Toolbar sx={{ justifyContent: !isMobileOrTablet ? 'flex-end' : 'space-between', alignItems: 'center' }}>
					{isMobileOrTablet && (
						<Stack direction="row" alignItems="center" spacing={5}>
							<IconButton
								onClick={() => setOpenDrawer(true)}
								size="small"
								edge="start"
								color="secondary"
								aria-label="menu"
								sx={(theme) => ({
									height: '40px',
									width: '40px',
									bgcolor: '#fff',
									borderRadius: '5px',
									border: '1px solid',
									borderColor: theme.palette.secondary.main,
									alignItems: 'center'
								})}
							>
								<MenuIcon />
							</IconButton>
							{/*<img src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`} style={{ width: '100px', height: '56px' }} alt="logo" />*/}
						</Stack>
					)}
					{displayInfo && (
						<>
							{(isEntreprise && withSelectorSociete) ?
								<Stack maxWidth="50%" >
									<EntrepriseSelector
										datas={listCompany}
										onChange = {handleSociete}
										stateValue={societeSelect}
										label={intl.formatMessage({id: 'appbar.selector.title'})}
									/>
								</Stack>
								: !isMobileOrTablet ?
									// <Stack>
									// 	<Typography textAlign="right" variant="h3" component="div" sx={{ mr: 2 }}>
									// 		{`${user?.prenom} ${user?.nom}`}
									// 	</Typography>
									// 	<Typography textAlign="right" variant="subtitle1" component="div" sx={{ mr: 2 }}>
									// 		<FormattedMessage
									// 			id="appbar.lastConnexion"
									// 			values={{ date: FormatUtils.formatDate(user?.dateConnexion) || new Date().toLocaleDateString('fr-FR') }}
									// 		/>
									// 	</Typography>
									// </Stack>
									undefined
									:
									undefined
							}
						</>
					)}
				</Toolbar>
			</MuiAppBar>
			<Drawer
				PaperProps={{ style: { width: '268px', overflow: 'hidden' } }}
				anchor="left"
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
			>
				{isEntreprise?
					<NavMenuEE closeDrawer={() => setOpenDrawer(false)} />
					:
					<NavMenuEA closeDrawer={() => setOpenDrawer(false)} />
				}
			</Drawer>
		</Box>
	)
}

const makeMapStateToProps = (state: any) => ({
	user: userSelector(state),
	listCompany: getListCompany(state),
	societeSelect: societeSelectSelector(state)
})

const actions = {
	handleSociete: dataActions.handleSociete,
}

export default compose(
	connect(makeMapStateToProps, actions)
)(AppBarEE)
