import React from 'react'
import {Box, Stack, Typography} from '@mui/material'

type InfoFieldProps = {
	label: string | React.ReactNode
	value: string | React.ReactNode
	required?: boolean
	direction?: 'row' | 'column',
	variantValue?: 'button' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'inherit' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'overline' | undefined
	flexLabel?: number
}

const InfoField: React.FC<InfoFieldProps> = (
	{
		value,
		label,
		required,
		direction = 'row',
		variantValue,
		flexLabel
	}) => {

	return (
		<Stack direction={direction} flex={1} spacing="12px">
			<Typography flex={flexLabel ? flexLabel : 1} fontWeight={400} fontSize="0.875rem">
				{label} {required ? (<span>*</span>) : ''}
			</Typography>
			{typeof value === 'string' ? (
				<>
					{variantValue ? (
						<Typography flex={3} variant={variantValue}>
							{value}
						</Typography>
					) : (
						<Typography flex={3} variant="body1">
							{value}
						</Typography>
					)
					}
				</>
			) : (
				<Box flex={3}>
					{value}
				</Box>
			)
			}
		</Stack>
	)
}

export default InfoField
