import GerepAPI from './GerepAPI'

export default class RemboursementsService {

	static getRemboursements(token, annee) {
		return GerepAPI.get(`/remboursements/${annee}`, null, token)
	}

	static getRemboursementsV2(token, annee) {
		return GerepAPI.get(`/remboursements/v2/${annee}`, null, token)
	}

	static getDerniersRemboursements(token) {
		return GerepAPI.get('/remboursements/last', null, token)
	}

	static downloadRemboursements(token, values) {
		return GerepAPI.download2('/remboursements/download', values, token)
	}

	static demandeRemboursement(token, values) {
		return GerepAPI.postMultipart('/remboursements/demandeRemboursement', values, token)
	}

	static getActesBienEtre(token, numBenf, dateSoins) {
		return GerepAPI.get(`/remboursements/actes/${numBenf}/${dateSoins}`, null, token)
	}
}
