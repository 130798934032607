import SuggestService from '../../../../../api/gerep/SuggestService'
import LocalStorage from '../../../../../business/storage/LocalStorage'
import AffiliationService from '../../../../../api/gerep/AffiliationService'
import UserService from '../../../../../api/gerep/UserService'
import { types as getTypes } from '../../../../webservices/affiliation/affiliationActions'
import { types as getUserTypes } from '../../../user/userActions'

const ACTION_NAMESPACE = '/SUGGEST'

export const types = {
	GET_PAYS: `${ACTION_NAMESPACE}/GET_PAYS`,
	CLEAR_PAYS: `${ACTION_NAMESPACE}/CLEAR_PAYS`,
	UPDATE_INPUT_VALUE: `${ACTION_NAMESPACE}/UPDATE_INPUT_VALUE`
}

export const getPays = value => dispatch => SuggestService.getPays()
	.then(pays => dispatch({
			type: types.GET_PAYS,
			payload: {
				pays,
				value
			}
		})
	).catch((e) => {
		throw e
	})

export const clearPays = () => dispatch => dispatch({
	type: types.CLEAR_PAYS
})

export const updateInputValue = value => dispatch => dispatch({
	type: types.UPDATE_INPUT_VALUE,
	payload: {
		value
	}
})

export const refreshAffiliation = (dispatch) => {
	dispatch({ type: getTypes.GET_AFFILIATION_LOADING, payload: true })
	try {
		const token = LocalStorage.getToken()
		const affiliationData = AffiliationService.getAffiliation(token)
		dispatch({ type: getTypes.GET_AFFILIATION_LOADING, payload: false })
		dispatch({ type: getTypes.GET_AFFILIATION, payload: affiliationData })
	} catch (e) {
		dispatch({ type: getTypes.GET_AFFILIATION_LOADING, payload: false })
		throw e
	}
}

export const refreshUser = (dispatch) => {
	const token = LocalStorage.getToken()
	UserService.getUser(token).then(
		(response) => {
			dispatch({ type: getUserTypes.SET_USER_MAIL, payload: response.email })
		}
	).catch((e) => {
		throw e
	})
}