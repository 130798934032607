import React from 'react'
import {Divider, Stack, Typography} from '@mui/material'
import HelpFolder from './HelpFolder'
import HelpFaq from './HelpFaq'
import HelpLink from './HelpLink'
import { TYPE_FOLDER, TYPE_ARTICLE_FAQ, TYPE_ARTICLE_LINK } from '../services/HelpConstants'
import { useHistory } from 'react-router-dom'
import SearchIcon  from '@mui/icons-material/Search'
import {FormattedMessage} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'

type HelpListProps = {
	folderContent: any
	setShortcut: any
	ariane: any
	setAriane: any
	forSearch: boolean
	setIsLoading: any
}

const HelpList: React.FC<HelpListProps> = (
	{
		folderContent,
		setShortcut,
		ariane,
		setAriane,
		forSearch,
		setIsLoading
	}
) => {

	const history = useHistory()
	const { isTablet, isMobile, isTabletPortrait } = useBreakpoints()

	return (
		<Stack direction="column">

			{(forSearch) ? (
				<>
					{
					(folderContent && folderContent.length > 0 ) ? (
						<>
							{folderContent.map((item: any, index: number) => {
								if (item.type === TYPE_FOLDER) {
									return (
										<HelpFolder
											key={index}
											title={item.name}
											onClick={() => {
												if (!(item.fil_ariane.find((itemAriane: any) => itemAriane.shortcut === item.shortcut))) {
													setAriane((prev: any) => [...prev, {
														label: item.name,
														shortcut: item.shortcut
													}])
												}
												setShortcut(item.shortcut)
												setIsLoading(true)
											}}
										/>
									)
								} else if (item.type === TYPE_ARTICLE_FAQ) {
									return (
										<HelpFaq
											key={index}
											question={item.question}
											ariane={item.fil_ariane}
											reponse={item.reponse}
											forSearch={forSearch}
										/>
									)
								} else if (item.type === TYPE_ARTICLE_LINK) {
									return (
										<HelpLink
											key={index}
											title={item.titre}
											onClick={() => {
												if (item.external) {
													window.location = item.url
												} else {
													history.push(item.url)
												}
											}}
											ariane={item.fil_ariane}
											forSearch={forSearch}
										/>
									)
								} else {
									return (
										<></>
									)
								}
							})
							}
						</>
					) : (
						<>
							<Stack spacing={2} direction={isTablet || isMobile || isTabletPortrait ? 'column' : 'row'}
								   justifyContent="center">
								<Stack spacing={isTablet || isMobile || isTabletPortrait ? 5 : 2} direction="row">
									<Stack direction="column" alignItems="center">
										<SearchIcon color="secondary" sx={{fontSize: '48px', opacity: 0.5}}/>
										<Typography variant="body2" sx={{color: '#929292'}}>
											<FormattedMessage id="help.emptyState"/>
										</Typography>
									</Stack>
								</Stack>
							</Stack>
						</>
					)
				}
				</>
			) : (
					<>
						{folderContent.map((item: any, index: number) => {
								if (item.type === TYPE_FOLDER) {
									return (
										<HelpFolder
											key={index}
											title={item.name}
											onClick={() => {
												if (!(ariane.find((itemAriane: any) => itemAriane.shortcut === item.shortcut))) {
													setAriane((prev: any) => [...prev, {
														label: item.name,
														shortcut: item.shortcut
													}])
												}
												setShortcut(item.shortcut)
												setIsLoading(true)
											}}
										/>
									)
								} else if (item.type === TYPE_ARTICLE_FAQ) {
									return (
										<HelpFaq
											key={index}
											question={item.question}
											ariane={ariane}
											reponse={item.reponse}
											forSearch={forSearch}
										/>
									)
								} else if (item.type === TYPE_ARTICLE_LINK) {
									return (
										<HelpLink
											key={index}
											title={item.titre}
											onClick={() => {
												if (item.external) {
													window.location = item.url
												} else {
													history.push(item.url)
												}
											}}
											ariane={ariane}
											forSearch={forSearch}
										/>
									)
								} else {
									return (
										<></>
									)
								}
							})
						}
					</>
			)}
		</Stack>
	)
}

export default HelpList
