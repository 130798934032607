
export const BENEFICIARY_TYPES = [
    {
        value: '2_A',
        label: 'Enfant mineur',
        document: 'Acte de naissance ou copie du livret de famille'
    },
    {
        value: '2_B',
        label: 'Enfant majeur',
        document: 'Certificat de scolarité'
    },
    {
        value: '1',
        label: 'Conjoint',
        document: 'Acte de mariage ou copie du livret de famille'
    },
    {
        value: '3',
        label: 'Concubin',
        document: 'Attestation de concubinage ou Justificatif de vie commune (quittance de loyer, compte bancaire joint...) ou Attestation sur l\'honneur de vie maritale'
    },
    {
        value: '7',
        label: 'Pacsé',
        document: 'Copie du PACS'
    }
]