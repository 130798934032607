import React, { useCallback } from 'react'
import { HOSPIWAY, ITELIS, SANTECLAIR, TELECONSULTATION_VYV } from '../../../webservices/servicesSante/servicesSanteUtils'
import SanteClairBlock from './SanteClairBlock'
import {ACCES_RESEAU_GA_ACTION, LIEN_ITELIS, SERVICES_BIEN_ETRE_GA_CATEGORY} from '../../../../utils/servicesUtils'
import HospiwayBlock from './HospiwayBlock'
import TeleconsultationVyvBlock from './TeleconsultationVyvBlock'
import ReactGA from 'react-ga'
import TeleconsultationCard from './TeleconsultationCard'
import ButtonDashboard from '../../../../components/ButtonDashboard'

type PolymorphicBlockProps = {
	service: any
}

const PolymorphicBlock: React.FC<PolymorphicBlockProps> = (
	{
		service
	}
) => {
	const renderComponentById = useCallback((id: number) => {
		switch (id) {
			case SANTECLAIR:
				return <SanteClairBlock service={service} />
			case ITELIS:
				return (
					<TeleconsultationCard
						service={service}
						lienWebCustom={
							<a
								href={LIEN_ITELIS}
								target="_blank"
								rel="noopener noreferrer"
								style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center' }}
								onClick={() => {
									ReactGA.event({
										category: SERVICES_BIEN_ETRE_GA_CATEGORY,
										action: ACCES_RESEAU_GA_ACTION,
										label: 'ITELIS'
									})
								}}
							>
								<ButtonDashboard>
									{service?.libelleLienWeb || 'Accédez au réseau'}
								</ButtonDashboard>
							</a>
						}
					/>
				)
			case HOSPIWAY:
				return <HospiwayBlock service={service} />
			case TELECONSULTATION_VYV:
				return <TeleconsultationVyvBlock service={service} />
			default:
				return <TeleconsultationCard service={service} />
		}
	}, [service])

	return (
		<>
			{renderComponentById(service.idService)}
		</>
	)
}

export default PolymorphicBlock
