import { getExpirationDate, isRubriquesLoading } from './contactSelector'
import ContactService from '../../../api/gerep/ContactService';
import LocalStorage from '../../../business/storage/LocalStorage';

const ACTION_NAMESPACE = '/CONTACT'

export const types = {
	GET_RUBRIQUES: `${ACTION_NAMESPACE}/GET_RUBRIQUES`,
	GET_RUBRIQUES_LOADING: `${ACTION_NAMESPACE}/GET_RUBRIQUES_LOADING`,
	GET_RECHERCHE_FAQ: `${ACTION_NAMESPACE}/GET_RECHERCHE_FAQ`,
	GET_RECHERCHE_LOADING: `${ACTION_NAMESPACE}/GET_RECHERCHE_LOADING`
}

export const getRubriques = (shortcut) => (dispatch, getState) => {
	if (
		!isRubriquesLoading(getState()) &&
		(!getExpirationDate(getState())[shortcut] || getExpirationDate(getState())[shortcut] < (+new Date()))) {
		dispatch({ type: types.GET_RUBRIQUES_LOADING, payload: true })
		return ContactService.getRubriques(LocalStorage.getToken(),{shortcut : shortcut})
			.then((rubriques) => {
				dispatch({ type: types.GET_RUBRIQUES_LOADING, payload: false })
				return dispatch({
					type: types.GET_RUBRIQUES,
					payload: rubriques
				})
			}).catch((e) => {
				dispatch({ type: types.GET_RUBRIQUES_LOADING, payload: false })
				throw e
			})
	}
}

export const rechercheFaq = (query) => (dispatch, getState) => {
	if (!isRubriquesLoading(getState())) {
		dispatch({ type: types.GET_RECHERCHE_LOADING, payload: true })
		return ContactService.getRechercheFaq(LocalStorage.getToken(),{query : query})
			.then((rubriques) => {
				dispatch({ type: types.GET_RECHERCHE_LOADING, payload: false })
				return dispatch({
					type: types.GET_RECHERCHE_FAQ,
					payload: rubriques
				})
			}).catch((e) => {
				dispatch({ type: types.GET_RECHERCHE_LOADING, payload: false })
				throw e
			})
	}
}
