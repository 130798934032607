import React, {Dispatch, SetStateAction, useContext, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Form} from 'react-final-form'
import {Box, CircularProgress, Stack, Typography} from '@mui/material'
import {ToastContext} from '../../../../components/toast/ToastContext'
import UserService from '../../../../api/gerep/UserService'
import LocalStorage from '../../../../business/storage/LocalStorage'
import {ToastType} from '../../../../components/toast/toastConstants'
import Popup from '../../../../components/Popup'
import {MODIFY_PASSWORD_FIELDS} from '../../../../ea/ui/personalInfo/services/personalInfoConstants'
import TextInput from '../../../../components/form/TextInput'
import ButtonDefault from '../../../../components/ButtonDefault'
import {useHistory} from 'react-router-dom'
import {MAIN_PATH} from '../../../../constant/path'
import {FIELD_PATTERN} from "../services/loginConstants";

type ResetPasswordProps = {
    openForm: boolean
    setOpenForm: Dispatch<SetStateAction<boolean>>
    passwordToken: string
}

const ResetPassword: React.FC<ResetPasswordProps> = ({setOpenForm, openForm, passwordToken}) => {
    const intl = useIntl()
    const history = useHistory()
    const {addToast} = useContext(ToastContext)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onSubmit = (values: Record<string, string>) => {
        setIsLoading(true)
        return UserService.editPasswordExt(LocalStorage.getToken(), values.password, values.newPassword, passwordToken)
            .then(() => {
                addToast(ToastType.SUCCESS, 'personalInfo.connexionInfo.resetPassword.success')
                setOpenForm(false)
                history.push(MAIN_PATH.LOGIN)
            })
            .catch((error) => {
                if (error?.data) {
                    return error.data
                } else {
                    addToast(ToastType.ERROR, 'global.error.occurred')
                }
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <Popup
                open={openForm}
                width="576px"
                setOpen={setOpenForm}
                title={intl.formatMessage({id: 'personalInfo.connexionInfo.resetPassword.title'})}
            >
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors: Record<string, string> = {}
                        if (!values[MODIFY_PASSWORD_FIELDS.NEW_PASSWORD]) {
                            errors[MODIFY_PASSWORD_FIELDS.NEW_PASSWORD] = intl.formatMessage({id: 'global.error.required'})
                        } else if (!FIELD_PATTERN.PASSWORD.test(values[MODIFY_PASSWORD_FIELDS.NEW_PASSWORD])) {
                            errors[MODIFY_PASSWORD_FIELDS.NEW_PASSWORD] = intl.formatMessage({id: 'global.error.newpasswordbadFormat'})
                        }
                        if (!values[MODIFY_PASSWORD_FIELDS.CONFIRM_PASSWORD]) {
                            errors[MODIFY_PASSWORD_FIELDS.CONFIRM_PASSWORD] = intl.formatMessage({id: 'global.error.required'})
                        } else if (values[MODIFY_PASSWORD_FIELDS.CONFIRM_PASSWORD] !== values[MODIFY_PASSWORD_FIELDS.NEW_PASSWORD]) {
                            errors[MODIFY_PASSWORD_FIELDS.CONFIRM_PASSWORD] = intl.formatMessage({id: 'global.error.passwordsNotMatch'})
                        }

                        return errors
                    }}
                    render={({handleSubmit, pristine}) => (
                        <form onSubmit={handleSubmit}>
                            <Stack direction="column" spacing="24px" pt="8px">
                                <TextInput
                                    type="password"
                                    maxWidth="unset"
                                    name={MODIFY_PASSWORD_FIELDS.NEW_PASSWORD}
                                    label={intl.formatMessage({id: 'personalInfo.connexionInfo.labels.newPassword'})}
                                    helperChecksNeedeed
                                />
                                <TextInput
                                    type="password"
                                    maxWidth="unset"
                                    name={MODIFY_PASSWORD_FIELDS.CONFIRM_PASSWORD}
                                    label={intl.formatMessage({id: 'personalInfo.connexionInfo.labels.confirmNewPassword'})}
                                />
                                <Box display="flex" justifyContent="center">
                                    <ButtonDefault variant="contained" onClick={handleSubmit} disabled={pristine}>
                                        {isLoading ? <CircularProgress size={24} color="inherit"/> :
                                            <FormattedMessage id="global.button.validate"/>}
                                    </ButtonDefault>
                                </Box>
                            </Stack>
                        </form>
                    )}
                />
            </Popup>
        </>
    )
}

export default ResetPassword
