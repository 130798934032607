import affiliation from './affiliation/affiliationsReducers'
import articles from './articles/articleReducers'
import rib from './rib/ribReducers'
import contrats from './contrats/contratsReducers'
import echeancier from './echeancier/echeancierReducers'
import souscription from './souscription/souscriptionReducers'
import remboursements from './remboursements/remboursementsReducers'
import carteTP from './carteTiersPayant/carteTiersPayantReducers'
import contact from './contact/contactReducers'
import demandes from './demandes/demandesReducer'
import repayment from './remboursementsV2/repaymentReducer'
import servicesSante from './servicesSante/servicesSanteReducer'
import navMenu from './navMenu/navMenuReducers'

export const webservicesReducersEA = {
    affiliation,
    articles,
    remboursements,
    repayment,
    souscription,
    demandes,
    contact,
    contrats,
    echeancier,
    rib,
    carteTP,
    servicesSante,
    navMenu
}

export const webservicesReducersEE = {
    navMenu
}
