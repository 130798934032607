import React from 'react'
import { IconButton, InputAdornment, InputBase, Paper } from '@mui/material'
import { Clear, Search } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { SHORTCUT_RACINE_AIDE } from '../services/HelpConstants'


type SearchbarProps = {
	setForSearch: any,
	getFolderContentByShortcut: any,
	rechercheAction: any,
	setIsLoading: any
}

const Searchbar: React.FC<SearchbarProps> = (
	{
		setForSearch,
		getFolderContentByShortcut,
		rechercheAction,
		setIsLoading
	}
) => {
	const intl = useIntl()
	const [values, setValues] = React.useState({
		input: ''
	})

	const handleChange = (prop: any) => (event: any) => {
		setValues({ ...values, [prop]: event.target.value })
	}


	const handleClick = () => {
		setIsLoading(true)
		setValues({ input: '' })
		getFolderContentByShortcut(SHORTCUT_RACINE_AIDE)
		setForSearch(false)
	}

	const handleRechercheClick = () => {
		if (values.input) {
			setIsLoading(true)
			setForSearch(true)
			rechercheAction(values.input)
		} else {
			setForSearch(false)
			setIsLoading(true)
			getFolderContentByShortcut(SHORTCUT_RACINE_AIDE)
		}
	}

	const keyPress = (event: any) => {
		if (event.keyCode === 13) {
			event.preventDefault()
			handleRechercheClick()
		}
	}

	const handleMouseDown = (event: any) => {
		event.preventDefault()
	}

	const endAdornment = () => {
		if (values.input) {
			return (
				<InputAdornment position="end">
					<IconButton
						onClick={handleClick}
						onMouseDown={handleMouseDown}
						color="secondary"
					>
						<Clear color="primary" />
					</IconButton>
				</InputAdornment>
			)
		}

		return ''
	}

	return (
		<Paper
			component="form"
			sx={{
				p: '2px 4px',
				display: 'flex',
				alignItems: 'center',
				width: '100%',
				boxShadow: 'none',
				border: '1px solid #e0e0e0'
			}}
		>
			<IconButton sx={{ p: '10px' }} aria-label="menu" onClick={handleRechercheClick}>
				<Search sx={{ height: '32px', width: '32px' }} color="secondary" />
			</IconButton>
			<InputBase
				onChange={handleChange('input')}
				sx={{ ml: 1, flex: 1 }}
				placeholder={intl.formatMessage({ id: 'help.placeholder' })}
				endAdornment={endAdornment()}
				inputProps={{ style: { textOverflow: 'ellipsis' } }}
				value={values.input}
				onKeyDown={keyPress}
			/>
		</Paper>
	)
}

export default Searchbar
