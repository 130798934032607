import { types } from './demandesActions'
import { combineReducers } from 'redux'
import appConst from '../../../constant/appConstants';

const data = (state = [], action) => {
	switch (action.type) {
		case types.GET_DEMANDES : {
			return action.payload
		}
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case types.GET_DEMANDES_LOADING: {
			return action.payload
		}
		default:
			return state
	}
}

const expirationDate = (state = 0, action) => {
	switch (action.type) {
		case types.GET_DEMANDES: {
			return (+new Date() + appConst.cacheDuration.default * 1000)
		}
		default:
			return state
	}
}

export default combineReducers({
	data,
	loading,
	expirationDate
})

