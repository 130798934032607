import { createImmutableSelector } from '../../../utils/globalUtils';
import { sortDecomptes } from './remboursementsUtils';

/**
 * Tous les remboursements
 */
export const remboursements = (state) => state.remboursements.remboursements

export const getRemboursementsByAnnee = state => state.remboursements.remboursementsByAnnee

export const getRemboursements = annee => state => state.remboursements.remboursementsByAnnee.get(annee)
/**
 * Loading global
 */
export const isLoading = state => !state.remboursements.loading.isEmpty()
/**
 * Loading pour une année en particulier
 */
export const isRemboursementsLoading = annee => state => state.remboursements.loading.has(annee)
/**
 * Date d'expiration pour une année (aka cache)
 */
export const getExpirationDate = annee => state => state.remboursements.cache.get(annee)

export const getPlageDerniersRemboursements = state => state.remboursements.derniersRemboursements.intervalle

/**
 * On préfère récupérer les derniers remboursements depuis la Map complète plutôt que d'interroger
 * la portion de state spécifique (?)
 *
 * @param max le nombre max de remboursements
 */
export const getDerniersRemboursements = (max = 3) => createImmutableSelector(
	[remboursements],
	(remboursements) => (
		remboursements
			.sort(sortDecomptes)
			.take(max)
			.toArray()
	)
)
