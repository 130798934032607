import React from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Theme } from '@mui/material'
import { Field } from 'react-final-form'
import { SxProps } from '@mui/system'

type SelectInputProps = {
	name: string
	label?: string | React.ReactNode
	data: SelectOption[]
	error?: string
	helperText?: string
	validate?: (value: string) => string | undefined
	onChange?: (newValue: any, index?: number) => void
	disabled?: boolean,
	index?: number
	defaultValue?: string
	sxOverright?: SxProps<Theme>
}

const SelectInputRembFilter: React.FC<SelectInputProps> = (
	{
		name,
		label,
		data,
		error,
		helperText,
		validate,
		onChange,
		disabled,
		index,
		defaultValue,
		sxOverright
	}) => {
	return (
		<Field name={name} validate={validate} initialValue={defaultValue}>
			{({ input, meta }) => {
				const handleChange = (newValue: any, index?: number) => {
					input.onChange(newValue)
					onChange && onChange(newValue, index)
				}

				return (
					<FormControl size="small" fullWidth error={!!error || ((meta.error || meta.submitError) && meta.touched)} disabled={disabled}>
						<InputLabel error={!!error || ((meta.error || meta.submitError) && meta.touched)} color="secondary" id="select-input-label">{label}</InputLabel>
						<Select
							label={label}
							labelId="select-input-label"
							fullWidth
							sx={sxOverright ? sxOverright : { minWidth: '160px' }}
							error={!!error || ((meta.error || meta.submitError) && meta.touched)}
							color="secondary"
							size="small"
							value={input.value}
							onChange={(e, newValue: any) => {
								handleChange(newValue.props.value, index)
							}}
						>
							{data.map(({ value, label }) => (
								<MenuItem key={value} value={value} style={{ whiteSpace: 'normal' }}>{label}</MenuItem>
							))}
						</Select>
						<FormHelperText sx={{ marginLeft: '14px' }}>{helperText || error || meta.touched && meta.error}</FormHelperText>
					</FormControl>
				)
			}}
		</Field>
	)
}

export default SelectInputRembFilter
