import React, { Dispatch, SetStateAction } from 'react'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../../../../components/ButtonDefault'
import Popup from '../../../../components/Popup'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {logout} from '../services/preaffiliationActions'



type GoBackAction = {
	title: string
	action: () => void
}

type ConfirmationPopinProps = {
	title: string | React.ReactNode
	body?: React.ReactNode
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	showActions?: boolean
	resetForm?: () => void
	goBackAction?: GoBackAction
	goHomeOnClose?: boolean
	disabled?: boolean
}

const ConfirmationPopin: React.FC<ConfirmationPopinProps> = (
	{
		title,
		open,
		setOpen,
		resetForm,
		showActions = true,
		goBackAction,
		body,
		goHomeOnClose = true,
		disabled,
	}
) => {
	const { isMobile } = useBreakpoints()

	const onGoLogin = () => {
		goHomeOnClose && logout()
	}

	return (
		<Popup open={open} setOpen={setOpen} title={title} showCloseButton={false}>
			<Stack direction="column" spacing="24px">
				{body ||
					<Stack direction="column" spacing="16px">
						<Typography variant="body2">
							<FormattedMessage id="beneficiary.modifyPopin.success" values={{ br: <br /> }} />
						</Typography>
						<Typography variant="body2" fontWeight={500}>
							<FormattedMessage id="beneficiary.modifyPopin.success2"/>
						</Typography>
					</Stack>
				}
				{showActions &&
					<Stack direction={isMobile ? 'column' : 'row'} spacing="10px" justifyContent="space-around">
						{resetForm &&
							<ButtonDefault
								fullWidth={isMobile}
								variant="outlined"
								onClick={() => {
									resetForm()
									setOpen(false)
								}}
							>

								<FormattedMessage id="global.button.newDemand" />
							</ButtonDefault>
						}
						<ButtonDefault
							fullWidth={isMobile}
							variant="contained"
							disabled={disabled}
							onClick={() => {
								goBackAction ? goBackAction.action() : onGoLogin()
								// setOpen(false)
							}}
						>
							{goBackAction ? goBackAction.title : <FormattedMessage id="global.button.goBackToHome" />}
						</ButtonDefault>
					</Stack>
				}
			</Stack>
		</Popup>
	)
}

const actions = {
}

export default compose(
	connect(null, actions)
)(ConfirmationPopin)
