import { types } from './suggestActions'
import { combineReducers } from 'redux'

const pays = (state = {
	pays: [],
	value: ''
}, action) => {
	switch (action.type) {
		case types.GET_PAYS: {
			const { value, pays } = action.payload
			if (!value) {
				return {
					value: '',
					pays: []
				}
			}
			const inputValue = value.trim().toLowerCase()
			const inputLength = inputValue.length

			return {
				...state,
				value,
				pays: inputLength === 0 ? [] : pays.filter(p => p.name.toLowerCase().slice(0, inputLength) === inputValue
			) }
		}
		case types.CLEAR_PAYS: {
			return {
				...state,
				pays: []
			}
		}
		case types.UPDATE_INPUT_VALUE: {
			return {
				...state,
				value: action.payload.value
			}
		}
		default:
			return state
	}
}

export default combineReducers({
	pays
})
