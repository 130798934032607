const SUCCESS = {
	code: 1
}
const NON_VALIDE_RH = {
	code: 2,
	title: 'Affiliation en ligne',
	body: `<p>Vous avez déjà effectué une affiliation en ligne.</p>
	<p>Elle est en cours de validation par votre service du personnel.</p>
	<p>Vous recevrez prochainement un e-mail avec toutes les consignes pour vous
	connecter à votre Espace Assuré Gerep, et compléter si besoin vos données d’affiliation.</p>
`
}
const NON_VALIDE_GEREP = {
	code: 3,
	title: 'Affiliation en ligne',
	body: `<p>Vous avez déjà effectué une affiliation en ligne.</p>
	<p>Elle est en cours de traitement par Gerep. Vous allez rapidement
	recevoir un e-mail avec toutes les consignes pour vous connecter à votre
	Espace Assuré Gerep, et compléter si besoin vos données d’affiliation.</p>
	`
}
const ADHESION_EN_COURS = {
	code: 4,
	title: 'Affiliation en ligne',
	body: `<p>Vous êtes déjà affilié.</p>
	<p>Si vous souhaitez modifier ou compléter votre affiliation (bénéficiaires, coordonnées bancaires, données personnelles, ...), vous devez vous connecter à votre Espace Assuré Gerep.</p>
	`
}
const VALIDATION_MANUELLE_REQUISE = {
	code: 5
}
export default {
	SUCCESS,
	NON_VALIDE_RH,
	NON_VALIDE_GEREP,
	ADHESION_EN_COURS,
	VALIDATION_MANUELLE_REQUISE
}

