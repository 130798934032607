import React, { Dispatch, FC, SetStateAction, useContext, useState } from 'react'
import Popup from '../../../../../components/Popup'
import { FormattedMessage, useIntl } from 'react-intl'
import { CircularProgress, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import FormatUtils from '../../../../../utils/FormatUtils'
import { Form } from 'react-final-form'
import ButtonDefault from '../../../../../components/ButtonDefault'
import Box from '@mui/material/Box'
import TextInput from '../../../../../components/form/TextInput'
import { MODIFY_PERSONAL_INFO_FIELDS } from '../../services/personalInfoConstants'
import DatePickerInput from '../../../../../components/form/DatePickerInput'
import LocalStorage from '../../../../../business/storage/LocalStorage'
import UserService from '../../../../../api/gerep/UserService'
import { ToastContext } from '../../../../../components/toast/ToastContext'
import { ToastType } from '../../../../../components/toast/toastConstants'
import AutocompleteInput from '../../../../../components/form/AutocompleteInput'
import { FormApi } from 'final-form'
import ConfirmationPopin from '../../../../../components/ConfirmationPopin'
import { isValid } from 'date-fns'
import { MAIN_PATH } from '../../../../../constant/path'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { refreshAffiliation } from '../../services/suggest/suggestActions'
import ConfirmationAuthent from '../../../../../components/doubleAuthentComponents/ConfirmationAuthent'

type ModifyPersonalInfoProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	user: any
	pays: Country[]
	doubleAuthent: string
}

const ModifyPersonalInfo: FC<ModifyPersonalInfoProps> = (
	{
		open,
		setOpen,
		user,
		pays,
		doubleAuthent
	}) => {
	const intl = useIntl()
	const history = useHistory()
	const dispatch = useDispatch()
	const { addToast } = useContext(ToastContext)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
	const [openDoubleAuthent, setOpenDoubleAuthent] = useState<boolean>(false)
	const [telephone, setTelephone] = useState<string>('')
	const [warning, setWarning] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [popinData, setPopinData] = useState<{ values: Record<string, any>; form: FormApi<any, any> } | null>(null)

	const formatDate = (date: any) => {
		const formatUtils = FormatUtils.parseDate(date)
		const defaultFormat = new Date(date)

		if (isValid(formatUtils)) {
			return formatUtils
		} else if (isValid(defaultFormat)) {
			return defaultFormat
		} else {
			return new Date()
		}
	}

	const initialValues = {
		[MODIFY_PERSONAL_INFO_FIELDS.NAME]: user?.nom,
		[MODIFY_PERSONAL_INFO_FIELDS.FIRST_NAME]: user?.prenom,
		[MODIFY_PERSONAL_INFO_FIELDS.BIRTH_DATE]: user?.dateNaissance,
		[MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_1]: user?.adresse?.adresse,
		[MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_2]: user?.adresse?.adresse2,
		[MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_3]: user?.adresse?.adresse3,
		[MODIFY_PERSONAL_INFO_FIELDS.POSTAL_CODE]: user?.adresse?.codpos,
		[MODIFY_PERSONAL_INFO_FIELDS.CITY]: user?.adresse?.ville,
		[MODIFY_PERSONAL_INFO_FIELDS.COUNTRY]: user?.adresse?.libPays,
		[MODIFY_PERSONAL_INFO_FIELDS.DATE_OF_ENTRY]: new Date().toLocaleDateString('fr-FR'),
		[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE]: user?.telephone
	}

	const handleSubmit = (values: Record<string, any>, form: FormApi<Record<string, any>, { nom: any; prenom: any; dateNaissance: any; adresse1: any; adresse2: any; adresse3: any; codpos: any; ville: any; libPays: any; dateCompter: any; telephone: any; }>) => {
		const telephoneFieldModified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE)?.modified

		if (doubleAuthent && doubleAuthent.includes('sms') && telephoneFieldModified) {
			setPopinData({ values, form })
			UserService.envoyerCode({ telephone: values[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE] }, LocalStorage.getToken())
				.then(() => setOpenDoubleAuthent(true))
				.catch((error) => {
					if (error?.data) {
						const errorMessages = Object.values(error.data as Record<string, string>)
						errorMessages.forEach(errorMessage => addToast(ToastType.ERROR, errorMessage))
					} else {
						addToast(ToastType.ERROR, 'global.error.occurred')
					}
					setIsLoading(false)
				})
		} else {
			onSubmit(values, form)
		}
	}

	const handlePopinClose = () => {
		setOpenDoubleAuthent(false)
		if (popinData) {
			const { values, form } = popinData
			onSubmit(values, form)
		}
	}

	const onSubmit = (values: Record<string, any>, form: FormApi<Record<string, any>, { nom: any; prenom: any; dateNaissance: any; adresse1: any; adresse2: any; adresse3: any; codpos: any; ville: any; libPays: any; dateCompter: any; telephone: any; }>) => {
		setIsLoading(true)
		// Check if the form fields have been modified
		const isNomModified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.NAME)?.modified
		const isPrenomModified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.FIRST_NAME)?.modified
		const isDateNaissanceModified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.BIRTH_DATE)?.modified
		const isAdresse1Modified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_1)?.modified
		const isAdresse2Modified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_2)?.modified
		const isAdresse3Modified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_3)?.modified
		const isCodposModified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.POSTAL_CODE)?.modified
		const isVilleModified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.CITY)?.modified
		const isLibPaysModified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.COUNTRY)?.modified
		const isTelephoneModified = form.getFieldState(MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE)?.modified

		// The address is a particular case, as it is composed of several fields and the API expects a single object
		const isAddressModified = isAdresse1Modified || isAdresse2Modified || isAdresse3Modified || isCodposModified || isVilleModified || isLibPaysModified
		const changedValues = {
			[MODIFY_PERSONAL_INFO_FIELDS.NAME]: isNomModified ? values[MODIFY_PERSONAL_INFO_FIELDS.NAME] : undefined,
			[MODIFY_PERSONAL_INFO_FIELDS.FIRST_NAME]: isPrenomModified ? values[MODIFY_PERSONAL_INFO_FIELDS.FIRST_NAME] : undefined,
			[MODIFY_PERSONAL_INFO_FIELDS.BIRTH_DATE]: isDateNaissanceModified ? formatDate(values[MODIFY_PERSONAL_INFO_FIELDS.BIRTH_DATE]).toLocaleDateString('fr-FR') : undefined,
			[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE]: isTelephoneModified ? values[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE] : undefined
		}

		// Get the form values
		const {
			// adresse
			adresse1,
			adresse2,
			adresse3,
			codpos,
			ville,
			libPays,
			dateCompter
		} = values

		const formattedDateCompter = formatDate(dateCompter).toLocaleDateString('fr-FR')

		// The API call needs the original values and the modified values
		return UserService.editInformationsPersonnelles({
			values: { ...initialValues, dateCompter: formattedDateCompter, [MODIFY_PERSONAL_INFO_FIELDS.BIRTH_DATE]: formatDate(user?.dateNaissance).toLocaleDateString('fr-FR') },
			modifiedValues: {
				...changedValues,
				// EA311 - il faut toujours envoyer tous les champs de l'adresse
				...(isAddressModified ? {
					adresse1,
					adresse2,
					adresse3,
					codpos,
					ville,
					libPays
				} : null),
				dateCompter: formattedDateCompter
			}
		}, LocalStorage.getToken())
			.then(() => {
				addToast(ToastType.SUCCESS, 'personalInfo.personalData.modifyPersonalInfo.success')
				setOpen(false)
				setOpenConfirmation(true)
			}).catch((error) => {
				if (error.data) {
					const errorMessages = Object.values(error.data as Record<string, string>)
					errorMessages.forEach(errorMessage => addToast(ToastType.ERROR, errorMessage))
				} else {
					addToast(ToastType.ERROR, 'global.error.personInfoFailed')
				}
			})
			.finally(() => setIsLoading(false))
	}

	const required = (value: string) => (value ? undefined : intl.formatMessage({ id: 'global.error.required' }))

	const warningCheck = (value: any) => {
		if (doubleAuthent === 'sms' && user?.telephone && value !== user?.telephone) {
			setWarning(true)
		} else {
			setWarning(false)
		}
	}

	return (
		<>
			<Popup
				maxWidth="600px"
				open={open}
				setOpen={setOpen}
				title={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.title' })}
			>
				<Form
					onSubmit={handleSubmit}
					validate={(values: any) => {
						const errors: any = {}

						if (!/^\d{10}$/.test(values[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE]?.replace(/\s/g, ''))) {
							errors[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE] = intl.formatMessage({ id: 'global.error.invalidPhone' })
						}
						if (!/^0[6-7]/.test(values[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE]?.substring(0, 2))) {
							errors[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE] = intl.formatMessage({ id: 'global.error.invalidCellphone' })
						}

						if ((doubleAuthent === 'sms' && !errors[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE])) {
							warningCheck(values[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE])
						}


						return errors
					}}
					render={({ handleSubmit, pristine }) => {
						return (
							<form onSubmit={handleSubmit}>
								<Stack direction="column" spacing="24px">
									<Stack>
										<Typography variant="body2">
											{`${user?.nom} ${user?.prenom}`}
										</Typography>
										<Typography variant="body2">
											<FormattedMessage id="personalInfo.personalData.modifyPersonalInfo.labels.nass" values={{ num: user?.numIndividu || 0 }} />
										</Typography>
										<Typography variant="body2">
											<FormattedMessage id="personalInfo.personalData.modifyPersonalInfo.labels.nss" values={{ num: FormatUtils.formatNiss(user?.matorg) }} />
										</Typography>
									</Stack>

									<Typography variant="body2">
										<FormattedMessage id="personalInfo.personalData.modifyPersonalInfo.subtitle" />
									</Typography>

									<TextInput
										maxWidth="unset"
										name={MODIFY_PERSONAL_INFO_FIELDS.NAME}
										label={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.labels.nom' })}
										defaultValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.NAME]}
									/>
									<TextInput
										maxWidth="unset"
										name={MODIFY_PERSONAL_INFO_FIELDS.FIRST_NAME}
										label={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.labels.prenom' })}
										defaultValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.FIRST_NAME]}
									/>
									<DatePickerInput
										validate={required}
										maxWidth="unset"
										name={MODIFY_PERSONAL_INFO_FIELDS.BIRTH_DATE}
										label={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.labels.birthdate' })}
										initialValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.BIRTH_DATE]}
									/>
									<TextInput
										validate={required}
										maxWidth="unset"
										name={MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_1}
										label={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.labels.address' })}
										defaultValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_1]}
									/>
									<TextInput
										maxWidth="unset"
										name={MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_2}
										defaultValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_2]}
									/>
									<TextInput
										maxWidth="unset"
										name={MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_3}
										defaultValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.ADDRESS_3]}
									/>
									<TextInput
										validate={required}
										maxWidth="unset"
										name={MODIFY_PERSONAL_INFO_FIELDS.POSTAL_CODE}
										label={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.labels.postalCode' })}
										defaultValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.POSTAL_CODE]}
									/>
									<TextInput
										validate={required}
										maxWidth="unset"
										name={MODIFY_PERSONAL_INFO_FIELDS.CITY}
										label={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.labels.city' })}
										defaultValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.CITY]}
									/>
									<AutocompleteInput
										validate={required}
										name={MODIFY_PERSONAL_INFO_FIELDS.COUNTRY}
										options={pays.map((p) => p.name)}
										label={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.labels.pays' })}
										defaultValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.COUNTRY]}
										maxWidth="unset"
									/>
									<DatePickerInput
										validate={required}
										maxWidth="unset"
										name={MODIFY_PERSONAL_INFO_FIELDS.DATE_OF_ENTRY}
										label={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.labels.dateEntry' })}
										initialValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.DATE_OF_ENTRY]}
									/>
									<TextInput
										maxWidth="unset"
										name={MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE}
										label={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.labels.phone' })}
										defaultValue={initialValues[MODIFY_PERSONAL_INFO_FIELDS.TELEPHONE]}
										onChange={(value) => setTelephone(value)}
									/>

									{
										warning && <Typography variant="subtitle2" sx={{ color: 'red' }}>
											<b>Attention : </b>
											<FormattedMessage id={`personalInfo.personalData.modifyPersonalInfo.warningText.${doubleAuthent}`} />
										</Typography>
									}

									<Typography variant="body2">
										<FormattedMessage id="personalInfo.personalData.modifyPersonalInfo.info" />
									</Typography>

									<Box display="flex" justifyContent="center">
										<ButtonDefault
											type="submit"
											variant="contained"
											onClick={handleSubmit}
											disabled={pristine}
										>
											{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
										</ButtonDefault>
									</Box>
								</Stack>
							</form>
						)
					}}
				/>
			</Popup>
			<ConfirmationPopin
				title={intl.formatMessage({ id: 'personalInfo.personalData.modifyPersonalInfo.title' })}
				open={openConfirmation}
				setOpen={setOpenConfirmation}
				goHomeOnClose={false}
				onCloseAction={() => refreshAffiliation(dispatch)}
				goBackAction={{
					action: () => {
						history.push(MAIN_PATH.DASHBOARD)
						refreshAffiliation(dispatch)
					},
					title: intl.formatMessage({ id: 'global.button.goBackToHome' })
				}}
			/>
			{openDoubleAuthent &&
				<Popup
					open={openDoubleAuthent}
					setOpen={setOpenDoubleAuthent}
					title={intl.formatMessage({ id: 'doubleAuthent.confirmation.title.modification' })}
					width="720px"
					showCloseButton={true}
					onClose={() => setOpenDoubleAuthent(false)}
				>
					<ConfirmationAuthent nextStep={handlePopinClose} currentAuthent={'sms'} telephone={telephone} email={''} modificationMailOuSms />
				</Popup>
			}
		</>
	)
}

export default ModifyPersonalInfo
