import React, { Dispatch, SetStateAction } from 'react'
import { Box, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useBreakpoints } from './breakpoints/BreakpointsProvider'

type RightDrawerProps = {
	children: React.ReactNode
	openModal: boolean
	setOpenModal: Dispatch<SetStateAction<boolean>>
	title: string
}


const RightDrawer: React.FC<RightDrawerProps> = (
	{
		children,
		openModal,
		setOpenModal,
		title
	}) => {

	const { isMobile } = useBreakpoints()

	const StyledDrawer = styled(Drawer)(() => (
		{
			'.MuiDrawer-paper': {
				width: isMobile ? '85vw' : '450px'
			}
		}))

	return (
		<StyledDrawer disableEnforceFocus anchor="right" open={openModal} onClose={() => setOpenModal(false)}>
			<Stack
				height="100%"
				direction="column"
				margin="48px 32px"
			>
				<Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
					<Typography variant="h2">
						{title}
					</Typography>
					<Box>
						<IconButton onClick={() => setOpenModal(false)}>
							<Close />
						</IconButton>
					</Box>
				</Stack>
				<Divider sx={{ marginTop: '24px', marginBottom: '24px', color: '##d6d6d6' }} orientation="horizontal" />
				<Stack width="100%" spacing="24px">
					{children}
				</Stack>
			</Stack>
		</StyledDrawer>
	)
}

export default RightDrawer
