import React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import CloseIcon from '@mui/icons-material/Close'

type FileDisplayProps = {
	name: string
	onRemove: () => void
}

const FileDisplay: React.FC<FileDisplayProps> = ({ name, onRemove }) => {
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			spacing={2}
			bgcolor="#f7f5f4"
			borderRadius="5px"
			width="100%"
		>
			<Stack direction="row" alignItems="center" spacing={1} width="80%">
				<DescriptionOutlinedIcon color="secondary" />
				<Typography noWrap variant="body2" color="black">{name}</Typography>
			</Stack>
			<IconButton onClick={onRemove} size="small" color="secondary">
				<CloseIcon color="secondary" />
			</IconButton>
		</Stack>
	)
}

export default FileDisplay