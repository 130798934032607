import GerepAPI from './GerepApi'

export const identifier = (token, values) => GerepAPI.post('/identifier', values, token)

export const getBeneficiaires = (token, idSociete) => GerepAPI.get(`/contractToSignUp/${idSociete}`, null, token)

export const downloadGaranties = (token, idSociete, params) => GerepAPI.getTicket(`/garanties/download/${idSociete}`, params, token)

export const validerPreAffiliation = (token, values) => GerepAPI.postMultipart('/valider', values, token)

export const changeCarteTPOption = (token, values) => GerepAPI.post('/carteTP/changeOption', values, token)

export const preSetFields = (token, idSociete, numIndiv) => GerepAPI.get(`/prefillfields/${idSociete}/${numIndiv}`, null, token)

export const downloadAttestationDispense = (token, values) => GerepAPI.getTicket('/downloadAttestationDispense', values, token)
