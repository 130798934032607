import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import { connect } from 'react-redux'
import appConst from '../../../../../constant/appConstants'
import Popup from '../../../../../components/Popup'
import { Typography } from '@mui/material'

type DeleteRibPopinProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}

const ARTICLE_SHORTCUT = 'confirm_delete_rib'

const ConfirmDeleteRibPopin: React.FC<DeleteRibPopinProps> = (
	{
		open,
		setOpen,
		// @ts-ignore
		confirmDelete,
		// @ts-ignore
		getArticleByShortcut
	}
) => {
	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_SHORTCUT)
	}, [])

	return (
		<Popup open={open} setOpen={setOpen} title={confirmDelete && confirmDelete.fields.title}>
			<Typography variant="body2" dangerouslySetInnerHTML={{ __html: confirmDelete && confirmDelete.fields.body }} />
		</Popup>
	)
}

const mapStateToProps = (state: any) => ({
	confirmDelete: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_SHORTCUT]
})

export default connect(
	mapStateToProps,
	{
		getArticleByShortcut: cardiboxActions.articles.getByShortcut
	}
)(ConfirmDeleteRibPopin)