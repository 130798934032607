import React from 'react'
import {Grid, Switch, SwitchProps} from '@mui/material'
import {styled} from '@mui/material/styles'


type TypeDataSelectorProps = {
	name: string
	onChange?: () => void
	stateValue: boolean
	label1: string
	label2: string
}

const StyledTypeData = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : theme.palette.secondary.main,
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22,
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}))

const TypeDataSelector : React.FC<TypeDataSelectorProps> = (
	{
		name,
		onChange,
		stateValue,
		label1,
		label2
	}) => {

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		if (checked !== null) {
			onChange && onChange()
		}
	}

	return (
		<Grid component="label" container justifyContent="center" alignItems="center" flex="1" spacing={1}>
			<Grid
				item
				sx={(theme) => ({
					color: !stateValue ? theme.palette.secondary.main : '#232323' ,
					fontWeight: !stateValue ? 600 : 500,
					fontSize: '0.875rem'
				})}
			>
				{label1}
			</Grid>

			<Grid item>
				<StyledTypeData
					checked={stateValue} // relevant state for your case
					onChange={handleChange} // relevant method to handle your change
					name={name}
				/>
			</Grid>

			<Grid
				item
				sx={(theme) => ({
					color: !stateValue ? '#232323' : theme.palette.secondary.main,
					fontWeight: !stateValue ? 500 : 600,
					fontSize: '0.875rem'
				})}
			>
				{label2}
			</Grid>
		</Grid>
	)
}

export default TypeDataSelector
