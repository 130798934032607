import React, { useContext, useState } from 'react'
import arrayMutators from 'final-form-arrays'
import { DEMAND_REFUND_DATA, DEMAND_REFUND_DEMAND_TYPES, DEMAND_REFUND_FIELDS, DEMAND_REFUND_TYPES } from '../services/demandRefundConstants'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import ToggleInput from '../../../../components/form/ToggleInput'
import { FormattedMessage, useIntl } from 'react-intl'
import SelectInput from '../../../../components/form/SelectInput'
import RadioInput from '../../../../components/form/RadioInput'
import FileInput from '../../../../components/fileInput/FileInput'
import DetailsSoinArrayFields from './DetailsSoinArrayFields'
import Popover from '../../../../components/Popover'
import { mdiInformation } from '@mdi/js'
import ButtonDefault from '../../../../components/ButtonDefault'
import { Form } from 'react-final-form'
import { ToastContext } from '../../../../components/toast/ToastContext'
import { destructSelectedType, validateDetailsSoins } from '../services/demandRefundUtils'
import { ToastType } from '../../../../components/toast/toastConstants'
import LocalStorage from '../../../../business/storage/LocalStorage'
import RemboursementsService from '../../../../api/gerep/RemboursementsService'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

type DemandRefundFormProps = {
	individuList: any[]
	snackbarInfo: any
}

const MEDECINE_INFO = 'A l\'aide du [+], renseignez autant de lignes que de soins indiqués sur la (ou les) facture(s) que vous nous transmettez.'

// La question est inversée par rapport au webservice
const YES_OR_NO_DATA = [
	{
		value: 'non',
		label: 'Oui'
	},
	{
		value: 'oui',
		label: 'Non'
	}
]

//todo toast handler for info

const DemandRefundForm: React.FC<DemandRefundFormProps> = (
	{
		individuList,
		snackbarInfo
	}
) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)
	const { isMobile, isTabletPortrait } = useBreakpoints()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
	const [errorValue, setErrorValue] = useState<string>('')

	const TYPE_DATA: SelectOption[] = [
		{
			label: intl.formatMessage({ id: 'demandRefund.input.type.bill' }),
			value: DEMAND_REFUND_TYPES.BILL
		},
		{
			label: intl.formatMessage({ id: 'demandRefund.input.type.other' }),
			value: DEMAND_REFUND_TYPES.OTHER
		},
		{
			label: intl.formatMessage({ id: 'demandRefund.input.type.ss' }),
			value: DEMAND_REFUND_TYPES.SS_NUMBER
		}
	]

	const validate = (values: any) => {
		const errors: any = {}
		if (!values[DEMAND_REFUND_FIELDS.TYPE]) {
			errors[DEMAND_REFUND_FIELDS.TYPE] = intl.formatMessage({ id: 'global.error.required' })
		} else if (values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.BILL) {
			if (!values[DEMAND_REFUND_FIELDS.BENEFICIARY]) {
				errors[DEMAND_REFUND_FIELDS.BENEFICIARY] = intl.formatMessage({ id: 'global.error.required' })
			}

			if (!values[DEMAND_REFUND_FIELDS.MUTUELLE]) {
				errors[DEMAND_REFUND_FIELDS.MUTUELLE] = intl.formatMessage({ id: 'global.error.required' })
			}

			if (values[DEMAND_REFUND_FIELDS.DEMAND]) {
				const selectedDemande = DEMAND_REFUND_DATA.find(demande => demande.value === values[DEMAND_REFUND_FIELDS.DEMAND])
				if (selectedDemande && selectedDemande.entries) {
					if (!values[DEMAND_REFUND_FIELDS.HEALING_DETAILS]) {
						errors[DEMAND_REFUND_FIELDS.HEALING_DETAILS] = intl.formatMessage({ id: 'global.error.required' })
					}
				} else if (selectedDemande && selectedDemande.value === DEMAND_REFUND_DEMAND_TYPES.MEDECINE_DOUCE) {
					errors[DEMAND_REFUND_FIELDS.HEALING_DETAILS_ARRAY] = []
					validateDetailsSoins(values, errors)
				}
			}
			if (!values[DEMAND_REFUND_FIELDS.FILES] || values[DEMAND_REFUND_FIELDS.FILES]?.length === 0) {
				errors[DEMAND_REFUND_FIELDS.FILES] = intl.formatMessage({ id: 'global.error.required' })
			}
		} else if (values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.OTHER) {
			if (!values[DEMAND_REFUND_FIELDS.FILES_OTHER] || values[DEMAND_REFUND_FIELDS.FILES_OTHER]?.length === 0) {
				errors[DEMAND_REFUND_FIELDS.FILES_OTHER] = intl.formatMessage({ id: 'global.error.required' })
			}
		} else if (values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.SS_NUMBER) {
			if (!values[DEMAND_REFUND_FIELDS.FILES_SS] || values[DEMAND_REFUND_FIELDS.FILES_SS]?.length === 0) {
				errors[DEMAND_REFUND_FIELDS.FILES_SS] = intl.formatMessage({ id: 'global.error.required' })
			}
		}


		return errors
	}

	const onSubmit = (values: any) => {
		const {
			type,
			beneficiaire,
			mutuelle,
			mutuelleFile,
			demande,
			files,
			filesSS,
			filesOther,
			detailsSoins,
			detailsSoinsArray
		} = values
		const personneConcernee = individuList && individuList.find(i => i[1].numIndividu === beneficiaire)
		const nomPersonneConcernee = personneConcernee && `${personneConcernee[1].prenom} ${personneConcernee[1].nom}`

		const filesToSend = type === DEMAND_REFUND_TYPES.BILL ? files : type === DEMAND_REFUND_TYPES.SS_NUMBER ? filesSS : filesOther

		const mutuelleBody = mutuelle === 'oui' ? [{
			key: 'mutuelleFile',
			value: mutuelleFile[0]
		}] : []

		const detailsSoinBody = demande === DEMAND_REFUND_DEMAND_TYPES.MEDECINE_DOUCE ? {
			detailsSoins: detailsSoinsArray.map((detail: any) => ({
				...detail,
				date: new Date(detail.date).toLocaleDateString('fr-FR'),
				type: destructSelectedType(detail.type)
			}))
		} : {}

		const soinsBody = demande !== DEMAND_REFUND_DEMAND_TYPES.MEDECINE_DOUCE ? {
			soins: detailsSoins
		} : {}

		const body = [{
			key: 'demande',
			value: {
				type,
				beneficiaire,
				nomBeneficiaire: nomPersonneConcernee,
				mutuelle: mutuelle === 'oui',
				demande,
				...soinsBody,
				...detailsSoinBody
			}
		},
			...filesToSend.map((file: any, index: number) => ({
				key: `file${index}`,
				value: file
			})),
			...mutuelleBody]

		setIsLoading(true)
		return RemboursementsService.demandeRemboursement(LocalStorage.getToken(), body)
			.then(() => {
				addToast(ToastType.SUCCESS, 'global.success' )
				setOpenConfirmation(true)
			})
			// Echec de l'envoi de demande de remboursement
			.catch((e) => {
				if (e?.data?._error) {
					// addToast(ToastType.ERROR, '', e?.data?._error)
					setErrorValue(e?.data?._error)
					setOpenConfirmation(true)
				} else if (e?.data) {
					return e.data
				} else {
					addToast(ToastType.ERROR, '', 'Echec de l\'envoi de la demande de remboursement')
				}
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<Form
			onSubmit={onSubmit}
			mutators={{
				...arrayMutators
			}}
			validate={validate}
			render={(
				{
					handleSubmit,
					values,
					form: {
						mutators: { push },
						reset,
						change
					},
					errors,
					touched
				}) => {
				const isSelectedValueDetailed = values[DEMAND_REFUND_FIELDS.DEMAND] === DEMAND_REFUND_DEMAND_TYPES.OPTIQUE ||
					values[DEMAND_REFUND_FIELDS.DEMAND] === DEMAND_REFUND_DEMAND_TYPES.DENTAIRE ||
					values[DEMAND_REFUND_FIELDS.DEMAND] === DEMAND_REFUND_DEMAND_TYPES.HOSPITALISATION ||
					values[DEMAND_REFUND_FIELDS.DEMAND] === DEMAND_REFUND_DEMAND_TYPES.SOINS_EXTERNE

				const getFilesMessage = () => {
					if (values[DEMAND_REFUND_FIELDS.TYPE]) {
						if (values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.BILL) {
							if (isSelectedValueDetailed) {
								if (values[DEMAND_REFUND_FIELDS.HEALING_DETAILS]) {
									return selectedData?.entries?.find((entry: any) => entry.value === values[DEMAND_REFUND_FIELDS.HEALING_DETAILS])?.help
								} else {
									return ''
								}
							} else {
								return selectedData?.help || ''
							}
						} else if (values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.OTHER) {
							return intl.formatMessage({ id: 'demandRefund.input.files.releveComp' })
						} else {
							return intl.formatMessage({ id: 'demandRefund.input.files.releveSS' })
						}
					}

					return ''
				}

				const selectedData = DEMAND_REFUND_DATA.find((data) => data.value === values[DEMAND_REFUND_FIELDS.DEMAND])

				const displayHealingDetails = values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.BILL && selectedData && isSelectedValueDetailed
				const displayFileInput = (values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.BILL ||
						values[DEMAND_REFUND_FIELDS.TYPE] !== DEMAND_REFUND_TYPES.BILL) &&
					(values[DEMAND_REFUND_FIELDS.TYPE] !== DEMAND_REFUND_TYPES.BILL ||
						(values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.BILL &&
							values[DEMAND_REFUND_FIELDS.DEMAND] && (
								(isSelectedValueDetailed && values[DEMAND_REFUND_FIELDS.HEALING_DETAILS] &&
									// Check if the current selected details is in the available ones, which changes depending on the selected demand
									selectedData && !!selectedData?.entries?.find((data) => data.value === values[DEMAND_REFUND_FIELDS.HEALING_DETAILS])
								) ||
								(!isSelectedValueDetailed)
							)
						)
					)
				const filesMessage = getFilesMessage()

				return (
					<form onSubmit={handleSubmit}>
						<Stack direction="column" spacing="24px">
							<ToggleInput
								name={DEMAND_REFUND_FIELDS.TYPE}
								data={TYPE_DATA}
								label={
									<Typography variant="body2">
										<FormattedMessage id="demandRefund.input.type.label" />
										<span> *</span>
									</Typography>
								}
								onChange={(value) => {
									if (value === DEMAND_REFUND_TYPES.BILL) {
										addToast(ToastType.INFO, '', (
											<div dangerouslySetInnerHTML={{ __html: snackbarInfo.fields.body }} />
										))
									}
									change(DEMAND_REFUND_FIELDS.DEMAND, undefined)
									change(DEMAND_REFUND_FIELDS.HEALING_DETAILS_ARRAY, undefined)
									change(DEMAND_REFUND_FIELDS.FILES, undefined)
									change(DEMAND_REFUND_FIELDS.MUTUELLE, undefined)
									change(DEMAND_REFUND_FIELDS.BENEFICIARY, undefined)
									change(DEMAND_REFUND_FIELDS.HEALING_DETAILS, undefined)
									change(DEMAND_REFUND_FIELDS.MUTUELLE_FILE, undefined)
									change(DEMAND_REFUND_FIELDS.HEALING, undefined)
								}}
							/>

							{values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.BILL && (
								<>
									<SelectInput
										name={DEMAND_REFUND_FIELDS.BENEFICIARY}
										data={individuList.map((individu: any) => ({ label: `${individu[1].prenom} ${individu[1].nom}`, value: individu[1].numIndividu }))}
										defaultValue={individuList.length === 1 ? individuList[0][1].numIndividu : undefined}
										label={
											<Typography variant="body2">
												<FormattedMessage id="demandRefund.input.beneficiary.label" />
												<span> *</span>
											</Typography>
										}
										helperText={intl.formatMessage({ id: 'demandRefund.input.beneficiary.helper' })}
									/>

									{values[DEMAND_REFUND_FIELDS.BENEFICIARY] !== undefined && (
										<>
											<RadioInput
												name={DEMAND_REFUND_FIELDS.MUTUELLE}
												label={
													<Typography variant="body2">
														<FormattedMessage id="beneficiary.addBeneficiary.labels.isGerepFirstInsurance" />
														<span> *</span>
													</Typography>
												}
												data={YES_OR_NO_DATA}
												direction="row"
												error={touched && touched[DEMAND_REFUND_FIELDS.MUTUELLE] && errors && errors[DEMAND_REFUND_FIELDS.MUTUELLE]}
											/>
											{values[DEMAND_REFUND_FIELDS.MUTUELLE] === 'oui' && (
												<FileInput
													maxFileNumber={1}
													name={DEMAND_REFUND_FIELDS.MUTUELLE_FILE}
													title={intl.formatMessage({ id: 'demandRefund.input.mutuelleFile.title' })}
													label={intl.formatMessage({ id: 'demandRefund.input.mutuelleFile.label' })}
													required
													error={errors && errors[DEMAND_REFUND_FIELDS.MUTUELLE_FILE]}
												/>
											)}
											<SelectInput
												name={DEMAND_REFUND_FIELDS.DEMAND}
												data={DEMAND_REFUND_DATA.map((demand: any) => ({ label: demand.label, value: demand.value }))}
												label={intl.formatMessage({ id: 'demandRefund.input.demand.label' })}
												helperText={intl.formatMessage({ id: 'demandRefund.input.demand.helper' })}
												onChange={(value) => {
													if (value === DEMAND_REFUND_DEMAND_TYPES.MEDECINE_DOUCE) {
														addToast(ToastType.INFO, '', MEDECINE_INFO)
													}
												}}
										mandatory
											/>
										</>
									)}
								</>
							)}

							{displayHealingDetails && (
								<RadioInput
									name={DEMAND_REFUND_FIELDS.HEALING_DETAILS}
									data={selectedData?.entries?.map((entry: any) => ({ label: entry.label, value: entry.value })) || []}
									label={intl.formatMessage({ id: 'demandRefund.input.healingDetails.label' })}
									error={touched && touched[DEMAND_REFUND_FIELDS.HEALING_DETAILS] && errors && errors[DEMAND_REFUND_FIELDS.HEALING_DETAILS]}
								/>
							)}

							{values[DEMAND_REFUND_FIELDS.DEMAND] === DEMAND_REFUND_DEMAND_TYPES.MEDECINE_DOUCE && (
								<DetailsSoinArrayFields
									addField={() => push(DEMAND_REFUND_FIELDS.HEALING_DETAILS_ARRAY, undefined)}
									selectedBenef={values[DEMAND_REFUND_FIELDS.BENEFICIARY]}
								/>
							)}

							{values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.BILL && displayFileInput && (
								<FileInput
									name={DEMAND_REFUND_FIELDS.FILES}
									title={filesMessage}
									label={
										<>
											<FormattedMessage id="form.fileinput.content" />
											{/*// EAREA863 'enlever l'infobulle sur cette page, quel que soit le choix dans "Votre demande concerne", et d'enlever également au niveau des pièces*/}
											{/*// justificatives demandées dans l'upload de fichier la partie "et relevé de remboursement de la Sécurité Sociale en cas de non télétransmission".*/}
											{/*{(selectedData?.entries?.find((entry: any) => entry.value === values[DEMAND_REFUND_FIELDS.HEALING_DETAILS])?.infobulle ||*/}
											{/*		selectedData?.infobulle) &&*/}
											{/*	<Popover iconPath={mdiInformation}>*/}
											{/*		<Typography variant="subtitle2">*/}
											{/*			{*/}
											{/*				selectedData?.entries?.find((entry: any) => entry.value === values[DEMAND_REFUND_FIELDS.HEALING_DETAILS])?.infobulle ||*/}
											{/*				selectedData?.infobulle || ''*/}
											{/*			}*/}
											{/*		</Typography>*/}
											{/*	</Popover>*/}
											{/*}*/}
										</>
									}
									required
									error={errors && errors[DEMAND_REFUND_FIELDS.FILES]}
								/>
							)}
							{values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.OTHER && (
								<FileInput
									name={DEMAND_REFUND_FIELDS.FILES_OTHER}
									title={filesMessage}
									label={
										<>
											<FormattedMessage id="form.fileinput.content" />
											{(selectedData?.entries?.find((entry: any) => entry.value === values[DEMAND_REFUND_FIELDS.HEALING_DETAILS])?.infobulle ||
													selectedData?.infobulle) &&
												<Popover iconPath={mdiInformation}>
													<Typography variant="subtitle2">
														{
															selectedData?.entries?.find((entry: any) => entry.value === values[DEMAND_REFUND_FIELDS.HEALING_DETAILS])?.infobulle ||
															selectedData?.infobulle || ''
														}
													</Typography>
												</Popover>
											}
										</>
									}
									required
									error={errors && errors[DEMAND_REFUND_FIELDS.FILES_OTHER]}
								/>
							)}
							{values[DEMAND_REFUND_FIELDS.TYPE] === DEMAND_REFUND_TYPES.SS_NUMBER && (
								<FileInput
									name={DEMAND_REFUND_FIELDS.FILES_SS}
									title={filesMessage}
									label={
										<>
											<FormattedMessage id="form.fileinput.content" />
											{(selectedData?.entries?.find((entry: any) => entry.value === values[DEMAND_REFUND_FIELDS.HEALING_DETAILS])?.infobulle ||
													selectedData?.infobulle) &&
												<Popover iconPath={mdiInformation}>
													<Typography variant="subtitle2">
														{
															selectedData?.entries?.find((entry: any) => entry.value === values[DEMAND_REFUND_FIELDS.HEALING_DETAILS])?.infobulle ||
															selectedData?.infobulle || ''
														}
													</Typography>
												</Popover>
											}
										</>
									}
									required
									error={errors && errors[DEMAND_REFUND_FIELDS.FILES_SS]}
								/>
							)}
							<Stack alignItems="center">
								<ButtonDefault
									variant="contained"
									onClick={handleSubmit}
									type="submit"
								>
									{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
								</ButtonDefault>
								<Box display="flex" width="100%" flex={1} alignSelf={isMobile || isTabletPortrait ? 'unset' : 'baseline'}>
									<Typography
										sx={{
											position: isMobile || isTabletPortrait ? 'unset' : 'relative',
											bottom: '32px',
											left: 0,
											height: '32px',
											display: 'flex',
											alignItems: 'flex-end'
										}}
										variant="body2"
										color="#7a7a7a"
									>
										<FormattedMessage id="global.mandatory" />
									</Typography>
								</Box>
							</Stack>
							<ConfirmationPopin
								title={intl.formatMessage({ id: 'demandRefund.title' })}
								open={openConfirmation}
								setOpen={setOpenConfirmation}
								body={errorValue ?
									<Stack direction="column" spacing="16px">
										<Typography variant="body2">
											Nous ne pouvons pas donner suite à votre demande.
										</Typography>
										<Typography variant="body2">
											{errorValue}
										</Typography>
									</Stack> :
									undefined
								}
								resetForm={() => {
									reset()
									setErrorValue('')
								}}
							/>
						</Stack>
					</form>
				)
			}}
		/>
	)
}

export default DemandRefundForm
