import React, {useLayoutEffect} from 'react'
import {Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5radar from '@amcharts/amcharts5/radar'
import * as am5xy from '@amcharts/amcharts5/xy'
import EmptyResult from '../../components/EmptyResult'

type RadialBarChartProps = {
    isInt: boolean
    id_title: string | React.ReactNode | any
    idChart: string
    annee: string
    middleLabel: string
    dataChart: any[]
}

const RadialBarChart: React.FC<RadialBarChartProps> = (
    {
        isInt,
        id_title,
        idChart,
        middleLabel,
        dataChart
    }
) => {
    const intl = useIntl()
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const isSmallScreen = isMobile || isTabletPortrait

    const validRadialBarData = (data: any) => {
        return (data[0].value !== 0 || data[1].value !== 0)
    }

    useLayoutEffect(() => {
        if (dataChart && validRadialBarData(dataChart)) {
            let root = am5.Root.new(idChart)
            root.setThemes([am5themes_Animated.new(root)])
            root.numberFormatter.set("intlLocales", "fr-FR");
            if (!isInt) {
                root.numberFormatter.set("numberFormat", {
                    minimumFractionDigits: 2
                })
            } else {
                root.numberFormatter.set("numberFormat", {
                    minimumFractionDigits: 0
                })
            }
            // @ts-ignore
            root._logo.dispose()

            let chart = root.container.children.push(
                am5radar.RadarChart.new(root, {
                    panX: false,
                    panY: false,
                    innerRadius: am5.percent(50),
                    startAngle: -90,
                    endAngle: 180,
                })
            )

            let label = chart.radarContainer.children.push(
                am5.Label.new(root, {
                    centerX: am5.percent(50),
                    textAlign: "center",
                    centerY: am5.percent(50),
                    fontSize: 20
                })
            )

            let data = [{
                category: intl.formatMessage({id: dataChart[1].title}),
                value: dataChart[1].value,
                full: dataChart[0].value,
                columnSettings: {
                    fill: am5.color(dataChart[1].color)
                }
            }, {
                category: intl.formatMessage({id: dataChart[0].title}),
                value: dataChart[0].value,
                full: dataChart[0].value,
                columnSettings: {
                    fill: am5.color(dataChart[0].color)
                }
            }]

            let xRenderer = am5radar.AxisRendererCircular.new(root, {})

            xRenderer.labels.template.setAll({
                radius: 10
            })

            xRenderer.grid.template.setAll({
                forceHidden: true
            })

            let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                renderer: xRenderer,
                min: 0,
                max: dataChart[0].value,
                strictMinMax: true,
                visible: false,
                tooltip: am5.Tooltip.new(root, {})
            }))

            let yRenderer = am5radar.AxisRendererRadial.new(root, {
                minGridDistance: 20
            })

            yRenderer.labels.template.setAll({
                centerX: am5.p100,
                fontWeight: "500",
                fontSize: 18,
                templateField: "columnSettings",
                visible: !isSmallScreen
            })

            yRenderer.grid.template.setAll({
                forceHidden: true
            })

            let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: yRenderer,
            }))

            yAxis.data.setAll(data)

            let series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                clustered: false,
                valueXField: "full",
                categoryYField: "category",
                fill: root.interfaceColors.get("alternativeBackground")
            }))

            series1.columns.template.setAll({
                width: am5.p100,
                fillOpacity: 0.08,
                strokeOpacity: 0,
                cornerRadius: 20
            })

            series1.data.setAll(data)

            let series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                clustered: false,
                valueXField: "value",
                categoryYField: "category"
            }))

            series2.columns.template.setAll({
                width: am5.p100,
                strokeOpacity: 0,
                tooltipText: isInt? "{category}: {valueX} " + dataChart[0].unite : "{category}: {valueX.formatNumber(\'#.## a\')} " + dataChart[0].unite,
                cornerRadius: 20,
                templateField: "columnSettings"
            })

            series2.data.setAll(data)

            series1.appear(1000)
            series2.appear(1000)
            chart.appear(1000, 100)
            label.set("text", middleLabel)

            return () => root.dispose()
        }

    }, [dataChart, idChart, isSmallScreen])

    const titleGraph = () => {
        return (
            <Typography
                textAlign="left"
                variant="h2"
                fontSize="1.125rem"
                dangerouslySetInnerHTML={{__html: id_title}}
            />
        )
    }

    if (!dataChart || (dataChart && !validRadialBarData(dataChart))) {
        return (
            <Stack direction="column" spacing="24px" flex="1">
                {titleGraph()}
                <Stack alignItems="center" direction="column">
                    <EmptyResult
                        nature="GRAPH"
                        message={
                            <FormattedMessage
                                id="global.noGraphData"
                                values={{
                                    titreGraph:
                                        <Typography
                                            variant="body2"
                                            fontSize="0,875rem"
                                            fontWeight="bold"
                                            color="#929292"
                                            dangerouslySetInnerHTML={{__html: id_title}}
                                        />
                                }}
                            />
                        }
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column" flex="1">
            {titleGraph()}
            <div
                id={idChart}
                style={{
                    width: '100%',
                    height: isSmallScreen ? '400px' : '500px'
                }}
            />
        </Stack>
    )
}

export default RadialBarChart
