import {types} from '../services/preaffiliationActions'


export default (state = {}, action) => {
	switch (action.type) {
		case types.LOG_USER: {
			if (action.payload) {
				return action.payload.user
			}
			return state
		}
		default:
			return state
	}
}
