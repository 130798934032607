import NavMenuService from '../../../api/gerep/NavMenuService'
import LocalStorage from '../../../business/storage/LocalStorage'
import {isNavMenuLoading} from './navMenuSelector'

const ACTION_NAMESPACE = '/NAV_MENU'

export const types = {
    GET_NAV_MENU: `${ACTION_NAMESPACE}/GET_NAV_MENU`,
    GET_NAV_MENU_LOADING: `${ACTION_NAMESPACE}/GET_NAV_MENU_LOADING`
}

export const getMenu = (shortcut) => (dispatch, getState) => {
    if (!isNavMenuLoading(getState())) {
        dispatch({type: types.GET_NAV_MENU_LOADING, payload: true})
        return NavMenuService.getNavMenu(LocalStorage.getToken(), {shortcut: shortcut})
            .then((navMenu) => {
                dispatch({type: types.GET_NAV_MENU_LOADING, payload: false})
                return dispatch({
                    type: types.GET_NAV_MENU,
                    payload: navMenu
                })
            }).catch((e) => {
                dispatch({type: types.GET_NAV_MENU_LOADING, payload: false})
                throw e
            })
    }
}
