import React from 'react'
import {FormControl, InputLabel, MenuItem, Select, Stack} from '@mui/material'
import {useBreakpoints} from "../../../components/breakpoints/BreakpointsProvider";


type EntrepriseSelectorProps = {
	datas: any
	onChange?: (newValue: any) => void
	stateValue: string
	label: string
}

const EntrepriseSelector : React.FC<EntrepriseSelectorProps> = (
	{
		datas,
		onChange,
		stateValue,
		label
	}) => {

	const { isMobile } = useBreakpoints()

		const handleChange = (value: string | null) => {
			if (value !== null) {
				onChange && onChange(value)
			}
		}

		return (
			<Stack direction="column" spacing="12px" width="100%">
				<FormControl  color="primary" size="small" fullWidth>
					<InputLabel
						id="select-input-label"
						sx={{
							px: "4px",
							background: "#f7f5f4",
							borderRadius: "24px 24px 24px 24px",
							'&.Mui-focused': {
								color: "black"
							}
						}}
					>
						{label}
					</InputLabel>

					<Select
						label={label}
						labelId="select-input-label"
						fullWidth
						sx={(theme) => ({
							width: isMobile ? undefined : '350px',
							height: "36px",
							padding: "4px 8px 4px 24px",
							background: "#ffffff",
							color: "#004687",
							borderColor: "#004687",
							borderWidth: "1px",
							borderStyle: "solid",
							borderRadius: "24px 24px 24px 24px",
							fontWeight: "600",
							fontSize: "18px",
							lineHeight: "1.3",
							textAlign: "left",
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: theme.palette.secondary.main
							}
						})}
						MenuProps={{
							sx:{
								"&& .Mui-selected": {
									'&, &:hover': {
										backgroundColor: 'rgba(0, 70, 135, 0.15)' // TODO ?
									}
								}
							}
						}}
						value={stateValue}
						onChange={(e, newValue: any) => {
							handleChange(newValue.props.value)
						}}

					>
						{datas.map((data: any) => (
							<MenuItem
								key={data.numindiv}
								value={data.numindiv}
								style={{ whiteSpace: 'normal' }}
							>
								{data.nom}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Stack>
		)
}

export default EntrepriseSelector
