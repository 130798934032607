import {createTheme} from '@mui/material'

export class Univers {
    constructor(id, contextPath) {
        this.id = id
        this.contextPath = contextPath
    }

    to(path) {
        return `${this.contextPath}${path}`
    }

    root() {
        return this.contextPath
    }
}

export const UNIVERS_PARTICULIERS = new Univers('particuliers', '/particuliers')

export const UNIVERS_ENTREPRISES = new Univers('entreprises', '/entreprises')

export const UNIVERS_ENTREPRISES_KPI = new Univers('entreprisesKPI', '/entreprises/kpi')

export const UNIVERS_PREAFFILIATIONS = new Univers('preaffiliations', '/affiliations')

export const getUnivers = (id) => {
    if (id === UNIVERS_ENTREPRISES.id) {
        return UNIVERS_ENTREPRISES
    } else if (id === UNIVERS_ENTREPRISES_KPI.id) {
        return UNIVERS_ENTREPRISES_KPI
    }
    // fallback
    return UNIVERS_PARTICULIERS
}

export const themeEA = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1440
        }
    },
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: '#004687'
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#e96e11'
        },
        error: {
            // This is the color of the error messages.
            main: '#fc454a'
        },
        info: {
            // This is the color of the info messages.
            main: '#6a91b6'
        },
        success: {
            // This is the color of the success messages.
            main: '#8ac11d'
        }
    },
    // Name of the component
    components: {
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    borderRadius: 24,
                    height: 36,
                    textTransform: 'none',
                    boxShadow: 'none',
                    '&.contained': {
                        color: 'white'
                    },
                    '&.outlined': {
                        color: '#004687',
                        border: '2px solid #004687'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontSize: '18px',
                    fontWeight: 600,
                    height: '70px'
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                action: {
                    margin: 0
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: '#e96e11'
                    },
                    '&.Mui-completed': {
                        color: '#e96e11'
                    }
                }
            }
        }
    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        h1: {
            fontSize: '1.5rem',
            fontWeight: 500,
            color: '#232323'
        },
        h2: {
            fontSize: '1.125rem',
            fontWeight: 600,
            color: '#232323'
        },
        h3: {
            fontSize: '1rem',
            fontWeight: 600,
            color: '#232323'
        },
        h4: {
            fontSize: '0.875rem',
            fontWeight: 600,
            color: '#232323'
        },
        subtitle1: {
            fontSize: '0.8125rem',
            fontWeight: 400,
            color: '#7a7a7a'
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 500,
            color: '#7a7a7a'
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 500,
            color: '#232323'
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            color: '#232323'
        }
    }
})
export const themeEE = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1440
        }
    },
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: '#e96e11'
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#004687'
        },
        error: {
            // This is the color of the error messages.
            main: '#fc454a'
        },
        info: {
            // This is the color of the info messages.
            main: '#6a91b6'
        },
        success: {
            // This is the color of the success messages.
            main: '#8ac11d'
        }
    },
    // Name of the component
    components: {
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    borderRadius: 24,
                    height: 36,
                    textTransform: 'none',
                    boxShadow: 'none',
                    '&.contained': {
                        color: 'white'
                    },
                    '&.outlined': {
                        color: '#004687',
                        border: '2px solid #004687'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontSize: '18px',
                    fontWeight: 600,
                    height: '70px'
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                action: {
                    margin: 0
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: '#e96e11'
                    },
                    '&.Mui-completed': {
                        color: '#e96e11'
                    }
                }
            }
        }
    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        h1: {
            fontSize: '1.5rem',
            fontWeight: 500,
            color: '#232323'
        },
        h2: {
            fontSize: '1.125rem',
            fontWeight: 600,
            color: '#232323'
        },
        h3: {
            fontSize: '1rem',
            fontWeight: 600,
            color: '#232323'
        },
        h4: {
            fontSize: '0.875rem',
            fontWeight: 600,
            color: '#232323'
        },
        subtitle1: {
            fontSize: '0.8125rem',
            fontWeight: 400,
            color: '#7a7a7a'
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 500,
            color: '#7a7a7a'
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 500,
            color: '#232323'
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            color: '#232323'
        }
    }
})
