import { createContext } from 'react'

type RefreshContextProps = {
	refresh: () => void
	refreshing: boolean
}

const context: RefreshContextProps = {
	refresh: () => {
	},
	refreshing: false
}

export const RefreshContext = createContext(context)