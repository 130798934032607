import React, { useMemo, useState } from 'react'
import { Stack, Typography, Grid, CircularProgress } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../../../../components/ButtonDefault'
import BeneficiaryAvatar from '../../../../components/BeneficiaryAvatar'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

type PaymentCardCellProps = {
	startingDate: string
	endDate: string
	beneficiaries: Record<string, any>[]
	allBeneficiaries: any[]
	downloadCarteTP: any
	numIndividu: string
	openNoCard: () => void
}

/**
 * Le format de la carte TP à télécharger.
 */
const FORMAT = {
	STANDARD: 1,
	REDUIT: 2
}

const PaymentCardCell: React.FC<PaymentCardCellProps> = (
	{
		startingDate,
		endDate,
		beneficiaries,
		allBeneficiaries,
		downloadCarteTP,
		numIndividu,
		openNoCard
	}
) => {
	const { isLaptop, isDesktop, isWidescreen, isMobile } = useBreakpoints()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const download = () => {
		setIsLoading(true)
		downloadCarteTP(numIndividu, (startingDate ? new Date(startingDate) : new Date()).getFullYear(), isLaptop || isDesktop || isWidescreen ? FORMAT.STANDARD : FORMAT.REDUIT, callBackonDownload, callBackonDownloadError)
			.catch(() => {
				setIsLoading(false)
				openNoCard()
			})
	}

	const callBackonDownload = () => {
		setIsLoading(false)
	}

	const callBackonDownloadError = () => {
		setIsLoading(false)
		openNoCard()
	}

	const benefName = useMemo(() => {
		const benef = allBeneficiaries.find((benef: any) => benef[1].numIndividu === numIndividu)
		return benef ? `${benef[1].prenom} ${benef[1].nom}` : ''
	}, [allBeneficiaries, numIndividu])

	return (
		<Stack direction="column" spacing="24px">
			<Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems={isMobile ? 'flex-start' : 'center'} spacing="24px">
				<Typography variant="h3">
					<FormattedMessage id="paymentCard.insured" values={{ name: benefName }} />
				</Typography>
				<ButtonDefault onClick={download} fullWidth={isMobile} variant="outlined" color="primary">
					{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="paymentCard.download" />}
				</ButtonDefault>
			</Stack>

			<Stack direction={isMobile ? 'column' : 'row'} spacing="24px">
				<Typography flex={1} variant="body1">
					<FormattedMessage id="paymentCard.date" values={{ startingDate: new Date(startingDate).toLocaleDateString('fr-FR'), endDate: new Date(endDate).toLocaleDateString('fr-FR') }} />
				</Typography>
				<Stack flex={2} direction="column">
					<Typography variant="body2" sx={{ marginBottom: '16px' }}>
						<FormattedMessage id="paymentCard.beneficiaries" />
					</Typography>
					<Grid container rowSpacing="16px">
						{beneficiaries.map((beneficiary: any, index: number) => {
							const beneficiaryInfo = allBeneficiaries.find((b: any) => beneficiary?.numIndividu === b[0])

							if (beneficiaryInfo) {
								return (
									<Grid key={index} item xs={12} md={6}>
										<Grid container>
											<Stack direction="row" justifyContent="center" alignItems="center" spacing="8px">
												<BeneficiaryAvatar
													size="small"
													lastname={beneficiaryInfo ? beneficiaryInfo[1].nom : ''}
													firstname={beneficiaryInfo ? beneficiaryInfo[1].prenom : ''}
													type={beneficiaryInfo ? beneficiaryInfo[1].typAdr : ''}
												/>
												<Typography variant="h4">
													{(beneficiaryInfo && `${beneficiaryInfo[1].prenom} ${beneficiaryInfo[1].nom}`) || ''}
												</Typography>
											</Stack>
										</Grid>
									</Grid>
								)
							} else {
								return <></>
							}
						})}
					</Grid>
				</Stack>
			</Stack>
		</Stack>
	)
}

export default PaymentCardCell
