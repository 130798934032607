import React, { useEffect, useState } from 'react'
import ActivationAuthent from '../../../components/doubleAuthentComponents/ActivationAuthent'
import ModifyAuthent from '../../../components/doubleAuthentComponents/ModifyAuthent'
import ConfirmationAuthent from '../../../components/doubleAuthentComponents/ConfirmationAuthent'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import Box from '@mui/material/Box'
import loginImg from '../../../resources/img/login_img.jpg'
import { alpha, Stack, Typography } from '@mui/material'
import { useBreakpoints } from '../../../components/breakpoints/BreakpointsProvider'
import { UNIVERS_ENTREPRISES_KPI } from '../../../univers/constant/univers'
import { MAIN_PATH_EE } from '../../../constant/path'
import { userSelector } from '../userEE/userEESelectors'
import FinalPopinAuthentKPI from '../../../components/doubleAuthentComponents/FinalPopinAuthentKPI'
import appConst from '../../../constant/appConstants'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'

const DoubleAuthentificationPageKPI = ({
	// @ts-ignore
	user,
	// @ts-ignore
	getArticleByShortcut,
	// @ts-ignore
	activationAuthentArticle,
	// @ts-ignore
	finalisationAuthentArticle
}) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait, width, height } = useBreakpoints()
	const [currentStep, setCurrentStep] = useState(1)
	const [telephone, setTelephone] = useState<string>(user.telephone)
	const [email, setEmail] = useState<string>(user.email)

	useEffect(() => {
		if (!activationAuthentArticle) {
			getArticleByShortcut(appConst.cardiboxApi, 'activate_authent')
		}
		if (!finalisationAuthentArticle) {
			getArticleByShortcut(appConst.cardiboxApi, 'finalize_authent')
		}
	}, [getArticleByShortcut, activationAuthentArticle, finalisationAuthentArticle])

	const title = () => {
		switch (currentStep) {
			case 1:
				return intl.formatMessage({ id: 'doubleAuthent.activation.title' })
			case 2:
				return intl.formatMessage({ id: 'personalInfo.connexionInfo.modifyAuthent.title.activation' })
			case 3:
				return intl.formatMessage({ id: 'doubleAuthent.confirmation.title.activation' })
			case 4:
				return intl.formatMessage({ id: 'doubleAuthent.finalPopin.title' })
			default:
				return undefined
		}
	}

	const renderStep = () => {
		switch (currentStep) {
			case 1:
				return <ActivationAuthent nextStep={nextStep} obligatoire={true} textCardibox={activationAuthentArticle && activationAuthentArticle.fields.body} />
			case 2:
				return <ModifyAuthent nextStep={nextStep} updateContact={updateContact} currentAuthent={'mail'} telephone={telephone} email={email} kpi={true} />
			case 3:
				return <ConfirmationAuthent nextStep={nextStep} prevStep={prevStep} currentAuthent={'mail'} telephone={telephone} email={email} espaceEntreprise={true} />
			case 4:
				return <FinalPopinAuthentKPI terminer={closePopup} textCardibox={finalisationAuthentArticle && finalisationAuthentArticle.fields.body} />
			default:
				return undefined
		}
	}

	const updateContact = (newTelephone: string, newEmail: string) => {
		newTelephone && setTelephone(newTelephone)
		newEmail && setEmail(newEmail)
	}

	const nextStep = () => {
		if (currentStep < 4) {
			setCurrentStep(currentStep + 1)
		}
	}

	const prevStep = () => {
		if (currentStep > 4) {
			setCurrentStep(currentStep - 1)
		}
	}

	const closePopup = () => {
		window.location.href = UNIVERS_ENTREPRISES_KPI.to(MAIN_PATH_EE.DASHBOARD)
	}

	return (
		<Stack direction="row" justifyContent="flex-end">
			{!(isMobile || isTabletPortrait) && (
				<Box flex={1} width="100%" height={height} position="relative">
					<img style={{ width: '100%', height: height, objectFit: 'cover', zIndex: 0 }} src={loginImg}
					     alt="background" />
					<Stack
						direction="column"
						sx={{ position: 'absolute', top: '0', left: '0', width: '100%', height: height, zIndex: 1 }}
						bgcolor={alpha('#e0e4ff', 0.65)}
						alignItems="center"
						spacing="80px"
					>
						<img style={{ width: '300px', marginTop: '80px' }}
						     src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
						     alt="logo" />
					</Stack>
				</Box>)
			}
			<Stack width={!(isMobile || isTabletPortrait) ? '700px' : width} direction="column" spacing={2}
			       alignItems="center">
				<Stack direction="column" width={!isMobile ? '460px' : 'unset'} alignItems="center"
				       pt={!isMobile ? '96px' : '48px'} px="24px">
					<Stack spacing="32px">
						<Typography color="secondary" variant="h2" width={!isMobile ? '460px' : 'unset'}>
							{title()}
						</Typography>
						{renderStep()}
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	)
}

const actions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}

const mapStateToProps = (state: any) => ({
	activationAuthentArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)['activate_authent'],
	finalisationAuthentArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)['finalize_authent'],
	user: userSelector(state)
})

export default connect(
	mapStateToProps,
	actions
)(DoubleAuthentificationPageKPI)
