import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { AUTHENTIFICATION_SECURISEE } from '../../ea/ui/personalInfo/services/personalInfoConstants'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../ButtonDefault'
import RadioInput from '../form/RadioInput'
import HelpPopin from '../../ea/ui/help/components/HelpPopin'
import UserService from '../../api/gerep/UserService'
import { ToastContext } from '../toast/ToastContext'
import { ToastType } from '../toast/toastConstants'
import UserServiceEE from '../../api/gerepEe/UserServiceEE'
import { useBreakpoints } from '../breakpoints/BreakpointsProvider'
import { maskTelephone, maskEmail } from '../../utils/authentUtils'

type RelanceCodeProps = {
	currentAuthent: string
	token: string | undefined
	telephone?: string
	email?: string
	nextStep: () => void
	prevStep: () => void
	changeMethode?: (newMethode: string) => void
	espaceEntreprise?: boolean
}

const RelanceCode: React.FC<RelanceCodeProps> = ({
	currentAuthent,
	telephone,
	email,
	nextStep,
	prevStep,
	token,
	changeMethode,
	espaceEntreprise = false
}) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [contact, setContact] = useState<boolean>(false)

	const { addToast } = useContext(ToastContext)

	const getMethode = () => {
		let methode, recuperation

		if (currentAuthent.includes('/')) {
			[methode, recuperation] = currentAuthent.split('/')
		} else {
			methode = currentAuthent
			recuperation = undefined
		}
		return { methode, recuperation }
	}

	const loadData = () => {
		const { methode, recuperation } = getMethode()
		const options = [
			{ value: methode, label: `Recevoir un nouveau code au ${methode === 'sms' ? maskTelephone(telephone) : maskEmail(email)}` },
			{ value: 'support', label: 'Contacter le support' }
		]
		if (recuperation) {
			options.splice(1, 0, {
				value: recuperation,
				label: recuperation === 'sms' ? `Utiliser mon numéro de téléphone de récupération ${maskTelephone(telephone)}`
					: `Utiliser mon mail de récupération ${maskEmail(email)}`
			})
		}
		return options
	}

	const onSubmit = (values: Record<string, string>) => {
		const renvoi = values[AUTHENTIFICATION_SECURISEE.RENVOI_CODE]
		if (renvoi === 'support') {
			setContact(true)
		} else {
			changeMethode && changeMethode(renvoi)
			return envoyerCode(renvoi)
				.then(() => {
					addToast(ToastType.SUCCESS, 'doubleAuthent.success')
					nextStep()
					setIsLoading(false)
				})
				.catch((error) => {
					if (error?.data) {
						return error?.data
					} else {
						addToast(ToastType.ERROR, 'global.error.occurred')
					}
				})
				.finally(() => setIsLoading(false))
		}

	}

	const envoyerCode = (methode: string) => {
		return espaceEntreprise ? UserServiceEE.envoyerCode({ email: email }, token)
			: UserService.envoyerCodeConnexion({ methode: methode }, token)
	}

	return (<>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Stack direction="column" spacing="24px">
							<Typography variant="body2">
								Vous n'avez pas reçu votre code
							</Typography>
							<RadioInput
								name={AUTHENTIFICATION_SECURISEE.RENVOI_CODE}
								data={loadData()}
								defaultValue={getMethode().methode}
							/>

							<Stack direction={(isMobile || isTabletPortrait) ? 'column' : 'row'} spacing="24px" display="flex" justifyContent="center">
								<ButtonDefault variant="outlined" onClick={prevStep}>
									<FormattedMessage id="global.button.prevStep" />
								</ButtonDefault>
								<ButtonDefault variant="contained" onClick={handleSubmit}>
									{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
								</ButtonDefault>
							</Stack>
						</Stack>
					</form>
				)}
			/>
		{contact && <HelpPopin showHelpDuration={0} showActionsInital={true} setOpenPopinClick={setContact} openPopinClick={contact}/>}
		</>
	)
}

export default RelanceCode
