import React from 'react'
import { Card, CardContent, CardHeader, IconButton, Modal, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useBreakpoints } from './breakpoints/BreakpointsProvider'

type PopupProps = {
	children: React.ReactNode
	open: boolean
	setOpen: any
	onClose?: () => void
	title: string | React.ReactNode
	maxWidth?: string
	width?: string
	showCloseButton?: boolean
}

const Popup: React.FC<PopupProps> = (
	{
		children,
		open,
		setOpen,
		title,
		maxWidth = 'unset',
		width = 'auto',
		onClose,
		showCloseButton = true
	}
) => {
	const { isMobile, isTabletPortrait, height } = useBreakpoints()
	return (
		<Modal disableEnforceFocus open={open}>
			<Card sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				borderRadius: '6px',
				boxShadow: 12,
				maxWidth: maxWidth,
				outline: 'none',
				width: isMobile ? '95%' : isTabletPortrait ? '75%' : width
			}}>
				<CardHeader
					sx={{ borderBottom: '1px solid #dedede', padding: !isMobile ? '24px 32px' : '24px' }}
					title={
						<Typography variant="h2" color="secondary">
							{title}
						</Typography>
					}
					action={
						showCloseButton &&
						<IconButton
							onClick={() => {
								onClose && onClose()
								setOpen(false)
							}}
						>
							<CloseIcon />
						</IconButton>
					}
				/>
				<CardContent sx={{ padding: !isMobile ? '24px 32px' : '24px' }}>
					<Stack maxHeight={height - 250} direction="column" spacing={2} sx={{ overflow: 'hidden', overflowY: 'auto' }}>
						{children}
					</Stack>
				</CardContent>
			</Card>
		</Modal>
	)
}

export default Popup
