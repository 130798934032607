import qs from 'qs'

const CONTEXTE = {
	ASSURE_PRINCIPAL: 4,
	ASSURE: 12,
	TELETRANSMISSION: 19,
	DOSSIER_SANTE: 20
}

const DOMAIN = {
	PEC: 'pec',
	DEVIS: 'devis'
}
const OTHER_DOMAINS = [DOMAIN.PEC, DOMAIN.DEVIS]

export const TYPE_NOTIFICATION = {
	POLE_EMPLOI: 'pole_emploi',
	JUSTIFICATIFS_EN_ATTENTE: 'justificatifs_en_attente',
	ENFANT_MAJEUR: 'enfant_majeur',
	TELETRANSMISSION: 'teletransmission',
	JUSTIFICATIFS_DEVIS: 'justificatifs_devis',
	JUSTIFICATIFS_REMBOURSEMENT: 'justificatifs_remboursement',
	GENERIC: 'generic'
}

const buildUrlWithParams = (url, params) => {
	const querystring = qs.stringify(params, {
		skipNulls: true,
		arrayFormat: 'repeat'
	})
	if (querystring) {
		return `${url}?${querystring}`
	}
	return url
}

export const getSuffix = ({
	domain,
	data: {
		contexte,
		nopiece
	}
}) => {
	if (OTHER_DOMAINS.indexOf(domain) >= 0) {
		// pour ces domaines, on fait confiance à ce qui est retourné par le serveur
		return domain
	}
	// pour les autres, on détermine nous-même le type de notification

	switch (contexte) {
		case CONTEXTE.ASSURE_PRINCIPAL: {
			if (nopiece === 3 || nopiece === 4) {
				// justificatif pôle emploi
				return TYPE_NOTIFICATION.POLE_EMPLOI
			}
			// justificatif
			return TYPE_NOTIFICATION.JUSTIFICATIFS_EN_ATTENTE
		}
		case CONTEXTE.ASSURE: {
			if (nopiece === 37 // scolarité 2022/2023
			) {
				return TYPE_NOTIFICATION.ENFANT_MAJEUR
			}
			// autres
			return TYPE_NOTIFICATION.JUSTIFICATIFS_EN_ATTENTE
		}
		case CONTEXTE.TELETRANSMISSION: {
			return TYPE_NOTIFICATION.TELETRANSMISSION
		}
		case CONTEXTE.DOSSIER_SANTE: {
			if (nopiece >= 10 && nopiece <= 17) {
				// devis
				return TYPE_NOTIFICATION.JUSTIFICATIFS_DEVIS
			}
			// remboursement
			return TYPE_NOTIFICATION.JUSTIFICATIFS_REMBOURSEMENT
		}
		default:
			return TYPE_NOTIFICATION.GENERIC
	}
}

export const getUrlLink = (notification) => {
	const {
		data
	} = notification
	if (data.url) {
		return null
	}
	const suffix = getSuffix(notification)
	switch (suffix) {
		case TYPE_NOTIFICATION.POLE_EMPLOI: {
			const { idpiece } = data
			return buildUrlWithParams('/contact/parcours-pole-emploi', {
				s: idpiece
			})
		}
		case TYPE_NOTIFICATION.JUSTIFICATIFS_REMBOURSEMENT: {
			const { idpiece } = data
			return buildUrlWithParams('/contact/justificatif-remboursement', {
				s: idpiece
			})
		}
		case TYPE_NOTIFICATION.ENFANT_MAJEUR: {
			const {
				numbene
			} = data
			if (numbene) {
				const { idpiece } = data
				return buildUrlWithParams(`/services/enfant-majeur/${encodeURIComponent(numbene)}`, {
					s: idpiece
				})
			}
			return '/services/enfant-majeur'
		}
		case TYPE_NOTIFICATION.GENERIC: {
			const { idpiece } = data
			return buildUrlWithParams('/contact/piece-manquante', {
				s: idpiece
			})
		}
		default: {
			const { idpiece } = data
			return buildUrlWithParams(`/contact/${suffix}/piece-manquante`, {
				s: idpiece
			})
		}
	}
}
