import { isBefore, isValid } from 'date-fns'
import { newDatePlusYears } from '../../../../utils/dateUtils'

const MONTANT_MAXIMUM_MEDECINE_DOUCE = 1000

export const isDateSoinsValidate = (dateStr: string) => {
	const date = new Date(dateStr)
	const today = new Date()
	return isValid(date) && isBefore(date, today)
}

export const destructSelectedType = (typeActe = '') => {
	const [codeActe, rang] = typeActe.split('-')
	return {
		codeActe,
		rang
	}
}

const isMontantValide = (value: any) => {
	let valueToCheck = 0
	if (typeof value === 'string') {
		valueToCheck = parseFloat(value.replace(',', '.'))
	}

	return !isNaN(valueToCheck) && valueToCheck >= 0
}

export const validateDetailsSoins = (values: any, errors: any) => {
	(values?.detailsSoinsArray as any[] || []).forEach((detailSoins) => {
		const error: any = {}
		if (!detailSoins?.date) {
			error.date = 'Champ obligatoire'
		} else {
			if (!isValid(detailSoins?.date)) {
				error.date = 'La valeur est incorrecte'
			} else if (isValid(detailSoins?.date)) {
				const date = new Date(detailSoins.date)
				const today = new Date()
				const dateBackTwoYears = newDatePlusYears(-2)

				if (isBefore(today, date)) {
					error.date = 'Date de soins ne peut pas être supérieure à la date du jour'
				} else if (isBefore(date, dateBackTwoYears)) {
					error.date = 'La date de soins doit être inférieure ou égale à 2 ans'
				}
			}
			if (!error.date && !detailSoins?.type) {
				error.type = 'Champ obligatoire'
			}
		}
		if (!detailSoins?.montantPraticien) {
			error.montantPraticien = 'Champ obligatoire'
		} else if (!isMontantValide(detailSoins.montantPraticien)) {
			error.montantPraticien = 'La valeur est incorrecte'
		} else if (parseFloat(detailSoins.montantPraticien) >= MONTANT_MAXIMUM_MEDECINE_DOUCE) {
			error.montantPraticien = 'Le montant saisi doit être inférieur à 1000 euros'
		}
		if (detailSoins?.type
			&& destructSelectedType(detailSoins?.type).rang === '2') {
			if (!detailSoins.rmbAutreMutuelle) {
				error.rmbAutreMutuelle = 'Champ obligatoire'
			} else if (!isMontantValide(detailSoins.rmbAutreMutuelle)) {
				error.rmbAutreMutuelle = 'La valeur est incorrecte'
			} else if (parseFloat(detailSoins.rmbAutreMutuelle) >= parseFloat(detailSoins.montantPraticien)) {
				error.rmbAutreMutuelle = 'Le montant saisi ne peut être supérieur ou égal au montant payé au praticien'
			}
		}
		errors.detailsSoinsArray?.push(error)
	})
	return errors
}