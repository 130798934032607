import React, {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../../components/breakpoints/BreakpointsProvider'
import {login as loginAction} from '../../../preaffiliation/services/preaffiliationActions'
import {MAIN_PATH_UNIVERS} from '../../../../../constant/path'
import {CircularProgress, Stack, Typography} from '@mui/material'
import {Form} from 'react-final-form'
import TextInput from '../../../../../components/form/TextInput'
import Box from '@mui/material/Box'
import ButtonConnexion from '../../../../../components/ButtonConnexion'
import {useAppDispatch} from '../../../../../config/hooksUnivers'
import {AccessParam} from '../../services/preaffiliationConstants'
import {useHistory} from 'react-router-dom'
import {trim} from '../../../../../utils/stringUtils'

type LoginPreaffiliationFormProps = {
    token: string
}

const LoginPreaffiliationForm: React.FC<LoginPreaffiliationFormProps> = (
    {
        token
    }
) => {

    const intl = useIntl()
    const dispatch = useAppDispatch()
    const {isMobile, isTabletPortrait} = useBreakpoints()

    const [loading, setLoading] = useState<boolean>(false)
    // const [openForgottenAccessCode, setOpenForgottenAccessCode] = useState<boolean>(false)
    const history = useHistory()

    const onSubmit = (
        {
            codeAccess
        }: AccessParam) => {
        setLoading(true)

        return dispatch(loginAction(token, codeAccess))
            .then(() => {
                history.push(MAIN_PATH_UNIVERS.HOME_PREAFFILIATION)
            })
            .catch((e: any) => {
                // login failed
                if (e.data && e.data.codeAccess) {
                    return e.data
                }

                if (e.status === 401) {
                    return {"codeAccess": intl.formatMessage({id: 'preaffiliation.error.wrongCode'})}
                } else if (e.status === 403) {
                    return {"codeAccess": e.data._error}
                } else {
                     return {"codeAccess": intl.formatMessage({id: 'preaffiliation.error.global'})}
                }
            })
            .finally(() => setLoading(false))
    }

    const required = (value: string) => (value ? undefined : intl.formatMessage({id: 'global.error.required'}))

    return (
        <>
            <Stack
                direction="column"
                spacing="48px"
                width={!isMobile ? '360px' : 'unset'}
                alignItems="center"
                pt={isMobile || isTabletPortrait ? '48px' : '0'}
                px="24px"
            >
                <Form
                    onSubmit={onSubmit}
                    render={({handleSubmit}) => (
                        <form onSubmit={handleSubmit} role="form" style={{width: '100%'}}>
                            <Stack direction="column" spacing="32px">
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography color="secondary" variant="h2" fontSize="1.125rem">
                                        <FormattedMessage id="preaffiliation.connexion.title"/>
                                    </Typography>
                                    <img
                                        style={{width: '80px'}}
                                        src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
                                        alt="logo"
                                    />
                                </Stack>

                                <Typography variant="body2" fontSize="0.875rem">
                                    <FormattedMessage id="preaffiliation.connexion.helper"/>
                                </Typography>

                                <TextInput
                                    maxWidth={isMobile ? '100%' : '360px'}
                                    size="medium"
                                    type="password"
                                    name="codeAccess"
                                    label={intl.formatMessage({id: 'preaffiliation.connexion.code'})}
                                    validate={required}
                                    format={trim}
                                />

                                <Box>
                                    <ButtonConnexion type="submit" onClick={handleSubmit} variant="contained">
                                        {loading ?
                                            <CircularProgress size={24} color="inherit"/> :
                                            <FormattedMessage id="login.button.login"/>
                                        }
                                    </ButtonConnexion>
                                </Box>
                            </Stack>
                        </form>
                    )}
                />
            </Stack>
        </>
    )
}
export default LoginPreaffiliationForm
