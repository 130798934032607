import React, { FC } from 'react'
import { downloadDocument } from '../../../webservices/demandes/demandesActions'
import { Typography } from '@mui/material'

type DownloadDownloadLinkProps = {
	idDemande: any,
	document: any
}

const DownloadDownloadLink: FC<DownloadDownloadLinkProps> = (
	{
		idDemande,
		document: {
			iddoc: idDocument,
			nomdoc
		}
	}
) => {
	return (
		<Typography flex={1} variant="body2" fontWeight={600} sx={{ cursor: 'pointer', wordBreak: 'break-word' }}
		            onClick={(event) => {
			            event.preventDefault()
			            downloadDocument(idDemande, idDocument)
		            }}>
			{nomdoc}
		</Typography>
	)
}

export default DownloadDownloadLink
