import React, {useContext, useMemo, useState} from 'react'
import {Form} from 'react-final-form'
import {Box, CircularProgress, Stack, Typography} from '@mui/material'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import ButtonDefault from '../../../../components/ButtonDefault'
import {FormattedMessage, useIntl} from 'react-intl'
import {getSuffix} from '../../../webservices/notifications/NotificationUtils'
import DownloadNotifLink from './DownloadNotifLink'
import FilePdf from '../../../../resources/img/file-pdf.png'
import FileInput from '../../../../components/fileInput/FileInput'
import LocalStorage from '../../../../business/storage/LocalStorage'
import ContactService from '../../../../api/gerep/ContactService'
import {ToastContext} from '../../../../components/toast/ToastContext'
import {ToastType} from '../../../../components/toast/toastConstants'

type ParcoursPoleEmploiFormProps = {
	selectedNotif: any
	confirmationTitle: string
	removeNotification: (id: string) => void
}

const ParcoursPoleEmploiForm: React.FC<ParcoursPoleEmploiFormProps> = (
	{
		selectedNotif,
		confirmationTitle,
		removeNotification
	}
) => {
	const intl = useIntl()
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const {addToast} = useContext(ToastContext)

	const attachmentUrl = useMemo(() => selectedNotif?.data.file, [selectedNotif])

	const onSubmit = (values: any) => {
		setIsLoading(true)
		const {
			data: {
				entite,
				contexte,
				numbene,
				nopiece,
				idpiece
			}
		} = selectedNotif

		const {files} = values

		return ContactService.parcoursPoleEmploi(LocalStorage.getToken(),
			[{
				key: 'parcours',
				value: {
					idpiece,
					entite,
					contexte,
					numbene,
					nopiece
				}
			}, ...files.map((file: any, index: number) => ({
				key: `file${index}`,
				value: file
			}))])
			.then(() => {
				// suppression de la notification
				if (selectedNotif.id) {
					removeNotification(selectedNotif.id)
				}
				setOpenConfirmation(true)
			})
			.catch((e) => {
				if (e?.data?._error) {
					addToast(ToastType.ERROR, e.data._error)
				} else if (e?.data) {
					return e.data
				} else {
					addToast(ToastType.ERROR, 'Echec de la demande')
				}
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={(values: any) => {
				const errors: any = {}
				if (!values['files'] || values['files']?.length === 0) {
					errors['files'] = intl.formatMessage({id: 'global.error.required'})
				}

				return errors
			}}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit}>
					<Stack direction="column" spacing="24px">
						<Typography variant="body2">
							<FormattedMessage id={`notification.${getSuffix(selectedNotif)}.form.label`}/>
						</Typography>

						{attachmentUrl && (
							<Box display="flex" justifyContent="center">
								<DownloadNotifLink notification={selectedNotif} url={attachmentUrl}>
									<img alt="Détail" src={FilePdf} style={{width: 50}}/>
								</DownloadNotifLink>
							</Box>
						)}

						<Stack direction="column" spacing="12px">
							<Typography variant="body2">
								<FormattedMessage id="notification.benef"
												  values={{nombene: selectedNotif?.data.nombene}}/>
							</Typography>

							{selectedNotif?.commentaire && (
								<Typography variant="body2">
									{selectedNotif?.commentaire}
								</Typography>
							)}

							<FileInput
								name="files"
								title={selectedNotif.data.piece}
							/>
							<Typography variant="body2">
								<FormattedMessage id="notification.endMessage.1"/>
								<Typography variant="body2" fontWeight={600} component="span">
									<FormattedMessage id="notification.endMessage.2"/>
								</Typography>
							</Typography>
						</Stack>

						<Stack alignItems="center">
							<ButtonDefault
								type="submit"
								variant="contained"
							>
								{isLoading ? <CircularProgress size={24} color="inherit"/> :
									<FormattedMessage id="global.button.validate"/>}
							</ButtonDefault>
						</Stack>
						<ConfirmationPopin
							title={confirmationTitle}
							open={openConfirmation}
							setOpen={setOpenConfirmation}
						/>
					</Stack>
				</form>
			)}
		/>
	)
}

export default ParcoursPoleEmploiForm
