import React from 'react'
import { Avatar, Typography } from '@mui/material'

type BeneficiaryAvatarProps = {
	size?: 'small' | 'large',
	lastname: string,
	firstname?: string,
	type?: number,
	concatName?: string
}

type AvatarColors = {
	background: string
	text: string
}

const SMALL_SIZE = '24px'
const LARGE_SIZE = '36px'

const BeneficiaryAvatar: React.FC<BeneficiaryAvatarProps> = (
	{
		size = 'small',
		lastname,
		firstname,
		type,
		concatName
	}
) => {
	const getColor = (): AvatarColors => {
		switch (type) {
			case 0:
				return { background: '#FEB4B6', text: '#FC454A' }
			case 1:
			case 3:
			case 7:
				return { background: '#D1E5A7', text: '#8AC11D' }
			case 2:
			case 10:
			case 12:
			case 13:
			case 15:
			case 16:
			case 19:
				return { background: '#99B5CF', text: '#004687' }
			case 6:
				return { background: '#F6C59F', text: '#E96E11' }
			case 4:
			case 11:
			case 18:
				return { background: '#BDBDBD', text: '#595959' }
			default:
				return { background: '#e0e4ff', text: '#b5bbff' }
		}
	}

	const avatorColors = getColor()

	const getInitials = () => {
		if (firstname) {
			return `${firstname[0]}${lastname[0]}`
		} else {
			return lastname
		}
	}

	return (
		<Avatar
			sx={{
				bgcolor: avatorColors.background,
				width: size === 'small' ? SMALL_SIZE : LARGE_SIZE,
				height: size === 'small' ? SMALL_SIZE : LARGE_SIZE
			}}
		>
			<Typography sx={{ color: avatorColors.text }} fontWeight={600} fontSize={size === 'small' ? '0.625rem' : '1rem'}>
				{getInitials()}
			</Typography>
		</Avatar>
	)
}

export default BeneficiaryAvatar
