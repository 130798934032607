import {combineReducers} from 'redux'
// utils
import {types} from './ListCompanyActions'
import configureCache from '../../../utils/cache'
import appConst from '../../../constant/appConstants'

const _cache = configureCache(appConst.cacheDuration.remboursement)

const data = (state = [], action) => {
	switch (action.type) {
		case types.GET_LIST_COMPANY: {
			return action.payload
		}
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case types.GET_LIST_COMPANY_LOADING: {
			return action.payload
		}
		default:
			return state
	}
}

const expirationDate = (state = 0, action) => {
	switch (action.type) {
		case types.GET_LIST_COMPANY:
			return _cache.mark()
		default:
			return state
	}
}

export default combineReducers({
	data,
	loading,
	expirationDate
})
