import { types } from './souscriptionActions'
import { combineReducers } from 'redux'
import appConst from '../../../constant/appConstants'


const data = (state = [], action) => {
	switch (action.type) {
		case types.GET_GARANTIES: {
			return action.payload
		}
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case types.GET_GARANTIES_LOADING: {
			return action.payload
		}
		default:
			return state
	}
}

const expirationDate = (state = 0, action) => {
	switch (action.type) {
		case types.GET_GARANTIES: {
			return (+new Date() + appConst.cacheDuration.contrats * 1000)
		}
		default:
			return state
	}
}

const garanties = combineReducers({
	data,
	loading,
	expirationDate
})

const souscription = combineReducers({
	garanties
})
export default souscription
