import { getExpirationDate, isSurveillanceLoading } from './surveillanceSelector'
import SurveillanceService from '../../../../api/gerep/SurveillanceService';

const ACTION_NAMESPACE = '/SURVEILLANCE'

export const types = {
	GET_STATES: `${ACTION_NAMESPACE}/GET_STATES`,
	GET_STATES_LOADING: `${ACTION_NAMESPACE}/GET_STATES_LOADING`
}

export const getStates = () => (dispatch, getState) => {
	if (!isSurveillanceLoading(getState()) &&
		getExpirationDate(getState()) < (+new Date())) {
		dispatch({ type: types.GET_STATES_LOADING, payload: true })
		return SurveillanceService.getStates()
			.then((rubriques) => {
				dispatch({ type: types.GET_STATES_LOADING, payload: false })
				return dispatch({
					type: types.GET_STATES,
					payload: rubriques
				})
			}).catch((e) => {
				dispatch({ type: types.GET_STATES_LOADING, payload: false })
				throw e
			})
	}
}
