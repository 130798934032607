import React, {ReactNode} from 'react'
import ButtonLink from '../../../../components/ButtonLink'
import Icon from '@mdi/react'
import {mdiDownload} from '@mdi/js'
import {FormattedMessage} from 'react-intl'
import {Box} from '@mui/material'


type DownloadGarantiesButtonProps = {
    download: Function
}

const DownloadGarantiesButton: React.FC<DownloadGarantiesButtonProps> = (
    {
        download
    }
) => {

    const downloadGaranties = () => {
        return download().then((fileURL: any) => {
                const link = document.createElement('a')
                link.href = fileURL
                link.target = '_blank'
                link.click()
            }, (e: any) => {
                console.error(e)
            })
    }

    return (

        <Box>
            <ButtonLink
                variant="contained"
                startIcon={<Icon size="20px" path={mdiDownload}/>}
                onClick={downloadGaranties}
                sx={{textAlign: 'center', lineHeight: '18px'}}
            >
                <FormattedMessage id="preaffiliation.confirmation.contract.garanties"/>
            </ButtonLink>
        </Box>


    )
}
export default DownloadGarantiesButton