import React, { Dispatch, SetStateAction } from 'react'
import Popup from '../../../../../components/Popup'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../../../../../components/ButtonDefault'
import { useBreakpoints } from '../../../../../components/breakpoints/BreakpointsProvider'

type DeleteRibPopinProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	onConfirm: () => void
	loading: boolean
}

const DeleteRibPopin: React.FC<DeleteRibPopinProps> = (
	{
		open,
		setOpen,
		onConfirm,
		loading
	}
) => {
	const { isMobile } = useBreakpoints()

	return (
		<Popup open={open} setOpen={setOpen} title="Supprimer un RIB">
			<Stack direction="column" spacing="24px">
				<Typography variant="body2">
					Vous êtes sur le point de supprimer votre RIB. Le(s) bénéficiaire(s) rattaché(s) à ce RIB seront automatiquement rattachés au RIB de l’assuré principal.
				</Typography>
				<Box display="flex" justifyContent="center">
					<ButtonDefault
						fullWidth={isMobile}
						variant="outlined"
						onClick={onConfirm}
					>

						{loading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
					</ButtonDefault>
				</Box>
			</Stack>
		</Popup>
	)
}

export default DeleteRibPopin