import React, { useEffect, useMemo } from 'react'
import { alpha, Box, Stack, Typography, useTheme } from '@mui/material'
import loginImg from '../../../../resources/img/login_img.jpg'
import { useParams } from 'react-router-dom'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import { connect } from 'react-redux'
import * as actions from '../services/inscription/inscriptionActions'
import appConst from '../../../../constant/appConstants'
import Loader from '../../../../components/Loader'
import ButtonDefault from '../../../../components/ButtonDefault'
import { FormattedMessage } from 'react-intl'
import {MAIN_PATH_UNIVERS} from '../../../../constant/path'
import { useHistory } from 'react-router-dom'
import {compose} from "redux";

type ActivationParams = {
	idAdherent: string
	codeActivation: string
}

const ACTIVATION_SUCESS = 'activation_success'
const ACTIVATION_ERROR = 'activation_error'

const ACTIVATION_SUCESS_EE = 'ee_activation_success'
const ACTIVATION_ERROR_EE = 'ee_activation_error'

const ActivationPage = (
	{
		// @ts-ignore
		activateAction,
		// @ts-ignore
		getArticleByShortcut,
		// @ts-ignore
		activationLoading,
		// @ts-ignore
		activationStatus,
		// @ts-ignore
		activationSuccess,
		// @ts-ignore
		activationError,
		// @ts-ignore
		isEntreprise
	}
) => {
	const { idAdherent, codeActivation } = useParams<ActivationParams>()
	const { height } = useBreakpoints()
	const theme = useTheme()
	const history = useHistory()

	useEffect(() => {
		activateAction(idAdherent, codeActivation)
		if(isEntreprise){
			getArticleByShortcut(appConst.cardiboxApi, ACTIVATION_SUCESS_EE)
			getArticleByShortcut(appConst.cardiboxApi, ACTIVATION_ERROR_EE)
		} else {
			getArticleByShortcut(appConst.cardiboxApi, ACTIVATION_SUCESS)
			getArticleByShortcut(appConst.cardiboxApi, ACTIVATION_ERROR)
		}
	}, [])

	const activationContent = useMemo(() => activationStatus && (activationStatus === 'SUCCESS' ? activationSuccess : activationError), [activationStatus, activationSuccess, activationError])

	return (
		<Stack>
			<img style={{ width: '100%', height: height, objectFit: 'cover', zIndex: 0 }} src={loginImg} alt="background" />
			{activationLoading ? <Loader /> :
				<Stack
					direction="column"
					sx={{ position: 'absolute', top: '0', left: '0', width: '100%', height: height, zIndex: 1 }}
					bgcolor={alpha('#e0e4ff', 0.65)}
					alignItems="center"
					spacing="80px"
				>
					<img style={{ width: '300px', marginTop: '80px' }} src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`} alt="logo" />
					<Stack
						direction="column"
						spacing="24px"
						boxShadow={12}
						padding="24px"
						style={{
							maxWidth: '480px',
							borderRadius: '6px',
							backgroundColor: theme.palette.background.paper
						}}
					>
						<Typography color="secondary" variant="h1">
							{activationContent && activationContent.fields && activationContent.fields.title}
						</Typography>
						<div dangerouslySetInnerHTML={{ __html: (activationContent && activationContent.fields && activationContent.fields.body) }} />
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<ButtonDefault
								variant="contained"
								color="primary"
								onClick={() => {
									if(isEntreprise){
										history.push(MAIN_PATH_UNIVERS.LOGIN_EE)
									} else {
										history.push(MAIN_PATH_UNIVERS.LOGIN_EA)
									}
								}}
							>
								<FormattedMessage id="global.button.meLogin" />
							</ButtonDefault>
						</Box>
					</Stack>
				</Stack>
			}
		</Stack>
	)
}

const mapStateToProps = (state: any) => ({
	activationLoading: state.pages && state.pages.inscription && state.pages.inscription.activationLoading,
	activationStatus: state.pages && state.pages.inscription && state.pages.inscription.activationStatus,
	activationSuccess: cardiboxSelectors.articles.getByShortcut(state.cardibox).activation_success,
	activationError: cardiboxSelectors.articles.getByShortcut(state.cardibox).activation_error
})

const mapStateToPropsEE = (state: any) => ({
	activationLoading: state.pages && state.pages.inscription && state.pages.inscription.activationLoading,
	activationStatus: state.pages && state.pages.inscription && state.pages.inscription.activationStatus,
	activationSuccess: cardiboxSelectors.articles.getByShortcut(state.cardibox).ee_activation_success,
	activationError: cardiboxSelectors.articles.getByShortcut(state.cardibox).ee_activation_error
})

export const ActivationPageEA = compose(
	connect((state) => {
		const commonMapState = mapStateToProps(state)
		return {
			...commonMapState,
			isEntreprise: false
		}
	}, {
		activateAction: actions.activate,
		getArticleByShortcut: cardiboxActions.articles.getByShortcut
	})
)(ActivationPage)

export const ActivationPageEE = compose(
	connect((state) => {
		const commonMapState = mapStateToPropsEE(state)
		return {
			...commonMapState,
			isEntreprise: true
		}
	}, {
		activateAction: actions.activateEE,
		getArticleByShortcut: cardiboxActions.articles.getByShortcut
	})
)(ActivationPage)

export default connect(
	mapStateToProps,
	{
		activateAction: actions.activate,
		getArticleByShortcut: cardiboxActions.articles.getByShortcut
	}
)(ActivationPage)
