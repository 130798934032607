import React from 'react'
import {Link, Stack, Typography} from '@mui/material'
import {ameliUrl, BOOLEANS, INFOS_PERSOS_FIELDS} from '../services/preaffiliationConstants'
import {FormattedMessage, useIntl} from 'react-intl'
import FileInput from '../../../../components/fileInput/FileInput'
import RadioInput from '../../../../components/form/RadioInput'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'


type AttestationTeletransmissionAreaProps = {
}

const AttestationTeletransmissionArea: React.FC<AttestationTeletransmissionAreaProps> = (
    {

    }
) => {

    const intl = useIntl()
    const { isMobile, isTabletPortrait } = useBreakpoints()
    const isMobOrTabVert = isMobile || isTabletPortrait

    return (
        <>
            <Stack direction="column" spacing="12px">
                <FileInput
                    name={INFOS_PERSOS_FIELDS.SOCIAL_SECURITY_ATTESTATION}
                    title={intl.formatMessage({ id: 'preaffiliation.personalInfos.form.attestation.title' })}
                    maxFileNumber={1}
                    label={
                        <Stack
                            direction={isMobOrTabVert ? 'column' : 'row'}
                            spacing={isMobOrTabVert ? '4px' : undefined}
                            justifyContent="space-between" >
                            <Typography variant="body2" fontSize="0.875rem">
                                <FormattedMessage id="preaffiliation.personalInfos.form.attestation.label"/>
                            </Typography>

                            <Typography variant="body2" fontSize="0.75rem">
                                <Link
                                    href={ameliUrl}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    sx={(theme) => ({color: theme.palette.secondary.main})}
                                >
                                    {intl.formatMessage({id: 'preaffiliation.personalInfos.form.attestation.link'})}
                                </Link>
                            </Typography>
                        </Stack>
                    }
                />
                <RadioInput
                    name={INFOS_PERSOS_FIELDS.TELETRANSMISSION}
                    label={intl.formatMessage({id: 'preaffiliation.personalInfos.form.teletransmission.label'})}
                    data={BOOLEANS.map((boolean: any) => ({ value: boolean.value, label: boolean.label }))}
                    direction="row"
                    radioSpacing="24px"
                />
                <Stack direction="row" spacing="8px">
                    <InfoOutlinedIcon width="400px"/>
                    <Typography variant="body2" fontSize="0.875rem">
                        <FormattedMessage id="preaffiliation.personalInfos.form.teletransmission.helper"/>
                    </Typography>
                </Stack>

            </Stack>
        </>
    )
}

export default AttestationTeletransmissionArea