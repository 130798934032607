import React from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {Stack, Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import welcomeEE from '../../../../resources/img/welcomeEE.png'
import Box from '@mui/material/Box'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import {userSelector} from '../../userEE/userEESelectors'

const WelcomeEE = (
	{
		// @ts-ignore
		user
	}) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()

	return (
		<DashboardCard isTransparent cardSx={{
			display: 'flex',
			alignItems: 'flex-end'
		}}>
			<Stack direction="row" alignItems="end" justifyContent="space-between">
				<Stack width="100%" direction="column" spacing={2}  flex={(!isMobile || isTabletPortrait)? 12 : 9}>
					<Stack direction="column">
						<Typography fontWeight="400" fontSize="1.5rem">
							{`Bonjour `}
							<b>
								{user.prenom}
							</b>
						</Typography>
					</Stack>
					<Typography variant="body1">
						<FormattedMessage id="dashboard.welcome.message" />
					</Typography>
				</Stack>
				{(!isMobile || isTabletPortrait) &&
					<Stack direction="column" flex={3}>
						<Box>
							<img src={welcomeEE} alt="welcome temp" style={{ maxHeight: '215px' }} />
						</Box>
					</Stack>
				}
			</Stack>
		</DashboardCard>
	)
}

const mapStateToProps = (state: any) => ({
	user: userSelector(state)
})

export default compose(
	connect(mapStateToProps)
)(WelcomeEE)
