import React, { useState } from 'react'
import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Stack, Typography } from '@mui/material'
import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { STEP1_CHECKBOX_NAME } from '../services/souscriptionConstants'
import { positiveOrNaN } from '../../../../utils/numberUtils'
import DownloadGuideButton from './DownloadGuideButton'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

type SouscriptionCheckboxInputProps = {
	name: string
	data: Garantie[]
	error?: string
	onError?: () => void
	values: any
}

const SouscriptionCheckboxInput: React.FC<SouscriptionCheckboxInputProps> = (
	{
		data,
		name,
		onError,
		error,
		values
	}) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const [touched, setTouched] = useState<boolean>(false)

	return (
		<FormControl error={!!error}>
			<FormGroup>
				<Stack spacing="12px" direction="column">
					{data.map((garantie, index) => {
						const getPrixOption = () => {
							if (garantie.cumulative) {
								// le prix de l'option varie en fonction du nombre de bénéficiaires
								const selectedBenef = values[STEP1_CHECKBOX_NAME] ? values[STEP1_CHECKBOX_NAME][index]?.beneficiaires : []
								if (selectedBenef.length === 0) {
									return positiveOrNaN(garantie.prix)
								}
								return garantie.beneficiaires.filter((benef: any) => selectedBenef.includes(benef.id)).reduce((acc: number, benef: any) => acc + benef.prix, 0)
							}
							// le prix placé sur l'option, qui est normalement le prix le plus bas
							return positiveOrNaN(garantie.prix)
						}

						const prix = getPrixOption()

						return (
							<Field
								name={name}
								render={() => {
									return (
										<Stack direction="column" spacing="12px">
											<FormControlLabel
												key={garantie.key}
												control={
													<Field
														name={`${name}[${index.toString()}].selected`}
														type="checkbox"
														render={({ input, meta }) => {
															if (meta.touched && !touched) {
																setTouched(true)
															}
															return (
																<Checkbox
																	{...input}
																	onChange={(event) => {
																		if (event.target.checked) {
																			garantie.beneficiaires.forEach(((benef) => {
																				if (!benef.garantieObligatoire) {
																					values.options[index].beneficiaires.push(benef.numIndiv)
																				}
																			}))
																		} else {
																			values.options[index].beneficiaires = garantie.beneficiaires.filter((benef) => benef.garantieObligatoire).map((benef) => benef.numIndiv)
																		}
																		input.onChange(event)
																	}}
																/>
															)
														}}
													/>
												}
												label={
													<Typography fontSize="0.875rem" fontWeight={500}>
														{garantie.libelle}
													</Typography>
												}
											/>
											<Stack direction={isMobile || isTabletPortrait ? 'column' : 'row'} spacing="8px">
												<Stack direction="column" spacing="24px" flex={2}>
													<Stack direction="column" spacing="8px">
														<Typography variant="subtitle2">
															{garantie.dateEffet && garantie.dateLimiteSouscription &&
																<FormattedMessage
																	id="souscription.selectOption.dates.beginAndEnd"
																	values={{
																		dateDebut: new Date(garantie.dateEffet).toLocaleDateString('fr-FR'),
																		dateFin: new Date(garantie.dateLimiteSouscription).toLocaleDateString('fr-FR')
																	}}
																/>
															}
															{garantie.dateEffet && !garantie.dateLimiteSouscription &&
																<FormattedMessage
																	id="souscription.selectOption.dates.dateDebut"
																	values={{
																		dateDebut: new Date(garantie.dateEffet).toLocaleDateString('fr-FR')
																	}}
																/>
															}
															{!garantie.dateEffet && garantie.dateLimiteSouscription &&
																<FormattedMessage
																	id="souscription.selectOption.dates.dateFin"
																	values={{
																		dateFin: new Date(garantie.dateLimiteSouscription).toLocaleDateString('fr-FR')
																	}}
																/>
															}
														</Typography>
														{garantie.description && (
															<Typography variant="subtitle1">
																{garantie.description}
															</Typography>
														)}
													</Stack>
													<Stack direction="column">
														<Typography variant="body2">
															<FormattedMessage id="souscription.selectOption.beneficiaire" />
														</Typography>
														{garantie.beneficiaires && garantie.beneficiaires.length > 0 && (
															<Stack direction="column" paddingLeft="24px">
																{garantie.beneficiaires.map((beneficiaire) => (
																	<FormControlLabel
																		label={`${beneficiaire.nom} ${beneficiaire.prenom}`}
																		key={beneficiaire.numIndiv}
																		control={
																			<Field
																				name={`${name}[${index.toString()}].beneficiaires`}
																				value={beneficiaire.numIndiv}
																				type="checkbox"
																				render={({ input, meta }) => {
																					if (meta.touched && !touched) {
																						setTouched(true)
																					}
																					return (
																						<Checkbox
																							{...input}
																							onChange={(event) => {
																								const garantiesNonObligatoires = garantie.beneficiaires.filter((garantie) => !garantie.garantieObligatoire)
																								const valuesGarantiesNonObligatoires = values.options[index].beneficiaires.filter((benef: SouscriBeneficiaire) => !benef.garantieObligatoire)

																								if (event.target.checked) {
																									if (valuesGarantiesNonObligatoires.length === garantiesNonObligatoires.length) {
																										values.options[index].selected = true
																									}
																								} else {
																									if (valuesGarantiesNonObligatoires.length === 0) {
																										values.options[index].selected = false
																									}
																								}
																								input.onChange(event)
																							}}
																							disabled={!values[STEP1_CHECKBOX_NAME][index].selected || garantie.beneficiaires.find(b => b.numIndiv === beneficiaire.numIndiv)?.garantieObligatoire}
																						/>
																					)
																				}}
																			/>
																		}
																	/>
																))}
															</Stack>
														)}
													</Stack>
												</Stack>
												<Stack direction="column" spacing="8px" flex={1}>
													{garantie.libellePrix && (
														<Typography variant="h3">
															<FormattedMessage id="souscription.selectOption.libellePrix" values={{ libellePrix: garantie.libellePrix }} />
														</Typography>
													)}
													{prix > 0 && (
														<Typography variant="subtitle2">
															<FormattedMessage id="souscription.selectOption.prix" values={{ prix: prix.toFixed(2) }} />
														</Typography>
													)}
													{garantie.dureeEngagement && garantie.dureeEngagement > 0 && (
														<Typography variant="subtitle2">
															<FormattedMessage id="souscription.selectOption.dureeEngagement" values={{ dureeEngagement: garantie.dureeEngagement }} />
														</Typography>
													)}
													<DownloadGuideButton
														onError={onError}
														numGar={garantie.cntrtBase}
														siret={garantie.siret}
													/>
												</Stack>
											</Stack>
											<Divider />
										</Stack>
									)
								}}
							/>
						)
					})}
				</Stack>
				{touched && <FormHelperText sx={{ marginLeft: 0 }}>{error}</FormHelperText>}
			</FormGroup>
		</FormControl>
	)
}

export default SouscriptionCheckboxInput
