import React, { Dispatch, SetStateAction, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ConfirmationAuthent from '../../../../components/doubleAuthentComponents/ConfirmationAuthent'
import RelanceCode from '../../../../components/doubleAuthentComponents/RelanceCode'
import Popup from '../../../../components/Popup'
import { Typography } from '@mui/material'

type AuthentificationSecureProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	currentAuthent: string
	onSubmit: (code: string) => void
	currentEmail: string
	token: string | undefined
	currentTelephone?: string
	blocage?: string
	connexionIsLoading?: boolean
}

const AuthentificationSecure: React.FC<AuthentificationSecureProps> = ({
	setOpen,
	open,
	onSubmit,
	currentAuthent,
	currentTelephone,
	currentEmail,
	token,
	blocage,
	connexionIsLoading
}) => {
	const intl = useIntl()
	const [currentStep, setCurrentStep] = useState(1)
	const [methode, setMethode] = useState<string>()

	const determineMethod = (auth: string) => {
		if (auth === 'mail/sms' || auth === 'mail') {
			return 'mail'
		} else {
			return 'sms'
		}
	}

	const renderStep = () => {
		switch (currentStep) {
			case 1:
				return <ConfirmationAuthent
					nextStep={close}
					link={nextStep}
					onSubmitConnexion={onSubmit}
					currentAuthent={methode || determineMethod(currentAuthent)}
					telephone={currentTelephone}
					email={currentEmail}
					connexionIsLoading={connexionIsLoading} />
			case 2:
				return <RelanceCode
					currentAuthent={currentAuthent}
					nextStep={prevStep}
					prevStep={prevStep}
					telephone={currentTelephone}
					email={currentEmail}
					token={token}
					changeMethode={changeMethode}
				/>
			default:
				return undefined
		}
	}

	const changeMethode = (newMethode: string) => {
		setMethode(newMethode)
	}

	const nextStep = () => {
		if (currentStep < 2) {
			setCurrentStep(currentStep + 1)
		}
	}

	const prevStep = () => {
		if (currentStep > 1) {
			setCurrentStep(currentStep - 1)
		}
	}

	const close = () => {
		setCurrentStep(1)
		setOpen(false)
	}

	return (
		<Popup
			open={open}
			setOpen={setOpen}
			title={intl.formatMessage({ id: 'doubleAuthent.title' })}
			width="720px"
			showCloseButton={true}
			onClose={close}
		>
			{!blocage && renderStep()}
			{
				blocage &&
				<Typography variant="body2" color="error">
					<FormattedMessage id={'doubleAuthent.confirmation.blocage'} values={{ number: blocage }} />
				</Typography>
			}
		</Popup>
	)
}

export default AuthentificationSecure
