import { createSelector } from 'reselect'
import { Map, Set } from 'immutable'
import { getOrder, TypeAdherent } from '../../../constant/TypeAdherent';
import FormatUtils from '../../../utils/FormatUtils';
import { estMajeurFinAnnee } from '../../../utils/dateUtils';

export const getAffiliation = state => state.affiliation.data
export const isAffiliationLoading = state => state.affiliation.loading
export const getExpirationDate = state => state.affiliation.expirationDate
export const getAffiliationAssurePrincipal = state => state.affiliation.data.assurePrincipal
export const getAffiliationBeneficiaires = createSelector(
	getAffiliation,
	(affiliation) => {
		const now = new Date()
		return (
			affiliation.beneficiaires ? affiliation.beneficiaires.filter((beneficiaire) => {
				if (beneficiaire.garanties && beneficiaire.garanties.length > 0) {
					for (const garantie of beneficiaire.garanties) {
						const fin = garantie.dateFin ? FormatUtils.toLocalDate(garantie.dateFin) : null
						const debut = garantie.dateDebut ? FormatUtils.toLocalDate(garantie.dateDebut) : null
						if ((!fin || now < fin) && debut && debut < now) {
							return true
						}
					}
				}
				return false
			}) : []
		)
	}
)
export const getAffiliationBeneficiairesForRefund = createSelector(
	getAffiliation,
	(affiliation) => {
		const now = new Date()
		return (
			affiliation.beneficiaires ? affiliation.beneficiaires.filter((beneficiaire) => {
				if (beneficiaire.garanties && beneficiaire.garanties.length > 0) {
					for (const garantie of beneficiaire.garanties) {
						const fin = garantie.dateFin ? FormatUtils.toLocalDate(garantie.dateFin) : null
						//On veut aussi les garanties dont la date de couverture date de moins de 2 ans
						fin && fin.setFullYear(fin.getFullYear() + 2)
						const debut = garantie.dateDebut ? FormatUtils.toLocalDate(garantie.dateDebut) : null

						if ((!fin || now < fin) && debut && debut < now) {
							return true
						}
					}
				}
				return false
			}) : []
		)
	}
)
export const getAffiliationBeneficiairesForManage = createSelector(
	getAffiliation,
	(affiliation) => {
		const now = new Date()
		return (
			affiliation.beneficiaires ? affiliation.beneficiaires.filter((beneficiaire) => {
				if (beneficiaire.garanties && beneficiaire.garanties.length > 0) {
					for (const garantie of beneficiaire.garanties) {
						const fin = garantie.dateFin ? FormatUtils.toLocalDate(garantie.dateFin) : null

						if (!fin || now < fin) {
							return true
						}
					}
				}
				return false
			}) : []
		)
	}
)

/**
 * A utiliser lorsqu'on ne fait pas confiance au simple `assurePrincipal`.
 *
 * @return tous les assurés principaux des affiliations
 */
export const getTousAssuresPrincipaux = createSelector([
	getAffiliationAssurePrincipal,
	getAffiliationBeneficiaires
], (assurePrincipal, beneficiaires) => {
	return [assurePrincipal, ...beneficiaires]
		.filter(indiv => indiv && indiv.typAdr === TypeAdherent.ASSURE_PRINCIPAL)
})

export const sortIndividus = (ind1, ind2) => {
	// tri par type adhérent (assurePrincipal, Conjoint, Enfant)
	if (getOrder(ind1.typAdr) < getOrder(ind2.typAdr)) {
		return -1
	} else if (getOrder(ind1.typAdr) > getOrder(ind2.typAdr)) {
		return 1
	}
	// Tri des enfants par date de naissance
	const dateNaissanceInd1 = FormatUtils.parseDate(ind1.dateNaissance)
	const dateNaissanceInd2 = FormatUtils.parseDate(ind2.dateNaissance)

	if (dateNaissanceInd1 < dateNaissanceInd2) {
		return -1
	}
	return 1
}

export const getIndividuList = createSelector(
	getAffiliationAssurePrincipal,
	getAffiliationBeneficiaires,
	(assurePrincipal, beneficiaires) => {
		const individuMap = new Map().withMutations(map => {
			assurePrincipal && map.set(assurePrincipal.numIndividu, assurePrincipal)
			beneficiaires && beneficiaires.forEach((b) => {
				if (!map.get(b.numIndividu)) {
					map.set(b.numIndividu, b)
				}
			})
		})
		return individuMap.sort(sortIndividus).toArray()
	}
)

export const getIndividuListForRefund = createSelector(
	getAffiliationAssurePrincipal,
	getAffiliationBeneficiairesForRefund,
	(assurePrincipal, beneficiaires) => {
		const individuMap = new Map().withMutations(map => {
			assurePrincipal && map.set(assurePrincipal.numIndividu, assurePrincipal)
			beneficiaires && beneficiaires.forEach((b) => {
				if (!map.get(b.numIndividu)) {
					map.set(b.numIndividu, b)
				}
			})
		})
		return individuMap.sort(sortIndividus).toArray()
	}
)

export const getIndividuListForManage = createSelector(
	getAffiliationAssurePrincipal,
	getAffiliationBeneficiairesForManage,
	(assurePrincipal, beneficiaires) => {
		const individuMap = new Map().withMutations(map => {
			assurePrincipal && map.set(assurePrincipal.numIndividu, assurePrincipal)
			beneficiaires && beneficiaires.forEach((b) => {
				if (!map.get(b.numIndividu)) {
					map.set(b.numIndividu, b)
				}
			})
		})
		return individuMap.sort(sortIndividus).toArray()
	}
)

export const getEnfantMajeurList = createSelector(
	getIndividuList,
	(individuList) =>
		(
			individuList && individuList.filter(individu => {
				return ((individu[1].typAdr === TypeAdherent.ENFANTS || individu[1].typAdr === TypeAdherent.ENFANT_INVALIDE)
					&& estMajeurFinAnnee(individu[1].dateNaissance))
			})
		)
)

export const getPorteursRib = createSelector([
	getIndividuList
], (individus) => {
	return new Set().withMutations(_set => {
		individus.forEach(i => {
			if (i[1].porteurRib) {
				_set.add(i[1].porteurRib)
			}
		})
	})
})
