import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {cardiboxActions} from 'redux-cardibox'
import {createSelector} from 'reselect'
import {isDemandesLoading} from '../../../webservices/demandes/demandesSelectors'
import {profileSelector} from '../../user/userSelectors'
import {
	getMentionsLegales,
	isLoading as isLoadingMentionsLegales
} from '../../../webservices/servicesSante/mentionsLegales/servicesSanteMentionsLegalesSelectors'
import {
	getMentionsLegales as loadMentionsLegales,
	getServices as loadServices
} from '../../../webservices/servicesSante/servicesSanteActions'
import {getServices} from '../../../webservices/servicesSante/servicesSanteSelectors'
import PageLayout from '../../../../components/PageLayout'
import {useIntl} from 'react-intl'
import Loader from '../../../../components/Loader'
import PolymorphicBlock from '../components/PolymorphicBlock'
import appConst from '../../../../constant/appConstants'
import { Box, Stack } from '@mui/material'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

const SHORTCUT_SERVICE_SANTE_FOLDER = 'ea_services_sante'

const ServicesSantesPage = (
	{
		// @ts-ignore
		loadServices,
		// @ts-ignore
		loadMentionsLegales,
		// @ts-ignore
		loadFolders,
		// @ts-ignore
		services,
		// @ts-ignore
		loading
	}
) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		loadServices()
		loadMentionsLegales()
		loadFolders(appConst.cardiboxApi, SHORTCUT_SERVICE_SANTE_FOLDER)
		setIsLoading(false)
	}, [loadServices, loadMentionsLegales, loadFolders])

	if (loading || isLoading) {
		return <Loader />
	}

	return (
		<PageLayout title={intl.formatMessage({ id: 'servicesSante.title' })}>
			<Stack direction="row" flexWrap="wrap">
				{services.map((service: any, index: number) => (
					<>
						<Box
							display="flex"
							flex={(!isMobile && !isTabletPortrait) ? '1 1 calc(50% - 12px)' : '100%'}
							maxWidth={(!isMobile && !isTabletPortrait) ? 'calc(50% - 12px)': '100%'}
							marginLeft={((index + 1) % 2 === 0 && (!isMobile && !isTabletPortrait)) ? '24px' : undefined}
							height="min-content"
						>
							<PolymorphicBlock service={service} />
						</Box>
						{((index + 1) % 2 === 0 || isMobile || isTabletPortrait) && <Box width="100%" height="24px" />}
					</>
				))}
			</Stack>
		</PageLayout>
	)
}

const mapStateToProps = createSelector([
	isDemandesLoading,
	isLoadingMentionsLegales,
	getServices,
	getMentionsLegales,
	profileSelector
], (demandesLoading, mentionsLegalesLoading, services, mentionsLegales, profile) => ({
	loading: demandesLoading || mentionsLegalesLoading,
	services,
	mentionsLegales,
	accessPage: profile.servicesSante
}))

const mappedActions = {
	loadServices,
	loadFolders: cardiboxActions.folders.getSubFolders,
	loadMentionsLegales
}

export default connect(mapStateToProps, mappedActions)(ServicesSantesPage)
