import GerepAPI from './GerepAPI'
import appConst from '../../constant/appConstants';

export default class DemandeServices {
	static getHistoriqueDemandes(token) {
		return GerepAPI.get('/demandes', null, token)
	}

	static downloadDocument(idDemande, idDocument, token) {
		return GerepAPI.get('/ticket', null, token)
			.then(ticket => {
				window.location = `${appConst.gerepApi.baseUrl}/demandes/${idDemande}/${idDocument}?token=${ticket}`
			})
	}

	static addAffichagePopinNotation(token, closePermanently, clicLien) {
		return GerepAPI.post('/notation', { blocage: closePermanently, clicLien }, token)
	}
}
