import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import {
	Box,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography
} from '@mui/material'
import FormatUtils from '../../../../utils/FormatUtils'
import DownloadDocumentLink from './DownloadDocumentLink'
import demandeUtils from '../../../../utils/demandeUtils'
import { visuallyHidden } from '@mui/utils'


type TableauHistoriqueDemandesProps = {
	demandes: any
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (orderBy === 'etat') {
		// @ts-ignore
		if (demandeUtils.demandLabelStatus(b[orderBy]) < demandeUtils.demandLabelStatus(a[orderBy])) {
			return -1
		}
		// @ts-ignore
		if (demandeUtils.demandLabelStatus(b[orderBy]) > demandeUtils.demandLabelStatus(a[orderBy])) {
			return 1
		}
		return 0
	}
	if (orderBy === 'dateDemande') {
		// @ts-ignore
		return (new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf())
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}
		if (b[orderBy] > a[orderBy]) {
			return 1
		}
		return 0
	}
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string }
) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

interface Data {
	etat: string,
	dateDemande: Date,
	type: string,
	beneficiaire: string
}

interface EnhancedTableProps {
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void,
	order: Order,
	orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort } =
		props
	const createSortHandler =
		(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property)
		}

	return (
		<TableHead>
			<TableRow>
				<TableCell
					key="etat"
					align="left"
					sortDirection={orderBy === 'etat' ? order : false}
					size="small"
				>
					<TableSortLabel
						active={orderBy === 'etat'}
						direction={orderBy === 'etat' ? order : 'asc'}
						onClick={createSortHandler('etat')}
					>
						{orderBy === 'etat' ? (
							<Box component="span" sx={visuallyHidden}>
								{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
							</Box>
						) : null}
						<Typography flex={1} variant="body2">
							<FormattedMessage id="suivie_Demande.tableau.header.statut" />
						</Typography>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="dateDemande"
					align="left"
					sortDirection={orderBy === 'dateDemande' ? order : false}
					size="small"
				>
					<TableSortLabel
						active={orderBy === 'dateDemande'}
						direction={orderBy === 'dateDemande' ? order : 'asc'}
						onClick={createSortHandler('dateDemande')}
					>
						{orderBy === 'dateDemande' ? (
							<Box component="span" sx={visuallyHidden}>
								{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
							</Box>
						) : null}
						<Typography flex={1} variant="body2">
							<FormattedMessage id="suivie_Demande.tableau.header.date" />
						</Typography>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="type"
					align="left"
					sortDirection={orderBy === 'type' ? order : false}
				>
					<TableSortLabel
						active={orderBy === 'type'}
						direction={orderBy === 'type' ? order : 'asc'}
						onClick={createSortHandler('type')}
					>
						{orderBy === 'type' ? (
							<Box component="span" sx={visuallyHidden}>
								{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
							</Box>
						) : null}
						<Typography flex={1} variant="body2">
							<FormattedMessage id="suivie_Demande.tableau.header.type" />
						</Typography>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="beneficiaire"
					align="left"
					sortDirection={orderBy === 'beneficiaire' ? order : false}
				>
					<TableSortLabel
						active={orderBy === 'beneficiaire'}
						direction={orderBy === 'beneficiaire' ? order : 'asc'}
						onClick={createSortHandler('beneficiaire')}
					>
						{orderBy === 'beneficiaire' ? (
							<Box component="span" sx={visuallyHidden}>
								{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
							</Box>
						) : null}
						<Typography flex={1} variant="body2">
							<FormattedMessage id="suivie_Demande.tableau.header.beneficiaire" />
						</Typography>
					</TableSortLabel>
				</TableCell>
				<TableCell align="left">
					<Typography flex={1} variant="body2">
						<FormattedMessage id="suivie_Demande.tableau.header.document" />
					</Typography>
				</TableCell>
			</TableRow>
		</TableHead>
	)
}


const TableauHistoriqueDemandes: FC<TableauHistoriqueDemandesProps> = (
	{
		demandes
	}
) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const [order, setOrder] = useState<Order>('desc')
	const [orderBy, setOrderBy] = useState<keyof Data>('dateDemande')

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	return (
		(!demandes || demandes.length === 0) ? (
			<Stack direction="column" spacing="24px">
				<Typography alignSelf="center" flex={10} variant="h3">
					<FormattedMessage id="suivie_Demande.emptyState" />
				</Typography>
			</Stack>
		) : (
			(isMobile || isTabletPortrait) ? (
				<TableContainer>
					<Table aria-label="custom table">
						<TableBody>
							{demandes.map((demande: any, index: any) => (
								<>
									<TableRow key={index + '_statut'}>
										<TableCell sx={{ border: 'none', paddingBottom: '10px' }} align="left">
											<Typography flex={1} variant="body2">
												<FormattedMessage id="suivie_Demande.tableau.header.statut" />
											</Typography>
										</TableCell>
										<TableCell sx={{ border: 'none', paddingBottom: '10px' }} align="left">
											{demandeUtils.demandStatus(demande.etat, { variant: 'body2', fontWeight: 600 })}
										</TableCell>
									</TableRow>
									<TableRow key={index + '_date'}>
										<TableCell sx={{ border: 'none', paddingBottom: '12px', paddingTop: '10px' }} align="left">
											<Typography flex={3} variant="body2">
												<FormattedMessage id="suivie_Demande.tableau.header.date" />
											</Typography>
										</TableCell>
										<TableCell sx={{ border: 'none', paddingBottom: '12px', paddingTop: '10px' }} align="left">
											<Typography flex={3} variant="body2" fontWeight={600}>
												{FormatUtils.formatDate(demande.dateDemande)}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow key={index + '_type'}>
										<TableCell sx={{ border: 'none', paddingBottom: '12px', paddingTop: '10px' }} align="left">
											<Typography flex={1} variant="body2">
												<FormattedMessage id="suivie_Demande.tableau.header.type" />
											</Typography>
										</TableCell>
										<TableCell sx={{ border: 'none', paddingBottom: '12px', paddingTop: '10px' }} align="left">
											<Typography flex={1} variant="body2" fontWeight={600}>
												{demande.libelle}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow key={index + '_beneficiaire'}>
										<TableCell sx={{ border: 'none', paddingBottom: '12px', paddingTop: '10px' }} align="left">
											<Typography flex={1} variant="body2">
												<FormattedMessage id="suivie_Demande.tableau.header.beneficiaire" />
											</Typography>
										</TableCell>
										<TableCell sx={{ border: 'none', paddingBottom: '12px', paddingTop: '10px' }} align="left">
											<Typography flex={1} variant="body2" fontWeight={600}>
												{demande.beneficiaire}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow key={index + '_document'}>
										<TableCell sx={{ border: 'none', borderBottom: '2px solid #dedede', paddingTop: '10px' }} align="left">
											<Typography flex={1} variant="body2">
												<FormattedMessage id="suivie_Demande.tableau.header.document" />
											</Typography>
										</TableCell>
										<TableCell sx={{ border: 'none', borderBottom: '2px solid #dedede', paddingTop: '10px' }} align="left">
											<Typography flex={1} variant="body2" fontWeight={600}>
												{
													demande.documents && demande.documents.map((document: any) => {
														return (
															<DownloadDocumentLink
																idDemande={demande.idDemande}
																document={document}
															/>
														)
													})
												}
											</Typography>
										</TableCell>
									</TableRow>
								</>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<TableContainer>
					<Table sx={{ minWidth: 500 }} aria-label="custom table">
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
						/>
						<TableBody>
							{demandes.sort(getComparator(order, orderBy)).map((demande: any, index: any) => (
								<TableRow key={index}>
									<TableCell component="th" scope="row" align="left" size="small">
										{demandeUtils.demandStatus(demande.etat, { variant: 'body2', fontWeight: 600, withoutPadding: true })}
									</TableCell>
									<TableCell component="th" scope="row" align="left" size="small">
										<Typography flex={3} variant="body2" fontWeight={600}>
											{FormatUtils.formatDate(demande.dateDemande)}
										</Typography>
									</TableCell>
									<TableCell component="th" scope="row" align="left">
										<Typography flex={1} variant="body2" fontWeight={600} sx={{ wordBreak: 'break-word' }}>
											{demande.libelle}
										</Typography>
									</TableCell>
									<TableCell component="th" scope="row" align="left">
										<Typography flex={1} variant="body2" fontWeight={600} sx={{ wordBreak: 'break-word' }}>
											{demande.beneficiaire}
										</Typography>
									</TableCell>
									<TableCell component="th" scope="row" align="left">
										<Stack direction="column" spacing={1}>
											{
												demande.documents && demande.documents.map((document: any) => {
													return (
														<DownloadDocumentLink
															idDemande={demande.idDemande}
															document={document}
														/>
													)
												})
											}
										</Stack>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)
		)
	)
}

export default TableauHistoriqueDemandes
