import React from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { format } from 'date-fns'
import FormatUtils from '../../../../utils/FormatUtils'
import { fr } from 'date-fns/locale'

type RefundCellProps = {
	remboursement: any
}

const RefundCell: React.FC<RefundCellProps> = ({ remboursement }) => {
	const payday = new Date(FormatUtils.parseDate(remboursement[1].datePay))

	return (
		<Stack direction="row" justifyContent="space-between">
			<Stack direction="row" spacing={1} alignItems="start">
				<Box height="100%" alignItems="center">
					<CheckCircle sx={{ color: '#4bc4c2', width: '24px', height: '24px' }} />
				</Box>
				<Stack direction="column">
					<Typography variant="h4">
						<FormattedMessage id="dashboard.refund.entry.date" values={{ date: format(payday, 'd MMM y', { locale: fr }) }} />
					</Typography>
					<Typography variant="subtitle1" sx={{ color: '#7a7a7a' }}>
						<FormattedMessage id="dashboard.refund.entry.to" values={{ to: remboursement[1].destinataire }} />
					</Typography>
				</Stack>
			</Stack>
			<Typography variant="h3">
				<FormattedNumber value={remboursement[1].total || 0} style="currency" currency="EUR" />
			</Typography>
		</Stack>
	)
}

export default RefundCell
