import { isSanteClairTokenLoading, getExpirationDate, getSanteClairToken } from './reseauSoinSelector'
import ReseauSoinsService from '../../../../api/gerep/ReseauSoinsService';
import LocalStorage from '../../../../business/storage/LocalStorage';
import SanteClairService from '../../../../api/gerep/SanteClairService';

const ACTION_NAMESPACE = '/RESEAU_SOIN'

export const types = {
	LOAD_SANTECLAIR_TOKEN: `${ACTION_NAMESPACE}/LOAD_TOKEN_SANTECLAIR`,
	SANTECLAIR_TOKEN_LOADING: `${ACTION_NAMESPACE}/TOKEN_SANTECLAIR_LOADING`
}

const isExpired = state => (getExpirationDate(state) || 0) < (+new Date())

export const getTypes = () => (
	ReseauSoinsService.getTypes(LocalStorage.getToken())
)

export const search = values => (
	ReseauSoinsService.search(values, LocalStorage.getToken())
)

export const loadTokenSanteClair = (force = false) => (dispatch, getState) => {
	if (!isSanteClairTokenLoading(getState()) && isExpired(getState()) || force) {
		dispatch({ type: types.SANTECLAIR_TOKEN_LOADING, payload: true })
		return SanteClairService.getToken(LocalStorage.getToken())
			.then((response) => {
				dispatch({ type: types.SANTECLAIR_TOKEN_LOADING, payload: false })
				return dispatch({
					type: types.LOAD_SANTECLAIR_TOKEN,
					payload: response && response.token
				})
			}).catch((e) => {
				dispatch({ type: types.SANTECLAIR_TOKEN_LOADING, payload: false })
				throw e
			})
	}
	return Promise.resolve(getSanteClairToken(getState()))
}
