import React, {useState} from 'react'
import {TextField} from '@mui/material'
import {Field} from 'react-final-form'
import {Visibility, VisibilityOff} from '@mui/icons-material'
import Popover from '../Popover'
import {mdiInformation} from '@mdi/js'

type TextInputProps = {
	name: string
	label?: string | React.ReactNode
	helperText?: string
	size?: 'small' | 'medium'
	type?: 'text' | 'password' | 'email' | 'number'
	popoverContent?: React.ReactNode
	placeholder?: string
	showPasswordToggle?: boolean
	maxLength?: number
	validate?: (value: string) => string | undefined
	numberOnly?: boolean
	maxWidth?: string
	error?: string
	defaultValue?: string
	disabled?: boolean
	rows?: number
	onChange?: (newValue: any) => void
	rightIcon?: React.ReactNode
	style?: React.CSSProperties
}

const TextInputRembFilter: React.FC<TextInputProps> = (
	{
		name,
		label,
		helperText,
		size = 'small',
		type = 'text',
		popoverContent,
		placeholder,
		showPasswordToggle = true,
		validate,
		maxLength,
		numberOnly = false,
		maxWidth = '350px',
		error,
		defaultValue,
		disabled = false,
		rows = 1,
		onChange,
		rightIcon,
		style
	}) => {
	const [showPassword, setShowPassword] = useState<boolean>(false)

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const numberOnlyProps = numberOnly ? { inputMode: 'numeric' as 'numeric', pattern: '[0-9]*' } : {}

	return (
		<Field name={name} validate={validate} initialValue={defaultValue}>
			{({ input, meta }) => {
				const handleChange = (newValue: any) => {
					const regex = /^[0-9\b]+$/
					if (numberOnly && newValue && regex.test(newValue)) {
						input.onChange(newValue)
						onChange && onChange(newValue)
					} else if (!numberOnly) {
						input.onChange(newValue)
						onChange && onChange(newValue)
					}
				}

				return (
					<TextField
						multiline={rows > 1}
						rows={rows}
						disabled={disabled}
						type={type !== 'password' ? type : showPassword ? 'text' : 'password'}
						error={!!error || ((meta.touched || meta.dirty || meta.modified) && (meta.error || meta.submitError))}
						placeholder={placeholder}
						label={label}
						color="secondary"
						size={size}
						helperText={((meta.touched || meta.dirty || meta.modified) && meta.error) || ((meta.touched || meta.dirty || meta.modified) && meta.submitError) || helperText || error}
						value={input.value}
						onChange={(e: any) => {
							handleChange(e.target.value)
						}}
						style={{ width: '100%', maxWidth: maxWidth }}
						sx={{
							'& input': {
								...style
							}
						}}
						inputProps={{ maxLength, ...numberOnlyProps }}
						InputProps={{
							endAdornment: rightIcon ? rightIcon : popoverContent ? (
								<Popover iconPath={mdiInformation}>
									{popoverContent}
								</Popover>

							) : type === 'password' && showPasswordToggle ? (
								<span
									style={{ color: '#606060' }}
									onClick={handleClickShowPassword}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</span>
							) : null
						}}
					/>
				)
			}}
		</Field>
	)
}

export default TextInputRembFilter
