import { createSelector } from 'reselect'

export const getCarteTP = state => state.carteTP.data
export const isCarteTPLoading = state => state.carteTP.loading
export const getExpirationDate = state => state.carteTP.expirationDate

export const getCarteTPActives = createSelector(
	getCarteTP,
	(listeCarteTP) => {
		const now = new Date()
		return listeCarteTP
			.filter((carteTP) => {
				const fin = carteTP.assure.fin ? new Date(carteTP.assure.fin) : null
				return fin && now < fin
			})
			.sort((carte1, carte2) => new Date(carte2.assure.fin) - new Date(carte1.assure.fin))
	}
)
