import React from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import BankAccountRemboursementCell from './BankAccountRemboursementCell'
import BankAccountCotisationCell from './BankAccountCotisationCell'

type BankAccountCellProps = {
	individuList: any[]
	ribs: any[]
	rib: any
	assurePrincipal: any
	profile: any
	echeancier: any[]
}

const BankAccountInfo: React.FC<BankAccountCellProps> = (
	{
		ribs,
		rib,
		individuList,
		assurePrincipal,
		profile,
		echeancier
	}) => {
	const mainRib = ribs.filter((rib) => rib.numindiv === assurePrincipal.numIndividu)[0]
	const otherRibs = ribs.filter((rib) => rib.numindiv !== assurePrincipal.numIndividu)

	// on n'affiche que les beneficiaire du même type de prélèvement que l'assure principal
	const virement = mainRib?.bban

	return (
		<Stack direction="column" spacing="24px">
			<Typography variant="h2">
				<FormattedMessage id="personalInfo.bankInfo.title" />
			</Typography>
			<Stack direction="column" spacing="14px" pl="24px" pr="12px">
				<BankAccountRemboursementCell ribInfo={mainRib} ribs={ribs} individuList={individuList} assurePrincipal={assurePrincipal} />
				{virement && (
					otherRibs.filter((rib: any) => rib.bban).map((ri: any, index: number) => (
						<BankAccountRemboursementCell key={index} ribInfo={ri} ribs={ribs} individuList={individuList} assurePrincipal={assurePrincipal} />
					))
				)}
				{profile?.cotisations && <BankAccountCotisationCell echeancier={echeancier} ribInfo={rib} ribs={ribs} individuList={individuList} assurePrincipal={assurePrincipal} />}
			</Stack>
			<Divider />
		</Stack>
	)
}

export default BankAccountInfo