import React, {useEffect, useState} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import Loader from '../../../../components/Loader'
import {Divider, Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import PageLayoutEE from '../../../../components/PageLayoutEE'
import RadialBarChart from '../components/RadialBarChart'
import PieChartChart from '../components/PieChartChart'
import GridChart from '../components/GridChart'
import {isDataKPILoading, santeConsommationSelector} from '../../data/dataSelectors'
import AnneeSelector from '../../components/AnneeSelector'
import FormatUtils from '../../../../utils/FormatUtils'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import appConst from '../../../../constant/appConstants'
import EmptyResult from '../../components/EmptyResult'
import PieChartConsommationEuro from "../components/PieChartConsommationEuro";
import PieChartConsommationActe from "../components/PieChartConsommationActe";


const ARTICLE_CONSO_FIRST_RADIAL_CHART = 'conso_first_radial_chart'
const ARTICLE_CONSO_SECOND_RADIAL_CHART = 'conso_second_radial_chart'
const ARTICLE_CONSO_PIE_EURO = 'conso_pie_chart_euro'
const ARTICLE_CONSO_PIE_ACTE = 'conso_pie_chart_acte'
const ARTICLE_CONSO_GRID = 'conso_grid_chart'


const SanteConsommationPageEE = (
	{
		// @ts-ignore
		loading,
		// @ts-ignore
		santeConsommation,
		// @ts-ignore
		getArticleByShortcut,
		// @ts-ignore
		consoFirstRadialChartArticle,
		// @ts-ignore
		consoSecondRadialChartArticle,
		// @ts-ignore
		consoPieEuroChartArticle,
		// @ts-ignore
		consoPieActeChartArticle,
		// @ts-ignore
		consoGridChartArticle
	}
) => {

	const intl = useIntl()

	const { isMobile, isTabletPortrait } = useBreakpoints()
	const isSmallScreen = isMobile || isTabletPortrait
	const [annee, setAnnee] = useState<number>(Number(Object.keys(santeConsommation)[2]))
	const santeConsoAnneeN = santeConsommation[annee.toString()]
	const santeConsoAnneeNMoins1 = santeConsommation[(annee - 1).toString()]

	useEffect(() => {
		setAnnee(Number(Object.keys(santeConsommation)[2]))
	}, [santeConsommation])


	function handleChangeYear(value:number){
		setAnnee(value)
	}

	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_CONSO_FIRST_RADIAL_CHART)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_CONSO_SECOND_RADIAL_CHART)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_CONSO_PIE_ACTE)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_CONSO_PIE_EURO)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_CONSO_GRID)
	}, [getArticleByShortcut])

	const nothingToShow = (consoAnneeX: any) => {
		if (consoAnneeX !== undefined) {
			if(consoAnneeX.donneesActualisees){
				return false
			}
			return true
		}
	}

	const consoAnneeSelector = () => {
		const selector = [
            {
                value: Object.keys(santeConsommation)[2],
                label: Object.keys(santeConsommation)[2]
            }
        ]
        if (!nothingToShow(santeConsommation[Object.keys(santeConsommation)[1]])
            || (nothingToShow(santeConsommation[Object.keys(santeConsommation)[1]]) && !nothingToShow(santeConsommation[Object.keys(santeConsommation)[0]]))) {
            selector.push(
                {
                    value: Object.keys(santeConsommation)[1],
                    label: Object.keys(santeConsommation)[1]
                }
            )
        }
        if (!nothingToShow(santeConsommation[Object.keys(santeConsommation)[0]])) {
            selector.push(
                {
                    value: Object.keys(santeConsommation)[0],
                    label: Object.keys(santeConsommation)[0]
                }
            )
        }
		return selector
	}

	if (loading || santeConsoAnneeN === undefined
		|| !consoFirstRadialChartArticle || !consoSecondRadialChartArticle || !consoPieActeChartArticle || !consoPieEuroChartArticle || !consoGridChartArticle) {
		return <Loader/>
	} else {
		return (
			<PageLayoutEE
				title={intl.formatMessage({id: 'sante.consommation.title'})}
				subtitle={santeConsoAnneeN.donneesActualisees && !nothingToShow(santeConsoAnneeN) && (
					intl.formatMessage({id: 'global.majData'}) + santeConsoAnneeN.donneesActualisees
				)}
			>
				<Stack direction="column" spacing="24px">
					<Stack direction="column" alignSelf="center" width={isSmallScreen ? '100%' : '50%'}>
						<AnneeSelector
							onChange={handleChangeYear}
							name="toggleYears"
							stateValue={annee}
							data={consoAnneeSelector()}
						/>
					</Stack>

					<Divider style={{border: '1px solid #dedede'}}/>

					{nothingToShow(santeConsoAnneeN) ?
						(
							<EmptyResult
								nature="PAGE"
								message={
									<FormattedMessage
										id="global.noPageData"
										values={{titrePage:
												<Typography
													variant="body2"
													fontSize="0,875rem"
													fontWeight="bold"
													color="#929292"
												>
													{intl.formatMessage({id: 'sante.consommation.title'})}
												</Typography>
										}}
									/>
								}
							/>
						) : (
							<Stack direction="column" spacing="24px" alignItems={isSmallScreen ? 'center' : undefined}>
								<Stack direction={isSmallScreen ? 'column' : 'row'} spacing="24px">
									<Stack
										direction="column"
										flex="1"
										sx={{
											borderRight: isSmallScreen ? undefined : '1px solid rgb(222, 222, 222)',
											borderBottom: isSmallScreen ? '1px solid rgb(222, 222, 222)' : undefined,
											paddingBottom: isSmallScreen ? '24px' : undefined
										}}
									>
										<RadialBarChart
											isInt={true}
											id_title={consoFirstRadialChartArticle && consoFirstRadialChartArticle.fields.title}
											idChart="sante_beneficiaires"
											annee={annee.toString()}
											middleLabel={Math.round(santeConsoAnneeN.dataBeneficiaire[1].value / santeConsoAnneeN.dataBeneficiaire[0].value * 100).toString() + "% \n d'utilisation"}
											dataChart={santeConsoAnneeN.dataBeneficiaire}
										/>

										{santeConsoAnneeN.dataBeneficiaire
											&& (santeConsoAnneeN.dataBeneficiaire[0].value !== 0 || santeConsoAnneeN.dataBeneficiaire[1].value !==0) ?
											<>
												<Stack
													direction={isMobile ? 'column' : 'row'}
													width="unset"
													justifyContent="space-around"
													alignItems="center"
													spacing={isMobile ? '20px' : 0}
													mt={isMobile? '-40px' : 0}
												>
													<Stack direction="column" >
														<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
															<FormattedMessage id="sante.consommation.utilisationChart.beneficiairesTitle"/>
														</Typography>
														<Stack direction="row" alignItems="baseline" spacing="2px">
															<Typography fontSize="2rem" fontWeight="600" color="#b5bbff" variant="body1">
																{intl.formatNumber(santeConsoAnneeN.nbBeneficaire)}
															</Typography>
															<Typography variant="subtitle1" fontSize="0.813rem">
																en {santeConsoAnneeN.annee}
															</Typography>
														</Stack>
														{(santeConsoAnneeNMoins1 && santeConsoAnneeNMoins1.nbBeneficaire > 0) &&
															<Stack direction="row" alignItems="baseline" spacing="2px">
																<Typography fontWeight="600" variant="body1" fontSize="1rem">
																	{intl.formatNumber(santeConsoAnneeNMoins1.nbBeneficaire)}
																</Typography>
																<Typography variant="subtitle1" fontSize="0.8713rem">
																	en {santeConsoAnneeNMoins1.annee}
																</Typography>
															</Stack>
														}
													</Stack>

													<Stack direction="column" >
														<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
															<FormattedMessage id="sante.consommation.utilisationChart.utilisateursTitle"/>
														</Typography>
														<Stack direction="row" alignItems="baseline" spacing="2px">
															<Typography fontSize="2rem" fontWeight="600" color="#e96e11" variant="body1">
																{intl.formatNumber(santeConsoAnneeN.nbUtilisateur)}
															</Typography>
															<Typography variant="subtitle1" fontSize="0.813rem">
																en {santeConsoAnneeN.annee}
															</Typography>
														</Stack>
														{( santeConsoAnneeNMoins1 && santeConsoAnneeNMoins1.nbUtilisateur) > 0 &&
															<Stack direction="row" alignItems="baseline" spacing="2px">
																<Typography fontWeight="600" variant="body1" fontSize="1rem">
																	{intl.formatNumber(santeConsoAnneeNMoins1.nbUtilisateur)}
																</Typography>
																<Typography variant="subtitle1" fontSize="0.813rem">
																	en {santeConsoAnneeNMoins1.annee}
																</Typography>
															</Stack>
														}
													</Stack>
												</Stack>
												{santeConsoAnneeN.donneesActualisees !== santeConsoAnneeN.dateConsoGraphs &&
														<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" mr="8px" pt="4px">
															{intl.formatMessage({id: 'global.majData'}) + santeConsoAnneeN.dateConsoGraphs}
														</Typography>
												}
											</> : undefined
										}
									</Stack>

									<Stack direction="column" flex="1">
										<RadialBarChart
											isInt={false}
											id_title={consoSecondRadialChartArticle && consoSecondRadialChartArticle.fields.title}
											idChart="sante_couvertures"
											annee={annee.toString()}
											middleLabel={Math.round(santeConsoAnneeN.dataCouverture[1].value / santeConsoAnneeN.dataCouverture[0].value * 100).toString() + "% \n de couverture"}
											dataChart={santeConsoAnneeN.dataCouverture}
										/>
										{santeConsoAnneeN.dataCouverture
											&& (santeConsoAnneeN.dataCouverture[0].value !== 0 || santeConsoAnneeN.dataCouverture[1].value !== 0) ?
											<>
												<Stack
													pl={isSmallScreen? '0px' : '32px'}
													mt={isMobile ? '-20px' : 0}
													direction={isMobile? 'column' : 'row'}
													spacing={isMobile? '20px' : 0}
													alignItems="center"
													justifyContent="space-around"
												>
													<Stack flex="1" direction="column" >
														<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
															<FormattedMessage id="sante.consommation.couvertureChart.depensesTitle" />
														</Typography>
														<Stack direction="row" alignItems="baseline" spacing="2px">
															<Typography fontSize="2rem" fontWeight="600" color="#8ac11d" variant="body1">
																{FormatUtils.formatNumber(santeConsoAnneeN.depense) + "€"}
															</Typography>
															<Typography variant="subtitle1" fontSize="0.813rem">
																en {santeConsoAnneeN.annee}
															</Typography>
														</Stack>
														{(santeConsoAnneeNMoins1 && santeConsoAnneeNMoins1.depense > 0) &&
															<Stack direction="row" alignItems="baseline" spacing="2px" fontSize="1rem">
																<Typography fontWeight="600" variant="body1">
																	{FormatUtils.formatNumber(santeConsoAnneeNMoins1.depense) + "€"}
																</Typography>
																<Typography variant="subtitle1" fontSize="0.813rem">
																	en {santeConsoAnneeNMoins1.annee}
																</Typography>
															</Stack>
														}
													</Stack>

													<Stack flex="1" direction="column" >
														<Typography fontWeight="500" variant="body1">
															<FormattedMessage id="sante.consommation.couvertureChart.remboursementsTitle" />
														</Typography>
														<Stack direction="row" alignItems="baseline" spacing="2px">
															<Typography fontSize="2rem" fontWeight="600" color="#4bc4c2" variant="body1">
																{FormatUtils.formatNumber(santeConsoAnneeN.remboursement) + "€"}
															</Typography>
															<Typography variant="subtitle1" fontSize="0.813rem">
																en {santeConsoAnneeN.annee}
															</Typography>
														</Stack>
														{(santeConsoAnneeNMoins1 && santeConsoAnneeNMoins1.remboursement > 0) &&
															<Stack direction="row" alignItems="baseline" spacing="2px" fontSize="1rem">
																<Typography fontWeight="600" variant="body1">
																	{FormatUtils.formatNumber(santeConsoAnneeNMoins1.remboursement) + "€"}
																</Typography>
																<Typography variant="subtitle1" fontSize="0.813rem">
																	en {santeConsoAnneeNMoins1.annee}
																</Typography>
															</Stack>
														}
													</Stack>
												</Stack>
												{santeConsoAnneeN.donneesActualisees !== santeConsoAnneeN.dateConsoGraphs &&
													<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" pt="4px">
														{intl.formatMessage({id: 'global.majData'}) + santeConsoAnneeN.dateConsoGraphs}
													</Typography>
												}
											</> : undefined
										}
									</Stack>
								</Stack>

								<Divider style={{border: '1px solid #dedede', width: '100%'}}/>

								<Stack direction={isSmallScreen ? 'column' : 'row'} spacing="24px">
									<Stack
										direction="row"
										flex="1"
										sx={{
											borderRight: isSmallScreen ? undefined : '1px solid rgb(222, 222, 222)',
											borderBottom: isSmallScreen ? '1px solid rgb(222, 222, 222)' : undefined,
										}}
										pb={isSmallScreen ? '24px' : 0}
									>
										<Stack direction="column" flex="1">

											<PieChartConsommationEuro
												id_title={consoPieEuroChartArticle && consoPieEuroChartArticle.fields.title }
												idChart="sante_consommation_euro"
												dataChart={santeConsoAnneeN.dataFamilleSoin}
											/>

											{santeConsoAnneeN.donneesActualisees !== santeConsoAnneeN.dateFamiliesCares &&
												<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" pt="4px">
													{intl.formatMessage({id: 'global.majData'}) + santeConsoAnneeN.dateFamiliesCares}
												</Typography>
											}
										</Stack>
									</Stack>
									<Stack direction="column" flex="1">

										<PieChartConsommationActe
											id_title={consoPieActeChartArticle && consoPieActeChartArticle.fields.title }
											idChart="sante_consommation_acte"
											dataChart={santeConsoAnneeN.dataFamilleSoin}
										/>

										{santeConsoAnneeN.donneesActualisees !== santeConsoAnneeN.dateFamiliesCares &&
											<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" pt="4px">
												{intl.formatMessage({id: 'global.majData'}) + santeConsoAnneeN.dateFamiliesCares}
											</Typography>
										}
									</Stack>
								</Stack>

								<Divider style={{border: '1px solid #dedede', width: '100%'}}/>

								<Stack direction="column">
									<Stack
										direction={isMobile ? 'column' : 'row'}
										spacing="24px"
										width="100%"
									>
										<Stack flex="1" direction="column" alignItems="center">
											<Stack direction="column" margin="auto">
												<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
													{intl.formatMessage({id: 'sante.consommation.consoNumbers.refund'})}
												</Typography>

												{santeConsoAnneeN.acteRembourse ?
													<>
														<Stack direction="row" alignItems="baseline" spacing="2px">
															<Typography fontSize="2rem" fontWeight="600" color="#004687" variant="body1">
																{intl.formatNumber(santeConsoAnneeN.acteRembourse)}
															</Typography>
															<Typography variant="subtitle1" fontSize="0.813rem">
																en {santeConsoAnneeN.annee}
															</Typography>
														</Stack>
														{(santeConsoAnneeNMoins1 && santeConsoAnneeNMoins1.acteRembourse > 0) &&
															<Stack direction="row" spacing="2px">
																<Typography fontWeight="600" variant="body1" fontSize="1rem">
																	{intl.formatNumber(santeConsoAnneeNMoins1.acteRembourse)}
																</Typography>
																<Typography alignSelf="end" variant="subtitle1" fontSize="0.813rem">
																	en {santeConsoAnneeNMoins1.annee}
																</Typography>
															</Stack>
														}
													</> : <>
														<EmptyResult
															nature="NUMBER"
															message={intl.formatMessage({id: 'global.noKpiNumbers'})}
														/>
													</>
												}
											</Stack>
										</Stack>
										<Stack flex="1" direction="column" alignItems="center">
											<Stack direction="column" margin="auto">
												<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
													{intl.formatMessage({id: 'sante.consommation.consoNumbers.rest'})}
												</Typography>

												{santeConsoAnneeN.acteRembourse
													? <>
														<Stack direction="row" alignItems="baseline" spacing="2px">
															<Typography fontSize="2rem" fontWeight="600" color="#606060" variant="body1">
																{intl.formatNumber(santeConsoAnneeN.acteSansRAS)}
															</Typography>
															<Typography variant="subtitle1" fontSize="0.813rem">
																en {santeConsoAnneeN.annee}
															</Typography>
														</Stack>
														{(santeConsoAnneeNMoins1 && santeConsoAnneeNMoins1.acteSansRAS > 0) &&
															<Stack direction="row" spacing="2px">
																<Typography fontWeight="600" variant="body1" fontSize="1rem">
																	{intl.formatNumber(santeConsoAnneeNMoins1.acteSansRAS)}
																</Typography>
																<Typography variant="subtitle1" fontSize="0.813rem">
																	en {santeConsoAnneeNMoins1.annee}
																</Typography>
															</Stack>
														}
													</> : <>
														<EmptyResult
															nature="NUMBER"
															message={intl.formatMessage({id: 'global.noKpiNumbers'})}
														/>
													</>
												}
											</Stack>
										</Stack>
									</Stack>
									{santeConsoAnneeN.donneesActualisees !== santeConsoAnneeN.dateConsoGraphs &&
										<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" pt="4px" mb="-12px">
											{intl.formatMessage({id: 'global.majData'}) + santeConsoAnneeN.dateConsoGraphs}
										</Typography>
									}
								</Stack>

								<Divider style={{border: '1px solid #dedede', width: '100%'}}/>


								<Stack direction="column">
									<GridChart
										id_title={consoGridChartArticle && consoGridChartArticle.fields.title}
										idChart="sante_cout_moyen"
										dataChart={santeConsoAnneeN.dataCoutMoyen}
									/>
									{santeConsoAnneeN.donneesActualisees !== santeConsoAnneeN.dateCategoriesActes &&
										<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem">
											{intl.formatMessage({id: 'global.majData'}) + santeConsoAnneeN.dateCategoriesActes}
										</Typography>
									}
								</Stack>
							</Stack>
						)
					}
				</Stack>
			</PageLayoutEE>
		)
	}
}

const actions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}
const mapStateToProps = (state: any) => {

	return ({
		loading: isDataKPILoading(state),
		santeConsommation : santeConsommationSelector(state),
		consoFirstRadialChartArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_CONSO_FIRST_RADIAL_CHART],
		consoSecondRadialChartArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_CONSO_SECOND_RADIAL_CHART],
		consoPieEuroChartArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_CONSO_PIE_EURO],
		consoPieActeChartArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_CONSO_PIE_ACTE],
		consoGridChartArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_CONSO_GRID],
	})
}
export default compose(
	connect(mapStateToProps, actions)
)(SanteConsommationPageEE)

