import React, { Dispatch, SetStateAction, useState } from 'react'
import { Alert, Link, Snackbar, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import CookieIcon from '@mui/icons-material/Cookie'
import Box from '@mui/material/Box'
import { useBreakpoints } from '../breakpoints/BreakpointsProvider'
import ButtonCookies from '../ButtonCookies'
import ButtonLink from '../ButtonLink'
import Popup from '../Popup'
import { CNIL_LINK, COOKIES_INFO_LINK } from './cookiesConstants'
import { Form } from 'react-final-form'
import RadioInput from '../form/RadioInput'
import { getAcceptCookie, isAcceptCookiesNotExist, rejectGA, startGA, updateAcceptCookie } from './cookiesUtils'
import appConst from '../../constant/appConstants'
import { styled } from '@mui/material/styles'
import ButtonDefault from '../ButtonDefault'

const ACCEPT_COOKIES_DEFAULT_VALUE = 'true'

const CustomSnackbar = styled(Snackbar)(() => ({
	'&.MuiSnackbar-root': {
		width: '100%',
		left: 0,
		bottom: 0,
		transform: 'none'
	},
	'&.MuiSnackbar-anchorOriginBottomCenter': {
		bottom: '0px',
		left: 0,
		right: 0
	}
}))

const CustomAlert = styled(Alert)(() => ({
	'&.MuiAlert-root': {
		width: '100%',
		paddingLeft: '48px',
		paddingRight: '48px'
	},
	'&.MuiAlert-message': {
		width: '100%'
	}
}))

type CookiesPopupProps = {
	openForm: boolean
	setOpenForm: Dispatch<SetStateAction<boolean>>
}

const CookiesPopup: React.FC<CookiesPopupProps> = ({ openForm, setOpenForm }) => {
	const intl = useIntl()

	const [openPopup, setOpenPopup] = useState<boolean>(isAcceptCookiesNotExist())
	const [formValues, setFormValues] = useState<Record<string, string>>({})
	const { isDesktop, isMobile, isWidescreen } = useBreakpoints()

	const data: SelectOption[] = [
		{ value: 'true', label: intl.formatMessage({ id: 'global.input.yes' }) },
		{ value: 'false', label: intl.formatMessage({ id: 'global.input.no' }) }
	]

	const handleClose = () => setOpenPopup(false)

	const acceptCookies = () => {
		updateAcceptCookie(true)
		startGA()
		handleClose()
	}

	const refuseCookies = () => {
		updateAcceptCookie(false)
		rejectGA()
		handleClose()
	}

	const getInfo = () => {
		handleClose()
		setOpenForm(true)
	}

	return (
		<>
			<CustomSnackbar open={openPopup} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
				<CustomAlert severity="info" sx={{ backgroundColor: '#f6c5a0' }} variant="filled" icon={false}>
					<Stack direction={isDesktop || isWidescreen ? 'row' : 'column'} width="100%" justifyContent="space-between" spacing="8px">
						<Box display="flex" alignItems="center">
							<Typography variant="body1" color="black">
								<FormattedMessage id="cookies.content" />
							</Typography>
						</Box>

						<Stack spacing={2} direction={!isMobile ? 'row' : 'column'} alignItems="center" justifyContent="center">
							<Stack spacing={2} direction="row" justifyContent="space-between" width={isMobile ? '316px' : 'unset'}>
								<Box>
									<ButtonCookies variant="contained" onClick={acceptCookies} sx={{ width: '120px' }}>
										<FormattedMessage id="cookies.buttons.accept" />
									</ButtonCookies>
								</Box>
								<Box>
									<ButtonCookies variant="outlined" onClick={refuseCookies} sx={{ width: '120px' }}>
										<FormattedMessage id="cookies.buttons.decline" />
									</ButtonCookies>
								</Box>
							</Stack>
							<Box>
								<ButtonLink variant="contained" onClick={getInfo} startIcon={<CookieIcon />} sx={{ width: '316px', '&.MuiButton-outlined': { border: 'unset' } }}>
									<FormattedMessage id="cookies.buttons.info" />
								</ButtonLink>
							</Box>
						</Stack>
					</Stack>
				</CustomAlert>
			</CustomSnackbar>
			<Popup
				open={openForm}
				setOpen={setOpenForm}
				title={intl.formatMessage({ id: 'cookies.popup.title' })}
			>
				<Typography sx={{ whiteSpace: 'pre-line' }} variant="body2" color="black">
					<FormattedMessage id="cookies.popup.text" values={{
						link: (
							<Link href={COOKIES_INFO_LINK} target="_blank" rel="noopener noreferrer">
								{COOKIES_INFO_LINK}
							</Link>
						)
					}} />
				</Typography>

				<Stack direction="column" spacing={1}>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="h3" color="black">
						<FormattedMessage id="cookies.popup.whatIsCookie.title" />
					</Typography>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="body2" color="black">
						<FormattedMessage id="cookies.popup.whatIsCookie.text" />
					</Typography>
				</Stack>

				<Stack direction="column" spacing={1}>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="h3" color="black">
						<FormattedMessage id="cookies.popup.cookieUsed.title" />
					</Typography>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="body2" color="black">
						<FormattedMessage id="cookies.popup.cookieUsed.text" />
					</Typography>
				</Stack>

				<Stack direction="column" spacing={1}>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="h3" color="black">
						<FormattedMessage id="cookies.popup.noConsentTrackers.title" />
					</Typography>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="body2" color="black">
						<FormattedMessage id="cookies.popup.noConsentTrackers.text" />
					</Typography>
				</Stack>

				<Stack direction="column" spacing={1}>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="h3" color="black">
						<FormattedMessage id="cookies.popup.consentTrackers.title" />
					</Typography>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="body2" color="black">
						<FormattedMessage id="cookies.popup.consentTrackers.text" />
					</Typography>

					<Typography sx={{ whiteSpace: 'pre-line' }} variant="h4" color="black">
						<FormattedMessage id="cookies.popup.consentTrackers.underControl.title" />
					</Typography>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="body2" color="black">
						<FormattedMessage id="cookies.popup.consentTrackers.underControl.text" />
					</Typography>

					<Typography sx={{ whiteSpace: 'pre-line' }} variant="h4" color="black">
						<FormattedMessage id="cookies.popup.consentTrackers.survey.title" />
					</Typography>
					<Form
						onSubmit={() => {
						}}
						initialValues={{ [appConst.acceptCookie.name]: isAcceptCookiesNotExist() ? ACCEPT_COOKIES_DEFAULT_VALUE : getAcceptCookie() }}
						render={({ handleSubmit, values }) => {
							setFormValues(values)

							return (
								<form onSubmit={handleSubmit}>
									<RadioInput name={appConst.acceptCookie.name} data={data} />
								</form>
							)
						}}
					/>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="body2" color="black">
						<FormattedMessage id="cookies.popup.consentTrackers.survey.text" />
					</Typography>

					<Typography sx={{ whiteSpace: 'pre-line' }} variant="h4" color="black">
						<FormattedMessage id="cookies.popup.consentTrackers.thirdParty.title" />
					</Typography>
					<Typography sx={{ whiteSpace: 'pre-line' }} variant="body2" color="black">
						<FormattedMessage id="cookies.popup.consentTrackers.thirdParty.text" values={{
							link: (
								<Link href={CNIL_LINK} target="_blank" rel="noopener noreferrer">
									{CNIL_LINK}
								</Link>
							)
						}} />
					</Typography>
				</Stack>

				<Box display="flex" alignItems="center" justifyContent="center" flex={1}>
					<Box>
						<ButtonDefault
							variant="contained"
							type="submit"
							onClick={() => {
								const acceptCookies = formValues[appConst.acceptCookie.name]
								updateAcceptCookie(acceptCookies)
								acceptCookies === 'true' ? startGA() : rejectGA()
								setOpenForm(false)
							}}
						>
							{intl.formatMessage({ id: 'global.button.ok' })}
						</ButtonDefault>
					</Box>
				</Box>
			</Popup>
		</>
	)
}

export default CookiesPopup
