import { getSouscriptionGarantiesExpirationDate } from './souscriptionSelector'
import ContratsService from '../../../api/gerep/ContratsService';
import LocalStorage from '../../../business/storage/LocalStorage';

const ACTION_NAMESPACE = '/SOUSCRIPTION'

export const types = {
	GET_GARANTIES: `${ACTION_NAMESPACE}/GET_GARANTIES`,
	GET_GARANTIES_LOADING: `${ACTION_NAMESPACE}/GET_GARANTIES_LOADING`
}

export const loadSouscriptionGaranties = () => (dispatch, getState) => {
	if (getSouscriptionGarantiesExpirationDate(getState()) < (+new Date())) {
		dispatch({ type: types.GET_GARANTIES_LOADING, payload: true })
		return ContratsService.getSouscriptionGaranties(LocalStorage.getToken())
			.then((garanties) => {
				dispatch({ type: types.GET_GARANTIES_LOADING, payload: false })
				return dispatch({
					type: types.GET_GARANTIES,
					payload: garanties
				})
			}).catch((e) => {
				dispatch({ type: types.GET_GARANTIES_LOADING, payload: false })
				throw e
			})
	}
}
