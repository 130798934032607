import React, { useEffect, useMemo, useState } from 'react'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import PageLayout from '../../../../components/PageLayout'
import { Box, Link, Stack, Typography, useTheme } from '@mui/material'
import { replaceEntityLinks } from '../../../../utils/FormatUtils'
import appConst from '../../../../constant/appConstants'
import LocalStorage from '../../../../business/storage/LocalStorage'
import { getMatrix } from '../../../../api/gerep/Simulateur'
import SimulationForm from '../components/SimulationForm'
import { simulatorGroupBy } from '../services/simulatorUtils'
import GuideGestionErrorPopin from '../components/GuideGestionErrorPopin'
import { getContratBaseSante } from '../../../webservices/contrats/contratsSelector'
import * as contratsActions from '../../../webservices/contrats/contratsActions'

const ARTICLE_INTRO_SHORTCUT = 'simulateur_sante_introduction'

const SimulationPage: React.FC = (
	{
		// @ts-ignore
		articleIntro,
		// @ts-ignore
		getArticle,
		// @ts-ignore
		company,
		// @ts-ignore
		loadContrats,
		// @ts-ignore
		download
	}) => {
	const intl = useIntl()
	const theme = useTheme()
	const [matrix, setMatrix] = useState()
	const [openGestionError, setOpenGestionError] = useState<boolean>(false)

	useEffect(() => {
		getArticle(appConst.cardiboxApi, ARTICLE_INTRO_SHORTCUT)
		loadContrats()
		getMatrix(LocalStorage.getToken())
			.then(matrix => setMatrix(matrix))
	}, [getArticle, loadContrats])

	const groupedMatrix = useMemo(() => matrix && simulatorGroupBy(matrix), [matrix])

	const downloadContrat = (numgar: any, siret: any) => {
		download(numgar, siret)
			.catch(() => {
				setOpenGestionError(true)
			})
	}

	return (
		<PageLayout title={intl.formatMessage({ id: 'simulation.title' })}>
			<Stack direction="column" spacing="12px">
				<Typography variant="body2">
					{
						articleIntro && (
							<Box
								dangerouslySetInnerHTML={{ __html: (articleIntro.fields && replaceEntityLinks(articleIntro.fields.body)) }}
								sx={{ 'a': { color: '#e96e11' } }}
							/>
						)
					}
				</Typography>
				{company &&
					<Typography variant="body2">
						<FormattedMessage
							id="simulation.guide"
							values={{
								guide: (
									<Link onClick={() => downloadContrat(company.numgar, company.siret)} href="#" color={theme.palette.secondary.main}>
										guide de gestion
									</Link>
								)
							}}
						/>
					</Typography>
				}

				<SimulationForm matrix={groupedMatrix} />
				<GuideGestionErrorPopin open={openGestionError} setOpen={setOpenGestionError} />
			</Stack>
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	articleIntro: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_INTRO_SHORTCUT],
	company: getContratBaseSante(state)
})

const mappedActions = {
	getArticle: cardiboxActions.articles.getByShortcut,
	loadContrats: contratsActions.loadContrats,
	download: contratsActions.download
}

export default compose(
	connect(mapStateToProps, mappedActions)
)(SimulationPage)