import React, { useEffect, useState } from 'react'
import { NotationContext } from './NotationContext'
import { cardiboxActions, cardiboxSelectors } from 'redux-cardibox'
import { connect } from 'react-redux'
import NotationPopup from './NotationPopup'
import appConst from '../../../constant/appConstants'
import { addAffichagePopinNotation } from './popinNotationActions'

const ARTICLE_SHORTCUT = 'contenu_popin_notation'

type NotationProviderProps = {
	children: React.ReactNode
}

const NotationProvider: React.FC<NotationProviderProps> = (
	{
		children,
		// @ts-ignore
		contenu,
		// @ts-ignore
		getArticleByShortcut,
		// @ts-ignore
		addAffichagePopinNotation,
		// @ts-ignore
		openInitalState
	}) => {
	const [openPopin, setOpenPopin] = useState<boolean>(openInitalState)

	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_SHORTCUT)
		setOpenPopin(openInitalState)
	}, [openInitalState])

	const contextValue = {
		setOpen: (open: boolean) => setOpenPopin(open)
	}

	return (
		<NotationContext.Provider value={contextValue}>
			{children}
			{contenu && <NotationPopup open={openPopin} setOpen={setOpenPopin} contenu={contenu} addAffichagePopinNotation={addAffichagePopinNotation} />}
		</NotationContext.Provider>
	)
}

const mapStateToProps = (state: any) => ({
	contenu: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_SHORTCUT],
	openInitalState: state.popinNotation.open
})

const mappedActions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut,
	addAffichagePopinNotation
}

export default connect(mapStateToProps, mappedActions)(NotationProvider)
