import React, {Dispatch, SetStateAction, useState} from 'react'
import {Divider, Link, Stack, Typography} from '@mui/material'
import TextInput from '../../../../../components/form/TextInput'
import {DISPENSE_PREAFF_FIELDS} from '../../services/preaffiliationConstants'
import {FormattedMessage, useIntl} from 'react-intl'
import DatePickerInput from '../../../../../components/form/DatePickerInput'
import RadioInput from '../../../../../components/form/RadioInput'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {toUpperCase} from '../../../../../utils/stringUtils'
import {useBreakpoints} from '../../../../../components/breakpoints/BreakpointsProvider'
import {cardiboxSelectors} from 'redux-cardibox'
import {compose} from 'redux'
import {connect} from 'react-redux'
import ButtonDefault from '../../../../../components/ButtonDefault'
import {motifDispenseChosen} from '../../preaffiliationUtils'
import {addDays, addMonths} from 'date-fns'


const ARTICLE_DISPENSE_CHOIX = 'intro_dispense_etape_3'


type ChoixDispenseAffiliationFormProps = {
    values: any
    contract: any,
    dateDebutMin: Function
    step: number
    setStep: Dispatch<SetStateAction<number>>
}

const ChoixDispenseAffiliationForm: React.FC<ChoixDispenseAffiliationFormProps> = (
    {
        values,
        contract,
        dateDebutMin,
        step,
        setStep,
        //@ts-ignore
        dispensePreaffChoixDispenseArticle
    }
) => {

    const intl = useIntl()
    const [moreOptions, setMoreOptions] = useState<boolean>(false)

    const { isMobile, isTabletPortrait } = useBreakpoints()
    const isMobOrTabVert = isMobile || isTabletPortrait

    const motifsDispenseData = (listJustifsDispense: any) => {
        const listMotifs = []
        const listJustifsLength = listJustifsDispense.length
        if (listJustifsLength !== 0) {
            for (
                let i = 0;
                i < (listJustifsLength <= 3 ? listJustifsLength : moreOptions ? listJustifsLength : 3);
                i++
            ) {
                listMotifs.push(listJustifsDispense[i])
            }
            return listMotifs
        }
    }

    const dateDebutMinDispense = dateDebutMin(values)

    return (
        <>
            <Stack direction="column" spacing="24px">

                <Typography
                    variant="body2"
                    style={{fontWeight: '500', fontSize: '1rem'}}
                    dangerouslySetInnerHTML={{ __html: dispensePreaffChoixDispenseArticle.fields.body }}
                />

                <Divider />

                <Typography variant="body2" style={{fontWeight: '600', fontSize: '1.125rem'}}>
                    {intl.formatMessage({id: 'preaffiliation.dispense.dispenseChoiceStep.form.title'})}
                </Typography>

                <Stack direction="column" spacing="12px">
                    <RadioInput
                        name={DISPENSE_PREAFF_FIELDS.MOTIF}
                        data={
                            //@ts-ignore
                            motifsDispenseData(contract.justifsDispense)
                                .map((motif: any) => ({ value: motif.codedispense, label: motif.libdispense }))
                        }
                        direction="column"
                        radioSpacing="4px"
                    />

                    {(contract.justifsDispense.length <= 3 || moreOptions) &&
                        <Stack direction="row" spacing="8px">
                            <InfoOutlinedIcon width="400px"/>
                            <Typography variant="body2" fontSize="0.875rem">
                                <FormattedMessage id="preaffiliation.dispense.dispenseChoiceStep.form.noOptions"/>
                                <Link
                                    onClick={() => {
                                        setStep(step - 1)
                                        // TODO : Faire de plus changer la value du radio button pour la dispense à "NON"
                                    }}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    sx={(theme) => ({color: theme.palette.secondary.main})}
                                >
                                    {intl.formatMessage({id: 'preaffiliation.dispense.dispenseChoiceStep.form.clickHere'})}
                                </Link>
                            </Typography>
                        </Stack>
                    }
                    {contract.justifsDispense.length > 3 &&
                        <Stack direction="row" justifyContent="center">
                            <ButtonDefault
                                variant="outlined"
                                onClick={() => setMoreOptions(!moreOptions)}
                            >
                                {moreOptions
                                    ? intl.formatMessage({id: 'preaffiliation.dispense.dispenseChoiceStep.form.lessOptions'})
                                    : intl.formatMessage({id: 'preaffiliation.dispense.dispenseChoiceStep.form.moreOptions'})
                                }
                            </ButtonDefault>
                        </Stack>
                    }
                </Stack>


                <Stack direction="column" spacing="24px">

                    {values[DISPENSE_PREAFF_FIELDS.MOTIF] &&
                        <>
                            <DatePickerInput
                                name={DISPENSE_PREAFF_FIELDS.START_DATE}
                                label={intl.formatMessage({id: 'preaffiliation.dispense.dispenseChoiceStep.form.startDate.label'})}
                                helperText={intl.formatMessage({id: 'preaffiliation.dispense.dispenseChoiceStep.form.startDate.helper'})}
                                minDate={dateDebutMinDispense}
                                maxDate={addDays(new Date(), 60)}
                                disabled={!!contract.debutAffiliation}
                                maxWidth="unset"
                                mandatory
                            />
                            {motifDispenseChosen(contract, values[DISPENSE_PREAFF_FIELDS.MOTIF]).organisme === "O" &&
                                <TextInput
                                    name={DISPENSE_PREAFF_FIELDS.ORGANISM}
                                    label={intl.formatMessage({id: 'preaffiliation.dispense.dispenseChoiceStep.form.organisme.label'})}
                                    helperText={intl.formatMessage({id: 'preaffiliation.dispense.dispenseChoiceStep.form.organisme.helper'})}
                                    maxWidth="unset"
                                    mandatory
                                    format={toUpperCase}
                                />
                            }
                            {motifDispenseChosen(contract, values[DISPENSE_PREAFF_FIELDS.MOTIF]).ctrl === "O" &&
                                <DatePickerInput
                                    name={DISPENSE_PREAFF_FIELDS.END_DATE}
                                    label={intl.formatMessage({id: 'preaffiliation.dispense.dispenseChoiceStep.form.endDate.label'})}
                                    minDate={addDays(values[DISPENSE_PREAFF_FIELDS.START_DATE], 1)}
                                    maxDate={addMonths(values[DISPENSE_PREAFF_FIELDS.START_DATE], 12)}
                                    maxWidth="unset"
                                    mandatory
                                />
                            }
                        </>
                    }
                </Stack>
            </Stack>
        </>
    )
}

const actions = {
}
const mapStateToProps = (state: any) => ({
    dispensePreaffChoixDispenseArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_DISPENSE_CHOIX],
    isDispensePreaffChoixDispenseArticleLoading: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_DISPENSE_CHOIX]
})
export default compose(
    connect(mapStateToProps, actions)
)(ChoixDispenseAffiliationForm)