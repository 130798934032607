import React from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import appStoreImg from '../../../../resources/img/app-store.png'
import playStoreImg from '../../../../resources/img/google-play.png'
import smartphoneImg from '../../../../resources/img/smartphone.png'
import Box from '@mui/material/Box'

const DownloadApp = () => {
	return (
		<DashboardCard height="fit-content" cardContentPadding="24px">
			<Stack
				spacing="25px"
				width="100%"
				alignItems="flex-start"
				direction="column"
				justifyContent="center"
			>
				<Stack direction="row" width="100%" spacing={2}>
					<Typography flex={3} variant="body1" sx={{ color: '#7A7A7A' }}>
						<FormattedMessage id="dashboard.downloadApp.content" />
					</Typography>
					<Box flex={1}>
						<img src={smartphoneImg} alt="smartphone" style={{ width: '108px', position: 'absolute', marginTop: '-50px', marginLeft: '-24px' }} />
					</Box>
				</Stack>
				<Stack direction="row" width="100%" spacing={2} alignItems="center">
					<a href="https://apps.apple.com/fr/app/Gerep/482050275" target="_blank" rel="noreferrer">
						<img src={appStoreImg} alt="App Store" style={{ width: '115px' }} />
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.gerepmobile.prod&hl=fr&gl=US" target="_blank" rel="noreferrer">
						<img src={playStoreImg} alt="Google Play" style={{ width: '115px' }} />
					</a>
				</Stack>
			</Stack>
		</DashboardCard>
	)
}

export default DownloadApp
