import * as React from 'react'
import { useState } from 'react'
import { AppBar as MuiAppBar } from '@mui/material'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Drawer, IconButton, Stack } from '@mui/material'
import {NavMenuEE, NavMenuEA} from '../NavMenu'
import { useBreakpoints } from '../breakpoints/BreakpointsProvider'
import { userSelector } from '../../ea/ui/user/userSelectors'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

type AppBarProps = {
	displayInfo?: boolean
	isEntreprise: boolean
}

const AppBar: React.FC<AppBarProps> = (
	{
		displayInfo = true,
		// @ts-ignore
		user,
		// @ts-ignore
		isEntreprise
	}) => {
	const [openDrawer, setOpenDrawer] = useState<boolean>(false)
	const { isMobile, isTabletPortrait, isTablet } = useBreakpoints()

	const isMobileOrTablet = isMobile || isTablet || isTabletPortrait

	return (
		<Box bgcolor="#f7f5f4" height="72px" ml={!isMobileOrTablet ? '260px' : 0} display="flex" alignItems="center">
			<MuiAppBar position="static" sx={{ bgcolor: '#f7f5f4', boxShadow: 0 }}>
				<Toolbar sx={{ justifyContent: !isMobileOrTablet ? 'flex-end' : 'space-between', alignItems: 'center' }}>
					{isMobileOrTablet && (
						<Stack direction="row" alignItems="center" spacing={5}>
							<IconButton
								onClick={() => setOpenDrawer(true)}
								size="small"
								edge="start"
								color="secondary"
								aria-label="menu"
								sx={{
									height: '40px',
									width: '40px',
									bgcolor: '#fff',
									borderRadius: '5px',
									border: '1px solid #e96e11',
									alignItems: 'center'
								}}
							>
								<MenuIcon />
							</IconButton>
							<img src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`} style={{ width: '100px', height: '56px' }} alt="logo" />
						</Stack>
					)}
					{(!isMobileOrTablet && displayInfo) && (
						<Stack>
							<Typography textAlign="right" variant="h3" component="div" sx={{ mr: 2 }}>
								{`${user?.prenom} ${user?.nom}`}
							</Typography>
							<Typography textAlign="right" variant="subtitle1" component="div" sx={{ mr: 2 }}>
								<FormattedMessage id="appbar.lastConnexion" values={{ date: user?.dateConnexion || new Date().toLocaleDateString('fr-FR') }} />
							</Typography>
						</Stack>
					)}
				</Toolbar>
			</MuiAppBar>
			<Drawer PaperProps={{ style: { width: '268px', overflow: 'hidden' } }} anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
				{isEntreprise?
					<NavMenuEE closeDrawer={() => setOpenDrawer(false)} />
					:
					<NavMenuEA closeDrawer={() => setOpenDrawer(false)} />
				}
			</Drawer>
		</Box>
	)
}

const makeMapStateToProps = (state: any) => ({
	user: userSelector(state)
})

export default compose(
	connect(makeMapStateToProps, {})
)(AppBar)
