import GerepAPI from './GerepAPI'

export default class AffiliationService {

	static getAffiliation(token) {
		return GerepAPI.get('/monAffiliation', null, token)
	}

	static editBeneficiaire(values, token) {
		return GerepAPI.post('/monAffiliation/editBeneficiaire', values, token)
	}

	static downloadAttestationAffiliation(token) {
		return GerepAPI.download('/monAffiliation/downloadAffiliation', null, token)
	}

	static downloadAttestationRadiation(token) {
		return GerepAPI.download('/monAffiliation/downloadRadiation', null, token)
	}
}
