import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import FormatUtils from '../../../../utils/FormatUtils'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

type TableauEcheancierProps = {
	echeancier: any
}

const TableauEcheancier: FC<TableauEcheancierProps> = (
	{
		echeancier
	}
) => {

	const { isMobile, isTabletPortrait } = useBreakpoints()

	return (
		(!echeancier || echeancier.length === 0) ? (
			<Stack direction="column" spacing="24px">
				<></>
			</Stack>
		) : (
			(isMobile || isTabletPortrait) ? (
				<TableContainer>
					<Table aria-label="custom table">
						<TableBody>
							{echeancier.map((c: any, index: any) => (
								<>
									<TableRow key={index + '_contrat'}>
										<TableCell sx={{ border: 'none', borderTop: '2px solid #dedede' }} align="left">
											<Typography flex={1} variant="body2">
												<FormattedMessage id="echeancier.tableau.header.nomContrat" />
											</Typography>
										</TableCell>
										<TableCell sx={{ border: 'none', borderTop: '2px solid #dedede' }} align="left">
											<Typography flex={1} variant="body2" fontWeight={600}>
												{c.refContrat}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow key={index + '_periode'}>
										<TableCell sx={{ border: 'none', paddingBottom: '16px', paddingTop: '0px' }} align="left">
											<Typography flex={3} variant="body2">
												<FormattedMessage id="echeancier.tableau.header.periode" />
											</Typography>
										</TableCell>
										<TableCell sx={{ border: 'none', paddingBottom: '16px', paddingTop: '0px' }} align="left">
											<Typography flex={3} variant="body2" fontWeight={600}>
												<FormattedMessage id="echeancier.tableau.contenu.periode" values={{ from: FormatUtils.formatDate(c.dateDebut), to: FormatUtils.formatDate(c.dateFin) }} />
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow key={index + '_date'}>
										<TableCell sx={{ border: 'none', paddingBottom: '16px', paddingTop: '0px' }} align="left">
											<Typography flex={1} variant="body2">
												<FormattedMessage id="echeancier.tableau.header.date" />
											</Typography>
										</TableCell>
										<TableCell sx={{ border: 'none', paddingBottom: '16px', paddingTop: '0px' }} align="left">
											<Typography flex={1} variant="body2" fontWeight={600}>
												<FormattedMessage id="echeancier.tableau.contenu.date" values={{ date: FormatUtils.formatDate(c.dateEcheance) }} />
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow key={index + '_date'}>
										<TableCell sx={{ border: 'none', borderBottom: '2px solid #dedede', paddingTop: '0px' }} align="left">
											<Typography flex={1} variant="body2">
												<FormattedMessage id="echeancier.tableau.header.cotisation" />
											</Typography>
										</TableCell>
										<TableCell sx={{ border: 'none', borderBottom: '2px solid #dedede', paddingTop: '0px' }} align="left">
											<Typography flex={1} variant="body2" fontWeight={600}>
												{c.devise === 'EUR' ? (
													<FormattedMessage id="echeancier.tableau.contenu.cotisationFr" values={{ montant: FormatUtils.formatMontant(c.montant) }} />
												) : (
													<FormattedMessage id="echeancier.tableau.contenu.cotisation" values={{ montant: c.montant, devise: c.devise }} />
												)
												}
											</Typography>
										</TableCell>
									</TableRow>
								</>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<TableContainer>
					<Table sx={{ minWidth: 500 }} aria-label="custom table">
						<TableHead>
							<TableRow>
								<TableCell align="center">
									<Typography flex={1} variant="body2">
										<FormattedMessage id="echeancier.tableau.header.nomContrat" />
									</Typography>
								</TableCell>
								<TableCell align="center">
									<Typography flex={3} variant="body2">
										<FormattedMessage id="echeancier.tableau.header.periode" />
									</Typography>
								</TableCell>
								<TableCell align="center">
									<Typography flex={1} variant="body2">
										<FormattedMessage id="echeancier.tableau.header.date" />
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography flex={1} variant="body2">
										<FormattedMessage id="echeancier.tableau.header.cotisation" />
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{echeancier.map((c: any, index: any) => (
								<TableRow key={index}>
									<TableCell component="th" scope="row" align="center">
										<Typography flex={1} variant="body2" fontWeight={600}>
											{c.refContrat}
										</Typography>
									</TableCell>
									<TableCell component="th" scope="row" align="center">
										<Typography flex={3} variant="body2" fontWeight={600}>
											<FormattedMessage id="echeancier.tableau.contenu.periode" values={{ from: FormatUtils.formatDate(c.dateDebut), to: FormatUtils.formatDate(c.dateFin) }} />
										</Typography>
									</TableCell>
									<TableCell component="th" scope="row" align="center">
										<Typography flex={1} variant="body2" fontWeight={600}>
											<FormattedMessage id="echeancier.tableau.contenu.date" values={{ date: FormatUtils.formatDate(c.dateEcheance) }} />
										</Typography>
									</TableCell>
									<TableCell component="th" scope="row" align="right">
										<Typography flex={1} variant="body2" fontWeight={600}>
											{c.devise === 'EUR' ? (
												<FormattedMessage id="echeancier.tableau.contenu.cotisationFr" values={{ montant: FormatUtils.formatMontant(c.montant) }} />
											) : (
												<FormattedMessage id="echeancier.tableau.contenu.cotisation" values={{ montant: c.montant, devise: c.devise }} />
											)
											}
										</Typography>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)
		)
	)
}

export default TableauEcheancier
