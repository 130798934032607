import React from 'react'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const UnauthorizedErrorPage = () => {
	return (
		<Stack direction="column" spacing="48px" alignItems="center">
			<Typography fontSize="5.653rem" fontWeight={600} color="secondary">
				<FormattedMessage id="codeError.unauthorized.title" />
			</Typography>
			<Typography variant="body1">
				<FormattedMessage id="codeError.unauthorized.text" />
			</Typography>
		</Stack>
	)
}

export default UnauthorizedErrorPage