import { isRibInCache, isRibLoading } from './ribSelector'
import LocalStorage from '../../../business/storage/LocalStorage';
import RibService from '../../../api/gerep/RibService';

const ACTION_NAMESPACE = '/RIB'

export const types = {
	GET_RIB: `${ACTION_NAMESPACE}/GET_RIB`,
	GET_RIB_LOADING: `${ACTION_NAMESPACE}/GET_RIB_LOADING`
}

export const getRibPrestation = idIndividu => (dispatch, getState) => {
	// si on est pas déjà en train de loader cet individu
	if (!isRibLoading(idIndividu)(getState())) {
		// Si le state n'est pas à jour
		if (!isRibInCache(idIndividu)(getState())) {
			dispatch({
				type: types.GET_RIB_LOADING,
				payload: {
					loading: true,
					idIndividu
				}
			})
			return RibService.getRibPrestationIndividu(LocalStorage.getToken(), idIndividu)
				.then((rib) => {
					dispatch({
						type: types.GET_RIB_LOADING,
						payload: {
							loading: false,
							idIndividu
						}
					})
					dispatch({
						type: types.GET_RIB,
						payload: {
							rib,
							idIndividu
						}
					})
				}).catch((e) => {
					dispatch({
						type: types.GET_RIB_LOADING,
						payload: {
							loading: false,
							idIndividu
						}
					})
					throw e
				})
		}
	}
}
