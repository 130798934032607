import React, { useMemo, useState } from 'react'
import { Stack } from '@mui/material'
import ButtonLink from '../../../../components/ButtonLink'
import { FormattedMessage } from 'react-intl'
import Icon from '@mdi/react'
import { mdiAccountEdit, mdiPlaylistEdit, mdiAccountPlus, mdiAccountOff } from '@mdi/js'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import { SECONDARY_PATH } from '../../../../constant/path'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import ModifyBeneficiaryPopup from './ModifyBeneficiaryPopup'
import { TypeAdherent } from '../../../../constant/TypeAdherent'

type BeneficiaryActionsProps = {
	individuList: any[]
}

const BeneficiaryActions: React.FC<BeneficiaryActionsProps> = (
	{
		individuList
	}
) => {
	const history = useHistory()
	const { isMobile, isTabletPortrait } = useBreakpoints()

	const [openModifyBeneficiary, setOpenModifyBeneficiary] = useState<boolean>(false)

	const beneficiaireList = useMemo(() => individuList.filter(ind => ind[1].typAdr !== TypeAdherent.ASSURE_PRINCIPAL), [individuList])

	return (
		<Stack direction="column" spacing={isMobile || isTabletPortrait ? '18px' : '14px'}>
			<Stack direction={isMobile || isTabletPortrait ? 'column' : 'row'} spacing={isMobile || isTabletPortrait ? '18px' : '14px'}>
				{beneficiaireList && beneficiaireList.length > 0 ?
					<Box flex={1}>
						<ButtonLink variant="contained" startIcon={<Icon size="20px" path={mdiAccountEdit} />} onClick={() => setOpenModifyBeneficiary(true)}
						            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
						>
							<FormattedMessage id="beneficiary.buttons.modify" />
						</ButtonLink>
					</Box>
					:
					<Box flex={1}>
						<ButtonLink variant="contained" startIcon={<Icon size="20px" path={mdiAccountPlus} />} onClick={() => history.push(SECONDARY_PATH.CONTACT_ADD_BENEFICIARY)}
						            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
						>
							<FormattedMessage id="beneficiary.buttons.add" />
						</ButtonLink>
					</Box>
				}
				<Box flex={1}>
					<ButtonLink variant="contained" startIcon={<Icon size="20px" path={mdiPlaylistEdit} />} onClick={() => history.push(SECONDARY_PATH.CONTACT_MODIFY_SOCIAL_SECURITY)}
					            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
					>
						<FormattedMessage id="beneficiary.buttons.modifySS" />
					</ButtonLink>
				</Box>
			</Stack>
			{beneficiaireList && beneficiaireList.length > 0 &&
				<Stack direction={isMobile || isTabletPortrait ? 'column' : 'row'} spacing={isMobile || isTabletPortrait ? '18px' : '14px'}>
					<Box flex={1}>
						<ButtonLink variant="contained" startIcon={<Icon size="20px" path={mdiAccountPlus} />} onClick={() => history.push(SECONDARY_PATH.CONTACT_ADD_BENEFICIARY)}
						            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
						>
							<FormattedMessage id="beneficiary.buttons.add" />
						</ButtonLink>
					</Box>
					<Box flex={1}>
						<ButtonLink variant="contained" startIcon={<Icon size="20px" path={mdiAccountOff} />} onClick={() => history.push(SECONDARY_PATH.CONTACT_DELETE_BENEFICIARY)}
						            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
						>
							<FormattedMessage id="beneficiary.buttons.delete" />
						</ButtonLink>
					</Box>
				</Stack>
			}

			<ModifyBeneficiaryPopup open={openModifyBeneficiary} setOpen={setOpenModifyBeneficiary} beneficiaireList={beneficiaireList} />
		</Stack>
	)
}

export default BeneficiaryActions
