import React, { useState } from 'react'
import { IconButton, Stack, Typography, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { FieldArray } from 'react-final-form-arrays'
import { DEMAND_REFUND_FIELDS } from '../services/demandRefundConstants'
import DatePickerInput from '../../../../components/form/DatePickerInput'
import SelectInput from '../../../../components/form/SelectInput'
import TextInput from '../../../../components/form/TextInput'
import Icon from '@mdi/react'
import { mdiMinusBox, mdiPlusBox } from '@mdi/js'
import RemboursementsService from '../../../../api/gerep/RemboursementsService'
import LocalStorage from '../../../../business/storage/LocalStorage'
import { isBefore, isValid } from 'date-fns'
import { newDatePlusYears } from '../../../../utils/dateUtils'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'

type DetailsSoinArrayFieldsProps = {
	addField: () => void
	selectedBenef?: any
}

const DetailsSoinArrayFields: React.FC<DetailsSoinArrayFieldsProps> = (
	{
		addField,
		selectedBenef
	}
) => {
	const intl = useIntl()
	const theme = useTheme()
	const { isMobile, isTabletPortrait } = useBreakpoints()

	const [updateFieldArray, setUpdateFieldArray] = useState('any')
	const [error, setError] = useState<string[]>([])
	const [typeDesoins, settypeDesoins] = useState<any[]>([])
	const [isRembAutreMutuelleDisabled, setIsRembAutreMutuelleDisabled] = useState<boolean[]>([])

	const loadActesBienEtre = (dateSoin: Date, index?: number) => {
		const dateBackTwoYears = newDatePlusYears(-2)
		if (isValid(dateSoin) && !isBefore(dateSoin, dateBackTwoYears) && index !== undefined) {
			RemboursementsService.getActesBienEtre(
				LocalStorage.getToken(),
				selectedBenef,
				dateSoin.toISOString().slice(0, 10)
			).then((res) => {
				setUpdateFieldArray(`${new Date()}`)
				if (index <= typeDesoins.length) {
					settypeDesoins(type => {
						type[index] = res
						return type
					})
				} else {
					settypeDesoins(type => {
						type.splice(index, 0, res)
						return type
					})
				}
				if (!res || res.length === 0) {
					const errorMessage = 'A cette date, le bénéficiaire concerné n\'est pas couvert en Médecine douce et bien-être.'
					if (index <= error.length) {
						setError(error => {
							error[index] = errorMessage
							return error
						})
					} else {
						setError(error => {
							error.splice(index, 0, errorMessage)
							return error
						})
					}
				} else {
					setError(error => {
						error[index] = ''
						return error
					})
				}
			})
		}
	}

	const rembAutreMutuelleDisabled = (typeDeSoin: string, index?: number) => {
		if (index !== undefined) {
			setUpdateFieldArray(`${new Date()}`)
			const rang: string = typeDesoins[index].find((value: any) => `${value.codeActe}-${value.rang}` === typeDeSoin).rang
			setIsRembAutreMutuelleDisabled(isRembAutreMutuelleDisabled => {
				isRembAutreMutuelleDisabled.splice(index, 0, rang === '1')
				return isRembAutreMutuelleDisabled
			})
		}
	}

	return (
		<Stack direction="column" spacing="12px">
			<Typography fontSize="0.875rem" fontWeight={500}>
				<FormattedMessage id="demandRefund.input.healingDetails.label" />
			</Typography>
			<FieldArray name={DEMAND_REFUND_FIELDS.HEALING_DETAILS_ARRAY} key={updateFieldArray} initialValue={[{}] as any[]}>
				{({ fields }) => {
					return (
						fields.map((name, index) => {
							const isTypeDisabled = (typeDesoins[index] && typeDesoins[index].length === 0) || !(fields.value && fields.value[index] && fields.value[index]?.date && isValid(fields.value[index]?.date))

							return (
								<Stack key={`${name}-${index}`} direction={isMobile || isTabletPortrait ? 'column' : 'row'} spacing="12px" alignItems="flex-start">
									<Stack direction={isMobile ? 'column' : 'row'} spacing="12px" alignItems="flex-start" sx={{ width: '100%' }}>
										<DatePickerInput
											name={`${name}.date`}
											label={intl.formatMessage({ id: 'demandRefund.input.healingDetails.array.date.label' })}
											onChange={loadActesBienEtre}
											index={index}
											disableFuture
										/>
										<SelectInput
											name={`${name}.type`}
											data={typeDesoins[index] ? typeDesoins[index].map((type: any) => ({ value: `${type.codeActe}-${type.rang}`, label: type.libelleActe })) : []}
											label={intl.formatMessage({ id: 'demandRefund.input.healingDetails.array.type.label' })}
											disabled={isTypeDisabled}
											onChange={rembAutreMutuelleDisabled}
											index={index}
											error={error[index]}
											mandatory
										/>
									</Stack>
									<Stack direction={isMobile ? 'column' : 'row'} spacing="12px" alignItems="flex-start" sx={{ width: '100%' }}>
										<TextInput
											name={`${name}.montantPraticien`}
											label={intl.formatMessage({ id: 'demandRefund.input.healingDetails.array.amount.label' })}
											helperText={intl.formatMessage({ id: 'demandRefund.input.healingDetails.array.amount.helper' })}
											disabled={isTypeDisabled}
											placeholder="0,00 €"
											type="number"
										/>
										<Stack direction="row" spacing="12px" alignItems="flex-start" sx={{ width: '100%' }}>
											<TextInput
												name={`${name}.rmbAutreMutuelle`}
												label={intl.formatMessage({ id: 'demandRefund.input.healingDetails.array.other.label' })}
												helperText={intl.formatMessage({ id: 'demandRefund.input.healingDetails.array.other.helper' })}
												placeholder="0,00 €"
												disabled={isRembAutreMutuelleDisabled[index] || isTypeDisabled}
												type="number"
											/>
											<Stack direction="column">
												{fields.value.length > 1 &&
													<IconButton onClick={() => {
														fields.remove(index)
														settypeDesoins(type => {
															type.splice(index, 1)
															return type
														})
														setIsRembAutreMutuelleDisabled(isRembAutreMutuelleDisabled => {
															isRembAutreMutuelleDisabled.splice(index, 1)
															return isRembAutreMutuelleDisabled
														})
														setError(error => {
															error.splice(index, 1)
															return error
														})
													}}>
														<Icon path={mdiMinusBox} size="24px" color={theme.palette.primary.main} />
													</IconButton>
												}
												{index === fields.value.length - 1 &&
													<IconButton onClick={() => {
														addField()
														setError(error => {
															error.splice(index + 1, 0, '')
															return error
														})
													}}>
														<Icon path={mdiPlusBox} size="24px" color={theme.palette.primary.main} />
													</IconButton>
												}
											</Stack>
										</Stack>
									</Stack>
								</Stack>
							)
						})
					)
				}}
			</FieldArray>
		</Stack>
	)
}

export default DetailsSoinArrayFields
