import * as React from 'react'
import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const AppBarButton = styled(ButtonMUI)<ButtonProps>(() => ({
	'&.MuiButton-root': {
		borderRadius: 8,
		width: '100%',
		minHeight: 48,
		justifyContent: 'start',
		textAlign: 'left'
	}
}))

export default AppBarButton