import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import SelectInput from '../../../../components/form/SelectInput'
import { SEND_CONTRACT_FIELDS } from '../services/sendContractConstants'
import RadioInput from '../../../../components/form/RadioInput'
import ButtonDefault from '../../../../components/ButtonDefault'
import FileInput from '../../../../components/fileInput/FileInput'
import { ToastType } from '../../../../components/toast/toastConstants'
import { ToastContext } from '../../../../components/toast/ToastContext'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import LocalStorage from '../../../../business/storage/LocalStorage'
import ServicesService from '../../../../api/gerep/ServicesService'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import { YES_OR_NO_DATA } from '../../../../components/formConstants'

type SendContractFormProps = {
	individuList: any[]
}

const SEND_CONTRACT_DATA = [
	{
		value: '1',
		label: 'Optique'
	},
	{
		value: '2',
		label: 'Dentaire'
	},
	{
		value: '3',
		label: 'Hospitalisation'
	},
	{
		value: '4',
		label: 'Maternité'
	},
	{
		value: '5',
		label: 'Prothèse auditive'
	},
	{
		value: '6',
		label: 'Examen (IRM, scanner, ...)'
	},
	{
		value: '7',
		label: 'Autres devis'
	}
]

const DEMANDE_INFO = (
	<>
		<p>Avant de nous joindre votre devis, merci de vérifier que les informations suivantes sont présentes sur celui-ci :</p>
		<ul>
			<li>bénéficiaire des soins</li>
			<li>nature de l’acte</li>
			<li>codification sécurité sociale</li>
			<li>montant des frais engagés</li>
			<li>base de remboursement de la sécurité sociale s’il s’agit d’un soin remboursable</li>
			<li>n° de dent pour un devis dentaire</li>
		</ul>
	</>
)

const SendContractForm: React.FC<SendContractFormProps> = (
	{
		individuList
	}
) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

	const onSubmit = (values: any) => {
		const {
			beneficiaire,
			mutuelle,
			mutuelleFile,
			demande,
			files
		} = values

		const personneConcernee = individuList && individuList.find(i => i[1].numIndividu === beneficiaire)
		const nomPersonneConcernee = personneConcernee && `${personneConcernee[1].prenom} ${personneConcernee[1].nom}`

		const mutuelleBody = mutuelle === 'oui' ? [{
			key: 'mutuelleFile',
			value: mutuelleFile[0]
		}] : []

		setIsLoading(true)
		ServicesService.demandeDevis(
			LocalStorage.getToken(),
			[{
				key: 'demande',
				value: {
					beneficiaire,
					nomBeneficiaire: nomPersonneConcernee,
					mutuelle: mutuelle === 'oui',
					demande
				}
			}, ...files.map((file: any, index: number) => ({
				key: `file${index}`,
				value: file
			})),
				...mutuelleBody
			])
			.then(() => {
				addToast(ToastType.SUCCESS, 'global.success')
				setOpenConfirmation(true)
			})
			.catch((e) => {
				const error = e?.data?._error || 'Echec de l\'envoi de demande de devis'
				addToast(ToastType.ERROR, '', error)
			})
			.finally(() => setIsLoading(false))
	}

	const validate = (values: any) => {
		const errors: any = {}

		if (!values[SEND_CONTRACT_FIELDS.BENEFICIARY]) {
			errors[SEND_CONTRACT_FIELDS.BENEFICIARY] = intl.formatMessage({ id: 'global.error.required' })
		}

		if (!values[SEND_CONTRACT_FIELDS.DEMANDE]) {
			errors[SEND_CONTRACT_FIELDS.DEMANDE] = intl.formatMessage({ id: 'global.error.required' })
		}

		if (!values[SEND_CONTRACT_FIELDS.MUTUELLE]) {
			errors[SEND_CONTRACT_FIELDS.MUTUELLE] = intl.formatMessage({ id: 'global.error.required' })
		} else if (values[SEND_CONTRACT_FIELDS.MUTUELLE] === 'oui') {
			if (!values[SEND_CONTRACT_FIELDS.MUTUELLE_FILE] || values[SEND_CONTRACT_FIELDS.MUTUELLE_FILE]?.length === 0) {
				errors[SEND_CONTRACT_FIELDS.MUTUELLE_FILE] = intl.formatMessage({ id: 'global.error.required' })
			}
		}

		if (!values[SEND_CONTRACT_FIELDS.FILES] || values[SEND_CONTRACT_FIELDS.FILES]?.length === 0) {
			errors[SEND_CONTRACT_FIELDS.FILES] = intl.formatMessage({ id: 'global.error.required' })
		}

		return errors
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={validate}
			render={({ handleSubmit, values, errors, touched, form: { reset } }) => {
				const filesMessage = values[SEND_CONTRACT_FIELDS.DEMANDE] === '1' ? 'sendContract.input.files.titles.contractAndOrdonnance' : 'sendContract.input.files.titles.contract'

				return (
					<form onSubmit={handleSubmit}>
						<Stack direction="column" spacing="24px">
							<SelectInput
								name={SEND_CONTRACT_FIELDS.BENEFICIARY}
								data={individuList.map((individu: any) => ({ label: `${individu[1].prenom} ${individu[1].nom}`, value: individu[1].numIndividu }))}
								label={intl.formatMessage({id: 'sendContract.input.beneficiary.label'})}
								defaultValue={individuList.length === 1 ? individuList[0][1].numIndividu : undefined}
								mandatory
							/>

							<SelectInput
								name={SEND_CONTRACT_FIELDS.DEMANDE}
								data={SEND_CONTRACT_DATA}
								label={intl.formatMessage({ id: 'sendContract.input.demand.label' })}
								onChange={() => addToast(ToastType.INFO, '', DEMANDE_INFO)}
								mandatory
							/>

							<RadioInput
								name={SEND_CONTRACT_FIELDS.MUTUELLE}
								label={intl.formatMessage({ id: 'sendContract.input.mutuelle.label' })}
								data={YES_OR_NO_DATA}
								direction="row"
								error={touched && touched[SEND_CONTRACT_FIELDS.MUTUELLE] && errors && errors[SEND_CONTRACT_FIELDS.MUTUELLE]}
							/>

							{values[SEND_CONTRACT_FIELDS.MUTUELLE] === 'oui' && (
								<FileInput
									name={SEND_CONTRACT_FIELDS.MUTUELLE_FILE}
									title={intl.formatMessage({ id: 'sendContract.input.mutuelleFile.title' })}
									label={intl.formatMessage({ id: 'sendContract.input.mutuelleFile.label' })}
									required
									error={errors && errors[SEND_CONTRACT_FIELDS.MUTUELLE_FILE]}
									maxFileNumber={1}
								/>
							)}

							<FileInput
								name={SEND_CONTRACT_FIELDS.FILES}
								title={intl.formatMessage({ id: filesMessage })}
								label={intl.formatMessage({ id: 'sendContract.input.files.label' })}
								error={errors && errors[SEND_CONTRACT_FIELDS.FILES]}
								required
							/>

							<Stack alignItems="center">
								<ButtonDefault
									variant="contained"
									onClick={handleSubmit}
									type="submit"
								>
									{isLoading ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="global.button.validate" />}
								</ButtonDefault>
								<Box display="flex" width="100%" flex={1} alignSelf={isMobile || isTabletPortrait ? 'unset' : 'baseline'}>
									<Typography sx={{ position: isMobile || isTabletPortrait ? 'unset' : 'relative', bottom: '32px', left: 0, height: '32px', display: 'flex', alignItems: 'flex-end' }} variant="body2" color="#7a7a7a">
										<FormattedMessage id="global.mandatory" />
									</Typography>
								</Box>
							</Stack>
							<ConfirmationPopin
								title={intl.formatMessage({ id: 'sendContract.title' })}
								open={openConfirmation}
								setOpen={setOpenConfirmation}
								resetForm={reset}
							/>
						</Stack>
					</form>
				)
			}}
		/>
	)
}

export default SendContractForm
