import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as ribCotisationActions from '../../../webservices/rib/cotisation/ribCotisationActions'
import { Box, Link, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import ButtonDefault from '../../../../components/ButtonDefault'
import CheckboxInput from '../../../../components/form/CheckboxInput'
import { Edit } from '@mui/icons-material'
import { STEP1_CHECKBOX_NAME, STEP2_FORM_NAME } from '../services/souscriptionConstants'
import TextInput from '../../../../components/form/TextInput'
import FileInput from '../../../../components/fileInput/FileInput'
import { COTISATIONS, NATURE, REMBOURSEMENTS } from '../../../webservices/rib/ribConsts'
import IBAN from 'iban'
import { isEmpty } from '../../../../utils/stringUtils'
import IBANTextInput from '../../../../components/IBANTextInput'

type GarantieSouscriptionRibSectionProps = {
	editRib: boolean,
	setEditRib: Dispatch<SetStateAction<boolean>>
	values: any
	rib: any
	errors: any
	garanties: Garantie[]
}

const isPrelevementSurSalaire = (rib: any) => rib && rib.nature !== NATURE.VIREMENT

const GarantieSouscriptionRibSection: React.FC<GarantieSouscriptionRibSectionProps> = (
	{
		// @ts-ignore
		downloadMandatSepa,
		editRib,
		setEditRib,
		values,
		rib,
		errors,
		garanties
	}
) => {
	const intl = useIntl()

	const canEditBank = useMemo(() => isEmpty(rib?.banque), [rib])

	const selectedOptionModePaie = useMemo(() => {
		const selectedOptionKey = values[STEP1_CHECKBOX_NAME] && values[STEP1_CHECKBOX_NAME][0]?.idGarantie[0]
		const selectedOption = garanties.find((garantie: Garantie) => garantie.key === selectedOptionKey)

		return selectedOption && selectedOption.modePaie
	}, [values, garanties])

	useEffect(() => {
		if (selectedOptionModePaie === 2 && !rib) {
			setEditRib(true)
		}
	}, [selectedOptionModePaie, setEditRib, rib])

	const getLibellemandatSepa = (iban: string) => {
		if (isPrelevementSurSalaire(rib)) {
			return intl.formatMessage({ id: 'souscription.input.libelleMandatSepa.prelevementSalaire' })
		}

		if (IBAN.isValid(iban)) {
			return intl.formatMessage({ id: 'souscription.input.libelleMandatSepa.modifyCoord' }, {
				link: (
					<Link
						component="button"
						variant="body2"
						sx={{ verticalAlign: 'initial' }}
						onClick={() => downloadMandatSepa(iban || (rib && rib?.iban))}
					>
						<FormattedMessage id="souscription.input.libelleMandatSepa.sepa" />
					</Link>
				)
			})
		}

		return intl.formatMessage({ id: 'souscription.input.libelleMandatSepa.default' })
	}

	const getIntro = () => {
		if (editRib) {
			return 'Merci de saisir ci-dessous votre RIB pour le prélèvement de vos cotisations :'
		}
		const ribType = rib?.type
		if (isPrelevementSurSalaire(rib)) {
			return (
				<span>Votre mode de paiement pour la souscription d'option est le suivant : <b>Prélèvement sur salaire</b></span>
			)
		}
		if (ribType === REMBOURSEMENTS) {
			return 'Coordonnées bancaires actuellement en notre possession pour le remboursement de vos soins de santé :'
		}
		if (ribType === COTISATIONS) {
			return 'Les coordonnées bancaires associées à vos cotisations sont les suivantes :'
		}
	}

	return (
		<Stack direction="column" spacing="24px">
			<Typography variant="body2">
				{getIntro()}
			</Typography>
			{!isPrelevementSurSalaire(rib) &&
				<>
					<Stack direction="column" spacing="12px">
						<IBANTextInput
							maxWidth="unset"
							name={STEP2_FORM_NAME.IBAN}
							label={intl.formatMessage({ id: 'souscription.input.iban' })}
							disabled={!editRib}
						/>
						<TextInput
							maxWidth="unset"
							name={STEP2_FORM_NAME.BANK}
							label={intl.formatMessage({ id: 'souscription.input.bank' })}
							disabled={!editRib || !canEditBank}
						/>
						<TextInput
							maxWidth="unset"
							name={STEP2_FORM_NAME.ACCOUNT_HOLDER}
							label={intl.formatMessage({ id: 'souscription.input.titulaire' })}
							disabled={!editRib}
						/>
						{editRib && (
							<FileInput
								name={STEP2_FORM_NAME.RIB}
								title={intl.formatMessage({ id: 'souscription.input.rib.title' })}
								label={intl.formatMessage({ id: 'souscription.input.rib.label' })}
							/>
						)}
					</Stack>
					{!editRib && (
						<Stack direction="column" spacing="12px">
							<Typography variant="body2">
								<FormattedMessage id="souscription.mode.editRib" />
							</Typography>
							<Box width="100%" display="flex" justifyContent="center">
								<ButtonDefault variant="text" color="secondary" onClick={() => setEditRib(true)} startIcon={<Edit />}>
									<FormattedMessage id="global.button.modify" />
								</ButtonDefault>
							</Box>
						</Stack>
					)}
				</>
			}
			<CheckboxInput
				name={STEP2_FORM_NAME.ACCEPT_SEPA}
				data={[
					{
						label: getLibellemandatSepa(values[STEP2_FORM_NAME.IBAN]),
						value: 'acceptMandatSepa'
					}
				]}
				error={errors[STEP2_FORM_NAME.ACCEPT_SEPA]}
			/>
		</Stack>
	)
}

const mapStateToProps = () => {
}

const mappedActions = {
	downloadMandatSepa: ribCotisationActions.downloadMandatSepa
}

export default compose(
	connect(mapStateToProps, mappedActions)(GarantieSouscriptionRibSection))