export const isEmpty = str => (
	str === null
	|| typeof str === 'undefined'
	|| (typeof str === 'string' && str.trim().length === 0)
)

export const isNotEmpty = str => (
	!isEmpty(str)
)

export const equalsIgnoreCase = (v1, v2) => {
	if (typeof v1 === 'string' && typeof v2 === 'string') {
		return v1.toUpperCase() === v2.toUpperCase()
	}
	return false
}

export const toUpperCase = value => (
	typeof value === 'string' ? value.toUpperCase() : value
)

export const trim = (value) => {
	if (!value || typeof value.trim !== 'function') {
		return value
	}
	return value.trim()
}
