import getBrowserName from './BrowserName'

declare global {
    interface Window {
        __SERVER_URL__: any,
        __SANTECLAIR_URL__: any,
        __GA_ID__: any,
        __ENVIRONMENT__: any,
        __PASSCONNECT_URL__: any,
        __openApplication__: any,
        __openApplicationPourUnBeneficiaire__: any,
    }
}

const MIME_TYPE = {
    GIF: 'image/gif',
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    PDF: 'application/pdf',
    BMP: 'image/bmp'
}

const maxSizeMo = 5

const appConst = {
    responsive: {
        breakpoints: {
            extraLarge: 1200,
            large: 992,
            medium: 768,
            small: 576
        }
    },
    cacheDuration: {
        default: 600,
        affiliation: 600,
        rib: 600,
        contrats: 600,
        remboursement: 600,
        contact: 600,
        surveillance: 60,
        gerepDigital: 600
    },
    idleTime: 60 * 60 * 1000,
    fileUpload: {
        // TODO typo
        maxSizMo: maxSizeMo,
        maxSize: 1024 * 1024 * maxSizeMo,
        format: 'gif, jpg, jpeg, png, pdf, bmp',
        attrAccept: '.gif, .jpg, .jpeg, .png, .pdf, .bmp',
        mimeTypes: [
            MIME_TYPE.GIF,
            MIME_TYPE.JPEG,
            MIME_TYPE.PNG,
            MIME_TYPE.PDF,
            MIME_TYPE.BMP
        ]
    },
    acceptCookie: {
        name: 'accept-cookies',
        expiration: {years: 13 / 12},
        path: '/'
    },
    date: {
        fr: {
            format: 'DD/MM/YYYY',
            placeholder: 'JJ/MM/AAAA'
        }
    },
    browserName: "",
    userAgent: "",
    browserVersion: "",
    googleAnalytics: {},
    authorizationHeader: "",
    gerepApi: {},
    cardiboxApi: {},
    santeClairUrl: "",
    passConnectUrl: "",
    recaptchaSiteKey: "",
    openApplication: function(application:string, authSrv:string, idpCode:string, targetUrl:any, otherParameters:any, roles:String) { },
    openApplicationPourUnBeneficiaire:function(application:string, authSrv:string, idpCode:string, targetUrl:any, nomBeneficiaire:string, prenomBeneficiaire:string, emailBeneficiaire:string, birthDateBeneficiaire:string, otherParameters:any, roles:String) { },
}

const browserName = getBrowserName()
const ua = navigator.userAgent
const M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
const browserVersion = M[2]
appConst.browserName = browserName
appConst.userAgent = ua
appConst.browserVersion = browserVersion

appConst.googleAnalytics = {
    id: window.__GA_ID__ || 'UA-99833139-1',
    cookiesNames: ['__utma', '__utmb', '__utmc', '__utmz', '_ga', '_gat'],
    disabledCookieName: `ga-disable-${window.__GA_ID__ || 'UA-99833139-1'}`
}

appConst.authorizationHeader = 'X-Auth'

window.__SERVER_URL__ = window.__SERVER_URL__ || 'http://gerep-dev.ipsosenso.com'
appConst.cardiboxApi = {
    baseUrl: `${window.__SERVER_URL__}/api/cardibox`,
    authorizationKey: 'Bearer ayfIRNPOzf'
}
appConst.gerepApi = {
    baseUrl: `${window.__SERVER_URL__}/api/gerep`,
    baseUrlEE: `${window.__SERVER_URL__}/api/ee`
}

if (window.__ENVIRONMENT__ === 'production') {
// =============================
// CONF SPECIFIQUE PRODUCTION
// =============================
    appConst.passConnectUrl = `${window.__PASSCONNECT_URL__}`
    appConst.santeClairUrl = `${window.__SANTECLAIR_URL__}`
    appConst.recaptchaSiteKey = '6LcDPgAqAAAAACCzPKS7b-4uxkacU9ltJR1OAwe5'
} else if (window.__ENVIRONMENT__ === 'pprod') {
// =============================
// CONF SPECIFIQUE PRE-PROD
// =============================
    appConst.passConnectUrl = `${window.__PASSCONNECT_URL__}`
    appConst.santeClairUrl = `${window.__SANTECLAIR_URL__}`
    appConst.recaptchaSiteKey = '6LcoPgAqAAAAANb14HZ37INlbd2DYOqUXZauhLUO'
} else if (window.__ENVIRONMENT__ === 'recette') {
// =============================
// CONF SPECIFIQUE RECETTE
// =============================
    appConst.passConnectUrl = `https://passconnect-preprod.groupe-vyv.fr`
    appConst.santeClairUrl = `${window.__SANTECLAIR_URL__}`
    appConst.recaptchaSiteKey = '6LcoPgAqAAAAANb14HZ37INlbd2DYOqUXZauhLUO'
} else {
// =============================
// DEVELOPPEMENT
// =============================
    appConst.passConnectUrl = `https://passconnect-preprod.groupe-vyv.fr`
    appConst.santeClairUrl = 'https://services.integration.santeclair.fr/___idApplication___/mysanteclair/authentification.do?idPart=GEREP&idCharte=GEREP&idJeton='
    appConst.recaptchaSiteKey = '6LcjzBMUAAAAAFwOMJbVOFYAY6E_PXd7ado6E1GD'
}

// @ts-ignore
appConst.openApplication = window['openApplicationV2']
// @ts-ignore
appConst.openApplicationPourUnBeneficiaire = window['openApplicationPourUnBeneficiaireV2']

export default appConst
