import {logoutEA, logoutEE, types} from '../../univers/ui/authentication/services/login/loginActions'
import appConst from '../../constant/appConstants'
import LocalStorage from '../../business/storage/LocalStorage'
import {UNIVERS_ENTREPRISES, UNIVERS_PARTICULIERS} from '../../univers/constant/univers'

/**
 * Variable contenant le timer de déconnexion.
 */
let timer

const createTimeout = store => setTimeout(() => {
    if(LocalStorage.getUnivers() === UNIVERS_ENTREPRISES.id) {
        logoutEA('Votre session a expiré')(store.dispatch)
    } else if(LocalStorage.getUnivers() === UNIVERS_PARTICULIERS.id) {
        logoutEE('Votre session a expiré')(store.dispatch)
    } else {
        logoutEA('Votre session a expiré')(store.dispatch)
    }
}, appConst.idleTime)

/**
 * Middleware chargé de la déconnexion automatique de l'utilisateur
 * en cas d'inactivité.
 *
 * Dès que l'utilisateur est loggué, on déclenche un timeout qui est
 * alors réinitialisé à chaque action propagée à travers le middleware.
 *
 * L'action de déconnexion provoque l'arrêt de la surveillance.
 *
 * @param store
 */
const logoutMiddleware = store => next => action => {
    if (action.type === types.INIT_USER
        || action.type === types.LOG_USER) {
        if (timer) {
            clearTimeout(timer)
        }
        timer = createTimeout(store)
    } else if (action.type !== types.LOGOUT) {
        // si le timer n'a pas été déclenché, l'action n'a pas d'effet au niveau
        // de ce middleware
        if (timer) {
            clearTimeout(timer)
            timer = createTimeout(store)
        }
    } else {
        // LOGOUT
        clearTimeout(timer)
        timer = null
    }
    return next(action)
}

export default logoutMiddleware
