import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import LocalStorage from '../../../../business/storage/LocalStorage'
import ServicesService from '../../../../api/gerep/ServicesService'
import TeleconsultationCard from './TeleconsultationCard'
import ButtonDashboard from '../../../../components/ButtonDashboard'

type HospiwayBlockProps = {
	service: any
}

const HospiwayBlock: React.FC<HospiwayBlockProps> = ({ service }) => {
	const [hospiwayLink, setHospiwayLink] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const buttonLibelle = service?.libelleLienWeb || 'Accédez au site'

	useEffect(() => {
		ServicesService.getHospiwayLink(LocalStorage.getToken())
			.then(({ url }) => setHospiwayLink(url))
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false))
	}, [])

	return (
		<TeleconsultationCard
			service={service}
			lienWebCustom={
				<a
					href={hospiwayLink}
					target="_blank"
					rel="noopener"
					style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center' }}
				>
					<ButtonDashboard>
						{isLoading ? <CircularProgress size={24} color="inherit" /> : buttonLibelle}
					</ButtonDashboard>
				</a>
			}
		/>
	)
}

export default HospiwayBlock