import React, {useContext, useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'
import PageLayout from '../../../../components/PageLayout'
import {FormattedMessage, useIntl} from 'react-intl'
import StepperForm from '../../../../components/StepperForm'
import {Form} from 'react-final-form'
import StepsButton from '../../../../components/StepsButton'
import {Stack, Typography} from '@mui/material'
import GarantieSouscriptionForm from '../components/GarantieSouscriptionForm'
import {createSelector} from 'reselect'
import GarantieSouscriptionRibSection from '../components/GarantieSouscriptionRibSection'
import NoGaranties from '../components/NoGaranties'
import Loader from '../../../../components/Loader'
import {STEP1_CHECKBOX_NAME, STEP1_FORM, STEP2_FORM_NAME, STEP3_FORM_NAME} from '../services/souscriptionConstants'
import IBAN from 'iban'
import GarantieSouscriptionEtape3Form from '../components/GarantieSouscriptionEtape3Form'
import {ToastContext} from '../../../../components/toast/ToastContext'
import {ToastType} from '../../../../components/toast/toastConstants'
import {positiveOrNaN} from '../../../../utils/numberUtils'
import {getAdhesion} from '../../webservices/WsSelector'
import {COTISATIONS, NATURE} from '../../../../ea/webservices/rib/ribConsts'
import ContratNotAvailable from '../../../../ea/ui/contrats/components/ContratNotAvailable'
import Box from '@mui/material/Box'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import {getContract} from '../../preaffiliation/user/userSelector'
import {souscrire} from '../services/souscriptionActions'
import ConfirmationPopinPreaff from '../../preaffiliation/components/ConfirmationPopinPreaff'
import {Redirect} from 'react-router-dom'
import {MAIN_PATH_UNIVERS} from '../../../../constant/path'
import {compose} from 'redux'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import appConst from '../../../../constant/appConstants'
import {logout} from "../../preaffiliation/services/preaffiliationActions";


const ARTICLE_1_SHORTCUT = 'intro_etape_1_option'
const ARTICLE_3_SHORTCUT = 'intro_etape_3_option'
const CONFIRMATION_ARTICLE = 'txt_popin_confirmation_opt'

const isPrelevementSurSalaire = (selectedOptions: any) => selectedOptions && selectedOptions.modePaie !== NATURE.VIREMENT

const resetScroll = () => {
	window.scrollTo(0, 0)
}

const GarantieSouscriptionPage = (
	{
		// @ts-ignore
		garanties,
		// @ts-ignore
		adhesion,
		// @ts-ignore
		contract,
		// @ts-ignore
		articleConfirmation,
		// @ts-ignore
		isSouscriptionGarantiesLoading,
		// @ts-ignore
		ribInfoPreaff,
		// @ts-ignore
		souscrireOption,
		// @ts-ignore
		getArticleByShortcut

	}
) => {
	const intl = useIntl()
	const { addToast } = useContext(ToastContext)

	const [currentStep, setCurrentStep] = useState<number>(0)
	const [editRib, setEditRib] = useState<boolean>(false)
	const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
	const [openContratNotAvailable, setOpenContratNotAvailable] = useState<boolean>(false)
	const [submitLoading, setSubmitLoading] = useState<boolean>(false)

	const { isMobile, isTabletPortrait, height } = useBreakpoints()
	const isMobOrTabVert = isMobile || isTabletPortrait

	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_1_SHORTCUT)
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_3_SHORTCUT)
		getArticleByShortcut(appConst.cardiboxApi, CONFIRMATION_ARTICLE)
	}, [])

	const STEPS_LABELS = useMemo(() => [
		intl.formatMessage({ id: 'souscription.steps.1' }),
		intl.formatMessage({ id: 'souscription.steps.2' }),
		intl.formatMessage({ id: 'souscription.steps.3' })
	], [intl])

	const isGaranties = useMemo(() => garanties && garanties.length > 0, [garanties])

	const onSubmit = (values: any) => {

		const selectedOptionValue = values[STEP1_CHECKBOX_NAME] && values[STEP1_CHECKBOX_NAME].filter((value: any) => value?.selected)[0]
		const selectedOption = garanties.filter((garantie: any) => garantie.key === selectedOptionValue?.idGarantie[0])[0]

		const getPrixOption = () => {
			if (selectedOption.cumulative) {
				// le prix de l'option varie en fonction du nombre de bénéficiaires
				const selectedBenef = selectedOption?.beneficiaires || []
				if (selectedBenef.length === 0) {
					return positiveOrNaN(selectedOption.prix)
				}
				return selectedOption.beneficiaires.filter((benef: any) => selectedBenef.includes(benef.id)).reduce((acc: number, benef: any) => acc + benef.prix, 0)
			}
			// le prix placé sur l'option, qui est normalement le prix le plus bas
			return positiveOrNaN(selectedOption.prix)
		}

		const selectedBenef = selectedOption?.beneficiaires || []

		const step1Data = {
			fract: selectedOption.fract,
			echeance: selectedOption.echeance,
			options: [{
				beneficiaires: selectedBenef.filter((benef: any) => values[STEP1_CHECKBOX_NAME] &&
					values[STEP1_CHECKBOX_NAME][0]?.beneficiaires?.includes(benef.numIndiv)).map((benef: any) => ({
						typBene: benef.typBene,
						numIndiv: benef.numIndiv
					})
				),
				total: getPrixOption(),
				...selectedOption
			}],
			editRib: editRib
		}

		const ribFile = editRib ? values[STEP2_FORM_NAME.RIB] : {}

		const ribInfos = editRib ? {
			iban: values[STEP2_FORM_NAME.IBAN],
			banque: values[STEP2_FORM_NAME.BANK],
			titulaire: values[STEP2_FORM_NAME.ACCOUNT_HOLDER]
		} : {}

		const newRibInfo = editRib ? {
			type: COTISATIONS,
			nature: NATURE.VIREMENT
		} : {}

		const ribInfoData = {
			rib:
				{
					...ribInfos,
					...newRibInfo
				}
		}

		const dataToSend = [{
			key: 'souscription',
			value: {
				...step1Data,
				...ribInfoData
			}
		},
			{
				key: 'rib',
				value: ribFile
			}
		]

		setSubmitLoading(true)
		return souscrireOption(currentStep + 1, adhesion.numadhe, adhesion.idadhesion, dataToSend)
			.then(() => {
				if (currentStep < STEPS_LABELS.length - 1) {
					setCurrentStep(currentStep + 1)
					resetScroll()
				} else if (currentStep === STEPS_LABELS.length - 1) {
					setOpenConfirmation(true)
				}
			})
			.catch((e: any) => {
				if (!e.data || Object.keys(e.data).length === 0) {
					addToast(ToastType.ERROR, 'Une erreur technique est survenue. Votre demande n\'a pas pu aboutir.')
				} else if (e?.data && e?.data?._error) {
					addToast(ToastType.ERROR, e?.data?._error)
				}
				return e?.data
			})
			.finally(() => setSubmitLoading(false))
	}

	const checkboxInitial = useMemo(() => garanties ? (
		(garanties as Garantie[]).map((option) => ({
				idGarantie: [option.key],
				selected: false,
				beneficiaires: option.beneficiaires.filter((beneficiaire) => beneficiaire.garantieObligatoire)
					.map((beneficiaire) => (beneficiaire.numIndiv))
			})
		)) : [], [garanties])

	if (isSouscriptionGarantiesLoading) {
		return <Loader />
	}

	const validateFirstStep = (values: any, errors: any, selectedOptions: any) => {
		if (selectedOptions.length === 0) {
			errors[STEP1_FORM] = intl.formatMessage({ id: 'souscription.form.error.options' })
		} else if (selectedOptions.length > 1) {
			errors[STEP1_FORM] = intl.formatMessage({ id: 'souscription.form.error.tooManyOptions' })
		} else if (selectedOptions[0].beneficiaires.length === 0) {
			errors[STEP1_FORM] = intl.formatMessage({ id: 'souscription.form.error.beneficiaires' })
		} else {
			const option: Garantie = garanties.find((option: any) => option.key === selectedOptions[0].idGarantie[0])
			if (option && option.nbBeneMax && option.nbBeneMax < selectedOptions[0].beneficiaires.length) {
				errors[STEP1_FORM] = intl.formatMessage({ id: 'souscription.form.error.tooManyBeneficiairies' }, { nbBeneMax: option.nbBeneMax })
			}
		}
	}

	const validateSecondStep = (values: any, errors: any) => {
		const selectedOptionValue = values[STEP1_CHECKBOX_NAME] && values[STEP1_CHECKBOX_NAME].filter((value: any) => value?.selected)[0]
		const selectedOption = garanties.filter((garantie: any) => garantie.key === selectedOptionValue?.idGarantie[0])[0]

		if (!isPrelevementSurSalaire(selectedOption)) {
			if (!values[STEP2_FORM_NAME.IBAN]) {
				errors[STEP2_FORM_NAME.IBAN] = intl.formatMessage({ id: 'global.error.required' })
			} else if (!IBAN.isValid(values[STEP2_FORM_NAME.IBAN])) {
				errors[STEP2_FORM_NAME.IBAN] = intl.formatMessage({ id: 'souscription.form.error.iban' })
			}
			if (!values[STEP2_FORM_NAME.BANK]) {
				errors[STEP2_FORM_NAME.BANK] = intl.formatMessage({ id: 'global.error.required' })
			}
			if (!values[STEP2_FORM_NAME.ACCOUNT_HOLDER]) {
				errors[STEP2_FORM_NAME.ACCOUNT_HOLDER] = intl.formatMessage({ id: 'global.error.required' })
			}
		}
		if (editRib) {
			if (!values[STEP2_FORM_NAME.RIB] || values[STEP2_FORM_NAME.RIB].length === 0) {
				errors[STEP2_FORM_NAME.RIB] = intl.formatMessage({ id: 'global.error.required' })
			}
		}
		if (!values[STEP2_FORM_NAME.ACCEPT_SEPA] || values[STEP2_FORM_NAME.ACCEPT_SEPA].length === 0) {
			// if (!editRib && isPrelevementSurSalaire(selectedOption)) {
			if (isPrelevementSurSalaire(selectedOption)) {
				errors[STEP2_FORM_NAME.ACCEPT_SEPA] = intl.formatMessage({ id: 'souscription.form.error.acceptPrelev' })
			} else {
				errors[STEP2_FORM_NAME.ACCEPT_SEPA] = intl.formatMessage({ id: 'souscription.form.error.acceptSepa' })
			}
		}
	}

	const validateThirdStep = (values: any, errors: any) => {
		if (!values[STEP3_FORM_NAME] || values[STEP3_FORM_NAME].length === 0) {
			errors[STEP3_FORM_NAME] = intl.formatMessage({ id: 'souscription.form.error.acceptConditions' })
		}
	}

	const initialValues = {
		[STEP1_CHECKBOX_NAME]: checkboxInitial,
		[STEP2_FORM_NAME.IBAN]: ribInfoPreaff?.iban,
		[STEP2_FORM_NAME.BANK]: ribInfoPreaff?.banque,
		[STEP2_FORM_NAME.ACCOUNT_HOLDER]: ribInfoPreaff?.titulaire
	}

	if (Object.keys(adhesion).length === 0 && adhesion.constructor === Object) {
		return <Redirect to={MAIN_PATH_UNIVERS.LOGIN_EA}/>
	}

	return (

		<>
			<Box
				display="block"
				minHeight={height}
				sx={{ bgcolor: '#f7f5f4', overflow: 'hidden'}}
				justifyContent="center"
				paddingBottom={isMobOrTabVert ? '12px': '72px'}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					paddingX={isMobOrTabVert ? '12px' : '40px'}
				>
					<img
						src={`${window.location.origin}/blobs/medias/s/logo_gerep/gerep_logo.dc371007.png`}
						style={{ width: '100px', height: '56px', paddingTop:'8px' }}
						alt="logo"
					/>

					<Stack direction="column" justifyContent="flex-end" textAlign="right">
						<Typography variant="h4" fontSize="0.875rem">
							<FormattedMessage id="preaffiliation.company"/>
							{contract.libSociete}
						</Typography>
						<Typography variant="subtitle1" fontSize="0.8125rem">
							<FormattedMessage id="preaffiliation.category"/>
							{contract.college}
						</Typography>
					</Stack>
				</Stack>

				<Box
					display="flex"
					justifyContent="center"
					paddingTop="8px"
					paddingX={isMobile ? '12px': 0}
				>
					<PageLayout title={intl.formatMessage({ id: 'souscription.title' })}>
						<>
							<Form
								initialValues={initialValues}
								validate={(values) => {
									const errors: any = {}
									const selectedOptions = values[STEP1_CHECKBOX_NAME] ? values[STEP1_CHECKBOX_NAME].filter((option: any) => option.selected) : []

									if (currentStep === 0) {
										validateFirstStep(values, errors, selectedOptions)
									} else if (currentStep === 1) {
										validateSecondStep(values, errors)
									} else if (currentStep === 2) {
										validateThirdStep(values, errors)
									}
									return errors
								}}
								onSubmit={onSubmit}
								render={({ handleSubmit, values, errors }) => {
									return (
										<>
											{isGaranties ?
												(
													<Stack direction="column" spacing="24px">
														<StepperForm steps={STEPS_LABELS} activeStep={currentStep} />

														{currentStep === 0 && (
															<GarantieSouscriptionForm
																garanties={garanties}
																values={values}
																errors={errors}
																onError={() => setOpenContratNotAvailable(true)}
															/>
														)}

														{currentStep === 1 && (
															<GarantieSouscriptionRibSection
																garanties={garanties}
																values={values}
																rib={ribInfoPreaff}
																errors={errors}
															/>
														)}

														{currentStep === 2 && (
															<GarantieSouscriptionEtape3Form
																garanties={garanties}
																values={values}
																errors={errors}
																onError={() => setOpenContratNotAvailable(true)}
															/>
														)}

														<StepsButton
															step={currentStep}
															setStep={setCurrentStep}
															maxSteps={STEPS_LABELS.length}
															handleSubmit={handleSubmit}
															loading={submitLoading}
															labelNext={intl.formatMessage({ id: 'souscription.buttons.nextStep' })}
															labelEnd={intl.formatMessage({id:'souscription.buttons.validate'})}
															labelPrevious={intl.formatMessage({ id: 'souscription.buttons.previousStep' })}

														/>
													</Stack>
												) :
												<NoGaranties />
											}
										</>
									)
								}}
							/>
							<ConfirmationPopinPreaff
								title={intl.formatMessage({ id: 'souscription.title' })}
								open={openConfirmation}
								setOpen={setOpenConfirmation}
								body={
									<Stack direction="column" spacing="24px">
										{articleConfirmation &&
											<Typography
												variant="body2"
												style={{fontSize: '16px', lineHeight: '1'}}
												dangerouslySetInnerHTML={{__html: articleConfirmation.fields.body}}
											/>
										}
									</Stack>
								}
								goBackAction={{
									// @ts-ignore
									action: () => logout(),
									title: "Fermer"
								}}
							/>
							<ContratNotAvailable open={openContratNotAvailable} setOpen={setOpenContratNotAvailable} />
						</>
					</PageLayout>
				</Box>
			</Box>
		</>
	)
}

const makeMapStateToProps = (state: any) => {
	/*
	 * Les options souscriptibles sont récupérées de l'adhésion en préaffiliation.
	 */
	const adhesion = state.pages && getAdhesion(state)

	if (Object.keys(adhesion).length !== 0) {
		const garantiesSouscriptionSelector = createSelector([
			getAdhesion
		], ({garanties}) => (
			(garanties || []).map((garantie: { beneficiaires: any[] }) => {
				return {
					...garantie,
					selected: false,
					beneficiaires: garantie.beneficiaires.map((beneficiaire) => {
						return {
							...beneficiaire,
							selected: false
						}
					})
				}
			})
		))

		/*
         * Les données saisies par l'utilisateur lors de sa préaffiliation.
         */
		const preaffiliationSelector = createSelector([
			getAdhesion
		], ({preaffiliation}) => preaffiliation)
		/*
         * Les données avec lesquelles initialiser le formulaire de souscription.
         */
		const initialValuesSelector = createSelector([
			garantiesSouscriptionSelector,
			preaffiliationSelector
		], (garanties, {
			coordBancaires: {
				iban,
				banque,
				titulaire
			}
		}) => ({
			options: garanties,
			rib: {
				iban,
				banque,
				titulaire
			}
		}))
		// @ts-ignore
		return {
			garanties: garantiesSouscriptionSelector(state),
			contract: state.pages && getContract(state),
			adhesion: adhesion,
			// @ts-ignore
			ribInfoPreaff: initialValuesSelector(state).rib,
			// @ts-ignore
			isSouscriptionGarantiesLoading: false,
			articleConfirmation: cardiboxSelectors.articles.getByShortcut(state.cardibox)[CONFIRMATION_ARTICLE]
		}
	} else {
		return {
			garanties: [],
			contract: {},
			adhesion: adhesion,
			// @ts-ignore
			ribInfoPreaff: {},
			// @ts-ignore
			isSouscriptionGarantiesLoading: false,
			articleConfirmation: cardiboxSelectors.articles.getByShortcut(state.cardibox)[CONFIRMATION_ARTICLE]
		}
	}
}

const mappedActions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut,
	souscrireOption: (
		step: number,
		numAdherent: number,
		idAdhesion: number,
		parts: any
	) => {
		if (step === 3) {
			return souscrire(numAdherent, idAdhesion, parts)
		}
		// OK, next step
		return () => Promise.resolve()
	}
}

export default compose(connect(makeMapStateToProps, mappedActions))(GarantieSouscriptionPage)
