import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import LocalStorage, {IS_KPI} from './business/storage/LocalStorage'
import {LoginPageEA, LoginPageEE} from './univers/ui/authentication/pages/LoginPage'
import HomePreAffiliationPage from './univers/ui/preaffiliation/pages/home/homePreaffiliation'
import messagesEA from './ea/messages'
import messagesKpi from './kpi/messages'
import messages from './univers/messages'
import {
    getUnivers,
    themeEA,
    themeEE,
    UNIVERS_ENTREPRISES,
    UNIVERS_ENTREPRISES_KPI,
    UNIVERS_PARTICULIERS
} from './univers/constant/univers'
import {IntlProvider} from 'react-intl'
import {configureStoreEA, configureStoreEE, configureStoreUnivers} from './config/configureStore'
import {ThemeProvider} from '@mui/material'
import ToastProvider from './components/toast/ToastProvider'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import frLocale from 'date-fns/locale/fr'
import './resources/css/index.css'
import {saveStore} from './api/gerep/GerepAPI'
import CookiesProvider from './components/cookie/CookiesProvider'
import UserService from './api/gerep/UserService'
import ApiError from './api/gerep/ApiError'
import {MAIN_PATH, MAIN_PATH_EE, MAIN_PATH_UNIVERS, SECONDARY_PATH} from './constant/path'
import {getConfiguration} from './api/gerep/Configuration'
import {types as loginTypes} from './univers/ui/authentication/services/login/loginActions'
import {setConfiguration} from './constant/configuration'
import AppEA from './AppEA'
import AppEE from './AppEE'
import {isGAActive, rejectGA, startGA} from './components/cookie/cookiesUtils'
import ReactGA from 'react-ga'
import BreakpointsProvider from './components/breakpoints/BreakpointsProvider'
import {ActivationPageEA, ActivationPageEE} from './univers/ui/authentication/pages/ActivationPage'
import InternalErrorPage from './univers/ui/errorPage/InternalErrorPage'
import UnauthorizedErrorPage from './univers/ui/errorPage/UnauthorizedErrorPage'
import NotFoundPage from './univers/ui/errorPage/NotFoundPage'
import UserServiceEE from './api/gerepEe/UserServiceEE'
import GarantieSouscriptionPage from './univers/ui/subscription/pages/GarantieSouscriptionPage'
import PreaffiliationLoginPage from './univers/ui/preaffiliation/pages/login/LoginPreafffiliationPage'
import OauthPage from './ea/ui/oauth/page/OauthPage'

// injectTapEventPlugin()
//
isGAActive() ? startGA() : rejectGA()

function initReact(user?: any, configuration?: any) {

    // sauvegarde de la configuration du projet
    setConfiguration(configuration)

    const initialState = {
        user
    }

    let store
    if (user && LocalStorage.getUnivers() === UNIVERS_PARTICULIERS.id) {
        store = configureStoreEA(initialState, user)
    } else if (user && LocalStorage.getUnivers() === UNIVERS_ENTREPRISES.id && LocalStorage.isKpi()) {
        store = configureStoreEE(initialState, user)
    } else if (user && LocalStorage.getUnivers() === UNIVERS_ENTREPRISES.id) {
        window.location.href = UNIVERS_ENTREPRISES.to(MAIN_PATH_EE.DASHBOARD)
        return
    } else {
        store = configureStoreUnivers(initialState)
    }

    // GerepAPI a besoin du store pour dispatch le logout sur une 401
    saveStore(store)

    if (user) {
        // pour les actions qui ont besoin de s'exécuter en réponse à l'authentification de l'utilisateur
        store.dispatch({
            type: loginTypes.INIT_USER,
            payload: user
        })
    }

    const logPageView = () => {
        window.scrollTo(0, 0)
        if (isGAActive()) {
            ReactGA.set({page: window.location.pathname + window.location.search})
            ReactGA.pageview(window.location.pathname + window.location.search)
        }
        return null
    }

    const root = ReactDOM.createRoot(document.getElementById('root')!)

    if (LocalStorage.getUnivers() === UNIVERS_PARTICULIERS.id) {
        root.render(
            <React.StrictMode>
                <BrowserRouter basename={UNIVERS_PARTICULIERS.root()}>
                    <Provider store={store}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                            <IntlProvider messages={messagesEA} locale="fr" defaultLocale="fr">
                                <ThemeProvider theme={themeEA}>
                                    <BreakpointsProvider>
                                        <ToastProvider>
                                            <CookiesProvider>
                                                <Route component={logPageView}/>
                                                <Switch>
                                                    <Route path={MAIN_PATH.LOGIN} component={LoginPageEA}/>
                                                    <Route path={MAIN_PATH.OAUTH_TOKEN} component={OauthPage}/>
                                                    <Route path="*" component={AppEA}/>
                                                </Switch>
                                            </CookiesProvider>
                                        </ToastProvider>
                                    </BreakpointsProvider>
                                </ThemeProvider>
                            </IntlProvider>
                        </LocalizationProvider>
                    </Provider>
                </BrowserRouter>
            </React.StrictMode>
        )
    } else if (LocalStorage.getUnivers() === UNIVERS_ENTREPRISES.id && LocalStorage.isKpi()) {
        root.render(
            <React.StrictMode>
                <BrowserRouter basename={UNIVERS_ENTREPRISES_KPI.root()}>
                    <Provider store={store}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                            <IntlProvider messages={messagesKpi} locale="fr" defaultLocale="fr">
                                <ThemeProvider theme={themeEE}>
                                    <BreakpointsProvider>
                                        <ToastProvider>
                                            <CookiesProvider>
                                                <Route component={logPageView}/>
                                                <Switch>
                                                    <Route path={MAIN_PATH_EE.LOGIN} component={LoginPageEE}/>
                                                    <Route path="*" component={AppEE}/>
                                                </Switch>
                                            </CookiesProvider>
                                        </ToastProvider>
                                    </BreakpointsProvider>
                                </ThemeProvider>
                            </IntlProvider>
                        </LocalizationProvider>
                    </Provider>
                </BrowserRouter>
            </React.StrictMode>
        )
    } else {
        root.render(
            <React.StrictMode>
                <BrowserRouter basename="/">
                    <Provider store={store}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                            <IntlProvider messages={messages} locale="fr" defaultLocale="fr">
                                <ThemeProvider theme={themeEA}>
                                    <BreakpointsProvider>
                                        <ToastProvider>
                                            <CookiesProvider>
                                                <Route component={logPageView}/>
                                                <Switch>
                                                    <Route path={MAIN_PATH_UNIVERS.LOGIN_EE} component={LoginPageEE}/>
                                                    <Route path={MAIN_PATH_UNIVERS.LOGIN_EA} component={LoginPageEA}/>
                                                    <Route path={MAIN_PATH_UNIVERS.ACTIVATION} component={ActivationPageEA}/>
                                                    <Route path={MAIN_PATH_UNIVERS.ACTIVATION_EE} component={ActivationPageEE}/>
                                                    <Route path={MAIN_PATH_UNIVERS.LOGIN_PREAFFILIATION} component={PreaffiliationLoginPage}/>
                                                    <Route path={MAIN_PATH_UNIVERS.HOME_PREAFFILIATION} component={HomePreAffiliationPage}/>
                                                    <Route path={MAIN_PATH_UNIVERS.PREAFFILIATION_SOUSCRIPTION} component={GarantieSouscriptionPage}/>
                                                    <Route path={MAIN_PATH_UNIVERS.NOT_FOUND} component={NotFoundPage}/>
                                                    <Route path={MAIN_PATH_UNIVERS.NOT_AUTHORIZED} component={UnauthorizedErrorPage}/>
                                                    <Route path={MAIN_PATH_UNIVERS.INTERNAL_ERROR} component={InternalErrorPage}/>
                                                    <Route path={MAIN_PATH_UNIVERS.LOGIN_PREAFFILIATION_BIS} component={PreaffiliationLoginPage}/>
                                                    <Route path={UNIVERS_PARTICULIERS.root()} component={LoginPageEA}/>
                                                    <Route path={UNIVERS_ENTREPRISES.root()} component={LoginPageEE}/>
                                                    <Route path="/" component={LoginPageEA}/>
                                                    <Route path="*" component={NotFoundPage}/>
                                                </Switch>
                                            </CookiesProvider>
                                        </ToastProvider>
                                    </BreakpointsProvider>
                                </ThemeProvider>
                            </IntlProvider>
                        </LocalizationProvider>
                    </Provider>
                </BrowserRouter>
            </React.StrictMode>
        )
    }
}

function main() {
    const tokenJwt = LocalStorage.getToken()
    const univers = getUnivers(LocalStorage.getUnivers())
    if (tokenJwt) {
        // recuperation des données du compte en session
        if(univers === UNIVERS_ENTREPRISES){
            Promise.all([UserServiceEE.getUser(tokenJwt), UserServiceEE.getConfigurationEE(tokenJwt)])
                .then(([receivedUser, configuration]) => {
                    if (receivedUser) {
                        LocalStorage.setItem(IS_KPI, receivedUser.profil.kpi)
                        initReact(receivedUser, configuration)
                    } else {
                        LocalStorage.clearSession()
                        initReact()
                    }
                })
                .catch((e) => {
                    if (e instanceof ApiError) {
                        console.log('Erreur lors de la recuperation des données de l\'internaute à partir de la session')
                        //token invalid
                        LocalStorage.clearSession()
                        initReact()
                    } else {
                        console.error(e)
                    }
                })
        } else {
            if(window.location.pathname === UNIVERS_PARTICULIERS.root() + MAIN_PATH.OAUTH_TOKEN){
                Promise.all([getConfiguration(tokenJwt)])
                    .then(([configuration]) => {
                        if (configuration) {
                            initReact(null, configuration)
                        } else {
                            LocalStorage.clearSession()
                            initReact()
                        }
                    })
                    .catch((e) => {
                        if (e instanceof ApiError) {
                            console.log('Erreur lors de la recuperation des données de l\'internaute à partir de la session')
                            //token invalid
                            LocalStorage.clearSession()
                            initReact()
                        } else {
                            console.error(e)
                        }
                    })
            } else {
                Promise.all([UserService.getUser(tokenJwt), getConfiguration(tokenJwt)])
                    .then(([receivedUser, configuration]) => {
                        if (receivedUser) {
                            initReact(receivedUser, configuration)
                        } else {
                            LocalStorage.clearSession()
                            initReact()
                        }
                    })
                    .catch((e) => {
                        if (e instanceof ApiError) {
                            console.log('Erreur lors de la recuperation des données de l\'internaute à partir de la session')
                            //token invalid
                            LocalStorage.clearSession()
                            initReact()
                        } else {
                            console.error(e)
                        }
                    })
            }
        }
    } else {
        initReact()
    }
}

main()

