import React from 'react'
import {Box, Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import {BENEFICIARY_PREAFF_FIELDS, CONTRACT_FIELDS} from '../../services/preaffiliationConstants'
import CheckboxInput from '../../../../../components/form/CheckboxInput'
import DatePickerInput from '../../../../../components/form/DatePickerInput'
import BeneficiaireAffiliationForm from './BeneficiaireAffiliationForm'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {cardiboxSelectors} from 'redux-cardibox'
import Loader from '../../../../../components/Loader'
import {FieldArray} from 'react-final-form-arrays'
import {validFieldArrayBeneficiaire} from '../../services/preaffiliationValidationFields'
import SelectInput from '../../../../../components/form/SelectInput'
import {useBreakpoints} from '../../../../../components/breakpoints/BreakpointsProvider'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {addDays} from 'date-fns'


const ARTICLE_PREAFF_CNTRT_BENEF = 'intro_etape_4'
const ARTICLE_PREAFF_SIMPLIFIE_CNTRT_BENEF = 'intro_etape_4_simplifie'

const BeneficiaireInitialValues = [{
    // rangNaissance: 1
}]


// TODO à déplacer
const getNbMaxBeneficiaires = (selectedCouverture: any) => {
    const nb = selectedCouverture && selectedCouverture.nbBeneMax
    return nb === 1 ? nb : -1
}

type ContratBeneficiaireAffiliationFormProps = {
    values: any
    push: any
    listCouvertures: any
    dateDebutMin: Function
    contract: any
}
const ContratBeneficiaireAffiliationForm: React.FC<ContratBeneficiaireAffiliationFormProps> = (
    {
        // @ts-ignore
        preAffCntrtBenefArticle,
         // @ts-ignore
        preAffSimplCntrtBenefArticle,
        values,
        push,
        listCouvertures,
        dateDebutMin,
        contract
    }
) => {

    const intl = useIntl()
    const { isMobile, isTabletPortrait } = useBreakpoints()
    const isMobOrTabVert = isMobile || isTabletPortrait

    const DECLARE_BENEFICIARY_DATA = [{
        label: intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.beneficiary.addbenef'}),
        value: 1,
    }]

    const nbMaxBeneficiaire = values[CONTRACT_FIELDS.COVERAGE] !== undefined ? getNbMaxBeneficiaires(values[CONTRACT_FIELDS.COVERAGE]) : 1

    const dateDebutMinCover = dateDebutMin(values)

    if (contract.context === "ADP" || contract.context === "AFFILSIMPL" ) {
        if (!preAffSimplCntrtBenefArticle) {
            return <Loader/>
        }
    } else {
        if (!preAffCntrtBenefArticle) {
            return <Loader/>
        }
    }

    return (
        <>
            <Stack direction="column" spacing="52px">
                <Stack direction="column" spacing="24px">
                    <Typography
                        variant="body2"
                        style={{fontWeight: '500', fontSize: '1rem'}}
                        dangerouslySetInnerHTML={{ __html: (contract.context === "ADP" || contract.context === "AFFILSIMPL")
                                ? preAffSimplCntrtBenefArticle.fields.body
                                : preAffCntrtBenefArticle.fields.body
                        }}
                    />

                    <Stack direction="column" spacing="24px">
                        <Typography variant="h2" fontSize="1.125rem" fontWeight="bold">
                            <FormattedMessage id="preaffiliation.cntrtAndBenef.contrat.title"/>
                        </Typography>

                        <Stack
                            direction={isMobOrTabVert ? 'column' : 'row'}
                            spacing={isMobOrTabVert ? '32px' : '42px'}
                            flex="1"
                        >
                            <SelectInput
                                name={CONTRACT_FIELDS.COVERAGE}
                                label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.contrat.coverage'})}
                                data={listCouvertures.map((couverture: any) => ({value: couverture.numFor, label: couverture.libelle}))}
                                mandatory
                            />
                            <DatePickerInput
                                name={CONTRACT_FIELDS.AFFILIATION_START}
                                label={intl.formatMessage({id: 'preaffiliation.cntrtAndBenef.contrat.start'})}
                                maxWidth="unset"
                                minDate={dateDebutMinCover}
                                maxDate={addDays(new Date(), 60)}
                                mandatory={true}
                                disabled={!!contract.debutAffiliation}
                            />
                        </Stack>

                        <Stack direction="row" spacing="8px">
                            <InfoOutlinedIcon width="400px"/>
                            <Typography variant="body2" fontSize="0.875rem">
                                <FormattedMessage id="preaffiliation.cntrtAndBenef.contrat.info"/>
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>

                {nbMaxBeneficiaire === -1 &&
                    <Stack direction="column" spacing="16px">
                        <Typography variant="h2" fontSize="1.125rem" fontWeight="bold">
                            <FormattedMessage id="preaffiliation.cntrtAndBenef.beneficiary.title"/>
                        </Typography>

                        <Box>
                            <CheckboxInput
                                name={CONTRACT_FIELDS.MORE_BENEFICIARY}
                                data={DECLARE_BENEFICIARY_DATA as SelectOption[]}
                            />
                        </Box>

                        {values[CONTRACT_FIELDS.MORE_BENEFICIARY]
                            && values[CONTRACT_FIELDS.MORE_BENEFICIARY].length === 1
                            && (
                                <FieldArray
                                    name={BENEFICIARY_PREAFF_FIELDS.NAME}
                                    initialValue={BeneficiaireInitialValues}
                                    maxNbFields={nbMaxBeneficiaire}
                                    validate={valuesBeneficiaire => {
                                        return validFieldArrayBeneficiaire(values, valuesBeneficiaire, intl)
                                    }}
                                >
                                    {({fields}) => {
                                        return (
                                            fields.map((name, index) => {
                                                return (
                                                    <BeneficiaireAffiliationForm
                                                        values={values}
                                                        name={name}
                                                        index={index}
                                                        fields={fields}
                                                        push={push}
                                                    />
                                                )
                                            })
                                        )
                                    }}
                                </FieldArray>
                            )
                        }
                    </Stack>
                }
            </Stack>
        </>
    )
}

const actions = {
}
const mapStateToProps = () => {

    return (state: any) => ({
        preAffCntrtBenefArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_PREAFF_CNTRT_BENEF],
        isPreAffCntrtBenefArticleLoading: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_PREAFF_CNTRT_BENEF],
        preAffSimplCntrtBenefArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_PREAFF_SIMPLIFIE_CNTRT_BENEF],
        isPreAffSimplCntrtBenefArticleLoading: cardiboxSelectors.articles.getLoadingStateByShortcut(state.cardibox)[ARTICLE_PREAFF_SIMPLIFIE_CNTRT_BENEF],
    })
}

export default compose(
    connect(mapStateToProps, actions)
)(ContratBeneficiaireAffiliationForm)
