import {FORM_REGEX} from './formConstants'


export const isNssValid = (variable = '') => {
    const niss = variable.replace(/ |_/g, '')
    if (niss.length === 15) {
        if (FORM_REGEX.NSS.test(niss) || FORM_REGEX.FOREIGN_NSS.test(niss)) {
            let NSS = niss.substring(0, 13)
            const cleInit = niss.substring(13, 15)
            let NSSbis = 0
            let cleResult
            if (NSS.substring(6, 7) === 'A') {
                NSS = `${NSS.substring(0, 6)}0${NSS.substring(7, 13)}`
                NSSbis = parseInt(NSS, 10) - 1000000
            }
            if (NSS.substring(6, 7) === 'B') {
                NSS = `${NSS.substring(0, 6)}0${NSS.substring(7, 13)}`
                NSSbis = parseInt(NSS, 10) - 2000000
            }
            if (NSSbis !== 0) {
                cleResult = 97 - NSSbis % 97
            } else {
                cleResult = 97 - parseInt(NSS, 10) % 97
            }
            return cleResult === parseInt(cleInit, 10)
        }
    }
    return false
}

export const nissAndBirthCoherence = (birthDate:Date, niss:string) => {
    const NISS = niss.replace(/ /g, '')

    const yearPart = NISS.substring(1, 3)
    const monthPart = NISS.substring(3, 5)

    const yearOfBirth = birthDate.getFullYear().toString().substring(2)
    const monthOfBirth = (birthDate.getMonth() + 1).toString().padStart(2, '0')

    if (yearPart === yearOfBirth && monthPart === monthOfBirth) {
        return true
    }
    else {
        return false
    }
}

