import { types } from './WsActions'

const affiliation = (state = {}, action) => {
	switch (action.type) {
		/* Suite au chargement de l'affiliation, on met new à true */
		case types.GET_AFFILIATION:
			return {
				...action.payload,
				new: true
			}
		case types.RESET_AFFILIATION:
			return {
				...action.payload,
				new: false
			}
		default:
			return state
	}
}

const beneficiaires = (state = [], action) => {
	switch (action.type) {
		case types.GET_BENEFICIAIRES:{
			return action.payload
		}
		default:
			return state
	}
}

const adhesion = (state = {}, action) => {
	switch (action.type) {
		case types.SUBSCRIBE_ADHESION:
			return action.payload
		default:
			return state
	}
}

const preremplissage = (state = {}, action) => {
	switch (action.type) {
		case types.GET_PRESET_FIELDS:
			return action.payload
		default:
			return state
	}
}

export default {
	affiliation,
	beneficiaires,
	adhesion,
	preremplissage
}
