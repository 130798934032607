import { createSelector } from 'reselect'
import { Map } from 'immutable'

export const getEcheancier = state => state.echeancier.data
export const isEcheancierLoading = state => state.echeancier.loading
export const getEcheancierExpirationDate = state => state.echeancier.expirationDate

export const getRumList = createSelector(
	getEcheancier,
	(echeancier) => {
		if (!echeancier || !echeancier.length) {
			return []
		}
		let contratsByRef = new Map()

		echeancier.forEach(e => {
			if (!contratsByRef.has(e.refContrat) && e.rum) {
				contratsByRef = contratsByRef.set(e.refContrat, { contrat: e.refContrat, rum: e.rum })
			}
		})
		return contratsByRef.toList().toArray()
	}
)

export const getModeReglement = createSelector([
	getEcheancier
], (echeancier) => {
	const now = new Date()
	return echeancier.reduce((acc, el) => {
		const {
			dateEcheance: dateEcheanceStr,
			modeReglement
		} = el
		const dateEcheance = new Date(dateEcheanceStr)
		if (!acc.dateEcheance || acc.dateEcheance < now < new Date(dateEcheance)) {
			return {
				dateEcheance: new Date(dateEcheance),
				modeReglement
			}
		}
		return acc
	}, {}).modeReglement
})
