import { Cookies } from 'react-cookie'
import ReactGA from 'react-ga'
import appConst from '../../constant/appConstants'

const cookies = new Cookies()

/**
 * Calculer l'expiration de cookies en seconde
 * @param cookieExpiration soit un type number, soit un objet
 * @returns {number}
 */
export const getSecondsSinceExpiration = (cookieExpiration) => {
	if (typeof cookieExpiration === 'number') {
		return cookieExpiration
	}
	const SECONDS_IN_YEAR = 31536000
	const SECONDS_IN_DAY = 86400
	const SECONDS_IN_HOUR = 3600
	const _cookieExpiration = {
		years: 0,
		days: 0,
		hours: 0,
		...cookieExpiration
	}
	const { years, days, hours } = _cookieExpiration
	return (years * SECONDS_IN_YEAR) + (days * SECONDS_IN_DAY) + (hours * SECONDS_IN_HOUR)
}

/**
 * Mise à jour de la cookie. Une nouvelle cookie sera créée s'il n'existe pas
 * @param cookieName nom de la cookie à mettre à jour
 * @param value valeur à mettre à jour
 * @param options objet de toutes les options de RFC 6265
 */
export const updateCookies = (cookieName, value, options = {}) => {
	cookies.set(cookieName, value, {
		path: options.path,
		expires: options.expires,
		...options
	})
}

/**
 * Vérifier si la cookie n'existe pas
 * @param cookieName nom de la cookie à vérifier
 * @returns {boolean}
 */
export const isCookieNotExist = (cookieName) => (
	typeof cookies.get(cookieName) === 'undefined'
)

/**
 * Récupérer la cookie par nom
 * @param cookieName nom de la cookie
 * @returns {string | undefined}
 */
export const getCookie = (cookieName) => (
	cookies.get(cookieName)
)

/**
 * Supprimer la cookie par nom
 * @param cookieName nom de la cookie
 * @param options bjet de toutes les options de RFC 6265
 */
export const removeCookie = (cookieName, options = {}) => {
	const value = cookies.get(cookieName)
	cookies.remove(cookieName, options)
	return value
}

export const rejectGA = () => {
	// création du cookie spécifique empêchant Google Analytics de démarrer
	updateCookies(appConst.googleAnalytics.disabledCookieName, true)
	window[appConst.googleAnalytics.disabledCookieName] = true
	appConst.googleAnalytics.cookiesNames.forEach((cookieName) => {
		removeCookie(cookieName)
	})
}

export const startGA = () => {
	removeCookie(appConst.googleAnalytics.disabledCookieName)
	window[appConst.googleAnalytics.disabledCookieName] = false
	ReactGA.initialize(appConst.googleAnalytics.id, {
		debug: process.env.NODE_ENV === 'development',
		titleCase: false
	})
}

export const isAcceptCookiesNotExist = () => (
	isCookieNotExist(appConst.acceptCookie.name)
)

export const getAcceptCookie = () => (
	getCookie(appConst.acceptCookie.name)
)

export const isGAActive = () => (
	getAcceptCookie() === 'true'
)

export const updateAcceptCookie = (value) => {
	updateCookies(appConst.acceptCookie.name, value, {
		path: appConst.acceptCookie.path,
		expires: new Date(Date.now() + (getSecondsSinceExpiration(appConst.acceptCookie.expiration) * 1000))
	})
}
