import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const AppBarSubButton = styled(ButtonMUI)<ButtonProps>(({ theme }) => ({
	'&.MuiButton-root': {
		borderRadius: 8,
		height: 48,
		justifyContent: 'start',
		paddingLeft: 34,
		textAlign: 'left',
		marginTop: 0,
		width: '100%'
	}
}))

export default AppBarSubButton
