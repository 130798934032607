import {GerepAPI_EE} from '../gerep/GerepAPI'

export default class KpiService {

    static getDataKPI(token, idSociete, annee, typeData) {
        return GerepAPI_EE.get(`/kpi/data/${typeData}/${idSociete}/${annee}`, null, token)
    }

    static getListContacts(token, idSociete) {
        return GerepAPI_EE.get(`/kpi/contacter/listCDC/${idSociete}`, null, token)
    }

    static contacterGerep(token, values) {
        return GerepAPI_EE.post('/kpi/contacter/envoi', values, token)
    }
}
