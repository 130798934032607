import {applyMiddleware, configureStore as configStoreRedux} from '@reduxjs/toolkit'
import promiseMiddleware from 'redux-promise'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import rootSaga from '../sagas'
import {rootEAReducer, rootEEReducer, rootUniversReducer} from '../reducers'
import logoutMiddleware from '../ea/middleware/logoutMiddleware'

export function configureStoreUnivers(initialState: any) {

    const sagaMiddleware = createSagaMiddleware()
    const middlewareEnhancer = applyMiddleware(thunk, promiseMiddleware, sagaMiddleware)
    const enhancers = [middlewareEnhancer]

    const store = configStoreRedux({
        reducer: rootUniversReducer,
        preloadedState: initialState,
        enhancers: enhancers
    })


    // Saga
    sagaMiddleware.run(rootSaga)
    return store
}

export function configureStoreEA(initialState: any, user: any) {

    // FIXME
    const sagaMiddleware = createSagaMiddleware()
    const middleware = [logoutMiddleware, thunk, promiseMiddleware, sagaMiddleware]

    const store = configStoreRedux({
        reducer: rootEAReducer,
        preloadedState: initialState,
        middleware: middleware
    })

    if (user && !user.viewCarteTP) {
        // Saga
        sagaMiddleware.run(rootSaga)
    }

    return store
}

export function configureStoreEE(initialState: any, user: any) {

    const sagaMiddleware = createSagaMiddleware()
    const middleware = [logoutMiddleware, thunk, promiseMiddleware, sagaMiddleware]

    const store = configStoreRedux({
        reducer: rootEEReducer,
        preloadedState: initialState,
        middleware: middleware
    })


    return store
}

export default configureStoreUnivers

export type Store = ReturnType<typeof configureStoreUnivers>
export type RootState = ReturnType<Store['getState']>
export type AppDispatch = Store['dispatch']
