import appConst from '../../constant/appConstants'

/**
 * Le but de cette méthode est de permettre de reconnaître des valeurs similaires (c'est-à-dire
 * ayant la même "représentation") mais pas strictement identiques. Par exemple :
 *
 * '' (chaîne trimée vide) == null == undefined
 *
 * MAIS :
 *
 * 0 != '0' != false
 * 1 != '1'
 *
 * @param value
 * @returns {*}
 */
const getRepresentation = (value) => {
	if (typeof value === 'string') {
		return value.trim() || null
	}
	if (typeof value === 'undefined') {
		return null
	}
	return value
}

/**
 * Retourne un objet ne contenant que les valeurs d'un formulaire ayant changé.
 *
 * @param values les valeurs du formulaire
 * @param initialValues les valeurs par défaut
 * @param fields un tableau spécifiant éventuellement les attributs à considérer
 * @returns {*}
 */
export const diffFields = (values, initialValues, fields) => {
	return (fields || Object.keys(values)).reduce((obj, key) => {
		if (getRepresentation(values[key]) !== getRepresentation(initialValues[key])) {
			return {
				...obj,
				[key]: values[key]
			}
		}
		return obj
	}, {})
}

const regexEmail = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const isEmailValid = (email = '') => regexEmail.test(email)

export const isValueAnArrayOfFiles = value => {
	return Array.isArray(value) && value.every(element => element instanceof File)
}

export const isFileTypeValid = (fileList) => {
	if (fileList instanceof FileList) {
		for (let i = 0; i < fileList.length; i++) {
			if (!appConst.fileUpload.mimeTypes.find(type => type === fileList[i].type)) {
				return false
			}
		}
	}
	if (isValueAnArrayOfFiles(fileList)) {
		return fileList.every(file => appConst.fileUpload.mimeTypes.find(type => type === file.type))
	}
	if (fileList instanceof File) {
		return appConst.fileUpload.mimeTypes.find(type => type === fileList.type)
	}
	return true
}

const regexNSS = new RegExp('([12]([0-9]{2})((0[1-9]|1[012])|[235]{1}[0-9]{1}|4[012]{1})([0-9][0-9]|2[AB])([0-9]{3})([0-9]{3})([0-9]{2}))')
const regexNSSEtranger = new RegExp('([78](\\w{14}))')

export const checkValidNSS = (variable = '') => {
	const niss = variable.replace(/ /g, '')
	if (niss.length === 15) {
		if (regexNSS.test(niss) || regexNSSEtranger.test(niss)) {
			let NSS = niss.substring(0, 13)
			const cleInit = niss.substring(13, 15)
			let NSSbis = 0
			let cleResult
			if (NSS.substring(6, 7) === 'A') {
				NSS = `${NSS.substring(0, 6)}0${NSS.substring(7, 13)}`
				NSSbis = parseInt(NSS, 10) - 1000000
			}
			if (NSS.substring(6, 7) === 'B') {
				NSS = `${NSS.substring(0, 6)}0${NSS.substring(7, 13)}`
				NSSbis = parseInt(NSS, 10) - 2000000
			}
			if (NSSbis !== 0) {
				cleResult = 97 - NSSbis % 97
			} else {
				cleResult = 97 - parseInt(NSS, 10) % 97
			}
			return cleResult === parseInt(cleInit, 10)
		}
	}
	return false
}

export const isNumber = variable => typeof variable === 'number' || typeof parseInt(variable, 10) === 'number'

export const isObjectEmpty = (obj = {}) => {
	return Object.keys(obj).length === 0 && obj.constructor === Object
}
