import { combineReducers } from 'redux'
import { types } from './articleActions'
import appConst from '../../../constant/appConstants'

const data = (state = [], action) => {
	switch (action.type) {
		case types.GET_ARTICLES: {
			return action.payload
		}
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case types.GET_ARTICLES_LOADING: {
			return action.payload
		}
		default:
			return state
	}
}

const expirationDate = (state = 0, action) => {
	switch (action.type) {
		case types.GET_ARTICLES: {
			return (+new Date() + appConst.cacheDuration.default * 1000)
		}
		default:
			return state
	}
}

const articles = combineReducers({
	data,
	loading,
	expirationDate
})

export default articles
