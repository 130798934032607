import React from 'react'
import { Link, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ButtonDefault from '../ButtonDefault'
import { MAIN_PATH } from '../../constant/path'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getDateDoubleAuthent } from '../../constant/configuration'
import { useBreakpoints } from '../breakpoints/BreakpointsProvider'
import { isAfterNow } from '../../utils/authentUtils'

type ActivationAuthentProps = {
	prevStep?: () => void
	nextStep: () => void
	obligatoire?: boolean
	textCardibox?: string
}

const ActivationAuthent: React.FC<ActivationAuthentProps> = ({
	prevStep,
	nextStep,
	obligatoire = false,
	textCardibox,
	// @ts-ignore
	dateDoubleAuthent
}) => {
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const history = useHistory()

	return (
		<Stack direction="column" spacing="24px">
			{
				textCardibox &&
				<Typography variant="body2">
					<div dangerouslySetInnerHTML={{ __html: textCardibox }} />
				</Typography>
			}
			{
				!obligatoire &&
				<Typography variant="body2">
					<FormattedMessage id={'doubleAuthent.activation.faq.label'} />
					<Link onClick={() => history.push(MAIN_PATH.HELP)} sx={{ cursor: 'pointer' }}>
						<FormattedMessage id={'doubleAuthent.activation.faq.link'} />
					</Link>
				</Typography>
			}
			<Stack direction={(isMobile || isTabletPortrait) ? 'column' : 'row'} spacing="16px" display="flex" justifyContent="center">
				{(isAfterNow(dateDoubleAuthent) && !obligatoire) &&
					<ButtonDefault
						variant="outlined"
						onClick={prevStep}>
						<FormattedMessage id="doubleAuthent.activation.button.inactivate" />
					</ButtonDefault>
				}
				<ButtonDefault
					variant="contained"
					onClick={nextStep}>
					<FormattedMessage id="doubleAuthent.activation.button.activate" />
				</ButtonDefault>
			</Stack>
		</Stack>
	)
}

const mapStateToProps = () => ({
	dateDoubleAuthent: getDateDoubleAuthent()
})

export default compose(
	connect(mapStateToProps)
)(ActivationAuthent)
