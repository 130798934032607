import { types } from './loginActions'
import { combineReducers } from 'redux'

const loading = (state = false, action) => {
	switch (action.type) {
		case types.LOADING: {
			return action.payload
		}
		default:
			return state
	}
}

// Plus utile maintenant, provient de l'ancienne version
const redirectUrl = (state = null, action) => {
	switch (action.type) {
		case types.REGISTER_USER_REDIRECTION: {
			return action.path
		}
		default:
			return state
	}
}

const redirectionBoolean = (state = false, action) => {
	switch (action.type) {
		case types.GET_REDIRECTION_BOOLEAN:
			return action.payload
		default:
			return state
	}
}

export default combineReducers({
	loading,
	redirectUrl,
	redirectionBoolean
})

