import GerepAPI from './GerepAPI'
import appConst from '../../constant/appConstants';

export default class NotificationsService {

	static getNotifications(token) {
		return GerepAPI.get('/notifications', null, token)
	}

	static downloadNotification(url, token) {
		return GerepAPI.get('/ticket', null, token)
			.then(ticket => {
				window.location = `${appConst.gerepApi.baseUrl}${url}?token=${ticket}`
			})
	}
}
