import React, {useState} from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import {Box, CircularProgress, createSvgIcon, Stack, Typography, useTheme} from '@mui/material'
import CardButton from './CardButton'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import Icon from '@mdi/react'
import {
    mdiArrowLeftRight,
    mdiBookCheckOutline,
    mdiCards,
    mdiCalendarMonth,
    mdiChartArc,
    mdiDownloadCircleOutline,
    mdiFileDownloadOutline,
    mdiHospitalBoxOutline,
    mdiHospitalBuilding
} from '@mdi/js'
import {SECONDARY_PATH} from '../../../../constant/path'
import {useHistory} from 'react-router-dom'
import ContratNotAvailable from '../../contrats/components/ContratNotAvailable'
import {userSelector} from '../../user/userSelectors'
import {getContratBaseSante} from '../../../webservices/contrats/contratsSelector'
import * as contratsActions from '../../../webservices/contrats/contratsActions'
import * as affiliationActions from '../../../webservices/affiliation/affiliationActions'
import {connect} from 'react-redux'

type ServiceCardProps = {
    simulateur?: boolean
    servicesSante?: boolean
    virementCotisation?: boolean
    affiliationExpired?: boolean
}

const ServiceCard: React.FC<ServiceCardProps> = (
    {
        simulateur,
        servicesSante,
        virementCotisation,
        affiliationExpired,
        // @ts-ignore
        download,
        // @ts-ignore
        downloadRadiation,
        // @ts-ignore
        company,
        // @ts-ignore
        user
    }
) => {
    const intl = useIntl()
    const {isMobile} = useBreakpoints()
    const theme = useTheme()
    const history = useHistory()
    const [openContratNotAvailable, setOpenContratNotAvailable] = useState<boolean>(false)
    const [loadingDlGarantie, setLoadingDlGarantie] = useState<boolean>(false)

    const height = isMobile ? 'auto' : '236px'
    const iconMarginBottom = !(isMobile) ? '10px' : '0px'
    const iconSize = !(isMobile) ? '56px' : '24px'

    const downloadContrat = (company: any) => {
        if (!company || !company?.numgar || !company.siret) {
            setOpenContratNotAvailable(true)
            return
        }
        const {numgar, siret} = company
        setLoadingDlGarantie(true)
        download(numgar, siret)
            .catch(() => {
                setOpenContratNotAvailable(true)
            })
            .finally(() => setLoadingDlGarantie(false))
    }

    const MoneyEuroCircleIcon = createSvgIcon(
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 512 512">
            <g>
                <g>
                    <path d="M256,0C114.848,0,0,114.848,0,256s114.848,256,256,256s256-114.848,256-256S397.152,0,256,0z M256,480
			C132.48,480,32,379.52,32,256S132.48,32,256,32s224,100.48,224,224S379.52,480,256,480z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M310.976,339.744C299.072,347.872,285.952,352,272,352c-29.472,0-55.008-19.456-68.864-48H256c8.832,0,16-7.168,16-16
			c0-8.832-7.168-16-16-16h-62.656c-0.736-5.216-1.344-10.528-1.344-16s0.608-10.784,1.344-16H272c8.832,0,16-7.168,16-16
			c0-8.832-7.168-16-16-16h-68.864c13.856-28.544,39.392-48,68.864-48c13.952,0,27.072,4.128,38.976,12.256
			c7.296,4.96,17.28,3.136,22.24-4.192c4.992-7.296,3.104-17.248-4.192-22.24C311.936,134.176,292.224,128,272,128
			c-46.88,0-87.008,33.184-103.68,80H144c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h17.408
			c-0.576,5.312-1.408,10.528-1.408,16s0.832,10.688,1.408,16H144c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h24.32
			c16.672,46.816,56.8,80,103.68,80c20.224,0,39.936-6.176,57.024-17.824c7.296-4.992,9.184-14.944,4.192-22.24
			C328.256,336.64,318.272,334.784,310.976,339.744z"/>
                </g>
            </g>
        </svg>
        , 'MoneyEuroCircleIcon')

    const DownloadButton = () => (
        loadingDlGarantie ?
            <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                <CircularProgress size={24} color="secondary"/>
            </Box>
            :
            <CardButton
                isMobile={isMobile}
                variant="text"
                color="secondary"
                onClick={() => downloadContrat(company)}
                size="small"
                startIcon={
                    <Icon path={mdiFileDownloadOutline} size={iconSize} color={theme.palette.secondary.main}
                          style={{marginBottom: iconMarginBottom}}/>
                }
                sx={{maxWidth: '144px'}}
            >
                <Typography variant="body2">
                    <FormattedMessage id="dashboard.services.buttons.download"/>
                </Typography>
            </CardButton>
    )

    const SimulateRefundButton = () => (
        <CardButton
            isMobile={isMobile}
            onClick={() => history.push(SECONDARY_PATH.SERVICES_SIMULATION)}
            variant="text"
            color="secondary"
            size="small"
            startIcon={
                <Icon path={mdiChartArc} size={iconSize} color={theme.palette.secondary.main}
                      style={{marginBottom: iconMarginBottom}}/>
            }
        >
            <Typography variant="body2">
                <FormattedMessage id="dashboard.services.buttons.simulate"/>
            </Typography>
        </CardButton>
    )

    const TeleconsultingButton = () => (
        <CardButton
            isMobile={isMobile}
            onClick={() => history.push(SECONDARY_PATH.SERVICES_TELECONSULTATION)}
            variant="text"
            color="secondary"
            size="small"
            startIcon={
                <Icon path={mdiHospitalBoxOutline} size={iconSize} color={theme.palette.secondary.main}
                      style={{marginBottom: iconMarginBottom}}/>
            }
        >
            <Typography variant="body2">
                <FormattedMessage id="dashboard.services.buttons.teleconsulting"/>
            </Typography>
        </CardButton>
    )

    const PortabilityButton = () => (
        <CardButton
            isMobile={isMobile}
            onClick={() => history.push(SECONDARY_PATH.SERVICES_EXTEND_COVER)}
            variant="text"
            color="secondary"
            size="small"
            startIcon={
                <Icon path={mdiArrowLeftRight} size={iconSize} color={theme.palette.secondary.main}
                      style={{marginBottom: iconMarginBottom}}/>
            }
        >
            <Typography variant="body2">
                <FormattedMessage id="dashboard.services.buttons.portability"/>
            </Typography>
        </CardButton>
    )

    const ScheduleButton = () => (
        <CardButton
            isMobile={isMobile}
            variant="text"
            color="secondary"
            size="small"
            startIcon={
                <Icon path={mdiCalendarMonth} size={iconSize} color={theme.palette.secondary.main}
                      style={{marginBottom: iconMarginBottom}}/>
            }
            onClick={() => history.push(SECONDARY_PATH.ACCOUNT_DEADLINE)}
        >
            <Typography variant="body2">
                <FormattedMessage id="dashboard.services.buttons.schedule"/>
            </Typography>
        </CardButton>
    )

    const ContractButton = () => (
        <CardButton
            isMobile={isMobile}
            variant="text"
            color="secondary"
            size="small"
            startIcon={
                <Icon path={mdiBookCheckOutline} size={iconSize} color={theme.palette.secondary.main}
                      style={{marginBottom: iconMarginBottom}}/>
            }
            onClick={() => history.push(SECONDARY_PATH.ACCOUNT_CONTRACT)}
        >
            <Typography variant="body2">
                <FormattedMessage id="dashboard.services.buttons.contract"/>
            </Typography>
        </CardButton>
    )

    const HostpitalStayButton = () => (
        <CardButton
            isMobile={isMobile}
            onClick={() => history.push(SECONDARY_PATH.SERVICES_HOSTPITALIZATION)}
            variant="text"
            color="secondary"
            size="small"
            startIcon={
                <Icon path={mdiHospitalBuilding} size={iconSize} color={theme.palette.secondary.main}
                      style={{marginBottom: iconMarginBottom}}/>
            }
        >
            <Typography variant="body2">
                <FormattedMessage id="dashboard.services.buttons.hospitalStay"/>
            </Typography>
        </CardButton>
    )

    const DownloadAttestationButton = () => (
        <CardButton
            isMobile={isMobile}
            variant="text"
            onClick={() => downloadRadiation()}
            color="secondary"
            size="small"
            startIcon={
                <Icon path={mdiDownloadCircleOutline} size={iconSize} color={theme.palette.secondary.main}
                      style={{marginBottom: iconMarginBottom}}/>
            }
        >
            <Typography variant="body2">
                <FormattedMessage id="dashboard.services.buttons.downloadAttestation"/>
            </Typography>
        </CardButton>
    )

    const SouscriptionButton = () => (
        <CardButton
            isMobile={isMobile}
            onClick={() => history.push(SECONDARY_PATH.ACCOUNT_SUBSCRIPTION)}
            variant="text"
            color="secondary"
            size="small"
            startIcon={
                <Icon path={mdiCards} size={iconSize} color={theme.palette.secondary.main}
                      style={{marginBottom: iconMarginBottom}}/>
            }
        >
            <Typography variant="body2">
                <FormattedMessage id="dashboard.services.buttons.souscription"/>
            </Typography>
        </CardButton>
    )

    const DemandRefundButton = () => (
        <CardButton
            isMobile={isMobile}
            onClick={() => history.push(SECONDARY_PATH.REFUND_DEMAND)}
            variant="text"
            color="secondary"
            size="small"
            startIcon={
                <MoneyEuroCircleIcon color="secondary"
                                     style={{marginBottom: iconMarginBottom, height: iconSize, width: iconSize}}/>
            }
        >
            <Typography variant="body2">
                <FormattedMessage id="dashboard.services.buttons.demandRefund"/>
            </Typography>
        </CardButton>
    )


    const AFFILIATION_EXPIRED_BUTTONS = [
        {
            button: DemandRefundButton,
            name: 'demandRefund',
            display: true
        },
        {
            button: PortabilityButton,
            name: 'portability',
            display: true
        },
        {
            button: DownloadAttestationButton,
            name: 'downloadAttestation',
            display: true
        }
    ]

    const BUTTONS_LIST = [
        {
            button: DownloadButton,
            name: 'download',
            display: true
        },
        {
            button: SouscriptionButton,
            name: 'souscription',
            display: user.profil?.nouvellesOptions
        },
        {
            button: SimulateRefundButton,
            name: 'simulate',
            display: simulateur
        },
        {
            button: TeleconsultingButton,
            name: 'teleconsulting',
            display: servicesSante
        },
        {
            button: PortabilityButton,
            name: 'portability',
            display: true
        },
        {
            button: ScheduleButton,
            name: 'schedule',
            display: virementCotisation
        },
        {
            button: ContractButton,
            name: 'contract',
            display: true
        }
        // {
        // 	button: HostpitalStayButton,
        // 	name: 'hospitalStay',
        // 	display: true
        // }
    ]

    const DISPLAYABLE_BUTTONS = affiliationExpired ? AFFILIATION_EXPIRED_BUTTONS : BUTTONS_LIST.filter((button) => button.display).splice(0, 4)

    return (
        <DashboardCard height={height} title={intl.formatMessage({id: 'dashboard.services.title'})}>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                width="100%"
                spacing="12px"
            >
                {DISPLAYABLE_BUTTONS.map((button, index) => (
                    <Box flex={1} key={index} justifyContent="center" display="flex">
                        {button.button()}
                    </Box>
                ))}
            </Stack>
            <ContratNotAvailable open={openContratNotAvailable} setOpen={setOpenContratNotAvailable}/>
        </DashboardCard>
    )
}

const mapStateToProps = (state: any) => ({
    user: userSelector(state),
    company: getContratBaseSante(state)
})

const actions = {
    download: contratsActions.download,
    downloadRadiation: affiliationActions.downloadAttestationRadiation()
}

export default connect(mapStateToProps, actions)(ServiceCard)
