import React, {useContext, useEffect, useMemo, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {ToastContext} from '../../../../components/toast/ToastContext'
import {TypeAdherent} from '../../../../constant/TypeAdherent'
import {estMajeur} from '../../../../utils/dateUtils'
import {MODIFY_SOCIAL_SECURITY_FIELDS, SOCIAL_SECURITY_CHOICES} from '../services/personalInfoConstants'
import ContactService from '../../../../api/gerep/ContactService'
import LocalStorage from '../../../../business/storage/LocalStorage'
import {ToastType} from '../../../../components/toast/toastConstants'
import {Form} from 'react-final-form'
import {Box, CircularProgress, Divider, Stack, Typography} from '@mui/material'
import {Delete} from '@mui/icons-material'
import ButtonDefault from '../../../../components/ButtonDefault'
import InfoField from '../../../../components/InfoField'
import RadioInput from '../../../../components/form/RadioInput'
import EndTeletransmission from '../components/personalInfo/EndTeletransmission'
import ModifySocialSecurityNumber from '../components/personalInfo/ModifySocialSecurityNumber'
import {connect} from 'react-redux'
import {getIndividuList, isAffiliationLoading} from '../../../webservices/affiliation/affiliationSelector'
import PageLayout from '../../../../components/PageLayout'
import {useHistory} from 'react-router-dom'
import {SECONDARY_PATH} from '../../../../constant/path'
import * as affiliationAction from '../../../webservices/affiliation/affiliationActions'
import Loader from '../../../../components/Loader'
import ConfirmationPopin from '../../../../components/ConfirmationPopin'
import Popover from '../../../../components/Popover'
import {mdiHelpCircleOutline} from '@mdi/js'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'

const buildOrganisme = (
    regime?: number,
    caisse?: number,
    centre?: number
) => {
    return [regime, caisse, centre].filter(value => value).join(' ')
}

const ModifySocialSecurityPage = (
    {
        // @ts-ignore
        allBeneficiaries,
        // @ts-ignore
        getAffiliation,
        // @ts-ignore
        isAffiliationLoading
    }
) => {
    const intl = useIntl()
    const history = useHistory()
    const {addToast} = useContext(ToastContext)
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)

    const beneficiaireList = useMemo(() => allBeneficiaries.filter((ind: any) => ind[1].typAdr !== TypeAdherent.ASSURE_PRINCIPAL), [allBeneficiaries])

    const enfantsMineurList: any[] = []
    const individuMajeurList: any[] = []
    allBeneficiaries.forEach((beneficiary: any) => {
        const individu = beneficiary[1]
        if ((individu.typAdr === TypeAdherent.ENFANTS || individu.typAdr === TypeAdherent.ENFANT_INVALIDE)
            && !estMajeur(individu.dateNaissance)) {
            if (!individu.matorg2) {
                // on ne permet aux mineures ayant déjà un numSS2 d'ajouter un numSS2
                enfantsMineurList.push(beneficiary)
            }
        } else {
            individuMajeurList.push(beneficiary)
        }
    })
    const hasMinorChild = enfantsMineurList.length > 0

    const onSubmit = (values: Record<string, any>) => {
        setIsButtonLoading(true)
        const {
            motif,
            dateCompter,
            numSS,
            numSS2,
            organisme,
            organisme2,
            files,
            beneficiaire,
            beneficiaires
        } = values

        const filesUploaded = (
            motif === SOCIAL_SECURITY_CHOICES.DEACTIVATE ? [] : (files as []).map((file: any, index) => ({
                key: `file${index}`,
                value: file
            }))
        )

        const personneConcernee = allBeneficiaries.find((benef: any) => benef[0] === values[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARY])
        const personneConcerneeNom = personneConcernee && `${personneConcernee[1].prenom} ${personneConcernee[1].nom}`

        // Le back ne prend en compte que 2 types de motif. La modification de niss ou caisse revient au même type de motif
        const data = [{
            key: 'edit',
            value: {
                motif,
                dateCompter: dateCompter ? new Date(dateCompter).toLocaleDateString('fr-FR') : '',
                numSS: (numSS && numSS.replace(/ /g, '')),
                numSS2: numSS2 && numSS2.replace(/ /g, ''),
                organisme: organisme || (personneConcernee && buildOrganisme(personneConcernee[1]?.regime, personneConcernee[1]?.caisse, personneConcernee[1]?.centre)),
                organisme2: organisme2 || (personneConcernee && buildOrganisme(personneConcernee[1]?.regime, personneConcernee[1]?.caisse, personneConcernee[1]?.centre)),
                typAdr: personneConcernee && personneConcernee[1].typAdr,
                nomBeneficiaire: personneConcerneeNom,
                beneficiaire
            }
        }, ...filesUploaded]

        const dataEnd = [{
            key: 'edit',
            value: {
                motif,
                dateCompter: new Date(dateCompter).toLocaleDateString('fr-FR'),
                beneficiaires: beneficiaires ? beneficiaires.map((benef: any) => benef.toString()) : []
            }
        }]

        return ContactService.editInfosSecuriteSociale(LocalStorage.getToken(), motif === SOCIAL_SECURITY_CHOICES.DEACTIVATE ? dataEnd : data)
            .then(() => {
                addToast(ToastType.SUCCESS, 'personalInfo.personalData.modifySS.success')
                setOpenConfirmation(true)
            })
            .catch((e) => {
                if (e?.data?._error) {
                    addToast(ToastType.ERROR, e.data._error)
                } else if (e?.data) {
                    return e.data
                } else {
                    addToast(ToastType.ERROR, 'global.error.occurred')
                }
            })
            .finally(() => setIsButtonLoading(false))
    }

    const OPTION_SELECT_DATA: SelectOption[] = useMemo(() => {
        const data: SelectOption[] = [
            {
                value: SOCIAL_SECURITY_CHOICES.MODIFY,
                label: intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.wish.modify'})
            },
            {
                value: SOCIAL_SECURITY_CHOICES.DEACTIVATE,
                label: intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.wish.end'})
            }
        ]

        if (hasMinorChild) {
            data.splice(1, 0, {
                value: SOCIAL_SECURITY_CHOICES.ADD,
                label: intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.wish.add'})
            })
        }
        return data
    }, [hasMinorChild, intl])

    useEffect(() => {
        getAffiliation()
        setIsLoading(false)
    }, [getAffiliation])

    if (isAffiliationLoading || isLoading) {
        return <Loader/>
    }

    return (
        <PageLayout bodyPadding={isMobile || isTabletPortrait ? '32px 32px 12px 32px' : '32px 32px 0 32px'}
                    title={intl.formatMessage({id: 'personalInfo.personalData.modifySS.title'}, {br: <br/>})}>
            <Form
                onSubmit={onSubmit}
                validate={(values: Record<string, any>) => {
                    const errors: Record<string, any> = {}

                    if (values[MODIFY_SOCIAL_SECURITY_FIELDS.OPTION] === SOCIAL_SECURITY_CHOICES.MODIFY) {
                        if (!values[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARY]) {
                            errors[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARY] = intl.formatMessage({id: 'global.error.required'})
                        } else if (!values[MODIFY_SOCIAL_SECURITY_FIELDS.SOCIAL_SECURITY_NUMBER]) {
                            errors[MODIFY_SOCIAL_SECURITY_FIELDS.SOCIAL_SECURITY_NUMBER] = intl.formatMessage({id: 'global.error.required'})
                        }
                        if (!values[MODIFY_SOCIAL_SECURITY_FIELDS.CERTIFICATE] || values[MODIFY_SOCIAL_SECURITY_FIELDS.CERTIFICATE]?.length === 0) {
                            errors[MODIFY_SOCIAL_SECURITY_FIELDS.CERTIFICATE] = intl.formatMessage({id: 'global.error.required'})
                        }
                    }

                    if (values[MODIFY_SOCIAL_SECURITY_FIELDS.OPTION] === SOCIAL_SECURITY_CHOICES.DEACTIVATE) {
                        if (!values[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARIES] || values[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARIES]?.length === 0) {
                            errors[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARIES] = intl.formatMessage({id: 'global.error.required'})
                        }
                        if (!values[MODIFY_SOCIAL_SECURITY_FIELDS.DATE_OF_ENTRY]) {
                            errors[MODIFY_SOCIAL_SECURITY_FIELDS.DATE_OF_ENTRY] = intl.formatMessage({id: 'global.error.required'})
                        } else if (values[MODIFY_SOCIAL_SECURITY_FIELDS.DATE_OF_ENTRY] <= new Date()) {
                            errors[MODIFY_SOCIAL_SECURITY_FIELDS.DATE_OF_ENTRY] = intl.formatMessage({id: 'global.error.invalidDate'})
                        }
                    }

                    return errors
                }}
                render={({handleSubmit, pristine, values, errors, form: {reset, change}}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Stack direction="column" spacing="24px">
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="personalInfo.personalData.modifySS.content"
                                        values={{
                                            br: <br/>,
                                            icon: (
                                                <Popover iconPath={mdiHelpCircleOutline} iconSize="14px">
                                                    <Box p={2} maxWidth="242px">
                                                        <Typography variant="subtitle2">
                                                            <FormattedMessage
                                                                id="personalInfo.personalData.modifySS.popover"/>
                                                        </Typography>
                                                    </Box>
                                                </Popover>
                                            )
                                        }}
                                    />
                                    {beneficiaireList && beneficiaireList.length > 0 && (
                                        <Typography variant="body2">
                                            <FormattedMessage id="personalInfo.personalData.modifySS.radiation"/>
                                        </Typography>
                                    )}
                                </Typography>
                                {beneficiaireList && beneficiaireList.length > 0 && (
                                    <Box display="flex" justifyContent="center">
                                        <ButtonDefault
                                            onClick={() => history.push(SECONDARY_PATH.CONTACT_DELETE_BENEFICIARY)}
                                            variant="text" color="secondary" startIcon={<Delete/>}>
                                            <FormattedMessage id="personalInfo.personalData.buttons.removeBeneficiary"/>
                                        </ButtonDefault>
                                    </Box>
                                )}

                                <Divider/>

                                <InfoField
                                    label={intl.formatMessage({id: 'personalInfo.personalData.modifySS.form.wish.title'})}
                                    value={
                                        <RadioInput
                                            name={MODIFY_SOCIAL_SECURITY_FIELDS.OPTION}
                                            data={OPTION_SELECT_DATA}
                                            onChange={() => {
                                                values[MODIFY_SOCIAL_SECURITY_FIELDS.CERTIFICATE] && change(MODIFY_SOCIAL_SECURITY_FIELDS.CERTIFICATE, [])
                                                values[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARIES] && change(MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARIES, [])
                                                values[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARY] && change(MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARY, undefined)
                                                values[MODIFY_SOCIAL_SECURITY_FIELDS.SOCIAL_SECURITY_NUMBER] && change(MODIFY_SOCIAL_SECURITY_FIELDS.SOCIAL_SECURITY_NUMBER, undefined)
                                                values[MODIFY_SOCIAL_SECURITY_FIELDS.HOST] && change(MODIFY_SOCIAL_SECURITY_FIELDS.HOST, undefined)
                                                values[MODIFY_SOCIAL_SECURITY_FIELDS.DATE_OF_ENTRY] && change(MODIFY_SOCIAL_SECURITY_FIELDS.DATE_OF_ENTRY, new Date().toISOString())
                                            }}
                                        />
                                    }
                                    direction="column"
                                    required
                                />

                                {values[MODIFY_SOCIAL_SECURITY_FIELDS.OPTION] === SOCIAL_SECURITY_CHOICES.DEACTIVATE && (
                                    <EndTeletransmission allBeneficiaries={allBeneficiaries} errors={errors}/>
                                )}

                                {values[MODIFY_SOCIAL_SECURITY_FIELDS.OPTION] === SOCIAL_SECURITY_CHOICES.MODIFY && (
                                    <ModifySocialSecurityNumber
                                        allBeneficiaries={individuMajeurList}
                                        selectedBenef={values[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARY]}
                                        change={change}
                                        values={values}
                                    />
                                )}

                                {values[MODIFY_SOCIAL_SECURITY_FIELDS.OPTION] === SOCIAL_SECURITY_CHOICES.ADD && (
                                    <ModifySocialSecurityNumber
                                        type={SOCIAL_SECURITY_CHOICES.ADD}
                                        allBeneficiaries={enfantsMineurList}
                                        selectedBenef={values[MODIFY_SOCIAL_SECURITY_FIELDS.BENEFICIARY]}
                                        change={change}
                                        values={values}
                                    />
                                )}

                                <Stack alignItems="center">
                                    <ButtonDefault
                                        variant="contained"
                                        type="submit"
                                        disabled={pristine}
                                    >
                                        {isButtonLoading ? <CircularProgress size={24} color="inherit"/> :
                                            <FormattedMessage id="global.button.validate"/>}
                                    </ButtonDefault>
                                    <Box display="flex" width="100%" flex={1}
                                         alignSelf={isMobile || isTabletPortrait ? 'unset' : 'baseline'}>
                                        <Typography sx={{
                                            position: isMobile || isTabletPortrait ? 'unset' : 'relative',
                                            bottom: '32px',
                                            left: 0,
                                            height: '32px',
                                            display: 'flex',
                                            alignItems: 'flex-end'
                                        }} variant="body2" color="#7a7a7a">
                                            <FormattedMessage id="global.mandatory"/>
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                            <ConfirmationPopin
                                title={intl.formatMessage({id: 'personalInfo.personalData.modifySS.title'}, {
                                    br: <br/>
                                })}
                                open={openConfirmation}
                                setOpen={setOpenConfirmation}
                                resetForm={reset}
                            />
                        </form>
                    )
                }}
            />
        </PageLayout>
    )
}

const mapStateToProps = (state: any) => ({
    // @ts-ignore
    allBeneficiaries: getIndividuList(state),
    isAffiliationLoading: isAffiliationLoading(state)
})

const actions = {
    getAffiliation: affiliationAction.getAffiliation
}

export default connect(
    mapStateToProps,
    actions
)(ModifySocialSecurityPage)
