export enum MODIFY_PERSONAL_INFO_FIELDS {
    NAME = 'nom',
    FIRST_NAME = 'prenom',
    BIRTH_DATE = 'dateNaissance',
    ADDRESS_1 = 'adresse1',
    ADDRESS_2 = 'adresse2',
    ADDRESS_3 = 'adresse3',
    POSTAL_CODE = 'codpos',
    CITY = 'ville',
    COUNTRY = 'libPays',
    DATE_OF_ENTRY = 'dateCompter',
    TELEPHONE = 'telephone',
}

export enum MODIFY_SOCIAL_SECURITY_FIELDS {
    OPTION = 'motif',
    DATE_OF_ENTRY = 'dateCompter',
    BENEFICIARIES = 'beneficiaires',
    BENEFICIARY = 'beneficiaire',
    CERTIFICATE = 'files',
    SOCIAL_SECURITY_NUMBER = 'numSS',
    HOST = 'organisme',
    SECOND_SS_NUMBER = 'numSS2',
}

export enum SOCIAL_SECURITY_CHOICES {
    ADD = '1',
    MODIFY = '2',
    DEACTIVATE = '3'
}

export enum MODIFY_EMAIL_FIELDS {
    NEW_EMAIL = 'newMail',
    CONFIRM_EMAIL = 'confirmMail',
}

export enum MODIFY_PASSWORD_FIELDS {
    OLD_PASSWORD = 'password',
    NEW_PASSWORD = 'newPassword',
    CONFIRM_PASSWORD = 'confirmPassword',
}

export enum MODIFY_AUTHENT_FIELDS {
    NEW_AUTHENT = 'newAuthent',
    TELEPHONE = 'telephone',
    MAIL = 'email',
    CODE = 'code'
}

export enum AUTHENTIFICATION_SECURISEE {
    RENVOI_CODE = 'renvoiCode'
}

export enum DELETE_BENEFICIARY_FIELDS {
    BENEFIARY = 'beneficiaire',
    REASON = 'motif',
    RADIATION_DATE = 'dateRadiation',
    DEATH_CERTIFICATE = 'files',
    SEND_MAIL = 'envoyerMail',
    EMAIL = 'email',
}

export enum RIBS_FIELDS {
    IBAN = 'iban',
    BANK = 'banque',
    OWNER = 'titulaire',
    STARTING_DATE = 'dateCompter',
    RIB = 'rib',
    BENEFICIARIES = 'beneficiaires',
    EDIT_COTISATION_TOO = 'editCotisationToo'
}

export const REMBOURSEMENTS = 1
export const COTISATIONS = 2

export const NATURE = {
    // Nature "Chèque" ou "Lettre chèque"
    CHEQUE: 1,
    // Nature "Virement" ou "Prélèvement"
    VIREMENT: 2
}
