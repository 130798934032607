export default class FormatUtils {
	static formatNiss(paramNiss) {
		const niss = paramNiss && paramNiss.replace(/ /g, '')
		if (niss && niss.length === 15) {
			return (
				`${niss.substring(0, 1)} ${
					niss.substring(1, 3)} ${
					niss.substring(3, 5)} ${
					niss.substring(5, 7)} ${
					niss.substring(7, 10)} ${
					niss.substring(10, 13)} ${
					niss.substring(13, 15)}`
			)
		}
		return niss
	}

	static formatTel(paramTel) {
		const tel = paramTel && paramTel.replace(/ /g, '')
		if (tel && tel.length === 10) {
			return (
				`${tel.substring(0,2)} ${
					tel.substring(2,4)} ${
					tel.substring(4,6)} ${
					tel.substring(6,8)} ${
					tel.substring(8,10)}`
			)
		}
	}

	static formatDate(dateString) {
		const date = new Date(dateString)
		if (date) {
			const mm = date.getMonth() + 1 // getMonth() is zero-based
			const dd = date.getDate()

			return [(dd > 9 ? '' : '0') + dd,
				(mm > 9 ? '' : '0') + mm,
				date.getFullYear()
			].join('/')
		}
		return dateString
	}

	static formatBban(bban, obfuscate) {
		const string = obfuscate ? this.obfuscate(bban, '----XXXXXXXXXXXXXXXX---') : bban
		if (string) {
			return string.match(/.{1,4}/g).join(' ')
		}
		return string
	}

	static formatIban(iban, obfuscate) {
		const string = obfuscate ? this.obfuscate(iban, '--------XXXXXXXXXXXXXXXX---') : iban
		if (string) {
			return string.match(/.{1,4}/g).join(' ')
		}
		return string
	}

	/**
	 * Obfusque une chaîne "sensible" à l'affichage.
	 *
	 * Idéalement, le masque doit matcher la longueur de la chaîne à obfusquer. Il peut comporter n'importe quel
	 * caractère sachant que le 'X' est utilisé pour indiquer que le caractère courant doit être omis.
	 *
	 * @param plainText le texte à obfusquer
	 * @param mask le masque de remplacement
	 * @param replacementChar le caractère à utiliser comme remplacement
	 * @returns {*}
	 */
	static obfuscate(plainText, mask, replacementChar = 'X') {
		if (!plainText || !mask) {
			return plainText
		}
		let obfuscatedString = ''
		for (let index = 0; index < plainText.length; ++index) {
			if (mask.length > index && mask[index] === 'X') {
				obfuscatedString += replacementChar
			} else {
				obfuscatedString += plainText[index]
			}
		}
		return obfuscatedString
	}

	/**
	 * @param dateString la date au format dd/MM/YYYY
	 * @param lenient si le parsing doit être permissif
	 * @return {Date}
	 */
	static parseDate(dateString, lenient = false) {
		const dateSplit = typeof dateString === 'string' ? dateString.split(/\//) : []
		const [dayPart, monthPart, yearPart] = dateSplit
		const day = +dayPart
		const month = +monthPart - 1
		const year = +yearPart
		const date = new Date(year, month, day)
		if (isNaN(date.getTime())) {
			return date
		}
		if (!lenient && (date.getMonth() !== month || date.getDate() !== day)) {
			return new Date(NaN)
		}
		return date
	}

	static formatMontant(montant) {
		if (!montant) {
			return '0,00 €'
		}
		return `${montant.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ',')} €`
	}

	static formatNumber(num, precision = 2) {
		const map = [
			{ suffix: ' T', threshold: 1e12 },
			{ suffix: ' B', threshold: 1e9 },
			{ suffix: ' M', threshold: 1e6 },
			{ suffix: ' k', threshold: 1e3 },
			{ suffix: ' ', threshold: 1 },
		];

		const found = map.find((x) => Math.abs(num) >= x.threshold);
		if (found) {
			const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
			return formatted.replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ',');
		}

		return num.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ',');
	}

	static formatNumberWithoutK(num, precision = 2) {
		const map = [
			{ suffix: ' T', threshold: 1e12 },
			{ suffix: ' B', threshold: 1e9 },
			{ suffix: ' M', threshold: 1e6 },
			{ suffix: ' ', threshold: 1 },
		];

		const found = map.find((x) => Math.abs(num) >= x.threshold);
		if (found) {
			const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
			return formatted.replace(/(\d)(?=(\d{3}))/g, '$1 ').replace('.', ',');
		}

		return num.toString().replace(/(\d)(?=(\d{3}))/g, '$1 ').replace('.', ',');
	}

	/**
	 * Convertit une date en string (ISO 8601) en un objet Date JavaScript. La timezone utilisée est celle
	 * du navigateur du client.
	 *
	 * @param str
	 * @returns {Date}
	 */
	static toLocalDate(str) {
		// le conde suivant le fonctionne pas sour IE :
		// new Date(str.split('-')) => Invalid date
		// en fait le constructor avec tableau n'est pas géré,
		// du coup on décompose la string pour passer les paramètres manuellement
		const dateSplitted = str.split('-').map(s => parseInt(s, 10))
		if (dateSplitted.length < 3) {
			return null
		}
		const annee = dateSplitted[0]
		const mois = dateSplitted[1] - 1
		const jour = dateSplitted[2]
		return new Date(annee, mois, jour)
	}

	/**
	 * Convertir l'objet time de type Date en string en format 'YYYY-MM-DD'
	 * @param time
	 * @returns {string}
	 */
	static toStrLocalDate(time = new Date()) {
		const year = time.getFullYear()
		const month = time.getMonth() + 1
		const day = time.getDate()
		return [year,
			(month > 9 ? '' : '0') + month,
			(day > 9 ? '' : '0') + day
		].join('-')
	}

	static checkValidFrenchCP(cp) {
		const regex = new RegExp('^[0-9]{5}$')
		return regex.test(cp)
	}
}

/**
 * Remplace les liens vers les médias Cardibox au sein des attributs de type CKEditor.
 *
 * Sans cette méthode, les liens conservent leur valeur brute qui ne mènent à rien.
 *
 * @param content la valeur brute de l'attribut en Cardibox
 * @return string contenu Cardibox avec les liens remplacés
 */
export const replaceEntityLinks = (content = '') => {
	return content.replace(/#MEDIALINK\(([a-zA-Z0-9]+)\)/, '/blobs/medias/s/$1/')
}

