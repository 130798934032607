import { Set } from 'immutable'
import { createImmutableSelector } from '../../../utils/globalUtils'

export const isLoaded = state => state.notifications.loaded
export const isNotificationLoading = state => state.notifications.loading
export const getExpirationDate = state => state.notifications.expirationDate

const allNotifications = state => state.notifications.objects

export const getByDomainOrAll = () => (
	createImmutableSelector(
		[
			allNotifications,
			(state, props) => {
				if (Array.isArray(props?.domain)) {
					return new Set(props?.domain)
				}
				if (typeof props?.domain === 'string') {
					return new Set([props?.domain])
				}
				return new Set()
			}
		],
		(notifications, domains) => {
			if (domains.isEmpty()) {
				return Array.from(notifications.values())
			}
			return notifications
				.filter(notif => domains.contains(notif.domain))
				.toArray()
		}
	)
)

export const getByIdPiece = idPieceSelector => (
	createImmutableSelector(
		[allNotifications, idPieceSelector],
		(notifications, idPiece) => {
			if (idPiece !== null && typeof idPiece !== 'undefined') {
				return notifications.find(e => `${e.data.idpiece}` === `${idPiece}`)
			}
		}
	)
)
