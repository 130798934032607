import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Popup from '../../../components/Popup'
import {closeReadOnlyModal} from './ReadOnlyActions'

const WithReadOnlyModalContext = React.createContext({})

type InterstitielProviderProps = {
	children: React.ReactNode
}

const WithReadOnlyModalProvider: React.FC<InterstitielProviderProps> = (
	{
		children,
		// @ts-ignore
		openModal,
		// @ts-ignore
		closeReadOnlyModal
	}) => {
	const [open, setOpen] = useState(openModal)

	useEffect(() => {
		setOpen(openModal)
	}, [openModal])


	const handleClose= (open: boolean) => {
		setOpen(open)
		closeReadOnlyModal()
	}

	return (
		<WithReadOnlyModalContext.Provider value={{}}>
			{children}
			<Popup
				open={open}
				setOpen={handleClose}
				title="Action interdite"
			>
				<div className="col-lg-12">
					<div>
						<p>Vous êtes en consultation uniquement et ne pouvez effectuer cette action.</p>
					</div>
				</div>
			</Popup>
		</WithReadOnlyModalContext.Provider>
	)
}

const mapStateToProps = (state: any) => {
	return {
		openModal: state.readOnlyModal.open
	}
}

const mappedActions = {
	closeReadOnlyModal: closeReadOnlyModal,
}

export default connect(mapStateToProps, mappedActions)(WithReadOnlyModalProvider)
