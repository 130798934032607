import React, { useContext, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import InfoField from '../../../../../components/InfoField'
import ButtonLink from '../../../../../components/ButtonLink'
import Icon from '@mdi/react'
import { mdiAccountLock, mdiCookieCog } from '@mdi/js'
import { useBreakpoints } from '../../../../../components/breakpoints/BreakpointsProvider'
import { CookiesContext } from '../../../../../components/cookie/CookiesContext'
import ModifyPassword from './ModifyPassword'
import PopinDoubleAuthentKPI from '../../../doubleAuthent/PopinDoubleAuthentKPI'
import {maskEmail} from '../../../../../utils/authentUtils'

const ConnexionInfo = (
	{
		// @ts-ignore
		user
	}
) => {
	const intl = useIntl()
	const { isMobile, isDesktop, isLaptop, isTabletPortrait, isWidescreen } = useBreakpoints()
	const isDesktopOrLaptop = isDesktop || isLaptop || isWidescreen
	const { openManageCookies } = useContext(CookiesContext)
	const [openModifyPassword, setOpenModifyPassword] = useState<boolean>(false)
	const [openDoubleAuthent, setOpenDoubleAuthent] = useState<boolean>(false)

	return (
		<Stack direction="column" spacing="24px">
			<Typography variant="h2">
				<FormattedMessage id="personalInfo.connexionInfo.title" />
			</Typography>
			<Stack direction="column" spacing="20px" pl="24px" pr="12px">
				<Stack
					width="100%"
					direction={isMobile || isTabletPortrait ? 'column' : 'row'}
					spacing={isMobile || isTabletPortrait ? '16px' : '12px'}
					justifyContent="space-between"
				>
					<InfoField
						label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.email' })}
						value={maskEmail(user?.email) || ''}
						variantValue="body1"
					/>
					<InfoField
						label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.password' })}
						value="***************"
						variantValue="body1"
					/>
				</Stack>
				{user?.doubleAuthentification && <Stack
					width="70%"
					direction={isMobile || isTabletPortrait ? 'column' : 'row'}
					spacing={isMobile || isTabletPortrait ? '16px' : '12px'}
					justifyContent="space-between"
				>
					<InfoField
						label={intl.formatMessage({ id: 'personalInfo.connexionInfo.labels.doubleAuthent' })}
						value={(user?.doubleAuthentification && 'Mail') || ''}
						variantValue="body1"
					/>
				</Stack>
				}

				<Stack
					width="100%"
					direction={isMobile || isTabletPortrait ? 'column' : 'row'}
					spacing={isMobile || isTabletPortrait ? '16px' : '12px'}
					justifyContent="space-between"
				>
					<Box display="flex" flex={1}>
						<ButtonLink
							onClick={() => setOpenModifyPassword(true)}
							variant="contained"
							startIcon={<Icon size="20px" path={mdiAccountLock} />}
							sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
						>
							<FormattedMessage id="personalInfo.connexionInfo.buttons.modifyPassword" />
						</ButtonLink>
					</Box>

					<Box display="flex" flex={1}>
						<ButtonLink
							onClick={openManageCookies}
							variant="contained"
							startIcon={<Icon size="20px" path={mdiCookieCog} />}
							sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
						>
							<FormattedMessage id="personalInfo.connexionInfo.buttons.manageCookies" />
						</ButtonLink>
					</Box>
				</Stack>
				<Stack
					width="100%"
					direction={isMobile || isTabletPortrait ? 'column' : 'row'}
					spacing={isMobile || isTabletPortrait ? '16px' : '12px'}
					justifyContent="space-between"
				>

					<Box display="flex" flex={1}>
						<ButtonLink
							onClick={() => setOpenDoubleAuthent(true)}
							variant="contained"
							startIcon={<Icon size="20px" path={mdiCookieCog} />}
							sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
						>
							<FormattedMessage id={`personalInfo.connexionInfo.buttons.${user?.doubleAuthentification ? 'modifierAuthent' : 'activerAuthent'}`} />
						</ButtonLink>
					</Box>
				</Stack>
			</Stack>
			<ModifyPassword openForm={openModifyPassword} setOpenForm={setOpenModifyPassword} />
			<PopinDoubleAuthentKPI open={openDoubleAuthent} setOpen={setOpenDoubleAuthent} currentEmail={user?.email} modification={!!user?.doubleAuthentification} />
		</Stack>
	)
}

export default ConnexionInfo
