import { types } from './contactActions'
import { combineReducers } from 'redux'
import appConst from '../../../constant/appConstants';

const data = (state = [], action) => {
	switch (action.type) {
		case types.GET_RUBRIQUES: {
			return {
				...state,
				[action.payload.shortcut]: action.payload
			}
		}
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case types.GET_RUBRIQUES_LOADING: {
			return state
		}
		default:
			return state
	}
}

const loadingRecherche = (state = false, action) => {
	switch (action.type) {
		case types.GET_RECHERCHE_LOADING: {
			return state
		}
		default:
			return state
	}
}

const recherche = (state = [], action) => {
	switch (action.type) {
		case types.GET_RECHERCHE_FAQ: {
			return action.payload
		}
		default:
			return state
	}
}

const expirationDate = (state = [], action) => {
	switch (action.type) {
		case types.GET_RUBRIQUES: {
			return {
				...state,
				[action.payload.shortcut]: (+new Date() + appConst.cacheDuration.contact * 1000)
			}
		}
		default:
			return state
	}
}

export default combineReducers({
	data,
	loading,
	loadingRecherche,
	recherche,
	expirationDate
})
