import GerepAPI from './GerepAPI'

export default class ContactService {

	static getRubriques(token, values) {
		return GerepAPI.get('/contact', values, token)
	}

	static getRechercheFaq(token, values) {
		return GerepAPI.get('/contact/rechercherFaq', values, token)
	}

	static suppressionBeneficiaire(token, values) {
		return GerepAPI.postMultipart('/contact/suppressionBeneficiaire', values, token)
	}

	static informationBeneficiaire(token, values) {
		return GerepAPI.post('/contact/informationBeneficiaire', values, token)
	}

	static ajoutBeneficiaire(token, values) {
		return GerepAPI.postMultipart('/contact/ajoutBeneficiaire', values, token)
	}

	static prolongationCouvertureANI(token, values) {
		return GerepAPI.postMultipart('/contact/prolongationCouvertureANI', values, token)
	}

	static editInfosSecuriteSociale(token, values) {
		return GerepAPI.postMultipart('/contact/editInfosSecuriteSociale', values, token)
	}

	static parcoursPoleEmploi(token, values) {
		return GerepAPI.postMultipart('/contact/parcoursPoleEmploi', values, token)
	}

	static justificatifRemboursement(token, values) {
		return GerepAPI.postMultipart('/contact/justificatifRemboursement', values, token)
	}

	static envoyerActeNaissance(token, values) {
		return GerepAPI.postMultipart('/contact/acteNaissance', values, token)
	}
}
