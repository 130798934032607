import React from 'react'
import DashboardCard from '../../../../components/DashboardCard'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { Stack, Typography } from '@mui/material'
import Icon from '@mdi/react'
import { mdiFolderOpenOutline } from '@mdi/js'
import DemandsCell from './DemandsCell'
import { connect } from 'react-redux'
import { isDemandesLoading } from '../../../webservices/demandes/demandesSelectors'
import { SECONDARY_PATH } from '../../../../constant/path'
import { useHistory } from 'react-router-dom'

type DemandsCardProps = {
	demands: any[]
	affiliationExpired: boolean
}

const DemandsCard: React.FC<DemandsCardProps> = (
	{
		demands,
		affiliationExpired,
		// @ts-ignore
		loading
	}) => {
	const intl = useIntl()
	const history = useHistory()
	const { isDesktop, isLaptop, isWidescreen } = useBreakpoints()

	const height = !(isDesktop || isLaptop || isWidescreen) ? 'auto' : '236px'

	return (
		<DashboardCard
			height={height}
			title={intl.formatMessage({ id: 'dashboard.demands.title' })}
			action={!affiliationExpired ? {
				label: intl.formatMessage({ id: 'global.button.seeAll' }),
				onClick: () => history.push(SECONDARY_PATH.SERVICES_HISTORIQUE_DEMANDE)
			} : undefined}
			loading={loading}
		>
			{demands?.length > 0 ? (
				<Stack width="100%" direction="column" spacing="12px">
					{demands.map((demand, index) => (
						<DemandsCell key={index} demand={demand} />
					))}
				</Stack>
			) : (
				<Stack mt="12px" spacing={1} direction="column" alignItems="center" justifyContent="center" height="100%" width="100%">
					<Icon path={mdiFolderOpenOutline} size="48px" color="#f6c5a0" />
					<Typography textAlign="center" variant="body2" sx={{ color: '#929292' }}>
						<FormattedMessage id="dashboard.demands.emptyState" />
					</Typography>
				</Stack>
			)}
		</DashboardCard>
	)
}

const mapStateToProps = (state: any) => ({
	loading: isDemandesLoading(state)
})

export default connect(
	mapStateToProps,
	{}
)(DemandsCard)
