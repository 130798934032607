import GerepAPI from './GerepAPI'

export default class RibService {

	static getRib(token) {
		return GerepAPI.get('/rib', null, token)
	}

	static getRibCotisation(token) {
		return GerepAPI.get('/rib/cotisation', null, token)
	}

	static getRibPrestationIndividu(token, individu) {
		return GerepAPI.get(`/rib/${individu}/prestation`, null, token)
	}

	static editRib(parts, token) {
		return GerepAPI.postMultipart('/rib/editRIB', parts, token)
	}

	static deleteRib(values, token) {
		return GerepAPI.post('/rib/deleteRIB', values, token)
	}

	static downloadMandatSepa(token, values) {
		return GerepAPI.download('/rib/downloadMandatSepa', values, token)
	}
}
