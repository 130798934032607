import { createContext } from 'react'

type CookiesContextProps = {
	openManageCookies: () => void
}

const context: CookiesContextProps = {
	openManageCookies: () => {
	}
}

export const CookiesContext = createContext(context)