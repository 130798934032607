import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { userSelector } from '../../user/userSelectors'
import Loader from '../../../../components/Loader'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import ButtonDefault from '../../../../components/ButtonDefault'
import * as actionsAffiliation from '../../../webservices/affiliation/affiliationActions'
import * as contratsActions from '../../../webservices/contrats/contratsActions'
import { getAffiliationAssurePrincipal, isAffiliationLoading } from '../../../webservices/affiliation/affiliationSelector'
import { getContratBaseSante, getContratsSante, isContratsLoading } from '../../../webservices/contrats/contratsSelector'
import ContratList from '../components/ContratList'
import ContratNotAvailable from '../components/ContratNotAvailable'
import ButtonLink from '../../../../components/ButtonLink'
import Icon from '@mdi/react'
import { mdiAccountEdit, mdiChatQuestion, mdiDownload } from '@mdi/js'
import { MAIN_PATH, SECONDARY_PATH } from '../../../../constant/path'
import { useHistory } from 'react-router-dom'
import PageLayout from '../../../../components/PageLayout'

const ContratPage = (
	{
		// @ts-ignore
		loadContrats,
		// @ts-ignore
		download,
		// @ts-ignore
		getAffiliation,
		// @ts-ignore
		downloadAttestationAffiliation,
		// @ts-ignore
		loading,
		// @ts-ignore
		contratsLoading,
		// @ts-ignore
		company,
		// @ts-ignore
		contrats
	}
) => {

	const intl = useIntl()
	const { isDesktop, isLaptop, isTablet, isMobile, isWidescreen } = useBreakpoints()
	const isDesktopOrLaptop = isDesktop || isLaptop || isTablet || isWidescreen
	const [loadingDlGarantie, setLoadingDlGarantie] = useState<boolean>(false)
	const [loadingDlAffiliation, setLoadingDlAffiliation] = useState<boolean>(false)
	const [openContratNotAvailable, setOpenContratNotAvailable] = useState<boolean>(false)
	const history = useHistory()

	useEffect(() => {
		getAffiliation()
		loadContrats()
	}, [getAffiliation, loadContrats])

	const onDlAttestationAffiliation = () => {
		setLoadingDlAffiliation(true)
		downloadAttestationAffiliation().then(() => {
			setLoadingDlAffiliation(false)
		})
	}

	const downloadContrat = (numgar: any, siret: any) => {
		setLoadingDlGarantie(false)
		download(numgar, siret)
			.then(() => {
				setLoadingDlGarantie(false)
			})
			.catch(() => {
				setLoadingDlGarantie(false)
				setOpenContratNotAvailable(true)
			})
	}

	return (
		<PageLayout title={intl.formatMessage({ id: 'contrat.title' })}>
			{
				(loading || contratsLoading) ? (
					<Stack direction="column" spacing="24px">
						<Loader />
					</Stack>
				) : (
					<>
						{company &&
							<Stack direction={isDesktopOrLaptop ? 'row' : 'column'} spacing="24px" pb="24px" alignItems={isDesktopOrLaptop ? 'start' : 'normal'}>
								<Typography alignSelf={isDesktopOrLaptop ? 'center' : 'flex-start'} flex={10} variant="h3">
									{`Société contractante : ${company.name ? company.name : ''}`}
								</Typography>
								<ButtonDefault
									variant="outlined"
									onClick={() => downloadContrat(company.numgar, company.siret)}
								>
									{loadingDlGarantie ?
										<CircularProgress size={24} color="inherit" /> :
										<FormattedMessage id="contrat.download" />
									}
								</ButtonDefault>
								<ContratNotAvailable open={openContratNotAvailable} setOpen={setOpenContratNotAvailable} />
							</Stack>
						}

						<Divider />

						<ContratList contrats={contrats} />

						<Stack direction="row" spacing="24px" pt="10px" pb="10px">

						</Stack>

						<Stack direction="column" spacing={isDesktopOrLaptop ? '14px' : '18px'}>
							<Stack direction={!isDesktopOrLaptop ? 'column' : 'row'} spacing={isDesktopOrLaptop ? '14px' : '18px'}>
								<Box display="flex" flex={1}>
									<ButtonLink onClick={() => history.push(MAIN_PATH.HELP)} variant="contained" startIcon={<Icon size="20px" path={mdiChatQuestion} />}
									            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
									>
										<FormattedMessage id="contrat.footer.buttonInfoContrat" />
									</ButtonLink>
								</Box>
								<Box display="flex" flex={1}>
									<ButtonLink onClick={onDlAttestationAffiliation} variant="contained" startIcon={<Icon size="20px" path={mdiDownload} />}
									            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
									>
										{loadingDlAffiliation ?
											<CircularProgress size={24} color="inherit" /> :
											<FormattedMessage id="contrat.footer.buttonDlAttestation" />
										}
									</ButtonLink>
								</Box>
								{!isDesktopOrLaptop &&
									<Box display="flex" flex={1}>
										<ButtonLink onClick={() => history.push(SECONDARY_PATH.ACCOUNT_BENEFICIARY)} variant="contained" startIcon={<Icon size="20px" path={mdiAccountEdit} />}
										            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
										>
											<FormattedMessage id="contrat.footer.buttonInfoBeneficiaire" />
										</ButtonLink>
									</Box>
								}
							</Stack>
							{isDesktopOrLaptop &&
								<Stack width="100%" direction={!isDesktopOrLaptop ? 'column' : 'row'} spacing="14px">
									<Box display="flex" flex={1}>
										<ButtonLink onClick={() => history.push(SECONDARY_PATH.ACCOUNT_BENEFICIARY)} variant="contained" startIcon={<Icon size="20px" path={mdiAccountEdit} />}
										            sx={isMobile ? { textAlign: 'start', lineHeight: '18px' } : {}}
										>
											<FormattedMessage id="contrat.footer.buttonInfoBeneficiaire" />
										</ButtonLink>
									</Box>
									<Box display="flex" flex={1} />
								</Stack>
							}
						</Stack>
					</>
				)
			}
		</PageLayout>
	)
}

const mapStateToProps = (state: any) => ({
	user: userSelector(state),
	assurePrincipal: getAffiliationAssurePrincipal(state),
	// @ts-ignore
	contrats: getContratsSante(state),
	company: getContratBaseSante(state),
	loading: isAffiliationLoading(state),
	contratsLoading: isContratsLoading(state)
})

const actions = {
	loadContrats: contratsActions.loadContrats,
	download: contratsActions.download,
	getAffiliation: actionsAffiliation.getAffiliation,
	downloadAttestationAffiliation: actionsAffiliation.downloadAttestationAffiliation
}

export default connect(mapStateToProps, actions)(ContratPage)
