import React from 'react'
import {Stack, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import {useBreakpoints} from '../../../components/breakpoints/BreakpointsProvider'
import {styled} from '@mui/material/styles'


type AnneeSelectorProps = {
    name: string
    data: SelectOption[]
    onChange?: (newValue: any) => void
    stateValue: number
}

const StyledAnneeSelector = styled(ToggleButton)(() => ({
    '&.MuiButtonBase-root': {
        borderRadius: "26px",
        padding: "8px",
    },
    '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#cddae7',
        color: "#004687",
        fontWeight: "600"
    }
}))

const AnneeSelector: React.FC<AnneeSelectorProps> = (
    {
        data,
        onChange,
        stateValue
    }) => {
    const {isMobile, isTabletPortrait} = useBreakpoints()

    const handleChange = (event: React.MouseEvent<HTMLElement>, value: number | null) => {
        if (value !== null) {
            onChange && onChange(value)
        }
    }

    return (
        <ToggleButtonGroup
            value={stateValue.toString()}
            exclusive
            onChange={handleChange}
            // orientation={isMobile || isTabletPortrait ? 'vertical' : 'horizontal'}
            orientation="horizontal"
            sx={{display: 'flex'}}
        >
            {data.map(({value, label}) => (
                <StyledAnneeSelector value={value} key={`${label}-${value}`} style={{height: 'auto', flex: 1}}>
                    <Stack direction='row' alignItems="center">
                        <Typography sx={{textAlign: 'center', fontWeight: stateValue === value ? 600 : 500}}>
                            {label}
                        </Typography>
                    </Stack>
                </StyledAnneeSelector>
            ))}
        </ToggleButtonGroup>
    )
}

export default AnneeSelector
