import React, { useMemo, useState } from 'react'
import { RefreshContext } from './RefreshContext'

type RefreshProvider = {
	children: React.ReactNode
}

const RefreshProvider: React.FC<RefreshProvider> = (
	{
		children
	}) => {
	const [refreshing, setRefreshing] = useState<boolean>(false)

	const refresh = () => {
		setRefreshing(prevState => !prevState)
	}

	const contextValue = useMemo(() => ({
		refresh,
		refreshing
	}), [refreshing, refresh])

	return (
		<RefreshContext.Provider value={contextValue}>
			{children}
		</RefreshContext.Provider>
	)
}

export default RefreshProvider