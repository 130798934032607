import React, {useLayoutEffect} from 'react'
import {Stack, Typography} from '@mui/material'
import {FormattedMessage, useIntl} from 'react-intl'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import EmptyResult from '../../components/EmptyResult'

type GridAssuresChartProps = {
    id_title: string | React.ReactNode | any
    idChart: string
    dataChart: any[]
}

const GridAssuresChart: React.FC<GridAssuresChartProps> = (
    {
        id_title,
        idChart,
        dataChart
    }
) => {
    const intl = useIntl()
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const isSmallScreen = isMobile || isTabletPortrait

    useLayoutEffect(() => {
        if (dataChart) {
            let root = am5.Root.new(idChart)
            root.setThemes([am5themes_Animated.new(root)])
            root.numberFormatter.set("intlLocales", "fr-FR");
            root.numberFormatter.set("numberFormat", {
                minimumFractionDigits: 0
            })
            root.setThemes([
                am5themes_Animated.new(root)
            ])
            root.interfaceColors.set("grid", am5.color(0xffffff))

            // @ts-ignore
            root._logo.dispose()

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    layout: root.verticalLayout
                })
            )

            // @ts-ignore
            chart.get("colors").set("colors", [
                am5.color(0x8ac11d),
                am5.color(0x8ac11d),
                am5.color(0x7a7a7a),
            ])
            var xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 12
            });
            let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: "yearShort",
                    renderer: xRenderer,
                    tooltip: am5.Tooltip.new(root, {})
                })
            )
            xRenderer.grid.template.setAll({
                location: 1
            })
            xRenderer.labels.template.setAll({
                rotation: -45,
                centerY: am5.p50,
                centerX: am5.p100,
                textAlign: "center"
            });

            xAxis.data.setAll(dataChart)

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    //min: 0,
                    //max: 20,
                    extraMax: 0.1,
                    renderer: am5xy.AxisRendererY.new(root, {
                        strokeOpacity: 0.1
                    })
                })
            )

            let series2 = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    name: "Assurés",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "expenses",
                    categoryXField: "yearShort",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "{name} en {categoryX} : {valueY}"
                    })
                })
            )

            series2.strokes.template.setAll({
                templateField: "strokeSettings",
                strokeWidth: 3,
            })

            series2.fills.template.setAll({
                visible: true,
                fillOpacity: 0.2
            })

            series2.data.setAll(dataChart)

            series2.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(root, {
                        strokeWidth: 3,
                        stroke: series2.get("stroke"),
                        radius: 5,
                        fill: root.interfaceColors.get("background")
                    })
                })
            })

            chart.set("cursor", am5xy.XYCursor.new(root, {}))
            let cursor = chart.get("cursor")
            cursor?.lineX.setAll({visible: false})
            cursor?.lineY.setAll({visible: false})

            let legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                })
            )
            legend.data.setAll(chart.series.values)

            chart.appear(1000, 100)

            return () => root.dispose()
        }

    }, [dataChart, idChart])

    const titleGraph = () => {
        return (
            <Typography
                textAlign="left"
                variant="h2"
                fontSize="1.125rem"
                dangerouslySetInnerHTML={{__html: id_title}}
            />
        )
    }

    if (!dataChart) {
        return (
            <Stack direction="column" spacing="24px" flex="1">
                {titleGraph()}
                <Stack alignItems="center" direction="column">
                    <EmptyResult
                        nature="GRAPH"
                        message={
                            <FormattedMessage
                                id="global.noGraphData"
                                values={{
                                    titreGraph:
                                        <Typography
                                            variant="body2"
                                            fontSize="0,875rem"
                                            fontWeight="bold"
                                            color="#929292"
                                            dangerouslySetInnerHTML={{__html: id_title}}
                                        />
                                }}
                            />
                        }
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column" spacing="12px" flex="1">
            {titleGraph()}
            <div
                id={idChart}
                style={{
                    width: '100%',
                    height: isSmallScreen ? '400px' : '500px'
                }}
            />
        </Stack>
    )
}

export default GridAssuresChart
