import React, {useEffect, useState} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {useBreakpoints} from '../../../../components/breakpoints/BreakpointsProvider'
import Loader from '../../../../components/Loader'
import {Divider, Stack, Typography} from '@mui/material'
import PageLayoutEE from '../../../../components/PageLayoutEE'
import {FormattedMessage, useIntl} from 'react-intl'
import GridQualiteChart from '../components/GridQualiteChart'
import TypeDataSelector from '../../components/TypeDataSelector'
import AnneeSelector from '../../components/AnneeSelector'
import {isDataKPILoading, qualiteSelector} from '../../data/dataSelectors'
import {cardiboxActions, cardiboxSelectors} from 'redux-cardibox'
import appConst from '../../../../constant/appConstants'
import EmptyResult from '../../components/EmptyResult'
import FormatUtils from '../../../../utils/FormatUtils'


const ARTICLE_QUALITY_EVOLUTION_GRAPH = 'quality_evolution_graph'


const DashboardPageEE = (
	{
		// @ts-ignore
		loading,
		// @ts-ignore
		dataTelephonies,
		// @ts-ignore
		getArticleByShortcut,
		// @ts-ignore
		qualiteEvolutionGraphArticle
	}
) => {

	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const isSmallScreen = isMobile || isTabletPortrait

	const initialeAnnee = Number(Object.keys(dataTelephonies)[2])
	const [annee, setAnnee] = useState<number>(initialeAnnee)
	const [typeData, setTypeData] = useState<boolean>(false)
	const [dataGraphe, setDataGraphe] = useState<any>([])
	const [validMonth, setValidMonth] = useState<number>(0)

	const qualiteTelAnneeN = dataTelephonies[annee.toString()]
	const qualiteTelAnneeNMoins1 = dataTelephonies[(annee - 1).toString()]
	const isSelectedYearPastYear = initialeAnnee != new Date().getFullYear() || (initialeAnnee == new Date().getFullYear() && qualiteTelAnneeN && (qualiteTelAnneeN.annee === (initialeAnnee - 1).toString() || qualiteTelAnneeN.annee === (initialeAnnee - 2).toString()))

	useEffect(() => {
		if (initialeAnnee) {
			setAnnee(initialeAnnee)
			for (let i = dataTelephonies[initialeAnnee].dataMonth.length-1; i >= 0; i--) {
				if (validMonth === 0
					&& dataTelephonies[initialeAnnee].dataMonth[i].nbIncoming !== undefined) {
					setValidMonth(i)
					break
				}
			}
		}
	}, [dataTelephonies])


	useEffect(() => {
		if (!loading && qualiteTelAnneeN !== undefined) {
			if (!typeData) {
				setDataGraphe(qualiteTelAnneeN.dataMonth)
			} else {
				let dataTrimestre: any[] = []
				Object.keys(dataTelephonies).forEach((key: any) => {
					dataTrimestre = [...dataTrimestre,
						...dataTelephonies[key].dataTrimestre
					]
				})
				setDataGraphe(dataTrimestre)
			}
		}
	}, [annee, typeData, loading, dataTelephonies])


	useEffect(() => {
		getArticleByShortcut(appConst.cardiboxApi, ARTICLE_QUALITY_EVOLUTION_GRAPH)
	}, [getArticleByShortcut])

	function handleChangeTypeData(){
		setTypeData(!typeData)
	}

	function handleChangeYear(value:number){
		setAnnee(value)
	}

	const nothingToShow = (qualiteAnneeX: any) => {
		if (qualiteAnneeX !== undefined) {
			if(qualiteAnneeX.donneesActualisees){
				return false
			}
			return true
		}
	}

	const trimestreOrMonthNumbers = (donneeAnneeX: any) => {
		if (dataTelephonies && donneeAnneeX !== undefined && !nothingToShow(donneeAnneeX)) {
			if (!typeData) {
				if (donneeAnneeX.dataMonth && donneeAnneeX.dataMonth.length > 0) {
					for (let i = validMonth;i >= 0; i--) {
						if (donneeAnneeX.dataMonth[i].nbIncoming !== undefined) {
							if(donneeAnneeX.dataMonth[i].nbIncoming === 0){
								donneeAnneeX.dataMonth[i].ratioAnswer = 0
							}
							return donneeAnneeX.dataMonth[i]
						}
					}
					return undefined
				}
				return undefined
			} else {
				const validTrimestre = Math.ceil((validMonth + 1)/3)
				if (donneeAnneeX.dataTrimestre && donneeAnneeX.dataTrimestre.length > 0) {
					for (let i = validTrimestre; i >= 0; i--) {
						if (donneeAnneeX.dataTrimestre[i].nbIncoming !== undefined) {
							if(donneeAnneeX.dataTrimestre[i].nbIncoming === 0){
								donneeAnneeX.dataTrimestre[i].ratioAnswer = 0
							}
							return donneeAnneeX.dataTrimestre[i]
						}
					}
					return undefined
				}
				return undefined
			}
		}
		return undefined
	}

	const qualiteTelephonieAnneeSelector = () => {
		const selector = [
			{
				value: Object.keys(dataTelephonies)[2],
				label: Object.keys(dataTelephonies)[2]
			}
		]
		if (!nothingToShow(dataTelephonies[Object.keys(dataTelephonies)[1]])
			|| (nothingToShow(dataTelephonies[Object.keys(dataTelephonies)[1]]) && !nothingToShow(dataTelephonies[Object.keys(dataTelephonies)[0]]))) {
			selector.push(
				{
					value: Object.keys(dataTelephonies)[1],
					label: Object.keys(dataTelephonies)[1]
				}
			)
		}
		if (!nothingToShow(dataTelephonies[Object.keys(dataTelephonies)[0]])) {
			selector.push(
				{
					value: Object.keys(dataTelephonies)[0],
					label: Object.keys(dataTelephonies)[0]
				}
			)
		}
		return selector
	}

	if (loading || qualiteTelAnneeN === undefined || !qualiteEvolutionGraphArticle) {
		return <Loader />
	} else {
		return (
			<PageLayoutEE
				title={intl.formatMessage({ id: 'qualiteService.title' })}
				subtitle={qualiteTelAnneeN.donneesActualisees &&
					(intl.formatMessage({id: 'global.majData'}) + qualiteTelAnneeN.donneesActualisees)
				}
			>
				<Stack  spacing="24px" alignItems={isSmallScreen? 'center' : undefined}>
					<Stack direction={isSmallScreen ? 'column' : 'row'} spacing={isSmallScreen ? '24px' : 0} width="100%">
						<TypeDataSelector
							onChange={handleChangeTypeData}
							stateValue={typeData}
							name="typeData"
							label1={intl.formatMessage({id: 'prevoyance.dataSelector.monthLabel'})}
							label2={intl.formatMessage({id: 'prevoyance.dataSelector.trimesterLabel'})}
						/>
						<Stack direction="column" width={isSmallScreen ? '100%' : '50%'}>
							{!typeData &&
								<AnneeSelector
									onChange={handleChangeYear}
									name="toggleYears"
									stateValue={annee}
									data={qualiteTelephonieAnneeSelector()}
								/>
							}
						</Stack>
					</Stack>

					<Divider style={{border: '1px solid #dedede', width: '100%'}}/>

					{nothingToShow(qualiteTelAnneeN) ?
						<EmptyResult
							nature="PAGE"
							message={
								<FormattedMessage
									id="global.noPageData"
									values={{titrePage:
											<Typography
												variant="body2"
												fontSize="0,875rem"
												fontWeight="bold"
												color="#929292"
											>
												{intl.formatMessage({id: 'qualiteService.title'})}
											</Typography>
									}}
								/>
							}
						/> : <>

							<Stack direction="row" flex="1">
								<Typography variant="subtitle1" fontSize="0.75rem">
									{intl.formatMessage({id: 'qualiteService.info'})}
								</Typography>
							</Stack>

							<Divider style={{border: '1px solid #dedede', width: '100%'}}/>

							<Stack direction="column" flex="1">
								<Stack direction="row" spacing="24px" alignItems="center">
									<Stack flex="1" direction="column" spacing="5px" alignItems="center">
										<Stack direction="column" margin="auto">
											<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
												{intl.formatMessage({id: 'qualiteService.qualityNumbers.incomingCalls'})}
											</Typography>

											{(isSelectedYearPastYear ? qualiteTelAnneeN.totalAppelRentrant : trimestreOrMonthNumbers(qualiteTelAnneeN) !== undefined) ?
												<>
													<Stack direction="row" alignItems="baseline" spacing="2px">
														<Typography fontSize="2rem" fontWeight="600" color="#e96e11" variant="body1">
															{intl.formatNumber(isSelectedYearPastYear ? qualiteTelAnneeN.totalAppelRentrant : trimestreOrMonthNumbers(qualiteTelAnneeN) ? trimestreOrMonthNumbers(qualiteTelAnneeN).nbIncoming : "" )}
														</Typography>
														<Typography variant="subtitle1" fontSize="0.813rem">
															en {isSelectedYearPastYear
																? qualiteTelAnneeN.annee
																: trimestreOrMonthNumbers(qualiteTelAnneeN) ?  trimestreOrMonthNumbers(qualiteTelAnneeN).year.toLowerCase() + (!typeData ? " " + qualiteTelAnneeN.annee : '') : "" + (!typeData ? " " + qualiteTelAnneeN.annee : '')
															}
														</Typography>
													</Stack>
													{(isSelectedYearPastYear ? !nothingToShow(qualiteTelAnneeNMoins1) : trimestreOrMonthNumbers(qualiteTelAnneeNMoins1) !== undefined) &&
														<Stack direction="row" alignItems="baseline" spacing="2px">
															<Typography fontWeight="600" variant="body1" fontSize="1.125rem">
																{intl.formatNumber(isSelectedYearPastYear ? qualiteTelAnneeNMoins1.totalAppelRentrant : trimestreOrMonthNumbers(qualiteTelAnneeNMoins1) ? trimestreOrMonthNumbers(qualiteTelAnneeNMoins1).nbIncoming : "")}
															</Typography>
															<Typography variant="subtitle1" fontSize="0.813rem">
																en {isSelectedYearPastYear
																	? qualiteTelAnneeNMoins1.annee
																	: trimestreOrMonthNumbers(qualiteTelAnneeNMoins1) ? trimestreOrMonthNumbers(qualiteTelAnneeNMoins1).year.toLowerCase() + (!typeData ? " " + qualiteTelAnneeNMoins1.annee : '' )  : "" + (!typeData ? " " + qualiteTelAnneeNMoins1.annee : '' )
																}
															</Typography>
														</Stack>
													}
												</> : <>
													<EmptyResult
														nature="NUMBER"
														message={intl.formatMessage({id: 'global.noKpiNumbers'})}
													/>
												</>
											}
										</Stack>
									</Stack>
									<Stack flex="1" direction="column" spacing="5px" alignItems="center">
										<Stack direction="column" margin="auto">
											<Typography fontWeight="500" variant="body1" fontSize="0.875rem">
												{intl.formatMessage({id: 'qualiteService.qualityNumbers.answeredRate'})}
											</Typography>

											{(isSelectedYearPastYear ? qualiteTelAnneeN.tauxDecroche > 0 : trimestreOrMonthNumbers(qualiteTelAnneeN) !== undefined) ?
												<>
													<Stack direction="row" alignItems="baseline" spacing="2px">
														<Typography fontSize="2rem" fontWeight="600" color="#8ac11d" variant="body1">
															{isSelectedYearPastYear
																? qualiteTelAnneeN.tauxDecroche.toString().replace('.', ',')
																: trimestreOrMonthNumbers(qualiteTelAnneeN) ? trimestreOrMonthNumbers(qualiteTelAnneeN).ratioAnswer.toString().replace('.', ',') : ""
															}%
														</Typography>
														<Typography variant="subtitle1" fontSize="0.813rem">
															en {isSelectedYearPastYear
																? qualiteTelAnneeN.annee
																: trimestreOrMonthNumbers(qualiteTelAnneeN) ? trimestreOrMonthNumbers(qualiteTelAnneeN).year.toLowerCase() + (!typeData ? " " + qualiteTelAnneeN.annee : '') : ""
															}
														</Typography>
													</Stack>
													{(isSelectedYearPastYear ? !nothingToShow(qualiteTelAnneeNMoins1) : trimestreOrMonthNumbers(qualiteTelAnneeNMoins1) !== undefined) &&
														<Stack direction="row" alignItems="baseline" spacing="2px">
															<Typography fontWeight="600" variant="body1" fontSize="1.125rem">
																{isSelectedYearPastYear
																	? qualiteTelAnneeNMoins1.tauxDecroche.toString().replace('.', ',')
																	: trimestreOrMonthNumbers(qualiteTelAnneeNMoins1) ? trimestreOrMonthNumbers(qualiteTelAnneeNMoins1).ratioAnswer.toString().replace('.', ',') : ""
																}%
															</Typography>
															<Typography variant="subtitle1" fontSize="0.813rem">
																en {isSelectedYearPastYear
																	? qualiteTelAnneeNMoins1.annee
																	: trimestreOrMonthNumbers(qualiteTelAnneeNMoins1) ? trimestreOrMonthNumbers(qualiteTelAnneeNMoins1).year.toLowerCase() + (!typeData ? " " + qualiteTelAnneeNMoins1.annee : '') : ""
																}
															</Typography>
														</Stack>
													}
												</> : <>
													<EmptyResult
														nature="NUMBER"
														message={intl.formatMessage({id: 'global.noKpiNumbers'})}
													/>
												</>
											}
										</Stack>
									</Stack>
								</Stack>
								{qualiteTelAnneeN.donneesActualisees !== qualiteTelAnneeN.dateTelephonie &&
									<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem" mb="-12px" pt="4px">
										{intl.formatMessage({id: 'global.majData'}) + qualiteTelAnneeN.dateTelephonie}
									</Typography>
								}
							</Stack>

							<Divider style={{border: '1px solid #dedede', width: '100%'}}/>

							<Stack direction="column">
								<GridQualiteChart
									id_title={qualiteEvolutionGraphArticle && qualiteEvolutionGraphArticle.fields.title }
									idChart="qualite_evolution"
									dataChart={dataGraphe}
								/>
								{qualiteTelAnneeN.donneesActualisees !== qualiteTelAnneeN.dateTelephonie &&
									<Typography alignSelf="flex-end" variant="subtitle2" fontSize="0.75rem">
										{intl.formatMessage({id: 'global.majData'}) + qualiteTelAnneeN.dateTelephonie}
									</Typography>
								}
							</Stack>
						</>
					}
				</Stack>
			</PageLayoutEE>
		)
	}
}

const actions = {
	getArticleByShortcut: cardiboxActions.articles.getByShortcut
}
const mapStateToProps = (state: any) => {

	return ({
		loading: isDataKPILoading(state),
		dataTelephonies: qualiteSelector(state),
		qualiteEvolutionGraphArticle: cardiboxSelectors.articles.getByShortcut(state.cardibox)[ARTICLE_QUALITY_EVOLUTION_GRAPH]
	})
}
export default compose(
	connect(mapStateToProps, actions)
)(DashboardPageEE)

