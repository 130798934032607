import { getExpirationDate, isDemandesLoading } from './servicesSanteSelectors'
import { getExpirationDate as getExpirationDateMentionsLegales, isLoading as isLoadingMentionsLegales } from './mentionsLegales/servicesSanteMentionsLegalesSelectors'
import { GET_SERVICES, GET_SERVICES_LOADING, GET_MENTIONS_LEGALES, GET_MENTIONS_LEGALES_LOADING } from './servicesSanteActionTypes'
import ServicesService from '../../../api/gerep/ServicesService';
import LocalStorage from '../../../business/storage/LocalStorage';

const ACTION_NAMESPACE = '/RESEAU_SOIN'

export const types = {
	LOAD_SANTECLAIR_TOKEN: `${ACTION_NAMESPACE}/LOAD_TOKEN_SANTECLAIR`,
	SANTECLAIR_TOKEN_LOADING: `${ACTION_NAMESPACE}/TOKEN_SANTECLAIR_LOADING`
}

export const getServices = () => (dispatch, getState) => {
	if (!isDemandesLoading(getState()) &&
		(!getExpirationDate(getState()) ||
			getExpirationDate(getState()) < (+new Date()))) {
		dispatch({
			type: GET_SERVICES_LOADING,
			payload: true
		})
		return ServicesService.getServicesSante(LocalStorage.getToken())
			.then((services) => {
				dispatch({
					type: GET_SERVICES_LOADING,
					payload: false
				})
				return dispatch({
					type: GET_SERVICES,
					payload: services
				})
			}).catch((e) => {
				dispatch({
					type: GET_SERVICES_LOADING,
					payload: false
				})
				throw e
			})
	}
}

export const getMentionsLegales = () => (dispatch, getState) => {
	if (!isLoadingMentionsLegales(getState())
		&& getExpirationDateMentionsLegales(getState()) < (+new Date())) {
		dispatch({
			type: GET_MENTIONS_LEGALES_LOADING,
			payload: true
		})
		return ServicesService.getMentionsLegales(LocalStorage.getToken())
			.then(({ mentionsLegales }) => {
				return dispatch({
					type: GET_MENTIONS_LEGALES,
					payload: mentionsLegales
				})
			}).catch((e) => {
				dispatch({
					type: GET_MENTIONS_LEGALES_LOADING,
					payload: false
				})
				throw e
			})
	}
}
