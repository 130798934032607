import React, {useEffect, useMemo} from 'react'
import { useIntl } from 'react-intl'
import PageLayout from '../../../../components/PageLayout'
import MaintenirEnfantMajeurForm from '../components/MaintenirEnfantMajeurForm'
import { getEnfantMajeurList } from '../../../webservices/affiliation/affiliationSelector'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as affiliationActions from '../../../webservices/affiliation/affiliationActions'
import * as notificationActions from '../../../webservices/notifications/NotificationActions'
import { useBreakpoints } from '../../../../components/breakpoints/BreakpointsProvider'
import { getByDomainOrAll } from "../../../webservices/notifications/NotificationSelectors";
import { removeNotificationByInternalId } from "../../../webservices/notifications/NotificationActions";

const MaintenirEnfantMajeurPage = (
	{
		// @ts-ignore
		individuList,
		// @ts-ignore
		getAffiliation,
		// @ts-ignore
		loadNotifications,
		// @ts-ignore
		removeNotification,
		// @ts-ignore
		notifications
	}
) => {
	const intl = useIntl()
	const { isMobile, isTabletPortrait } = useBreakpoints()
	const urlParams = new URLSearchParams(window.location.search)
	const selectedNotif = useMemo(() => notifications.find((notif: any) => notif.data.idpiece && notif.data.idpiece.toString() === urlParams.get('s')), [notifications, urlParams])


	useEffect(() => {
		getAffiliation()
		loadNotifications()
	}, [getAffiliation, loadNotifications])

	return (
		<PageLayout title={intl.formatMessage({ id: 'maintenirEnfantMajeur.title' })} bodyPadding={isMobile || isTabletPortrait ? '32px 32px 12px 32px' : '32px 32px 0 32px'}>
			<MaintenirEnfantMajeurForm individuList={individuList} notification={selectedNotif} removeNotification={removeNotification}/>
		</PageLayout>
	)
}

// @ts-ignore
const makeMapStateToProps = (state: any, { location }) => {
	const notificationsSelector = getByDomainOrAll()
	return ({
		notifications: notificationsSelector(state),
		individuList: getEnfantMajeurList(state),
	})
}

const actions = {
	getAffiliation: affiliationActions.getAffiliation,
	loadNotifications: notificationActions.loadNotifications,
	removeNotification: removeNotificationByInternalId
}

export default compose(
	connect(makeMapStateToProps, actions)
)(MaintenirEnfantMajeurPage)
