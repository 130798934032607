import React from 'react'
import { Box, Stack } from '@mui/material'
import Notifications from '../Notifications'
import DownloadApp from '../DownloadApp'
import Welcome from '../Welcome'
import SendDocuments from '../SendDocuments'
import RefundCard from '../RefundCard'
import ServiceCard from '../ServiceCard'
import NetworkPartnerCard from '../NetworkPartnerCard'
import DemandsCard from '../DemandsCard'
import PersonalDataCard from '../PersonalDataCard'
import BeneficiaryCard from '../BeneficiaryCard'

type DashboardComputerProps = {
	affiliationExpired: boolean
	notifications: any[]
	remboursements: any[]
	user: any
	demandes: any[]
	assurePrincipal: any
	beneficiaires: any[]
}

const DashboardComputer: React.FC<DashboardComputerProps> = (
	{
		affiliationExpired,
		notifications,
		remboursements,
		user,
		demandes,
		assurePrincipal,
		beneficiaires
	}
) => {
	const hasUserServices = user?.profil?.itelis || user?.profil?.santeclair

	return (
		<Stack width="100%" direction="column" spacing="24px">
			<Stack width="100%" direction="row" spacing="24px" alignItems="flex-end">
				<Box display="flex" flex={2}>
					<Welcome affiliationExpired={affiliationExpired} />
				</Box>
				<Box display="flex" flex={1} alignItems="flex-end" minHeight="200px">
					{notifications?.length > 0 ? <Notifications notifications={notifications} /> : <DownloadApp />}
				</Box>
			</Stack>

			<Stack width="100%" direction="row" spacing="24px">
				<Box display="flex" flex={1}>
					{affiliationExpired ?
						<ServiceCard affiliationExpired={affiliationExpired} servicesSante={user?.profil?.servicesSante} simulateur={user?.profil?.simulateur} virementCotisation={user?.profil?.virementCotisation} />
						:
						<SendDocuments />
					}
				</Box>
				<Box display="flex" flex={1}>
					<RefundCard remboursements={remboursements} />
				</Box>
			</Stack>

			{!affiliationExpired &&
				<Stack width="100%" direction="row" spacing="24px">
					<Box display="flex" flex={2}>
						<ServiceCard affiliationExpired={affiliationExpired} servicesSante={user?.profil?.servicesSante} simulateur={user?.profil?.simulateur} virementCotisation={user?.profil?.virementCotisation} />
					</Box>
					{hasUserServices ?
						<Box display="flex" flex={1}>
							<NetworkPartnerCard isItelis={user.profil.itelis} isSanteClair={user.profil.santeclair} />
						</Box> :
						<Box display="flex" flex={1}>
							<DemandsCard demands={demandes} affiliationExpired={affiliationExpired} />
						</Box>
					}
				</Stack>
			}

			{affiliationExpired ?
				<Stack width="100%" direction="row" spacing="24px">
					<Stack flex={2} direction="row" spacing="24px">
						<Box display="flex" flex={1}>
							<DemandsCard demands={demandes} affiliationExpired={affiliationExpired} />
						</Box>
						<Box display="flex" flex={1}>
							<PersonalDataCard user={user} affiliation={assurePrincipal} affiliationExpired={affiliationExpired} />
						</Box>
					</Stack>
					<Box display="flex" flex={1}>
						<BeneficiaryCard beneficiaries={beneficiaires} affiliationExpired={affiliationExpired} />
					</Box>
				</Stack> :
				<Stack width="100%" direction="row" spacing="24px">
					{hasUserServices ?
						<Stack flex={2} direction="row" spacing="24px">
							<Box display="flex" flex={1}>
								<DemandsCard demands={demandes} affiliationExpired={affiliationExpired} />
							</Box>
							<Box display="flex" flex={1}>
								<PersonalDataCard user={user} affiliation={assurePrincipal} affiliationExpired={affiliationExpired} />
							</Box>
						</Stack> :
						<Stack flex={2} direction="row" spacing="24px">
							<Box display="flex" flex={1}>
								<PersonalDataCard user={user} affiliation={assurePrincipal} affiliationExpired={affiliationExpired} />
							</Box>
							<Box display="flex" flex={1}>
								<BeneficiaryCard beneficiaries={beneficiaires} affiliationExpired={affiliationExpired} />
							</Box>
						</Stack>
					}
					{hasUserServices ?
						<Box display="flex" flex={1}>
							<BeneficiaryCard beneficiaries={beneficiaires} affiliationExpired={affiliationExpired} />
						</Box> :
						<Box display="flex" flex={1} />
					}
				</Stack>
			}
		</Stack>
	)
}

export default DashboardComputer
