import { ButtonProps, default as ButtonMUI } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const ButtonConnexion = styled(ButtonMUI)<ButtonProps>(({ theme }) => ({
	'&.MuiButton-root': {
		borderRadius: 24,
		height: 36,
		width: '100%',
		fontSize: '1rem',
		fontWeight: 500,
		textTransform: 'uppercase',
		'&.MuiButton-contained': {
			color: 'white'
		},
		'&.MuiButton-outlined': {
			color: theme.palette.primary.main,
			border: '2px solid ' + theme.palette.primary.main
		}
	}
}))

export default ButtonConnexion
