import React, {useLayoutEffect} from 'react'
import {Stack} from '@mui/material'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5radar from '@amcharts/amcharts5/radar'
import * as am5xy from '@amcharts/amcharts5/xy'
import EmptyResult from '../../components/EmptyResult'

type RadialBarChartProps = {
	isInt : boolean
	idChart: string,
	middleLabel: string,
	dataChart: any
}

const RadialBarDashBoardChart: React.FC<RadialBarChartProps> = (
	{
		isInt,
		idChart,
		middleLabel,
		dataChart
	}
) => {

	useLayoutEffect(() => {
		if(dataChart) {
			let root = am5.Root.new(idChart)
			root.setThemes([am5themes_Animated.new(root)])
			root.numberFormatter.set("intlLocales", "fr-FR")
			if(!isInt){
				root.numberFormatter.set("numberFormat", {
					minimumFractionDigits: 2
				})
			}
			// @ts-ignore
			root._logo.dispose()

			let chart = root.container.children.push(
				am5radar.RadarChart.new(root, {
					panX: false,
					panY: false,
					innerRadius: am5.percent(70),
				})
			)

			let label = chart.radarContainer.children.push(
				am5.Label.new(root, {
					centerX: am5.percent(50),
					textAlign: "center",
					centerY: am5.percent(50),
					fontSize: 20,
					fill:am5.color(dataChart.color)
				})
			)

			let data = [{
				category: "",
				value: dataChart.value,
				full: 100,
				columnSettings: {
					fill: am5.color(dataChart.color)
				}
			}]

			let xRenderer = am5radar.AxisRendererCircular.new(root, {})

			xRenderer.labels.template.setAll({
				radius: 10
			})

			xRenderer.grid.template.setAll({
				forceHidden: true
			})

			let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
				renderer: xRenderer,
				min: 0,
				max: 100,
				strictMinMax: true,
				visible: false,
				tooltip: am5.Tooltip.new(root, {})
			}))

			let yRenderer = am5radar.AxisRendererRadial.new(root, {
				minGridDistance: 20
			})

			yRenderer.labels.template.setAll({
				centerX: am5.p100,
				fontWeight: "500",
				fontSize: 18,
				templateField: "columnSettings",
				visible: false
			})

			yRenderer.grid.template.setAll({
				forceHidden: true
			})

			let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
				categoryField: "category",
				renderer: yRenderer,
			}))

			yAxis.data.setAll(data)

			let series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
				xAxis: xAxis,
				yAxis: yAxis,
				clustered: false,
				valueXField: "full",
				categoryYField: "category",
				fill: root.interfaceColors.get("alternativeBackground")
			}))

			series1.columns.template.setAll({
				width: am5.p100,
				fillOpacity: 0.08,
				strokeOpacity: 0,
				cornerRadius: 20
			})

			series1.data.setAll(data)

			let series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
				xAxis: xAxis,
				yAxis: yAxis,
				clustered: false,
				valueXField: "value",
				categoryYField: "category"
			}))

			series2.columns.template.setAll({
				width: am5.p100,
				strokeOpacity: 0,
				cornerRadius: 20,
				templateField: "columnSettings"
			})

			series2.data.setAll(data)

			series1.appear(1000)
			series2.appear(1000)
			chart.appear(1000, 100)
			label.set("text", middleLabel)

			return () => root.dispose()
		}

	}, [dataChart,idChart])

	if(!dataChart){
		return (
			<Stack direction="column" spacing="12px" flex={12}>
				<Stack alignItems="center" direction="column" sx={{ height: '150px' }}>
					<EmptyResult/>
				</Stack>
			</Stack>
		)
	}


	return (
		<>
			<Stack direction="column" width="100%" alignItems="center">
				<div id={idChart} style={{ width: '100%', height: '150px' }}></div>
			</Stack>
		</>
	)
}

export default RadialBarDashBoardChart
